import {
    APPLICATION_ACTION_PAGE_FETCH,
    APPLICATION_ACTION_PAGE_FETCH_SUCCESS,
    APPLICATION_ACTION_PAGE_FETCH_ERROR,
    APPLICATION_ACTION_ORDERING_ENABLE,
    APPLICATION_ACTION_ORDERING_ENABLE_SUCCESS,
    APPLICATION_ACTION_ORDERING_ENABLE_ERROR,
    APPLICATION_ACTION_ORDERING_ENABLE_SAVE,
    APPLICATION_ACTION_ORDERING_ENABLE_SAVE_SUCCESS,
    APPLICATION_ACTION_ORDERING_ENABLE_SAVE_ERROR,
    APPLICATION_ACTION_ORDERING_ENABLE_DELETE,
    APPLICATION_ACTION_ORDERING_ENABLE_DELETE_SUCCESS,
    APPLICATION_ACTION_ORDERING_ENABLE_DELETE_ERROR,
    APPLICATION_ACTION_MENU_SYNC_ERROR,
    APPLICATION_ACTION_MENU_SYNC_SUCCESS,
    APPLICATION_ACTION_MENU_SYNC,
    defaultNullUser,
} from "./types";
import { ApplicationAction, ApplicationActionEvent, ApplicationActionOperations } from "./types";

interface ApplicationActionState {
    actionsById: {
        [key: string]: ApplicationAction;
    };
    eventsByApplicationHref: {
        [key: string]: string[];
    };
    eventsByActionId: string[];
    eventsById: {
        [key: string]: ApplicationActionEvent;
    };
    fetching: boolean;
    saving: boolean;
    error: Error | undefined | null;
}

const initialState: ApplicationActionState = {
    actionsById: {},
    eventsByApplicationHref: {},

    eventsByActionId: [],
    eventsById: {},
    fetching: false,
    saving: false,
    error: undefined,
};

export default function applicationActions(
    state = initialState,
    action: ApplicationActionOperations,
): ApplicationActionState {
    switch (action.type) {
        case APPLICATION_ACTION_ORDERING_ENABLE:
        case APPLICATION_ACTION_ORDERING_ENABLE_SAVE:
        case APPLICATION_ACTION_ORDERING_ENABLE_DELETE:
        case APPLICATION_ACTION_MENU_SYNC:
            return {
                ...state,
                saving: true,
            };
        case APPLICATION_ACTION_PAGE_FETCH:
            return {
                ...state,
                fetching: true,
            };

        case APPLICATION_ACTION_MENU_SYNC_SUCCESS:
            return {
                ...state,
                saving: false,
            };

        case APPLICATION_ACTION_PAGE_FETCH_SUCCESS: {
            const eventsById = parseEventsById(action.actions);
            const eventsByApplicationHref = parseApplicationHrefs(eventsById, state);

            return {
                ...state,
                actionsById: {
                    ...(action.actions || []).reduce((allActions: { [id: string]: ApplicationAction }, a) => {
                        allActions[a.id] = a;
                        return allActions;
                    }, {}),
                },
                eventsById: {
                    ...state.eventsById,
                    ...eventsById,
                },
                eventsByApplicationHref: {
                    ...state.eventsByApplicationHref,
                    ...eventsByApplicationHref,
                },
                fetching: false,
            };
        }

        case APPLICATION_ACTION_ORDERING_ENABLE_SUCCESS: {
            const eventsById = parseEventsById(action.actions);
            const eventsByApplicationHref = parseApplicationHrefs(eventsById, state);

            return {
                ...state,
                actionsById: {
                    ...(action.actions || []).reduce((allActions: { [id: string]: ApplicationAction }, a) => {
                        allActions[a.id] = a;
                        return allActions;
                    }, {}),
                },
                eventsById: {
                    ...state.eventsById,
                    ...eventsById,
                },
                eventsByApplicationHref: {
                    ...state.eventsByApplicationHref,
                    ...eventsByApplicationHref,
                },
                saving: false,
            };
        }

        case APPLICATION_ACTION_ORDERING_ENABLE_SAVE_SUCCESS: {
            const eventsToChange = parseEventsById([action.action]);

            return {
                ...state,
                actionsById: {
                    ...state.actionsById,
                    [action.action.id]: action.action,
                },
                eventsById: {
                    ...state.eventsById,
                    ...Object.values(eventsToChange).reduce(
                        (
                            allEvents: {
                                [id: string]: ApplicationActionEvent;
                            },
                            e,
                        ) => {
                            allEvents[e.id] = e;
                            return allEvents;
                        },
                        {},
                    ),
                },
                saving: false,
            };
        }

        case APPLICATION_ACTION_ORDERING_ENABLE_DELETE_SUCCESS: {
            const eventsToRemove = parseEventsById([action.action]);
            const eventIdsToRemove = Object.keys(eventsToRemove);
            const eventsByApplicationHref = parseApplicationHrefs(eventsToRemove, state);

            return {
                ...state,
                actionsById: {
                    ...Object.values(state.actionsById).reduce((allActions: { [id: string]: ApplicationAction }, a) => {
                        if (action.action.id !== a.id) {
                            allActions[a.id] = a;
                        }
                        return allActions;
                    }, {}),
                },
                eventsById: {
                    ...Object.values(state.eventsById).reduce(
                        (allEvents: { [id: string]: ApplicationActionEvent }, e) => {
                            if (![...Object.keys(eventsToRemove)].includes(e.id)) {
                                allEvents[e.id] = e;
                            }
                            return allEvents;
                        },
                        {},
                    ),
                },
                eventsByApplicationHref: {
                    ...Object.keys(state.eventsByApplicationHref).reduce(
                        (allHrefs: { [href: string]: string[] }, h) => {
                            (eventsByApplicationHref[h] || []).forEach((id) => {
                                const ids = allHrefs[h];
                                if (!eventIdsToRemove.includes(id)) {
                                    allHrefs[h] = [...(ids || []), id];
                                }
                            });
                            return allHrefs;
                        },
                        {},
                    ),
                },
                saving: false,
            };
        }

        case APPLICATION_ACTION_PAGE_FETCH_ERROR:
            return {
                ...state,
                fetching: false,
                error: action.error,
            };

        case APPLICATION_ACTION_ORDERING_ENABLE_ERROR:
        case APPLICATION_ACTION_ORDERING_ENABLE_SAVE_ERROR:
        case APPLICATION_ACTION_ORDERING_ENABLE_DELETE_ERROR:
        case APPLICATION_ACTION_MENU_SYNC_ERROR:
            return {
                ...state,
                saving: false,
                error: action.error,
            };
        default:
            return state;
    }
}

const parseEventsById = (actions: ApplicationAction[]): { [key: string]: ApplicationActionEvent } => {
    return (actions || []).reduce((eventsById: { [id: string]: ApplicationActionEvent }, a) => {
        a._embedded.events.forEach((e) => {
            let status;
            switch (e.success) {
                case null:
                    status = "Pending";
                    break;
                case true:
                    status = "Success";
                    break;
                default:
                    status = "Failed";
                    break;
            }

            eventsById[e.id] = {
                ...e,
                status,
                actionId: a.id,
                actionType: a.type,
                scheduled: e.status === "scheduled",
                start_time: a.scheduled,
                enabled: JSON.parse(a.params)["enabled"],
                _embedded: {
                    ...e._embedded,
                    user: a._embedded.user || defaultNullUser(),
                },
            };
        });
        return eventsById;
    }, {});
};

const parseApplicationHrefs = (
    eventsById: { [key: string]: ApplicationActionEvent },
    state: ApplicationActionState,
): { [applicationHref: string]: string[] } => {
    return Object.keys(eventsById).reduce((eventsByHref: { [id: string]: string[] }, eID) => {
        const e = eventsById[eID];
        const href = createApplicationHref(e);

        eventsByHref[href] = [
            ...(eventsByHref[href] || state.eventsByApplicationHref[href] || []).filter((id) => id !== eID),
            eID,
        ];

        return eventsByHref;
    }, {});
};

const createApplicationHref = (e: ApplicationActionEvent): string => {
    return `${e._links.self.href.split("/mms")[0]}/locations/${e._embedded.location.id}/mms/menus/${
        e._embedded.menu.id
    }/applications/${e._embedded.application.id}`;
};
