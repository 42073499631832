import { Theme } from "@mui/material";
import { LightModeOutlined, SettingsBrightnessOutlined, DarkModeOutlined } from "@mui/icons-material";
import { ToggleButtonGroup, ToggleButton, Typography } from "@mui/material";
import React, { useState } from "react";
import { makeStyles } from "theme";

const useStyles = makeStyles()((theme: Theme) => ({
    text: { textTransform: "none", margin: "auto", padding: theme.spacing(0, 1) },
}));

interface ThemeSwitcherProps {
    onThemeChange: (mode?: string) => void;
}

export default function ThemeSwitcher({ onThemeChange }: ThemeSwitcherProps): JSX.Element {
    const { classes } = useStyles();
    const [selectedMode, setSelectedMode] = useState<string>(localStorage.getItem("theme") || "system");

    const modes = ["light", "system", "dark"];

    return (
        <ToggleButtonGroup>
            {modes.map((mode) => (
                <ToggleButton
                    selected={mode === selectedMode}
                    key={mode}
                    onClick={() => {
                        onThemeChange(mode);
                        setSelectedMode(mode);
                        localStorage.setItem("theme", mode);
                    }}
                    value={mode}
                >
                    {mode === "light" && <LightModeOutlined />}
                    {mode === "system" && <SettingsBrightnessOutlined />}
                    {mode === "dark" && <DarkModeOutlined />}

                    <Typography variant="subtitle1" className={classes.text}>
                        {mode.toString()[0].toUpperCase() + mode.substring(1, mode.length)}
                    </Typography>
                </ToggleButton>
            ))}
        </ToggleButtonGroup>
    );
}
