import { Typography, Alert } from "@mui/material";
import ConfirmDialog from "components/common/ConfirmDialog";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { MasterModifier } from "store/mms/menus/master/items/modifiers/types";
import { modifierGroupModifiersSave } from "store/mms/menus/master/items/option-sets/modifier-group/actions";
import { ModifierGroup } from "store/mms/menus/master/items/option-sets/modifier-group/types";
import { axiosErrorMessage } from "store/types";

interface ModifierDeleteProps {
    onClose(): void;
    open: boolean;
    modifier: MasterModifier;
    group: ModifierGroup;
}

export function ModifierDelete({ onClose, open, modifier, group }: ModifierDeleteProps): JSX.Element {
    const dispatch = useDispatch();
    const [state, setState] = useState<{ deleting: boolean; error?: string }>({
        deleting: false,
    });

    return (
        <ConfirmDialog
            title="Remove Modifier From Modifier Group"
            open={open}
            onClose={onClose}
            onConfirm={() => {
                setState(() => ({ error: undefined, deleting: true }));
                dispatch(
                    modifierGroupModifiersSave(
                        group,
                        group._embedded.modifiers
                            .filter((mgm) => mgm.id !== modifier.id)
                            .map((mgm) => ({ id: mgm.id, enabled: mgm.enabled })),
                        (error?: Error): void => {
                            if (error) {
                                return setState(() => ({
                                    deleting: false,
                                    error: axiosErrorMessage(error),
                                }));
                            }
                            setState(() => ({
                                deleting: false,
                                error: undefined,
                            }));
                            onClose();
                        },
                    ),
                );
            }}
            confirm="Remove Modifier"
            confirming={state.deleting}
            maxWidth="xl"
            transitionDuration={0}
        >
            <Typography>
                Are you sure you want to remove the <strong>{modifier.display_name}</strong> Modifier from the{" "}
                <strong>{group.display_name}</strong> Modifier Group?
            </Typography>

            <Alert variant="outlined" severity="info">
                This change will affect all linked <strong>Locations</strong> and anywhere{" "}
                <strong>{group.display_name}</strong> is used.
            </Alert>

            {state.error && (
                <Alert variant="outlined" severity="error">
                    {state.error}
                </Alert>
            )}
        </ConfirmDialog>
    );
}
