// useRand's purpose is to create a constant random for the skeleton components made in this file.  This prevents the
// skeletons from changing size if they are rerendered, which can happen when the state from the calling component (e.g.

import { useState } from "react";

// the master menu item page) finishes loading some but not all of its state.
export default function useRand(min: number, max: number): number {
    const [r] = useState((Math.random() * max + min) >>> 0);
    return r;
}
