export const ACCOUNT_FETCH = "@@ACCOUNT/FETCH";
export const ACCOUNT_FETCH_SUCCESS = "@@ACCOUNT/FETCH_SUCCESS";
export const ACCOUNT_FETCH_ERROR = "@@ACCOUNT/FETCH_ERROR";

export interface Account {
    company: string;
}

export interface AccountState extends Account {
    loading: boolean;
}

interface AccountFetchSuccess {
    type: typeof ACCOUNT_FETCH_SUCCESS;
    payload: Account;
}

interface AccountFetchError {
    type: typeof ACCOUNT_FETCH_ERROR;
    payload: Error;
}

interface AccountFetch {
    type: typeof ACCOUNT_FETCH;
}

export type AccountActions = AccountFetchSuccess | AccountFetchError | AccountFetch;
