import { Box, IconButton } from "@mui/material";
import { DataGrid, GridCellParams, GridSortItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import { OmniDrawer } from "components/common/OmniDrawer";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LocationMenuApplication } from "store/mms/menus/location/applications/types";
import { RootState } from "store/rootReducer";
import { LocationMenu } from "store/mms/menus/location/types";
import { Edit } from "@mui/icons-material";
import { ListPerPageOptions } from "components/props";
import ApplicationScheduledEventConfirmDeleteDialog from "./ApplicationScheduledEventConfirmDeleteDialog";
import ApplicationScheduledEventEditDialog from "./ApplicationScheduledEventEditDialog";
import { dateTimePMStringFormatter } from "components/common/utils";
import { LocationContext } from "pages/Locations/context";
import { ApplicationActionEvent, APPLICATION_ACTION_TYPE_ORDERING_ENABLE } from "store/mms/application-actions/types";
import { applicationActionPageFetch } from "store/mms/application-actions/actions";

interface ApplicationScheduledEventsProps {
    open: boolean;
    onClose: () => void;
    menu: LocationMenu | undefined;
    application: LocationMenuApplication | undefined;
}
export const ApplicationScheduledEvents = ({
    open = false,
    onClose,
    menu,
    application,
}: ApplicationScheduledEventsProps) => {
    const { locationId, menuId } = useContext(LocationContext);
    const dispatch = useDispatch();

    const applicationHref = application?._links.self.href || "";

    const scheduledEventsByApplicationHref = useSelector(
        (state: RootState) => state.mms.applicationActions.eventsByApplicationHref,
    );
    const scheduledEventsById = useSelector((state: RootState) => state.mms.applicationActions.eventsById);

    const scheduledEvents = useMemo(
        () =>
            (scheduledEventsByApplicationHref[applicationHref] || [])
                .filter(
                    (e) =>
                        scheduledEventsById[e].scheduled &&
                        scheduledEventsById[e].actionType === APPLICATION_ACTION_TYPE_ORDERING_ENABLE,
                )
                .map((id) => scheduledEventsById[id]) || [],
        [scheduledEventsByApplicationHref, scheduledEventsById, applicationHref],
    );
    const [events, setEvents] = useState<ApplicationActionEvent[]>(scheduledEvents);
    useEffect(() => {
        setEvents(scheduledEvents);
    }, [setEvents, scheduledEvents]);

    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState<ApplicationActionEvent | undefined>(undefined);
    const handleDeleteClose = () => {
        setConfirmDeleteOpen(undefined);
    };
    const [editOpen, setEditOpen] = useState<ApplicationActionEvent | undefined>(undefined);
    const handleEditClose = () => {
        setEditOpen(undefined);
    };
    useEffect(() => {
        if (application) {
            dispatch(applicationActionPageFetch(application.id, locationId, menuId));
        }
    }, [dispatch, application, locationId, menuId]);

    const [pageSize, setPageSize] = useState(ListPerPageOptions[0]);
    const [sortModel, setSortModel] = useState<GridSortItem[]>([
        {
            field: "start_time",
            sort: "asc",
        },
    ]);

    const isLoading =
        application === undefined ||
        menu === undefined ||
        scheduledEventsByApplicationHref[applicationHref] === undefined;

    const title = application === undefined ? "Upcoming Changes" : "Upcoming Changes for " + application.name;

    return (
        <>
            <OmniDrawer open={open} title={title} close={onClose}>
                {application && (
                    <DataGrid
                        autoHeight
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        rowsPerPageOptions={ListPerPageOptions}
                        sx={{ padding: (theme) => theme.spacing(2, 2, 0, 2) }}
                        loading={isLoading}
                        sortModel={sortModel}
                        onSortModelChange={(model) => setSortModel(model)}
                        rows={events}
                        columns={[
                            {
                                field: "created",
                                headerName: "Created",
                                flex: 0.35,
                                renderCell: function (params: GridCellParams) {
                                    return dateTimePMStringFormatter(new Date((params.row.created || 0) * 1000));
                                },
                            },
                            {
                                field: "user",
                                headerName: "User",
                                flex: 0.18,
                                renderCell: function (params: GridCellParams) {
                                    return <Box>{params.row._embedded.user.full_name}</Box>;
                                },
                            },
                            {
                                field: "enabledLabel",
                                headerName: "New Status",
                                flex: 0.18,
                                renderCell: function (params: GridCellParams) {
                                    return (
                                        <Box
                                            sx={{
                                                color: (theme) =>
                                                    params.row.enabled
                                                        ? theme.palette.success.main
                                                        : theme.palette.grey[800],
                                            }}
                                        >
                                            {params.row.enabled ? "Enabled" : "Disabled"}
                                        </Box>
                                    );
                                },
                            },
                            {
                                field: "start_time",
                                headerName: "Start Time",
                                flex: 0.3,
                                renderCell: function (params: GridCellParams) {
                                    return dateTimePMStringFormatter(new Date((params.row.start_time || 0) * 1000));
                                },
                            },
                            {
                                field: "actions",
                                headerName: "Actions",
                                flex: 0.18,
                                renderCell: function (params: GridCellParams) {
                                    return (
                                        <>
                                            <IconButton onClick={() => setEditOpen(params.row)} title="Edit">
                                                <Edit />
                                            </IconButton>
                                            <IconButton onClick={() => setConfirmDeleteOpen(params.row)} title="Delete">
                                                <DeleteIcon />
                                            </IconButton>
                                        </>
                                    );
                                },
                            },
                        ]}
                    />
                )}
            </OmniDrawer>
            {application && confirmDeleteOpen !== undefined && (
                <ApplicationScheduledEventConfirmDeleteDialog
                    application={application}
                    scheduledEvent={confirmDeleteOpen}
                    onClose={handleDeleteClose}
                />
            )}
            {application && editOpen !== undefined && (
                <ApplicationScheduledEventEditDialog
                    application={application}
                    scheduledEvent={editOpen}
                    onClose={handleEditClose}
                />
            )}
        </>
    );
};
