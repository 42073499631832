import { POSConfigKey } from "../types";
import { LocationModifier, ModifierStrategy } from "./location/items/modifiers/types";
import { ItemStrategy, LocationItem } from "./location/items/types";
import { MasterModifier } from "./master/items/modifiers/types";
import { OptionSet as MasterOptionSet } from "./master/items/option-sets/types";
import { MasterItem } from "./master/items/types";
import { MasterMenu } from "./master/types";
import { Period as MasterPeriod } from "./master/periods/types";
import { MenuLayer } from "./master/layers/types";
import { MenuLayerRuleSet } from "./master/layers/rule-sets/types";
import { MenuLayerRule } from "./master/layers/rule-sets/rules/types";

export interface MenuEntity {
    id: string;
    name: string;
    strategy: ItemStrategy | ModifierStrategy;
    pos_entity_type: POSConfigKey;
    pathname: string;
}

type entity =
    | Omit<LocationItem, "nutrition">
    | Omit<LocationModifier, "nutrition">
    | MasterItem
    | MasterModifier
    | MasterOptionSet;

function isMasterOptionSet(e: entity): e is MasterOptionSet {
    return e?._links?.self?.type === "application/hal+json; name=mms_master_menu_option_set";
}

export function entityLocationPageLink(e: entity): string {
    const url = new URL(e._links.self.href);

    return url.pathname
        .replace(/_/g, "-")
        .replace("mms/menus", "menus")
        .replace(/.+(?:\blocations\b|\baccounts\b)([^\/]*)/, "/locations$1");
}

export function entityMasterPageLink(e: entity, itemId?: string): string {
    let locationLink = entityLocationPageLink(e);

    if (!isMasterOptionSet(e)) {
        // Remove all option-sets and their ids
        locationLink = locationLink.replace(/\/\boption-sets\b\/[^\/]*/g, "");
    }

    if (itemId) {
        locationLink = locationLink.replace(/(.+\bmenus\b\/[^\/]*)(\/.+)/, `$1/items/${itemId}$2`);
    }

    return locationLink
        .replace(/\/\blocations\b\/[^\/]*/, "")
        .replace(/\/\bsections\b\/[^\/]*/, "")
        .replace(/\/\bmodifier-group\b[^\/]*/g, "") // Remove all modifier-group
        .replace(/(\bmodifiers\b\/[^\/]*\/?){2,}/g, "$1") // Remove duplicate modifiers and their ids
        .replace(/\/{2,}/, "/"); // Remove duplicate forward slashes
}

export const ENTITY_DELETE = "@@ADMINMASTER/ENTITY/DELETE";
export const ENTITY_DELETE_SUCCESS = "@@ADMINMASTER/ENTITY/DELETE_SUCCESS";
export const ENTITY_DELETE_ERROR = "@@ADMINMASTER/ENTITY/DELETE_ERROR";

export type MasterEntity =
    | MasterMenu
    | MasterPeriod
    | MenuLayer
    | MenuLayerRuleSet
    | MenuLayerRule
    | MasterItem
    | MasterModifier
    | MasterOptionSet;

interface EntityDelete {
    type: typeof ENTITY_DELETE;
    entity: MasterEntity;
    entityName: string;
    callback?: (error?: Error) => void;
}

interface EntityDeleteSuccess {
    type: typeof ENTITY_DELETE_SUCCESS;
    entity: MasterEntity;
}

interface EntityDeleteError {
    type: typeof ENTITY_DELETE_ERROR;
    error: Error;
}

export type MasterEntityActions = EntityDelete | EntityDeleteSuccess | EntityDeleteError;
