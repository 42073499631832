import { Location } from "store/locations/types";

export const REPORTS_FETCH = "@@REPORTS/FETCH";
export const REPORTS_FETCH_SUCCESS = "@@REPORTS/FETCH_SUCCESS";
export const REPORTS_FETCH_ERROR = "@@REPORTS/FETCH_ERROR";

export const REPORTS_FILTERS_FETCH = "@@REPORTS/FILTERS/FETCH";
export const REPORTS_FILTERS_FETCH_SUCCESS = "@@REPORTS/FILTERS/FETCH_SUCCESS";
export const REPORTS_FILTERS_FETCH_ERROR = "@@REPORTS/FILTERS/FETCH_ERROR";

export const REPORTS_FILTERS_SET_AVAILABLE = "@@REPORTS/FILTERS/SET_AVAILABLE";
export const REPORTS_FILTERS_SET_APPLIED = "@@REPORTS/FILTERS/SET_APPLIED";
export const REPORTS_FILTERS_SET_START_DATETIME = "@@REPORTS/FILTERS/SET_START_DATETIME";
export const REPORTS_FILTERS_SET_END_DATETIME = "@@REPORTS/FILTERS/SET_END_DATETIME";

export const REPORTS_FILTERS_ADD_RESTAURANT_CONCEPT = "@@REPORTS/FILTERS/ADD_RESTAURANT_CONCEPT";
export const REPORTS_FILTERS_REMOVE_RESTAURANT_CONCEPT = "@@REPORTS/FILTERS/REMOVE_RESTAURANT_CONCEPT";
export const REPORTS_FILTERS_RESET_RESTAURANT_CONCEPT = "@@REPORTS/FILTERS/RESET_RESTAURANT_CONCEPT";
export const REPORTS_FILTERS_ADD_POS_TYPE = "@@REPORTS/FILTERS/ADD_POS_TYPE";
export const REPORTS_FILTERS_REMOVE_POS_TYPE = "@@REPORTS/FILTERS/REMOVE_POS_TYPE";
export const REPORTS_FILTERS_RESET_POS_TYPE = "@@REPORTS/FILTERS/RESET_POS_TYPE";

export const REPORTS_FILTERS_ADD_LOCATION = "@@REPORTS/FILTERS/ADD_LOCATION";
export const REPORTS_FILTERS_REMOVE_LOCATION = "@@REPORTS/FILTERS/REMOVE_LOCATION";

export interface POSInjectionSuccessRateReport {
    success: number;
    failure: number;
    total: number;
}

export interface ReportsState {
    posInjectionSuccessRate: { readonly loading: boolean } & POSInjectionSuccessRateReport;
}

export interface POSInjectionStatsResp {
    success: number;
    failure: number;
    total: number;
}

export interface ReportFiltersResp {
    _embedded: {
        restaurant_concepts: RestaurantConcept[];
        pos_types: POSType[];
    };
}

interface ReportsFetch {
    type: typeof REPORTS_FETCH;
}

interface ReportsFetchSuccess {
    type: typeof REPORTS_FETCH_SUCCESS;
    payload: POSInjectionSuccessRateReport;
}

interface ReportsFetchError {
    type: typeof REPORTS_FETCH_ERROR;
    payload: Error;
}

export type ReportsActions = ReportsFetchSuccess | ReportsFetchError | ReportsFetch;

export interface RestaurantConcept {
    id: string;
    name: string;
}

export interface POSType {
    id: string;
    name: string;
}

export interface ReportsFilters {
    RestaurantConcepts: RestaurantConcept[];
    POSTypes: POSType[];
}

export interface ReportsFiltersState {
    available: ReportsFilters;
    applied: ReportsFilters & { Locations: Location[] };
    loading: boolean;
    start: string;
    end: string;
}

interface ReportsFiltersFetch {
    type: typeof REPORTS_FILTERS_FETCH;
}

interface ReportsFiltersFetchSuccess {
    type: typeof REPORTS_FILTERS_FETCH_SUCCESS;
}

interface ReportsFiltersFetchError {
    type: typeof REPORTS_FILTERS_FETCH_ERROR;
    payload: Error;
}

interface ReportsFiltersSetAvailable {
    type: typeof REPORTS_FILTERS_SET_AVAILABLE;
    payload: ReportsFilters;
}

interface ReportsFiltersSetApplied {
    type: typeof REPORTS_FILTERS_SET_APPLIED;
    payload: ReportsFilters;
}

interface ReportsFiltersAddRestaurantConcept {
    type: typeof REPORTS_FILTERS_ADD_RESTAURANT_CONCEPT;
    payload: RestaurantConcept;
}

interface ReportsFiltersRemoveRestaurantConcept {
    type: typeof REPORTS_FILTERS_REMOVE_RESTAURANT_CONCEPT;
    payload: RestaurantConcept;
}

interface ReportsFiltersAddLocation {
    type: typeof REPORTS_FILTERS_ADD_LOCATION;
    payload: Location;
}

interface ReportsFiltersRemoveLocation {
    type: typeof REPORTS_FILTERS_REMOVE_LOCATION;
    payload: Location;
}

interface ReportsFiltersResetRestaurantConcept {
    type: typeof REPORTS_FILTERS_RESET_RESTAURANT_CONCEPT;
}

interface ReportsFiltersAddPOSType {
    type: typeof REPORTS_FILTERS_ADD_POS_TYPE;
    payload: POSType;
}

interface ReportsFiltersRemovePOSType {
    type: typeof REPORTS_FILTERS_REMOVE_POS_TYPE;
    payload: POSType;
}

interface ReportsFiltersResetPOSType {
    type: typeof REPORTS_FILTERS_RESET_POS_TYPE;
}

interface ReportsFiltersSetStartDatetime {
    type: typeof REPORTS_FILTERS_SET_START_DATETIME;
    payload: string;
}

interface ReportsFiltersSetEndDatetime {
    type: typeof REPORTS_FILTERS_SET_END_DATETIME;
    payload: string;
}

export type ReportFiltersActions =
    | ReportsFiltersFetch
    | ReportsFiltersFetchSuccess
    | ReportsFiltersFetchError
    | ReportsFiltersSetAvailable
    | ReportsFiltersSetApplied
    | ReportsFiltersAddRestaurantConcept
    | ReportsFiltersRemoveRestaurantConcept
    | ReportsFiltersResetRestaurantConcept
    | ReportsFiltersAddLocation
    | ReportsFiltersRemoveLocation
    | ReportsFiltersAddPOSType
    | ReportsFiltersRemovePOSType
    | ReportsFiltersResetPOSType
    | ReportsFiltersSetStartDatetime
    | ReportsFiltersSetEndDatetime;
