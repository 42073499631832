import { Location } from "store/locations/types";
import { LocationMenuApplication } from "./applications/types";
import { SaveAction } from "store/types";

export const LOCATION_MENU_PAGE_FETCH = "@@ADMINLOCATION/MENU/PAGE/FETCH";
export const LOCATION_MENU_PAGE_FETCH_SUCCESS = "@@ADMINLOCATION/MENU/PAGE/FETCH_SUCCESS";
export const LOCATION_MENU_PAGE_FETCH_ERROR = "@@ADMINLOCATION/MENU/PAGE/FETCH_ERROR";

export const LOCATION_BY_MENU_EXPORT_FETCH = "@@ADMINLOCATION/BY_MENU/EXPORT";

export const LOCATION_MENU_SAVE = "@@ADMINLOCATION/MENU/SAVE";
export const LOCATION_MENU_SAVE_SUCCESS = "@@ADMINLOCATION/MENU/SAVE_SUCCESS";
export const LOCATION_MENU_SAVE_ERROR = "@@ADMINLOCATION/MENU/SAVE_ERROR";

export const LOCATION_MENU_ONBOARD = "@@ADMINLOCATION/MENU/ONBOARD";

export const LOCATION_MENU_APPLICATION_PAGE_FETCH = "@@ADMINLOCATION/MENU/APPLICATION/PAGE/FETCH";
export const LOCATION_MENU_APPLICATION_PAGE_FETCH_SUCCESS = "@@ADMINLOCATION/MENU/APPLICATION/PAGE/FETCH_SUCCESS";
export const LOCATION_MENU_APPLICATION_PAGE_FETCH_ERROR = "@@ADMINLOCATION/MENU/APPLICATION/PAGE/FETCH_ERROR";

export const ADMINLOCATION_RESET = "@@ADMINLOCATION/RESET";

// These statuses are controlled by the API. See below.
// https://github.com/omnivore/giganto/blob/4706369db2203a75acd1afe92e43be39f6b12b24/om/backend/internal/models/mms_location_menu.go#L34
export enum OnboardingStatus {
    Pending = "pending",
    Importing = "importing",
    Linking = "linking",
    Approving = "approving",
    Auditing = "auditing",
    Complete = "complete",
}

export interface LocationMenuOnboardReq {
    mms_menu: string;
}

export interface LocationMenu {
    enabled: boolean;
    id: string;
    name: string;
    onboarding_status: OnboardingStatus;
    _embedded: {
        location: Location;
        applications: LocationMenuApplication[];
    };
    _links: {
        applications: {
            href: string;
            type: "application/hal+json; name=adminlocation_menu_application_list";
        };
        periods: {
            href: string;
            type: "application/hal+json; name=adminlocation_menu_period_list";
        };
        sections: {
            href: string;
            type: "application/hal+json; name=adminlocation_menu_section_list";
        };
        self: {
            href: string;
            type: "application/hal+json; name=adminlocation_menu";
        };
    };
}

export interface LocationMenuPageResp {
    _embedded: {
        location_menus: LocationMenu[];
    };
    _links: {
        next?: {
            href: string;
            type: "application/hal+json; name=adminlocation_menu_list";
        };

        self: {
            href: string;
            type: "application/hal+json; name=adminlocation_menu_list";
        };
    };
}

export interface LocationByMenuResp {
    _embedded: {
        locations: LocationMenu[];
    };
    _links: {
        next?: {
            href: string;
            type: "application/hal+json; name=adminlocation_menu_list";
        };

        self: {
            href: string;
            type: "application/hal+json; name=adminlocation_menu_list";
        };
    };
}

export type LocationMenuReq = Omit<LocationMenu, "_links" | "_embedded">;

interface LocationMenuPageFetch {
    type: typeof LOCATION_MENU_PAGE_FETCH;
    url: string;
}

interface LocationByMenuExportFetch {
    type: typeof LOCATION_BY_MENU_EXPORT_FETCH;
    url: string;
    callback?: (error?: Error) => void;
}

interface LocationMenuPageFetchSuccess {
    type: typeof LOCATION_MENU_PAGE_FETCH_SUCCESS;
    menus: LocationMenu[];
    locationId: string;
}

interface LocationMenuPageFetchError {
    type: typeof LOCATION_MENU_PAGE_FETCH_ERROR;
    error: Error;
}

interface LocationMenuSave {
    type: typeof LOCATION_MENU_SAVE;
    menu: LocationMenu;
    url: string;
    callback?: (error?: Error) => void;
}

interface LocationMenuSaveSuccess {
    type: typeof LOCATION_MENU_SAVE_SUCCESS;
    menu: LocationMenu;
    locationId: string;
}

interface LocationMenuSaveError {
    type: typeof LOCATION_MENU_SAVE_ERROR;
    error: Error;
}

interface LocationMenuOnboard extends SaveAction {
    type: typeof LOCATION_MENU_ONBOARD;
    url: string;
    locationMenus: LocationMenuOnboardReq[];
}

interface LocationMenuApplicationPageFetch {
    type: typeof LOCATION_MENU_APPLICATION_PAGE_FETCH;
    url: string;
}

interface LocationMenuApplicationPageFetchSuccess {
    type: typeof LOCATION_MENU_APPLICATION_PAGE_FETCH_SUCCESS;
    menuId: string;
    applications: LocationMenuApplication[];
}

interface LocationMenuApplicationPageFetchError {
    type: typeof LOCATION_MENU_APPLICATION_PAGE_FETCH_ERROR;
    error: Error;
}

export type LocationMenuActions =
    | LocationMenuPageFetch
    | LocationMenuPageFetchSuccess
    | LocationMenuPageFetchError
    | LocationMenuSave
    | LocationMenuSaveSuccess
    | LocationMenuSaveError
    | LocationMenuOnboard
    | LocationByMenuExportFetch
    | LocationMenuApplicationPageFetch
    | LocationMenuApplicationPageFetchSuccess
    | LocationMenuApplicationPageFetch
    | LocationMenuApplicationPageFetchSuccess
    | LocationMenuApplicationPageFetchError;

export interface AdminlocationReset {
    type: typeof ADMINLOCATION_RESET;
}
