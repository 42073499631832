import { AxiosResponse } from "axios";
import PaperWrapper from "components/common/PaperWrapper";
import { Decorator, SubmissionErrors } from "final-form";
import debounce from "lodash/debounce";
import { Autocomplete } from "mui-rff";
import React, { useEffect, useMemo, useState } from "react";
import { Form } from "react-final-form";
import { core } from "services/api";
import { searchDebounce } from "services/env";
import { getSession } from "services/session";
import { mapModifierRespToDomain } from "store/mms/menus/master/items/modifiers/effects";
import { MasterModifier, MasterModifierPageResp } from "store/mms/menus/master/items/modifiers/types";
import { getEntityPOSConfig } from "store/mms/types";
import { default as createSubmitListenerDecorator } from "final-form-submit-listener";
import { Alert, AutocompleteInputChangeReason, Theme } from "@mui/material";
import { makeStyles } from "theme";
import { FormSubmit } from "components/common/FormSubmit";
import { axiosErrorMessage } from "store/types";

const useStyles = makeStyles()((theme: Theme) => ({
    footer: {
        marginTop: theme.spacing(8),
        textAlign: "end",
    },
    submitErrors: {
        margin: theme.spacing(4, 0),
    },
}));
const s = getSession();
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapToFormErrors = (error?: any): SubmissionErrors | undefined => {
    if (error !== undefined) {
        return { modifier: axiosErrorMessage(error) };
    }
};

interface ModifierAddExistingProps {
    menuId: string;
    onClose(): void;
    onSave?(modifier: MasterModifier, callback?: (error?: Error) => void): void;
}

interface formValues {
    modifier: MasterModifier;
}

export function ModifierAddExisting({ menuId, onClose, onSave }: ModifierAddExistingProps): JSX.Element {
    const { classes } = useStyles();
    const searchUrl = `/1.1/accounts/${s?.accountId}/mms/menus/${menuId}/modifiers?search=`;
    const [state, setState] = useState<{
        options: MasterModifier[];
        searching: boolean;
        query: string;
    }>({
        options: [],
        searching: false,
        query: "",
    });
    const submitListener = useMemo(
        () =>
            createSubmitListenerDecorator({
                afterSubmitSucceeded: onClose,
            }),
        [onClose],
    );
    const search = useMemo(
        () =>
            debounce(async (isMounted: () => boolean, query: string) => {
                try {
                    const resp: AxiosResponse<MasterModifierPageResp> = await core.get(searchUrl + query);

                    if (!isMounted()) {
                        return;
                    }

                    setState((prevState) => ({
                        ...prevState,
                        searching: false,
                        options: resp.data._embedded.modifiers.map((m) => mapModifierRespToDomain(m)),
                    }));
                } catch (e) {
                    console.error("Failed to search for Menu Modifiers", e);
                    setState((prevState) => ({ ...prevState, searching: false }));
                }
            }, searchDebounce),
        [searchUrl],
    );
    const handleSearch = (event: unknown, value: string, reason: AutocompleteInputChangeReason) => {
        if (reason === "reset") {
            return;
        }

        setState((prevState) => ({ ...prevState, query: value, searching: true }));
    };

    useEffect(() => {
        if (!state.searching) {
            return;
        }

        let mounted = true;
        search(() => mounted, state.query);

        return () => {
            mounted = false;
        };
    }, [search, state.query, state.searching]);

    return (
        <Form
            decorators={[submitListener as Decorator<formValues, formValues>]}
            onSubmit={(values, form, errorsCallback) =>
                onSave &&
                onSave(values.modifier, (error?: Error) => {
                    if (errorsCallback !== undefined) {
                        errorsCallback(mapToFormErrors(error));
                    }
                })
            }
            validate={(values) => {
                const errors: { modifier?: string } = {};

                if (!values.modifier) {
                    errors.modifier = "Required";
                }

                return errors;
            }}
        >
            {({ handleSubmit, submitError }) => (
                <form onSubmit={handleSubmit}>
                    <Autocomplete
                        name="modifier"
                        label="Search Menu Modifiers"
                        helperText="Search by Modifier name."
                        onInputChange={handleSearch}
                        options={state.options}
                        getOptionLabel={(o) => {
                            if (o) {
                                const config = getEntityPOSConfig(o.pos_config);

                                return `${o.display_name}${o.reference_name ? ` (${o.reference_name})` : ""}${
                                    config ? ` - POS ID ${config.id}` : ""
                                }`;
                            }
                            return "";
                        }}
                        textFieldProps={{
                            title: "Search Menu Modifiers",
                            variant: "outlined",
                            autoFocus: true,
                        }}
                        showError={({ meta: { submitError, dirtySinceLastSubmit, error, touched } }) => {
                            return !!(((submitError && !dirtySinceLastSubmit) || error) && touched);
                        }}
                        PaperComponent={PaperWrapper}
                        loading={state.searching}
                        fullWidth
                        freeSolo
                    />
                    {submitError && (
                        <Alert className={classes.submitErrors} severity="error">
                            {submitError}
                        </Alert>
                    )}

                    <FormSubmit className={classes.footer} onClose={onClose} label="Add Modifier" />
                </form>
            )}
        </Form>
    );
}
