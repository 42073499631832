import { Typography, Alert } from "@mui/material";
import ConfirmDialog from "components/common/ConfirmDialog";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { masterItemImageDelete } from "store/mms/menus/master/items/actions";
import { MasterItem } from "store/mms/menus/master/items/types";
import { axiosErrorMessage } from "store/types";

interface ItemImageDeleteProps {
    onClose(): void;
    open: boolean;
    item: MasterItem;
}

export function ItemImageDelete({ onClose, open, item }: ItemImageDeleteProps): JSX.Element {
    const dispatch = useDispatch();
    const [state, setState] = useState<{ deleting: boolean; error?: string }>({
        deleting: false,
    });

    return (
        <ConfirmDialog
            title="Remove Image"
            open={open}
            onClose={onClose}
            onConfirm={() => {
                setState(() => ({ error: undefined, deleting: true }));
                dispatch(
                    masterItemImageDelete(item, (error?: Error): void => {
                        if (error) {
                            return setState(() => ({
                                deleting: false,
                                error: axiosErrorMessage(error),
                            }));
                        }
                        setState(() => ({
                            deleting: false,
                            error: undefined,
                        }));
                        onClose();
                    }),
                );
            }}
            confirm="Remove Image"
            confirming={state.deleting}
            maxWidth="xl"
            transitionDuration={0}
        >
            <Typography>
                Are you sure you want to remove the image from this Menu Item? This cannot be undone.
            </Typography>
            <Alert variant="outlined" severity="info">
                This change will affect <strong>all Locations</strong>.
            </Alert>
            {state.error && (
                <Alert variant="outlined" severity="error">
                    {state.error}
                </Alert>
            )}
        </ConfirmDialog>
    );
}
