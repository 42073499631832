import {
    POSInjectionSuccessRateReport,
    REPORTS_FETCH_SUCCESS,
    REPORTS_FETCH_ERROR,
    REPORTS_FETCH,
    REPORTS_FILTERS_FETCH,
    REPORTS_FILTERS_FETCH_SUCCESS,
    REPORTS_FILTERS_FETCH_ERROR,
    REPORTS_FILTERS_SET_AVAILABLE,
    REPORTS_FILTERS_SET_APPLIED,
    REPORTS_FILTERS_SET_START_DATETIME,
    REPORTS_FILTERS_SET_END_DATETIME,
    REPORTS_FILTERS_ADD_RESTAURANT_CONCEPT,
    REPORTS_FILTERS_REMOVE_RESTAURANT_CONCEPT,
    REPORTS_FILTERS_RESET_RESTAURANT_CONCEPT,
    REPORTS_FILTERS_ADD_POS_TYPE,
    REPORTS_FILTERS_REMOVE_POS_TYPE,
    REPORTS_FILTERS_RESET_POS_TYPE,
    ReportsActions,
    ReportFiltersActions,
    ReportsFilters,
    RestaurantConcept,
    POSType,
    REPORTS_FILTERS_ADD_LOCATION,
    REPORTS_FILTERS_REMOVE_LOCATION,
} from "./types";
import { format } from "date-fns";
import { Location } from "store/locations/types";

export function reportsFetchSuccess(report: POSInjectionSuccessRateReport): ReportsActions {
    return {
        type: REPORTS_FETCH_SUCCESS,
        payload: report,
    };
}

export function reportsFetchError(err: Error): ReportsActions {
    return {
        type: REPORTS_FETCH_ERROR,
        payload: err,
    };
}

export function reportsFetch(): ReportsActions {
    return {
        type: REPORTS_FETCH,
    };
}

export function reportsFiltersFetch(): ReportFiltersActions {
    return {
        type: REPORTS_FILTERS_FETCH,
    };
}

export function reportsFiltersFetchSuccess(): ReportFiltersActions {
    return {
        type: REPORTS_FILTERS_FETCH_SUCCESS,
    };
}

export function reportsFiltersFetchError(err: Error): ReportFiltersActions {
    return {
        type: REPORTS_FILTERS_FETCH_ERROR,
        payload: err,
    };
}

export function reportsFiltersSetAvailable(filters: ReportsFilters): ReportFiltersActions {
    return {
        type: REPORTS_FILTERS_SET_AVAILABLE,
        payload: filters,
    };
}

export function reportsFiltersSetApplied(filters: ReportsFilters): ReportFiltersActions {
    return {
        type: REPORTS_FILTERS_SET_APPLIED,
        payload: filters,
    };
}

export function reportsFiltersAddRestaurantConcept(concept: RestaurantConcept): ReportFiltersActions {
    return {
        type: REPORTS_FILTERS_ADD_RESTAURANT_CONCEPT,
        payload: concept,
    };
}

export function reportsFiltersRemoveRestaurantConcept(concept: RestaurantConcept): ReportFiltersActions {
    return {
        type: REPORTS_FILTERS_REMOVE_RESTAURANT_CONCEPT,
        payload: concept,
    };
}

export function reportsFiltersAddLocation(location: Location): ReportFiltersActions {
    return {
        type: REPORTS_FILTERS_ADD_LOCATION,
        payload: location,
    };
}

export function reportsFiltersRemoveLocation(location: Location): ReportFiltersActions {
    return {
        type: REPORTS_FILTERS_REMOVE_LOCATION,
        payload: location,
    };
}

export function reportsFiltersAddPOSType(posType: POSType): ReportFiltersActions {
    return {
        type: REPORTS_FILTERS_ADD_POS_TYPE,
        payload: posType,
    };
}

export function reportsFiltersRemovePOSType(posType: POSType): ReportFiltersActions {
    return {
        type: REPORTS_FILTERS_REMOVE_POS_TYPE,
        payload: posType,
    };
}

export function reportsFiltersSetStartDatetime(start: Date): ReportFiltersActions {
    return {
        type: REPORTS_FILTERS_SET_START_DATETIME,
        payload: format(new Date(start.toUTCString().substring(0, 26)), "yyyy-MM-dd HH:mm:ss"),
    };
}

export function reportsFiltersSetEndDatetime(end: Date): ReportFiltersActions {
    return {
        type: REPORTS_FILTERS_SET_END_DATETIME,
        payload: format(new Date(end.toUTCString().substring(0, 26)), "yyyy-MM-dd HH:mm:ss"),
    };
}

export function reportsFiltersClearRestaurantConcept(): ReportFiltersActions {
    return {
        type: REPORTS_FILTERS_RESET_RESTAURANT_CONCEPT,
    };
}

export function reportsFiltersResetPOSType(): ReportFiltersActions {
    return {
        type: REPORTS_FILTERS_RESET_POS_TYPE,
    };
}
