import React from "react";
import { ListItemSecondaryAction as MuiListItemSecondaryAction, ListItemSecondaryActionProps } from "@mui/material";

export function ListItemSecondaryAction({ children, sx = [] }: ListItemSecondaryActionProps): JSX.Element {
    return (
        <MuiListItemSecondaryAction
            sx={[
                (theme) => ({
                    marginTop: theme.spacing(0.75),
                    right: "unset",
                    top: "unset",
                    position: "initial",
                    display: "flex",
                    alignItems: "center",
                    transform: "unset",
                    flex: "none",
                    marginRight: theme.spacing(1),

                    "&:last-of-type": {
                        marginRight: 0,
                    },

                    "& button": {
                        marginRight: theme.spacing(1),

                        "&:last-child": {
                            marginRight: 0,
                        },
                    },
                }),
                ...(Array.isArray(sx) ? sx : [sx]),
            ]}
            onClick={(e) => e.stopPropagation()}
        >
            {children}
        </MuiListItemSecondaryAction>
    );
}
