import { Grid, Typography, Theme, Skeleton } from "@mui/material";
import { makeStyles } from "theme";
import { EntityDetails } from "components/common/forms/EntityDetails";
import DefaultLayout from "components/common/layouts/DefaultLayout";
import { OmniDrawer } from "components/common/OmniDrawer";
import { EntityAddModifierGroup } from "components/mms/EntityAddModifierGroup";
import { ModifierDelete } from "components/mms/Modifiers/ModifierDelete";
import { ModifierEditPOSConfig } from "components/mms/Modifiers/ModifierEditPOSConfig";
import { ModifierVisibility } from "components/mms/Modifiers/ModifierVisibility";
import NutritionFactsDelete from "components/mms/NutritionFacts/NutritionFactsDelete";
import NutritionFactsDisplay from "components/mms/NutritionFacts/NutritionFactsDisplay";
import NutritionFactsEdit from "components/mms/NutritionFacts/NutritionFactsEdit";
import { OptionSetDelete } from "components/mms/OptionSets/OptionSetDelete";
import { MasterOptionSetList } from "components/mms/OptionSets/MasterOptionSets";
import { OptionSetDefaultModifier } from "components/mms/OptionSets/OptionSetDefaultModifier";
import { OptionSetVisibility } from "components/mms/OptionSets/OptionSetVisibility";
import { Sidebar } from "components/Sidebar";
import { getBreadcrumbSkeleton, MainContentSkeleton } from "components/skeletons";
import useToggle from "helpers/useToggle";
import { debounce } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useParams } from "react-router-dom";
import { apiBaseUrl, searchDebounce } from "services/env";
import { getSession } from "services/session";
import { menuFetch } from "store/mms/menus/master/actions";
import { masterItemFetch } from "store/mms/menus/master/items/actions";
import { masterModifierOptionSetsSave, masterModifierSave } from "store/mms/menus/master/items/modifiers/actions";
import { MasterModifier } from "store/mms/menus/master/items/modifiers/types";
import { ModifierGroup } from "store/mms/menus/master/items/option-sets/modifier-group/types";
import { OptionSet } from "store/mms/menus/master/items/option-sets/types";
import { RootState } from "store/rootReducer";
import { DetailsSidebarSection } from "./helpers";
import { ActionButton, ActionButtonContainer } from "components/common/buttons/ActionButton";
import { EntityEdit } from "components/mms/IconButtons/EntityEdit";
import { EntityRemove } from "components/mms/IconButtons/EntityRemove";
import { Header } from "./Header";

const useMenuModifierStyle = makeStyles()((theme: Theme) => ({
    container: {
        position: "relative",
    },
    title: {
        margin: theme.spacing(0, 0, 2, 0),
    },
    details: {
        marginBottom: theme.spacing(3),
    },
    nutrition: {
        marginBottom: theme.spacing(3),
    },
    addGroup: {
        position: "absolute",
        right: 0,
        top: 15,
        zIndex: 2,
    },
    optionSetList: {
        "& > .MuiListSubheader-root": {
            paddingTop: theme.spacing(2),

            "& > .MuiTypography-root": {
                marginBottom: theme.spacing(2),
            },
        },
    },
    modifiers: {
        marginTop: theme.spacing(2),
    },
    items: {
        marginTop: theme.spacing(2),
    },
    image: {
        height: 200,
        width: 200,
    },
    imageActions: {
        justifyContent: "space-evenly",
    },
}));

export default function MenuModifier(): JSX.Element {
    const { classes } = useMenuModifierStyle();
    const s = getSession();
    const dispatch = useDispatch();
    const { menuId, itemId, modifierId } = useParams() as {
        menuId: string;
        itemId: string;
        modifierId: string;
    };
    const menusById = useSelector((state: RootState) => state.mms.menus.master.menus.byId);
    const menusFetching = useSelector((state: RootState) => state.mms.menus.master.menus.fetching);
    const itemsById = useSelector((state: RootState) => state.mms.menus.master.items.byId);
    const itemsFetching = useSelector((state: RootState) => state.mms.menus.master.items.fetching);
    const modsByHref = useSelector((state: RootState) => state.mms.menus.master.modifiers.byHref);
    const groupsById = useSelector((state: RootState) => state.mms.menus.master.modifierGroups.byId);
    const optionSetIdsByParentId = useSelector((state: RootState) => state.mms.menus.master.optionSets.idsByParentId);
    const optionSetsById = useSelector((state: RootState) => state.mms.menus.master.optionSets.byId);
    const [editDetails, setEditDetails] = useState(false);
    const [editNutrition, toggleEditNutrition] = useToggle();
    const [deleteNutrition, toggleDeleteNutrition] = useToggle();
    const [addModifierGroup, setAddModifierGroup] = useState<MasterModifier | undefined>(undefined);
    const [deleteOS, setDeleteOS] = useState<{ optionSet: OptionSet; parent: MasterModifier } | undefined>(undefined);
    const [osVisibility, setOSVisibility] = useState<OptionSet | undefined>(undefined);
    const [modifierVisibility, setModifierVisibility] = useState<MasterModifier | undefined>(undefined);
    const [editModifier, setEditModifier] = useState<MasterModifier | undefined>(undefined);
    const [osDefaultModifier, setOSDefaultModifier] = useState<
        { optionSet: OptionSet; modifier: MasterModifier; isDefault: boolean } | undefined
    >(undefined);
    const [deleteModifier, setDeleteModifier] = useState<
        { modifier: MasterModifier; group: ModifierGroup } | undefined
    >(undefined);
    const accountUrl = `/1.1/accounts/${s?.accountId}`;
    const menuUrl = `${accountUrl}/mms/menus/${menuId}`;
    const itemUrl = `${menuUrl}/items/${itemId}`;
    const modifierUrl = `${apiBaseUrl}${menuUrl}/modifiers/${modifierId}`;
    const masterMenu = menusById[menuId];
    const item = itemsById[itemId];
    const modifier = modsByHref[modifierUrl];
    const isLoading =
        masterMenu === undefined || menusFetching || item === undefined || itemsFetching || modifier === undefined;

    const handleModifierAddGroup = (entity: MasterModifier) => setAddModifierGroup(entity);
    const handleResequenceOptionSets = useMemo(
        () =>
            debounce((optionSets: OptionSet[]) => {
                dispatch(masterModifierOptionSetsSave(modifier, optionSets));
            }, searchDebounce),
        [dispatch, modifier],
    );
    const handleOSDelete = (os: OptionSet, entity: MasterModifier) =>
        setDeleteOS({
            optionSet: os,
            parent: entity,
        });
    const handleOSVisibility = (os: OptionSet) => setOSVisibility(os);
    const handleModifierVisibility = (modifier: MasterModifier) => setModifierVisibility(modifier);
    const handleModifierEdit = (modifier: MasterModifier) => setEditModifier(modifier);
    const handleOSDefaultModifier = (optionSet: OptionSet, modifier: MasterModifier, isDefault: boolean) =>
        setOSDefaultModifier({
            optionSet,
            modifier,
            isDefault,
        });
    const handleModifierDelete = (group: ModifierGroup, modifier: MasterModifier) =>
        setDeleteModifier({ group, modifier });

    useEffect(() => {
        if (masterMenu === undefined) {
            dispatch(menuFetch(menuUrl));
        }

        if (item === undefined || modifier === undefined) {
            dispatch(masterItemFetch(itemUrl));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemUrl]);

    const breadcrumbs = isLoading
        ? getBreadcrumbSkeleton()
        : [
              <RouterLink key="menu" to={`/menus/${masterMenu.id}`}>
                  {masterMenu.name}
              </RouterLink>,
              <RouterLink key="item" to={`/menus/${masterMenu.id}/items/${item.id}`}>
                  {item.display_name}
              </RouterLink>,
              <Typography key="modifier" color="textPrimary">
                  {modifier.display_name}
              </Typography>,
          ];

    return (
        <DefaultLayout
            aside={
                <Sidebar>
                    <DetailsSidebarSection
                        entityId={modifierId}
                        entity={modifier}
                        onChangeVisibility={(visible) =>
                            dispatch(masterModifierSave({ ...modifier, enabled: visible }, modifierUrl))
                        }
                        onEditPOSConfig={() => setEditModifier(modifier)}
                    />
                </Sidebar>
            }
            breadcrumbs={[
                <RouterLink key="menus" to="/menus">
                    Menus
                </RouterLink>,
                ...breadcrumbs,
            ]}
            header={isLoading ? <Skeleton /> : <Header menuName={masterMenu.name} />}
        >
            {isLoading ? (
                <MainContentSkeleton />
            ) : (
                <>
                    <Grid container justifyContent="space-between" className={classes.details}>
                        <Grid item xs={12}>
                            <Grid container spacing={3} wrap="nowrap" justifyContent="space-between">
                                <Grid item>
                                    <Grid container alignItems="center">
                                        <Grid item>
                                            <Typography variant="h4">{modifier.display_name}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <EntityEdit
                                                sx={{ marginLeft: (theme) => theme.spacing(2) }}
                                                title="Rename Modifier Group"
                                                onClick={() => setEditDetails(true)}
                                            />
                                        </Grid>
                                    </Grid>

                                    {modifier._embedded.nutrition && (
                                        <Grid
                                            sx={{ marginTop: (theme) => theme.spacing(4) }}
                                            container
                                            direction="column"
                                        >
                                            <Grid container>
                                                <Typography variant="h6">Nutrition Facts</Typography>
                                                <>
                                                    <EntityEdit
                                                        sx={{ marginLeft: (theme) => theme.spacing(2) }}
                                                        title="Edit Nutrition Facts"
                                                        onClick={toggleEditNutrition}
                                                    />
                                                    <EntityRemove
                                                        title="Remove Nutrition Facts"
                                                        onClick={toggleDeleteNutrition}
                                                    />
                                                </>
                                            </Grid>
                                            <NutritionFactsDisplay
                                                className={classes.nutrition}
                                                nutrition={modifier._embedded.nutrition}
                                            />
                                        </Grid>
                                    )}
                                </Grid>

                                <Grid item>
                                    <ActionButtonContainer>
                                        {!modifier._embedded.nutrition && (
                                            <ActionButton label="Add Nutrition Facts" onClick={toggleEditNutrition} />
                                        )}
                                    </ActionButtonContainer>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <div className={classes.container}>
                        <ActionButton
                            label="Add Modifier Group"
                            onClick={() => handleModifierAddGroup(modifier)}
                            className={classes.addGroup}
                        />

                        <MasterOptionSetList
                            className={classes.optionSetList}
                            itemId={itemId}
                            modsByHref={modsByHref}
                            onModifierAddGroup={handleModifierAddGroup}
                            onModifierDelete={handleModifierDelete}
                            onModifierEdit={handleModifierEdit}
                            onModifierVisibility={handleModifierVisibility}
                            onOptionSetVisibility={handleOSVisibility}
                            onOptionSetDelete={handleOSDelete}
                            onOptionSetDefaultModifier={handleOSDefaultModifier}
                            onResequenceOptionSets={handleResequenceOptionSets}
                            modGroupsById={groupsById}
                            optionSetIdsByModifierId={optionSetIdsByParentId}
                            optionSetsById={optionSetsById}
                            masterEntity={modifier}
                        />
                    </div>

                    <OmniDrawer close={() => setEditDetails(false)} open={editDetails} title="Edit Modifier">
                        {editDetails && (
                            <EntityDetails
                                display_name={modifier.display_name}
                                reference_name={modifier.reference_name}
                                submitLabel="Update Modifier"
                                onClose={() => setEditDetails(false)}
                                onSave={(display_name, reference_name, unused, callback) =>
                                    dispatch(
                                        masterModifierSave(
                                            { ...modifier, display_name, reference_name },
                                            modifierUrl,
                                            callback,
                                        ),
                                    )
                                }
                            />
                        )}
                    </OmniDrawer>

                    {/* Modifier Add Modifer Group */}
                    <EntityAddModifierGroup
                        entity={addModifierGroup}
                        itemId={itemId}
                        menuId={menuId}
                        onClose={() => setAddModifierGroup(undefined)}
                        open={Boolean(addModifierGroup)}
                    />

                    {/* Edit Modifier POS Settings/Relink */}
                    <ModifierEditPOSConfig
                        item={item}
                        modifier={editModifier}
                        onClose={() => setEditModifier(undefined)}
                        open={Boolean(editModifier)}
                    />

                    <NutritionFactsEdit open={editNutrition} entity={modifier} onClose={toggleEditNutrition} />
                    {deleteNutrition && (
                        <NutritionFactsDelete
                            entity={modifier}
                            onClose={toggleDeleteNutrition}
                            open={deleteNutrition}
                        />
                    )}

                    {/* Delete Option Set */}
                    {deleteOS && (
                        <OptionSetDelete
                            onClose={() => setDeleteOS(undefined)}
                            open={Boolean(deleteOS)}
                            {...deleteOS}
                        />
                    )}

                    {/* Edit Option Set Visibility */}
                    {osVisibility && (
                        <OptionSetVisibility
                            menuName={masterMenu.name}
                            onClose={() => setOSVisibility(undefined)}
                            open={Boolean(osVisibility)}
                            optionSet={osVisibility}
                        />
                    )}

                    {/* Edit Modifier Visibility */}
                    {modifierVisibility && (
                        <ModifierVisibility
                            modifier={modifierVisibility}
                            onClose={() => setModifierVisibility(undefined)}
                            open={Boolean(modifierVisibility)}
                        />
                    )}

                    {/* Option Set Default Modifier */}
                    {osDefaultModifier && (
                        <OptionSetDefaultModifier
                            onClose={() => setOSDefaultModifier(undefined)}
                            open={Boolean(osDefaultModifier)}
                            {...osDefaultModifier}
                        />
                    )}

                    {/* Delete Modifier from Group */}
                    {deleteModifier && (
                        <ModifierDelete
                            onClose={() => setDeleteModifier(undefined)}
                            open={Boolean(deleteModifier)}
                            {...deleteModifier}
                        />
                    )}
                </>
            )}
        </DefaultLayout>
    );
}
