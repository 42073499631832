import { MenuLayerRule, NewMenuLayerRule, RuleActions, RULE_DELETE, RULE_SAVE } from "./types";

export function ruleSave(
    url: string,
    rule: MenuLayerRule | NewMenuLayerRule,
    callback?: (error?: Error) => void,
): RuleActions {
    return {
        type: RULE_SAVE,
        url,
        rule,
        callback,
    };
}

export function ruleDelete(rule: MenuLayerRule, callback?: (error?: Error) => void): RuleActions {
    return {
        type: RULE_DELETE,
        rule,
        callback,
    };
}
