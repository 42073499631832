import { Box, Checkbox, FormControl, FormLabel, Grid, TextField, Typography, useTheme } from "@mui/material";
import { Accordion } from "components/common/forms/Accordion";
import PaperWrapper from "components/common/PaperWrapper";
import { sub } from "date-fns";
import { getIn } from "final-form";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider, DatePicker, DateTimePicker } from "@mui/lab";
import { Autocomplete } from "mui-rff";
import React from "react";
import { Field, useFormState } from "react-final-form";
import { useSelector } from "react-redux";
import { RootState } from "store/rootReducer";
import {
    enableConstraintsLocalStartEnd,
    enableConstraintsOrderProfiles,
    constraintsOrderProfiles,
    constraintsLocalStart,
    constraintsLocalEnd,
    ruleType,
} from "./constants";
import { useConfigurationsStyles } from "./styles";

export function Constraints(): JSX.Element {
    const theme = useTheme();
    const { classes } = useConfigurationsStyles();
    const orderProfiles = useSelector((state: RootState) => state.mms.orderProfiles.profiles);
    const { values } = useFormState();
    const minDate = sub(new Date(), { months: 1 });

    return (
        <Box className={classes.root}>
            <Typography variant="h5">Conditions</Typography>
            <Typography variant="subtitle1">Select Order Profile(s) and Active Time to apply to the Rules.</Typography>

            <Accordion label="Order Profile" name={enableConstraintsOrderProfiles}>
                <FormControl component="fieldset">
                    <FormLabel component="legend">
                        Choose any number of Order Profiles to use as a condition for the Rules below. The Rules will
                        only apply to the chosen Order Profiles. If no Order Profiles are chosen, the Rules will always
                        apply.
                    </FormLabel>

                    <Autocomplete
                        id="constraints-order-profiles"
                        name={constraintsOrderProfiles}
                        label="Order Profile"
                        options={orderProfiles}
                        getOptionLabel={(o) => {
                            return `${o.name} (${o.id})`;
                        }}
                        getOptionValue={(o) => o.id}
                        isOptionEqualToValue={(o, v) => o.id === v.id}
                        renderOption={(props, o, { selected }) => (
                            <li {...props}>
                                <Checkbox style={{ marginRight: theme.spacing(1) }} checked={selected} />
                                {`${o.name} (${o.id})`}
                            </li>
                        )}
                        textFieldProps={{
                            variant: "outlined",
                        }}
                        PaperComponent={PaperWrapper}
                        ChipProps={{
                            color: "primary",
                        }}
                        disabled={!getIn(values, enableConstraintsOrderProfiles) || false}
                        fieldProps={{
                            validate: (v, allValues) =>
                                getIn(allValues, enableConstraintsOrderProfiles) && Array.isArray(v) && v.length === 0
                                    ? "Required"
                                    : undefined,
                        }}
                        multiple
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        disableCloseOnSelect
                    />
                </FormControl>
            </Accordion>

            <Accordion label="Active Time" name={enableConstraintsLocalStartEnd}>
                <FormControl component="fieldset">
                    <FormLabel component="legend">
                        Set when the Rule should be active. All times are local to each Location.
                    </FormLabel>

                    <Grid container spacing={2}>
                        <Grid item>
                            <Field name={constraintsLocalStart}>
                                {({ input }) => (
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        {getIn(values, "ruleType") === ruleType.Periods ? (
                                            <DatePicker
                                                {...input}
                                                label="Start (Optional)"
                                                minDate={minDate}
                                                disabled={!getIn(values, enableConstraintsLocalStartEnd) || false}
                                                renderInput={(props) => <TextField {...props} error={false} />}
                                            />
                                        ) : (
                                            <DateTimePicker
                                                {...input}
                                                label="Start (Optional)"
                                                minDate={minDate}
                                                disabled={!getIn(values, enableConstraintsLocalStartEnd) || false}
                                                renderInput={(props) => <TextField {...props} error={false} />}
                                            />
                                        )}
                                    </LocalizationProvider>
                                )}
                            </Field>
                        </Grid>
                        <Grid item>
                            <Field name={constraintsLocalEnd}>
                                {({ input }) => (
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        {getIn(values, "ruleType") === ruleType.Periods ? (
                                            <DatePicker
                                                {...input}
                                                label="End (Optional)"
                                                minDate={minDate}
                                                disabled={!getIn(values, enableConstraintsLocalStartEnd) || false}
                                                renderInput={(props) => <TextField {...props} error={false} />}
                                            />
                                        ) : (
                                            <DateTimePicker
                                                {...input}
                                                label="End (Optional)"
                                                minDate={minDate}
                                                disabled={!getIn(values, enableConstraintsLocalStartEnd) || false}
                                                renderInput={(props) => <TextField {...props} error={false} />}
                                            />
                                        )}
                                    </LocalizationProvider>
                                )}
                            </Field>
                        </Grid>
                    </Grid>
                </FormControl>
            </Accordion>
        </Box>
    );
}
