import { getSession } from "services/session";

// https://support.pendo.io/hc/en-us/articles/360046272771-Developer-s-Guide-To-Installing-Pendo#TheInitialization
interface PendoPayload {
    apiKey: string;

    visitor: {
        id?: string;
        email?: string; // Recommended if using Pendo Feedback, or NPS Email
        full_name?: string; // Recommended if using Pendo Feedback
        role?: string; // Optional

        // You can add any additional visitor level key-values here,
        // as long as it's not one of the above reserved names.
    };

    account: {
        id?: string; // Highly recommended
        name?: string; // Optional
        is_paying?: boolean; // Recommended if using Pendo Feedback
        monthly_value?: number; // Recommended if using Pendo Feedback
        planLevel?: number; // Optional
        planPrice?: number; // Optional
        creationDate?: Date; // Optional

        // You can add any additional account level key-values here,
        // as long as it's not one of the above reserved names.
    };
}

declare global {
    interface Window {
        pendo: {
            identify(p: PendoPayload): void;
            initialize(p: PendoPayload): void;
        };
    }
}

export default function initAnalytics(): void {
    try {
        const s = getSession();

        if (s === undefined) {
            throw { message: "Can't get session for Account/User info, bailing out." };
        }

        window.pendo.initialize({
            apiKey: "a50efbcb-1bf2-42d6-4676-c8e5aebfc628",
            account: {
                id: s.accountId,
            },
            visitor: {
                id: s.userId,
            },
        });
    } catch (e) {
        console.error("Analytics error", e);
    }
}
