import { TextField, Theme } from "@mui/material";
import { makeStyles } from "theme";
import clsx from "clsx";
import React from "react";
import { Field } from "react-final-form";
import { handleErrors } from "./helpers";

const useStyles = makeStyles()((theme: Theme) => ({
    root: {
        padding: 0,
        border: "none",
        "& > *": {
            marginBottom: theme.spacing(4),
        },

        "& > *:last-child": {
            marginBottom: 0,
        },
    },
}));

interface RenameFieldsetProps {
    className?: string;
}

export function RenameFieldset({ className }: RenameFieldsetProps): JSX.Element {
    const { classes } = useStyles();

    return (
        <fieldset className={clsx(classes.root, className)}>
            <Field name="display_name">
                {({ input, meta }) => (
                    <TextField
                        {...input}
                        {...handleErrors(meta, "Customers will see this name on the Menu.")}
                        label="Display Name"
                        variant="outlined"
                        fullWidth
                        autoFocus
                    />
                )}
            </Field>

            <Field name="reference_name">
                {({ input, meta }) => (
                    <TextField
                        {...input}
                        {...handleErrors(meta, "Internal use only — will not appear on the Menu.")}
                        label="Reference Name"
                        variant="outlined"
                        fullWidth
                    />
                )}
            </Field>
        </fieldset>
    );
}
