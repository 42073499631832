import { CLOSE_SNACKBAR, ENQUEUE_SNACKBAR, NotificationActions, Notification, REMOVE_SNACKBAR } from "./types";

interface NotificationsState {
    notifications: Notification[];
}

const initialState: NotificationsState = {
    notifications: [],
};

export function notificationsReducer(state = initialState, action: NotificationActions): NotificationsState {
    switch (action.type) {
        case ENQUEUE_SNACKBAR:
            return {
                ...state,
                notifications: [...state.notifications, action.notification],
            };

        case CLOSE_SNACKBAR:
            return {
                ...state,
                notifications: state.notifications.map((notification) =>
                    action.dismissAll || notification.key === action.key
                        ? { ...notification, dismissed: true }
                        : { ...notification },
                ),
            };

        case REMOVE_SNACKBAR:
            return {
                ...state,
                notifications: state.notifications.filter((notification) => notification.key !== action.key),
            };

        default:
            return state;
    }
}
