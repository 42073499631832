import { Typography } from "@mui/material";
import ConfirmDialog from "components/common/ConfirmDialog";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { locationItemSave } from "store/mms/menus/location/items/actions";
import { LocationItem } from "store/mms/menus/location/items/types";

interface LocationItemVisibilityProps {
    item: LocationItem;
    onClose(): void;
    open: boolean;
}

export const LocationItemVisibility = ({ item, open, onClose }: LocationItemVisibilityProps): JSX.Element => {
    const dispatch = useDispatch();
    const [state, setState] = useState<{ saving: boolean; error?: string }>({
        saving: false,
    });
    return (
        <ConfirmDialog
            title={`${item.enabled ? "Hide" : "Display"} Item`}
            open={open}
            onClose={onClose}
            onConfirm={() => {
                setState((prevState) => ({
                    ...prevState,
                    savingItem: true,
                }));
                dispatch(
                    locationItemSave({ ...item, enabled: !item.enabled }, item._links.self.href, () =>
                        setState((prevState) => ({
                            ...prevState,
                            itemVisibility: undefined,
                            savingItem: false,
                        })),
                    ),
                );
                onClose();
            }}
            confirm={`${item.enabled ? "Hide" : "Display"} Item`}
            confirming={state.saving}
            transitionDuration={0}
        >
            <Typography gutterBottom>
                Are you sure that you want to {item.enabled ? "hide" : "display"} the Item{" "}
                <strong>{item.display_name}</strong>?
            </Typography>
        </ConfirmDialog>
    );
};
