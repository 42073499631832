import { Location } from "store/locations/types";
import { ListRoute } from "store/types";

export const ORDER_PROFILES_FETCH = "@@ORDER-PROFILES/FETCH";
export const ORDER_PROFILES_FETCH_SUCCESS = "@@ORDER-PROFILES/FETCH_SUCCESS";
export const ORDER_PROFILES_FETCH_ERROR = "@@ORDER-PROFILES/FETCH_ERROR";

export const ORDER_PROFILE_SAVE = "@@ORDER-PROFILE/SAVE";
export const ORDER_PROFILE_SAVE_SUCCESS = "@@ORDER-PROFILE/SAVE_SUCCESS";
export const ORDER_PROFILE_SAVE_ERROR = "@@ORDER-PROFILE/SAVE_ERROR";

export const ORDER_PROFILE_DELETE = "@@ORDER-PROFILE/DELETE";
export const ORDER_PROFILE_DELETE_SUCCESS = "@@ORDER-PROFILE/DELETE_SUCCESS";

export const LOCATION_ORDER_PROFILE_SAVE = "@@LOCATION-ORDER-PROFILE/SAVE";
export const LOCATION_ORDER_PROFILE_SAVE_SUCCESS = "@@LOCATION-ORDER-PROFILE/SAVE_SUCCESS";
export const LOCATION_ORDER_PROFILE_SAVE_ERROR = "@@LOCATION-ORDER-PROFILE/SAVE_ERROR";

export const LOCATION_ORDER_PROFILE_DELETE = "@@LOCATION-ORDER-PROFILE/DELETE";
export const LOCATION_ORDER_PROFILE_DELETE_SUCCESS = "@@LOCATION-ORDER-PROFILE/DELETE_SUCCESS";
export const LOCATION_ORDER_PROFILE_DELETE_ERROR = "@@LOCATION-ORDER-PROFILE/DELETE_ERROR";

export const ORDER_PROFILE_FETCH = "@@ORDER-PROFILE/FETCH";
export const ORDER_PROFILE_FETCH_SUCCESS = "@@ORDER-PROFILE/FETCH_SUCCESS";
export const ORDER_PROFILE_FETCH_ERROR = "@@ORDER-PROFILE/FETCH_ERROR";

export enum OrderProfileTypes {
    Delivery = "delivery",
    DineIn = "dine-in",
    Pickup = "pickup",
}

export enum OrderProfileAdjustmentStrategyTypes {
    TicketServiceCharge = "ticket-service-charge",
    TicketDiscount = "ticket-discount",
    ForcePriceAll = "forced-price-all",
}

export enum OrderProfileAdjustmentTypes {
    Dollar = "dollar",
    Percent = "percent",
}

export interface OrderProfileAdjustment {
    enabled: boolean;
    amount?: number;
    strategy?: OrderProfileAdjustmentStrategyTypes;
    type?: OrderProfileAdjustmentTypes;
}

interface orderProfileLinks {
    _links: {
        self: {
            href: string;
            type: "application/hal+json; name=mms_master_order_profile";
        };
    };
}

export interface OrderProfile extends orderProfileLinks {
    id?: string;
    name: string;
    type: OrderProfileTypes;
    locations: number;
    adjustment: OrderProfileAdjustment;
    locationOrderProfiles: LocationOrderProfile[];
}

export interface LocationOrderProfile {
    id?: string;
    autoClose: boolean | null;
    autoSend: boolean | null;
    linked: boolean;
    posConfig: {
        employeeId: string;
        orderTypeId: string;
        revenueCenterId: string;

        adjustmentId?: string;
        tenderTypeId?: string;
        itemOrderModesId?: string;
        accountNumber?: string;
    };
    ticketConfig: {
        holdViaReadyTimeAddition: number;
        omitPriceLevel: boolean;
        omitReadyTime: boolean;
        // TODO: remove null after updating API to not return this field if it is null (like the other nullable fields
        // in pos_config)
        guestCount?: number | null;
    };
    location: Location;
}

export interface LocationOrderProfileReq {
    id: string | undefined;
    auto_close: boolean | null;
    auto_send: boolean | null;
    linked: boolean;
    pos_config: {
        employee_id: string;
        order_type_id: string;
        revenue_center_id: string;

        account_number?: string;
        adjustment_id?: string;
        item_order_mode_id?: string;
        tender_type_id?: string;
    };
    ticket_config: {
        hold_via_ready_time_addition: number;
        omit_price_level: boolean;
        omit_ready_time: boolean;
        // TODO: remove null after updating API to not return this field if it is null (like the other nullable fields
        // in pos_config)
        guest_count?: number | null;
    };
    location: string;
}

export interface LocationOrderProfileResp {
    id: string;
    auto_close: boolean | null;
    auto_send: boolean | null;
    linked: boolean;
    pos_config: {
        employee_id: string;
        order_type_id: string;
        revenue_center_id: string;

        account_number?: string;
        adjustment_id?: string;
        item_order_mode_id?: string;
        tender_type_id?: string;
    };
    ticket_config: {
        hold_via_ready_time_addition: number;
        omit_price_level: boolean;
        omit_ready_time: boolean;
        // TODO: remove null after updating API to not return this field if it is null (like the other nullable fields
        // in pos_config)
        guest_count?: number | null;
    };
    _embedded: {
        location: Location;
    };
}

export interface OrderProfileResp extends orderProfileLinks {
    adjustment_amount?: number;
    adjustment_enabled: boolean;
    adjustment_strategy?: OrderProfileAdjustmentStrategyTypes;
    adjustment_type?: OrderProfileAdjustmentTypes;
    id: string;
    name: string;
    stats: {
        locations: number;
    };
    type: OrderProfileTypes;
    _embedded: {
        location_order_profiles: LocationOrderProfileResp[];
    };
}

export interface OrderProfilesPageResp extends ListRoute {
    _embedded: {
        order_profiles: OrderProfileResp[];
    };
}

interface OrderProfilesFetch {
    type: typeof ORDER_PROFILES_FETCH;
}

interface OrderProfilesFetchSuccess {
    type: typeof ORDER_PROFILES_FETCH_SUCCESS;
    profiles: OrderProfile[];
}

interface OrderProfilesFetchError {
    type: typeof ORDER_PROFILES_FETCH_ERROR;
    fetchError: Error;
}

export interface OrderProfileSave {
    type: typeof ORDER_PROFILE_SAVE;
    profile: OrderProfile;
}

interface OrderProfileSaveSuccess {
    type: typeof ORDER_PROFILE_SAVE_SUCCESS;
    profile: OrderProfile;
}

interface OrderProfileSaveError {
    type: typeof ORDER_PROFILE_SAVE_ERROR;
    saveError: Error;
}

interface OrderProfileDelete {
    type: typeof ORDER_PROFILE_DELETE;
    profile: OrderProfile;
    callback?: (error?: Error) => void;
}

interface OrderProfileDeleteSuccess {
    type: typeof ORDER_PROFILE_DELETE_SUCCESS;
    profile: OrderProfile;
}

export interface LocationOrderProfileSave {
    type: typeof LOCATION_ORDER_PROFILE_SAVE;
    master: OrderProfile;
    profile: LocationOrderProfile;
}

interface LocationOrderProfileSaveSuccess {
    type: typeof LOCATION_ORDER_PROFILE_SAVE_SUCCESS;
    masterId: string;
    profile: LocationOrderProfile;
}

interface LocationOrderProfileSaveError {
    type: typeof LOCATION_ORDER_PROFILE_SAVE_ERROR;
    saveError: Error;
}

export interface LocationOrderProfileDelete {
    type: typeof LOCATION_ORDER_PROFILE_DELETE;
    master: OrderProfile;
    profile: LocationOrderProfile;
}

interface LocationOrderProfileDeleteSuccess {
    type: typeof LOCATION_ORDER_PROFILE_DELETE_SUCCESS;
    masterId: string;
    profile: LocationOrderProfile;
}

interface LocationOrderProfileDeleteError {
    type: typeof LOCATION_ORDER_PROFILE_DELETE_ERROR;
    masterId: string;
    profile: LocationOrderProfile;
    deleteError: Error;
}

interface OrderProfileFetch {
    type: typeof ORDER_PROFILE_FETCH;
    id: string;
}

interface OrderProfileFetchSuccess {
    type: typeof ORDER_PROFILE_FETCH_SUCCESS;
    profile: OrderProfile;
}

interface OrderProfileFetchError {
    type: typeof ORDER_PROFILE_FETCH_ERROR;
    fetchError: Error;
}

export type OrderProfilesActions =
    | OrderProfilesFetch
    | OrderProfilesFetchSuccess
    | OrderProfilesFetchError
    | OrderProfileSave
    | OrderProfileSaveSuccess
    | OrderProfileSaveError
    | OrderProfileFetch
    | OrderProfileFetchSuccess
    | OrderProfileFetchError
    | OrderProfileDelete
    | OrderProfileDeleteSuccess
    | LocationOrderProfileSave
    | LocationOrderProfileSaveSuccess
    | LocationOrderProfileSaveError
    | LocationOrderProfileDelete
    | LocationOrderProfileDeleteSuccess
    | LocationOrderProfileDeleteError;
