import React from "react";
import { getSession } from "services/session";
import { AxiosResponse } from "axios";
import OmniComplete from "./OmniComplete";
import { TenderType } from "./reducer";

interface props {
    tenderTypeId?: string;
    locationId: string;
    setTenderType(t: TenderType | null): void;
    error?: boolean;
    errorText?: string;
    disableFetch?: boolean;
}

interface tenderTypeResponse {
    id: string;
    name: string;
    allows_tips: boolean;
    pos_id: string;
}

const mapToDomain = (t: tenderTypeResponse): TenderType => ({
    id: t.id,
    allowsTips: t.allows_tips,
    name: t.name,
    posId: t.pos_id,
    kind: "tender_type",
});

export default function POSTenderTypeSearch(props: props): JSX.Element {
    const s = getSession();
    const { tenderTypeId, disableFetch, locationId, error, errorText = "Required", setTenderType } = props;

    return (
        <OmniComplete
            id="tender-type-id"
            entity={tenderTypeId ? ({ id: tenderTypeId } as TenderType) : null}
            error={error}
            helperText={error ? errorText : "Not Found on POS"}
            setEntity={setTenderType}
            inputLabel="Tender Type"
            disabled={!locationId}
            disableFetch={disableFetch}
            searchUrl={`/1.1/accounts/${s?.accountId}/locations/${locationId}/mms/pos/tender_types/?search=`}
            searchRespMapper={(
                resp: AxiosResponse<{
                    _embedded: {
                        tender_types: tenderTypeResponse[];
                    };
                }>,
            ) => resp.data._embedded.tender_types.map(mapToDomain)}
            fetchUrl={
                tenderTypeId &&
                `/1.1/accounts/${s?.accountId}/locations/${locationId}/mms/pos/tender_types/${tenderTypeId}`
            }
            fetchRespMapper={(resp: AxiosResponse<tenderTypeResponse>) => mapToDomain(resp.data)}
        />
    );
}
