import { combineReducers } from "redux";
import { reportsReducer, reportFiltersReducer } from "./reports/reducers";
import { accountReducer } from "./account/reducers";
import restaurantConceptsReducer from "./account/restaurant_concepts/reducers";
import { locationsReducer } from "./locations/reducers";
import { notificationsReducer } from "./notifications/reducer";
import mms from "./mms/reducers";

const rootReducer = combineReducers({
    app: notificationsReducer,
    account: accountReducer,
    restaurantConcepts: restaurantConceptsReducer,
    locations: locationsReducer,
    reports: reportsReducer,
    reportFilters: reportFiltersReducer,
    mms,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
