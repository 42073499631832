import { Box, Button } from "@mui/material";
import { FormApi, SubmissionErrors } from "final-form";
import React, { HTMLAttributes, ReactNode, useState } from "react";
import { Form, FormProps } from "react-final-form";
import { LoadingButton } from "@mui/lab";
import { navBarWidth } from "components/MainNav";

interface WizardProps<Values> {
    cancelLabel?: string;
    children: ReactNode;
    initialValues?: Partial<Values>;
    formProps?: Partial<Omit<FormProps<Values>, "onSubmit">>;
    nextLabel?: string;
    noPrevOnClick?(): void;
    prevLabel?: string;
    onClose?(): void;
    onSubmit(
        values: Values,
        form: FormApi<Values, Partial<Values>>,
        callback?: ((errors?: SubmissionErrors) => void) | undefined,
    ): void;
    submitLabel?: string;
}

export function Wizard<Values = Record<string, unknown>>(
    p: WizardProps<Values> & Partial<Omit<HTMLAttributes<Omit<HTMLFormElement, "onSubmit">>, "onSubmit">>,
): JSX.Element {
    const {
        children,
        className,
        initialValues,
        formProps,
        cancelLabel = "Cancel",
        prevLabel = "Previous",
        nextLabel = "Next",
        noPrevOnClick,
        submitLabel = "Submit",
        onClose,
        onSubmit,
        ...restForm
    } = p;
    const filteredChildren = React.Children.toArray(children).filter((c) => c);
    const [state, setState] = useState({
        page: 0,
        values: initialValues,
    });
    const next = (values: Values) =>
        setState((prevState) => ({
            page: Math.min(prevState.page + 1, filteredChildren.length - 1),
            values,
        }));
    const previous = () => setState((prevState) => ({ ...prevState, page: Math.max(prevState.page - 1, 0) }));
    const validate = (values: Values) => {
        const activePage = filteredChildren[state.page] as React.ReactElement;
        return activePage.props.validate ? activePage.props.validate(values) : {};
    };
    const handleSubmit = (
        values: Values,
        form: FormApi<Values, Partial<Values>>,
        callback?: ((errors?: SubmissionErrors) => void) | undefined,
    ): void => {
        const { page } = state;
        const isLastPage = page === filteredChildren.length - 1;

        if (isLastPage) {
            return onSubmit(values, form, callback);
        }

        next(values);
        callback && callback();
    };
    const activePage = filteredChildren[state.page];
    const isLastPage = state.page === filteredChildren.length - 1;

    return (
        <Form {...formProps} onSubmit={handleSubmit} validate={validate} initialValues={state.values}>
            {({ handleSubmit, submitting }) => (
                <Box
                    component="form"
                    className={className}
                    onSubmit={handleSubmit}
                    sx={{
                        minWidth: "100%",
                        minHeight: "inherit",
                        display: "flex",
                        flexDirection: "column",
                    }}
                    {...restForm}
                >
                    {activePage}

                    <Box
                        component="footer"
                        sx={(theme) => ({
                            position: "fixed",
                            left: navBarWidth,
                            right: 0,
                            bottom: 0,
                            padding: theme.spacing(1, 3),
                            display: "flex",
                            justifyContent: "flex-end",
                            backgroundColor: theme.mixins.mainNavColor,

                            [theme.breakpoints.down("sm")]: {
                                left: 0,
                            },
                        })}
                    >
                        <Button
                            title={cancelLabel}
                            onClick={onClose}
                            disabled={submitting}
                            sx={(theme) => ({
                                color: theme.palette.getContrastText(theme.mixins.mainNavColor),
                                marginRight: "auto",
                            })}
                            disableElevation
                        >
                            {cancelLabel}
                        </Button>
                        {state.page > 0 && (
                            <Button
                                sx={{ marginRight: (theme) => theme.spacing(2) }}
                                onClick={previous}
                                type="button"
                                variant="outlined"
                            >
                                {prevLabel}
                            </Button>
                        )}
                        {state.page === 0 && noPrevOnClick && (
                            <Button
                                sx={{ marginRight: (theme) => theme.spacing(2) }}
                                onClick={noPrevOnClick}
                                type="button"
                                variant="outlined"
                            >
                                {prevLabel}
                            </Button>
                        )}
                        {!isLastPage && (
                            <Button type="submit" variant="contained" color="primary">
                                {nextLabel}
                            </Button>
                        )}
                        {isLastPage && (
                            <LoadingButton
                                type="submit"
                                disabled={submitting}
                                loading={submitting}
                                loadingIndicator="Saving..."
                                variant="contained"
                                color="primary"
                            >
                                {submitLabel}
                            </LoadingButton>
                        )}
                    </Box>
                </Box>
            )}
        </Form>
    );
}

interface WizardPageProps<Values> {
    children: ReactNode;
    validate?: (values: Partial<Values>) => Partial<Record<keyof typeof values, unknown>>;
}

export function WizardPage<Values = Record<string, unknown>>({ children }: WizardPageProps<Values>): JSX.Element {
    return <>{children}</>;
}
