import React from "react";
import { Grid, GridProps, Typography } from "@mui/material";
import { ActionButton } from "components/common/buttons/ActionButton";

interface ZeroStateProps extends GridProps {
    title: string;
    onClick?(): void;
    createLabel?: string;
}

export function ZeroState({ title, onClick, createLabel, ...rest }: ZeroStateProps): JSX.Element {
    const { sx = [], ...lessRest } = rest;

    return (
        <Grid
            container
            direction="column"
            alignContent="center"
            alignItems="center"
            justifyContent="space-between"
            sx={[
                {
                    minHeight: "120px",
                    margin: (theme) => theme.spacing(10, 0),
                },
                ...(Array.isArray(sx) ? sx : [sx]),
            ]}
            {...lessRest}
        >
            <Grid item>
                <Typography variant="h4">{title}</Typography>
            </Grid>
            {createLabel && (
                <Grid item>
                    <ActionButton label={createLabel} onClick={onClick} />
                </Grid>
            )}
        </Grid>
    );
}
