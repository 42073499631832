import React from "react";
import { getSession } from "services/session";
import { AxiosResponse } from "axios";
import OmniComplete from "./OmniComplete";
import { ItemOrderMode } from "./reducer";

interface props {
    itemOrderModeId?: string;
    locationId: string;
    setItemOrderMode(t: ItemOrderMode | null): void;
    error?: boolean;
    errorText?: string;
    disableFetch?: boolean;
}

interface itemOrderModeResponse {
    id: string;
    name: string;
    available: boolean;
    pos_id: string;
}

const mapToDomain = (t: itemOrderModeResponse): ItemOrderMode => ({
    id: t.id,
    available: t.available,
    name: t.name,
    posId: t.pos_id,
    kind: "item_order_mode",
});

export default function POSItemOrderModeSearch(props: props): JSX.Element {
    const s = getSession();
    const { itemOrderModeId, disableFetch, locationId, error, errorText = "Required", setItemOrderMode } = props;

    return (
        <OmniComplete
            id="item-order-mode-id"
            entity={itemOrderModeId ? ({ id: itemOrderModeId } as ItemOrderMode) : null}
            error={error}
            helperText={error ? errorText : "Not Found on POS"}
            setEntity={setItemOrderMode}
            inputLabel="Item Order Mode (optional)"
            disabled={!locationId}
            disableFetch={disableFetch}
            searchUrl={`/1.1/accounts/${s?.accountId}/locations/${locationId}/mms/pos/item_order_modes/?search=`}
            searchRespMapper={(
                resp: AxiosResponse<{
                    _embedded: {
                        item_order_modes: itemOrderModeResponse[];
                    };
                }>,
            ) => resp.data._embedded.item_order_modes.map(mapToDomain)}
            fetchUrl={
                itemOrderModeId &&
                `/1.1/accounts/${s?.accountId}/locations/${locationId}/mms/pos/item_order_modes/${itemOrderModeId}`
            }
            fetchRespMapper={(resp: AxiosResponse<itemOrderModeResponse>) => mapToDomain(resp.data)}
        />
    );
}
