import { HALLink, ListRoute, SaveAction } from "store/types";
import { MasterEntity, MasterEntityActions } from "../../types";
import { MasterMenu } from "../types";
import { MenuLayerRuleSet, RuleSetActions } from "./rule-sets/types";
import { RuleActions } from "./rule-sets/rules/types";

export const LAYERS_PAGE_FETCH = "@@ADMINMASTER/MENU/LAYERS/PAGE/FETCH";
export const LAYERS_PAGE_FETCH_SUCCESS = "@@ADMINMASTER/MENU/LAYERS/PAGE/FETCH_SUCCESS";
export const LAYERS_PAGE_FETCH_ERROR = "@@ADMINMASTER/MENU/LAYERS/PAGE/FETCH_ERROR";

export const LAYERS_SAVE = "@@ADMINMASTER/MENU/LAYERS/SAVE";
export const LAYERS_SAVE_SUCCESS = "@@ADMINMASTER/MENU/LAYERS/SAVE_SUCCESS";
export const LAYERS_SAVE_ERROR = "@@ADMINMASTER/MENU/LAYERS/SAVE_ERROR";

export const LAYER_ADD = "@@ADMINMASTER/MENU/LAYER/ADD";
export const LAYER_ADD_SUCCESS = "@@ADMINMASTER/MENU/LAYER/ADD_SUCCESS";

export interface MenuLayer {
    id: string;
    enabled: boolean;
    name: string;
    sequence: number;

    _embedded: {
        rule_sets: MenuLayerRuleSet[];
    };

    _links: {
        rule_sets: HALLink;
        self: {
            href: string;
            type: "application/hal+json; name=mms_master_menu_layer";
        };
    };
}

export type MenuLayerReq = Omit<MenuLayer, "_embedded" | "_links">;
export interface NewMenuLayer extends Pick<MenuLayer, "name"> {
    enabled: false;
    sequence: -1;
}

export const isMenuLayer = (e: MasterEntity): e is MenuLayer =>
    (e as MenuLayer)?._links?.self?.type === "application/hal+json; name=mms_master_menu_layer";

export interface MenuLayerLocation {
    id: string;
    display_name: string;
    reference_name: string;
    name: string;

    _links: {
        self: HALLink;
    };
}

export interface MenuLayerPageResp extends ListRoute {
    _embedded: {
        layers: MenuLayer[];
    };

    _links: {
        next?: HALLink;
        self: HALLink;
    };
}

interface LayersPageFetch {
    type: typeof LAYERS_PAGE_FETCH;
    menu: MasterMenu;
    url?: string;
}

interface LayersPageFetchSuccess {
    type: typeof LAYERS_PAGE_FETCH_SUCCESS;
    menuId: string;
    layers: MenuLayer[];
}

interface LayersPageFetchError {
    type: typeof LAYERS_PAGE_FETCH_ERROR;
    error: Error;
}

export interface LayersSave extends SaveAction {
    type: typeof LAYERS_SAVE;
    menuId: string;
    layers: MenuLayer[];
}

interface LayersSaveSuccess {
    type: typeof LAYERS_SAVE_SUCCESS;
    menuId: string;
    layers: MenuLayer[];
}

interface LayersSaveError {
    type: typeof LAYERS_SAVE_ERROR;
    error: Error;
}

export interface LayerAdd extends SaveAction {
    type: typeof LAYER_ADD;
    menu: MasterMenu;
    layer: NewMenuLayer;
}

interface LayerAddSuccess {
    type: typeof LAYER_ADD_SUCCESS;
    menuId: string;
    layer: MenuLayer;
}

export type MenuLayerActions =
    | LayersPageFetch
    | LayersPageFetchSuccess
    | LayersPageFetchError
    | LayersSave
    | LayersSaveSuccess
    | LayersSaveError
    | LayerAdd
    | LayerAddSuccess
    | RuleSetActions
    | RuleActions
    | MasterEntityActions;
