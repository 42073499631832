import {
    Location,
    LOCATION_SEARCH,
    LOCATION_SEARCH_SUCCESS,
    LOCATION_SEARCH_ERROR,
    LOCATION_SEARCH_RESET,
    LocationsActions,
    LOCATION_FETCH,
    LOCATION_FETCH_ERROR,
    LOCATION_FETCH_SUCCESS,
    LOCATION_PAGE_FETCH,
    LOCATION_PAGE_FETCH_SUCCESS,
    LOCATION_PAGE_FETCH_ERROR,
} from "./types";

export function locationFetch(url: string): LocationsActions {
    return {
        type: LOCATION_FETCH,
        url,
    };
}

export function locationFetchSuccess(location: Location): LocationsActions {
    return {
        type: LOCATION_FETCH_SUCCESS,
        location,
    };
}

export function locationFetchError(error: Error): LocationsActions {
    return {
        type: LOCATION_FETCH_ERROR,
        error,
    };
}

export function locationSearch(query: string): LocationsActions {
    return {
        type: LOCATION_SEARCH,
        query,
    };
}

export function locationSearchSuccess(locations: Location[]): LocationsActions {
    return {
        type: LOCATION_SEARCH_SUCCESS,
        locations,
    };
}

export function locationSearchError(error: Error): LocationsActions {
    return {
        type: LOCATION_SEARCH_ERROR,
        error,
    };
}

export function locationSearchReset(): LocationsActions {
    return {
        type: LOCATION_SEARCH_RESET,
    };
}

export function locationPageFetch(url: string, page: number, pageSize: number, query?: string): LocationsActions {
    return {
        type: LOCATION_PAGE_FETCH,
        url,
        page,
        pageSize,
        query,
    };
}

export function locationPageFetchSuccess(
    locations: Location[],
    page: number,
    pageSize: number,
    total: number,
): LocationsActions {
    return {
        type: LOCATION_PAGE_FETCH_SUCCESS,
        locations,
        page,
        pageSize,
        total,
    };
}

export function locationPageFetchError(error: Error, page: number): LocationsActions {
    return {
        type: LOCATION_PAGE_FETCH_ERROR,
        error,
        page,
    };
}
