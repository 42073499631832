import { Typography, Alert } from "@mui/material";
import ConfirmDialog from "components/common/ConfirmDialog";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { MasterModifier } from "store/mms/menus/master/items/modifiers/types";
import { optionSetModifierSave } from "store/mms/menus/master/items/option-sets/actions";
import { OptionSet } from "store/mms/menus/master/items/option-sets/types";
import { axiosErrorMessage } from "store/types";

interface OptionSetDefaultModifierProps {
    isDefault: boolean;
    modifier: MasterModifier;
    onClose(): void;
    open: boolean;
    optionSet: OptionSet;
}

export function OptionSetDefaultModifier({
    isDefault,
    modifier,
    onClose,
    open,
    optionSet,
}: OptionSetDefaultModifierProps): JSX.Element {
    const dispatch = useDispatch();
    const [state, setState] = useState<{ error?: string; saving: boolean }>({ saving: false });

    return (
        <ConfirmDialog
            title={`${isDefault ? "Set" : "Unset"} Default`}
            open={open}
            onClose={onClose}
            onConfirm={() => {
                setState(() => ({
                    error: undefined,
                    saving: true,
                }));
                dispatch(
                    optionSetModifierSave(optionSet, { id: modifier.id, default: isDefault }, (error?: Error) => {
                        if (error) {
                            return setState(() => ({
                                saving: false,
                                error: axiosErrorMessage(error),
                            }));
                        }

                        setState(() => ({
                            saving: false,
                            error: undefined,
                        }));
                        onClose();
                    }),
                );
            }}
            confirm={`${isDefault ? "Set" : "Unset"} Default`}
            confirming={state.saving}
            maxWidth="xl"
            transitionDuration={0}
        >
            <Typography>
                Are you sure you want to {isDefault ? "set" : "unset"} <strong>{modifier.display_name}</strong> as a
                default Modifier on the <strong>{optionSet._embedded.modifier_group.display_name}</strong> Modifier
                Group?
            </Typography>

            <Alert variant="outlined" severity="info">
                This change will affect <strong>all Locations</strong>.
            </Alert>

            {state.error && (
                <Alert variant="outlined" severity="error">
                    {state.error}
                </Alert>
            )}
        </ConfirmDialog>
    );
}
