import { byHref } from "store/types";
import { AdminlocationReset, ADMINLOCATION_RESET } from "../types";
import {
    LocationItem,
    LOCATION_ITEM_SAVE,
    LOCATION_ITEM_SAVE_ERROR,
    LOCATION_ITEM_SAVE_SUCCESS,
    LocationItemActions,
    LOCATION_ITEM_FETCH,
    LOCATION_ITEM_FETCH_ERROR,
    LOCATION_ITEM_FETCH_SUCCESS,
    LOCATION_ITEMS_HYDRATE,
} from "./types";

interface LocationItemsState {
    readonly byHref: {
        [key: string]: LocationItem;
    };
    readonly hrefsById: { [key: string]: string[] };
    readonly allIds: string[];
    readonly fetching: boolean;
    readonly saving: boolean;
    readonly fetchError?: Error;
}

const initialState: LocationItemsState = {
    byHref: {},
    hrefsById: {},
    allIds: [],
    fetching: false,
    saving: false,
};

export default function locationItemsReducer(
    state = initialState,
    action: LocationItemActions | AdminlocationReset,
): LocationItemsState {
    switch (action.type) {
        case LOCATION_ITEM_FETCH:
            return {
                ...state,
                fetching: true,
            };

        case LOCATION_ITEM_FETCH_SUCCESS:
            return {
                ...state,
                byHref: {
                    ...state.byHref,
                    [action.item._links.self.href]: action.item,
                },
                hrefsById: {
                    ...state.hrefsById,
                    [action.item.id]: [...(state.hrefsById[action.item.id] || []), action.item._links.self.href],
                },
                allIds: [...state.allIds.filter((id) => id !== action.item.id), action.item.id],
                fetching: false,
            };

        case LOCATION_ITEM_FETCH_ERROR:
            return {
                ...state,
                fetching: false,
                fetchError: action.error,
            };

        case LOCATION_ITEM_SAVE:
            return {
                ...state,
                saving: true,
            };

        case LOCATION_ITEM_SAVE_SUCCESS:
            return {
                ...state,
                byHref: {
                    ...state.byHref,
                    [action.item._links.self.href]: action.item,
                },
                hrefsById: {
                    ...state.hrefsById,
                    [action.item.id]: [...(state.hrefsById[action.item.id] || []), action.item._links.self.href],
                },
                allIds: [...state.allIds.filter((id) => id !== action.item.id), action.item.id],
                saving: false,
            };

        case LOCATION_ITEM_SAVE_ERROR:
            return {
                ...state,
                saving: false,
            };

        case LOCATION_ITEMS_HYDRATE:
            const newIds = action.items.map((m) => m.id);
            const hrefsById = action.items.reduce((ids: { [key: string]: string[] }, i) => {
                if (!ids[i.id]) {
                    ids[i.id] = [...(state.hrefsById[i.id] || []), i._links.self.href];
                } else {
                    ids[i.id].push(i._links.self.href);
                }
                return ids;
            }, {});

            return {
                ...state,
                byHref: {
                    ...state.byHref,
                    ...byHref(action.items),
                },
                hrefsById: {
                    ...state.hrefsById,
                    ...hrefsById,
                },
                allIds: [...state.allIds.filter((id) => !newIds.includes(id)), ...newIds],
            };

        case ADMINLOCATION_RESET:
            return initialState;

        default:
            return state;
    }
}
