import React from "react";
import { Edit as EditIcon } from "@mui/icons-material";
import { IconButton, IconButtonProps, Tooltip } from "@mui/material";

interface EntityEditProps extends IconButtonProps {
    onClick(e?: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
    title?: string;
}

export function EntityEdit({ onClick, title = "Update", sx = [] }: EntityEditProps): JSX.Element {
    return (
        <Tooltip title={title} arrow>
            <IconButton aria-label={title.toLowerCase().split(" ").join("-")} sx={sx} size="small" onClick={onClick}>
                <EditIcon fontSize="inherit" />
            </IconButton>
        </Tooltip>
    );
}
