import { Alert, Skeleton, TextField, Typography, Theme } from "@mui/material";
import { makeStyles } from "theme";
import { handleErrors } from "components/common/forms/helpers";
import { FormSubmit } from "components/common/FormSubmit";
import { OmniDrawer } from "components/common/OmniDrawer";
import { MasterMenuContext } from "pages/Menus/MasterMenu/context";
import React, { useContext } from "react";
import { Field, Form } from "react-final-form";
import { useDispatch } from "react-redux";
import { sectionSave } from "store/mms/menus/master/sections/actions";

const useStyles = makeStyles()((theme: Theme) => ({
    header: { marginBottom: theme.spacing(4) },
    form: {
        "& .MuiFormControl-root": {
            marginBottom: theme.spacing(4),

            "&:last-of-type": {
                marginBottom: 0,
            },
        },
    },
    submitErrors: { margin: theme.spacing(4, 0) },
}));

interface SectionAddProps {
    onClose(): void;
    open: boolean;
}

export function SectionAdd({ onClose, open }: SectionAddProps): JSX.Element {
    const { menu } = useContext(MasterMenuContext);
    const { classes } = useStyles();
    const dispatch = useDispatch();

    return (
        <OmniDrawer close={onClose} open={open} title="Add Category">
            {menu === undefined ? (
                <Skeleton />
            ) : (
                <Form
                    onSubmit={(values, _, errorsCallback) => {
                        dispatch(
                            sectionSave(
                                {
                                    id: "",
                                    name: values.name,
                                    description: values.description,
                                    enabled: false,
                                    sequence: 1,
                                },
                                menu._links.sections.href,
                                (errors?: Error) => {
                                    if (errors) {
                                        return errorsCallback && errorsCallback(errors);
                                    }
                                    onClose();
                                },
                            ),
                        );
                    }}
                    validate={(values) => ({
                        name: !values.name ? "Required" : undefined,
                    })}
                >
                    {({ handleSubmit, submitError }) => (
                        <form onSubmit={handleSubmit} className={classes.form}>
                            <Typography className={classes.header} variant="h5">
                                {menu.name}
                            </Typography>

                            <Field name="name">
                                {({ input, meta }) => (
                                    <TextField
                                        {...input}
                                        {...handleErrors(meta)}
                                        label="Category Name"
                                        variant="outlined"
                                        autoFocus
                                        fullWidth
                                        data-lpignore
                                    />
                                )}
                            </Field>

                            <Field name="description">
                                {({ input, meta }) => (
                                    <TextField
                                        {...input}
                                        {...handleErrors(meta)}
                                        label="Category Description"
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        rows={4}
                                    />
                                )}
                            </Field>

                            {submitError && (
                                <Alert className={classes.submitErrors} severity="error">
                                    {submitError}
                                </Alert>
                            )}

                            <FormSubmit onClose={onClose} label="Add Category" />
                        </form>
                    )}
                </Form>
            )}
        </OmniDrawer>
    );
}
