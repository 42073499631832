import { applyMiddleware, compose, createStore } from "redux";
import { devToolsEnhancer } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";
import rootReducer from "./rootReducer";
import rootSaga from "./rootSaga";
import immutableStateInvariantMiddleware from "redux-immutable-state-invariant";

const initialState = {};
const sagaMiddleware = createSagaMiddleware();
const middlewares =
    process.env.NODE_ENV === "production" ? [sagaMiddleware] : [sagaMiddleware, immutableStateInvariantMiddleware()];

const store = createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(...middlewares), devToolsEnhancer({ trace: true, traceLimit: 25 })),
);

sagaMiddleware.run(rootSaga);

export default store;
