import { Paper, PaperProps } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { RefObject } from "react";

const PaperWrapper = React.forwardRef(function PaperWrapper(
    props: PaperProps,
    ref: ((instance: HTMLDivElement | null) => void) | RefObject<HTMLDivElement> | null,
) {
    const theme = useTheme();

    return <Paper {...props} elevation={theme.mixins.pageElevation} ref={ref} />;
});

export default PaperWrapper;
