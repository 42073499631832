import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Typography,
} from "@mui/material";
import { makeStyles } from "theme";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import {
    reportsFiltersAddPOSType,
    reportsFiltersRemovePOSType,
    reportsFiltersResetPOSType,
} from "store/reports/actions";
import { RootState } from "store/rootReducer";
import getTheme from "theme";

const useStyles = makeStyles()(() => ({
    root: {
        width: "100%",
    },
}));

export default function POSTypes(): JSX.Element {
    const { classes } = useStyles();
    const dispatch = useDispatch();
    const filters = useSelector((state: RootState) => state.reportFilters);
    const theme = getTheme();
    const onChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        const posType = {
            id: event.currentTarget.id,
            name: event.currentTarget.name,
        };

        if (checked) {
            dispatch(reportsFiltersAddPOSType(posType));
        } else {
            dispatch(reportsFiltersRemovePOSType(posType));
        }
    };
    const onChangeClear = () => {
        dispatch(reportsFiltersResetPOSType());
    };

    return (
        <div className={classes.root}>
            <Accordion disabled={filters.loading} elevation={theme.mixins.accordionElevation}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography variant="subtitle2">POS Type</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color="primary"
                                    onChange={onChangeClear}
                                    checked={!filters.applied.POSTypes.length}
                                />
                            }
                            label="All"
                        />
                        {filters.available.POSTypes.map(({ id, name }) => (
                            <FormControlLabel
                                key={id}
                                control={
                                    <Checkbox
                                        color="primary"
                                        onChange={onChange}
                                        checked={filters.applied.POSTypes.some((p) => p.id === id)}
                                        name={name}
                                        id={id}
                                    />
                                }
                                label={name}
                            />
                        ))}
                    </FormGroup>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}
