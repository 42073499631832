import { Grid, Typography } from "@mui/material";
import React, { useContext } from "react";
import { RuleSetContext } from "pages/Menus/Layers/RuleSets/context";
import { RuleListItem } from "./RuleListItem";

export default function RuleSetRulesList(): JSX.Element {
    const { ruleSet } = useContext(RuleSetContext);
    const rules = ruleSet?._embedded.rules || [];

    return (
        <>
            <Typography sx={{ padding: (theme) => theme.spacing(2, 0) }} variant="h6">
                <strong>Rules</strong> ({rules.length})
            </Typography>

            <Grid container spacing={3} sx={{ marginBottom: (theme) => theme.spacing(2.5) }}>
                {rules &&
                    rules.map((rule) => (
                        <Grid
                            item
                            key={rule.id}
                            sx={(theme) => ({
                                flexBasis: "50%",
                                "&:focus-visible": {
                                    outline: "none",
                                },

                                [theme.breakpoints.up("xl")]: {
                                    flexBasis: "33%",
                                },

                                [theme.breakpoints.down("sm")]: {
                                    flexBasis: "100%",
                                },
                            })}
                        >
                            <RuleListItem rule={rule} />
                        </Grid>
                    ))}
            </Grid>
        </>
    );
}
