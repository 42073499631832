import { Typography } from "@mui/material";
import ConfirmDialog from "components/common/ConfirmDialog";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { periodBlocksSave as locationPeriodBlocksSave } from "store/mms/menus/location/periods/actions";
import { Period as LocationPeriod } from "store/mms/menus/location/periods/types";

interface LocationMenuPeriodResetProps {
    period: LocationPeriod;
    onClose(): void;
    open: boolean;
}

export const LocationMenuPeriodReset = ({ period, open, onClose }: LocationMenuPeriodResetProps): JSX.Element => {
    const dispatch = useDispatch();
    const [state, setState] = useState<{ saving: boolean; error?: string }>({
        saving: false,
    });
    return (
        <ConfirmDialog
            title="Reset Menu Period Settings?"
            open={open}
            onClose={onClose}
            onConfirm={() => {
                setState({ saving: true });
                dispatch(
                    locationPeriodBlocksSave(period, [], period._links.blocks.href, () => {
                        setState({ saving: false });
                        onClose();
                    }),
                );
            }}
            confirm="Reset Period"
            confirming={state.saving}
            transitionDuration={0}
        >
            <Typography gutterBottom>
                Are you sure that you want to reset <strong>{period.display_name}</strong> Menu Period to inherit
                settings from the Master Menu Period?
            </Typography>
        </ConfirmDialog>
    );
};
