import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Theme, Tooltip, useTheme } from "@mui/material";
import { makeStyles } from "theme";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles()((theme: Theme) => ({
    tooltip: {
        whiteSpace: "pre-wrap",
        margin: "auto",
    },
    infoIcon: {
        cursor: "pointer",
        marginLeft: theme.spacing(0.5),
    },
}));
interface HintProps {
    className?: string;
    title: NonNullable<React.ReactNode>;
}
export default function Hint({ title, className = "" }: HintProps): JSX.Element {
    const { classes } = useStyles();
    const theme = useTheme();
    return (
        <Tooltip className={clsx(classes.tooltip, className)} arrow title={title}>
            <span className={classes.infoIcon}>
                <FontAwesomeIcon icon={faInfoCircle} color={theme.palette.info.light} />
            </span>
        </Tooltip>
    );
}
