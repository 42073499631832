import {
    Block,
    DayOfWeek,
    DEFAULT_GRACE_PERIOD,
    DEFAULT_START_BUFFER,
    MAX_DURATION,
    MAX_GRACE_PERIOD,
    MAX_START_BUFFER,
    NewPeriod,
    Period as MasterPeriod,
} from "store/mms/menus/master/periods/types";
import { Period as LocationPeriod } from "store/mms/menus/location/periods/types";
import { capitalize } from "components/common/utils";
import format from "date-fns/format";
import compareAsc from "date-fns/compareAsc";
import { isValid } from "date-fns";
import isBefore from "date-fns/isBefore";
import isAfter from "date-fns/isAfter";
import isEmpty from "lodash/isEmpty";
import { DEFAULT_BLOCK_DURATION, DEFAULT_BLOCK_END_TIME, DEFAULT_BLOCK_START_TIME } from "./EditMenuPeriod";
import { default as createCalculateDecorator } from "final-form-calculate";
import { getIn } from "final-form";
import addDays from "date-fns/addDays";
import addMinutes from "date-fns/addMinutes";
import subDays from "date-fns/subDays";
import differenceInMinutes from "date-fns/differenceInMinutes";

export interface UIBlockDescription {
    startEnd: string;
    days: string;
}

export interface UIBlock {
    duration: number;
    start: Date;
    end: Date;
    days: DayOfWeek[];
    is24Hours: boolean;
    isEveryDay?: boolean;
    startBuffer: number;
    eodBuffer: number;
}

interface UIBlockMap {
    [key: string]: UIBlock;
}

export function uiBlocksDescription(blocks: UIBlock[]): UIBlockDescription[] {
    return blocks.map((b) => ({
        startEnd:
            b.duration === MAX_DURATION ? "24 hours" : `${format(b.start, "h:mm a")} - ${format(b.end, "h:mm a")}`,
        days: b.days.map((d) => capitalize(d)).join(b.days.length < 3 ? " & " : ", "),
    }));
}

export const isNewPeriod = (p: NewPeriod | MasterPeriod | LocationPeriod): p is NewPeriod => {
    return !Object.prototype.hasOwnProperty.call(p as NewPeriod, "_embedded");
};

export function generateUIBlocks(p?: NewPeriod | LocationPeriod | MasterPeriod): UIBlock[] {
    if (!p || isNewPeriod(p)) {
        return [];
    }

    return generateUIBlocksFromPeriodBlocks(p._embedded.blocks);
}
export function generateUIBlocksFromPeriodBlocks(blocks: Block[]): UIBlock[] {
    const blockMap: UIBlockMap = blocks.reduce((map: UIBlockMap, block) => {
        const key = `${block.start}-${block.duration}`;
        const uiBlock = map[key];

        if (!uiBlock) {
            map[key] = {
                duration: block.duration,
                eodBuffer: block.eod_buffer,
                start: calculateStart(block.start),
                isEveryDay: false,
                is24Hours: block.duration === MAX_DURATION,
                end: calculateEnd(block.start, block.duration),
                startBuffer: block.start_buffer,
                days: [block.day],
            };
        } else {
            uiBlock.days.push(block.day);
            if (uiBlock.days.length === 7) {
                uiBlock.isEveryDay = true;
            }
        }

        return map;
    }, {});
    return Object.values(blockMap);
}

export function setTime(hours: number, minutes?: number): Date {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), now.getDate(), hours, minutes || 0, 0, 0);
}

export function calculateStart(start: string): Date {
    const [startHour, startMinute] = start.split(":");
    return setTime(parseInt(startHour), parseInt(startMinute));
}

function calculateEnd(start: string, duration: number): Date {
    return addMinutes(calculateStart(start), duration);
}

export function getMinutesBetween(start: Date, end: Date): number {
    let diff = (end.getTime() - start.getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
}

export function getEmptyMenuPeriod(): NewPeriod {
    return {
        id: "",
        display_name: "",
        mms_menu: "",
        reference_name: "",
    };
}

export const mapUIBlocks = (block: UIBlock): Block[] => {
    return block.days.map((day) => ({
        day,
        duration: block.duration,
        start: format(block.start, "HH:mm"),
        eod_buffer: block.eodBuffer,
        start_buffer: block.startBuffer,
    }));
};
const weekdayOrder = Object.values(DayOfWeek);
const blockStartEnd = (b: Block): [Date, Date] => {
    const start = calculateStart(b.start);
    const currentDay = start.getDay();
    const distance = weekdayOrder.indexOf(b.day) - currentDay;

    start.setDate(start.getDate() + distance);

    return [start, addMinutes(start, b.duration)];
};

interface blockValidationError {
    start?: string;
    end?: string;
    days?: string;
    startBuffer?: string;
    eodBuffer?: string;
    noBlocks?: string;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const validateBlocks = (blocks: any[]): blockValidationError[] | undefined => {
    const blockErrors: blockValidationError[] = [];

    if (!blocks) {
        return undefined;
    }

    blocks.forEach((b) => {
        const error: blockValidationError = {};

        if (!b.start || !isValid(b.start)) {
            error.start = "Required";
        }

        if (!b.end || !isValid(b.end)) {
            error.end = "Required";
        }

        if (!b.days) {
            error.days = "At least one day of the week is required";
        }

        if (
            b.startBuffer === undefined ||
            b.startBuffer === null ||
            b.startBuffer === "" ||
            b.startBuffer > MAX_START_BUFFER
        ) {
            error.startBuffer = `Value must be between 0 and ${MAX_START_BUFFER}`;
        }

        if (b.eodBuffer === undefined || b.eodBuffer === null || b.eodBuffer === "" || b.eodBuffer > MAX_GRACE_PERIOD) {
            error.eodBuffer = `Value must be between 0 and ${MAX_GRACE_PERIOD}`;
        }

        blockErrors.push(error);
    });

    if (blockErrors.some((e) => !isEmpty(e))) {
        return blockErrors;
    }

    const apiBlocks: Block[] = [];

    blocks.forEach((block: UIBlock) => apiBlocks.push(...mapUIBlocks(block)));

    apiBlocks
        .sort((a, b) => compareAsc(calculateStart(a.start), calculateStart(b.start)))
        .sort((a, b) => weekdayOrder.indexOf(a.day) - weekdayOrder.indexOf(b.day));

    const blocksAreValid = apiBlocks.every((b, idx) => {
        let [leftStart, leftEnd] = blockStartEnd(b);
        let blocksOverlap = false;

        for (let i = idx + 1; !blocksOverlap && i < apiBlocks.length; i++) {
            let [rightStart, rightEnd] = blockStartEnd(apiBlocks[i]);

            // Weeks are fixed to Sunday - Saturday.  If left is Saturday and right is Sunday (the inverse), advance
            // Sunday so that its date comes after Saturday (and not before).
            if (leftStart.getDay() === 6 && rightStart.getDay() === 0) {
                rightStart = addDays(rightStart, 7);
                rightEnd = addDays(rightEnd, 7);
            }
            if (rightStart.getDay() === 6 && leftStart.getDay() === 0) {
                leftStart = addDays(leftStart, 7);
                leftEnd = addDays(leftEnd, 7);
            }

            // If the left does not start before the right, flip them.
            if (!isBefore(leftStart, rightStart)) {
                [leftStart, rightStart] = [rightStart, leftStart];
                [leftEnd, rightEnd] = [rightEnd, leftEnd];
            }

            blocksOverlap = isAfter(leftEnd, rightStart);
        }

        return !blocksOverlap;
    });

    if (!blocksAreValid) {
        return blocks.map(() => ({ days: "Time blocks cannot overlap." }));
    }

    return undefined;
};

export const newBlock = (): UIBlock => {
    return {
        duration: DEFAULT_BLOCK_DURATION,
        start: DEFAULT_BLOCK_START_TIME,
        end: DEFAULT_BLOCK_END_TIME,
        days: [],
        is24Hours: false,
        startBuffer: DEFAULT_START_BUFFER,
        eodBuffer: DEFAULT_GRACE_PERIOD,
    };
};

export const blockFormCalculators = createCalculateDecorator(
    {
        field: /blocks\[\d\]\.start$/,
        updates: (start, name, allValues) => {
            const durationField = name.replace("start", "duration");
            const endField = name.replace("start", "end");
            const is24HoursField = name.replace("start", "is24Hours");
            let end = getIn(allValues ?? {}, endField);
            let is24Hours = getIn(allValues ?? {}, is24HoursField);
            let newDuration = differenceInMinutes(end, start);

            // 24 Hours
            if (newDuration === 0) {
                newDuration = MAX_DURATION;
                addMinutes(end, MAX_DURATION);
                is24Hours = true;
            } else if (is24Hours) {
                end = addMinutes(start, MAX_DURATION);
            }

            return { [endField]: end, [durationField]: newDuration, [is24HoursField]: is24Hours };
        },
    },
    {
        field: /blocks\[\d\]\.end/,
        updates: (end, name, allValues) => {
            const durationField = name.replace("end", "duration");
            const startField = name.replace("end", "start");
            const is24HoursField = name.replace("end", "is24Hours");
            const start = getIn(allValues ?? {}, startField);
            let is24Hours = getIn(allValues ?? {}, is24HoursField);
            let newDuration = differenceInMinutes(end, start);

            // 24 Hours
            if (newDuration === 0) {
                newDuration = MAX_DURATION;
                is24Hours = true;
                end = addDays(start, 1);
            } else if (newDuration < 0) {
                end = addDays(end, 1);
                newDuration = differenceInMinutes(end, start);
            } else if (newDuration > MAX_DURATION) {
                end = subDays(end, 1);
                newDuration = differenceInMinutes(end, start);
            }

            return { [name]: end, [durationField]: newDuration, [is24HoursField]: is24Hours };
        },
    },
    {
        field: /blocks\[\d\]\.is24Hours/,
        updates: (is24Hours, name, allValues) => {
            if (!is24Hours) {
                return {};
            }

            const durationField = name.replace("is24Hours", "duration");
            const endField = name.replace("is24Hours", "end");
            const startField = name.replace("is24Hours", "start");
            const start = getIn(allValues ?? {}, startField);

            return { [endField]: addDays(start, 1), [durationField]: MAX_DURATION };
        },
    },
    {
        field: /blocks\[\d\]\.isEveryDay/,
        updates: (isEveryDay, name, allValues) => {
            const blocksField = name.replace("isEveryDay", "days");
            const days = getIn(allValues ?? {}, blocksField);
            if (!isEveryDay && days.length === 7) {
                return { [blocksField]: [] };
            }
            return { [blocksField]: isEveryDay ? Object.values(DayOfWeek) : days };
        },
    },
    {
        field: /blocks\[\d\]\.days/,
        updates: (days, name) => {
            const isEveryDayField = name.replace("days", "isEveryDay");
            return { [name]: days, [isEveryDayField]: days.length === 7 };
        },
    },
);
