import { HALLink, ListRoute, SaveAction } from "store/types";

export const LINK_TIMES_PAGE_FETCH = "@@ADMINMASTER/LINK_TIMES/PAGE/FETCH";
export const LINK_TIMES_PAGE_FETCH_SUCCESS = "@@ADMINMASTER/LINK_TIMES/PAGE/FETCH_SUCCESS";

export const LINK_TIMES_SAVE = "@@ADMINMASTER/LINK_TIMES/SAVE";
export const LINK_TIMES_SAVE_SUCCESS = "@@ADMINMASTER/LINK_TIMES/SAVE_SUCCESS";

export interface LinkTime {
    id: string;
    link_time: string;

    _links: {
        self: {
            href: string;
            type: "application/hal+json; name=mms_account_menu_link_time";
        };
    };
}

export interface LinkTimeReq extends Pick<LinkTime, "link_time"> {
    id?: string;
}
export type NewLinkTime = Pick<LinkTime, "link_time">;

export const isLinkTime = (lt: LinkTime | NewLinkTime): lt is LinkTime =>
    (lt as LinkTime)?._links?.self?.type === "application/hal+json; name=mms_account_menu_link_time";
export interface LinkTimesPageResp extends ListRoute {
    _embedded: {
        link_times: LinkTime[];
    };

    _links: {
        next?: HALLink;
        self: HALLink;
    };
}

export interface LinkTimesPageFetch {
    type: typeof LINK_TIMES_PAGE_FETCH;
    url?: string;
}

interface LinkTimesPageFetchSuccess {
    type: typeof LINK_TIMES_PAGE_FETCH_SUCCESS;
    linkTimes: LinkTime[];
}

export interface LinkTimesSave extends SaveAction {
    type: typeof LINK_TIMES_SAVE;
    linkTimes: (LinkTime | NewLinkTime)[];
}
interface LinkTimesSaveSuccess {
    type: typeof LINK_TIMES_SAVE_SUCCESS;
    linkTimes: LinkTime[];
}

export type LinkTimesActions = LinkTimesPageFetch | LinkTimesPageFetchSuccess | LinkTimesSave | LinkTimesSaveSuccess;
