import { Typography, Alert } from "@mui/material";
import ConfirmDialog from "components/common/ConfirmDialog";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { axiosErrorMessage } from "store/types";
import { MenuLayer } from "store/mms/menus/master/layers/types";
import { layersSave } from "store/mms/menus/master/layers/actions";

interface MenuLayerVisibilityProps {
    menuId: string;
    layer: MenuLayer;
    onClose(): void;
    open: boolean;
}

export function MenuLayerVisibility({ menuId, layer, onClose, open }: MenuLayerVisibilityProps): JSX.Element {
    const dispatch = useDispatch();
    const [state, setState] = useState<{ saving: boolean; error?: string }>({
        saving: false,
    });
    const enabledness = layer.enabled ? "Disable" : "Enable";
    const name = `${enabledness} Layer`;

    return (
        <ConfirmDialog
            title={name}
            open={open}
            onClose={onClose}
            onConfirm={() => {
                setState({ saving: true });
                dispatch(
                    layersSave(menuId, [{ ...layer, enabled: !layer.enabled }], (error?: Error): void => {
                        if (error) {
                            return setState({ saving: false, error: axiosErrorMessage(error) });
                        }
                        setState({ saving: false });
                        onClose();
                    }),
                );
            }}
            confirm={name}
            confirming={state.saving}
            maxWidth="xl"
            transitionDuration={0}
        >
            <Typography>
                Are you sure you want to <strong>{enabledness.toLowerCase()}</strong> the Layer{" "}
                <strong>{layer.name}</strong>?
            </Typography>

            <Alert variant="outlined" severity="info">
                Defined Rules will {layer.enabled && "no longer "}be applied to specified Locations.
            </Alert>

            {state.error && (
                <Alert variant="outlined" severity="error">
                    {state.error}
                </Alert>
            )}
        </ConfirmDialog>
    );
}
