export interface ListRoute {
    count: number;
    limit: number;
    total?: number;
}

interface ServerSideError {
    description: string;
    error: string;
    fields: string[];
}

export interface ServerSideErrors {
    errors: ServerSideError[];
}

export interface SaveAction {
    callback?: (error?: Error) => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function axiosErrorMessage(error: any): string {
    if (error.response) {
        // Request made and server responded
        return (error.response.data as ServerSideErrors).errors.map((err) => err.description).join(" ");
    } else if (error.request) {
        // The request was made but no response was received
        console.error("The request was made but no response was received");

        return "Unknown server error";
    } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Unknown error", error.message);
    }

    return "Internal Error. Try again later.";
}

interface ID {
    id: string;
}

export function byId<T extends ID>(models: T[]): { [key: string]: T } {
    return models.reduce((allModels: { [key: string]: T }, model) => {
        allModels[model.id] = model;
        return allModels;
    }, {});
}

interface SelfLink {
    _links: { self: HALLink };
}

export function byHref<T extends SelfLink>(models: T[]): { [key: string]: T } {
    return models.reduce((all: { [key: string]: T }, m) => {
        all[m._links.self.href] = m;
        return all;
    }, {});
}

export interface HALEntity {
    _embedded?: unknown;

    _links: {
        self: HALLink;
    };
}

export interface HALLink {
    href: string;
    type: string;
}
