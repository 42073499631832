import { dateStringFormatter, dateTimeStringFormatter } from "components/common/utils";
import {
    generateUIBlocks,
    generateUIBlocksFromPeriodBlocks,
    mapUIBlocks,
    UIBlock,
} from "components/mms/MenuPeriods/helpers";
import { getIn } from "final-form";
import { MasterModifier } from "store/mms/menus/master/items/modifiers/types";
import { MasterItem } from "store/mms/menus/master/items/types";
import {
    MenuLayerRule,
    MenuLayerRuleDefinitionTransform,
    NewMenuLayerRule,
    TransformType,
} from "store/mms/menus/master/layers/rule-sets/rules/types";
import { Block, Period } from "store/mms/menus/master/periods/types";

export const ruleName = "ruleName";
export const selectedItems = "selectedItems";
export const selectedModifiers = "selectedModifiers";

export const enableConstraintsOrderProfiles = "constrainOrderProfiles";
export const enableConstraintsLocalStartEnd = "constrainLocalStartEnd";
export const constraintsOrderProfiles = "constraints.orderProfiles";
export const constraintsLocalStart = "constraints.localStart";
export const constraintsLocalEnd = "constraints.localEnd";

export const enableTransformTypeEnabled = "transformEnabled";
export const transformTypeEnabledConfig = "transformEnabledConfig";

export const enableTransformTypeAdjustPrice = "transformAdjustPrice";
export const transformTypeAdjustPriceConfig = "transformAdjustPriceConfig";

export const enableTransformTypeAddTags = "transformAddTags";
export const transformTypeAddTagsConfig = "transformAddTagsConfig";

export const enableTransformTypePeriods = "transformPeriods";
export const transformTypePeriodsConfig = "transformPeriodsConfig";

export const entitySelectErrors = "entitySelectErrors";
export const transformErrors = "transformErrors";

export enum ruleType {
    Menu = "menu",
    Periods = "periods",
}

interface formPeriod {
    id: string;
    blocks: UIBlock[];
    preventLocationOverride: boolean;
}

export interface formValues {
    menuId: string;
    ruleType: ruleType;
    [selectedItems]: string[];
    [selectedModifiers]: string[];
    [ruleName]: string;
    constraints: {
        orderProfiles: string[];
        localStart: string;
        localEnd: string;
    };
    [enableConstraintsOrderProfiles]: boolean;
    [enableConstraintsLocalStartEnd]: boolean;
    [enableTransformTypeEnabled]: boolean;
    [transformTypeEnabledConfig]: {
        enabled: "true" | "false";
    };
    [enableTransformTypeAdjustPrice]: boolean;
    [transformTypeAdjustPriceConfig]: {
        orderProfile: string;
        type: string;
        enabled: boolean;
        amount: string;
    };
    [enableTransformTypeAddTags]: boolean;
    [transformTypeAddTagsConfig]: { addTags: string[] };
    [enableTransformTypePeriods]: boolean;
    [transformTypePeriodsConfig]: formPeriod[];
}

export const getInitialValues = (
    menuId: string,
    periods: Period[],
    selectedRuleType: ruleType,
    itemsById: { [key: string]: MasterItem },
    modifiersByHref: { [key: string]: MasterModifier },
    modifierHrefsById: { [key: string]: string[] },
    rule?: MenuLayerRule,
): formValues => {
    const existingEnableConstraintsOrderProfiles =
        (rule?.definition.constraints.order_profiles || "").length > 0 || false;
    const existingEnableConstraintsLocalStartEnd = (rule?.local_start || rule?.local_end || "").length > 0 || false;
    const existingEnabledConfig = rule?.definition.transforms.find((t) => t.type === TransformType.Enabled);
    let enableConfig: "true" | "false" = "false";

    if (existingEnabledConfig !== undefined && existingEnabledConfig.type === TransformType.Enabled) {
        enableConfig = existingEnabledConfig.config.enabled ? "true" : "false";
    }

    const existingAdjustPriceConfig = rule?.definition.transforms.find((t) => t.type === TransformType.AdjustPrice);
    const adjustPriceConfig = {
        orderProfile: "",
        type: "",
        enabled: false,
        amount: "",
    };

    if (existingAdjustPriceConfig && existingAdjustPriceConfig.type === TransformType.AdjustPrice) {
        adjustPriceConfig.orderProfile = existingAdjustPriceConfig.config.order_profile;
        adjustPriceConfig.type = existingAdjustPriceConfig.config.type || "";
        adjustPriceConfig.enabled = existingAdjustPriceConfig.config.enabled;
        adjustPriceConfig.amount = existingAdjustPriceConfig.config.amount
            ? (existingAdjustPriceConfig.config.amount / 100).toString()
            : "";
    }

    const existingAddTagsConfig = rule?.definition.transforms.find((t) => t.type === TransformType.AddTags);
    const addTagsConfig =
        existingAddTagsConfig !== undefined && existingAddTagsConfig.type === TransformType.AddTags
            ? existingAddTagsConfig.config.tags.map((t) => t.id)
            : [];

    const existingPeriodsConfig = rule?.definition.transforms.find((t) => t.type === TransformType.AdjustMenuPeriods);
    const periodsConfig =
        existingPeriodsConfig !== undefined && existingPeriodsConfig.type === TransformType.AdjustMenuPeriods
            ? existingPeriodsConfig.config.periods.map((p) => ({
                  id: p.id,
                  blocks: generateUIBlocksFromPeriodBlocks(p.blocks),
                  preventLocationOverride: p.prevent_location_override,
              }))
            : periods.map((period) => ({
                  id: period.id,
                  blocks: generateUIBlocks(period),
                  preventLocationOverride: false,
              }));

    return {
        menuId,
        ruleType: selectedRuleType,
        [selectedItems]: rule?.definition.matches.items.filter((i: string) => itemsById[i]) || [],
        [selectedModifiers]:
            rule?.definition.matches.modifiers.filter(
                (i: string) => modifierHrefsById[i] && modifiersByHref[modifierHrefsById[i][0]],
            ) || [],
        [ruleName]: rule?.name || "",
        constraints: {
            orderProfiles: rule?.definition.constraints.order_profiles || [],
            localStart: rule?.local_start || "",
            localEnd: rule?.local_end || "",
        },
        [enableConstraintsOrderProfiles]: existingEnableConstraintsOrderProfiles,
        [enableConstraintsLocalStartEnd]: existingEnableConstraintsLocalStartEnd,
        [enableTransformTypeEnabled]: existingEnabledConfig !== undefined,
        [transformTypeEnabledConfig]: {
            enabled: enableConfig,
        },
        [enableTransformTypeAdjustPrice]: existingAdjustPriceConfig !== undefined,
        [transformTypeAdjustPriceConfig]: adjustPriceConfig,
        [enableTransformTypeAddTags]: existingAddTagsConfig !== undefined,
        [transformTypeAddTagsConfig]: { addTags: addTagsConfig },
        [enableTransformTypePeriods]: existingPeriodsConfig !== undefined || selectedRuleType === ruleType.Periods,
        [transformTypePeriodsConfig]: periodsConfig,
    };
};

export const mapValuesToMenuRule = (values: formValues, rule?: MenuLayerRule): MenuLayerRule | NewMenuLayerRule => {
    const transforms: MenuLayerRuleDefinitionTransform[] = [];
    const selectedRuleType: ruleType = getIn(values, "ruleType");
    const hasModifiers = (getIn(values, selectedModifiers) || []).length > 0;
    const enableActiveTime = getIn(values, enableConstraintsLocalStartEnd) || false;
    const localStartValue = enableActiveTime && getIn(values, constraintsLocalStart);
    const localEndValue = enableActiveTime && getIn(values, constraintsLocalEnd);
    const localStart = localStartValue ? formatDate(localStartValue, selectedRuleType) : null;
    const localEnd = localEndValue ? formatDate(localEndValue, selectedRuleType) : null;
    const ruleEnabled = rule ? rule.enabled : false;

    if (selectedRuleType === ruleType.Menu) {
        if (getIn(values, enableTransformTypeEnabled)) {
            transforms.push({
                type: TransformType.Enabled,
                config: { enabled: getIn(values, `${transformTypeEnabledConfig}.enabled`) === "true" },
            });
        }

        if (!hasModifiers && getIn(values, enableTransformTypeAdjustPrice)) {
            const adjustmentConfig = getIn(values, transformTypeAdjustPriceConfig);

            transforms.push({
                type: TransformType.AdjustPrice,
                config: {
                    amount:
                        adjustmentConfig.enabled && adjustmentConfig.amount
                            ? Math.max(parseInt(adjustmentConfig.amount, 10) * 100, 0)
                            : null,
                    type: (adjustmentConfig.enabled && adjustmentConfig.type) || null,
                    order_profile: adjustmentConfig.orderProfile,
                    enabled: adjustmentConfig.enabled,
                },
            });
        }
        if (!hasModifiers && getIn(values, enableTransformTypeAddTags)) {
            transforms.push({
                type: TransformType.AddTags,
                config: {
                    tags: getIn(values, `${transformTypeAddTagsConfig}.addTags`).map((tag: string) => ({ id: tag })),
                },
            });
        }
    }

    if (selectedRuleType === ruleType.Periods && getIn(values, enableTransformTypePeriods)) {
        transforms.push({
            type: TransformType.AdjustMenuPeriods,
            config: {
                periods: getIn(values, transformTypePeriodsConfig).map((p: formPeriod) => {
                    const apiBlocks: Block[] = [];
                    p.blocks.forEach((b) => apiBlocks.push(...mapUIBlocks(b)));

                    return {
                        id: p.id,
                        blocks: apiBlocks,
                        prevent_location_override: p.preventLocationOverride,
                    };
                }),
            },
        });
    }

    return {
        ...(rule || {}),
        enabled: ruleEnabled,
        name: getIn(values, ruleName),
        local_start: localStart,
        local_end: localEnd,
        definition: {
            constraints: {
                order_profiles: getIn(values, enableConstraintsOrderProfiles)
                    ? getIn(values, constraintsOrderProfiles)
                    : [],
            },
            matches: {
                items: getIn(values, selectedItems) || [],
                modifiers: getIn(values, selectedModifiers) || [],
                sections: [],
            },
            transforms,
        },
    };
};

export const formatDate = (d: string, selectedRuleType: ruleType): string =>
    selectedRuleType === ruleType.Periods
        ? `${dateStringFormatter(new Date(d))} 00:00:00`
        : dateTimeStringFormatter(new Date(d));
