import { Box, Button, Grid, Skeleton, Typography, useTheme } from "@mui/material";
import { DataGrid, GridCellParams, GridOverlay } from "@mui/x-data-grid";
import { DoubleArrowRounded, ListRounded as ListRoundedIcon } from "@mui/icons-material";
import React, { useEffect, useRef, useMemo, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { applicationActionOrderingEnable } from "store/mms/application-actions/actions";
import { RootState } from "store/rootReducer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { format } from "date-fns";
import { locationMenuApplicationPageFetch } from "store/mms/menus/location/actions";
import { LocationContext, LocationEvent } from "./context";

import ApplicationActionButton from "components/mms/MenuApplications/ApplicationActions";
import ApplicationSnoozeButton from "components/mms/MenuApplications/ApplicationSnooze";

export function LocationMenuApplicationsList(): JSX.Element {
    const { locationId, menuId, onChange } = useContext(LocationContext);
    const dispatch = useDispatch();
    const refreshCycle = useRef<NodeJS.Timer>();

    // Retrieve menus, spread applications as rows
    const menusFetching = useSelector((state: RootState) => state.mms.menus.location.menus.fetching);
    const menuIdsByLocationId = useSelector((state: RootState) => state.mms.menus.location.menus.byLocationId);
    const menusById = useSelector((state: RootState) => state.mms.menus.location.menus.byId);
    const locationMenu = menusById[menuId];
    const locationMenus = useMemo(
        () => menuIdsByLocationId[locationId]?.map((id) => menusById[id]) || [],
        [locationId, menuIdsByLocationId, menusById],
    );

    // Retrieve application statuses on an interval, expedited if there are pending changes.
    const [lastRefreshed, setLastRefreshed] = useState<number>(Date.now());
    const [menuChangesPending, setMenuChangesPending] = useState<{
        [menuApplicationId: string]: boolean;
    }>({});
    useEffect(() => {
        if (!locationMenus) return;

        const pendingChanges = Object.keys(menuChangesPending).length > 0;
        const refreshTime = pendingChanges ? 3000 : 20000;

        if (refreshCycle.current) clearInterval(refreshCycle.current);
        refreshCycle.current = setInterval(() => {
            locationMenus.forEach((menu) => {
                dispatch(locationMenuApplicationPageFetch(menu._links.applications.href));
            });
            setLastRefreshed(Date.now());
        }, refreshTime);
        return () => refreshCycle.current && clearInterval(refreshCycle.current);
    }, [dispatch, menuChangesPending, locationId, menuIdsByLocationId, menusById, locationMenus]);

    // Resolve / resume pending changes on application status refresh.
    useEffect(() => {
        const unresolvedChanges = { ...menuChangesPending };
        const startChangeCount = Object.keys(menuChangesPending).length;

        Object.keys(menuChangesPending).forEach((id) => {
            const [menuId, appId] = id.split("-");

            const pendingStatus = menuChangesPending[id];
            const actualStatus = menusById[menuId]._embedded.applications.find((a) => a.id === appId)?.actual_enabled;

            if (pendingStatus === actualStatus) {
                delete unresolvedChanges[id];
            }
        });
        const finalChangeCount = Object.keys(unresolvedChanges).length;
        if (startChangeCount !== finalChangeCount) setMenuChangesPending(unresolvedChanges);
    }, [menusFetching, setMenuChangesPending, menuChangesPending, menusById]);

    const isLoading = menusFetching || locationMenu === undefined;
    const menuApplications = locationMenu?._embedded.applications || [];

    return isLoading ? (
        <Skeleton />
    ) : (
        <Grid
            sx={{ flex: "1 0 auto", marginTop: (theme) => theme.spacing(-0.5) }}
            container
            flexWrap="nowrap"
            direction="column"
        >
            <Grid container direction="column" key={locationMenu.id}>
                <Grid container justifyContent="flex-end">
                    {lastRefreshed && (
                        <Typography variant="caption" sx={{ marginRight: (theme) => theme.spacing(2) }}>
                            Last refreshed:{" "}
                            {`${format(new Date(lastRefreshed), "yyyy-MM-dd @ p")} (${Intl.DateTimeFormat()
                                .resolvedOptions()
                                .timeZone.replace("_", " ")})`}
                        </Typography>
                    )}
                </Grid>
                <DataGrid
                    sortModel={[
                        {
                            field: "name",
                            sort: "asc",
                        },
                    ]}
                    columns={[
                        {
                            field: "id",
                            headerName: "ID",
                            width: 0,
                            flex: 0,
                            hide: true,
                        },
                        {
                            field: "name",
                            headerName: "Services",
                            width: 25,
                            flex: 0.5,
                            renderCell: function renderActions(params: GridCellParams) {
                                return !params.row.logo_url ? (
                                    params.row.name
                                ) : (
                                    <img
                                        style={{ objectFit: "contain" }}
                                        width="100%"
                                        height="100%"
                                        src={params.row.logo_url}
                                        alt=""
                                    />
                                );
                            },
                        },
                        {
                            field: "status",
                            headerName: "Status",
                            headerAlign: "center",
                            flex: 0.45,
                            renderCell: function renderState(params: GridCellParams) {
                                const actualState = params.row.actual_enabled as string;
                                const stateUnknown = actualState === "unknown" || actualState === null;

                                const change = menuChangesPending[`${locationMenu.id}-${params.row.id}`];
                                const expectedState = change !== undefined ? change : params.row.expected_enabled;
                                const statesMatch = expectedState === actualState;

                                return (
                                    <Grid
                                        alignItems="center"
                                        justifyContent={statesMatch || stateUnknown ? "center" : "space-between"}
                                        container
                                        flexDirection="row"
                                        sx={{ marginLeft: "auto", marginRight: "auto" }}
                                    >
                                        {stateUnknown ? (
                                            <StatusIndicator status={null} hasLabel />
                                        ) : (
                                            <>
                                                <StatusIndicator status={params.row.actual_enabled} hasLabel />
                                                {!statesMatch && !stateUnknown && (
                                                    <>
                                                        <DoubleArrowRounded />
                                                        <StatusIndicator status={expectedState} hasLabel />
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </Grid>
                                );
                            },
                        },
                        {
                            field: "last_synced",
                            headerName: "Last Synced",
                            flex: 0.75,
                            renderCell: function renderSync(params: GridCellParams) {
                                return (
                                    <Box flexDirection="column">
                                        <Box>{`${
                                            params.row.last_synced
                                                ? format(new Date(params.row.last_synced), "yyyy-MM-dd p")
                                                : ""
                                        }`}</Box>
                                        <Box>
                                            {params.row._embedded.next_event && (
                                                <Typography variant="caption">
                                                    Will{" "}
                                                    {params.row._embedded.next_event.enabled ? "enable" : "disable"}{" "}
                                                    {`${format(
                                                        new Date(params.row._embedded.next_event.start_time * 1000),
                                                        "yyyy-MM-dd @ p",
                                                    )}`}
                                                </Typography>
                                            )}
                                        </Box>
                                    </Box>
                                );
                            },
                        },
                        {
                            field: "actions",
                            headerName: "Actions",
                            flex: 2,
                            renderCell: function renderActions(params: GridCellParams) {
                                const change = menuChangesPending[`${locationMenu.id}-${params.row.id}`];

                                return (
                                    <>
                                        <ApplicationSnoozeButton
                                            application={params.row}
                                            incomingChange={change}
                                            sx={{
                                                marginRight: (theme) => theme.spacing(1),
                                            }}
                                            onChange={() => {
                                                const targetStatus = !params.row.actual_enabled;
                                                setMenuChangesPending({
                                                    ...menuChangesPending,
                                                    [`${locationMenu.id}-${params.row.id}`]: targetStatus,
                                                });
                                            }}
                                        />
                                        <ApplicationActionButton
                                            application={params.row}
                                            incomingChange={change}
                                            onChange={() => {
                                                const targetStatus = !params.row.actual_enabled;
                                                setMenuChangesPending({
                                                    ...menuChangesPending,
                                                    [`${locationMenu.id}-${params.row.id}`]: targetStatus,
                                                });

                                                const targets = {
                                                    menus: [menuId],
                                                    locations: [locationId],
                                                    applications: [params.row.id],
                                                };

                                                dispatch(
                                                    applicationActionOrderingEnable(null, targets, {
                                                        enabled: targetStatus,
                                                    }),
                                                );
                                            }}
                                        />
                                    </>
                                );
                            },
                        },
                        {
                            field: "logs",
                            headerName: "Changes",
                            flex: 1,
                            width: 100,
                            renderCell: function renderActions(params: GridCellParams) {
                                return (
                                    <>
                                        <Button
                                            size="small"
                                            variant="outlined"
                                            startIcon={<ListRoundedIcon />}
                                            onClick={() =>
                                                onChange({
                                                    type: LocationEvent.ApplicationScheduledEventsView,
                                                    scheduledEventsViewInfo: {
                                                        menu: locationMenu,
                                                        application: params.row,
                                                    },
                                                })
                                            }
                                            title="Scheduled Changes"
                                        >
                                            Scheduled
                                        </Button>
                                        <Button
                                            size="small"
                                            sx={{
                                                marginLeft: (theme) => theme.spacing(2),
                                            }}
                                            variant="outlined"
                                            startIcon={<ListRoundedIcon />}
                                            onClick={() =>
                                                onChange({
                                                    type: LocationEvent.ApplicationLogView,
                                                    logViewInfo: { menu: locationMenu, application: params.row },
                                                })
                                            }
                                            title="Change History"
                                        >
                                            History
                                        </Button>
                                    </>
                                );
                            },
                        },
                    ]}
                    rows={menuApplications}
                    loading={isLoading}
                    autoHeight
                    sx={{
                        marginBottom: (theme) => theme.spacing(5),
                        minHeight: "inherit",
                        borderLeft: "unset",
                        borderRight: "unset",
                        borderTop: "unset",
                        "& .actions-cell": {
                            "& > div": {
                                cursor: "pointer",
                            },
                        },
                        "& .hidden": {
                            visibility: "hidden",
                        },
                        "& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within": {
                            outline: "none",
                        },
                        "& .MuiDataGrid-virtualScrollerContent": {
                            maxHeight: menuApplications.length ? "none" : "52px",
                        },
                        "& .MuiDataGrid-columnHeader": {
                            pointerEvents: "none",
                        },
                    }}
                    disableSelectionOnClick
                    hideFooter
                    components={{
                        NoRowsOverlay: () => <GridOverlay>Contact support to onboard new Applications.</GridOverlay>,
                    }}
                    disableColumnSelector
                    disableColumnMenu
                    disableColumnFilter
                />
            </Grid>
        </Grid>
    );
}

interface StatusIndicatorProps {
    status: boolean | null;
    hasLabel?: boolean;
}

const StatusIndicator = ({ status, hasLabel }: StatusIndicatorProps): JSX.Element => {
    const theme = useTheme();

    let iconColor = theme.palette.success.main;
    let textColor = theme.palette.success.dark;
    let text = "Enabled";
    if (status === null) {
        iconColor = theme.palette.warning.main;
        textColor = theme.palette.warning.dark;
        text = "Unknown";
    } else if (!status) {
        iconColor = theme.palette.grey[600];
        textColor = theme.palette.grey[800];
        text = "Disabled";
    }

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                paddingTop: (theme) => theme.spacing(0.75),
            }}
        >
            <FontAwesomeIcon icon={faCircle} size="lg" fixedWidth color={iconColor} />
            {hasLabel && (
                <Typography variant="caption" sx={{ paddingTop: (theme) => theme.spacing(0.25) }} color={textColor}>
                    {text}
                </Typography>
            )}
        </Box>
    );
};
