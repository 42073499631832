import React, { CSSProperties, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { VictoryPie, VictoryTheme } from "victory";
import getTheme from "theme";
import Tooltip from "./Tooltip";
import Legend from "./Legend";
import { reportsFetch } from "store/reports/actions";
import { RootState } from "store/rootReducer";
import { makeStyles } from "theme";
import { Typography, Skeleton, Theme } from "@mui/material";

const useStyle = makeStyles()((theme: Theme) => ({
    root: {
        width: "350px",
        padding: theme.spacing(2),
        background: theme.palette.background.paper,
        boxShadow: theme.shadows[10],
    },
    graph: {
        margin: theme.spacing(-2, 0),
    },
    footer: {
        display: "flex",
        justifyContent: "space-between",
        position: "relative",
    },
    footerDivider: {
        borderRight: `1px solid ${theme.palette.divider}`,
        paddingRight: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    legend: {
        flexGrow: 1,
    },
    total: {
        textAlign: "center",
        alignSelf: "center",
        flexGrow: 1,
    },
    footerSkeleton: {
        position: "absolute",
        width: "100%",
    },
}));

function POSInjectionSuccessRate(): JSX.Element {
    const dispatch = useDispatch();
    const report = useSelector((state: RootState) => state.reports.posInjectionSuccessRate);
    const { classes } = useStyle();

    useEffect(() => {
        dispatch(reportsFetch());
    }, [dispatch]);

    const theme = getTheme();
    const loadingColor = theme.palette.grey[300];
    const loadingStyle = { visibility: report.loading ? "hidden" : "visible" } as CSSProperties;
    const data = [];

    if (report.loading) {
        data.push({ x: 0, y: 1, fill: loadingColor, label: "Loading..." });
    } else if (report.failure === 0 && report.success === 0) {
        data.push({ x: 0, y: 1, fill: theme.palette.info.light, label: "Zero Orders" });
    } else {
        data.push(
            // Don't change the order of these.  It matters for how the graph is displayed.
            {
                x: 0,
                y: report.success,
                fill: theme.palette.success.light,
                label: `Successful ${Math.round((report.success / report.total) * 100).toFixed(2)}%`,
            },
            {
                x: 1,
                y: report.failure,
                fill: theme.palette.error.light,
                label: `Failed ${Math.round((report.failure / report.total) * 100).toFixed(2)}%`,
            },
        );
    }

    return (
        <figure className={classes.root}>
            <Typography variant="h6" align="center" display="block">
                Order Injection Success Rate
            </Typography>
            <div className={classes.graph}>
                <VictoryPie
                    data={data}
                    animate={{ duration: 1200, easing: "cubic" }}
                    style={{
                        data: {
                            strokeWidth: report.loading ? 1 : 0,
                            stroke: report.loading ? loadingColor : "",
                            // TODO: figure out what the right type is.
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            fill: (d: any) => d.slice.data.fill,
                        },
                    }}
                    labelComponent={Tooltip()}
                    theme={VictoryTheme.material}
                />
            </div>
            <footer className={classes.footer}>
                <Legend
                    entries={[
                        {
                            label: "Successful",
                            value: report.success,
                            color: theme.palette.success.light,
                            toolTip: (
                                <Typography variant="subtitle2">
                                    Order was successfully injected into the point-of-sale.
                                </Typography>
                            ),
                        },
                        {
                            label: "Failed",
                            value: report.failure,
                            color: theme.palette.error.light,
                            toolTip: (
                                <>
                                    <Typography variant="subtitle2">
                                        Order failed to inject into the point-of-sale.
                                    </Typography>
                                    <br />
                                    <br />
                                    <Typography variant="subtitle2">
                                        Example Causes: Internet connectivity issues, POS timeouts, integration issues.
                                    </Typography>
                                </>
                            ),
                        },
                    ]}
                    className={classes.legend}
                    style={loadingStyle}
                />
                <div className={classes.footerDivider} style={loadingStyle} />
                <aside className={classes.total} style={loadingStyle}>
                    <Typography variant="h5">
                        <strong>{(report.loading ? 0 : report.total).toLocaleString()}</strong>
                    </Typography>
                    <Typography variant="h6" color="textSecondary">
                        total orders
                    </Typography>
                </aside>

                {report.loading && (
                    <div className={classes.footerSkeleton}>
                        <Skeleton variant="text" width={`${Math.random() * (100 - 23) + 23}%`} height={30} />
                        <Skeleton variant="text" width={`${Math.random() * (100 - 23) + 23}%`} height={30} />
                    </div>
                )}
            </footer>
        </figure>
    );
}

export default function Visualizations(): JSX.Element {
    return <POSInjectionSuccessRate />;
}
