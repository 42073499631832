import React from "react";
import { Theme, Typography, Skeleton } from "@mui/material";
import { makeStyles } from "theme";
import { Section } from "store/mms/menus/master/sections/types";
import { Switches } from "mui-rff";
import { useFormState } from "react-final-form";

interface MasterMenuPeriodSectionsProps {
    sections?: Section[];
    className?: string;
}

function DisabledSectionsSkeleton(): JSX.Element {
    return <Skeleton width={100} height={35} variant="text" />;
}

export default function MasterMenuPeriodSections({ sections = undefined }: MasterMenuPeriodSectionsProps): JSX.Element {
    const { classes } = useStyles();
    const { values } = useFormState();
    const displayName = values.displayName;

    const noSectionsEnabled = values.enabledSections.length === 0;
    const allSectionsEnabled = values.enabledSections.length === sections?.length;

    let headerText;
    if (noSectionsEnabled)
        headerText = (
            <>
                There are no <strong>Categories</strong> subscribed to{" "}
                {displayName ? <strong>{displayName}</strong> : "this Menu Period"}.
            </>
        );
    else
        headerText = (
            <>
                {allSectionsEnabled ? "All " : "The following "} <strong>Categories</strong> are subscribed to{" "}
                {displayName ? <strong>{displayName}</strong> : "this Menu Period"}.
            </>
        );
    return (
        <>
            {!sections ? (
                <DisabledSectionsSkeleton />
            ) : (
                <>
                    <Typography className={classes.header} variant="subtitle2">
                        {headerText}
                    </Typography>
                    <Switches
                        data-testid="enabled-sections"
                        name="enabledSections"
                        data={sections.map((s) => ({ label: s.name, value: s.id }))}
                    />
                </>
            )}
        </>
    );
}

const useStyles = makeStyles()((theme: Theme) => ({
    header: {
        paddingBottom: theme.spacing(3),
    },
}));
