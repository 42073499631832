import { Location } from "store/locations/types";
import { LocationOrderProfile, OrderProfile } from "store/mms/order-profiles/types";

export interface formErrors {
    location: boolean;
    employee: boolean;
    orderType: boolean;
    revenueCenter: boolean;
    serviceCharge: boolean;
    discount: boolean;
    tenderType: boolean;
    guestCount: boolean;
    guestCountInvalid: boolean;
    accountNumber: boolean;
}

interface state {
    masterProfile: OrderProfile;
    profile: LocationOrderProfile;
    showAdvanced: boolean;
    isNew: boolean;
    saving: boolean;
    saveError?: Error;
    formErrors?: formErrors;

    enableTenderType: boolean;
    tenderTypeId?: string;
    enableGuestCount: boolean;
    guestCount: string;
    enableAccountNumber: boolean;
    accountNumber: string;
}

type action =
    | { type: "SET_LOCATION"; location: Location }
    | { type: "SET_EMPLOYEE"; employeeId: string }
    | { type: "SET_ORDER_TYPE"; orderTypeId: string }
    | { type: "SET_REVENUE_CENTER"; revenueCenterId: string }
    | { type: "SET_ITEM_ORDER_MODE"; itemOrderModeId: string }
    | { type: "SET_ADJUSTMENT_ID"; adjustmentId: string }
    | { type: "SET_TENDER_ENABLED"; enabled: boolean }
    | { type: "SET_TENDER_TYPE"; tenderTypeId: string }
    | { type: "SET_SHOW_ADVANCED"; showAdvanced: boolean }
    | { type: "SET_AUTO_SEND"; autoSend: boolean | null }
    | { type: "SET_AUTO_CLOSE"; autoClose: boolean | null }
    | { type: "SET_OMIT_READY_TIME"; omitReadyTime: boolean }
    | { type: "SET_HOLD_VIA_READY_TIME_ADDITION"; holdViaReadyTimeAddition: number }
    | { type: "ENABLE_GUEST_COUNT"; enabled: boolean }
    | { type: "SET_GUEST_COUNT"; guestCount: string }
    | { type: "ENABLE_ACCOUNT_NUMBER"; enabled: boolean }
    | { type: "SET_ACCOUNT_NUMBER"; accountNumber: string }
    | { type: "SHOW_ERROR"; error: Error }
    | { type: "SHOW_VALIDATION_ERRORS"; formErrors: formErrors }
    | { type: "SAVE_PROFILE"; save(m: OrderProfile, l: LocationOrderProfile): void };

export const getInitialState = (m: {
    masterProfile: OrderProfile | undefined;
    profile: LocationOrderProfile | undefined;
}): state => {
    return {
        masterProfile: m.masterProfile || ({} as OrderProfile),
        profile: m.profile?.id
            ? m.profile
            : ({
                  // by default, new profiles are linked
                  linked: true,
                  location: {
                      id: "",
                      display_name: "",
                      name: "",
                  },
                  posConfig: {
                      adjustmentId: "",
                      employeeId: "",
                      orderTypeId: "",
                      revenueCenterId: "",
                      tenderTypeId: "",
                  },
                  ticketConfig: {
                      holdViaReadyTimeAddition: 0,
                      omitPriceLevel: false,
                      omitReadyTime: false,
                      guestCount: null,
                  },
              } as LocationOrderProfile),
        enableTenderType: m.profile?.id && m.profile?.posConfig.tenderTypeId ? true : false,
        tenderTypeId: m.profile?.id ? m.profile?.posConfig.tenderTypeId : "",
        enableGuestCount: m.profile?.ticketConfig?.guestCount !== null,
        guestCount: m.profile?.ticketConfig?.guestCount == null ? "" : m.profile.ticketConfig.guestCount.toString(),
        enableAccountNumber: m.profile?.posConfig?.accountNumber
            ? m.profile?.posConfig?.accountNumber?.trim() !== ""
            : false,
        accountNumber: m.profile?.posConfig?.accountNumber || "",
        showAdvanced: false,
        isNew: !m.profile?.id,
        saving: false,
    };
};

export function reducer(state: state, action: action): state {
    switch (action.type) {
        case "SET_LOCATION":
            return {
                ...state,
                profile: {
                    ...state.profile,
                    location: action.location,
                },
                // if we have validated before, make sure to clear any location errors
                formErrors: state.formErrors
                    ? {
                          ...state.formErrors,
                          location: action.location.id ? false : true,
                      }
                    : undefined,
            };

        case "SET_EMPLOYEE":
            return {
                ...state,
                profile: {
                    ...state.profile,
                    posConfig: {
                        ...state.profile.posConfig,
                        employeeId: action.employeeId,
                    },
                },
                formErrors: state.formErrors
                    ? {
                          ...state.formErrors,
                          employee: !action.employeeId,
                      }
                    : undefined,
            };

        case "SET_ORDER_TYPE":
            return {
                ...state,
                profile: {
                    ...state.profile,
                    posConfig: {
                        ...state.profile.posConfig,
                        orderTypeId: action.orderTypeId,
                    },
                },
                formErrors: state.formErrors
                    ? {
                          ...state.formErrors,
                          orderType: !action.orderTypeId,
                      }
                    : undefined,
            };

        case "SET_REVENUE_CENTER":
            return {
                ...state,
                profile: {
                    ...state.profile,
                    posConfig: {
                        ...state.profile.posConfig,
                        revenueCenterId: action.revenueCenterId,
                    },
                },
                formErrors: state.formErrors
                    ? {
                          ...state.formErrors,
                          revenueCenter: !action.revenueCenterId,
                      }
                    : undefined,
            };

        case "SET_ITEM_ORDER_MODE":
            return {
                ...state,
                profile: {
                    ...state.profile,
                    posConfig: {
                        ...state.profile.posConfig,
                        itemOrderModesId: action.itemOrderModeId,
                    },
                },
            };

        case "SET_ADJUSTMENT_ID":
            return {
                ...state,
                profile: {
                    ...state.profile,
                    posConfig: {
                        ...state.profile.posConfig,
                        adjustmentId: action.adjustmentId,
                    },
                },
                formErrors: state.formErrors
                    ? {
                          ...state.formErrors,
                          discount: !action.adjustmentId,
                          serviceCharge: !action.adjustmentId,
                      }
                    : undefined,
            };

        case "SET_TENDER_ENABLED":
            return {
                ...state,
                enableTenderType: action.enabled,
                profile: {
                    ...state.profile,
                    posConfig: {
                        ...state.profile.posConfig,
                        tenderTypeId: action.enabled ? state.profile.posConfig.tenderTypeId : "",
                    },
                },
            };

        case "SET_TENDER_TYPE":
            return {
                ...state,
                profile: {
                    ...state.profile,
                    posConfig: {
                        ...state.profile.posConfig,
                        tenderTypeId: action.tenderTypeId,
                    },
                },
                formErrors: state.formErrors
                    ? {
                          ...state.formErrors,
                          tenderType: !action.tenderTypeId,
                      }
                    : undefined,
                tenderTypeId: action.tenderTypeId,
            };

        case "SET_SHOW_ADVANCED":
            return {
                ...state,
                showAdvanced: action.showAdvanced,
            };

        case "SET_AUTO_SEND":
            return {
                ...state,
                profile: {
                    ...state.profile,
                    autoSend: action.autoSend,
                },
            };

        case "SET_AUTO_CLOSE":
            return {
                ...state,
                profile: {
                    ...state.profile,
                    autoClose: action.autoClose,
                },
            };

        case "SET_OMIT_READY_TIME":
            return {
                ...state,
                profile: {
                    ...state.profile,
                    ticketConfig: {
                        ...state.profile.ticketConfig,
                        omitReadyTime: action.omitReadyTime,
                    },
                },
            };

        case "SET_HOLD_VIA_READY_TIME_ADDITION":
            return {
                ...state,
                profile: {
                    ...state.profile,
                    ticketConfig: {
                        ...state.profile.ticketConfig,
                        holdViaReadyTimeAddition: action.holdViaReadyTimeAddition,
                    },
                },
            };

        case "ENABLE_GUEST_COUNT":
            return {
                ...state,
                enableGuestCount: action.enabled,
                guestCount: action.enabled && state.guestCount == "" ? "1" : state.guestCount,
                profile: {
                    ...state.profile,
                    ticketConfig: {
                        ...state.profile.ticketConfig,
                        guestCount: action.enabled ? parseInt(state.guestCount || "1", 10) : null,
                    },
                },
            };

        case "SET_GUEST_COUNT":
            return {
                ...state,
                guestCount: action.guestCount || "",
                profile: {
                    ...state.profile,
                    ticketConfig: {
                        ...state.profile.ticketConfig,
                        guestCount: action.guestCount === "" ? null : parseInt(action.guestCount, 10),
                    },
                },
                formErrors: state.formErrors
                    ? {
                          ...state.formErrors,
                          guestCount: false,
                          guestCountInvalid: false,
                      }
                    : undefined,
            };

        case "ENABLE_ACCOUNT_NUMBER":
            return {
                ...state,
                enableAccountNumber: action.enabled,
                profile: {
                    ...state.profile,
                    posConfig: {
                        ...state.profile.posConfig,
                        accountNumber: action.enabled ? state.accountNumber : undefined,
                    },
                },
            };

        case "SET_ACCOUNT_NUMBER":
            return {
                ...state,
                accountNumber: action.accountNumber.trim(),
                profile: {
                    ...state.profile,
                    posConfig: {
                        ...state.profile.posConfig,
                        accountNumber: action.accountNumber.trim(),
                    },
                },
                formErrors: state.formErrors && { ...state.formErrors, accountNumber: false },
            };

        case "SHOW_ERROR":
            return {
                ...state,
                saving: false,
                saveError: action.error,
            };

        case "SHOW_VALIDATION_ERRORS":
            return {
                ...state,
                formErrors: action.formErrors,
            };

        case "SAVE_PROFILE":
            const newState = {
                ...state,
                profile: {
                    ...state.profile,
                    linked: true,
                },
                saving: true,
                saveError: undefined,
            };

            action.save(newState.masterProfile, newState.profile);
            return newState;

        default:
            return state;
    }
}
