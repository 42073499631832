import { entityPOSConfigKey, Nutrition, POSConfig, POSConfigKey } from "store/mms/types";
import { HALEntity } from "store/types";
import { entityLocationPageLink, MenuEntity } from "../../types";
import { LocationModifier } from "./modifiers/types";
import { OptionSet } from "./option-sets/types";

export const LOCATION_ITEM_FETCH = "@@ADMINLOCATION/ITEM/FETCH";
export const LOCATION_ITEM_FETCH_SUCCESS = "@@ADMINLOCATION/ITEM/FETCH_SUCCESS";
export const LOCATION_ITEM_FETCH_ERROR = "@@ADMINLOCATION/ITEM/FETCH_ERROR";

export const LOCATION_ITEM_SAVE = "@@ADMINLOCATION/ITEM/SAVE";
export const LOCATION_ITEM_SAVE_SUCCESS = "@@ADMINLOCATION/ITEM/SAVE_SUCCESS";
export const LOCATION_ITEM_SAVE_ERROR = "@@ADMINLOCATION/ITEM/SAVE_ERROR";

export const LOCATION_ITEMS_HYDRATE = "@@ADMINLOCATION/ITEMS/HYDRATE";

export enum ItemStrategy {
    AddItem = "add_item",
    AddCombo = "add_combo",
}

export interface LocationItem {
    base_price_per_unit: number;
    description: string;
    display_name: string;
    enabled: boolean;
    id: string;
    linked: boolean;
    master_enabled: boolean;
    nutrition: Nutrition | null;
    pos_config: POSConfig | null;
    price_per_unit: number;
    reference_name: string;
    strategy: ItemStrategy;
    tags: string[];
    unlinkedModifiers: number;
    _embedded: {
        option_sets: OptionSet[];
        image: {
            url: string;
        } | null;
    };
    _links: {
        self: {
            href: string;
            type: "application/hal+json; name=adminlocation_menu_item";
        };
    };
}

export interface LocationItemReq
    extends Omit<LocationItem, "_embedded" | "_links" | "pos_config" | "unlinkedModifiers"> {
    pos_config: string;
}
export interface LocationItemResp extends Omit<LocationItem, "pos_config"> {
    pos_config: string;
}

interface LocationItemFetch {
    type: typeof LOCATION_ITEM_FETCH;
    url: string;
}

interface LocationItemFetchSuccess {
    type: typeof LOCATION_ITEM_FETCH_SUCCESS;
    item: LocationItem;
}

interface LocationItemFetchError {
    type: typeof LOCATION_ITEM_FETCH_ERROR;
    error: Error;
}

export interface LocationItemSave {
    type: typeof LOCATION_ITEM_SAVE;
    item: LocationItem;
    url: string;
    callback?: (error?: Error) => void;
}

interface LocationItemSaveSuccess {
    type: typeof LOCATION_ITEM_SAVE_SUCCESS;
    item: LocationItem;
}

interface LocationItemSaveError {
    type: typeof LOCATION_ITEM_SAVE_ERROR;
    error: Error;
}

interface LocationItemssHydrate {
    type: typeof LOCATION_ITEMS_HYDRATE;
    items: LocationItem[];
}

export type LocationItemActions =
    | LocationItemFetch
    | LocationItemFetchSuccess
    | LocationItemFetchError
    | LocationItemSave
    | LocationItemSaveSuccess
    | LocationItemSaveError
    | LocationItemssHydrate;

interface locationModifierResp extends Omit<LocationModifier, "pos_config"> {
    pos_config: string;
}

export function buildTraversal(i: LocationItem, pathname?: string): MenuEntity[] {
    const traversal: MenuEntity[] = [];
    let traverseMod;

    traversal.push({
        id: i.id,
        name: i.display_name,
        strategy: i.strategy,
        pos_entity_type: entityPOSConfigKey(i.pos_config),
        pathname: entityLocationPageLink(i),
    });

    if (!pathname) {
        return traversal;
    }

    for (const [, , osId, modId] of pathname.matchAll(
        /(\/option-sets\/([a-zA-Z\d]*)\/modifier-group\/modifiers\/([a-zA-Z\d]*))/g,
    )) {
        const baseEntity = traverseMod !== undefined ? traverseMod : i;
        const modResp = baseEntity._embedded.option_sets
            .find((o) => o.id === osId)
            ?._embedded.modifier_group._embedded.modifiers.find((m) => m.id === modId) as
            | locationModifierResp
            | undefined;

        if (modResp !== undefined) {
            traversal.push({
                id: modResp.id,
                name: modResp.display_name,
                strategy: modResp.strategy,
                pos_entity_type: modResp.pos_config[0] as POSConfigKey,
                pathname: entityLocationPageLink(modResp as LocationModifier),
            });
            traverseMod = modResp;
        }
    }

    return traversal;
}

export const isLocationItem = (entity: HALEntity): entity is LocationItem => {
    return entity._links.self.type === "application/hal+json; name=adminlocation_menu_item";
};
