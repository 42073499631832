import { Typography, Alert } from "@mui/material";
import ConfirmDialog from "components/common/ConfirmDialog";
import React from "react";
import { useDispatch } from "react-redux";
import { optionSetSave } from "store/mms/menus/master/items/option-sets/actions";
import { OptionSet } from "store/mms/menus/master/items/option-sets/types";

interface OptionSetVisibilityProps {
    menuName: string;
    optionSet: OptionSet;
    onClose(): void;
    open: boolean;
}

export function OptionSetVisibility({ menuName, optionSet, onClose, open }: OptionSetVisibilityProps): JSX.Element {
    const dispatch = useDispatch();

    return (
        <ConfirmDialog
            title={`${optionSet.enabled ? "Hide" : "Display"} Modifier Group`}
            open={open}
            onClose={onClose}
            onConfirm={() => {
                dispatch(optionSetSave({ ...optionSet, enabled: !optionSet.enabled }));
                onClose();
            }}
            confirm={`${optionSet.enabled ? "Hide" : "Display"} Modifier Group`}
            maxWidth="xl"
            transitionDuration={0}
        >
            <Typography>
                Are you sure you want to {optionSet.enabled ? "hide" : "display"} the Modifier Group{" "}
                <strong>{optionSet._embedded.modifier_group.display_name}</strong> on the {menuName} Menu?
            </Typography>

            <Alert variant="outlined" severity="info">
                This change will affect <strong>all Locations</strong>.
            </Alert>
        </ConfirmDialog>
    );
}
