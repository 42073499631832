import { TextField, Alert } from "@mui/material";
import ConfirmDialog from "components/common/ConfirmDialog";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { menuEdit } from "store/mms/menus/master/actions";
import { MasterMenu } from "store/mms/menus/master/types";
import { axiosErrorMessage } from "store/types";
interface MasterMenuEditProps {
    onClose(): void;
    menu: MasterMenu;
}

export function MasterMenuEdit({ menu, onClose }: MasterMenuEditProps): JSX.Element {
    const dispatch = useDispatch();
    const [name, setName] = useState(menu.name);
    const [state, setState] = useState<{ saving: boolean; error?: string }>({
        saving: false,
    });
    const onSaveCallback = (error?: Error): void => {
        if (error) {
            return setState(() => ({
                saving: false,
                error: axiosErrorMessage(error),
            }));
        }
        setState(() => ({
            saving: false,
        }));
        onClose();
    };
    const title = "Rename Master Menu";

    const menuUpdate = { id: menu.id, type: menu.type, name };

    return (
        <ConfirmDialog
            title={title}
            open={true}
            onClose={onClose}
            onConfirm={() => {
                setState(() => ({ saving: true }));
                dispatch(menuEdit(menu._links.self.href, menuUpdate, onSaveCallback));
            }}
            confirm={title}
            confirming={state.saving}
            maxWidth="xl"
            transitionDuration={0}
            disableConfirm={name.length === 0}
        >
            <TextField
                label="Name"
                variant="outlined"
                onChange={(e) => setName(e.target.value)}
                value={name}
                InputProps={{ autoFocus: true }}
                fullWidth
            />
            {state.error && (
                <Alert variant="outlined" severity="error">
                    {state.error}
                </Alert>
            )}
            <Alert variant="outlined" severity="info">
                This change will affect <strong>all Locations</strong> linked to this Menu.
            </Alert>
        </ConfirmDialog>
    );
}
