import {
    Section,
    SectionActions,
    SECTIONS_SAVE_SUCCESS,
    SECTION_DELETE_SUCCESS,
    SECTION_ITEMS_SAVE_SUCCESS,
    SECTION_ITEM_DELETE_SUCCESS,
    SECTION_ITEM_EXISTING_SAVE_SUCCESS,
    SECTION_PAGE_FETCH,
    SECTION_PAGE_FETCH_ERROR,
    SECTION_PAGE_FETCH_SUCCESS,
    SECTION_SAVE_SUCCESS,
} from "./types";

interface SectionsState {
    readonly byId: {
        [key: string]: Section;
    };
    readonly sectionIdsByMenuId: {
        [key: string]: string[];
    };
    readonly fetching: boolean;
    readonly requests: number;
    readonly fetchingError?: Error;
}

const initialState: SectionsState = {
    byId: {},
    fetching: false,
    sectionIdsByMenuId: {},
    requests: 0,
};

export default function sectionsReducer(state = initialState, action: SectionActions): SectionsState {
    let numRequests;
    switch (action.type) {
        case SECTION_PAGE_FETCH:
            return {
                ...state,
                fetching: true,
                requests: state.requests + 1,
            };

        case SECTION_PAGE_FETCH_SUCCESS:
        case SECTIONS_SAVE_SUCCESS:
            const newIds = action.sections.map((m) => m.id);
            numRequests = Math.max(state.requests - 1, 0);

            return {
                ...state,
                byId: {
                    ...state.byId,
                    ...action.sections.reduce((allSections: { [key: string]: Section }, m) => {
                        allSections[m.id] = m;
                        return allSections;
                    }, {}),
                },
                sectionIdsByMenuId: {
                    ...state.sectionIdsByMenuId,
                    [action.menuId]: [
                        ...(state.sectionIdsByMenuId[action.menuId] || []).filter((id) => !newIds.includes(id)),
                        ...newIds,
                    ],
                },
                fetching: numRequests > 0,
                requests: numRequests,
            };

        case SECTION_PAGE_FETCH_ERROR:
            numRequests = Math.max(state.requests - 1, 0);

            return {
                ...state,
                fetching: numRequests > 0,
                requests: numRequests,
                fetchingError: action.error,
            };

        case SECTION_ITEMS_SAVE_SUCCESS:
            const sectionId = action.section.id;

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [sectionId]: {
                        ...state.byId[sectionId],
                        _embedded: {
                            ...state.byId[sectionId]._embedded,
                            items: action.items,
                        },
                    },
                },
            };

        case SECTION_ITEM_EXISTING_SAVE_SUCCESS:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.section.id]: {
                        ...state.byId[action.section.id],
                        _embedded: {
                            ...state.byId[action.section.id]._embedded,
                            items: [...state.byId[action.section.id]._embedded.items, action.item],
                        },
                    },
                },
            };

        case SECTION_ITEM_DELETE_SUCCESS:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.section.id]: {
                        ...state.byId[action.section.id],
                        _embedded: {
                            ...state.byId[action.section.id]._embedded,
                            items: state.byId[action.section.id]._embedded.items.filter((i) => i.id !== action.item.id),
                        },
                    },
                },
            };

        case SECTION_SAVE_SUCCESS:
            const sectionIdsByMenuId = [...state.sectionIdsByMenuId[action.menuId]] || [];

            if (!sectionIdsByMenuId.includes(action.section.id)) {
                sectionIdsByMenuId.unshift(action.section.id);
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.section.id]: action.section,
                },
                sectionIdsByMenuId: {
                    ...state.sectionIdsByMenuId,
                    [action.menuId]: sectionIdsByMenuId,
                },
            };

        case SECTION_DELETE_SUCCESS:
            return {
                ...state,
                byId: Object.values(state.byId).reduce((byId: { [key: string]: Section }, s: Section) => {
                    if (s.id !== action.section.id) {
                        byId[s.id] = s;
                    }
                    return byId;
                }, {}),
                sectionIdsByMenuId: {
                    ...state.sectionIdsByMenuId,
                    [action.menuId]: state.sectionIdsByMenuId[action.menuId].filter((id) => id !== action.section.id),
                },
            };

        default:
            return state;
    }
}
