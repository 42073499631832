import { call, ForkEffect, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { core } from "services/api";
import { POSConfig } from "store/mms/types";
import { enqueueSnackbar } from "store/notifications/actions";
import { RootState } from "store/rootReducer";
import {
    locationItemFetch as locationItemFetchAction,
    locationItemSaveError,
    locationItemSaveSuccess,
    locationItemFetchError,
    locationItemFetchSuccess,
} from "./actions";
import { locationModifiersHydrate } from "./modifiers/actions";
import { LocationModifier, LocationModifierResp } from "./modifiers/types";
import { LocationItem, LOCATION_ITEM_SAVE, LOCATION_ITEM_FETCH, LocationItemReq, LocationItemResp } from "./types";

const mapDomainToReq = (li: LocationItem): LocationItemReq => {
    return {
        base_price_per_unit: li.base_price_per_unit,
        description: li.description,
        display_name: li.display_name,
        enabled: li.enabled,
        id: li.id,
        linked: li.linked,
        master_enabled: li.master_enabled,
        nutrition: li.nutrition,
        pos_config: JSON.stringify(li.pos_config),
        price_per_unit: li.price_per_unit,
        reference_name: li.reference_name,
        strategy: li.strategy,
        tags: li.tags,
    };
};
export const mapItemResp = (r: LocationItemResp): LocationItem => {
    return {
        ...r,
        pos_config: JSON.parse(r.pos_config) as POSConfig,
    };
};
const mapModifierResp = (r: LocationModifierResp): LocationModifier => {
    return {
        ...r,
        pos_config: JSON.parse(r.pos_config) as POSConfig,
    };
};
export const flattenMods = (i: LocationItem | LocationModifier): LocationModifier[] => {
    const mods: LocationModifier[] = [];

    i._embedded.option_sets.forEach((os) => {
        const osMods = os._embedded.modifier_group._embedded.modifiers.map((m) =>
            mapModifierResp(m as LocationModifierResp),
        );
        mods.push(...osMods);

        osMods.forEach((m) => mods.push(...flattenMods(m)));
    });
    return mods;
};
const hrefsById = (state: RootState) => state.mms.menus.location.items.hrefsById;

function* locationItemFetch({ url }: { url: string; type: typeof LOCATION_ITEM_FETCH }) {
    try {
        const resp: { data: LocationItemResp } = yield call(core.get, url);
        const savedItem = mapItemResp(resp.data);

        yield put(locationModifiersHydrate(flattenMods(savedItem)));
        yield put(locationItemFetchSuccess(savedItem));
    } catch (e) {
        console.error("Failed to fetch Menu Location Item", e);
        if (e instanceof Error) {
            yield put(locationItemFetchError(e));
        }
    }
}

function* locationItemSave({
    url,
    item,
    callback,
}: {
    url: string;
    type: typeof LOCATION_ITEM_SAVE;
    item: LocationItem;
    callback?: (error?: Error) => void;
}) {
    try {
        const resp: { data: LocationItemResp } = yield call(
            item.id === "" ? core.post : core.put,
            url,
            mapDomainToReq(item),
        );
        const savedItem = mapItemResp(resp.data);
        const itemsMap: {
            [key: string]: string[];
        } = yield select(hrefsById);

        if (callback !== undefined) {
            yield call(callback);
        }
        yield put(locationItemSaveSuccess(savedItem));
        yield put(
            enqueueSnackbar({
                message: `Successfully saved Item, ${savedItem.display_name}.`,
                options: {
                    variant: "success",
                },
            }),
        );
        for (const url of itemsMap[savedItem.id]) {
            if (url !== savedItem._links.self.href) {
                yield put(locationItemFetchAction(url));
            }
        }
    } catch (e) {
        console.error("Failed to save Menu Location Item", e);
        if (e instanceof Error) {
            if (callback !== undefined) {
                yield call(callback, e);
            }
            yield put(locationItemSaveError(e));
        }

        yield put(
            enqueueSnackbar({
                message: `Failed to save Item${item.display_name && ", " + item.display_name}.`,
                options: {
                    variant: "error",
                },
            }),
        );
    }
}

export function* locationItemsSaga(): Generator<ForkEffect<never>, void, unknown> {
    yield takeEvery(LOCATION_ITEM_FETCH, locationItemFetch);
    yield takeLatest(LOCATION_ITEM_SAVE, locationItemSave);
}
