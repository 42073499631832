import React, { useState } from "react";
import { Alert } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { axiosErrorMessage } from "store/types";
import { applicationActionMenuSync } from "store/mms/application-actions/actions";
import { ApplicationActionTargets } from "store/mms/application-actions/types";
import ConfirmDialog from "components/common/ConfirmDialog";
import { RootState } from "store/rootReducer";

interface MasterMenuSyncProps {
    open: boolean;
    targets: ApplicationActionTargets | undefined;
    onClose: () => void;
}

export function MasterMenuSync({ open, targets, onClose }: MasterMenuSyncProps): JSX.Element {
    const [err, setError] = useState("");

    const isSaving = useSelector((state: RootState) => state.mms.applicationActions.saving);

    const dispatch = useDispatch();

    const handleConfirm = () => {
        // Schedule an event to re-enable (end the snooze)
        if (targets) {
            dispatch(
                applicationActionMenuSync(targets, (error?: Error) => {
                    if (!error) {
                        onClose();
                    }
                    return error && setError(axiosErrorMessage(error));
                }),
            );
        }
    };

    const handleCancel = () => {
        onClose();
        setError("");
    };

    return (
        <ConfirmDialog
            title="Menu Sync"
            open={open}
            disableConfirm={isSaving}
            onConfirm={handleConfirm}
            onClose={handleCancel}
            maxWidth="xl"
            transitionDuration={0}
        >
            {"Are you sure you want to sync for all Locations linked to this Master Menu?"}
            {err && <Alert severity="error">{err}</Alert>}
        </ConfirmDialog>
    );
}
