import { Alert, Box, MenuItem, TextField, Typography, Theme } from "@mui/material";
import { makeStyles } from "theme";
import ConfirmDialog from "components/common/ConfirmDialog";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { axiosErrorMessage } from "store/types";
import { entityDelete } from "store/mms/menus/actions";
import { MenuLayer } from "store/mms/menus/master/layers/types";
import { MenuLayerRuleSet } from "store/mms/menus/master/layers/rule-sets/types";
import pluralize from "pluralize";
import { ruleSetMigrateLocationsDelete } from "store/mms/menus/master/layers/rule-sets/actions";

const useStyles = makeStyles()((theme: Theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        maxWidth: "fit-content",
        marginTop: theme.spacing(-1),
    },
    migrateLocationsText: {
        marginBottom: theme.spacing(4),
    },
}));

interface RuleSetDeleteProps {
    layer: MenuLayer;
    ruleSet: MenuLayerRuleSet;
    onClose(): void;
}

enum RuleSetDeleteOption {
    DeleteLayer,
    MigrateLocations,
    Delete,
}

export function RuleSetDelete({ layer, ruleSet, onClose }: RuleSetDeleteProps): JSX.Element {
    const { classes } = useStyles();
    const dispatch = useDispatch();
    const locationCount = ruleSet._embedded.locations.length;
    let deleteOption: RuleSetDeleteOption;
    if (layer._embedded.rule_sets.length === 1) {
        deleteOption = RuleSetDeleteOption.DeleteLayer;
    } else if (locationCount === 0) {
        deleteOption = RuleSetDeleteOption.Delete;
    } else {
        deleteOption = RuleSetDeleteOption.MigrateLocations;
    }
    const [state, setState] = useState<{ saving: boolean; destinationRuleSet?: MenuLayerRuleSet; error?: string }>({
        saving: false,
    });
    const onSaveCallback = (error?: Error): void => {
        if (error) {
            return setState(() => ({
                saving: false,
                error: axiosErrorMessage(error),
            }));
        }
        setState(() => ({
            saving: false,
        }));
        onClose();
    };
    const name = deleteOption === RuleSetDeleteOption.DeleteLayer ? "Layer" : "Rule Set";
    const locationText = pluralize("Location", locationCount);

    return (
        <ConfirmDialog
            title={`Remove ${name}`}
            open={true}
            onClose={onClose}
            onConfirm={() => {
                setState({ saving: true });
                switch (deleteOption) {
                    case RuleSetDeleteOption.DeleteLayer:
                        return dispatch(entityDelete(layer, name, onSaveCallback));
                    case RuleSetDeleteOption.Delete:
                        return dispatch(entityDelete(ruleSet, name, onSaveCallback));
                    case RuleSetDeleteOption.MigrateLocations:
                        if (state.destinationRuleSet !== undefined) {
                            return dispatch(
                                ruleSetMigrateLocationsDelete(layer, ruleSet, state.destinationRuleSet, onSaveCallback),
                            );
                        }
                }
            }}
            confirm={`Remove ${name}`}
            confirming={state.saving}
            disableConfirm={
                deleteOption === RuleSetDeleteOption.MigrateLocations && state.destinationRuleSet === undefined
            }
            maxWidth="xl"
            transitionDuration={0}
        >
            <Box className={classes.root}>
                {deleteOption === RuleSetDeleteOption.Delete && (
                    <Typography>
                        Are you sure you want to <strong>delete</strong> the <strong>{ruleSet.name}</strong> Rule Set?
                    </Typography>
                )}

                {deleteOption === RuleSetDeleteOption.DeleteLayer && (
                    <Typography>
                        Layers must have at least one Rule Set. Do you want to delete the <strong>Layer</strong>{" "}
                        instead?
                    </Typography>
                )}
                {deleteOption === RuleSetDeleteOption.MigrateLocations && (
                    <>
                        <Typography className={classes.migrateLocationsText}>
                            The <strong>{ruleSet.name}</strong> Rule Set has {locationCount} {locationText} that must be
                            moved to another Rule Set before it can be deleted.
                        </Typography>

                        <TextField
                            select
                            title={`Move ${locationText} To`}
                            label={`Move ${locationText} To`}
                            variant="outlined"
                            value={state.destinationRuleSet?.id || "select"}
                            onChange={(e) =>
                                setState((p) => ({
                                    ...p,
                                    destinationRuleSet: layer._embedded.rule_sets.find(
                                        (rs) => rs.id === e.target.value,
                                    ),
                                }))
                            }
                            fullWidth
                        >
                            <MenuItem value="select">Select a Rule Set...</MenuItem>
                            {layer._embedded.rule_sets
                                .filter((rs) => rs.id !== ruleSet.id)
                                .map((rs, idx) => (
                                    <MenuItem value={rs.id} key={idx}>
                                        {rs.name}
                                    </MenuItem>
                                ))}
                        </TextField>
                    </>
                )}
            </Box>

            <Alert variant="outlined" severity="info">
                All Rules for this {name} will also be deleted. This cannot be undone!
            </Alert>

            {state.error && (
                <Alert variant="outlined" severity="error">
                    {state.error}
                </Alert>
            )}
        </ConfirmDialog>
    );
}
