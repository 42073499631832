import { call, fork, ForkEffect, put, takeEvery } from "redux-saga/effects";
import { core } from "services/api";
import { enqueueSnackbar } from "store/notifications/actions";
import { axiosErrorMessage } from "store/types";
import { masterModifiersHydrate } from "../modifiers/actions";
import { flattenMods, mapModifierRespToDomain } from "../modifiers/effects";
import { MasterModifier } from "../modifiers/types";
import { MasterItem } from "../types";
import {
    optionSetCreateError,
    optionSetCreateSuccess,
    optionSetDeleteError,
    optionSetDeleteSuccess,
    optionSetFetchError,
    optionSetFetchSuccess,
    optionSetModifierSaveSuccess,
    optionSetPageFetch as optionSetPageFetchAction,
    optionSetPageFetchError,
    optionSetPageFetchSuccess,
    optionSetSaveSuccess,
} from "./actions";
import { modifierGroupsHydrate } from "./modifier-group/actions";
import modifierGroupSaga, { mapModifierGroupRespToDomain } from "./modifier-group/effects";
import { ModifierGroup, ModifierGroupResp, NewModifierGroup } from "./modifier-group/types";
import {
    OptionSet,
    OptionSetModifier,
    OptionSetPageResp,
    OptionSetReq,
    OptionSetResp,
    OPTION_SET_CREATE,
    OPTION_SET_DELETE,
    OPTION_SET_FETCH,
    OPTION_SET_PAGE_FETCH,
    OPTION_SET_SAVE,
    OPTION_SET_MODIFIER_SAVE,
} from "./types";

export const flattenOptionSets = (optionSets: OptionSet[]): OptionSet[] => {
    const flattenedOSes: OptionSet[] = [...optionSets];

    for (const os of optionSets) {
        os._embedded.modifier_group._embedded.modifiers.forEach((mgm) => {
            flattenedOSes.push(...flattenOptionSets(mgm._embedded.modifier._embedded.option_sets));
        });
    }
    return flattenedOSes;
};
const mapOptionSetPageResp = (resp: OptionSetPageResp): OptionSet[] => {
    return resp._embedded.option_sets.map((os) => os as OptionSet);
};
const mapOptionSetReq = (o: OptionSet): OptionSetReq => ({
    description: o.description,
    enabled: o.enabled,
    id: o.id,
    implicit: o.implicit,
    max: o.max,
    min: o.min,
    name: o.name,
    required: o.required,
    sequence: o.sequence,
});
const isModifierGroup = (group: ModifierGroup | NewModifierGroup): group is ModifierGroup => {
    return (group as ModifierGroup)?._links?.self?.type === "application/hal+json; name=mms_master_menu_modifier_group";
};
const modifierGroupUrl = (parent: MasterItem | MasterModifier): string => {
    return parent._links.self.href.replace(/(.+\bmenus\b\/[^\/]*).+$/, "$1/modifier_groups");
};
const optionSetUrl = (parent: MasterItem | MasterModifier): string => {
    return `${parent._links.self.href}/option_sets`;
};
const newOptionSet = (group: ModifierGroup): Omit<OptionSetReq, "id" | "sequence"> & { modifier_group: string } => ({
    description: "",
    enabled: false,
    implicit: false,
    max: 0,
    min: 0,
    modifier_group: group.id,
    name: group.display_name,
    required: false,
});

function* optionSetFetch({ url }: { url: string; type: typeof OPTION_SET_FETCH }) {
    try {
        const resp: { data: OptionSetResp } = yield call(core.get, url);
        const optionSet = resp.data;
        const mods = flattenMods(
            optionSet._embedded.modifier_group._embedded.modifiers.map((mgm) =>
                mapModifierRespToDomain(mgm._embedded.modifier),
            ),
        );

        yield put(modifierGroupsHydrate([mapModifierGroupRespToDomain(optionSet._embedded.modifier_group)]));
        yield put(masterModifiersHydrate(mods));
        for (const mod of mods) {
            yield put(optionSetPageFetchAction(mod._links.option_sets.href));
        }
        yield put(optionSetFetchSuccess(optionSet as OptionSet));
    } catch (e) {
        console.error("Failed to fetch Option Set", e);
        if (e instanceof Error) {
            yield put(optionSetFetchError(e));
        }
    }
}

function* optionSetPageFetch({ url }: { url: string; type: typeof OPTION_SET_PAGE_FETCH }) {
    try {
        const resp: { data: OptionSetPageResp } = yield call(core.get, url);
        const mods = resp.data._embedded.option_sets.reduce((mods: MasterModifier[], os) => {
            mods.push(
                ...flattenMods(
                    os._embedded.modifier_group._embedded.modifiers.map((mgm) =>
                        mapModifierRespToDomain(mgm._embedded.modifier),
                    ),
                ),
            );
            return mods;
        }, []);

        yield put(
            modifierGroupsHydrate(
                resp.data._embedded.option_sets.map((os) => mapModifierGroupRespToDomain(os._embedded.modifier_group)),
            ),
        );
        yield put(masterModifiersHydrate(mods));
        for (const mod of mods) {
            yield put(optionSetPageFetchAction(mod._links.option_sets.href));
        }
        yield put(optionSetPageFetchSuccess(mapOptionSetPageResp(resp.data)));
    } catch (e) {
        console.error("Failed to fetch Option Set Page", e);
        if (e instanceof Error) {
            yield put(optionSetPageFetchError(e));
        }
    }
}

function* optionSetCreate({
    group,
    parent,
    callback,
}: {
    type: typeof OPTION_SET_CREATE;
    group: ModifierGroup | NewModifierGroup;
    parent: MasterItem | MasterModifier;
    callback?: (optionSet?: OptionSet, error?: Error) => void;
}) {
    try {
        let createdGroup: ModifierGroup;
        if (isModifierGroup(group)) {
            createdGroup = group;
        } else {
            // create the group and get the id
            const groupResp: { data: ModifierGroupResp } = yield call(core.post, modifierGroupUrl(parent), group);

            createdGroup = mapModifierGroupRespToDomain(groupResp.data);
        }

        const osResp: { data: OptionSetResp } = yield call(core.post, optionSetUrl(parent), newOptionSet(createdGroup));
        const optionSet = osResp.data;
        const mods = flattenMods(
            optionSet._embedded.modifier_group._embedded.modifiers.map((mgm) =>
                mapModifierRespToDomain(mgm._embedded.modifier),
            ),
        );

        yield put(modifierGroupsHydrate([mapModifierGroupRespToDomain(optionSet._embedded.modifier_group)]));
        yield put(masterModifiersHydrate(mods));
        for (const mod of mods) {
            yield put(optionSetPageFetchAction(mod._links.option_sets.href));
        }
        if (callback !== undefined) {
            callback(optionSet as OptionSet);
        }
        yield put(optionSetCreateSuccess(optionSet as OptionSet));
    } catch (e) {
        console.error("Failed to create Option Set", e);
        if (e instanceof Error) {
            if (callback !== undefined) {
                callback(undefined, e);
            }
            yield put(optionSetCreateError(e));
        }
    }
}

function* optionSetSave({ optionSet }: { optionSet: OptionSet; type: typeof OPTION_SET_SAVE }) {
    try {
        const resp: { data: OptionSetResp } = yield call(
            core.put,
            optionSet._links.self.href,
            mapOptionSetReq(optionSet),
        );

        yield put(optionSetSaveSuccess(resp.data as OptionSet));
    } catch (e) {
        console.error("Failed to save Option Set", e);
        yield put(
            enqueueSnackbar({
                message: `Failed to save Option Set: ${axiosErrorMessage(e)}`,
                options: {
                    variant: "error",
                },
            }),
        );
    }
}

function* optionSetModifierSave({
    optionSet,
    optionSetModifier,
    callback,
}: {
    optionSet: OptionSet;
    optionSetModifier: OptionSetModifier;
    callback?: (error?: Error) => void;
    type: typeof OPTION_SET_MODIFIER_SAVE;
}) {
    try {
        const resp: { data: OptionSetModifier } = yield call(
            core.put,
            `${optionSet._links.modifiers.href}/${optionSetModifier.id}`,
            { default: optionSetModifier.default },
        );

        if (callback !== undefined) {
            callback();
        }
        yield put(optionSetModifierSaveSuccess(optionSet, resp.data));
    } catch (e) {
        console.error("Failed to save Option Set Modifier", e);
        if (e instanceof Error && callback !== undefined) {
            callback(e);
        }
    }
}

function* optionSetDelete({
    url,
    callback,
}: {
    url: string;
    callback?: (error?: Error) => void;
    type: typeof OPTION_SET_DELETE;
}) {
    try {
        const resp: { data: OptionSetResp } = yield call(core.delete, url);

        if (callback !== undefined) {
            yield call(callback);
        }
        yield put(optionSetDeleteSuccess(resp.data as OptionSet));
    } catch (e) {
        console.error("Failed to delete Option Set", e);
        if (e instanceof Error) {
            if (callback !== undefined) {
                yield call(callback, e);
            }
            yield put(optionSetDeleteError(e));
        }
    }
}

export default function* optionSetsSaga(): Generator<ForkEffect<void>, void, unknown> {
    yield fork(modifierGroupSaga);
    yield takeEvery(OPTION_SET_FETCH, optionSetFetch);
    yield takeEvery(OPTION_SET_PAGE_FETCH, optionSetPageFetch);
    yield takeEvery(OPTION_SET_CREATE, optionSetCreate);
    yield takeEvery(OPTION_SET_SAVE, optionSetSave);
    yield takeEvery(OPTION_SET_MODIFIER_SAVE, optionSetModifierSave);
    yield takeEvery(OPTION_SET_DELETE, optionSetDelete);
}
