import { Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import React, { useContext, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Section } from "store/mms/menus/master/sections/types";
import { RootState } from "store/rootReducer";
import { ItemCard } from "./ItemCard";
import { QuickSearchToolbar } from "components/common/forms/QuickSearchToolbar";
import { escapeRegExp } from "components/common/forms/helpers";
import { useForm, useFormState } from "react-final-form";
import { List } from "components/common/List";
import { ListItem } from "components/common/ListItem";

interface SectionListProps {
    name: string;
    sections: Section[];
}

export function SectionList({ name, sections }: SectionListProps): JSX.Element {
    const { change } = useForm();
    const { values } = useFormState();
    const [searchText, setSearchText] = useState("");
    const searchRegex = new RegExp(escapeRegExp(searchText), "i");
    const memoizedSelectedItems = useMemo<string[]>(() => {
        return values !== undefined && values[name] !== undefined ? values[name] : [];
    }, [name, values]);

    const uniqueItemIds = new Set<string>();
    for (const section of sections) {
        section._embedded.items
            .filter(
                (i) => searchRegex.test(i.display_name) || searchRegex.test(i.reference_name) || searchRegex.test(i.id),
            )
            .forEach((i) => uniqueItemIds.add(i.id));
    }
    const allItemIds = Array.from(uniqueItemIds);
    const allItemsCheckboxChecked = memoizedSelectedItems.length !== 0;
    const handleSelectAll = () => {
        change(name, allItemsCheckboxChecked ? [] : allItemIds);
    };

    return (
        <ItemSearchContext.Provider value={searchText}>
            <Grid container direction="column">
                <Grid item>
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={handleSelectAll}
                                        checked={allItemsCheckboxChecked}
                                        indeterminate={allItemsCheckboxChecked}
                                    />
                                }
                                label={`${memoizedSelectedItems.length === 0 ? "Select" : "Deselect"} All`}
                            />
                        </Grid>

                        <Grid item>
                            <QuickSearchToolbar
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                                clearSearch={() => setSearchText("")}
                                textFieldProps={{
                                    size: "small",
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item>
                    <List>
                        {sections.map((section) => (
                            <SectionListItem name={name} section={section} key={section.id} />
                        ))}
                    </List>
                </Grid>
            </Grid>
        </ItemSearchContext.Provider>
    );
}
interface SectionListItemProps {
    name: string;
    section: Section;
}

function SectionListItem({ name, section }: SectionListItemProps): JSX.Element {
    const itemsById = useSelector((state: RootState) => state.mms.menus.master.items.byId);
    const itemSearchText = useContext(ItemSearchContext);
    const searchRegex = new RegExp(escapeRegExp(itemSearchText), "i");
    const sectionItems = itemSearchText
        ? section._embedded.items.filter(
              (i) => searchRegex.test(i.display_name) || searchRegex.test(i.reference_name) || searchRegex.test(i.id),
          )
        : section._embedded.items;

    return (
        <ListItem ListItemTextProps={{ primary: section.name }} defaultExpanded={true}>
            <Grid container spacing={3}>
                {sectionItems.map((i) => (
                    <ItemCard name={name} item={itemsById[i.id]} key={i.id + section.id} />
                ))}
                {sectionItems.length === 0 && (
                    <Grid item>
                        <Typography>
                            {itemSearchText
                                ? "No Menu Items match your search. Try again."
                                : "Category contains no Menu Items."}
                        </Typography>
                    </Grid>
                )}
            </Grid>
        </ListItem>
    );
}

const ItemSearchContext = React.createContext("");
