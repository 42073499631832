import { core } from "services/api";
import { getSession } from "services/session";
import { accountFetchSuccess, accountFetchError } from "./actions";
import { ACCOUNT_FETCH } from "./types";
import { call, put, takeLatest, ForkEffect } from "redux-saga/effects";

function* fetchAccount() {
    try {
        const s = getSession();
        const account: { data: { company: string } } = yield call(core.get, `/1.1/accounts/${s?.accountId}`);

        yield put(accountFetchSuccess({ company: account.data.company }));
    } catch (e) {
        console.error("Failed to load account", e);
        if (e instanceof Error) {
            yield put(accountFetchError(e));
        }
    }
}

export function* accountSaga(): Generator<ForkEffect<never>, void, unknown> {
    yield takeLatest(ACCOUNT_FETCH, fetchAccount);
}
