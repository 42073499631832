import { Alert, Box, Button, Checkbox, FormControlLabel, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { Restore as RestoreIcon } from "@mui/icons-material";
import { getIn } from "final-form";
import React from "react";
import { Field, useField, useForm, useFormState } from "react-final-form";
import { useSelector } from "react-redux";
import { Period } from "store/mms/menus/master/periods/types";
import { RootState } from "store/rootReducer";
import { generateUIBlocks, newBlock, validateBlocks } from "components/mms/MenuPeriods/helpers";
import { FieldArray } from "react-final-form-arrays";
import { MenuPeriodBlock } from "components/mms/MenuPeriods/MenuPeriodBlock";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/pro-solid-svg-icons";
import { enableTransformTypePeriods, transformTypePeriodsConfig } from "./constants";
import { ListItem } from "components/common/ListItem";
import { List } from "components/common/List";
import { isEqual } from "lodash";

export function PeriodsList(): JSX.Element {
    const { values } = useFormState();
    const menuId = getIn(values, "menuId") || "";
    const periodsById = useSelector((state: RootState) => state.mms.menus.master.periods.byId);
    const periodsByMenuId = useSelector((state: RootState) => state.mms.menus.master.periods.byMenuId);
    const periods = periodsByMenuId[menuId].map((periodId) => periodsById[periodId]);

    return (
        <List>
            {periods.map((period, index) => (
                <PeriodListItem key={period.id} period={period} index={index} />
            ))}
        </List>
    );
}

interface PeriodListItemProps {
    period: Period;
    index: number;
}

function PeriodListItem({ period, index }: PeriodListItemProps): JSX.Element {
    const { mutators, change, resetFieldState } = useForm();
    const { values } = useFormState();
    const periodFieldName = `${transformTypePeriodsConfig}[${index}]`;
    const blocksFieldName = `${periodFieldName}.blocks`;
    const { input, meta } = useField(blocksFieldName);
    const disabled = !getIn(values, enableTransformTypePeriods) || false;
    const periodBlocks = generateUIBlocks(period);
    const isInherited = isEqual(input.value, periodBlocks) || (meta.pristine && isEqual(meta.initial, periodBlocks));

    const handleFieldReset = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        change(blocksFieldName, periodBlocks);
        resetFieldState(blocksFieldName);
        e.stopPropagation();
    };
    const resetMasterTitle = "Reset to Master Menu Settings";

    return (
        <ListItem
            ListItemTextProps={{ primary: period.display_name }}
            actions={
                isInherited ? (
                    <Typography>(Inherited from Master Menu)</Typography>
                ) : (
                    <IconButton title={resetMasterTitle} onClick={handleFieldReset}>
                        <Tooltip title={resetMasterTitle} arrow>
                            <RestoreIcon fontSize="inherit" />
                        </Tooltip>
                    </IconButton>
                )
            }
            defaultExpanded={true}
        >
            <Box
                sx={(theme) => ({
                    padding: theme.spacing(2, 5),
                })}
            >
                <Typography variant="h6">
                    Define what hours your kitchen will be open for ordering during the week.
                </Typography>
                <Box
                    component="ul"
                    sx={(theme) => ({
                        listStyle: "none",
                        padding: 0,

                        "& > li": {
                            borderBottom: `1px solid ${theme.mixins.borderColor}`,
                            paddingBottom: theme.spacing(4),
                        },

                        "& > li:first-of-type": {
                            paddingTop: 0,
                        },
                    })}
                >
                    <FieldArray name={blocksFieldName} validate={validateBlocks}>
                        {({ fields }) => {
                            if (fields.length === 0) {
                                return (
                                    <Alert sx={{ marginTop: (theme) => theme.spacing(2) }} severity="warning">
                                        Menu Sections associated with this Period will{" "}
                                        <strong>not be available for ordering</strong> unless at least one Time Block is
                                        defined.
                                    </Alert>
                                );
                            }
                            return fields.map((name, index) => (
                                <MenuPeriodBlock
                                    disabled={disabled}
                                    key={name}
                                    fields={fields}
                                    name={name}
                                    index={index}
                                    isMasterPeriod={true}
                                />
                            ));
                        }}
                    </FieldArray>
                </Box>
                <Grid container justifyContent="space-between" sx={(theme) => ({ marginTop: theme.spacing(2) })}>
                    <Grid item>
                        <Button
                            data-testid="add-time-block"
                            onClick={() => mutators.push(blocksFieldName, newBlock())}
                            startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
                            disabled={disabled}
                        >
                            Add Time Block
                        </Button>
                    </Grid>

                    <Grid item>
                        <Field
                            name={`${periodFieldName}.preventLocationOverride`}
                            type="checkbox"
                            render={({ input: { name, checked, onChange, value } }) => (
                                <FormControlLabel
                                    label="Prevent Location Override"
                                    htmlFor={`${periodFieldName}.preventLocationOverride`}
                                    control={
                                        <Checkbox
                                            name={name}
                                            checked={checked}
                                            onChange={onChange}
                                            value={value}
                                            id={`${periodFieldName}.preventLocationOverride`}
                                        />
                                    }
                                    labelPlacement="start"
                                    disabled={disabled}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </Box>
        </ListItem>
    );
}
