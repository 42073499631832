import React, { useContext, useEffect, useState } from "react";
import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { LocationMenuApplication } from "store/mms/menus/location/applications/types";
import { format } from "date-fns";
import { DateTimePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { useDispatch } from "react-redux";
import { axiosErrorMessage } from "store/types";
import { applicationActionOrderingEnable } from "store/mms/application-actions/actions";
import { ApplicationActionTargets } from "store/mms/application-actions/types";
import { LocationContext } from "pages/Locations/context";

interface ApplicationSnoozeConfirmDialogProps {
    application: LocationMenuApplication;
    snoozeDuration: number;
    open: boolean;
    handleClose: () => void;
    onChange: () => void;
}

export default function ApplicationSnoozeConfirmDialog({
    application,
    snoozeDuration,
    open,
    handleClose,
    onChange,
}: ApplicationSnoozeConfirmDialogProps): JSX.Element {
    const [err, setError] = useState("");
    const [snoozeUntil, setSnoozeUntil] = useState<Date | null>(null);
    const [customDatetimeError, setCustomDatetimeError] = useState("");
    const isCustomDuration = snoozeDuration === 0;
    const minDateTime = new Date(Date.now() + 300000); // now + 5 minutes

    const { menuId, locationId } = useContext(LocationContext);

    const dispatch = useDispatch();

    useEffect(() => {
        if (open) {
            setCustomDatetimeError("");
            if (isCustomDuration) {
                setSnoozeUntil(null);
            } else {
                const initialSnoozeDuration = new Date(isCustomDuration ? Date.now() + 360000 : snoozeDuration);
                setSnoozeUntil(initialSnoozeDuration);
            }
        }
    }, [isCustomDuration, open, snoozeDuration]);

    const handleConfirm = () => {
        if (!snoozeUntil) {
            setError("Must select a time to snooze until.");
            return;
        }

        const target: ApplicationActionTargets = {
            menus: [menuId],
            locations: [locationId],
            applications: [application.id],
        };

        const snoozeUntilUnix = Math.round(snoozeUntil.getTime() / 1000);

        // Schedule an event to re-enable (end the snooze)
        dispatch(
            applicationActionOrderingEnable(snoozeUntilUnix, target, { enabled: true }, (error?: Error) => {
                if (!error) {
                    // Send an immediate event to disable (start the snooze)
                    dispatch(applicationActionOrderingEnable(null, target, { enabled: false }));
                    // force a state change on the parent component
                    onChange();
                    handleClose();
                }
                return error && setError(axiosErrorMessage(error).replace("start time", "snooze time"));
            }),
        );
    };

    const handleChange = (endTime: Date | null) => {
        if (endTime && endTime.getTime()) {
            setCustomDatetimeError("");
            setSnoozeUntil(endTime);
        } else {
            setCustomDatetimeError("Invalid date or time entered.");
        }
    };

    const handleCancel = () => {
        handleClose();
        setError("");
    };

    return (
        <>
            <Dialog open={open} onClose={handleCancel}>
                <DialogTitle id="confirm-snooze-dialog-title">Confirm Snooze</DialogTitle>
                <DialogContent id="confirm-snooze-dialog-description">
                    {!snoozeDuration && (
                        <Stack sx={{ marginBottom: (theme) => theme.spacing(2) }}>
                            <Typography sx={{ marginBottom: (theme) => theme.spacing(2) }} variant="caption">
                                Select a time to snooze until
                            </Typography>

                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DateTimePicker
                                    label="Snooze until"
                                    inputFormat="yyyy/MM/dd hh:mm a"
                                    mask="____/__/__ __:__ _"
                                    minDateTime={minDateTime}
                                    value={snoozeUntil}
                                    onChange={handleChange}
                                    onAccept={handleChange}
                                    renderInput={(props) => <TextField {...props} error={false} />}
                                />
                            </LocalizationProvider>
                            {customDatetimeError && (
                                <Alert severity="error" sx={{ marginTop: (theme) => theme.spacing(1) }}>
                                    {customDatetimeError}
                                </Alert>
                            )}
                        </Stack>
                    )}
                    {snoozeUntil !== null && (
                        <Typography>
                            Are you sure you wish to snooze {application.name} until
                            {format(new Date(snoozeUntil), " yyyy-MM-dd @ p")} (
                            {Intl.DateTimeFormat().resolvedOptions().timeZone.replace("_", " ")})?
                        </Typography>
                    )}
                    {err && <Alert severity="error">{err}</Alert>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button disabled={!snoozeUntil || customDatetimeError !== ""} onClick={handleConfirm} autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
