import React, { useState, useEffect } from "react";
import { DayOfWeek, MAX_GRACE_PERIOD, MAX_START_BUFFER } from "store/mms/menus/master/periods/types";
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    Theme,
    Tooltip,
    Typography,
    useMediaQuery,
} from "@mui/material";
import { makeStyles } from "theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/pro-solid-svg-icons";
import { Field, useForm, useFormState } from "react-final-form";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { FieldArrayRenderProps } from "react-final-form-arrays";
import { getIn } from "final-form";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider, TimePicker } from "@mui/lab";
import Hint from "components/common/info/Hint";

interface MenuPeriodBlockProps extends Omit<FieldArrayRenderProps<unknown, HTMLElement>, "meta"> {
    name: string;
    index: number;
    disabled?: boolean;
    isMasterPeriod?: boolean;
}

export function MenuPeriodBlock({
    fields,
    name,
    index,
    disabled = false,
    isMasterPeriod = false,
}: MenuPeriodBlockProps): JSX.Element {
    const isLargeDesktop = useMediaQuery("(min-width: 1840px)");
    const { classes } = useMenuPeriodBlockStyles();
    const { change } = useForm();
    const { values } = useFormState();
    const allDaysInWeek = Object.values(DayOfWeek);
    const blockDays = getIn(values, `${name}.days`) || [];
    const [isEveryDay, setIsEveryDay] = useState(blockDays.length === allDaysInWeek.length);
    const minimumRequiredPeriodBlocks = isMasterPeriod ? 0 : 1;

    useEffect(() => {
        setIsEveryDay(blockDays.length === allDaysInWeek.length);
    }, [allDaysInWeek.length, blockDays.length]);

    return (
        <li>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} className={classes.blockLabelContainer}>
                            <Grid container alignItems="center" spacing={2}>
                                <Grid item>
                                    <FormLabel className={classes.blockLabel} htmlFor={`days-${index}`}>{`Block ${
                                        index + 1
                                    }`}</FormLabel>
                                </Grid>
                                {fields.length !== undefined && fields.length > minimumRequiredPeriodBlocks && (
                                    <Grid item>
                                        <Tooltip arrow title="Remove Time Block">
                                            <IconButton
                                                onClick={() => fields.remove(index)}
                                                className={classes.removeBlock}
                                                disabled={disabled}
                                            >
                                                <FontAwesomeIcon size="xs" icon={faTrashAlt} />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>

                        <Grid item xs={isLargeDesktop ? 6 : 12}>
                            <Grid container direction="column">
                                <Grid item>
                                    <DaysSelector disabled={disabled} index={index} name={name} />
                                </Grid>
                                <Grid item>
                                    <>
                                        <FormControlLabel
                                            id={`id-isEveryDay-${index}`}
                                            label={
                                                <Typography variant="caption">
                                                    Every Day
                                                    <Hint
                                                        title={
                                                            <>
                                                                <strong>Ctrl + Click:</strong> Select Weekends
                                                                <br />
                                                                <strong>Shift + Click:</strong> Select Weekdays
                                                            </>
                                                        }
                                                    />
                                                </Typography>
                                            }
                                            control={
                                                <Checkbox
                                                    value={isEveryDay}
                                                    checked={isEveryDay}
                                                    onChange={(_, checked: boolean) => {
                                                        change(`${name}.days`, checked ? allDaysInWeek : []);
                                                        setIsEveryDay(checked);
                                                    }}
                                                    size="small"
                                                    disabled={disabled || blockDays.length === 7}
                                                    data-testid="set-every-day"
                                                />
                                            }
                                        />
                                    </>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={isLargeDesktop ? 6 : 12}>
                            <Grid container spacing={2} className={classes.timePickers}>
                                <Grid item xs={6}>
                                    <Field name={`${name}.start`}>
                                        {({ input }) => (
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <TimePicker
                                                    {...input}
                                                    label="Open"
                                                    mask="__:__ _M"
                                                    disabled={disabled}
                                                    renderInput={(props) => (
                                                        <TextField
                                                            id={`id-start-${index}`}
                                                            placeholder={"10:00 AM"}
                                                            {...props}
                                                            inputProps={{
                                                                ...props.inputProps,
                                                                "aria-label": input.name,
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                        )}
                                    </Field>
                                </Grid>

                                <Grid item xs={6}>
                                    <Field name={`${name}.end`}>
                                        {({ input }) => (
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <TimePicker
                                                    {...input}
                                                    label="Close"
                                                    mask="__:__ _M"
                                                    disabled={disabled || getIn(values, `${name}.is24Hours`) === true}
                                                    renderInput={(props) => (
                                                        <TextField
                                                            id={`id-end-${index}`}
                                                            placeholder={"8:00 PM"}
                                                            {...props}
                                                            inputProps={{
                                                                ...props.inputProps,
                                                                "aria-label": input.name,
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                        )}
                                    </Field>
                                </Grid>

                                <Grid item xs={12} className={classes.is24Hours}>
                                    <Field name={`${name}.is24Hours`} type="checkbox">
                                        {({ input }) => (
                                            <FormControlLabel
                                                id={`id-is24Hours-${index}`}
                                                label={<Typography variant="caption">24 Hours</Typography>}
                                                control={
                                                    <Checkbox
                                                        {...input}
                                                        size="small"
                                                        disabled={disabled || getIn(values, `${name}.end`) === null}
                                                    />
                                                }
                                            />
                                        )}
                                    </Field>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Field name={`${name}.startBuffer`} parse={(v) => (isNaN(parseInt(v, 10)) ? "" : parseInt(v, 10))}>
                        {({
                            input: { onChange, value },
                            meta: { submitError, dirtySinceLastSubmit, error, touched },
                        }) => (
                            <FormControl fullWidth>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <FormLabel htmlFor={`id-startBuffer-${index}`}>
                                            Accept Orders before the Period&apos;s start time.
                                        </FormLabel>
                                        <Hint
                                            title={`Enables Orders to be placed up to ${MAX_START_BUFFER} minutes before the Menu Period and picked up once it starts.`}
                                        />
                                    </Grid>

                                    <Grid item xs={isLargeDesktop ? 3 : 4}>
                                        <TextField
                                            id={`id-startBuffer-${index}`}
                                            type="number"
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">minutes</InputAdornment>,
                                            }}
                                            inputProps={{
                                                max: MAX_START_BUFFER,
                                                min: 0,
                                            }}
                                            onChange={(
                                                event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
                                            ) => onChange(event.target.value ?? parseInt(event.target.value, 10))}
                                            error={((submitError && !dirtySinceLastSubmit) || error) && touched}
                                            helperText={
                                                ((submitError && !dirtySinceLastSubmit) || error) &&
                                                touched &&
                                                (error || submitError)
                                            }
                                            value={value}
                                            disabled={disabled}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </FormControl>
                        )}
                    </Field>
                </Grid>

                <Grid item xs={12}>
                    <Field name={`${name}.eodBuffer`} parse={(v) => (isNaN(parseInt(v, 10)) ? "" : parseInt(v, 10))}>
                        {({
                            input: { onChange, value },
                            meta: { submitError, dirtySinceLastSubmit, error, touched },
                        }) => (
                            <FormControl fullWidth>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <FormLabel htmlFor={`id-eodBuffer-${index}`}>
                                            Accept Order pickups after the Period&apos;s end time.
                                        </FormLabel>
                                        <Hint
                                            title={`Enables Orders placed during the Menu Period to be picked up after it ends. Up to ${MAX_GRACE_PERIOD} minutes.`}
                                        />
                                    </Grid>

                                    <Grid item xs={isLargeDesktop ? 3 : 4}>
                                        <TextField
                                            id={`id-eodBuffer-${index}`}
                                            type="number"
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">minutes</InputAdornment>,
                                            }}
                                            inputProps={{
                                                max: MAX_GRACE_PERIOD,
                                                min: 0,
                                            }}
                                            onChange={(
                                                event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
                                            ) => onChange(event.target.value ?? parseInt(event.target.value, 10))}
                                            error={((submitError && !dirtySinceLastSubmit) || error) && touched}
                                            helperText={
                                                ((submitError && !dirtySinceLastSubmit) || error) &&
                                                touched &&
                                                (error || submitError)
                                            }
                                            value={value}
                                            disabled={disabled}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </FormControl>
                        )}
                    </Field>
                </Grid>
            </Grid>
        </li>
    );
}

interface DaysSelectorProps {
    name: string;
    index: number;
    disabled?: boolean;
}

function DaysSelector({ name, index, disabled }: DaysSelectorProps): JSX.Element {
    const { classes } = useDaysSelectorStyles();

    const [state, setState] = useState<{ selectWeekends: boolean; selectWeekdays: boolean }>({
        selectWeekends: false,
        selectWeekdays: false,
    });

    useEffect(() => {
        const onKeyDown = (key: KeyboardEvent) => {
            const changes: { [key: string]: boolean } = {};
            if (!state.selectWeekends && key.ctrlKey) changes["selectWeekends"] = true;
            if (!state.selectWeekdays && key.shiftKey) changes["selectWeekdays"] = true;

            Object.keys(changes).length > 0 && setState({ ...state, ...changes });
        };

        const onKeyUp = (key: KeyboardEvent) => {
            const changes: { [key: string]: boolean } = {};
            if (state.selectWeekends && key.key === "Control") changes["selectWeekends"] = false;
            if (state.selectWeekdays && key.key === "Shift") changes["selectWeekdays"] = false;

            Object.keys(changes).length > 0 && setState({ ...state, ...changes });
        };

        document.addEventListener("keydown", onKeyDown);
        document.addEventListener("keyup", onKeyUp);

        return () => {
            document.removeEventListener("keydown", onKeyDown);
            document.removeEventListener("keyup", onKeyUp);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    function checkInputModifiers(value: DayOfWeek[]): DayOfWeek[] {
        const days = [];
        const weekdays = [
            DayOfWeek.Monday,
            DayOfWeek.Tuesday,
            DayOfWeek.Wednesday,
            DayOfWeek.Thursday,
            DayOfWeek.Friday,
        ];
        const weekend = [DayOfWeek.Saturday, DayOfWeek.Sunday];

        if (!state.selectWeekdays && !state.selectWeekends) days.push(...value);
        if (state.selectWeekdays) days.push(...weekdays);
        if (state.selectWeekends) days.push(...weekend);

        const weekdayOrder = Object.values(DayOfWeek);
        return days.sort((a, b) => weekdayOrder.indexOf(a) - weekdayOrder.indexOf(b));
    }

    const getButtonText = (dow: DayOfWeek): string => {
        let dowTitle = "Su";
        switch (dow) {
            case DayOfWeek.Monday:
                dowTitle = "M";
                break;
            case DayOfWeek.Tuesday:
                dowTitle = "Tu";
                break;
            case DayOfWeek.Wednesday:
                dowTitle = "W";
                break;
            case DayOfWeek.Thursday:
                dowTitle = "Th";
                break;
            case DayOfWeek.Friday:
                dowTitle = "F";
                break;
            case DayOfWeek.Saturday:
                dowTitle = "S";
                break;
        }
        return dowTitle;
    };

    return (
        <Field
            name={`${name}.days`}
            key={name}
            validate={(days) => (days && days.length > 0 ? undefined : "At least one day of the week is required.")}
        >
            {({ input: { onChange, value }, meta: { submitError, dirtySinceLastSubmit, error, touched } }) => {
                const hasError = Boolean(((submitError && !dirtySinceLastSubmit) || error) && touched);

                return (
                    <>
                        <FormHelperText error={hasError}>{hasError ? error || submitError : " "}</FormHelperText>
                        <ToggleButtonGroup
                            className={classes.root}
                            id={`days-${index}`}
                            onChange={(e: React.MouseEvent<HTMLElement, MouseEvent>, value: unknown) => {
                                onChange(checkInputModifiers(value as DayOfWeek[]));
                            }}
                            value={value}
                            title={`block ${index} days`}
                        >
                            {Object.values(DayOfWeek).map((dow, idx) => (
                                <ToggleButton
                                    disabled={disabled}
                                    value={dow}
                                    {...a11yProps(dow)}
                                    data-testid={dow}
                                    key={idx}
                                >
                                    {getButtonText(dow)}
                                </ToggleButton>
                            ))}
                        </ToggleButtonGroup>
                    </>
                );
            }}
        </Field>
    );
}

function a11yProps(dow: DayOfWeek) {
    return {
        "aria-label": dow,
        role: "button",
    };
}

const useMenuPeriodBlockStyles = makeStyles()((theme: Theme) => ({
    blockLabelContainer: {
        marginBottom: theme.spacing(-4),
        paddingBottom: "0 !important",
        paddingTop: `${theme.spacing(3)}px !important`,
    },
    blockLabel: {
        display: "inline-flex",
        minHeight: "46px",
        alignItems: "center",
    },
    removeBlock: {
        marginBottom: theme.spacing(0.5),
    },
    is24Hours: {
        marginTop: theme.spacing(-2),
    },
    timePickers: {
        marginTop: theme.spacing(2),
    },
}));

const useDaysSelectorStyles = makeStyles()((theme: Theme) => ({
    root: {
        "& .MuiToggleButton-root": {
            minWidth: "48px",
            minHeight: "48px",
        },

        "& .MuiToggleButton-root.Mui-selected + .MuiToggleButton-root.Mui-selected": {
            borderLeft: `1px solid ${theme.palette.divider}`,
            marginLeft: "-1px",
        },

        "& .MuiToggleButton-root.Mui-selected": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
        },
    },
}));
