import { TextField, Typography, Alert, Theme } from "@mui/material";
import { makeStyles } from "theme";
import ConfirmDialog from "components/common/ConfirmDialog";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { axiosErrorMessage } from "store/types";
import { MenuLayer } from "store/mms/menus/master/layers/types";
import { ruleSetAdd } from "store/mms/menus/master/layers/rule-sets/actions";
import { NewMenuLayerRuleSet } from "store/mms/menus/master/layers/rule-sets/types";

interface RuleSetAddProps {
    layer: MenuLayer;
    onClose(): void;
}

export function RuleSetAdd({ layer, onClose }: RuleSetAddProps): JSX.Element {
    const dispatch = useDispatch();
    const { classes } = useStyles();
    const [name, setName] = useState("");
    const [state, setState] = useState<{ saving: boolean; error?: string }>({
        saving: false,
    });
    const title = "Add Rule Set";

    return (
        <ConfirmDialog
            title={title}
            open={true}
            onClose={onClose}
            onConfirm={() => {
                setState(() => ({ saving: true }));
                dispatch(
                    ruleSetAdd(layer, createNewMenuLayerRuleSet(name), (error?: Error): void => {
                        if (error) {
                            return setState({ saving: false, error: axiosErrorMessage(error) });
                        }
                        setState({ saving: false });
                        onClose();
                    }),
                );
            }}
            confirm={title}
            confirming={state.saving}
            maxWidth="xl"
            transitionDuration={0}
            disableConfirm={name.length === 0}
        >
            <Typography variant="h5" className={classes.layerName}>
                {layer.name}
            </Typography>
            <TextField
                label="Name"
                variant="outlined"
                helperText="Internal use only — will not appear on the Menu."
                onChange={(e) => setName(e.target.value)}
                value={name}
                InputProps={{ autoFocus: true }}
                fullWidth
            />
            {state.error && (
                <Alert variant="outlined" severity="error">
                    {state.error}
                </Alert>
            )}
        </ConfirmDialog>
    );
}

const useStyles = makeStyles()((theme: Theme) => ({ layerName: { marginBottom: theme.spacing(4) } }));
const createNewMenuLayerRuleSet = (name: string): NewMenuLayerRuleSet => ({
    name,
    enabled: false,
});
