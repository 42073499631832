import { Tab, Tabs, Typography, Theme, Grid, Skeleton } from "@mui/material";
import { makeStyles } from "theme";
import { Link as RouterLink, useLocation, useParams } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import DefaultLayout from "components/common/layouts/DefaultLayout";
import TabPanel from "components/common/TabPanel";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/rootReducer";
import { menuPageFetch } from "store/mms/menus/master/actions";
import { getBreadcrumbSkeleton } from "components/skeletons";
import { MasterMenuSections } from "components/mms/MenuSections/MasterMenuSections";
import { MasterMenuContext, MasterMenuProvider, MenuEvent } from "./context";
import MasterMenuPeriods from "components/mms/MenuPeriods/MasterMenuPeriods";
import { MenuLayersList } from "components/mms/MenuLayers/MenuLayersList";
import { ActionButton, ActionButtonContainer } from "components/common/buttons/ActionButton";
import { EntityEdit } from "components/mms/IconButtons/EntityEdit";
import { EntitySelector } from "components/common/EntitySelector";
import { MasterMenuSyncLog } from "components/mms/MasterMenuSyncLog";

export enum masterMenuPane {
    menu = 0,
    periods = 1,
    layers = 2,
    menuSyncs = 3,
}

export function MasterMenu(): JSX.Element {
    const { classes } = useMasterMenuStyles();
    const dispatch = useDispatch();
    const location = useLocation();

    const { menuId } = useParams() as { menuId: string };

    const [state, setState] = useState({
        tab: location.pathname.includes("layers")
            ? masterMenuPane.layers
            : location.pathname.includes("periods")
            ? masterMenuPane.periods
            : location.pathname.includes("menu-syncs")
            ? masterMenuPane.menuSyncs
            : masterMenuPane.menu,
        menuId: menuId,
    });

    const menusById = useSelector((state: RootState) => state.mms.menus.master.menus.byId);
    const masterMenusFetching = useSelector((state: RootState) => state.mms.menus.master.menus.fetching);
    const menu = menusById[state.menuId];

    const isLoading = masterMenusFetching || menu === undefined;
    const breadcrumbs = isLoading
        ? getBreadcrumbSkeleton(1)
        : [
              <Typography key="menu" color="textPrimary">
                  {menu.name}
              </Typography>,
          ];

    useEffect(() => {
        if (menu === undefined) {
            dispatch(menuPageFetch());
        }
    }, [dispatch, menu, menuId]);

    useEffect(() => {
        setState((prev) => ({ ...prev, menuId: menuId }));
    }, [menuId]);

    return (
        <MasterMenuProvider>
            <DefaultLayout
                breadcrumbs={[
                    <RouterLink key="menus" to="/menus">
                        Menus
                    </RouterLink>,
                    ...breadcrumbs,
                ]}
                header={
                    <>
                        <Header isLoading={isLoading} tab={state.tab} />
                        <Tabs
                            sx={{ marginTop: (theme) => theme.spacing(1) }}
                            indicatorColor="secondary"
                            textColor="primary"
                            value={state.tab}
                            onChange={(e, tab: masterMenuPane) => setState((prevState) => ({ ...prevState, tab }))}
                        >
                            <Tab
                                to={`/menus/${state.menuId}`}
                                component={RouterLink}
                                label="Menu"
                                {...a11yProps(masterMenuPane.menu)}
                            />
                            <Tab
                                to={`/menus/${state.menuId}/periods`}
                                component={RouterLink}
                                label="Hours"
                                {...a11yProps(masterMenuPane.periods)}
                            />
                            <Tab
                                to={`/menus/${state.menuId}/layers`}
                                component={RouterLink}
                                label="Layers"
                                {...a11yProps(masterMenuPane.layers)}
                            />
                            <Tab
                                to={`/menus/${state.menuId}/menu-syncs`}
                                component={RouterLink}
                                label="Menu Syncs"
                                {...a11yProps(masterMenuPane.menuSyncs)}
                            />
                        </Tabs>
                    </>
                }
            >
                <TabPanel className={classes.tabPanel} index={masterMenuPane.menu} value={state.tab}>
                    <MasterMenuSections />
                </TabPanel>

                <TabPanel className={classes.tabPanel} index={masterMenuPane.periods} value={state.tab}>
                    <Typography variant="h6" className={classes.tabTitle}>
                        <MasterMenuPeriods />
                    </Typography>
                </TabPanel>

                <TabPanel className={classes.tabPanel} index={masterMenuPane.layers} value={state.tab}>
                    <Typography variant="h6" className={classes.tabTitle}>
                        <MenuLayersList />
                    </Typography>
                </TabPanel>
                <TabPanel
                    className={classes.tabPanel}
                    index={masterMenuPane.menuSyncs}
                    value={state.tab}
                    disableHiddenChildren
                >
                    <MasterMenuSyncLog />
                </TabPanel>
            </DefaultLayout>
        </MasterMenuProvider>
    );
}

function a11yProps(index: number) {
    return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`,
    };
}

const useMasterMenuStyles = makeStyles()((theme: Theme) => ({
    tabPanel: {
        marginTop: theme.spacing(2),
        height: "100%",
    },
    tabTitle: {
        marginBottom: theme.spacing(2),
    },
}));

interface HeaderProps {
    isLoading: boolean;
    tab: masterMenuPane;
}

function Header({ isLoading, tab }: HeaderProps): JSX.Element {
    const { menu, onChange } = useContext(MasterMenuContext);
    const menusById = useSelector((state: RootState) => state.mms.menus.master.menus.byId);
    const actions = [];

    switch (tab) {
        case masterMenuPane.menu:
            actions.unshift(
                <ActionButton
                    key="section-add"
                    label="Add Category"
                    onClick={() => onChange({ type: MenuEvent.SectionAdd })}
                />,
                <ActionButton key="item-add" label="Add Item" onClick={() => onChange({ type: MenuEvent.ItemAdd })} />,
            );
            break;
        case masterMenuPane.periods:
            actions.unshift(
                <ActionButton
                    key="period-add"
                    label="Add Period"
                    onClick={() => onChange({ type: MenuEvent.PeriodAdd })}
                />,
            );
            break;
        case masterMenuPane.layers:
            actions.unshift(
                <ActionButton
                    key="layer-add"
                    label="Add Layer"
                    onClick={() => onChange({ type: MenuEvent.LayerAdd })}
                />,
            );
            break;
    }

    return (
        <Grid container justifyContent="space-between" direction="row">
            <Grid item xs={7} sm={8} md={6}>
                {isLoading ? (
                    <Skeleton />
                ) : (
                    <Grid container direction="row" alignItems="center">
                        <Grid item xs={7}>
                            <EntitySelector
                                selectedId={menu?.id || ""}
                                entities={Object.values(menusById).sort((a, b) => {
                                    const nameA = a.name.toLowerCase();
                                    const nameB = b.name.toLowerCase();
                                    if (nameA < nameB) {
                                        return -1;
                                    }
                                    if (nameA > nameB) {
                                        return 1;
                                    }

                                    return 0;
                                })}
                                onChange={(menuId) => onChange({ type: MenuEvent.MenuSelect, menuId })}
                                sx={(theme) => ({
                                    [theme.breakpoints.down("sm")]: { marginTop: 0 },
                                })}
                            />
                        </Grid>
                        <Grid item>
                            <EntityEdit
                                title="Edit Master Menu"
                                sx={{ marginLeft: (theme) => theme.spacing(1) }}
                                onClick={() => onChange({ type: MenuEvent.MenuEdit })}
                            />
                        </Grid>
                    </Grid>
                )}
            </Grid>
            {!isLoading && (
                <Grid item>
                    <ActionButtonContainer>{actions}</ActionButtonContainer>
                </Grid>
            )}
        </Grid>
    );
}
