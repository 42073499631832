import { Nutrition } from "store/mms/types";
import {
    EntityWithNutrition,
    ENTITY_WITH_NUTRITION_DELETE,
    ENTITY_WITH_NUTRITION_DELETE_SUCCESS,
    ENTITY_WITH_NUTRITION_UPDATE,
    ENTITY_WITH_NUTRITION_UPDATE_SUCCESS,
    NutritionActions,
} from "./types";

export function entityNutritionUpdate(
    entity: EntityWithNutrition,
    nutrition: Nutrition,
    onComplete?: (error?: Error) => void,
): NutritionActions {
    return { type: ENTITY_WITH_NUTRITION_UPDATE, entity, nutrition, onComplete };
}

export function entityNutritionUpdateSuccess(entity: EntityWithNutrition, nutrition: Nutrition): NutritionActions {
    return { type: ENTITY_WITH_NUTRITION_UPDATE_SUCCESS, entity, nutrition };
}

export function entityNutritionDelete(
    entity: EntityWithNutrition,
    onComplete?: (error?: Error) => void,
): NutritionActions {
    return { type: ENTITY_WITH_NUTRITION_DELETE, entity, onComplete };
}

export function entityNutritionDeleteSuccess(entity: EntityWithNutrition): NutritionActions {
    return { type: ENTITY_WITH_NUTRITION_DELETE_SUCCESS, entity };
}
