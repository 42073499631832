import { Skeleton } from "@mui/material";
import React, { ReactNode, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import { RootState } from "store/rootReducer";
import { locationItemSave } from "store/mms/menus/location/items/actions";
import { OmniDrawer } from "components/common/OmniDrawer";
import EditPOSConfig from "components/mms/EditPOSConfig";
import { buildTraversal, ItemStrategy, LocationItem } from "store/mms/menus/location/items/types";
import { Period as LocationPeriod } from "store/mms/menus/location/periods/types";
import { POSConfig } from "store/mms/types";
import { jobCreate } from "store/mms/jobs/actions";
import { JobType } from "store/mms/jobs/types";
import { getSession } from "services/session";
import EditMenuPeriod from "components/mms/MenuPeriods/EditMenuPeriod";
import { periodBlocksSave as locationPeriodBlocksSave } from "store/mms/menus/location/periods/actions";
import { LocationMenuPeriodReset } from "components/mms/MenuPeriods/LocationMenuPeriodReset";
import { LocationItemVisibility } from "components/mms/MenuSections/LocationItemVisibility";
import { getNewTaxRate, NewTaxRate, TaxRate } from "store/mms/menus/location/taxes/types";
import { LocationTaxRateDelete } from "components/mms/Locations/LocationTaxRateDelete";
import { LocationTaxRateVisibility } from "components/mms/Locations/LocationTaxRateVisibility";
import { LocationTaxRateEdit } from "components/mms/Locations/LocationTaxRateEdit";
import { ApplicationAuditLog } from "components/mms/MenuApplications/ApplicationAuditLog";
import { ApplicationScheduledEvents } from "components/mms/MenuApplications/ApplicationScheduledEvent";
import { LocationMenuApplication } from "store/mms/menus/location/applications/types";
import { LocationMenu } from "store/mms/menus/location/types";
import { LocationMenuLink } from "components/mms/Menus/LocationMenuLink";
import { locationMenuPageFetch } from "store/mms/menus/location/actions";
import { ApplicationScheduledEventAdd } from "components/mms/MenuApplications/ApplicationScheduledEventAdd";
import { LocationMenuSync } from "components/mms/Locations/LocationMenuSync";

interface LocationContextProps {
    locationId: string;
    menuId: string;
    menuUrl: string | undefined;
    allMenusLinked: boolean;
    noMasterMenus: boolean;
    onChange(action: onChangeActions): void;
}

export const LocationContext = React.createContext<LocationContextProps>({
    locationId: "",
    menuId: "",
    menuUrl: undefined,
    allMenusLinked: true,
    noMasterMenus: true,
    onChange: () => {
        /* */
    },
});

interface LocationProviderProps {
    children: ReactNode;
}

export function LocationProvider({ children }: LocationProviderProps): JSX.Element {
    const location = useLocation();
    const dispatch = useDispatch();

    const { locationId, menuId } = useParams() as { locationId: string; menuId: string };

    // Locations
    const locations = useSelector((state: RootState) => state.locations.byId);
    const locationsFetching = useSelector((state: RootState) => state.locations.fetching);
    const accountLocation = locations[locationId];

    // Master Menus
    const menusFetching = useSelector((state: RootState) => state.mms.menus.location.menus.fetching);
    const masterMenusById = useSelector((state: RootState) => state.mms.menus.master.menus.byId);
    const menuIdsByLocation =
        useSelector((state: RootState) => state.mms.menus.location.menus.byLocationId)[locationId] || [];

    const isLoading = accountLocation === undefined || locationsFetching;

    const accountUrl = `/1.1/accounts/${getSession()?.accountId}`;
    const locationUrl = `${accountUrl}${location.pathname
        .replace("menus", "mms/menus")
        .replace(/(\/locations\/[^\/]*).*/, "$1")}`;
    const menuUrl = menuId ? `${locationUrl}/mms/menus/${menuId}` : undefined;

    const [state, setState] = useState<{
        tab: number;
        locationId: string;
        menuId: string;
        menuUrl: string | undefined;
    }>({
        tab: location.pathname.includes("periods") ? 1 : 0,
        locationId: locationId,
        menuId: menuId,
        menuUrl: menuUrl,
    });

    useEffect(() => {
        setState((prev) => ({
            ...prev,
            locationId,
            menuId,
            menuUrl,
        }));
    }, [locationId, menuId, locationUrl, menuUrl]);

    const handleMenuLink = (): void => {
        dispatch(locationMenuPageFetch(`${accountLocation._links.self.href}mms/menus`));
    };
    const handleMenuSync = (): void => {
        dispatch(jobCreate({ type: JobType.Link, location: locationId, menu: state.menuId }, `${accountUrl}/mms/jobs`));
    };
    const [editDialog, setEditDialog] = useState<JSX.Element | undefined>(undefined);
    const handleOnCloseDialog = () => setEditDialog(undefined);

    const [logView, setLogView] = useState<{ menu?: LocationMenu; application?: LocationMenuApplication } | undefined>(
        undefined,
    );
    const [scheduledEventsView, setScheduledEventsView] = useState<
        { menu?: LocationMenu; application?: LocationMenuApplication } | undefined
    >(undefined);
    const [scheduledEventAdd, setScheduledEventAdd] = useState<{ application?: LocationMenuApplication } | undefined>(
        undefined,
    );
    const [applicationMenuSync, setApplicationMenuSync] = useState<boolean | undefined>();

    const [periodEdit, setPeriodEdit] = useState<LocationPeriod | undefined>(undefined);
    const [itemEdit, setItemEdit] = useState<LocationItem | undefined>(undefined);
    const [taxRateEdit, setTaxRateEdit] = useState<{ taxRate: TaxRate | NewTaxRate } | undefined>(undefined);

    const handleOnChange = (action: onChangeActions) => {
        switch (action.type) {
            case LocationEvent.MenuLink:
                return setEditDialog(
                    <LocationMenuLink
                        open={true}
                        onClose={handleOnCloseDialog}
                        onConfirm={handleMenuLink}
                        location={accountLocation}
                        menusById={masterMenusById}
                        linkedMenuIds={menuIdsByLocation}
                    />,
                );
            case LocationEvent.MenuSync:
                return handleMenuSync();
            case LocationEvent.PeriodEdit:
                return setPeriodEdit(action.period);
            case LocationEvent.PeriodReset:
                return setEditDialog(
                    <LocationMenuPeriodReset onClose={handleOnCloseDialog} open={true} period={action.period} />,
                );
            case LocationEvent.ItemEdit:
                return setItemEdit(action.item);
            case LocationEvent.ItemVisibility:
                return setEditDialog(
                    <LocationItemVisibility onClose={handleOnCloseDialog} open={true} item={action.item} />,
                );
            case LocationEvent.TaxRateAdd:
                return setTaxRateEdit({ taxRate: getNewTaxRate() });
            case LocationEvent.TaxRateEdit:
                return setTaxRateEdit({ taxRate: action.taxRate });
            case LocationEvent.TaxRateVisibility:
                return setEditDialog(
                    <LocationTaxRateVisibility onClose={handleOnCloseDialog} open={true} taxRate={action.taxRate} />,
                );
            case LocationEvent.TaxRateDelete:
                return setEditDialog(
                    <LocationTaxRateDelete onClose={handleOnCloseDialog} open={true} taxRate={action.taxRate} />,
                );
            case LocationEvent.ApplicationLogView:
                return setLogView(action.logViewInfo);
            case LocationEvent.ApplicationScheduledEventsView:
                return setScheduledEventsView(action.scheduledEventsViewInfo);
            case LocationEvent.ApplicationScheduledEventAdd:
                return setScheduledEventAdd(action.scheduledEventAddInfo);
            case LocationEvent.ApplicationMenuSync:
                return setApplicationMenuSync(true);
        }
    };

    return (
        <LocationContext.Provider
            value={{
                ...state,
                locationId,
                menuId,
                menuUrl,
                allMenusLinked: menusFetching || Object.values(masterMenusById).length - menuIdsByLocation.length === 0,
                noMasterMenus: Object.keys(masterMenusById).length === 0,
                onChange: handleOnChange,
            }}
        >
            {isLoading ? <Skeleton /> : children}
            <OmniDrawer title="Update Menu Item" open={itemEdit !== undefined} close={() => setItemEdit(undefined)}>
                {itemEdit !== undefined && (
                    <EditPOSConfig
                        posConfig={itemEdit.pos_config}
                        strategy={itemEdit.strategy}
                        traversal={buildTraversal(itemEdit)}
                        locationUrl={locationUrl}
                        onSave={(pos_config: POSConfig, strategy: ItemStrategy, callback) => {
                            if (itemEdit !== undefined) {
                                dispatch(
                                    locationItemSave(
                                        {
                                            ...itemEdit,
                                            linked: true,
                                            pos_config,
                                            strategy,
                                        },
                                        itemEdit._links.self.href,
                                        callback,
                                    ),
                                );
                            }
                        }}
                        onClose={() => setItemEdit(undefined)}
                        disableLocationSearch
                    />
                )}
            </OmniDrawer>

            <EditMenuPeriod
                period={periodEdit}
                open={periodEdit !== undefined}
                onClose={() => setPeriodEdit(undefined)}
                onSave={(periodToSave: LocationPeriod, blocks, callback) =>
                    dispatch(locationPeriodBlocksSave(periodToSave, blocks, periodToSave._links.blocks.href, callback))
                }
            />

            {editDialog}

            <LocationTaxRateEdit
                taxRate={taxRateEdit?.taxRate}
                onClose={() => setTaxRateEdit(undefined)}
                open={Boolean(taxRateEdit)}
            />
            <ApplicationAuditLog
                open={logView !== undefined}
                menu={logView?.menu}
                application={logView?.application}
                onClose={() => setLogView(undefined)}
            />
            <ApplicationScheduledEvents
                open={scheduledEventsView !== undefined}
                menu={scheduledEventsView?.menu}
                application={scheduledEventsView?.application}
                onClose={() => setScheduledEventsView(undefined)}
            />
            <ApplicationScheduledEventAdd
                open={scheduledEventAdd !== undefined}
                application={scheduledEventAdd?.application}
                onClose={() => setScheduledEventAdd(undefined)}
            />
            <LocationMenuSync
                open={applicationMenuSync !== undefined}
                location={accountLocation}
                onClose={() => setApplicationMenuSync(undefined)}
            />
        </LocationContext.Provider>
    );
}

export enum LocationEvent {
    //TODO: MenuEnable,
    MenuLink,
    MenuSync,

    PeriodEdit,
    PeriodReset,

    ItemVisibility,
    ItemEdit,

    TaxRateAdd,
    TaxRateEdit,
    TaxRateVisibility,
    TaxRateDelete,

    ApplicationLogView,
    ApplicationScheduledEventsView,
    ApplicationScheduledEventAdd,

    ApplicationMenuSync,
}

interface menuLink {
    type: LocationEvent.MenuLink;
}
interface menuSync {
    type: LocationEvent.MenuSync;
}
interface periodEdit {
    type: LocationEvent.PeriodEdit;
    period: LocationPeriod;
}

interface periodReset {
    type: LocationEvent.PeriodReset;
    period: LocationPeriod;
}

interface itemVisibility {
    type: LocationEvent.ItemVisibility;
    item: LocationItem;
}

interface itemEdit {
    type: LocationEvent.ItemEdit;
    item: LocationItem;
}

interface taxRateAdd {
    type: LocationEvent.TaxRateAdd;
}

interface taxRateEdit {
    type: LocationEvent.TaxRateEdit;
    taxRate: TaxRate;
}

interface taxRateVisibility {
    type: LocationEvent.TaxRateVisibility;
    taxRate: TaxRate;
}

interface taxRateDelete {
    type: LocationEvent.TaxRateDelete;
    taxRate: TaxRate;
}

interface applicationLogView {
    type: LocationEvent.ApplicationLogView;
    logViewInfo: {
        menu: LocationMenu;
        application: LocationMenuApplication;
    };
}

interface applicationScheduledEventsView {
    type: LocationEvent.ApplicationScheduledEventsView;
    scheduledEventsViewInfo: {
        menu: LocationMenu;
        application: LocationMenuApplication;
    };
}

interface applicationScheduledEventAdd {
    type: LocationEvent.ApplicationScheduledEventAdd;
    scheduledEventAddInfo: {
        application: LocationMenuApplication;
    };
}

interface applicationMenuSync {
    type: LocationEvent.ApplicationMenuSync;
}

type onChangeActions =
    | menuLink
    | menuSync
    | periodEdit
    | periodReset
    | itemVisibility
    | itemEdit
    | taxRateAdd
    | taxRateEdit
    | taxRateVisibility
    | taxRateDelete
    | applicationLogView
    | applicationScheduledEventsView
    | applicationScheduledEventAdd
    | applicationMenuSync;
