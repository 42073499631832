import {
    LinkTime,
    LinkTimesActions,
    LINK_TIMES_PAGE_FETCH,
    LINK_TIMES_PAGE_FETCH_SUCCESS,
    LINK_TIMES_SAVE,
    LINK_TIMES_SAVE_SUCCESS,
    NewLinkTime,
} from "./types";

export function linkTimesPageFetch(url?: string): LinkTimesActions {
    return {
        url,
        type: LINK_TIMES_PAGE_FETCH,
    };
}

export function linkTimesPageFetchSuccess(linkTimes: LinkTime[]): LinkTimesActions {
    return {
        linkTimes,
        type: LINK_TIMES_PAGE_FETCH_SUCCESS,
    };
}

export function linkTimesSave(
    linkTimes: (LinkTime | NewLinkTime)[],
    callback?: (error?: Error) => void,
): LinkTimesActions {
    return {
        type: LINK_TIMES_SAVE,
        linkTimes,
        callback,
    };
}

export function linkTimesSaveSuccess(linkTimes: LinkTime[]): LinkTimesActions {
    return {
        type: LINK_TIMES_SAVE_SUCCESS,
        linkTimes,
    };
}
