import { call, ForkEffect, put, takeLatest } from "redux-saga/effects";
import { core } from "services/api";
import { enqueueSnackbar } from "store/notifications/actions";
import {
    ModifierGroup,
    ModifierGroupModifier,
    ModifierGroupResp,
    ModifierGroupReq,
    MODIFIER_GROUP_MODIFERS_SAVE,
    MODIFIER_GROUP_SAVE,
    MODIFIER_GROUP_MODIFERS_ADD_NEW,
    MODIFIER_GROUP_MODIFERS_ADD_EXISTING,
    ModifierGroupModifierResp,
    ModifierGroupPageResp,
} from "./types";
import { mapModifierRespToDomain } from "../../modifiers/effects";
import { modifierGroupModifiersSaveError, modifierGroupSaveError, modifierGroupSaveSuccess } from "./actions";
import { MasterModifier, MasterModifierResp, NewMasterModifier } from "../../modifiers/types";
import { masterModifierSaveSuccess } from "../../modifiers/actions";

const mapDomainToReq = (group: ModifierGroup): ModifierGroupReq => ({
    display_name: group.display_name,
    reference_name: group.reference_name,
    id: group.id,
});
const mapNewModifierReq = (m: NewMasterModifier): Omit<NewMasterModifier, "pos_config"> & { pos_config: string } => ({
    ...m,
    pos_config: JSON.stringify(m.pos_config),
});
const mapModifierGroupModifierRespToDomain = (modifiers: ModifierGroupModifierResp[]): ModifierGroupModifier[] =>
    modifiers.map((mgm) => ({
        ...mgm,
        _embedded: { modifier: mapModifierRespToDomain(mgm._embedded.modifier) },
    }));
export const mapModifierGroupRespToDomain = (g: ModifierGroupResp): ModifierGroup => ({
    display_name: g.display_name,
    reference_name: g.reference_name,
    id: g.id,

    _links: g._links,
    _embedded: {
        modifiers: mapModifierGroupModifierRespToDomain(g._embedded.modifiers),
    },
});
export const mapModifierGroupPageRespToDomain = (r: ModifierGroupPageResp): ModifierGroup[] =>
    r._embedded.modifier_groups.map(mapModifierGroupRespToDomain);

function* modifierGroupSave({
    url,
    group,
    callback,
}: {
    url: string;
    type: typeof MODIFIER_GROUP_SAVE;
    group: ModifierGroup;
    callback?: (error?: Error) => void;
}) {
    try {
        const resp: { data: ModifierGroupResp } = yield call(core.put, url, mapDomainToReq(group));

        yield put(modifierGroupSaveSuccess(mapModifierGroupRespToDomain(resp.data)));
        if (callback !== undefined) {
            yield call(callback);
        }
        yield put(
            enqueueSnackbar({
                message: `Successfully saved Modifier Group, ${group.display_name}.`,
                options: {
                    variant: "success",
                },
            }),
        );
    } catch (e) {
        console.error("Failed to save Menu Modifier Group", e);
        if (e instanceof Error) {
            yield put(modifierGroupSaveError(e));
            if (callback !== undefined) {
                yield call(callback, e);
            }
        }

        yield put(
            enqueueSnackbar({
                message: `Failed to save Modifier Group${group.display_name && ", " + group.display_name}.`,
                options: {
                    variant: "error",
                },
            }),
        );
    }
}

function* modifierGroupModifersAddNew({
    group,
    modifier,
    url,
    callback,
}: {
    type: typeof MODIFIER_GROUP_MODIFERS_ADD_NEW;
    group: ModifierGroup;
    modifier: NewMasterModifier;
    url: string;
    callback?: (error?: Error) => void;
}) {
    try {
        // create the modifier
        const resp: { data: MasterModifierResp } = yield call(core.post, url, mapNewModifierReq(modifier));
        const createdModifier = mapModifierRespToDomain(resp.data);

        // add it to the mod group
        yield call(core.post, group._links.modifiers.href, [
            ...group._embedded.modifiers.map((m) => ({ id: m.id, enabled: m.enabled })),
            { id: createdModifier.id, enabled: false },
        ]);

        // get the group from the server with the latest stuff
        const groupResp: { data: ModifierGroupResp } = yield call(core.get, group._links.self.href);

        // add modifier and group to the store
        yield put(masterModifierSaveSuccess(createdModifier));
        yield put(modifierGroupSaveSuccess(mapModifierGroupRespToDomain(groupResp.data)));

        // feedback
        if (callback !== undefined) {
            yield call(callback);
        }
        yield put(
            enqueueSnackbar({
                message: `Successfully created and added Modifier, ${createdModifier.display_name}.`,
                options: {
                    variant: "success",
                },
            }),
        );
    } catch (e) {
        console.error("Failed to create new Modifier for Modifier Group", e);
        if (e instanceof Error && callback !== undefined) {
            yield call(callback, e);
        }
        yield put(
            enqueueSnackbar({
                message: `Failed to create Modifier.`,
                options: {
                    variant: "error",
                },
            }),
        );
    }
}

function* modifierGroupModifersAddExisting({
    group,
    modifier,
    callback,
}: {
    type: typeof MODIFIER_GROUP_MODIFERS_ADD_EXISTING;
    group: ModifierGroup;
    modifier: MasterModifier;
    callback?: (error?: Error) => void;
}) {
    try {
        // add the mod to the mod group
        yield call(core.post, group._links.modifiers.href, [
            ...group._embedded.modifiers.map((m) => ({ id: m.id, enabled: m.enabled })),
            { id: modifier.id, enabled: false },
        ]);

        // get the group from the server with the latest stuff
        const groupResp: { data: ModifierGroupResp } = yield call(core.get, group._links.self.href);

        // add modifier and group to the store
        yield put(masterModifierSaveSuccess(modifier));
        yield put(modifierGroupSaveSuccess(mapModifierGroupRespToDomain(groupResp.data)));

        // feedback
        if (callback !== undefined) {
            yield call(callback);
        }
        yield put(
            enqueueSnackbar({
                message: `Successfully added Modifier, ${modifier.display_name}.`,
                options: {
                    variant: "success",
                },
            }),
        );
    } catch (e) {
        console.error("Failed to add existing Modifier to Modifier Group", e);
        if (e instanceof Error && callback !== undefined) {
            yield call(callback, e);
        }
        yield put(
            enqueueSnackbar({
                message: `Failed to add Modifier to Modifier Group.`,
                options: {
                    variant: "error",
                },
            }),
        );
    }
}

function* modifierGroupModifiersSave({
    group,
    modifiers,
    callback,
}: {
    group: ModifierGroup;
    type: typeof MODIFIER_GROUP_MODIFERS_SAVE;
    modifiers: Omit<ModifierGroupModifier, "_embedded">[];
    callback?: (error?: Error) => void;
}) {
    try {
        // Optimistic save
        yield call(core.post, group._links.modifiers.href, modifiers);
        if (callback !== undefined) {
            yield call(callback);
        }
    } catch (e) {
        console.error("Failed to save Modifier Group Modifiers", e);
        // Revert on error
        const groupResp: { data: ModifierGroupResp } = yield call(core.get, group._links.self.href);
        yield put(modifierGroupModifiersSaveError(mapModifierGroupRespToDomain(groupResp.data)));

        yield put(
            enqueueSnackbar({
                message: `Failed to save Modifier Group.`,
                options: {
                    variant: "error",
                },
            }),
        );
        if (e instanceof Error && callback !== undefined) {
            yield call(callback, e);
        }
    }
}

export default function* modifierGroupSaga(): Generator<ForkEffect<never>, void, unknown> {
    yield takeLatest(MODIFIER_GROUP_MODIFERS_ADD_NEW, modifierGroupModifersAddNew);
    yield takeLatest(MODIFIER_GROUP_MODIFERS_ADD_EXISTING, modifierGroupModifersAddExisting);
    yield takeLatest(MODIFIER_GROUP_MODIFERS_SAVE, modifierGroupModifiersSave);
    yield takeLatest(MODIFIER_GROUP_SAVE, modifierGroupSave);
}
