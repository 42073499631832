import {
    LocationMenuApplicationActions,
    LocationMenuApplicationChange,
    LOCATION_MENU_APPLICATION_SAVE,
    LOCATION_MENU_APPLICATION_SAVE_ERROR,
    LOCATION_MENU_APPLICATION_SAVE_SUCCESS,
    LOCATION_MENU_APPLICATION_CHANGE_FETCH,
    LOCATION_MENU_APPLICATION_CHANGE_FETCH_SUCCESS,
    LocationMenuApplicationScheduledEvent,
    LOCATION_MENU_APPLICATION_SCHEDULED_EVENT_SAVE,
    LOCATION_MENU_APPLICATION_SCHEDULED_EVENT_SAVE_ERROR,
    LOCATION_MENU_APPLICATION_SCHEDULED_EVENT_SAVE_SUCCESS,
    LOCATION_MENU_APPLICATION_SCHEDULED_EVENT_FETCH,
    LOCATION_MENU_APPLICATION_SCHEDULED_EVENT_FETCH_SUCCESS,
    LOCATION_MENU_APPLICATION_SCHEDULED_EVENT_DELETE_SUCCESS,
} from "./types";

interface LocationMenuApplicationsState {
    readonly changeEventsById: {
        [key: string]: LocationMenuApplicationChange;
    };
    readonly changeIdsByHref: {
        [href: string]: string[];
    };
    readonly scheduledEventsById: {
        [key: string]: LocationMenuApplicationScheduledEvent;
    };
    readonly scheduledEventIdsByApplicationHref: {
        [href: string]: string[];
    };
    readonly fetching: boolean;
    readonly saving: boolean;
    readonly savingError?: Error;
    readonly fetchingError?: Error;
}

const initialState: LocationMenuApplicationsState = {
    changeEventsById: {},
    changeIdsByHref: {},
    scheduledEventsById: {},
    scheduledEventIdsByApplicationHref: {},
    fetching: false,
    saving: false,
};

export default function locationMenuApplications(
    state = initialState,
    action: LocationMenuApplicationActions,
): LocationMenuApplicationsState {
    switch (action.type) {
        case LOCATION_MENU_APPLICATION_SAVE:
            return {
                ...state,
                saving: true,
            };

        case LOCATION_MENU_APPLICATION_SAVE_SUCCESS:
            return {
                ...state,
                saving: false,
                changeEventsById: {
                    ...state.changeEventsById,
                    [action.changeEvent.id]: action.changeEvent,
                },
            };

        case LOCATION_MENU_APPLICATION_SAVE_ERROR:
            return {
                ...state,
                saving: false,
                savingError: action.error,
            };
        case LOCATION_MENU_APPLICATION_CHANGE_FETCH:
            return state;

        case LOCATION_MENU_APPLICATION_CHANGE_FETCH_SUCCESS:
            return {
                ...state,
                changeEventsById: action.changeEvents.reduce(
                    (allChanges: { [changeId: string]: LocationMenuApplicationChange }, change) => {
                        allChanges[change.id] = change;
                        return allChanges;
                    },
                    {},
                ),
                changeIdsByHref: {
                    ...state.changeIdsByHref,
                    [action.href]: action.changeEvents.map((event) => event.id),
                },
            };
        case LOCATION_MENU_APPLICATION_SCHEDULED_EVENT_SAVE:
            return {
                ...state,
                saving: true,
            };

        case LOCATION_MENU_APPLICATION_SCHEDULED_EVENT_SAVE_SUCCESS: {
            const href = action.application._links.self.href;
            return {
                ...state,
                saving: false,
                scheduledEventsById: {
                    ...state.scheduledEventsById,
                    [action.scheduledEvent.id]: action.scheduledEvent,
                },
                scheduledEventIdsByApplicationHref: {
                    ...state.scheduledEventIdsByApplicationHref,
                    [href]: [...(state.scheduledEventIdsByApplicationHref[href] || []), action.scheduledEvent.id],
                },
            };
        }

        case LOCATION_MENU_APPLICATION_SCHEDULED_EVENT_SAVE_ERROR:
            return {
                ...state,
                saving: false,
                savingError: action.error,
            };

        case LOCATION_MENU_APPLICATION_SCHEDULED_EVENT_DELETE_SUCCESS:
            const href = action.scheduledEvent._links.self.href.split("/scheduled_events")[0];
            return {
                ...state,
                scheduledEventsById: Object.values(state.scheduledEventsById).reduce(
                    (
                        scheduledEventsById: { [scheduledEventId: string]: LocationMenuApplicationScheduledEvent },
                        s: LocationMenuApplicationScheduledEvent,
                    ) => {
                        if (s.id !== action.scheduledEvent.id) {
                            scheduledEventsById[s.id] = s;
                        }
                        return scheduledEventsById;
                    },
                    {},
                ),
                scheduledEventIdsByApplicationHref: {
                    ...state.scheduledEventIdsByApplicationHref,
                    [href]: state.scheduledEventIdsByApplicationHref[href].filter(
                        (se) => se !== action.scheduledEvent.id,
                    ),
                },
            };

        case LOCATION_MENU_APPLICATION_SCHEDULED_EVENT_FETCH:
            return state;

        case LOCATION_MENU_APPLICATION_SCHEDULED_EVENT_FETCH_SUCCESS: {
            const href = action.href.split("/scheduled_events")[0];
            return {
                ...state,
                scheduledEventsById: action.scheduledEvents.reduce(
                    (
                        allScheduledEvents: { [scheduledEventId: string]: LocationMenuApplicationScheduledEvent },
                        scheduledEvent,
                    ) => {
                        allScheduledEvents[scheduledEvent.id] = scheduledEvent;
                        return allScheduledEvents;
                    },
                    {},
                ),
                scheduledEventIdsByApplicationHref: {
                    ...state.scheduledEventIdsByApplicationHref,
                    [href]: action.scheduledEvents.map((scheduledEvent) => scheduledEvent.id),
                },
            };
        }
        default:
            return state;
    }
}
