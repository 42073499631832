import { useState } from "react";

export default function useToggle(initialState = false): [boolean, () => void] {
    const [val, set] = useState(initialState);

    return [
        val,
        (): void => {
            set(!val);
        },
    ];
}
