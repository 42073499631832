import React, { useEffect, useMemo, useState } from "react";
import { Grid, LinearProgress, Typography, Alert, Skeleton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { orderProfileDelete, orderProfilesFetch } from "store/mms/order-profiles/actions";
import { RootState } from "store/rootReducer";
import EditOrderProfile from "components/mms/OrderProfiles/EditOrderProfile";
import { OrderProfile, OrderProfileTypes } from "store/mms/order-profiles/types";
import { ListPerPageOptions } from "components/props";
import { Link, useLocation } from "react-router-dom";
import { OmniDrawer } from "components/common/OmniDrawer";
import ConfirmDialog from "components/common/ConfirmDialog";
import { axiosErrorMessage } from "store/types";
import { DataGrid, GridCellParams, GridOverlay, GridSortDirection } from "@mui/x-data-grid";
import { QuickSearchToolbar } from "components/common/forms/QuickSearchToolbar";
import { search } from "components/common/forms/helpers";
import { searchDebounce } from "services/env";
import { debounce } from "lodash";
import DefaultLayout from "components/common/layouts/DefaultLayout";
import { EntityEdit } from "components/mms/IconButtons/EntityEdit";
import { EntityRemove } from "components/mms/IconButtons/EntityRemove";
import { ActionButton } from "components/common/buttons/ActionButton";

const sortOrder = [
    {
        field: "name",
        sort: "asc" as GridSortDirection,
    },
];

export default function OrderProfileList(): JSX.Element {
    const dispatch = useDispatch();
    const { fetching, profiles } = useSelector((state: RootState) => state.mms.orderProfiles);
    const [editProfile, setEditProfile] = useState<{ profile: OrderProfile | undefined }>({
        profile: undefined,
    });
    const [deleteProfile, setDeleteProfile] = useState<{ profile: OrderProfile } | undefined>(undefined);
    const location = useLocation();

    const [searchText, setSearchText] = useState("");
    const [rows, setRows] = useState(profiles);
    useEffect(() => setRows(profiles), [profiles]);
    const debouncedSearch = useMemo(
        () =>
            debounce((query: string) => {
                search(query, profiles, setRows);
            }, searchDebounce),
        [profiles],
    );

    useEffect(() => {
        dispatch(orderProfilesFetch());
    }, [dispatch]);

    const createProfile = () => {
        setEditProfile({
            profile: {
                name: "",
                type: OrderProfileTypes.Delivery,
                locations: 0,
                adjustment: {
                    enabled: false,
                },
                locationOrderProfiles: [],
                _links: { self: { href: "", type: "application/hal+json; name=mms_master_order_profile" } },
            },
        });
    };

    return (
        <DefaultLayout>
            <DataGrid
                autoHeight
                disableSelectionOnClick
                loading={fetching}
                rows={rows.map((p) => ({
                    id: p.id || "N/A",
                    name: p.name,
                    type: p.type,
                    locationCount: p.locations,
                }))}
                sortModel={sortOrder}
                components={{
                    Toolbar: QuickSearchToolbar,
                    LoadingOverlay: function CustomLoadingOverlay() {
                        return (
                            <GridOverlay>
                                <div style={{ position: "absolute", top: 0, width: "100%" }}>
                                    <LinearProgress />
                                </div>
                            </GridOverlay>
                        );
                    },
                }}
                componentsProps={{
                    toolbar: {
                        value: searchText,
                        onChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                            const query = e.target.value;
                            setSearchText(query);
                            debouncedSearch(query);
                        },
                        clearSearch: () => {
                            setSearchText("");
                            search("", profiles, setRows);
                        },
                        title: (
                            <Grid container justifyContent="space-between">
                                <Grid item>
                                    <Typography variant="h4" sx={{ margin: (theme) => theme.spacing(0, 0, 2, 0) }}>
                                        Order Profiles
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="subtitle1">
                                        {fetching ? (
                                            <Skeleton width={125} />
                                        ) : (
                                            <ActionButton
                                                label="Create Profile"
                                                onClick={createProfile}
                                                sx={{ marginRight: (theme) => theme.spacing(2) }}
                                            />
                                        )}
                                    </Typography>
                                </Grid>
                            </Grid>
                        ),
                    },
                }}
                columns={[
                    {
                        field: "name",
                        headerName: "Name",
                        width: 560,
                        flex: 4,
                        resizable: false,
                        disableColumnMenu: true,
                        renderCell: function renderName(params: GridCellParams) {
                            return <Link to={`${location.pathname}/${params.row.id}`}>{params.row.name}</Link>;
                        },
                    },
                    {
                        field: "id",
                        headerName: "ID",
                        width: 190,
                        flex: 1,
                        resizable: false,
                        disableColumnMenu: true,
                    },
                    {
                        field: "type",
                        headerName: "Type",
                        width: 190,
                        flex: 1,
                        sortable: false,
                        resizable: false,
                        disableColumnMenu: true,
                    },
                    {
                        field: "locationCount",
                        headerName: "Locations",
                        width: 60,
                        flex: 1,
                        sortable: false,
                        resizable: false,
                        disableColumnMenu: true,
                        align: "center",
                        headerAlign: "center",
                    },
                    {
                        field: "actions",
                        headerClassName: "hidden",
                        resizable: false,
                        disableColumnMenu: true,
                        width: 120,
                        flex: 1,
                        renderCell: function renderActions(params: GridCellParams) {
                            const profileId = params.row.id;

                            return (
                                <>
                                    <EntityEdit
                                        onClick={() =>
                                            setEditProfile({
                                                profile: profiles.find((p) => p.id === profileId),
                                            })
                                        }
                                        title="Update Profile"
                                    />

                                    <EntityRemove
                                        onClick={() => {
                                            const profile = profiles.find((p) => p.id === profileId);

                                            if (profile) {
                                                setDeleteProfile({
                                                    profile,
                                                });
                                            }
                                        }}
                                        title="Remove Order Profile"
                                    />
                                </>
                            );
                        },
                        align: "right",
                        cellClassName: "actions-cell",
                        filterable: false,
                    },
                ]}
                rowsPerPageOptions={ListPerPageOptions}
                sx={{
                    minHeight: "inherit",
                    borderLeft: "unset",
                    borderRight: "unset",
                    borderTop: "unset",
                    "& .actions-cell": {
                        "& > div": {
                            cursor: "pointer",
                        },
                    },
                    "& .hidden": {
                        visibility: "hidden",
                    },
                }}
            />

            <OmniDrawer
                close={() => setEditProfile({ profile: undefined })}
                open={editProfile.profile !== undefined}
                title="Update Order Profile"
            >
                {editProfile.profile && (
                    <EditOrderProfile
                        profile={editProfile.profile}
                        close={() => setEditProfile({ profile: undefined })}
                    />
                )}
            </OmniDrawer>

            {deleteProfile && (
                <DeleteOrderProfile
                    onClose={() => setDeleteProfile(undefined)}
                    open={Boolean(deleteProfile)}
                    {...deleteProfile}
                />
            )}
        </DefaultLayout>
    );
}

interface DeleteOrderProfileProps {
    onClose(): void;
    open: boolean;
    profile: OrderProfile;
}

function DeleteOrderProfile({ onClose, open, profile }: DeleteOrderProfileProps): JSX.Element {
    const dispatch = useDispatch();
    const [state, setState] = useState<{ deleting: boolean; error?: string }>({
        deleting: false,
    });

    return (
        <ConfirmDialog
            title="Remove Order Profile"
            open={open}
            onClose={onClose}
            onConfirm={() => {
                setState((prevState) => ({ ...prevState, error: undefined, deleting: true }));
                dispatch(
                    orderProfileDelete(profile, (error?: Error): void => {
                        if (error) {
                            return setState((prevState) => ({
                                ...prevState,
                                deleting: false,
                                error: axiosErrorMessage(error),
                            }));
                        }
                        setState(() => ({
                            deleting: false,
                            error: undefined,
                        }));
                        onClose();
                    }),
                );
            }}
            confirm="Remove Order Profile"
            confirming={state.deleting}
            maxWidth="xl"
            transitionDuration={0}
        >
            <Typography>
                Are you sure you want to remove the <strong>{profile.name}</strong> Order Profile?
            </Typography>
            <Alert variant="outlined" severity="info">
                This change will affect all linked <strong>Locations</strong> and anywhere the{" "}
                <strong>{profile.name}</strong> Order Profile is used.
            </Alert>
            {state.error && (
                <Alert variant="outlined" severity="error">
                    {state.error}
                </Alert>
            )}
        </ConfirmDialog>
    );
}
