import { Box, BoxProps, Button, CircularProgress } from "@mui/material";
import React from "react";
import { useFormState } from "react-final-form";

interface FormSubmitProps extends BoxProps {
    className?: string;
    onClose(): void;
    label: string;
    disableSubmit?: boolean;
}

export function FormSubmit({
    className,
    onClose,
    label,
    sx = [],
    disableSubmit = false,
}: FormSubmitProps): JSX.Element {
    const { submitting } = useFormState();

    return (
        <Box sx={sx} component="footer" className={className}>
            <Button
                title={label}
                type="submit"
                variant="contained"
                color="primary"
                disabled={submitting || disableSubmit}
                sx={{ visibility: submitting ? "hidden" : "visible" }}
            >
                {submitting && (
                    <Box
                        sx={{
                            position: "absolute",
                            visibility: "visible",
                            display: "flex",
                            left: "50%",
                            transform: "translate(-50%)",
                        }}
                    >
                        <CircularProgress color="secondary" size={16} />
                    </Box>
                )}
                {label}
            </Button>
            <Button
                title="Cancel"
                onClick={onClose}
                sx={(theme) => ({ marginLeft: theme.spacing(2) })}
                disabled={submitting}
            >
                Cancel
            </Button>
        </Box>
    );
}
