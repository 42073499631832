import React from "react";
import { getSession } from "services/session";
import { Employee } from "./reducer";
import OmniComplete from "./OmniComplete";
import { AxiosResponse } from "axios";

interface props {
    employeeId?: string;
    locationId: string;
    setEmployee(e: Employee | null): void;
    error?: boolean;
    errorText?: string;
    disableFetch?: boolean;
}

interface employeeResponse {
    id: string;
    first_name: string;
    middle_name: string | null;
    last_name: string;
    payroll_id: string | null;
    pos_id: string;
}

const mapToDomain = (e: employeeResponse): Employee => ({
    id: e.id,
    firstName: e.first_name,
    middleName: e.middle_name,
    lastName: e.last_name,
    payrollId: e.payroll_id,
    posId: e.pos_id,
    kind: "employee",
});

export default function POSEmployeeSearch(props: props): JSX.Element {
    const s = getSession();
    const { employeeId, disableFetch, locationId, error, errorText = "Required", setEmployee } = props;

    return (
        <OmniComplete
            id="employee-id"
            entity={employeeId ? ({ id: employeeId } as Employee) : null}
            error={error}
            helperText={error ? errorText : "Not Found on POS"}
            setEntity={setEmployee}
            inputLabel="Employee"
            disabled={!locationId}
            searchUrl={`/1.1/accounts/${s?.accountId}/locations/${locationId}/mms/pos/employees/?search=`}
            searchRespMapper={(
                resp: AxiosResponse<{
                    _embedded: {
                        employees: employeeResponse[];
                    };
                }>,
            ) => resp.data._embedded.employees.map(mapToDomain)}
            disableFetch={disableFetch}
            fetchUrl={
                employeeId && `/1.1/accounts/${s?.accountId}/locations/${locationId}/mms/pos/employees/${employeeId}`
            }
            fetchRespMapper={(resp: AxiosResponse<employeeResponse>) => mapToDomain(resp.data)}
        />
    );
}
