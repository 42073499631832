import { call, ForkEffect, put, takeEvery } from "redux-saga/effects";
import { core } from "services/api";
import { getSession } from "services/session";
import { LinkTimesPageFetch, LinkTimesPageResp, LinkTimesSave, LINK_TIMES_PAGE_FETCH, LINK_TIMES_SAVE } from "./types";
import {
    linkTimesPageFetch as linkTimesPageFetchAction,
    linkTimesPageFetchSuccess,
    linkTimesSaveSuccess,
} from "./actions";

const linkTimesUrl = `/1.1/accounts/${getSession()?.accountId}/mms/link_times`;

function* linkTimesPageFetch({ url: pageUrl }: LinkTimesPageFetch) {
    try {
        const url = pageUrl === undefined ? linkTimesUrl : pageUrl;
        const resp: { data: LinkTimesPageResp } = yield call(core.get, url);

        if (resp.data._links.next) {
            yield put(linkTimesPageFetchAction(resp.data._links.next.href));
        }
        yield put(linkTimesPageFetchSuccess(resp.data._embedded.link_times));
    } catch (e) {
        console.error("Failed to fetch Link Times Page", e);
    }
}

function* linkTimesSave({ linkTimes, callback }: LinkTimesSave) {
    try {
        const resp: { data: LinkTimesPageResp } = yield call(core.post, linkTimesUrl, {
            link_times: linkTimes.map((lt) => lt.link_time),
        });

        yield put(linkTimesSaveSuccess(resp.data._embedded.link_times));
        if (callback) {
            yield callback();
        }
    } catch (e) {
        console.error("Failed to save Link Times", e);
        if (e instanceof Error) {
            if (callback) {
                yield callback(e);
            }
        }
    }
}

export default function* linkTimesSaga(): Generator<ForkEffect<void>, void, unknown> {
    yield takeEvery(LINK_TIMES_PAGE_FETCH, linkTimesPageFetch);
    yield takeEvery(LINK_TIMES_SAVE, linkTimesSave);
}
