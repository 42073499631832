export const apiBaseUrl = (
    process.env.REACT_APP_API_BASE_URL ||
    window.OM_API_BASE_URL ||
    `${location.protocol}//${location.hostname.replace("merchant.", "api.")}`
).replace(/\/$/, "");
export const searchDebounce = parseInt(process.env.REACT_APP_DEFAULT_SEARCH_DEBOUNCE || "300", 10);
export const saveDebounce = parseInt(process.env.REACT_APP_DEFAULT_SAVE_DEBOUNCE || "300", 10);
export const cookieCipherKey =
    process.env.REACT_APP_OM_ACCOUNTS_SSO_AUTH_COOKIE_CIPHER_KEY || window.OM_ACCOUNTS_SSO_AUTH_COOKIE_CIPHER_KEY || "";
export const loginUrl = (
    process.env.REACT_APP_ACCOUNTS_URL ||
    window.OM_ACCOUNTS_URL ||
    location.hostname.replace("merchant.", "accounts.")
).replace(/\/$/, "/");
