import "date-fns";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import React from "react";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider, DatePicker } from "@mui/lab";
import { reportsFiltersSetStartDatetime, reportsFiltersSetEndDatetime } from "store/reports/actions";
import { RootState } from "store/rootReducer";
import { sub, isValid } from "date-fns";
import { Grid, TextField } from "@mui/material";

export default function DateRangePicker(): JSX.Element {
    const dispatch = useDispatch();
    const filters = useSelector((state: RootState) => state.reportFilters, shallowEqual);

    const handleStartChange = (d: Date | null) => {
        if (d && isValid(d)) {
            dispatch(reportsFiltersSetStartDatetime(d));
        }
    };
    const handleEndChange = (d: Date | null) => {
        if (d && isValid(d)) {
            dispatch(reportsFiltersSetEndDatetime(d));
        }
    };
    const [startDate, startTime] = filters.start.split(" ");
    const [endDate, endTime] = filters.end.split(" ");

    return (
        <>
            <Grid container spacing={2} justifyContent="space-between">
                <Grid item>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label="Start date"
                            value={new Date(`${startDate}T${startTime}Z`)}
                            onChange={(date: unknown) => {
                                handleStartChange(date as Date);
                            }}
                            onError={console.log}
                            inputFormat="yyyy/MM/dd hh:mm a"
                            minDate={sub(new Date(new Date().toUTCString().substring(0, 26)), { months: 1 })}
                            renderInput={(props) => <TextField {...props} />}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label="End date"
                            value={new Date(`${endDate}T${endTime}Z`)}
                            onChange={(date: unknown) => {
                                handleEndChange(date as Date);
                            }}
                            onError={console.log}
                            inputFormat="yyyy/MM/dd hh:mm a"
                            minDate={sub(new Date(new Date().toUTCString().substring(0, 26)), { months: 1 })}
                            renderInput={(props) => <TextField {...props} />}
                        />
                    </LocalizationProvider>
                </Grid>
            </Grid>
        </>
    );
}
