import { call, ForkEffect, put, takeEvery, takeLatest } from "redux-saga/effects";
import { core } from "services/api";
import { enqueueSnackbar } from "store/notifications/actions";
import { HALLink, ListRoute } from "store/types";
import { masterItemFetchSuccess, masterItemSaveSuccess } from "../items/actions";
import { mapItemResp } from "../items/effects";
import { masterModifiersHydrate } from "../items/modifiers/actions";
import { mapModifierRespToDomain } from "../items/modifiers/effects";
import { MasterModifier, MasterModifierResp } from "../items/modifiers/types";
import { optionSetsHydrate } from "../items/option-sets/actions";
import { flattenOptionSets } from "../items/option-sets/effects";
import { modifierGroupsHydrate } from "../items/option-sets/modifier-group/actions";
import { mapModifierGroupRespToDomain } from "../items/option-sets/modifier-group/effects";
import { ModifierGroupModifier, ModifierGroupResp } from "../items/option-sets/modifier-group/types";
import { MasterItem, MasterItemResp, NewMasterItem } from "../items/types";
import { MasterMenu } from "../types";
import {
    sectionDeleteError,
    sectionDeleteSuccess,
    sectionItemExistingSave as sectionItemExistingSaveAction,
    sectionItemExistingSaveSuccess,
    sectionItemDeleteError,
    sectionItemDeleteSuccess,
    sectionItemsSaveSuccess,
    sectionPageFetch as sectionPageFetchAction,
    sectionPageFetchError as sectionPageFetchErrorAction,
    sectionPageFetchSuccess as sectionPageFetchSuccessAction,
    sectionSaveSuccess,
    sectionsSaveSuccess,
} from "./actions";
import {
    SectionResp,
    SectionPageResp,
    SECTION_PAGE_FETCH,
    Section,
    SECTIONS_SAVE,
    SECTION_ITEMS_SAVE,
    SECTION_SAVE,
    SECTION_DELETE,
    SectionReq,
    SECTION_ITEM_DELETE,
    SECTION_ITEM_EXISTING_SAVE,
    SECTION_ITEM_NEW_SAVE,
} from "./types";

const flattenItems = (sections: SectionResp[]): MasterItem[] => {
    const items: MasterItem[] = [];

    sections.forEach((s) => {
        items.push(...s._embedded.items.map((i) => mapItemResp(i)));
    });
    return items;
};
const mapToDomain = (sectionsResp: SectionResp[]): Section[] => {
    return sectionsResp.map((s) => ({
        ...s,
        _embedded: {
            ...s._embedded,
            items: s._embedded.items.map((i) => mapItemResp(i)),
        },
    }));
};
const menuIdMenuResp = (r: SectionPageResp | Section): string => {
    return r._links.self.href.replace(/.+menus\/([^\/]*).*/, "$1");
};

function* sectionPageFetch({ url }: { url: string; type: typeof SECTION_PAGE_FETCH }) {
    try {
        const resp: { data: SectionPageResp } = yield call(core.get, url);
        const sections = resp.data._embedded.sections;
        const items = flattenItems(sections);

        for (const item of items) {
            const flattenedOSes = flattenOptionSets(item._embedded.option_sets);
            const flattenedMods = flattenedOSes.reduce((list: MasterModifier[], os) => {
                const osMods = os._embedded.modifier_group._embedded.modifiers.map((m: ModifierGroupModifier) =>
                    mapModifierRespToDomain(m._embedded.modifier as MasterModifierResp),
                );

                return [...list, ...osMods];
            }, []);

            yield put(optionSetsHydrate(flattenedOSes));
            yield put(
                modifierGroupsHydrate(
                    flattenedOSes.map((os) =>
                        mapModifierGroupRespToDomain(os._embedded.modifier_group as ModifierGroupResp),
                    ),
                ),
            );
            yield put(masterModifiersHydrate(flattenedMods));
            yield put(masterItemFetchSuccess(item));
        }
        if (resp.data._links.next !== undefined) {
            yield put(sectionPageFetchAction(resp.data._links.next.href));
        }
        yield put(sectionPageFetchSuccessAction(menuIdMenuResp(resp.data), mapToDomain(sections)));
    } catch (e) {
        console.error("Failed to fetch Menu Sections Page", e);
        if (e instanceof Error) {
            yield put(sectionPageFetchErrorAction(e));
        }
    }
}

function* sectionSave({
    section,
    url,
    callback,
}: {
    type: typeof SECTION_SAVE;
    section: Section | SectionReq;
    url: string;
    callback?: (error?: Error) => void;
}) {
    const isNewSection = section.id === "";
    try {
        const payload = isNewSection
            ? {
                  name: section.name,
                  description: section.description || "",
                  enabled: section.enabled,
                  sequence: section.sequence,
              }
            : {
                  id: section.id,
                  name: section.name,
                  description: section.description || "",
                  enabled: section.enabled,
                  sequence: section.sequence,
              };
        const resp: { data: SectionResp } = yield call(isNewSection ? core.post : core.put, url, payload);
        const savedSection = mapToDomain([resp.data])[0];

        if (callback !== undefined) {
            yield call(callback);
        }

        yield put(sectionSaveSuccess(url.replace(/.+menus\/([^\/]*).*/, "$1"), savedSection));
        yield put(
            enqueueSnackbar({
                message: `Successfully ${isNewSection ? "created" : "saved"} Category, ${savedSection.name}.`,
                options: {
                    variant: "success",
                },
            }),
        );
    } catch (e) {
        const saveType = isNewSection ? "create" : "save";
        console.error(`Failed to ${saveType} Master Menu Section`, e);

        if (e instanceof Error && callback !== undefined) {
            yield call(callback, e);
        }

        yield put(
            enqueueSnackbar({
                message: `Failed to ${saveType} Master Menu Category.`,
                options: {
                    variant: "error",
                },
            }),
        );
    }
}

function* sectionDelete({
    section,
    callback,
}: {
    type: typeof SECTION_DELETE;
    section: Section;
    callback?: (error?: Error) => void;
}) {
    try {
        yield call(core.delete, section._links.self.href);

        if (callback !== undefined) {
            yield call(callback);
        }
        yield put(sectionDeleteSuccess(menuIdMenuResp(section), section));
    } catch (e) {
        console.error("Failed to delete Master Menu Section", e);

        if (e instanceof Error) {
            if (callback !== undefined) {
                yield call(callback, e);
            }
            yield put(sectionDeleteError(e));
        }
        yield put(
            enqueueSnackbar({
                message: `Failed to delete Master Menu Category.`,
                options: {
                    variant: "error",
                },
            }),
        );
    }
}

function* sectionsSave({ sections, url }: { type: typeof SECTIONS_SAVE; sections: Section[]; url: string }) {
    try {
        yield call(
            core.post,
            url,
            sections.map((s) => ({
                id: s.id,
                name: s.name,
                description: s.description,
                enabled: s.enabled,
            })),
        );

        yield put(sectionsSaveSuccess(url.replace(/.+menus\/([^\/]*).*/, "$1"), sections));
    } catch (e) {
        console.error("Failed to save Master Menu Sections", e);

        yield put(
            enqueueSnackbar({
                message: `Failed to save Master Menu Categories.`,
                options: {
                    variant: "error",
                },
            }),
        );
    }
}

function* sectionItemExistingSave({
    section,
    item,
    callback,
}: {
    section: Section;
    item: MasterItem;
    callback?: (error?: Error) => void;
    type: typeof SECTION_ITEM_EXISTING_SAVE;
}) {
    try {
        yield call(core.post, section._links.items.href, { item: item.id });

        // Since we are adding an existing item to the section, we may not have the existing item in the redux store.
        // This ensures it's added to the store.
        yield put(masterItemSaveSuccess({ ...item }));
        yield put(sectionItemExistingSaveSuccess(section, { ...item }));
        if (callback) {
            yield call(callback);
        }
    } catch (e) {
        console.error("Failed to add existing Menu Item to Section", e);
        if (e instanceof Error) {
            if (callback) {
                yield call(callback, e);
            }
        }

        yield put(
            enqueueSnackbar({
                message: `Failed to add Menu Item, ${item.display_name} to Category, ${section.name}.`,
                options: {
                    variant: "error",
                },
            }),
        );
    }
}

function* sectionItemNewSave({
    menu,
    section,
    item,
    callback,
}: {
    menu: MasterMenu;
    section: Section;
    item: NewMasterItem;
    callback?: (error?: Error) => void;
    type: typeof SECTION_ITEM_NEW_SAVE;
}) {
    try {
        // create the new menu item
        const resp: { data: MasterItemResp } = yield call(core.post, menu._links.items.href, {
            ...item,
            pos_config: JSON.stringify(item.pos_config),
        });
        const savedItem = mapItemResp(resp.data);

        // add the item to the section
        yield put(sectionItemExistingSaveAction(section, savedItem, callback));
    } catch (e) {
        console.error("Failed to add new Menu Item to Section", e);
        if (e instanceof Error) {
            if (callback) {
                yield call(callback, e);
            }
        }

        yield put(
            enqueueSnackbar({
                message: `Failed to create Menu Item.`,
                options: {
                    variant: "error",
                },
            }),
        );
    }
}

function* sectionItemDelete({
    section,
    item,
    callback,
}: {
    section: Section;
    item: MasterItem;
    callback?: (error?: Error) => void;
    type: typeof SECTION_ITEM_DELETE;
}) {
    try {
        yield call(core.delete, `${section._links.items.href}/${item.id}`);

        if (callback) {
            yield call(callback);
        }
        yield put(sectionItemDeleteSuccess(section, item));
    } catch (e) {
        console.error(`Failed to delete Section Item`, e);
        if (e instanceof Error) {
            if (callback) {
                yield call(callback, e);
            }
            yield put(sectionItemDeleteError(e));
        }

        yield put(
            enqueueSnackbar({
                message: `Failed to delete Menu Item, ${item.display_name}.`,
                options: {
                    variant: "error",
                },
            }),
        );
    }
}

interface SectionItemsResp extends ListRoute {
    _embedded: {
        items: MasterItemResp[];
    };
    _links: {
        self: HALLink;
    };
}

function* sectionItemsSave({
    section,
    items,
}: {
    type: typeof SECTION_ITEMS_SAVE;
    section: Section;
    items: MasterItem[];
}) {
    try {
        const resp: { data: SectionItemsResp } = yield call(
            core.post,
            section._links.items.href,
            items.map((i) => ({ item: i.id })),
        );

        yield put(sectionItemsSaveSuccess(section, resp.data._embedded.items.map(mapItemResp)));
    } catch (e) {
        console.error("Failed to save Master Menu Section Items", e);

        yield put(
            enqueueSnackbar({
                message: `Failed to save Category changes.`,
                options: {
                    variant: "error",
                },
            }),
        );
    }
}

export default function* sectionsSaga(): Generator<ForkEffect<void>, void, unknown> {
    yield takeLatest(SECTION_PAGE_FETCH, sectionPageFetch);
    yield takeEvery(SECTION_SAVE, sectionSave);
    yield takeEvery(SECTION_DELETE, sectionDelete);
    yield takeEvery(SECTIONS_SAVE, sectionsSave);
    yield takeEvery(SECTION_ITEMS_SAVE, sectionItemsSave);
    yield takeEvery(SECTION_ITEM_EXISTING_SAVE, sectionItemExistingSave);
    yield takeEvery(SECTION_ITEM_NEW_SAVE, sectionItemNewSave);
    yield takeEvery(SECTION_ITEM_DELETE, sectionItemDelete);
}
