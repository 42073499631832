import React, { useMemo, useState } from "react";
import { FormSubmit } from "components/common/FormSubmit";
import { Field, Form } from "react-final-form";
import { makeStyles } from "theme";
import { MenuItem, TextField, Theme, Alert } from "@mui/material";
import { Decorator } from "final-form";
import { useSelector } from "react-redux";
import { default as createSubmitListenerDecorator } from "final-form-submit-listener";
import { MenuType } from "store/mms/menus/master/types";
import LocationSearch from "components/autocomplete/Location";
import { RootState } from "store/rootReducer";
import { mapToFormErrors, handleErrors } from "components/common/forms/helpers";

const useStyles = makeStyles()((theme: Theme) => ({
    description: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    spacer: {
        marginTop: theme.spacing(4),
    },
    subtext: {
        paddingLeft: theme.spacing(2),
        marginTop: theme.spacing(0.5),
    },
    submitErrors: {
        margin: theme.spacing(4, 0),
    },
}));

interface MasterMenuAddProps {
    submitLabel?: string;
    open: boolean;
    onClose(): void;
    onSave(
        menuName: string,
        menuLocation: string,
        menuType: MenuType,
        menuConcept: string,
        callback?: (e?: Error) => void,
    ): void;
}

interface MasterMenuAddFields {
    menuName: string;
    menuLocation: string;
    menuType: MenuType;
    menuConcept: string;
}

export function MasterMenuAdd({ submitLabel, open, onClose, onSave }: MasterMenuAddProps): JSX.Element {
    const { classes } = useStyles();

    const [location, setLocation] = useState<{ name: string; id: string }>({
        name: "",
        id: "",
    });

    const conceptsById = useSelector((state: RootState) => state.restaurantConcepts.byId);

    const submitListener = useMemo(
        () =>
            createSubmitListenerDecorator({
                afterSubmitSucceeded: onClose,
            }),
        [onClose],
    );

    return (
        <>
            {open && (
                <Form
                    initialValues={{
                        menuName: "",
                        menuLocation: "",
                        menuType: MenuType.DineIn,
                        menuConcept: "",
                    }}
                    decorators={[submitListener as Decorator<MasterMenuAddFields, MasterMenuAddFields>]}
                    onSubmit={(values, _, errorsCallback) => {
                        onSave(
                            values.menuName,
                            location.id as string,
                            values.menuType,
                            values.menuConcept,
                            (error?: Error) => {
                                return errorsCallback && errorsCallback(mapToFormErrors(error));
                            },
                        );
                    }}
                    validate={(values) => {
                        const errors: {
                            menuName?: string;
                            menuLocation?: string;
                            menuType?: string;
                            menuConcept?: string;
                        } = {};

                        if (!values.menuName) {
                            errors.menuName = "Required";
                        }
                        if (!location || location.id === "") {
                            errors.menuLocation = "Required";
                        }
                        if (!values.menuType) {
                            errors.menuType = "Required";
                        }
                        if (!values.menuConcept) {
                            errors.menuConcept = "Required";
                        }

                        return errors;
                    }}
                >
                    {({ handleSubmit, submitError }) => (
                        <form onSubmit={handleSubmit}>
                            <Field name="menuName">
                                {({ input, meta }) => (
                                    <TextField
                                        autoFocus
                                        {...input}
                                        {...handleErrors(meta)}
                                        id="menuName"
                                        title="Menu Name"
                                        label="Menu Name"
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        className={classes.description}
                                    />
                                )}
                            </Field>
                            <Field name="menuLocation">
                                {({ input, meta }) => (
                                    <LocationSearch
                                        {...input}
                                        {...handleErrors(meta)}
                                        setLocation={(loc) => {
                                            if (loc && loc.id !== location.id)
                                                setLocation({ name: loc.name, id: loc.id });
                                        }}
                                    />
                                )}
                            </Field>
                            <div className={classes.subtext}>Select the location for the initial menu mapping.</div>
                            <div className={classes.spacer} />
                            <Field name="menuType">
                                {({ input, meta }) => (
                                    <TextField
                                        {...input}
                                        {...handleErrors(meta)}
                                        select
                                        id="menuType"
                                        title="Menu Type"
                                        label="Menu Type"
                                        variant="outlined"
                                        fullWidth
                                    >
                                        {Object.values(MenuType).map((mt, idx) => (
                                            <MenuItem value={mt} key={idx}>
                                                {mt[0].toUpperCase() + mt.substring(1)}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                )}
                            </Field>
                            <div className={classes.spacer} />
                            <Field name="menuConcept">
                                {({ input, meta }) => (
                                    <TextField
                                        {...input}
                                        {...handleErrors(meta)}
                                        select
                                        id="menuConcept"
                                        title="Restaurant Concept"
                                        label="Restaurant Concept"
                                        variant="outlined"
                                        fullWidth
                                    >
                                        {Object.keys(conceptsById).map((conceptId) => (
                                            <MenuItem value={conceptId} key={conceptId}>
                                                {conceptsById[conceptId]}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                )}
                            </Field>
                            {submitError && (
                                <Alert className={classes.submitErrors} severity="error">
                                    {submitError}
                                </Alert>
                            )}
                            <FormSubmit
                                sx={{ width: "fit-content", marginTop: (theme) => theme.spacing(8), textAlign: "end" }}
                                onClose={onClose}
                                label={submitLabel || "Create Menu"}
                            />
                        </form>
                    )}
                </Form>
            )}
        </>
    );
}
