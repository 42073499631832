import { OmniDrawer } from "components/common/OmniDrawer";
import React from "react";
import { useDispatch } from "react-redux";
import { getSession } from "services/session";
import { ModifierStrategy } from "store/mms/menus/location/items/modifiers/types";
import { MasterModifier } from "store/mms/menus/master/items/modifiers/types";
import { masterModifierSave } from "store/mms/menus/master/items/modifiers/actions";
import { buildMasterTraversal, MasterItem } from "store/mms/menus/master/items/types";
import { POSConfig } from "store/mms/types";
import EditPOSConfig from "../EditPOSConfig";

const s = getSession();
const accountUrl = `/1.1/accounts/${s?.accountId}`;

interface ModifierEditPOSConfigProps {
    item: MasterItem;
    modifier?: MasterModifier;
    onClose(): void;
    open: boolean;
}

export function ModifierEditPOSConfig({ item, modifier, onClose, open }: ModifierEditPOSConfigProps): JSX.Element {
    const dispatch = useDispatch();

    return (
        <OmniDrawer close={onClose} open={open} title="Update Modifier Settings">
            {modifier !== undefined && (
                <EditPOSConfig
                    posConfig={modifier.pos_config}
                    strategy={modifier.strategy}
                    isModifier
                    traversal={buildMasterTraversal(item, modifier)}
                    locationUrl={`${accountUrl}/locations/${modifier.source_location}`}
                    onSave={(
                        pos_config: POSConfig,
                        strategy: ModifierStrategy,
                        callback,
                        source_location,
                        force_location_update,
                    ) => {
                        if (modifier !== undefined && source_location !== undefined) {
                            dispatch(
                                masterModifierSave(
                                    {
                                        ...modifier,
                                        linked: true,
                                        pos_config,
                                        strategy,
                                        source_location,
                                    },
                                    modifier._links.self.href,
                                    callback,
                                    force_location_update,
                                ),
                            );
                        }
                    }}
                    onClose={onClose}
                />
            )}
        </OmniDrawer>
    );
}
