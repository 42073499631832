import React from "react";
import { useContext } from "react";
import { LocationContext, LocationEvent } from "../context";
import { Sync as SyncIcon } from "@mui/icons-material";
import { ActionButton } from "components/common/buttons/ActionButton";

export const MenuPOSSync = (): JSX.Element => {
    const { onChange } = useContext(LocationContext);

    return (
        <ActionButton
            label="Re-sync with POS"
            startIcon={<SyncIcon />}
            onClick={() => {
                onChange({ type: LocationEvent.MenuSync });
            }}
        />
    );
};
