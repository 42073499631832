import { call, fork, ForkEffect, put, takeEvery, takeLatest } from "redux-saga/effects";
import { core } from "services/api";
import {
    menuFetchError,
    menuFetchSuccess,
    menuPageFetchError as menuPageFetchErrorAction,
    menuPageFetchSuccess as menuPageFetchSuccessAction,
    menuAddSuccess as menuAddSuccessAction,
    menuEditSuccess as menuEditSuccessAction,
} from "./actions";
import masterItemsSaga from "./items/effects";
import masterModifiersSaga from "./items/modifiers/effects";
import optionSetsSaga from "./items/option-sets/effects";
import nutritionSaga from "./nutrition/effects";
import periodsSaga from "./periods/effects";
import layersSaga from "./layers/effects";
import sectionsSaga from "./sections/effects";
import { enqueueSnackbar } from "store/notifications/actions";
import {
    MasterMenu,
    MasterMenuPageResp,
    MENU_FETCH,
    MENU_PAGE_FETCH,
    MENU_ADD,
    MenuAdd,
    MENU_EDIT,
    MasterMenuUpdate,
} from "./types";

function* menuFetch({ url }: { url: string; type: typeof MENU_FETCH }) {
    try {
        const resp: { data: MasterMenu } = yield call(core.get, url);

        yield put(menuFetchSuccess(resp.data));
    } catch (e) {
        if (e instanceof Error) {
            console.error("Failed to fetch Master Menu", e);
            yield put(menuFetchError(e));
        }
    }
}

function* menuPageFetch({ url }: { url: string; type: typeof MENU_PAGE_FETCH }) {
    try {
        const resp: { data: MasterMenuPageResp } = yield call(core.get, url);

        yield put(menuPageFetchSuccessAction(resp.data._embedded.menus));
    } catch (e) {
        if (e instanceof Error) {
            console.error("Failed to fetch Master Menu list", e);
            yield put(menuPageFetchErrorAction(e));
        }
    }
}

function* menuAdd({ url, menu, callback }: MenuAdd) {
    try {
        const resp: { data: MasterMenu } = yield call(core.post, url, menu);

        yield put(menuAddSuccessAction(resp.data));
        yield put(
            enqueueSnackbar({
                message: `Successfully created Menu: ${menu.name}.`,
                options: {
                    variant: "success",
                },
            }),
        );
        if (callback) {
            yield call(callback);
        }
    } catch (e) {
        if (e instanceof Error && callback) {
            console.error("Failed to create Menu", e);
            yield call(callback, e);
        }
    }
}

function* menuEdit({
    url,
    menu,
    callback,
}: {
    url: string;
    menu: MasterMenuUpdate;
    callback?: (error?: Error) => void;
    type: typeof MENU_EDIT;
}) {
    try {
        const resp: { data: MasterMenu } = yield call(core.put, url, menu);
        yield put(menuEditSuccessAction(resp.data));
        yield put(
            enqueueSnackbar({
                message: `Successfully renamed Menu: ${menu.name}`,
                options: {
                    variant: "success",
                },
            }),
        );
        if (callback) {
            yield call(callback);
        }
    } catch (e) {
        if (e instanceof Error && callback) {
            yield call(callback, e);
        }
    }
}

export default function* masterMenusSaga(): Generator<ForkEffect<void>, void, unknown> {
    yield takeEvery(MENU_FETCH, menuFetch);
    yield takeLatest(MENU_PAGE_FETCH, menuPageFetch);
    yield takeLatest(MENU_ADD, menuAdd);
    yield takeLatest(MENU_EDIT, menuEdit);
    yield fork(masterItemsSaga);
    yield fork(masterModifiersSaga);
    yield fork(optionSetsSaga);
    yield fork(periodsSaga);
    yield fork(layersSaga);
    yield fork(nutritionSaga);
    yield fork(sectionsSaga);
}
