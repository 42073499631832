import { Divider, Grid, TextField, Typography } from "@mui/material";
import { FormError } from "components/common/forms/FormError";
import { handleErrors } from "components/common/forms/helpers";
import { FORM_ERROR, getIn } from "final-form";
import React from "react";
import { Field, useFormState } from "react-final-form";
import { ruleName, ruleType } from "./constants";
import { Constraints } from "./Constraints";
import { EntitiesSelected } from "./EntitiesSelected";
import { useConfigurationsStyles } from "./styles";
import { Transformations } from "./Transformations";

export function ConfigureRule(): JSX.Element {
    const { classes } = useConfigurationsStyles();
    const { values } = useFormState();
    const selectedRuleType = getIn(values, "ruleType");

    return (
        <Grid container spacing={3} justifyContent="space-between">
            <Grid item xs={selectedRuleType === ruleType.Menu ? 9 : 12}>
                <Typography variant="h4">Configure the rules and optional conditions below.</Typography>

                <FormError className={classes.formError} name={FORM_ERROR} />

                <Divider />

                <Grid container direction="column" spacing={4}>
                    <Grid item xs={6} md={4}>
                        <Field name={ruleName} validate={(v) => (v ? "" : "Required")}>
                            {({ input, meta }) => (
                                <TextField
                                    {...input}
                                    {...handleErrors(meta, "Internal use only — will not appear on the Menu.")}
                                    label="Rule Name"
                                    id="rule-name"
                                    variant="outlined"
                                    className={classes.name}
                                    autoFocus
                                    fullWidth
                                />
                            )}
                        </Field>
                    </Grid>

                    <Grid item>
                        <Constraints />
                    </Grid>
                    <Grid item>
                        <Transformations />
                    </Grid>
                </Grid>
            </Grid>

            {selectedRuleType === ruleType.Menu && (
                <Grid item xs={3}>
                    <EntitiesSelected disableCardActions />
                </Grid>
            )}
        </Grid>
    );
}
