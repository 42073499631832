import { Theme, Typography } from "@mui/material";
import { makeStyles } from "theme";
import clsx from "clsx";
import pluralize from "pluralize";
import React from "react";
import { Nutrition } from "store/mms/types";

interface NutritionFactsDisplayProps {
    nutrition?: Nutrition | null;
    className?: string;
}

const useStyles = makeStyles()((theme: Theme) => ({
    nutritionInfo: {
        color: theme.palette.text.secondary,
    },
}));

const formatNutrition = (nutrition: Nutrition): string => {
    const servingSizesEqual = nutrition?.serving && nutrition?.serving.base === nutrition?.serving.total;
    const baseServingUnit = nutrition?.serving.unit && pluralize(nutrition?.serving.unit, nutrition?.serving.base);
    const totalServingUnit = nutrition?.serving.unit && pluralize(nutrition?.serving.unit, nutrition?.serving.total);
    const caloriesUnit = pluralize("calorie", Math.max(nutrition.calories.lower, nutrition.calories.upper || 0));

    let info = nutrition.calories.lower.toString();
    if (nutrition.calories.upper) {
        info += ` - ${nutrition?.calories.upper}`;
    }
    info += ` ${caloriesUnit} per`;
    if (nutrition?.serving.base > 1) {
        info += ` ${nutrition?.serving.base}`;
    }
    info += ` ${baseServingUnit}`;
    if (!servingSizesEqual) {
        info += ` (${nutrition?.serving.total} ${totalServingUnit})`;
    }
    return info;
};

export default function NutritionFactsDisplay({ nutrition, className }: NutritionFactsDisplayProps): JSX.Element {
    const { classes } = useStyles();
    return (
        <>
            {nutrition && (
                <Typography variant="subtitle1" className={clsx(classes.nutritionInfo, className)}>
                    {formatNutrition(nutrition)}
                </Typography>
            )}
        </>
    );
}
