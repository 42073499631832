import { Typography, Alert } from "@mui/material";
import ConfirmDialog from "components/common/ConfirmDialog";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { sectionSave } from "store/mms/menus/master/sections/actions";
import { Section } from "store/mms/menus/master/sections/types";
import { axiosErrorMessage } from "store/types";

interface MasterSectionVisibilityProps {
    menuName: string;
    section: Section;
    onClose(): void;
    open: boolean;
}

export function MasterSectionVisibility({
    menuName,
    section,
    onClose,
    open,
}: MasterSectionVisibilityProps): JSX.Element {
    const dispatch = useDispatch();
    const [state, setState] = useState<{ saving: boolean; error?: string }>({
        saving: false,
    });
    const hideDisplayText = section.enabled ? "Hide" : "Display";

    return (
        <ConfirmDialog
            title={`${hideDisplayText} Category`}
            open={open}
            onClose={onClose}
            onConfirm={() => {
                setState({ error: undefined, saving: true });
                dispatch(
                    sectionSave({ ...section, enabled: !section.enabled }, undefined, (error?: Error): void => {
                        if (error) {
                            return setState({ saving: false, error: axiosErrorMessage(error) });
                        }
                        setState({ saving: false, error: undefined });
                        onClose();
                    }),
                );
            }}
            confirm={`${hideDisplayText} Category`}
            confirming={state.saving}
            maxWidth="xl"
            transitionDuration={0}
        >
            <Typography>
                Are you sure you want to {hideDisplayText.toLowerCase()} the Category <strong>{section.name}</strong> on
                the {menuName} Menu?
            </Typography>

            <Alert variant="outlined" severity="info">
                This change will affect <strong>all Categories and at all Locations</strong>.
            </Alert>

            {state.error && (
                <Alert variant="outlined" severity="error">
                    {state.error}
                </Alert>
            )}
        </ConfirmDialog>
    );
}
