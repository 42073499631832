import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "theme";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    FormGroup,
    FormControlLabel,
    Checkbox,
} from "@mui/material";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import getTheme from "theme";
import {
    reportsFiltersAddRestaurantConcept,
    reportsFiltersRemoveRestaurantConcept,
    reportsFiltersClearRestaurantConcept,
} from "store/reports/actions";
import { RootState } from "store/rootReducer";

const useStyles = makeStyles()(() => ({
    root: {
        width: "100%",
    },
}));

export default function RestaurantConcepts(): JSX.Element {
    const { classes } = useStyles();
    const [isOpen, setIsOpen] = useState(true);
    const dispatch = useDispatch();
    const filters = useSelector((state: RootState) => state.reportFilters);
    const theme = getTheme();
    const onChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        const concept = {
            id: event.currentTarget.id,
            name: event.currentTarget.name,
        };

        if (checked) {
            dispatch(reportsFiltersAddRestaurantConcept(concept));
        } else {
            dispatch(reportsFiltersRemoveRestaurantConcept(concept));
        }
    };
    const onChangeClear = () => {
        dispatch(reportsFiltersClearRestaurantConcept());
    };
    const onAccordionChange = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={classes.root}>
            <Accordion
                disabled={filters.loading}
                expanded={isOpen}
                onChange={onAccordionChange}
                elevation={theme.mixins.accordionElevation}
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography variant="subtitle2">Concept</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color="primary"
                                    onChange={onChangeClear}
                                    checked={!filters.applied.RestaurantConcepts.length}
                                />
                            }
                            label="All"
                        />
                        {filters.available.RestaurantConcepts.map(({ id, name }) => (
                            <FormControlLabel
                                key={id}
                                control={
                                    <Checkbox
                                        color="primary"
                                        onChange={onChange}
                                        checked={filters.applied.RestaurantConcepts.some((c) => c.id === id)}
                                        name={name}
                                        id={id}
                                    />
                                }
                                label={name}
                            />
                        ))}
                    </FormGroup>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}
