import { Block } from "../../master/periods/types";

export const PERIOD_PAGE_FETCH = "@@ADMINLOCATION/MENU/LOCATION/PERIOD/PAGE/FETCH";
export const PERIOD_PAGE_FETCH_SUCCESS = "@@ADMINLOCATION/MENU/LOCATION/PERIOD/PAGE/FETCH_SUCCESS";
export const PERIOD_PAGE_FETCH_ERROR = "@@ADMINLOCATION/MENU/LOCATION/PERIOD/PAGE/FETCH_ERROR";

export const PERIOD_BLOCKS_SAVE = "@@ADMINLOCATION/MENU/LOCATION/PERIOD/BLOCKS/SAVE";
export const PERIOD_BLOCKS_SAVE_SUCCESS = "@@ADMINLOCATION/MENU/LOCATION/PERIOD/BLOCKS/SAVE_SUCCESS";
export const PERIOD_BLOCKS_SAVE_ERROR = "@@ADMINLOCATION/MENU/LOCATION/PERIOD/BLOCKS/SAVE_ERROR";

export interface Period {
    display_name: string;
    id: string;
    reference_name: string;

    _embedded: {
        blocks: Block[];
    };

    _links: {
        blocks: {
            href: string;
            type: "application/hal+json; name=adminlocation_menu_period_block_list";
        };

        self: {
            href: string;
            type: "application/hal+json; name=adminlocation_menu_period";
        };
    };
}

export interface PeriodPageFetch {
    type: typeof PERIOD_PAGE_FETCH;
    url: string;
}

interface PeriodPageFetchSuccess {
    type: typeof PERIOD_PAGE_FETCH_SUCCESS;
    periods: Period[];
    menuUrl: string;
}

interface PeriodPageFetchError {
    type: typeof PERIOD_PAGE_FETCH_ERROR;
    error: Error;
}

interface PeriodBlocksSave {
    type: typeof PERIOD_BLOCKS_SAVE;
    period: Period;
    blocks: Block[];
    url: string;
    callback?: (error?: Error) => void;
}

interface PeriodBlocksSaveSuccess {
    type: typeof PERIOD_BLOCKS_SAVE_SUCCESS;
    period: Period;
    blocks: Block[];
    menuUrl: string;
}

interface PeriodBlocksSaveError {
    type: typeof PERIOD_BLOCKS_SAVE_ERROR;
    error: Error;
}

export type PeriodActions =
    | PeriodPageFetch
    | PeriodPageFetchSuccess
    | PeriodPageFetchError
    | PeriodBlocksSave
    | PeriodBlocksSaveSuccess
    | PeriodBlocksSaveError;
