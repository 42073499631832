import { AlertProps, Alert } from "@mui/material";
import React from "react";
import { Field } from "react-final-form";

interface FormErrorFieldProps extends Partial<AlertProps> {
    name: string;
}

export function FormError({ name, ...restAlert }: FormErrorFieldProps): JSX.Element {
    return (
        <Field
            name={name}
            subscription={{ touched: true, error: true, submitError: true }}
            render={({ meta: { touched, error, submitError } }) =>
                touched && (error || submitError) ? (
                    <Alert variant="outlined" severity="error" {...restAlert}>
                        {error || submitError}
                    </Alert>
                ) : null
            }
        />
    );
}
