import { MasterModifier } from "../modifiers/types";
import { MasterItem } from "../types";
import { ModifierGroup, NewModifierGroup } from "./modifier-group/types";
import {
    OptionSet,
    OptionSetModifier,
    OptionSetsActions,
    OPTION_SET_CREATE,
    OPTION_SET_CREATE_ERROR,
    OPTION_SET_CREATE_SUCCESS,
    OPTION_SET_DELETE,
    OPTION_SET_DELETE_ERROR,
    OPTION_SET_DELETE_SUCCESS,
    OPTION_SET_FETCH,
    OPTION_SET_FETCH_ERROR,
    OPTION_SET_FETCH_SUCCESS,
    OPTION_SET_PAGE_FETCH,
    OPTION_SET_PAGE_FETCH_ERROR,
    OPTION_SET_PAGE_FETCH_SUCCESS,
    OPTION_SET_SAVE,
    OPTION_SET_SAVE_SUCCESS,
    OPTION_SET_MODIFIER_SAVE,
    OPTION_SET_MODIFIER_SAVE_SUCCESS,
    OPTION_SETS_HYDRATE,
} from "./types";

export function optionSetFetch(url: string): OptionSetsActions {
    return {
        type: OPTION_SET_FETCH,
        url,
    };
}

export function optionSetFetchSuccess(optionSet: OptionSet): OptionSetsActions {
    return {
        type: OPTION_SET_FETCH_SUCCESS,
        optionSet,
    };
}

export function optionSetFetchError(error: Error): OptionSetsActions {
    return {
        type: OPTION_SET_FETCH_ERROR,
        error,
    };
}

export function optionSetPageFetch(url: string): OptionSetsActions {
    return {
        type: OPTION_SET_PAGE_FETCH,
        url,
    };
}

export function optionSetPageFetchSuccess(optionSets: OptionSet[]): OptionSetsActions {
    return {
        type: OPTION_SET_PAGE_FETCH_SUCCESS,
        optionSets,
    };
}

export function optionSetPageFetchError(error: Error): OptionSetsActions {
    return {
        type: OPTION_SET_PAGE_FETCH_ERROR,
        error,
    };
}

export function optionSetSave(optionSet: OptionSet): OptionSetsActions {
    return {
        type: OPTION_SET_SAVE,
        optionSet,
    };
}

export function optionSetSaveSuccess(optionSet: OptionSet): OptionSetsActions {
    return {
        type: OPTION_SET_SAVE_SUCCESS,
        optionSet,
    };
}

export function optionSetCreate(
    group: ModifierGroup | NewModifierGroup,
    parent: MasterItem | MasterModifier,
    callback?: (optionSet?: OptionSet, error?: Error) => void,
): OptionSetsActions {
    return {
        type: OPTION_SET_CREATE,
        group,
        parent,
        callback,
    };
}

export function optionSetCreateSuccess(optionSet: OptionSet): OptionSetsActions {
    return {
        type: OPTION_SET_CREATE_SUCCESS,
        optionSet,
    };
}

export function optionSetCreateError(error: Error): OptionSetsActions {
    return {
        type: OPTION_SET_CREATE_ERROR,
        error,
    };
}

export function optionSetModifierSave(
    optionSet: OptionSet,
    optionSetModifier: OptionSetModifier,
    callback?: (error?: Error) => void,
): OptionSetsActions {
    return {
        type: OPTION_SET_MODIFIER_SAVE,
        optionSet,
        optionSetModifier,
        callback,
    };
}

export function optionSetModifierSaveSuccess(
    optionSet: OptionSet,
    optionSetModifier: OptionSetModifier,
): OptionSetsActions {
    return {
        type: OPTION_SET_MODIFIER_SAVE_SUCCESS,
        optionSet,
        optionSetModifier,
    };
}

export function optionSetDelete(url: string, callback?: (error?: Error) => void): OptionSetsActions {
    return {
        type: OPTION_SET_DELETE,
        url,
        callback,
    };
}

export function optionSetDeleteSuccess(optionSet: OptionSet): OptionSetsActions {
    return {
        type: OPTION_SET_DELETE_SUCCESS,
        optionSet,
    };
}

export function optionSetDeleteError(error: Error): OptionSetsActions {
    return {
        type: OPTION_SET_DELETE_ERROR,
        error,
    };
}

export function optionSetsHydrate(optionSets: OptionSet[]): OptionSetsActions {
    return {
        type: OPTION_SETS_HYDRATE,
        optionSets,
    };
}
