import { Box, ListItemIcon, ListSubheader, SxProps, Theme, Typography } from "@mui/material";
import { DragIndicator as DragIndicatorIcon } from "@mui/icons-material";
import React, { CSSProperties, useEffect, useState } from "react";
import { MasterModifier } from "store/mms/menus/master/items/modifiers/types";
import { OptionSet as MasterOptionSet } from "store/mms/menus/master/items/option-sets/types";
import { entityMasterPageLink } from "store/mms/menus/types";
import { Link } from "react-router-dom";
import { money } from "components/common/utils";
import { Nutrition } from "store/mms/types";
import { ModifierGroup } from "store/mms/menus/master/items/option-sets/modifier-group/types";
import ActionsGroup from "components/common/ActionsGroup";
import { MasterItem } from "store/mms/menus/master/items/types";
import { List } from "components/common/List";
import { DraggableListItemStyles, ListItem } from "components/common/ListItem";
import {
    closestCenter,
    DndContext,
    DragEndEvent,
    DraggableSyntheticListeners,
    DragOverlay,
    DragStartEvent,
    MouseSensor,
    TouchSensor,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import { arrayMove, rectSortingStrategy, SortableContext, useSortable, UseSortableArguments } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { EditPOSMapping } from "../IconButtons/EditPOSMapping";
import { EntityRemove } from "../IconButtons/EntityRemove";
import { ToggleDisplay } from "../IconButtons/ToggleDisplay";

interface modifierActions {
    onModifierAddGroup?(modifier: MasterModifier): void;
    onModifierDelete?(modifierGroup: ModifierGroup, modifier: MasterModifier): void;
    onModifierEdit?(modifier: MasterModifier): void;
    onModifierVisibility?(modifier: MasterModifier): void;
    onOptionSetDefaultModifier?(optionSet: MasterOptionSet, modifier: MasterModifier, isDefault: boolean): void;
    onOptionSetDelete?(optionSet: MasterOptionSet, parent: MasterModifier | MasterItem): void;
    onOptionSetVisibility?(optionSet: MasterOptionSet): void;
}

interface MasterOptionSetModifiersProps extends modifierActions {
    className?: string;
    itemId: string;
    modsByHref: { [key: string]: MasterModifier };
    modGroupsById: { [key: string]: ModifierGroup };
    onReorderModifiers?(modifierGroup: ModifierGroup, modifiers: { id: string; enabled: boolean }[]): void;
    optionSet: MasterOptionSet;
    optionSetIdsByModifierId: { [key: string]: string[] };
    optionSetsById: { [key: string]: MasterOptionSet };
}

export function MasterOptionSetModifiers({
    className,
    modsByHref,
    modGroupsById,
    onReorderModifiers,
    optionSet,
    ...rest
}: MasterOptionSetModifiersProps): JSX.Element {
    const group = modGroupsById[optionSet._embedded.modifier_group.id];
    const [modifiers, setModifiers] = useState(group._embedded.modifiers);
    const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
    const [activeId, setActiveId] = useState<string | null>(null);
    const activeIndex = activeId ? modifiers.findIndex((m) => m._embedded.modifier._links.self.href === activeId) : -1;
    const handleDragStart = (event: DragStartEvent): void => setActiveId(event.active.id);
    const handleDragCancel = (): void => setActiveId(null);
    const handleDragEnd = ({ active, over }: DragEndEvent): void => {
        if (over !== null && active.id !== over.id) {
            const oldIndex = modifiers.findIndex((i) => i._embedded.modifier._links.self.href === active.id);
            const newIndex = modifiers.findIndex((i) => i._embedded.modifier._links.self.href === over.id);
            const newSequence = arrayMove(modifiers, oldIndex, newIndex);

            if (onReorderModifiers) {
                onReorderModifiers(
                    group,
                    newSequence.map((mgm) => ({ id: mgm._embedded.modifier.id, enabled: mgm.enabled })),
                );
            }
            setModifiers(newSequence);
        }

        setActiveId(null);
    };

    useEffect(() => {
        setModifiers(group._embedded.modifiers);
    }, [group]);

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onDragCancel={handleDragCancel}
        >
            <SortableContext items={modifiers} strategy={rectSortingStrategy}>
                <List
                    className={className}
                    sx={listStyle}
                    aria-labelledby="modifiers-header"
                    subheader={
                        <ListSubheader id="modifiers-header" disableGutters>
                            <Typography variant="h6">Modifiers</Typography>
                            <hr />
                        </ListSubheader>
                    }
                >
                    {modifiers.length ? (
                        modifiers.map((m, index) => {
                            const mod = modsByHref[m._embedded.modifier._links.self.href];
                            return (
                                <SortableModifierItem
                                    activeIndex={activeIndex}
                                    modsByHref={modsByHref}
                                    modGroupsById={modGroupsById}
                                    modifier={mod}
                                    optionSet={optionSet}
                                    isParentDisabled={!optionSet.enabled}
                                    isOptionSetDefault={
                                        optionSet._embedded.modifiers.find((osm) => osm.id === m._embedded.modifier.id)
                                            ?.default || false
                                    }
                                    index={index}
                                    key={mod.id + index}
                                    {...rest}
                                />
                            );
                        })
                    ) : (
                        <ListItem ListItemTextProps={{ primary: "No modifiers" }} />
                    )}
                </List>
            </SortableContext>

            <DragOverlay>
                {activeId ? (
                    <ModifierItem
                        modsByHref={modsByHref}
                        modGroupsById={modGroupsById}
                        modifier={modsByHref[activeId]}
                        optionSet={optionSet}
                        isParentDisabled={!optionSet.enabled}
                        isOptionSetDefault={
                            optionSet._embedded.modifiers.find((osm) => osm.id === modsByHref[activeId].id)?.default ||
                            false
                        }
                        index={modifiers.findIndex((m) => m._embedded.modifier._links.self.href === activeId)}
                        dragOverlay
                        {...rest}
                    />
                ) : null}
            </DragOverlay>
        </DndContext>
    );
}

interface MasterOptionSetListProps extends modifierActions {
    className?: string;
    itemId?: string;
    modsByHref: { [key: string]: MasterModifier };
    modGroupsById: { [key: string]: ModifierGroup };
    onResequenceOptionSets?(optionSets: MasterOptionSet[]): void;
    optionSetIdsByModifierId: { [key: string]: string[] };
    optionSetsById: { [key: string]: MasterOptionSet };
    masterEntity: MasterItem | MasterModifier;
}

export function MasterOptionSetList({
    className,
    itemId,
    modsByHref,
    modGroupsById,
    onResequenceOptionSets,
    masterEntity,
    optionSetsById,
    ...rest
}: MasterOptionSetListProps): JSX.Element {
    const [oses, setOses] = useState(masterEntity._embedded.option_sets.map((os) => optionSetsById[os.id]));
    const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
    const [activeId, setActiveId] = useState<string | null>(null);
    const activeIndex = activeId ? oses.findIndex((os) => os.id === activeId) : -1;
    const handleDragStart = (event: DragStartEvent): void => setActiveId(event.active.id);
    const handleDragCancel = (): void => setActiveId(null);
    const handleDragEnd = ({ active, over }: DragEndEvent): void => {
        if (over !== null && active.id !== over.id) {
            const oldIndex = oses.findIndex((os) => os.id === active.id);
            const newIndex = oses.findIndex((os) => os.id === over.id);
            const newSequence = arrayMove(oses, oldIndex, newIndex);

            if (onResequenceOptionSets) {
                onResequenceOptionSets(newSequence);
            }
            setOses(newSequence);
        }

        setActiveId(null);
    };

    useEffect(() => {
        setOses(masterEntity._embedded.option_sets.map((os) => optionSetsById[os.id]));
    }, [masterEntity, optionSetsById]);

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onDragCancel={handleDragCancel}
        >
            <SortableContext items={oses} strategy={rectSortingStrategy}>
                <List
                    className={className}
                    sx={listStyle}
                    aria-labelledby="modifiers-header"
                    subheader={
                        <ListSubheader id="modifiers-header" disableGutters>
                            <Typography variant="h6">Modifier Groups</Typography>
                            <hr />
                        </ListSubheader>
                    }
                >
                    {oses.length ? (
                        oses.map((os, index) => (
                            <SortableOptionSetItem
                                activeIndex={activeIndex}
                                index={index}
                                itemId={itemId}
                                modsByHref={modsByHref}
                                modGroupsById={modGroupsById}
                                optionSet={os}
                                parent={masterEntity}
                                isParentDisabled={!os.enabled}
                                key={os.id + index}
                                optionSetsById={optionSetsById}
                                {...rest}
                            />
                        ))
                    ) : (
                        <ListItem ListItemTextProps={{ primary: "No modifier groups" }} />
                    )}
                </List>
            </SortableContext>

            <DragOverlay>
                {activeId ? (
                    <OptionSetItem
                        index={oses.findIndex((os) => os.id === activeId)}
                        itemId={itemId}
                        modsByHref={modsByHref}
                        modGroupsById={modGroupsById}
                        optionSet={optionSetsById[activeId]}
                        parent={masterEntity}
                        isParentDisabled={!optionSetsById[activeId].enabled}
                        optionSetsById={optionSetsById}
                        dragOverlay
                        {...rest}
                    />
                ) : null}
            </DragOverlay>
        </DndContext>
    );
}

interface ModifierItemProps extends modifierActions, DndProps, Partial<UseSortableArguments> {
    isOptionSetDefault: boolean;
    isParentDisabled: boolean;
    itemId?: string;
    modifier: MasterModifier;
    modsByHref: { [key: string]: MasterModifier };
    modGroupsById: { [key: string]: ModifierGroup };
    optionSet: MasterOptionSet;
    optionSetIdsByModifierId: { [key: string]: string[] };
    optionSetsById: { [key: string]: MasterOptionSet };
}

const ModifierItem = React.forwardRef(function ModifierItem(
    {
        dragOverlay,
        insertPosition,
        listeners,

        isOptionSetDefault,
        isParentDisabled,
        itemId,
        modifier,
        modsByHref,
        onModifierAddGroup,
        onModifierDelete,
        onModifierEdit,
        onModifierVisibility,
        onOptionSetDefaultModifier,
        optionSet,
        optionSetIdsByModifierId,
        optionSetsById,
        modGroupsById,
        ...rest
    }: ModifierItemProps,
    ref: ((instance: HTMLAnchorElement | null) => void) | React.RefObject<HTMLAnchorElement> | null,
) {
    const handleEdit = () => {
        if (onModifierEdit) {
            onModifierEdit(modifier);
        }
    };
    const isUnlinked = !modifier.linked;
    const isDisabled = !modifier.enabled;
    const hasOptionSets =
        optionSetIdsByModifierId[modifier.id] !== undefined && optionSetIdsByModifierId[modifier.id].length > 0;
    const optionSets =
        (optionSetIdsByModifierId[modifier.id] !== undefined &&
            optionSetIdsByModifierId[modifier.id]
                .map((id) => optionSetsById[id])
                .sort((a, b) => a.sequence - b.sequence)) ||
        [];
    const calInfo = getCalories(modifier._embedded.nutrition);
    const modifierPath = entityMasterPageLink(modifier, itemId);
    const handleModifierDelete = () => {
        const group = modGroupsById[optionSet._embedded.modifier_group.id];

        if (onModifierDelete && group) {
            onModifierDelete(group, modifier);
        }
    };
    const actions = (
        <ActionsGroup>
            <Typography onClick={() => onModifierAddGroup && onModifierAddGroup(modifier)}>
                Add Modifier Group
            </Typography>
            <Typography
                onClick={() =>
                    onOptionSetDefaultModifier && onOptionSetDefaultModifier(optionSet, modifier, !isOptionSetDefault)
                }
            >
                {`${isOptionSetDefault ? "Unset" : "Set"}`} Default
            </Typography>
        </ActionsGroup>
    );

    return (
        <ListItem
            ListItemTextProps={{
                primary: (
                    <Box sx={{ display: "flex" }}>
                        {/* Only show the grip icon for draggables */}
                        {(listeners || dragOverlay) && (
                            <ListItemIcon
                                sx={(theme) => ({
                                    justifyContent: "flex-start",
                                    minWidth: "unset",
                                    marginRight: theme.spacing(0.2),
                                    alignSelf: "flex-start",
                                    marginTop: theme.spacing(0.375),
                                })}
                                {...listeners}
                            >
                                <DragIndicatorIcon />
                            </ListItemIcon>
                        )}
                        <ModifierLabel
                            calorieInfo={calInfo}
                            isDefault={isOptionSetDefault}
                            pathname={modifierPath}
                            name={modifier.display_name}
                            defaultPrice={modifier.base_price_per_unit}
                        />
                    </Box>
                ),
                primaryTypographyProps: { variant: "body1" },
                className: isDisabled || isParentDisabled ? "disabled" : undefined,
                sx: {
                    "&.disabled .MuiListItemText-secondary, &.disabled .MuiListItemText-primary,  &.disabled .MuiListItemText-primary a, &.disabled .MuiListItemText-secondary a":
                        {
                            color: "text.disabled",
                        },
                    userSelect: "none",
                },
            }}
            title={modifier.display_name}
            sx={[
                (theme) =>
                    DraggableListItemStyles(
                        theme,
                        insertPosition === Position.After,
                        insertPosition === Position.Before,
                        dragOverlay,
                    ),
                {
                    alignItems: "center",
                },
            ]}
            disableCollapse={dragOverlay}
            ref={ref}
            actions={
                <>
                    <ToggleDisplay
                        onClick={() => {
                            if (onModifierVisibility) {
                                onModifierVisibility(modifier);
                            }
                        }}
                        hidden={isDisabled}
                    />
                    <EditPOSMapping linked={!isUnlinked} onClick={handleEdit} />
                    <EntityRemove onClick={handleModifierDelete} />
                    {!isUnlinked && actions}
                </>
            }
            CollapseContainerProps={{ sx: collapseContainerStyle }}
            {...rest}
        >
            {hasOptionSets && (
                <List disablePadding>
                    {optionSets.map((os: MasterOptionSet) => (
                        <OptionSetItem
                            modsByHref={modsByHref}
                            onModifierAddGroup={onModifierAddGroup}
                            onModifierDelete={onModifierDelete}
                            onModifierEdit={onModifierEdit}
                            onModifierVisibility={onModifierVisibility}
                            onOptionSetDefaultModifier={onOptionSetDefaultModifier}
                            optionSet={os}
                            parent={modifier}
                            isParentDisabled={!modifier.enabled || isParentDisabled}
                            itemId={itemId}
                            optionSetIdsByModifierId={optionSetIdsByModifierId}
                            optionSetsById={optionSetsById}
                            modGroupsById={modGroupsById}
                            key={os.id}
                        />
                    ))}
                </List>
            )}
        </ListItem>
    );
});

const SortableModifierItem = (props: ModifierItemProps & { activeIndex: number }): JSX.Element => {
    const modHrefId = props.modifier._links.self.href;
    const sortable = useSortable({ id: modHrefId });
    const { attributes, index, isDragging, isSorting, listeners, over, setNodeRef, transform, transition } = sortable;
    const style = {
        transform: isSorting ? undefined : CSS.Transform.toString(transform),
        transition: transition ? transition : undefined,
    };
    const { activeIndex, ...rest } = props;

    return (
        <ModifierItem
            dragActive={isDragging}
            ref={setNodeRef}
            style={style}
            insertPosition={
                over?.id === modHrefId ? (index > activeIndex ? Position.After : Position.Before) : undefined
            }
            listeners={listeners}
            {...rest}
            {...attributes}
        />
    );
};

interface OptionSetItemProps extends modifierActions, DndProps, Partial<UseSortableArguments> {
    isParentDisabled: boolean;
    itemId?: string;
    modsByHref: { [key: string]: MasterModifier };
    modGroupsById: { [key: string]: ModifierGroup };
    optionSet: MasterOptionSet;
    optionSetIdsByModifierId: { [key: string]: string[] };
    optionSetsById: { [key: string]: MasterOptionSet };
    parent: MasterItem | MasterModifier;
}

const OptionSetItem = React.forwardRef(function OptionSetItem(
    {
        dragOverlay,
        insertPosition,
        listeners,

        isParentDisabled,
        itemId,
        modsByHref,
        modGroupsById,
        optionSet,
        onOptionSetDelete,
        onOptionSetVisibility,
        parent,
        ...rest
    }: OptionSetItemProps,
    ref: ((instance: HTMLAnchorElement | null) => void) | React.RefObject<HTMLAnchorElement> | null,
) {
    const group = modGroupsById[optionSet._embedded.modifier_group.id];
    const mods: MasterModifier[] = group._embedded.modifiers
        .map((m) => m._embedded.modifier)
        .filter((m) => modsByHref[m._links.self.href]);
    const isDisabled = !optionSet.enabled;
    const isDraggable = listeners !== undefined || dragOverlay;

    return (
        <ListItem
            ListItemTextProps={{
                className: isDisabled || isParentDisabled ? "disabled" : undefined,
                primary: (
                    <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                        {/* Only show the grip icon for draggables */}
                        {isDraggable && (
                            <ListItemIcon
                                sx={(theme) => ({
                                    justifyContent: "flex-start",
                                    minWidth: "unset",
                                    marginRight: theme.spacing(0.2),
                                    alignSelf: "flex-start",
                                    marginTop: theme.spacing(0.375),
                                })}
                                {...listeners}
                            >
                                <DragIndicatorIcon />
                            </ListItemIcon>
                        )}
                        <Box component={Link} to={entityMasterPageLink(optionSet, itemId)}>
                            {group.display_name}
                        </Box>
                    </Box>
                ),
                secondary: optionSetHelperText(optionSet),
                primaryTypographyProps: { variant: "body1", sx: { display: "inline-flex" } },
                secondaryTypographyProps: isDraggable ? undefined : { sx: { marginLeft: 0 } },
            }}
            ref={ref}
            className={isDisabled ? "disabled" : undefined}
            sx={(theme) =>
                DraggableListItemStyles(
                    theme,
                    insertPosition === Position.After,
                    insertPosition === Position.Before,
                    dragOverlay,
                )
            }
            disableCollapse={dragOverlay}
            actions={
                <>
                    <ToggleDisplay
                        onClick={() => onOptionSetVisibility && onOptionSetVisibility(optionSet)}
                        hidden={isDisabled}
                    />
                    <EntityRemove onClick={() => onOptionSetDelete && onOptionSetDelete(optionSet, parent)} />
                </>
            }
            CollapseContainerProps={{ sx: collapseContainerStyle }}
        >
            <List disablePadding>
                {mods.map((m: MasterModifier) => (
                    <ModifierItem
                        modsByHref={modsByHref}
                        modGroupsById={modGroupsById}
                        modifier={modsByHref[m._links.self.href]}
                        optionSet={optionSet}
                        isOptionSetDefault={
                            optionSet._embedded.modifiers.find((osm) => osm.id === m.id)?.default || false
                        }
                        onOptionSetDelete={onOptionSetDelete}
                        onOptionSetVisibility={onOptionSetVisibility}
                        isParentDisabled={!optionSet.enabled || isParentDisabled}
                        key={m.id}
                        itemId={itemId || parent.id}
                        {...rest}
                    />
                ))}
            </List>
        </ListItem>
    );
});

const SortableOptionSetItem = (props: OptionSetItemProps & { activeIndex: number }): JSX.Element => {
    const sortable = useSortable({ id: props.optionSet.id });
    const { attributes, index, isDragging, isSorting, listeners, over, setNodeRef, transform, transition } = sortable;
    const style = {
        transform: isSorting ? undefined : CSS.Transform.toString(transform),
        transition: transition ? transition : undefined,
    };
    const { activeIndex, ...rest } = props;

    return (
        <OptionSetItem
            dragActive={isDragging}
            ref={setNodeRef}
            style={style}
            insertPosition={
                over?.id === props.optionSet.id ? (index > activeIndex ? Position.After : Position.Before) : undefined
            }
            listeners={listeners}
            {...rest}
            {...attributes}
        />
    );
};

interface ModifierLabelProps {
    calorieInfo?: string;
    defaultPrice: number;
    isDefault: boolean;
    name: string;
    pathname: string;
}

function ModifierLabel({ calorieInfo, defaultPrice, isDefault, name, pathname }: ModifierLabelProps): JSX.Element {
    return (
        <Box
            sx={(theme) => ({
                alignSelf: "flex-end",

                "& .calories": {
                    fontStyle: "italic",
                },

                "& .price": {
                    fontWeight: theme.typography.fontWeightBold,
                },

                "& .default-modifier": {
                    marginLeft: theme.spacing(2),
                },
            })}
            component="span"
        >
            <Link to={pathname}>{name}</Link>
            {defaultPrice ? <span className="price">{` (+ ${money(defaultPrice)})`}</span> : null}
            {calorieInfo && <span className="calories">{` (${calorieInfo})`}</span>}
            {isDefault && (
                <Typography className="default-modifier" variant="caption">
                    Default
                </Typography>
            )}
        </Box>
    );
}

function optionSetHelperText(os: MasterOptionSet): string {
    if (os.implicit) {
        return "Implicit";
    }

    const max = os.max;
    const min = os.min;
    const req = os.required ? "Required | " : "Optional | ";

    if (min === 0) {
        return max === 0 ? req + "Choose any" : req + `Choose up to ${max}`;
    }

    if (max === 0) {
        return req + `Choose at least ${min}`;
    }

    return min === max ? req + `Choose ${max}` : req + `Choose between ${min} and ${max}`;
}

function getCalories(nutrition?: Nutrition | null): string | undefined {
    if (!nutrition) {
        return;
    }
    const calories = nutrition.calories;

    return `${calories.lower}${calories.upper ? " - " + calories.upper : ""} cals`;
}

const listStyle: SxProps<Theme> = (theme) => ({
    borderBottom: `1.5px solid ${theme.palette.divider}`,
    paddingBottom: 0,

    "& .MuiList-root": {
        paddingLeft: theme.spacing(5.75),
    },

    "& > .MuiListSubheader-root > hr": {
        marginBottom: 0,
    },

    "& .MuiListItemText-secondary": {
        color: "text.secondary",
    },
});

const collapseContainerStyle: SxProps<Theme> = { padding: (theme) => theme.spacing(2, 0) };

enum Position {
    Before = -1,
    After = 1,
}

interface DndProps {
    dragActive?: boolean;
    dragOverlay?: boolean;
    insertPosition?: Position;
    index?: number;
    listeners?: DraggableSyntheticListeners;
    style?: CSSProperties;
}
