import {
    OrderProfile,
    OrderProfileAdjustmentStrategyTypes,
    OrderProfileTypes,
    OrderProfileAdjustmentTypes,
} from "store/mms/order-profiles/types";

interface state {
    profile: OrderProfile;
    adjustmentEnabledType: string;
    upliftStrategy: OrderProfileAdjustmentStrategyTypes;
    formattedAmount: string;
    formErrors: {
        name: boolean;
        type: boolean;
        adjustmentAmount: boolean;
    };
    saving: boolean;
}

type action =
    | { type: "CHANGE_NAME"; name: string }
    | { type: "CHANGE_TYPE"; profileType: OrderProfileTypes }
    | { type: "CHANGE_ADJUSTMENT_ENABLED"; enabledType: string }
    | { type: "CHANGE_ADJUSTMENT_TYPE"; adjustmentType: OrderProfileAdjustmentTypes | undefined }
    | { type: "CHANGE_ADJUSTMENT_AMOUNT"; formattedAmount: string; adjustmentAmount: number | undefined }
    | { type: "CHANGE_FORMATTED_AMOUNT" }
    | { type: "CHANGE_ADJUSTMENT_STRATEGY"; strategy: OrderProfileAdjustmentStrategyTypes }
    | { type: "UPDATE_FORM_ERRORS"; formErrors: { name: boolean; type: boolean; adjustmentAmount: boolean } }
    | { type: "SAVE_PROFILE"; save(): void };

export const getInitialState = (profile: OrderProfile | undefined): state => {
    return {
        profile:
            profile ||
            ({
                id: "",
                locations: 0,
                name: "",
                type: OrderProfileTypes.Delivery,
                adjustment: { enabled: false },
            } as OrderProfile),
        adjustmentEnabledType:
            (profile &&
                profile.adjustment.enabled &&
                (profile.adjustment.strategy === OrderProfileAdjustmentStrategyTypes.TicketDiscount
                    ? "discount"
                    : "uplift")) ||
            "",
        upliftStrategy:
            (profile &&
                profile.adjustment.strategy !== OrderProfileAdjustmentStrategyTypes.TicketDiscount &&
                profile.adjustment.strategy) ||
            OrderProfileAdjustmentStrategyTypes.ForcePriceAll,
        formattedAmount:
            (profile &&
                profile.adjustment.amount != undefined &&
                (profile.adjustment.type === OrderProfileAdjustmentTypes.Dollar
                    ? (profile.adjustment.amount / 100 || 0).toFixed(2)
                    : profile.adjustment.amount / 100
                ).toString()) ||
            "",
        formErrors: {
            name: false,
            type: false,
            adjustmentAmount: false,
        },
        saving: false,
    };
};

export function reducer(state: state, action: action): state {
    switch (action.type) {
        case "CHANGE_NAME":
            return {
                ...state,
                profile: {
                    ...state.profile,
                    name: action.name,
                },
            };
        case "CHANGE_TYPE":
            return {
                ...state,
                profile: {
                    ...state.profile,
                    type: action.profileType,
                },
            };
        case "CHANGE_ADJUSTMENT_ENABLED":
            if (action.enabledType) {
                if (action.enabledType === "discount") {
                    return {
                        ...state,
                        adjustmentEnabledType: action.enabledType,
                        profile: {
                            ...state.profile,
                            adjustment: {
                                ...state.profile.adjustment,
                                enabled: true,
                                strategy: OrderProfileAdjustmentStrategyTypes.TicketDiscount,
                            },
                        },
                    };
                }

                if (!state.profile.adjustment.type) {
                    return {
                        ...state,
                        adjustmentEnabledType: action.enabledType,
                        profile: {
                            ...state.profile,
                            adjustment: {
                                ...state.profile.adjustment,
                                enabled: true,
                                strategy: state.upliftStrategy,
                                type: OrderProfileAdjustmentTypes.Percent,
                            },
                        },
                    };
                }

                return {
                    ...state,
                    adjustmentEnabledType: action.enabledType,
                    profile: {
                        ...state.profile,
                        adjustment: {
                            ...state.profile.adjustment,
                            enabled: true,
                            strategy: state.upliftStrategy,
                        },
                    },
                };
            }
            return {
                ...state,
                adjustmentEnabledType: action.enabledType,
                profile: {
                    ...state.profile,
                    adjustment: {
                        ...state.profile.adjustment,
                        enabled: false,
                    },
                },
            };
        case "CHANGE_ADJUSTMENT_TYPE":
            return {
                ...state,
                formattedAmount: state.formattedAmount && parseFloat(state.formattedAmount).toFixed(2),
                profile: {
                    ...state.profile,
                    adjustment: {
                        ...state.profile.adjustment,
                        type: action.adjustmentType,
                    },
                },
            };
        case "CHANGE_ADJUSTMENT_AMOUNT":
            return {
                ...state,
                formattedAmount: action.formattedAmount,
                profile: {
                    ...state.profile,
                    adjustment: {
                        ...state.profile.adjustment,
                        amount: action.adjustmentAmount,
                    },
                },
            };
        case "CHANGE_FORMATTED_AMOUNT":
            if (state.formattedAmount && state.profile.adjustment.type === OrderProfileAdjustmentTypes.Dollar) {
                return {
                    ...state,
                    formattedAmount: parseFloat(state.formattedAmount).toFixed(2),
                };
            }
            return state;
        case "CHANGE_ADJUSTMENT_STRATEGY":
            return {
                ...state,
                upliftStrategy: action.strategy,
                profile: {
                    ...state.profile,
                    adjustment: {
                        ...state.profile.adjustment,
                        strategy: action.strategy,
                    },
                },
            };
        case "UPDATE_FORM_ERRORS":
            return {
                ...state,
                formErrors: action.formErrors,
            };
        case "SAVE_PROFILE":
            action.save();
            return {
                ...state,
                formErrors: {
                    name: false,
                    type: false,
                    adjustmentAmount: false,
                },
                saving: true,
            };
        default:
            return state;
    }
}
