import { Box, Chip, Grid, GridProps, Skeleton, Typography } from "@mui/material";
import { LocationContext, LocationEvent } from "pages/Locations/context";
import React, { useContext } from "react";
import { useNavigate } from "react-router";
import { Address, Location as AccountLocation } from "store/locations/types";
import { LocationMenu, OnboardingStatus } from "store/mms/menus/location/types";
import { ActionButton } from "./buttons/ActionButton";

interface LocationMenuInfoProps extends GridProps {
    loading?: false;
    location: AccountLocation;
    locationMenu?: LocationMenu;
}

interface LocationMenuInfoLoadingProps extends GridProps {
    loading: true;
}

export function LocationMenuInfo({
    loading = false,
    ...props
}: LocationMenuInfoProps | LocationMenuInfoLoadingProps): JSX.Element {
    const { onChange, allMenusLinked, noMasterMenus } = useContext(LocationContext);
    const { location, locationMenu, ...restGridProps } = props as LocationMenuInfoProps;

    const navigate = useNavigate();

    if (!location || loading) {
        return <LocationInfoLoadingSkeleton />;
    }

    const menuReady = locationMenu?.enabled && locationMenu?.onboarding_status === OnboardingStatus.Complete;

    let linkMenuButtonText = "Link Menus";
    let linkMenuEnabled = true;
    let linkMenuAction = () => onChange({ type: LocationEvent.MenuLink });

    if (noMasterMenus) {
        linkMenuButtonText = "Create a Menu";
        linkMenuAction = () => navigate("/menus/create");
    } else if (allMenusLinked) {
        linkMenuButtonText = "All Menus Linked";
        linkMenuEnabled = false;
    }

    return (
        <Grid
            container
            justifyContent="space-between"
            sx={{ margin: (theme) => theme.spacing(2, 0) }}
            {...restGridProps}
        >
            <Grid item>
                <Typography data-testid={location.name} variant="h5">
                    {location.name}
                </Typography>
                <Typography variant="subtitle2">{formatAddress(location.address)}</Typography>
            </Grid>

            <Grid item>
                <Grid container columnSpacing={3} direction="row" alignItems="right">
                    <Box sx={(theme) => ({ marginBottom: theme.spacing(2), marginRight: theme.spacing(3) })}>
                        {!noMasterMenus && (
                            <ActionButton
                                fullWidth
                                size="small"
                                disabled={!linkMenuEnabled}
                                label={linkMenuButtonText}
                                onClick={linkMenuAction}
                            />
                        )}
                    </Box>
                    <Box display="flex" flexDirection="column">
                        {location.development ? (
                            <Chip
                                sx={{ marginBottom: (theme) => theme.spacing(1) }}
                                label="Development Location"
                                size="small"
                                color="default"
                            />
                        ) : null}
                        {locationMenu && (
                            <Chip
                                sx={{ paddingLeft: 0 }}
                                label={`Ordering ${menuReady && locationMenu ? "Enabled" : "Disabled"}`}
                                size="small"
                                color={menuReady && locationMenu ? "success" : "warning"}
                            />
                        )}
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    );
}

interface LocationInfoLoadingSkeletonProps {
    className?: string;
}

function LocationInfoLoadingSkeleton({ className }: LocationInfoLoadingSkeletonProps): JSX.Element {
    return (
        <Grid container className={className} spacing={7} justifyContent="space-between" wrap="nowrap">
            <Grid item>
                <Typography variant="subtitle2">
                    <Skeleton width="15vw" height={60} variant="text" />
                </Typography>
            </Grid>

            <Grid item>
                <Typography variant="subtitle2">
                    <Skeleton width="15vw" height={82} variant="text" />
                </Typography>
            </Grid>
        </Grid>
    );
}

const formatAddress = (a: Address): string => {
    const pieces = [];
    if (a.street1) {
        pieces.push(a.street1);
    }
    if (a.street2) {
        pieces.push(a.street2);
    }
    if (a.city) {
        pieces.push(a.city);
    }
    if (a.state) {
        if (a.zip) {
            pieces.push(`${a.state} ${a.zip}`);
        } else {
            pieces.push(a.state);
        }
    }

    return pieces.join(", ");
};
