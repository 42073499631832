import { RuleActiveTimes } from "components/mms/MenuLayers/RuleSets/Rules/RuleActiveTimes";
import { RuleDelete } from "components/mms/MenuLayers/RuleSets/Rules/RuleDelete";
import { RuleVisibility } from "components/mms/MenuLayers/RuleSets/Rules/RuleVisibility";
import { RuleSetEdit } from "components/mms/MenuLayers/RuleSets/RuleSetEdit";
import RuleSetLocationMove from "components/mms/MenuLayers/RuleSets/RuleSetLocationMove";
import { RuleSetVisibility } from "components/mms/MenuLayers/RuleSets/RuleSetVisibility";
import React, { ChangeEvent, ReactNode, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { MenuLayerRule } from "store/mms/menus/master/layers/rule-sets/rules/types";
import { MenuLayerRuleSet } from "store/mms/menus/master/layers/rule-sets/types";
import { RootState } from "store/rootReducer";

interface RuleSetContextProps {
    ruleSet: MenuLayerRuleSet | undefined;
    onChange(action: onChangeActions): void;
    onRuleSetChange(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void;
}

export const RuleSetContext = React.createContext<RuleSetContextProps>({
    ruleSet: undefined,
    onChange: () => {
        /* */
    },
    onRuleSetChange: () => {
        /* */
    },
});

interface RuleSetProviderProps {
    children: ReactNode;
}

export function RuleSetProvider({ children }: RuleSetProviderProps): JSX.Element {
    const navigate = useNavigate();
    const location = useLocation();

    const { menuId, layerId, ruleSetId } = useParams() as { menuId: string; layerId: string; ruleSetId: string };

    const menu = useSelector((state: RootState) => state.mms.menus.master.menus.byId[menuId]);
    const layer = useSelector((state: RootState) => state.mms.menus.master.layers.byId[layerId]);

    const isLoading = menu === undefined || layer === undefined;

    const ruleSets = !isLoading ? layer._embedded.rule_sets : [];
    const ruleSet = ruleSets[ruleSets.findIndex((set) => set.id === ruleSetId)];

    const [state, setState] = useState({ ruleSet });
    const [editDialog, setEditDialog] = useState<JSX.Element | undefined>(undefined);
    const [locationsMove, setLocationsMove] = useState<boolean | undefined>(undefined);
    const handleOnCloseDialog = () => setEditDialog(undefined);

    useEffect(() => {
        if (isLoading) {
            return;
        }

        setState((prevState) => ({ ...prevState, ruleSet }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [layer]);

    const handleOnChange = (action: onChangeActions) => {
        switch (action.type) {
            case RuleSetEvent.RuleSetEdit:
                return setEditDialog(<RuleSetEdit onClose={handleOnCloseDialog} layer={layer} ruleSet={ruleSet} />);

            case RuleSetEvent.RuleSetVisibility:
                return setEditDialog(
                    <RuleSetVisibility layerId={layer.id} ruleSet={ruleSet} onClose={handleOnCloseDialog} />,
                );

            case RuleSetEvent.RuleAdd:
                navigate("rules");
                return;

            case RuleSetEvent.RuleEdit:
                console.log("Opened RuleEdit Dialog");
                return;

            case RuleSetEvent.RuleDelete:
                return setEditDialog(<RuleDelete rule={action.rule} onClose={handleOnCloseDialog} />);

            case RuleSetEvent.RuleVisibility:
                return setEditDialog(<RuleVisibility rule={action.rule} onClose={handleOnCloseDialog} />);

            case RuleSetEvent.RuleSchedule:
                return setEditDialog(<RuleActiveTimes rule={action.rule} onClose={handleOnCloseDialog} />);

            case RuleSetEvent.LocationsMove:
                setLocationsMove(true);
                return;
        }
    };
    const handleRuleSetChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const id = e.target.value;
        const index = ruleSets.findIndex((rS) => rS.id === id);
        if (ruleSets[index]) {
            setState((prevState) => ({
                ...prevState,
                ruleSet: ruleSets[index],
            }));
            navigate(location.pathname.replace(/\brule-sets\b\/(?:[^\/]*)(.*)/i, `rule-sets/${id}$1`));
        }
    };

    return (
        <RuleSetContext.Provider
            value={{
                ...state,
                ruleSet,
                onChange: handleOnChange,
                onRuleSetChange: handleRuleSetChange,
            }}
        >
            {children}
            <RuleSetLocationMove onClose={() => setLocationsMove(undefined)} open={Boolean(locationsMove)} />
            {editDialog}
        </RuleSetContext.Provider>
    );
}

export enum RuleSetEvent {
    RuleSetEdit,
    RuleSetVisibility,

    RuleAdd,
    RuleEdit,
    RuleDelete,

    RuleVisibility,
    RuleSchedule,

    LocationsMove,
}

interface ruleSetEdit {
    type: RuleSetEvent.RuleSetEdit;
}
interface ruleSetVisibility {
    type: RuleSetEvent.RuleSetVisibility;
}
interface ruleAdd {
    type: RuleSetEvent.RuleAdd;
}
interface ruleEdit {
    type: RuleSetEvent.RuleEdit;
    rule: MenuLayerRule;
}
interface ruleDelete {
    type: RuleSetEvent.RuleDelete;
    rule: MenuLayerRule;
}

interface ruleVisibility {
    type: RuleSetEvent.RuleVisibility;
    rule: MenuLayerRule;
}
interface ruleSchedule {
    type: RuleSetEvent.RuleSchedule;
    rule: MenuLayerRule;
}

interface locationsMove {
    type: RuleSetEvent.LocationsMove;
}

type onChangeActions =
    | ruleSetEdit
    | ruleSetVisibility
    | ruleAdd
    | ruleEdit
    | ruleDelete
    | ruleVisibility
    | ruleSchedule
    | locationsMove;
