import { Block } from "../../master/periods/types";
import {
    Period,
    PeriodActions,
    PERIOD_PAGE_FETCH,
    PERIOD_PAGE_FETCH_ERROR,
    PERIOD_PAGE_FETCH_SUCCESS,
    PERIOD_BLOCKS_SAVE,
    PERIOD_BLOCKS_SAVE_ERROR,
    PERIOD_BLOCKS_SAVE_SUCCESS,
} from "./types";

export function periodPageFetch(url: string): PeriodActions {
    return {
        type: PERIOD_PAGE_FETCH,
        url,
    };
}

export function periodPageFetchSuccess(menuUrl: string, periods: Period[]): PeriodActions {
    return {
        type: PERIOD_PAGE_FETCH_SUCCESS,
        menuUrl,
        periods,
    };
}

export function periodPageFetchError(error: Error): PeriodActions {
    return {
        type: PERIOD_PAGE_FETCH_ERROR,
        error,
    };
}

export function periodBlocksSave(
    period: Period,
    blocks: Block[],
    url: string,
    callback?: (error?: Error) => void,
): PeriodActions {
    return {
        type: PERIOD_BLOCKS_SAVE,
        period,
        blocks,
        url,
        callback,
    };
}

export function periodBlocksSaveSuccess(period: Period, blocks: Block[], menuUrl: string): PeriodActions {
    return {
        type: PERIOD_BLOCKS_SAVE_SUCCESS,
        menuUrl,
        period,
        blocks,
    };
}

export function periodBlocksSaveError(error: Error): PeriodActions {
    return {
        type: PERIOD_BLOCKS_SAVE_ERROR,
        error,
    };
}
