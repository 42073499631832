import { Card, CardActionArea, CardContent, Checkbox, Grid, Typography, Theme, SxProps, Box } from "@mui/material";
import { CardMedia } from "components/common/CardMedia";
import React from "react";
import { Field } from "react-final-form";
import { MasterItem } from "store/mms/menus/master/items/types";

interface ItemCardProps {
    item: MasterItem;
    name: string;
}

export function ItemCard({ item, name }: ItemCardProps): JSX.Element {
    return (
        <Field
            name={name}
            type="checkbox"
            value={item.id}
            render={({ input: { name, value, onChange, checked, ...restInput } }) => (
                <Grid
                    item
                    sx={(theme) => ({
                        flexBasis: "33%",
                        maxWidth: "33%",
                        flexGrow: 0,

                        [theme.breakpoints.down("md")]: {
                            flexBasis: "50%",
                            maxWidth: "50%",
                        },
                    })}
                >
                    <Card
                        variant="outlined"
                        sx={[
                            {
                                display: "flex",
                                height: cardHeight,
                                justifyContent: "space-between",
                                position: "relative",
                            },
                            checked ? (theme) => ({ borderColor: theme.palette.primary.main }) : {},
                        ]}
                    >
                        <CardActionArea
                            sx={{ display: "flex" }}
                            onClick={() => onChange({ target: { value, checked: !checked, type: "checkbox" } })}
                            data-testid={`${name}[${item.id}]`}
                        >
                            <CardContent
                                sx={{
                                    marginRight: "auto",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    paddingLeft: 0,
                                }}
                            >
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <Checkbox
                                        name={name}
                                        value={value}
                                        checked={checked}
                                        sx={{ alignSelf: "center" }}
                                        inputProps={{ ...restInput }}
                                        disableRipple
                                    />
                                    <Typography sx={textStyle} variant="h6" component="h2" title={item.display_name}>
                                        {item.display_name}
                                    </Typography>
                                </Box>
                                <Typography
                                    sx={{
                                        ...textStyle,
                                        marginLeft: (theme) => theme.spacing(5.5),
                                    }}
                                    variant="caption"
                                    color="textSecondary"
                                    title={`Reference: ${item.reference_name}`}
                                >
                                    {`Reference: ${item.reference_name}`}
                                </Typography>
                            </CardContent>
                            <CardMedia
                                sx={{
                                    height: cardHeight,
                                    width: cardHeight,
                                    minWidth: cardHeight,
                                }}
                                image={item._embedded.image?.url}
                                placeHolder={{ iconSize: "3x", variant: "subtitle2" }}
                            />
                        </CardActionArea>
                    </Card>
                </Grid>
            )}
        />
    );
}

const cardHeight = 100;
const textStyle: SxProps<Theme> = {
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    overflow: "hidden",
    wordBreak: "break-word",
};
