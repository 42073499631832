import * as React from "react";
import { ActionButton } from "components/common/buttons/ActionButton";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ButtonGroup from "@mui/material/ButtonGroup";
import { LocationMenuApplication } from "store/mms/menus/location/applications/types";
import { Button, ClickAwayListener, MenuItem, MenuList, Popover } from "@mui/material";
import { LocationContext, LocationEvent } from "pages/Locations/context";

interface ApplicationActionsProps {
    application: LocationMenuApplication;
    incomingChange: boolean | undefined;
    onChange(): void;
}

export default function ApplicationActionButton({
    application,
    incomingChange,
    onChange,
}: ApplicationActionsProps): JSX.Element {
    const actionAnchorRef = React.useRef(null);
    const [actionOpen, setActionOpen] = React.useState(false);
    const { onChange: contextOnChange } = React.useContext(LocationContext);

    const actualState = application.actual_enabled;
    const stateKnown = actualState !== null;
    const expectedState = incomingChange !== undefined ? incomingChange : application.expected_enabled;
    const pendingChange = stateKnown && actualState !== expectedState;
    const appEnabled = stateKnown && actualState !== false;
    const buttonEnabled = !pendingChange && stateKnown;

    let buttonText = "Enable";
    if (!stateKnown) {
        buttonText = "Unavailable";
    } else if (pendingChange) {
        buttonText = "Updating...";
    } else if (appEnabled) {
        buttonText = "Disable";
    }

    const handleMenuItemClick = () => {
        // There's only one action (schedule change) in the dropdown at the moment. If any new ones are added we
        // will need to properly map indexes and differentiate with a switch.
        contextOnChange({
            type: LocationEvent.ApplicationScheduledEventAdd,
            scheduledEventAddInfo: { application: application },
        });
        setActionOpen(false);
    };

    const handleToggle = () => {
        setActionOpen((prevOpen) => !prevOpen);
    };

    const handleClose = () => {
        setActionOpen(false);
    };

    return (
        <>
            <ButtonGroup size="small" variant="contained" aria-label="Actions">
                <ActionButton
                    fullWidth
                    size="small"
                    disabled={!buttonEnabled}
                    label={buttonText}
                    onClick={() => onChange()}
                />
                <Button
                    disabled={!buttonEnabled}
                    size="small"
                    aria-controls={actionOpen ? "split-button-menu" : undefined}
                    aria-expanded={actionOpen ? "true" : undefined}
                    aria-label="additional actions"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                    ref={actionAnchorRef}
                >
                    <ArrowDropDownIcon />
                </Button>
            </ButtonGroup>
            <Popover
                open={actionOpen}
                anchorEl={actionAnchorRef.current}
                role={undefined}
                disablePortal
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
                <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu">
                        <MenuItem key="Schedule" onClick={handleMenuItemClick}>
                            Schedule
                        </MenuItem>
                    </MenuList>
                </ClickAwayListener>
            </Popover>
        </>
    );
}
