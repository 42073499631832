import { Nutrition, POSConfig } from "store/mms/types";
import { OptionSet } from "../option-sets/types";

export const LOCATION_MODIFIER_FETCH = "@@ADMINLOCATION/MODIFIER/FETCH";
export const LOCATION_MODIFIER_FETCH_SUCCESS = "@@ADMINLOCATION/MODIFIER/FETCH_SUCCESS";
export const LOCATION_MODIFIER_FETCH_ERROR = "@@ADMINLOCATION/MODIFIER/FETCH_ERROR";

export const LOCATION_MODIFIER_SAVE = "@@ADMINLOCATION/MODIFIER/SAVE";
export const LOCATION_MODIFIER_SAVE_SUCCESS = "@@ADMINLOCATION/MODIFIER/SAVE_SUCCESS";
export const LOCATION_MODIFIER_SAVE_ERROR = "@@ADMINLOCATION/MODIFIER/SAVE_ERROR";

export const LOCATION_MODIFIERS_HYDRATE = "@@ADMINLOCATION/MODIFIERS/HYDRATE";

export enum ModifierStrategy {
    AddComboItem = "add_combo_item",
    AddItem = "add_item",
    Modify = "modify",
    ModifyComboItem = "modify_combo_item",
    ReplaceParent = "replace_parent",
}

export interface LocationModifier {
    base_price_per_unit: number;
    display_name: string;
    enabled: boolean;
    id: string;
    linked: boolean;
    master_enabled: boolean;
    nutrition: Nutrition | null;
    pos_config: POSConfig | null;
    price_per_unit: number;
    reference_name: string;
    strategy: ModifierStrategy;
    _embedded: {
        option_sets: OptionSet[];
    };
    _links: {
        self: {
            href: string;
            type: "application/hal+json; name=adminlocation_menu_modifier";
        };
    };
}

export interface LocationModifierReq extends Omit<LocationModifier, "_embedded" | "_links" | "pos_config"> {
    pos_config: string;
}
export interface LocationModifierResp extends Omit<LocationModifier, "pos_config"> {
    pos_config: string;
}

interface LocationModifierFetch {
    type: typeof LOCATION_MODIFIER_FETCH;
    url: string;
}

interface LocationModifierFetchSuccess {
    type: typeof LOCATION_MODIFIER_FETCH_SUCCESS;
    modifier: LocationModifier;
}

interface LocationModifierFetchError {
    type: typeof LOCATION_MODIFIER_FETCH_ERROR;
    error: Error;
}

export interface LocationModifierSave {
    type: typeof LOCATION_MODIFIER_SAVE;
    modifier: LocationModifier;
    url: string;
    callback?: (error?: Error) => void;
}

interface LocationModifierSaveSuccess {
    type: typeof LOCATION_MODIFIER_SAVE_SUCCESS;
    modifier: LocationModifier;
}

interface LocationModifierSaveError {
    type: typeof LOCATION_MODIFIER_SAVE_ERROR;
    error: Error;
}

interface LocationModifiersHydrate {
    type: typeof LOCATION_MODIFIERS_HYDRATE;
    modifiers: LocationModifier[];
}

export type LocationModifierActions =
    | LocationModifierFetch
    | LocationModifierFetchSuccess
    | LocationModifierFetchError
    | LocationModifierSave
    | LocationModifierSaveSuccess
    | LocationModifierSaveError
    | LocationModifiersHydrate;
