import React, { useEffect, useMemo, useState } from "react";
import { FormSpy, FormSpyRenderProps } from "react-final-form";
import diff from "object-diff";
import debounce from "lodash/debounce";

interface AutoSaveProps extends Pick<FormSpyRenderProps, "values" | "valid"> {
    debounce: number;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSave(values: Record<string, any>): void;
}

function AutoSaveComponent({ debounce: debounceTimout, values, valid, onSave }: AutoSaveProps): JSX.Element {
    const [state, setState] = useState(values);
    const save = useMemo(
        () =>
            debounce((debouncedVals, debouncedState, isValid) => {
                const differences = diff(debouncedState, debouncedVals);
                if (Object.keys(differences).length) {
                    setState(debouncedVals);
                    if (isValid) {
                        onSave(debouncedVals);
                    }
                }
            }, debounceTimout),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    useEffect(() => {
        save(values, state, valid);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [save, values, valid]);

    return <></>;
}

export function AutoSave({ debounce, onSave, ...rest }: Omit<AutoSaveProps, "values" | "valid">): JSX.Element {
    return (
        <FormSpy {...rest} subscription={{ values: true, valid: true }}>
            {({ values, valid }) => (
                <AutoSaveComponent values={values} valid={valid} debounce={debounce} onSave={onSave} />
            )}
        </FormSpy>
    );
}
