import React, { useMemo, useState, useEffect, useContext } from "react";
import { Block, isMasterMenuPeriod, NewPeriod, Period as MasterPeriod } from "store/mms/menus/master/periods/types";
import { Period as LocationPeriod } from "store/mms/menus/location/periods/types";
import {
    Button,
    CircularProgress,
    Grid,
    Tab,
    Tabs,
    TextField,
    Theme,
    Typography,
    useTheme,
    Skeleton,
    Alert,
} from "@mui/material";
import { makeStyles } from "theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle, faInfoCircle, faPlusCircle } from "@fortawesome/pro-solid-svg-icons";
import { Field, Form } from "react-final-form";
import {
    getMinutesBetween,
    generateUIBlocks,
    isNewPeriod,
    UIBlock,
    setTime,
    mapUIBlocks,
    validateBlocks,
    newBlock,
    blockFormCalculators,
} from "./helpers";
import { FieldArray } from "react-final-form-arrays";
import { Decorator, Mutator } from "final-form";
import { default as createSubmitListenerDecorator } from "final-form-submit-listener";
import arrayMutators from "final-form-arrays";
import { handleErrors, mapToFormErrors } from "components/common/forms/helpers";
import { OmniDrawer } from "components/common/OmniDrawer";
import MasterMenuPeriodSections from "./MasterMenuPeriodSections";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/rootReducer";
import TabPanel from "components/common/TabPanel";
import { MasterMenuContext } from "pages/Menus/MasterMenu/context";
import { sectionPageFetch } from "store/mms/menus/master/sections/actions";
import { FormError } from "components/common/forms/FormError";
import { MenuPeriodBlock } from "./MenuPeriodBlock";

export const DEFAULT_BLOCK_START_TIME = setTime(10);
export const DEFAULT_BLOCK_END_TIME = setTime(22);
export const DEFAULT_BLOCK_DURATION = getMinutesBetween(DEFAULT_BLOCK_START_TIME, DEFAULT_BLOCK_END_TIME);

enum panel {
    Period = 0,
    Sections = 1,
}

function a11yProps(index: number) {
    return {
        id: `tab-${index}`,
        "aria-controls": `tabpanel-${index}`,
    };
}

const useStyles = makeStyles()((theme: Theme) => ({
    root: {
        padding: theme.spacing(5),
    },
    notification: {
        backgroundColor: theme.palette.info.dark,
        color: theme.palette.info.contrastText,
        marginBottom: theme.spacing(4),
        marginLeft: theme.spacing(-5),
        marginTop: theme.spacing(-5),
        width: `calc(100% + ${theme.spacing(10)})`,
        padding: theme.spacing(1, 2),
    },
    displayName: {
        marginBottom: theme.spacing(4),
    },
    footer: {
        padding: theme.spacing(4, 0),
    },
    form: {
        marginTop: theme.spacing(3),
        "& ul": {
            listStyle: "none",
            padding: 0,
        },

        "& ul > li": {
            borderBottom: `1px solid ${theme.mixins.borderColor}`,
            paddingBottom: theme.spacing(4),
        },

        "& ul > li:first-of-type": {
            paddingTop: 0,
        },
    },
    flexRow: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
    },
    flexItem: {
        margin: "auto",
    },
    addBlock: {
        marginTop: theme.spacing(2),
    },
    cancel: {
        marginLeft: theme.spacing(2),
    },
    submitErrors: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: theme.palette.error.main,
    },
    submitErrorIcon: {
        marginRight: theme.spacing(1),
    },
    submitFeedback: {
        position: "absolute",
        visibility: "visible",
        display: "flex",
        left: "50%",
        transform: "translate(-50%)",
    },
    submitFeedbackLabel: {
        visibility: "hidden",
    },
    tabs: {
        marginBottom: theme.spacing(4),
    },
    blockError: {
        marginTop: theme.spacing(3),
    },
}));

interface EditMenuPeriodProps {
    open: boolean;
    isMaster?: boolean;
    period?: NewPeriod | MasterPeriod | LocationPeriod;
    onClose(): void;
    onSave?(
        period: NewPeriod | MasterPeriod | LocationPeriod,
        blocks: Block[],
        callback?: ((error?: Error) => void) | undefined,
        disabledSections?: string[],
    ): void;
}

interface EditMenuPeriodFormValues {
    blocks: UIBlock[];
    displayName: string;
    enabledSections: string[];
}

export default function EditMenuPeriod({
    isMaster = false,
    open,
    period,
    onClose,
    onSave,
}: EditMenuPeriodProps): JSX.Element {
    const { menu } = useContext(MasterMenuContext);
    const dispatch = useDispatch();
    const uiBlocks = generateUIBlocks(period);
    const { classes } = useStyles();
    const theme = useTheme();
    const submitListener = useMemo(
        () =>
            createSubmitListenerDecorator({
                afterSubmitSucceeded: onClose,
            }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const sectionIdsByMenuId = useSelector((state: RootState) => state.mms.menus.master.sections.sectionIdsByMenuId);
    const sectionsById = useSelector((state: RootState) => state.mms.menus.master.sections.byId);
    const sections = sectionIdsByMenuId[menu?.id || ""]?.map((id) => sectionsById[id]);
    const isLoading = period === undefined || (isMaster && menu === undefined);
    const isNew = !isLoading && isNewPeriod(period);
    const disabledSections =
        isLoading || isNewPeriod(period) || !isMasterMenuPeriod(period)
            ? []
            : period._embedded.disabled_sections.map((ds) => ds.mms_menu_section);

    const [state, setState] = useState<{ tab: panel }>({ tab: panel.Period });

    useEffect(() => {
        if (!isMaster || menu === undefined || period === undefined || sectionIdsByMenuId[menu.id] !== undefined) {
            return;
        }
        dispatch(sectionPageFetch(menu._links.sections.href));
    }, [dispatch, isMaster, menu, period, sectionIdsByMenuId]);

    useEffect(() => {
        if (period === undefined) {
            setState({ tab: panel.Period });
        }
    }, [period]);

    // This nonsense is here because the mutators don't super support Typescript.
    const mutators: { [x: string]: Mutator<EditMenuPeriodFormValues, EditMenuPeriodFormValues> } = {};
    for (const [key, value] of Object.entries(arrayMutators)) {
        mutators[key] = value as Mutator<EditMenuPeriodFormValues, EditMenuPeriodFormValues>;
    }

    return (
        <OmniDrawer title={`${isNew ? "Add" : "Update"} Menu Period `} hideClose close={onClose} open={open}>
            {isLoading ? (
                // TODO: create a better skeleton for this form
                <Skeleton />
            ) : (
                <section className={classes.root}>
                    <HeaderNotification
                        className={classes.notification}
                        periodId={period?.id || ""}
                        isMaster={isMaster}
                    />
                    {!isMaster && (
                        <Typography data-testid="display-name" variant="h4">
                            {period.display_name}
                        </Typography>
                    )}

                    <Form
                        initialValues={{
                            blocks:
                                isNewPeriod(period) || (!isMasterMenuPeriod(period) && uiBlocks.length === 0)
                                    ? [newBlock()]
                                    : uiBlocks,
                            displayName: period.display_name,
                            enabledSections:
                                isMaster && sections
                                    ? sections
                                          .filter((s) => {
                                              if (isNewPeriod(period) || !isMasterMenuPeriod(period)) {
                                                  return s;
                                              }
                                              return !disabledSections.includes(s.id);
                                          })
                                          .map((s) => s.id)
                                    : [],
                        }}
                        decorators={[
                            blockFormCalculators as Decorator<EditMenuPeriodFormValues, EditMenuPeriodFormValues>,
                            submitListener as Decorator<EditMenuPeriodFormValues, EditMenuPeriodFormValues>,
                        ]}
                        mutators={{ ...mutators }}
                        onSubmit={(values, form, errorsCallback) => {
                            const periodToSave = { ...period, display_name: values.displayName };
                            const apiBlocks: Block[] = [];
                            values.blocks.forEach((block: UIBlock) => apiBlocks.push(...mapUIBlocks(block)));
                            const disabledSections =
                                isMaster && sections
                                    ? sections.filter((s) => !values.enabledSections.includes(s.id)).map((s) => s.id)
                                    : undefined;

                            if (onSave) {
                                onSave(
                                    periodToSave,
                                    apiBlocks,
                                    (error?: Error) => {
                                        return errorsCallback && errorsCallback(mapToFormErrors(error));
                                    },
                                    disabledSections,
                                );
                            }
                        }}
                        keepDirtyOnReinitialize
                    >
                        {({ handleSubmit, form, submitting, submitError }) => (
                            <form onSubmit={handleSubmit} className={classes.form} noValidate>
                                {isMaster && (
                                    <div className={classes.tabs}>
                                        <Tabs
                                            className={classes.flexRow}
                                            indicatorColor="secondary"
                                            textColor="primary"
                                            value={state.tab}
                                            onChange={(_, tab: panel) =>
                                                setState(() => ({
                                                    tab,
                                                }))
                                            }
                                        >
                                            <Tab
                                                data-testid="period-tab"
                                                className={classes.flexItem}
                                                value={panel.Period}
                                                label="Define Period"
                                                {...a11yProps(panel.Period)}
                                            />
                                            <Tab
                                                data-testid="disabled-sections-tab"
                                                className={classes.flexItem}
                                                value={panel.Sections}
                                                label="Manage Categories"
                                                {...a11yProps(panel.Sections)}
                                            />
                                        </Tabs>
                                    </div>
                                )}
                                <TabPanel index={panel.Period} value={state.tab}>
                                    {isMaster && (
                                        <>
                                            <Field name="displayName" validate={(v) => !v && "Required"}>
                                                {({ input, meta }) => (
                                                    <TextField
                                                        data-testid="display-name-input"
                                                        {...input}
                                                        {...handleErrors(
                                                            meta,
                                                            "Customers will see this name on the Menu.",
                                                        )}
                                                        fullWidth
                                                        autoFocus={isNew}
                                                        label={`${
                                                            isNew ? "Enter a name for" : "Edit the name of"
                                                        } your Menu Period.`}
                                                        variant="outlined"
                                                        className={classes.displayName}
                                                    />
                                                )}
                                            </Field>
                                        </>
                                    )}
                                    <Typography variant="h6">Time Blocks</Typography>
                                    <Typography variant="subtitle2">
                                        Set when your Menu is available for ordering during a normal week.
                                    </Typography>
                                    <ul>
                                        <FieldArray name="blocks" validate={validateBlocks}>
                                            {({ fields }) => {
                                                if (fields.length === 0) {
                                                    return (
                                                        <Alert
                                                            sx={{ marginTop: (theme) => theme.spacing(2) }}
                                                            severity="warning"
                                                        >
                                                            Menu Sections associated with this Period will{" "}
                                                            <strong>not be available for ordering</strong> unless at
                                                            least one Time Block is defined.
                                                        </Alert>
                                                    );
                                                }
                                                return fields.map((name, index) => (
                                                    <MenuPeriodBlock
                                                        key={name}
                                                        fields={fields}
                                                        name={name}
                                                        index={index}
                                                        isMasterPeriod={isMaster}
                                                    />
                                                ));
                                            }}
                                        </FieldArray>
                                    </ul>

                                    <Button
                                        data-testid="add-time-block"
                                        className={classes.addBlock}
                                        onClick={() => form.mutators.push("blocks", newBlock())}
                                        startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
                                    >
                                        Add Time Block
                                    </Button>

                                    {submitError && (
                                        <div className={classes.submitErrors}>
                                            <FontAwesomeIcon
                                                className={classes.submitErrorIcon}
                                                icon={faExclamationTriangle}
                                                color={theme.palette.error.main}
                                            />
                                            {submitError}
                                        </div>
                                    )}
                                </TabPanel>

                                <TabPanel index={panel.Sections} value={state.tab}>
                                    <MasterMenuPeriodSections sections={sections} />
                                </TabPanel>

                                <FormError className={classes.blockError} name="blocks[0].noBlocks" />
                                <footer className={classes.footer}>
                                    <Button
                                        data-testid="update-period-button"
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        disabled={submitting}
                                        classes={{
                                            text: submitting ? classes.submitFeedbackLabel : "",
                                        }}
                                    >
                                        {submitting && (
                                            <div className={classes.submitFeedback}>
                                                <CircularProgress color="secondary" size={16} />
                                            </div>
                                        )}
                                        {`${isNew ? "Add" : "Update"} Menu Period`}
                                    </Button>
                                    <Button className={classes.cancel} onClick={onClose} disabled={submitting}>
                                        Cancel
                                    </Button>
                                </footer>
                            </form>
                        )}
                    </Form>
                </section>
            )}
        </OmniDrawer>
    );
}

interface HeaderNotificationProps {
    className?: string;
    periodId: string;
    isMaster: boolean;
}

function HeaderNotification({ className, periodId, isMaster }: HeaderNotificationProps): JSX.Element {
    return (
        <Grid container className={className} spacing={1} wrap="nowrap" alignItems="center">
            <Grid item>
                <FontAwesomeIcon size="lg" icon={faInfoCircle} />
            </Grid>

            <Grid item>
                <Typography variant="body1">
                    {periodId === "" ? (
                        <>
                            By default, this Period will be subscribed to all Menu <strong>Categories</strong>.
                        </>
                    ) : isMaster ? (
                        <>
                            Changes made to this Menu Period during business hours <strong>may disrupt ordering</strong>
                            . We recommend making changes after hours if possible.
                        </>
                    ) : (
                        <>
                            Any changes made to the time Blocks below will only impact <strong>this Location</strong>.
                        </>
                    )}
                </Typography>
            </Grid>
        </Grid>
    );
}
