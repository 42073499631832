import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { adminLocationReset } from "store/mms/menus/location/actions";
import { RootState } from "store/rootReducer";

const GOOGLE_UA = "UA-55052606-7";
ReactGA.initialize(GOOGLE_UA);

export function PageTracker(): JSX.Element {
    const location = useLocation();
    const locationMenuIds = useSelector((state: RootState) => state.mms.menus.location.menus.allIds);
    const dispatch = useDispatch();

    useEffect(() => {
        ReactGA.pageview(location.pathname + location.search);
    }, [location]);

    useEffect(() => {
        if (location.pathname.startsWith("/menus") && locationMenuIds.length) {
            dispatch(adminLocationReset());
        }
    }, [dispatch, location, locationMenuIds.length]);
    return <></>;
}
