import { Box, CssBaseline } from "@mui/material";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { CacheProvider } from "@emotion/react";
import { LayerRules } from "./Menus/LayerRules";
import { getSession, login, logout } from "services/session";
import { MasterMenu } from "./Menus/MasterMenu";
import { menuPageFetch } from "store/mms/menus/master/actions";
import { RuleSet } from "./Menus/Layers/RuleSets";
import { SnackbarProvider } from "notistack";
import { ThemeProvider } from "@mui/material/styles";
import { useDispatch, useStore } from "react-redux";
import createCache from "@emotion/cache";
import Dashboard from "./Dashboard";
import getTheme from "theme";
import LocationMenuItem from "./Locations/Menus/Item";
import LocationMenuModifier from "./Locations/Menus/Modifier";
import Notifier from "components/notifications/Notifier";
import { Location } from "./Locations";
import LocationsList from "./Locations/LocationsList";
import MainNav from "components/MainNav";
import MenuItem from "./Menus/Item";
import MenuModifier from "./Menus/Modifier";
import MenusList from "./Menus/MasterMenusList";
import NotFound from "./NotFound";
import OptionSet from "./Menus/OptionSet";
import OrderProfile from "./OrderProfiles/OrderProfile";
import OrderProfileList from "./OrderProfiles/OrderProfileList";
import React, { useEffect, useState } from "react";
import ThemeSwitcher from "components/MainNav/ThemeSwitcher";
import { PageTracker } from "components/PageTracker";

declare global {
    interface Window {
        location: Location;
        onProxyLocationChange?: (pathname: string) => undefined;
        OM_API_BASE_URL: string;
        OM_ACCOUNTS_URL: string;
        OM_ACCOUNTS_SSO_AUTH_COOKIE_CIPHER_KEY: string;
    }
}

const muiCache = createCache({
    key: "mui",
    prepend: true,
});

export default function App(): JSX.Element {
    const store = useStore();
    const dispatch = useDispatch();

    const [theme, setTheme] = useState<string | undefined>(getTheme().palette.mode);

    useEffect(() => {
        // Validate every time the store dispastches an action.
        store.subscribe(validateSession);
    }, [store]);

    const [validSession, setValidSession] = useState(false);

    const validateSession = () => {
        const session = getSession();
        if (!session || !session.accountId) {
            setValidSession(false);
            login();
        } else {
            setValidSession(true);
        }
    };

    useEffect(() => {
        dispatch(menuPageFetch());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!validSession) return <></>;

    return (
        <CacheProvider value={muiCache}>
            <ThemeProvider theme={getTheme(theme)}>
                <CssBaseline />
                <BrowserRouter>
                    <PageTracker />
                    <SnackbarProvider
                        maxSnack={3}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        hideIconVariant
                    >
                        <Notifier />
                        <Box sx={{ display: "flex", flex: "1 auto" }}>
                            <MainNav themeSwitch={<ThemeSwitcher key={"theme-switch"} onThemeChange={setTheme} />} />

                            <Routes>
                                {/* Main Sections */}
                                <Route path="/dashboard" element={<Dashboard />} />
                                <Route path="/order-profiles/:id" element={<OrderProfile />} />
                                <Route path="/order-profiles" element={<OrderProfileList />} />

                                {/* Locations*/}
                                <Route path="/locations" element={<LocationsList />} />
                                <Route path="/locations/:locationId" element={<Location />}>
                                    <Route path="/locations/:locationId/menu-syncs" element={<Location />} />
                                    <Route path="/locations/:locationId/menus/:menuId" element={<Location />} />
                                    <Route path="/locations/:locationId/menus/:menuId/periods" element={<Location />} />
                                    <Route
                                        path="/locations/:locationId/menus/:menuId/ordering"
                                        element={<Location />}
                                    />
                                    <Route path="/locations/:locationId/tax-rates" element={<Location />} />
                                </Route>
                                <Route
                                    path="/locations/:locationId/menus/:menuId/sections/:sectionId/items/:itemId"
                                    element={<LocationMenuItem />}
                                />
                                <Route
                                    path="/locations/:locationId/menus/:menuId/sections/:sectionId/items/:itemId/option-sets/*"
                                    element={<LocationMenuModifier />}
                                />

                                {/* Master sections */}
                                <Route element={<OptionSet />}>
                                    <Route
                                        path="/menus/:menuId/items/:itemId/modifiers/:modifierId/option-sets/:optionSetId"
                                        element={<OptionSet />}
                                    />
                                    <Route
                                        path="/menus/:menuId/items/:itemId/option-sets/:optionSetId"
                                        element={<OptionSet />}
                                    />
                                </Route>
                                <Route
                                    path="/menus/:menuId/items/:itemId/modifiers/:modifierId"
                                    element={<MenuModifier />}
                                />
                                <Route path="/menus/:menuId/items/:itemId" element={<MenuItem />} />
                                <Route
                                    path="/menus/:menuId/layers/:layerId/rule-sets/:ruleSetId/rules"
                                    element={<LayerRules />}
                                />
                                <Route
                                    path="/menus/:menuId/layers/:layerId/rule-sets/:ruleSetId/rules/:ruleId"
                                    element={<LayerRules />}
                                />
                                <Route
                                    path="/menus/:menuId/layers/:layerId/rule-sets/:ruleSetId"
                                    element={<RuleSet />}
                                />
                                <Route path="/menus" element={<MenusList />} />
                                <Route path="/menus/create" element={<MenusList />} />
                                <Route path="/menus/:menuId" element={<MasterMenu />}>
                                    <Route path="/menus/:menuId/menu-syncs" element={<MasterMenu />} />
                                    <Route path="/menus/:menuId/periods" element={<MasterMenu />} />
                                    <Route path="/menus/:menuId/layers" element={<MasterMenu />} />
                                </Route>

                                <Route path="/logout" element={<Logout />} />
                                {/* Deprecated/renamed routes */}
                                <Route path="/menu-profiles/*" element={<Navigate replace to="/order-profiles" />} />
                                {/* Catch-all routes */}
                                <Route path="/" element={<Navigate to="/dashboard" />} />
                                <Route path="*" element={<NotFound />} />
                            </Routes>
                        </Box>
                    </SnackbarProvider>
                </BrowserRouter>
            </ThemeProvider>
        </CacheProvider>
    );
}

function Logout(): JSX.Element {
    logout();
    login();
    return <></>;
}
