import { Location as AccountLocation } from "store/locations/types";

export interface Discount {
    id: string;
    posId: string;
    available: boolean;
    maxAmount: number | null;
    maxPercent: null;
    minAmount: null;
    minPercent: null;
    minTicketTotal: null;
    name: string;
    open: boolean;
    type: string;
    value: number | null;
    kind: "discount";
}

export interface Employee {
    id: string;
    firstName: string;
    middleName: string | null;
    lastName: string;
    payrollId: string | null;
    posId: string;
    kind: "employee";
}

export interface Location extends AccountLocation {
    kind: "location";
}

export interface ItemOrderMode {
    id: string;
    available: boolean;
    name: string;
    posId: string;
    kind: "item_order_mode";
}

export interface OrderType {
    id: string;
    available: boolean;
    name: string;
    posId: string;
    kind: "order_type";
}

export interface RevenueCenter {
    id: string;
    default: boolean;
    name: string;
    posId: string;
    kind: "revenue_center";
}

export interface ServiceCharge {
    id: string;
    posId: string;
    name: string;
    open: boolean;
    price: number | null;
    type: string;
    kind: "service_charge";
}

export interface TenderType {
    id: string;
    allowsTips: boolean;
    name: string;
    posId: string;
    kind: "tender_type";
}

export type AutocompleteTypes =
    | Discount
    | Employee
    | ItemOrderMode
    | Location
    | OrderType
    | RevenueCenter
    | ServiceCharge
    | TenderType;

interface state {
    entity: AutocompleteTypes | null;
    searching: boolean;
    fetching: boolean;
    found: boolean;
    options: AutocompleteTypes[];
    inputValue: string;
    error?: Error;
}

export const getLabel = (entity: AutocompleteTypes): string => {
    switch (entity.kind) {
        case "employee":
            if (!entity.firstName && !entity.lastName && !entity.posId && entity.id) {
                return entity.id;
            }
            return `${entity.firstName}${entity.middleName ? " " + entity.middleName : ""}${
                entity.lastName ? " " + entity.lastName : ""
            }${entity.posId ? " (" + entity.posId + ")" : ""}`;

        case "location":
            return `${entity.display_name} (${entity.id})`;

        default:
            if (!entity.name && !entity.posId && entity.id) {
                return entity.id;
            }
            return `${entity.name}${entity.posId ? " (" + entity.posId + ")" : ""}`;
    }
};
export const initState = (entity: AutocompleteTypes | null): state => ({
    entity,
    searching: false,
    fetching: false,
    found: false,
    options: entity ? [entity] : [],
    inputValue: entity ? getLabel(entity) : "",
});

type action =
    | { type: "SET_ENTITY"; entity: AutocompleteTypes | null }
    | { type: "SET_VALUE"; newValue: string }
    | { type: "SEARCH" }
    | { type: "SEARCH_SUCCESS"; entities: AutocompleteTypes[] }
    | { type: "SEARCH_ERROR"; error: Error }
    | { type: "SEARCH_CLEANUP" }
    | { type: "FETCH" }
    | { type: "FETCH_SUCCESS"; entity: AutocompleteTypes }
    | { type: "FETCH_ERROR"; error: Error };

export function reducer(state: state, action: action): state {
    switch (action.type) {
        case "SET_ENTITY":
            return {
                ...state,
                entity: action.entity,
                options: action.entity
                    ? [action.entity, ...state.options.filter((o) => o.id !== action.entity?.id)]
                    : state.options,
            };

        case "SET_VALUE":
            return {
                ...state,
                inputValue: action.newValue,
            };

        case "SEARCH":
            return {
                ...state,
                searching: true,
            };

        case "SEARCH_SUCCESS":
            return {
                ...state,
                searching: false,
                options: action.entities,
            };

        case "SEARCH_CLEANUP":
            return {
                ...state,
                searching: false,
            };

        case "SEARCH_ERROR":
            return {
                ...state,
                searching: false,
                error: action.error,
            };

        case "FETCH":
            return {
                ...state,
                fetching: true,
            };

        case "FETCH_SUCCESS":
            return {
                ...state,
                fetching: false,
                found: true,
                entity: action.entity,
                options: [action.entity, ...state.options.filter((o) => o.id !== action.entity.id)],
            };

        case "FETCH_ERROR":
            return {
                ...state,
                fetching: false,
                error: action.error,
            };

        default:
            return state;
    }
}
