import { OptionsObject, SnackbarKey, SnackbarMessage } from "notistack";

export const ENQUEUE_SNACKBAR = "ENQUEUE_SNACKBAR";
export const CLOSE_SNACKBAR = "CLOSE_SNACKBAR";
export const REMOVE_SNACKBAR = "REMOVE_SNACKBAR";

export interface Notification {
    key: SnackbarKey;
    message: SnackbarMessage;
    options: OptionsObject;
    dismissed?: boolean;
}

interface EnqueueSnackbar {
    type: typeof ENQUEUE_SNACKBAR;
    notification: Notification;
}

interface CloseSnackbar {
    type: typeof CLOSE_SNACKBAR;
    dismissAll: boolean;
    key?: SnackbarKey;
}

interface RemoveSnackbar {
    type: typeof REMOVE_SNACKBAR;
    key?: SnackbarKey;
}

export type NotificationActions = EnqueueSnackbar | CloseSnackbar | RemoveSnackbar;
