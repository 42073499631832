import { Button, Grid, Theme } from "@mui/material";
import { makeStyles } from "theme";
import clsx from "clsx";
import React, { SyntheticEvent, useEffect, useState } from "react";

const useStyles = makeStyles()((theme: Theme) => ({
    mainContainer: {
        width: "100%",
        height: "auto",
    },
    inputContainer: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
    },
    upload: {
        width: "100%",
        height: "48px",
        display: "inline-block",
        cursor: "pointer",
    },
    fileDrop: {
        cursor: "pointer",
        borderRadius: "4px",
        border: `1px solid ${theme.mixins.borderColor}`,
        minHeight: "42px",
        alignItems: "center",
        justifyContent: "left",
        display: "flex",
        paddingLeft: theme.spacing(1.5),
    },
    button: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
}));

interface FileDropProps {
    fileType: string;
    containerStyle?: string;
    buttonStyle?: string;
    onDrop: (f: File) => void;
}
export default function FileDrop({ fileType, containerStyle, buttonStyle, onDrop }: FileDropProps): JSX.Element {
    const { classes } = useStyles();
    const dropRef = React.createRef<HTMLDivElement>();
    // TODO: Currently support strings. Should support all file types.
    const [state, setState] = useState<{
        file: string | undefined;
        contents: string | ArrayBuffer | null;
        dragging: boolean;
    }>({
        file: "",
        contents: null,
        dragging: false,
    });

    useEffect(() => {
        const div = dropRef.current;
        div?.addEventListener("dragenter", handleDragIn);
        div?.addEventListener("dragleave", handleDragOut);
        div?.addEventListener("dragover", handleDrag);
        div?.addEventListener("drop", handleDrop);

        return () => {
            div?.removeEventListener("dragenter", handleDragIn);
            div?.removeEventListener("dragleave", handleDragOut);
            div?.removeEventListener("dragover", handleDrag);
            div?.removeEventListener("drop", handleDrop);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const muzzle = (e: Event) => {
        e.preventDefault();
        e.stopPropagation();
    };
    const handleDrag = (e: DragEvent) => {
        muzzle(e);
    };
    const handleDragIn = (e: DragEvent) => {
        muzzle(e);
        const data = e.dataTransfer;
        if (data?.items && data?.items.length > 0) {
            setState({ ...state, dragging: true });
        }
    };
    const handleDragOut = (e: DragEvent) => {
        muzzle(e);
        setState({ ...state, dragging: false, file: "" });
    };
    const handleDrop = (e: DragEvent) => {
        muzzle(e);
        const data = e.dataTransfer;
        if (!data) return;
        const file: File = data.files[0];
        handleFiles(file);
    };
    const handleFiles = (f: File) => {
        const fileTypeMismatch = f.name.split(`.${fileType}`)[0] === f.name;
        const badFileType = `The file provided was not a ${fileType} file.`;
        if (!fileTypeMismatch) {
            onDrop(f);
        }
        setState({ ...state, file: fileTypeMismatch ? badFileType : f.name });
    };

    return (
        <Grid direction="row" container className={classes.mainContainer} ref={dropRef}>
            <label className={clsx(containerStyle, classes.inputContainer)} htmlFor="file-upload">
                <Grid className={containerStyle || classes.fileDrop} container>
                    {!state.dragging && !state.file && "Drop a file here, or browse for one."}
                    {!state.dragging && state.file && state.file}
                </Grid>
                <Button
                    className={clsx(buttonStyle, classes.button)}
                    color="primary"
                    variant="contained"
                    component="span"
                >
                    BROWSE
                </Button>
            </label>
            <input
                id="file-upload"
                style={{ display: "none" }}
                type="file"
                accept={`.${fileType}`}
                onChange={(e: SyntheticEvent<HTMLInputElement>) => {
                    const files = e.currentTarget.files;
                    const file = files ? files[0] : undefined;
                    file && handleFiles(file);
                }}
            />
        </Grid>
    );
}
