import React, { CSSProperties, RefObject, useContext } from "react";
import { Box, Chip, IconButton, ListItemIcon, Menu, MenuItem, Tooltip, Typography, useTheme } from "@mui/material";
import {
    Delete as DeleteIcon,
    DragIndicator as DragIndicatorIcon,
    Edit as EditIcon,
    MoreVert as MoreVertIcon,
    Visibility as VisibilityIcon,
    VisibilityOff as VisibilityOffIcon,
} from "@mui/icons-material";
import { TaxRate } from "store/mms/menus/location/taxes/types";
import { DraggableListItemStyles, ListItem } from "components/common/ListItem";
import { UseSortableArguments } from "@dnd-kit/sortable";
import { DraggableSyntheticListeners } from "@dnd-kit/core";
import { LocationContext, LocationEvent } from "pages/Locations/context";

export enum Position {
    Before = -1,
    After = 1,
}

export interface LocationTaxRateItemProps extends Partial<UseSortableArguments> {
    dragActive?: boolean;
    dragOverlay?: boolean;
    index?: number;
    insertPosition?: Position;
    listeners?: DraggableSyntheticListeners;
    style?: CSSProperties;
    taxRate: TaxRate;
}

export const LocationTaxRateItem = React.forwardRef(function LocationTaxRateItem(
    { taxRate, dragOverlay, insertPosition, style, listeners, ...rest }: LocationTaxRateItemProps,
    ref: ((instance: HTMLAnchorElement | null) => void) | RefObject<HTMLAnchorElement> | null,
) {
    const theme = useTheme();
    const { onChange } = useContext(LocationContext);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const handleClose = () => setAnchorEl(null);

    const taxRatePercent = +(taxRate.rate / 100).toFixed(3);
    const isDisabled = !taxRate.enabled;

    return (
        <ListItem
            key={taxRate.id}
            ListItemTextProps={{
                primary: (
                    <>
                        <ListItemIcon
                            sx={(theme) => ({
                                justifyContent: "flex-start",
                                minWidth: "unset",
                                marginRight: theme.spacing(0.2),
                                alignSelf: "flex-start",
                                marginTop: theme.spacing(0.375),
                            })}
                        >
                            <DragIndicatorIcon />
                        </ListItemIcon>
                        <Typography component="span" variant="h5">
                            {taxRate.name}
                        </Typography>
                        {taxRate.default && (
                            <Chip
                                sx={{ marginLeft: (theme) => theme.spacing(2) }}
                                label={"DEFAULT"}
                                size="small"
                                color={"success"}
                            />
                        )}
                    </>
                ),
                secondary: (
                    <>
                        Rate: {taxRatePercent}%
                        {taxRate._embedded.tags.map((tag, i) => (
                            <Chip
                                disabled={isDisabled}
                                size="small"
                                variant="filled"
                                key={tag.id}
                                label={tag.value}
                                sx={{ marginLeft: (theme) => theme.spacing(i === 0 ? 2.5 : 1) }}
                            />
                        ))}
                    </>
                ),
                secondaryTypographyProps: {
                    sx: {
                        paddingTop: (theme) => theme.spacing(1.5),
                    },
                },
                className: isDisabled ? "disabled" : undefined,
                ...listeners,
            }}
            ListItemSecondaryActionProps={{
                sx: {
                    "& button": {
                        marginRight: 0,

                        "&:last-child": {
                            marginRight: 0,
                        },
                    },
                },
            }}
            actions={
                <>
                    <IconButton
                        sx={{ display: { sm: "none", xs: "flex" } }}
                        onClick={(event) => setAnchorEl(event.currentTarget)}
                        size="small"
                    >
                        <MoreVertIcon fontSize="inherit" />
                    </IconButton>
                    <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onBackdropClick={handleClose}>
                        <MenuItem
                            onClick={() => {
                                handleClose();
                                onChange({ type: LocationEvent.TaxRateEdit, taxRate });
                            }}
                        >
                            Update
                        </MenuItem>
                        <MenuItem
                            disabled={taxRate.default}
                            onClick={() => {
                                handleClose();
                                onChange({ type: LocationEvent.TaxRateVisibility, taxRate });
                            }}
                        >
                            {taxRate.enabled ? "Disable" : "Enable"}
                        </MenuItem>
                        <MenuItem
                            disabled={taxRate.default}
                            onClick={() => {
                                handleClose();
                                onChange({ type: LocationEvent.TaxRateDelete, taxRate });
                            }}
                        >
                            Remove Tax Rate
                        </MenuItem>
                    </Menu>

                    <Box sx={{ display: { xs: "none", sm: "flex" } }}>
                        <Tooltip title="Update" arrow>
                            <IconButton
                                size="small"
                                onClick={() => onChange({ type: LocationEvent.TaxRateEdit, taxRate })}
                            >
                                <EditIcon fontSize="inherit" />
                            </IconButton>
                        </Tooltip>

                        <Tooltip
                            title={
                                taxRate.default
                                    ? "Cannot disable default tax rate"
                                    : taxRate.enabled
                                    ? "Disable"
                                    : "Enable"
                            }
                            arrow
                        >
                            <div>
                                <IconButton
                                    size="small"
                                    disabled={taxRate.default}
                                    onClick={() => onChange({ type: LocationEvent.TaxRateVisibility, taxRate })}
                                >
                                    {taxRate.enabled ? (
                                        <VisibilityIcon
                                            fontSize="inherit"
                                            style={
                                                taxRate.default
                                                    ? { color: theme.palette.action.disabled }
                                                    : { color: theme.palette.primary.main }
                                            }
                                        />
                                    ) : (
                                        <VisibilityOffIcon
                                            fontSize="inherit"
                                            style={{ color: theme.palette.text.disabled }}
                                        />
                                    )}
                                </IconButton>
                            </div>
                        </Tooltip>

                        <Tooltip title={taxRate.default ? "Cannot delete default tax rate" : "Remove Tax Rate"} arrow>
                            <div>
                                <IconButton
                                    size="small"
                                    disabled={taxRate.default}
                                    onClick={() => onChange({ type: LocationEvent.TaxRateDelete, taxRate })}
                                >
                                    <DeleteIcon fontSize="inherit" />
                                </IconButton>
                            </div>
                        </Tooltip>
                    </Box>
                </>
            }
            sx={(theme) =>
                DraggableListItemStyles(
                    theme,
                    insertPosition === Position.After,
                    insertPosition === Position.Before,
                    dragOverlay,
                )
            }
            style={style}
            ref={ref}
            {...rest}
        />
    );
});
