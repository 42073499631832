import React from "react";
import { getSession } from "services/session";
import { AxiosResponse } from "axios";
import OmniComplete from "./OmniComplete";
import { Discount } from "./reducer";

interface props {
    discountId?: string;
    locationId: string;
    setDiscount(d: Discount | null): void;
    error?: boolean;
    errorText?: string;
    disableFetch?: boolean;
}

interface discountResponse {
    id: string;
    available: boolean;
    max_amount: number | null;
    max_percent: null;
    min_amount: null;
    min_percent: null;
    min_ticket_total: null;
    name: string;
    open: boolean;
    pos_id: string;
    type: string;
    value: number | null;
}

const mapToDomain = (d: discountResponse): Discount => ({
    id: d.id,
    posId: d.pos_id,
    available: d.available,
    maxAmount: d.max_amount,
    maxPercent: d.max_percent,
    minAmount: d.min_amount,
    minPercent: d.min_percent,
    minTicketTotal: d.min_ticket_total,
    name: d.name,
    open: d.open,
    type: d.type,
    value: d.value,
    kind: "discount",
});

export default function POSDiscountSearch(props: props): JSX.Element {
    const s = getSession();
    const { discountId, disableFetch, locationId, error, errorText = "Required", setDiscount } = props;

    return (
        <OmniComplete
            id="discount-id"
            entity={discountId ? ({ id: discountId, posId: "" } as Discount) : null}
            error={error}
            helperText={error ? errorText : "Not Found on POS"}
            setEntity={setDiscount}
            inputLabel="Discount"
            disabled={!locationId}
            searchUrl={`/1.1/accounts/${s?.accountId}/locations/${locationId}/mms/pos/discounts/?search=`}
            searchRespMapper={(
                resp: AxiosResponse<{
                    _embedded: {
                        discounts: discountResponse[];
                    };
                }>,
            ) => resp.data._embedded.discounts.map(mapToDomain)}
            disableFetch={disableFetch}
            fetchUrl={
                discountId && `/1.1/accounts/${s?.accountId}/locations/${locationId}/mms/pos/discounts/${discountId}`
            }
            fetchRespMapper={(resp: AxiosResponse<discountResponse>) => mapToDomain(resp.data)}
        />
    );
}
