import { OptionSet } from "../option-sets/types";
import {
    MasterModifierActions,
    MASTER_MODIFIER_FETCH,
    MasterModifier,
    MASTER_MODIFIER_FETCH_SUCCESS,
    MASTER_MODIFIER_FETCH_ERROR,
    MASTER_MODIFIER_SAVE,
    MASTER_MODIFIER_SAVE_SUCCESS,
    MASTER_MODIFIER_SAVE_ERROR,
    MASTER_MODIFIERS_HYDRATE,
    MASTER_MODIFIER_OPTION_SETS_SAVE,
    MASTER_MODIFIER_OPTION_SETS_SAVE_ERROR,
    MASTER_MODIFIER_OPTION_SET_DELETE_SUCCESS,
} from "./types";

export function masterModifierFetch(url: string): MasterModifierActions {
    return {
        type: MASTER_MODIFIER_FETCH,
        url,
    };
}

export function masterModifierFetchSuccess(modifier: MasterModifier): MasterModifierActions {
    return {
        type: MASTER_MODIFIER_FETCH_SUCCESS,
        modifier,
    };
}

export function masterModifierFetchError(error: Error): MasterModifierActions {
    return {
        type: MASTER_MODIFIER_FETCH_ERROR,
        error,
    };
}

export function masterModifierSave(
    modifier: MasterModifier,
    url: string,
    callback?: (error?: Error) => void,
    force_location_update?: boolean,
): MasterModifierActions {
    return {
        type: MASTER_MODIFIER_SAVE,
        modifier,
        url,
        callback,
        force_location_update,
    };
}

export function masterModifierSaveSuccess(modifier: MasterModifier): MasterModifierActions {
    return {
        type: MASTER_MODIFIER_SAVE_SUCCESS,
        modifier,
    };
}

export function masterModifierSaveError(error: Error): MasterModifierActions {
    return {
        type: MASTER_MODIFIER_SAVE_ERROR,
        error,
    };
}

export function masterModifierOptionSetsSave(
    modifier: MasterModifier,
    optionSets: OptionSet[],
    callback?: (error?: Error) => void,
): MasterModifierActions {
    return {
        type: MASTER_MODIFIER_OPTION_SETS_SAVE,
        modifier,
        optionSets,
        callback,
    };
}

export function masterModifierOptionSetsSaveError(
    error: Error,
    modifier: MasterModifier,
    optionSets: OptionSet[],
): MasterModifierActions {
    return {
        type: MASTER_MODIFIER_OPTION_SETS_SAVE_ERROR,
        error,
        modifier,
        optionSets,
    };
}

export function masterModifiersHydrate(modifiers: MasterModifier[]): MasterModifierActions {
    return {
        type: MASTER_MODIFIERS_HYDRATE,
        modifiers,
    };
}

export function masterModifierOptionSetDeleteSuccess(
    modifier: MasterModifier,
    optionSet: OptionSet,
): MasterModifierActions {
    return {
        type: MASTER_MODIFIER_OPTION_SET_DELETE_SUCCESS,
        modifier,
        optionSet,
    };
}
