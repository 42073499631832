import { call, ForkEffect, put, takeEvery } from "redux-saga/effects";
import { core } from "services/api";
import { enqueueSnackbar } from "store/notifications/actions";
import { locationMenuApplicationPageFetch } from "../actions";
import {
    locationMenuApplicationSaveSuccess as locationMenuApplicationSaveSuccessAction,
    locationMenuApplicationSaveError as locationMenuApplicationSaveErrorAction,
    locationMenuApplicationChangePageFetchSuccess,
    locationMenuApplicationScheduledEventSaveSuccess as locationMenuApplicationScheduledEventSaveSuccessAction,
    locationMenuApplicationScheduledEventDeleteSuccess as locationMenuApplicationScheduledEventDeleteSuccessAction,
    locationMenuApplicationScheduledEventPageFetchSuccess,
} from "./actions";
import {
    LocationMenuApplication,
    LocationMenuApplicationChange,
    LocationMenuApplicationScheduledEvent,
    LOCATION_MENU_APPLICATION_CHANGE_FETCH,
    LOCATION_MENU_APPLICATION_SAVE,
    LOCATION_MENU_APPLICATION_SCHEDULED_EVENT_FETCH,
    LOCATION_MENU_APPLICATION_SCHEDULED_EVENT_SAVE,
    LOCATION_MENU_APPLICATION_SCHEDULED_EVENT_DELETE,
    NewLocationMenuApplicationScheduledEvent,
} from "./types";

function* locationMenuApplicationSave({
    application,
}: {
    application: LocationMenuApplication;
    type: typeof LOCATION_MENU_APPLICATION_SAVE;
}) {
    try {
        const url = application._links.self.href;
        const resp: { data: LocationMenuApplicationChange } = yield call(core.post, `${url}/events`, {
            enabled: application.expected_enabled,
        });

        yield put(locationMenuApplicationSaveSuccessAction(application, resp.data));
        yield put(locationMenuApplicationPageFetch(application._links.self.href.replace(/[^\/]*$/, "")));
    } catch (e) {
        console.error("Failed to save Location Menu Application", e);
        if (e instanceof Error) {
            yield put(locationMenuApplicationSaveErrorAction(e));
        }

        yield put(
            enqueueSnackbar({
                message: `Failed to update ${application.name} state.`,
                options: {
                    variant: "error",
                },
            }),
        );
    }
}

interface ChangeRequestResp {
    data: {
        count: number;
        limit: number;
        _embedded: {
            events: LocationMenuApplicationChange[];
        };
        _links: {
            next?: {
                href: string;
                type: "application/hal+json; name=adminlocation_menu_application_event_list";
            };
            self: {
                href: string;
                type: "application/hal+json; name=adminlocation_menu_application_event_list";
            };
        };
    };
}

function* locationMenuApplicationChangePageFetch({
    url,
}: {
    url: string;
    type: typeof LOCATION_MENU_APPLICATION_CHANGE_FETCH;
}) {
    try {
        const changes: LocationMenuApplicationChange[] = [];
        let resp: ChangeRequestResp = yield call(core.get, url);
        changes.push(...resp.data._embedded.events);
        while (resp.data._links.next !== undefined) {
            resp = yield call(core.get, resp.data._links.next.href);
            changes.push(...resp.data._embedded.events);
        }
        yield put(locationMenuApplicationChangePageFetchSuccess(changes, url));
    } catch (e) {
        console.error("Failed to retrieve Location Menu Application change log", e);
    }
}

interface ScheduledEventRequestResp {
    data: {
        count: number;
        limit: number;
        _embedded: {
            events: LocationMenuApplicationScheduledEvent[];
        };
        _links: {
            next?: {
                href: string;
                type: "application/hal+json; name=adminlocation_menu_application_scheduled_event_list";
            };
            self: {
                href: string;
                type: "application/hal+json; name=adminlocation_menu_application_scheduled_event_list";
            };
        };
    };
}

function* locationMenuApplicationScheduledEventPageFetch({
    url,
}: {
    url: string;
    type: typeof LOCATION_MENU_APPLICATION_SCHEDULED_EVENT_FETCH;
}) {
    try {
        const scheduledEvents: LocationMenuApplicationScheduledEvent[] = [];
        let resp: ScheduledEventRequestResp = yield call(core.get, url);
        scheduledEvents.push(...resp.data._embedded.events);
        while (resp.data._links.next !== undefined) {
            resp = yield call(core.get, resp.data._links.next.href);
            scheduledEvents.push(...resp.data._embedded.events);
        }
        yield put(locationMenuApplicationScheduledEventPageFetchSuccess(scheduledEvents, url));
    } catch (e) {
        console.error("Failed to retrieve Location Menu Application scheduled event list", e);
    }
}

function* locationMenuApplicationScheduledEventSave({
    application,
    scheduledEvent,
    callback,
}: {
    application: LocationMenuApplication;
    scheduledEvent: LocationMenuApplicationScheduledEvent | NewLocationMenuApplicationScheduledEvent;
    callback?: (e?: Error) => void;
    type: typeof LOCATION_MENU_APPLICATION_SCHEDULED_EVENT_SAVE;
}) {
    try {
        const isNew = !isExistingScheduledEvent(scheduledEvent);
        const applicationUrl = application._links.self.href;
        if (isNew) {
            const resp: { data: LocationMenuApplicationScheduledEvent } = yield call(
                core.post,
                `${applicationUrl}/scheduled_events`,
                scheduledEvent,
            );
            yield put(locationMenuApplicationScheduledEventSaveSuccessAction(application, resp.data));
        } else {
            const resp: { data: LocationMenuApplicationScheduledEvent } = yield call(
                core.put,
                scheduledEvent._links.self.href,
                scheduledEvent,
            );
            yield put(locationMenuApplicationScheduledEventSaveSuccessAction(application, resp.data));
        }

        yield put(locationMenuApplicationPageFetch(application._links.self.href.replace(/[^\/]*$/, "")));
        if (callback) {
            callback();
        }
    } catch (e) {
        console.error("Failed to save Location Menu Application scheduled event", e);
        if (callback && e instanceof Error) {
            callback(e);
        }
    }
}

function* locationMenuApplicationScheduledEventDelete({
    scheduledEvent,
    callback,
}: {
    scheduledEvent: LocationMenuApplicationScheduledEvent;
    callback?: (e?: Error) => void;
    type: typeof LOCATION_MENU_APPLICATION_SCHEDULED_EVENT_DELETE;
}) {
    try {
        const url = scheduledEvent._links.self.href;
        const resp: { data: LocationMenuApplicationScheduledEvent } = yield call(core.delete, `${url}`, {});

        yield put(locationMenuApplicationScheduledEventDeleteSuccessAction(resp.data));
        if (callback) {
            callback();
        }
    } catch (e) {
        console.error("Failed to delete Location Menu Application Scheduled Event", e);
        if (callback && e instanceof Error) {
            callback(e);
        }
    }
}

export function* locationMenuApplicationsSaga(): Generator<ForkEffect<never>, void, unknown> {
    yield takeEvery(LOCATION_MENU_APPLICATION_SAVE, locationMenuApplicationSave);
    yield takeEvery(LOCATION_MENU_APPLICATION_CHANGE_FETCH, locationMenuApplicationChangePageFetch);
    yield takeEvery(LOCATION_MENU_APPLICATION_SCHEDULED_EVENT_SAVE, locationMenuApplicationScheduledEventSave);
    yield takeEvery(LOCATION_MENU_APPLICATION_SCHEDULED_EVENT_FETCH, locationMenuApplicationScheduledEventPageFetch);
    yield takeEvery(LOCATION_MENU_APPLICATION_SCHEDULED_EVENT_DELETE, locationMenuApplicationScheduledEventDelete);
}

function isExistingScheduledEvent(
    scheduledEvent: LocationMenuApplicationScheduledEvent | NewLocationMenuApplicationScheduledEvent,
): scheduledEvent is LocationMenuApplicationScheduledEvent {
    return (scheduledEvent as LocationMenuApplicationScheduledEvent)?.id !== undefined;
}
