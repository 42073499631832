import React, { ReactNode, useContext, useEffect, useMemo, useState } from "react";
import { Tab, Tabs, Typography, Theme, Grid, Skeleton } from "@mui/material";
import { makeStyles } from "theme";
import { Link as RouterLink, useNavigate, useLocation, useParams } from "react-router-dom";
import DefaultLayout from "components/common/layouts/DefaultLayout";
import TabPanel from "components/common/TabPanel";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/rootReducer";
import { getBreadcrumbSkeleton } from "components/skeletons";
import { EntitySelector } from "components/common/EntitySelector";
import { MenuFilter } from "components/mms/MenuFilter";
import LocationMenuSections from "components/mms/MenuSections/LocationMenuSections";
import LocationMenuPeriods from "components/mms/MenuPeriods/LocationMenuPeriods";
import { locationFetch } from "store/locations/actions";
import { locationMenuPageFetch } from "store/mms/menus/location/actions";
import { getSession } from "services/session";
import { MenuPOSSync } from "./Menus/LocationMenuSync";
import { LocationMenuInfo } from "components/common/LocationMenuInfo";
import { LocationContext, LocationEvent, LocationProvider } from "./context";
import { LocationMenuEnableOrdering } from "./Menus/LocationMenuEnableOrdering";
import { LocationTaxRatesList } from "components/mms/Locations/LocationTaxRatesList";
import { LocationMenuApplicationsList } from "./LocationMenuApplicationsList";
import { ZeroState } from "components/mms/ZeroState";
import { LocationMenuSyncLog } from "components/mms/Locations/LocationMenuSyncLog";

export enum locationPane {
    menu = 0,
    periods = 1,
    ordering = 2,
    taxRates = 3,
    menuSyncs = 4,
}

export function Location(): JSX.Element {
    const { classes } = useLocationStyles();

    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const { locationId, menuId } = useParams() as { locationId: string; menuId: string };

    // Locations
    const locationsFetching = useSelector((state: RootState) => state.locations.fetching);
    const locations = useSelector((state: RootState) => state.locations.byId);
    const accountLocation = locations[locationId];

    // Menus
    const menusFetching = useSelector((state: RootState) => state.mms.menus.location.menus.fetching);
    const menusByLocationId = useSelector((state: RootState) => state.mms.menus.location.menus.byLocationId);
    const menusById = useSelector((state: RootState) => state.mms.menus.location.menus.byId);
    const locationMenus = useMemo(
        () => (menusByLocationId[locationId] || []).map((id) => menusById[id]),
        [locationId, menusById, menusByLocationId],
    );
    const [selectedMenuId, setSelectedMenuId] = useState<string>(menuId || locationMenus[0]?.id);
    const selectedMenu = menusById[selectedMenuId || locationMenus[0]?.id];

    const menuManagementTabs = [locationPane.menu, locationPane.periods, locationPane.ordering];

    let tab = locationPane.menu;
    if (location.pathname.includes("periods")) tab = locationPane.periods;
    else if (location.pathname.includes("tax-rates")) tab = locationPane.taxRates;
    else if (location.pathname.includes("ordering")) tab = locationPane.ordering;
    else if (location.pathname.includes("menu-syncs")) tab = locationPane.menuSyncs;

    const [state, setState] = useState({
        tab,
    });

    const accountUrl = `/1.1/accounts/${getSession()?.accountId}`;
    const locationUrl = `${accountUrl}${location.pathname
        .replace("menus", "mms/menus")
        .replace(/(\/locations\/[^\/]*).*/, "$1")}`;
    const menuUrl = `${locationUrl}/mms/menus`;

    useEffect(() => {
        if (
            menusByLocationId[locationId] !== undefined &&
            menuId &&
            !locationMenus.map((lm) => lm.id).includes(menuId)
        ) {
            navigate(`/locations/${locationId}`);
        }
        if (location.pathname === `/locations/${locationId}` && locationMenus.length) {
            navigate(`/locations/${locationId}/menus/${locationMenus[0].id}`, { replace: true });
        }
    }, [location.pathname, locationId, locationMenus, menuId, menusByLocationId, menusFetching, navigate]);

    const [firstFetch, setFirstFetch] = useState(true);
    useEffect(() => {
        if (accountLocation === undefined && !locationsFetching) {
            dispatch(locationFetch(locationUrl));
        }
        if (
            accountLocation !== undefined &&
            !menusFetching &&
            (firstFetch || menusByLocationId[locationId] === undefined)
        ) {
            dispatch(locationMenuPageFetch(menuUrl));
            setFirstFetch(false);
        }
    }, [
        accountLocation,
        dispatch,
        locationsFetching,
        locationUrl,
        menuId,
        menuUrl,
        menusFetching,
        menusByLocationId,
        locationId,
        firstFetch,
    ]);

    useEffect(() => {
        if (!menuId) {
            return;
        }
        if (selectedMenuId !== menuId) {
            setSelectedMenuId(menuId);
        }
    }, [selectedMenuId, menuId]);

    const handleMenuChange = (menu: string): void => {
        setSelectedMenuId(menu);
        navigate(location.pathname.replace(/\bmenus\b\/(?:[^\/]*)(.*)/i, `menus/${menu}$1`));
    };

    const noMenu =
        accountLocation && !locationMenus.length && !menusFetching && menusByLocationId[locationId] !== undefined;

    const isLoading = accountLocation === undefined || locationsFetching;
    const breadcrumbs = isLoading
        ? getBreadcrumbSkeleton(1)
        : [
              <Typography key="location" color="textPrimary">
                  {accountLocation.name}
              </Typography>,
          ];

    return isLoading ? (
        <LoadingSkeleton tab={state.tab} />
    ) : (
        <LocationProvider>
            <DefaultLayout
                breadcrumbs={[
                    <RouterLink key="locations" to="/locations">
                        Locations
                    </RouterLink>,
                    ...breadcrumbs,
                ]}
                header={
                    <>
                        <LocationMenuInfo location={accountLocation} locationMenu={selectedMenu} />
                        <Tabs
                            indicatorColor="secondary"
                            textColor="primary"
                            value={state.tab}
                            onChange={(e, tab: locationPane) => setState((prevState) => ({ ...prevState, tab }))}
                        >
                            <Tab
                                to={`/locations/${locationId}${selectedMenuId ? `/menus/${selectedMenuId}` : ""}`}
                                component={RouterLink}
                                label="Menus"
                                {...a11yProps(locationPane.menu)}
                            />
                            <Tab
                                to={`/locations/${locationId}${
                                    selectedMenuId ? `/menus/${selectedMenuId}/periods` : ""
                                }`}
                                component={RouterLink}
                                label="Hours"
                                {...a11yProps(locationPane.periods)}
                            />
                            <Tab
                                to={`/locations/${locationId}${
                                    selectedMenuId ? `/menus/${selectedMenuId}/ordering` : ""
                                }`}
                                component={RouterLink}
                                label="Ordering"
                                {...a11yProps(locationPane.ordering)}
                            />
                            <Tab
                                to={`/locations/${locationId}/tax-rates`}
                                component={RouterLink}
                                label="Tax Rates"
                                {...a11yProps(locationPane.taxRates)}
                            />
                            ,
                            <Tab
                                to={`/locations/${locationId}/menu-syncs`}
                                component={RouterLink}
                                label="Menu Syncs"
                                {...a11yProps(locationPane.menuSyncs)}
                            />
                        </Tabs>
                    </>
                }
            >
                {menuManagementTabs.includes(state.tab) && selectedMenu && (
                    <Grid direction="row" container alignItems="center" justifyContent="space-between">
                        <Grid item sm={4}>
                            <EntitySelector
                                entities={locationMenus.map((menu) => menu)}
                                selectedId={selectedMenuId || selectedMenu?.id || ""}
                                onChange={handleMenuChange}
                            />
                        </Grid>
                        {state.tab === locationPane.menu && <MenuPOSSync />}
                    </Grid>
                )}
                <TabPanel className={classes.tabPanel} index={locationPane.menu} value={state.tab}>
                    <LocationMenuTab noMenu={noMenu}>
                        <>
                            {selectedMenu !== undefined && !selectedMenu.enabled && (
                                <LocationMenuEnableOrdering menu={selectedMenu} />
                            )}

                            <MenuFilter>
                                <LocationMenuSections />
                            </MenuFilter>
                        </>
                    </LocationMenuTab>
                </TabPanel>
                <TabPanel className={classes.tabPanel} index={locationPane.periods} value={state.tab}>
                    <LocationMenuTab noMenu={noMenu}>
                        <LocationMenuPeriods />
                    </LocationMenuTab>
                </TabPanel>
                <TabPanel
                    className={classes.tabPanel}
                    index={locationPane.ordering}
                    value={state.tab}
                    disableHiddenChildren
                >
                    <LocationMenuTab noMenu={noMenu}>
                        <LocationMenuApplicationsList />
                    </LocationMenuTab>
                </TabPanel>
                <TabPanel className={classes.tabPanel} index={locationPane.taxRates} value={state.tab}>
                    <LocationTaxRatesList />
                </TabPanel>
                <TabPanel
                    className={classes.tabPanel}
                    index={locationPane.menuSyncs}
                    value={state.tab}
                    disableHiddenChildren
                >
                    <LocationMenuSyncLog />
                </TabPanel>
            </DefaultLayout>
        </LocationProvider>
    );
}

interface LocationMenuTabProps {
    noMenu: boolean;
    children: ReactNode;
}
const LocationMenuTab = ({ noMenu, children }: LocationMenuTabProps): JSX.Element => {
    const { onChange, noMasterMenus } = useContext(LocationContext);

    const navigate = useNavigate();
    const title = noMasterMenus
        ? "No Master Menu has been created for this Account."
        : "No Menu has been linked to this Location.";
    const action = () => (noMasterMenus ? navigate("/menus/create") : onChange({ type: LocationEvent.MenuLink }));
    const label = noMasterMenus ? "Create a Menu" : "Link one or more Menus";

    return <>{noMenu ? <ZeroState title={title} onClick={action} createLabel={label} /> : children}</>;
};

function a11yProps(index: number) {
    return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`,
    };
}

const useLocationStyles = makeStyles()((theme: Theme) => ({
    tabPanel: {
        marginTop: theme.spacing(2),
        flex: "1 0 auto",
    },
    tabTitle: {
        marginBottom: theme.spacing(2),
    },
}));

function LoadingSkeleton({ tab }: { tab: locationPane }): JSX.Element {
    return (
        <DefaultLayout
            data-testid="loading"
            breadcrumbs={[
                <RouterLink key="locations" to="/locations">
                    Locations
                </RouterLink>,
                <Skeleton key="location" width={155} />,
            ]}
            header={
                <>
                    <LocationMenuInfo loading={true} />
                    <Tabs indicatorColor="secondary" textColor="primary" value={tab}>
                        <Tab label="Menus" {...a11yProps(locationPane.menu)} />
                        <Tab label="Hours" {...a11yProps(locationPane.periods)} />
                        <Tab label="Ordering" {...a11yProps(locationPane.ordering)} />
                        <Tab label="Tax Rates" {...a11yProps(locationPane.taxRates)} />
                        <Tab label="Menu Syncs" {...a11yProps(locationPane.menuSyncs)} />
                    </Tabs>
                </>
            }
        >
            <Skeleton width="30vw" height={60} variant="text" />
        </DefaultLayout>
    );
}
