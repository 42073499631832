import React, { useState } from "react";
import { MenuItem, TextField, useTheme, ScopedCssBaselineProps, Theme } from "@mui/material";
import { makeStyles } from "theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash, faCircle } from "@fortawesome/pro-solid-svg-icons";

const VISIBLE = "visible";
const INVISIBLE = "invisible";

interface EntityVisibilityProps extends Pick<ScopedCssBaselineProps, "className"> {
    visible: boolean;
    onChange: (visible: boolean) => void;
}

const useStyle = makeStyles()((theme: Theme) => ({
    icon: {
        paddingRight: theme.spacing(1),
    },
}));

export default function EntityVisibility({ className, visible, onChange }: EntityVisibilityProps): JSX.Element {
    const theme = useTheme();
    const { classes } = useStyle();
    const [isVisible, setIsVisible] = useState(visible);
    const valueChange = (value: string) => {
        const isVisible = value === VISIBLE;

        setIsVisible(isVisible);
        onChange(isVisible);
    };

    return (
        <TextField
            label="Availability:"
            className={className}
            id="entity-visibility"
            value={isVisible ? VISIBLE : INVISIBLE}
            onChange={(e) => valueChange(e.target.value as string)}
            fullWidth
            variant="outlined"
            select
        >
            <MenuItem value={VISIBLE}>
                <FontAwesomeIcon
                    className={classes.icon}
                    icon={faCircle}
                    fixedWidth
                    color={theme.palette.success.main}
                />
                Visible on Menu
            </MenuItem>
            <MenuItem value={INVISIBLE}>
                <FontAwesomeIcon className={classes.icon} icon={faEyeSlash} fixedWidth /> Not Visible on Menu
            </MenuItem>
        </TextField>
    );
}
