import { Alert, Typography, useTheme, Theme } from "@mui/material";
import { makeStyles } from "theme";
import { RenameFieldset } from "components/common/forms/RenameFieldset";
import { FormSubmit } from "components/common/FormSubmit";
import { Decorator } from "final-form";
import React, { useMemo } from "react";
import { Form } from "react-final-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import { default as createSubmitListenerDecorator } from "final-form-submit-listener";
import { mapToFormErrors } from "components/common/forms/helpers";

const useStyles = makeStyles()((theme: Theme) => ({
    notification: {
        marginBottom: theme.spacing(4),
    },
    rename: {
        marginTop: theme.spacing(5),
    },
    submitErrors: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: theme.palette.error.main,
    },
    submitErrorIcon: {
        marginRight: theme.spacing(1),
    },
}));

interface OptionSetEditProps {
    onClose(): void;
    onSave?(displayName: string, referenceName: string, callback?: ((error?: Error) => void) | undefined): void;
    displayName: string;
    referenceName: string;
}

interface formValues {
    display_name: string;
    reference_name: string;
}

export function OptionSetEdit({ onClose, onSave, displayName, referenceName }: OptionSetEditProps): JSX.Element {
    const { classes } = useStyles();
    const theme = useTheme();
    const submitListener = useMemo(
        () =>
            createSubmitListenerDecorator({
                afterSubmitSucceeded: onClose,
            }),
        [onClose],
    );

    return (
        <Form
            initialValues={{
                display_name: displayName,
                reference_name: referenceName,
            }}
            onSubmit={(values, form, errorsCallback) => {
                if (onSave !== undefined) {
                    onSave(values.display_name, values.reference_name, (error?: Error) => {
                        if (errorsCallback !== undefined) {
                            errorsCallback(mapToFormErrors(error));
                        }
                    });
                }
            }}
            decorators={[submitListener as Decorator<formValues, formValues>]}
            validate={(values) => {
                const errors: { display_name?: string; reference_name?: string } = {};

                if (!values.display_name.trim()) {
                    errors.display_name = "Required";
                }

                if (!values.reference_name.trim()) {
                    errors.reference_name = "Required";
                }

                return errors;
            }}
        >
            {({ handleSubmit, submitError }) => (
                <form onSubmit={handleSubmit}>
                    <Alert className={classes.notification} severity="warning">
                        Changes to this Modifier Group will be reflected anywhere it is used.
                    </Alert>

                    <Typography variant="h5">Details</Typography>

                    <RenameFieldset className={classes.rename} />

                    {submitError && (
                        <div className={classes.submitErrors}>
                            <FontAwesomeIcon
                                className={classes.submitErrorIcon}
                                icon={faExclamationTriangle}
                                color={theme.palette.error.main}
                            />
                            {submitError}
                        </div>
                    )}

                    <FormSubmit onClose={onClose} label="Update Modifier Group" />
                </form>
            )}
        </Form>
    );
}
