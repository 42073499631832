import React, { CSSProperties } from "react";
import { makeStyles } from "theme";
import { Tooltip, Typography, Theme } from "@mui/material";

const useEntryStyle = makeStyles()((theme: Theme) => ({
    root: {
        display: "flex",
        margin: theme.spacing(0, 0, 1),
    },
    bullet: {
        borderRadius: 100,
        width: "18px",
        height: "18px",
        display: "inline-block",
        marginRight: theme.spacing(1),
    },
}));

export interface LegendEntryProps {
    color: string;
    label: string;
    toolTip: React.ReactFragment;
    value?: number;
}
function Entry({ color, label, toolTip, value }: LegendEntryProps) {
    const { classes } = useEntryStyle();

    return (
        <li className={classes.root} key={label}>
            <Tooltip arrow title={toolTip}>
                <div className={classes.bullet} style={{ backgroundColor: color }}></div>
            </Tooltip>
            <Typography variant="subtitle2">
                {value !== undefined ? `${label}: ${value.toLocaleString()}` : label}
            </Typography>
        </li>
    );
}

const useLegendStyle = makeStyles()(() => ({
    root: {
        padding: "0",
        listStyleType: "none",
    },
}));

export interface LegendProps {
    entries: LegendEntryProps[];
    className?: string;
    style?: CSSProperties;
}
export default function Legend({ entries, className, style }: LegendProps): JSX.Element {
    const { classes } = useLegendStyle();

    return (
        <legend className={className} style={style}>
            <ul className={classes.root}>{entries.map((e: LegendEntryProps) => Entry(e))}</ul>
        </legend>
    );
}
