import { ENTITY_DELETE_SUCCESS } from "../../types";
import {
    Period,
    PeriodActions,
    isMasterMenuPeriod,
    PERIOD_PAGE_FETCH,
    PERIOD_PAGE_FETCH_ERROR,
    PERIOD_PAGE_FETCH_SUCCESS,
    PERIOD_SAVE,
    PERIOD_SAVE_SUCCESS,
    PERIOD_SAVE_ERROR,
    PERIOD_BLOCKS_SAVE,
    PERIOD_BLOCKS_SAVE_SUCCESS,
    PERIOD_BLOCKS_SAVE_ERROR,
} from "./types";

interface PeriodsState {
    readonly byId: {
        [key: string]: Period;
    };
    readonly byMenuId: {
        [key: string]: string[];
    };
    readonly allIds: string[];
    readonly fetching: boolean;
    readonly saving: boolean;
    readonly savingError?: Error;
    readonly fetchingError?: Error;
}

const initialState: PeriodsState = {
    byId: {},
    byMenuId: {},
    allIds: [],
    fetching: false,
    saving: false,
};

export default function periodsReducer(state = initialState, action: PeriodActions): PeriodsState {
    switch (action.type) {
        case PERIOD_PAGE_FETCH:
            return {
                ...state,
                fetching: true,
            };

        case PERIOD_PAGE_FETCH_SUCCESS:
            const newIds = action.periods.map((m) => m.id);

            return {
                ...state,
                byId: {
                    ...state.byId,
                    ...action.periods.reduce((allPeriods, m) => ({ ...allPeriods, [m.id]: m }), {}),
                },
                byMenuId: {
                    ...state.byMenuId,
                    [action.menuId]: [
                        ...(state.byMenuId[action.menuId] || []).filter((id) => !newIds.includes(id)),
                        ...newIds,
                    ],
                },
                allIds: [...state.allIds.filter((id) => !newIds.includes(id)), ...newIds],
                fetching: false,
            };

        case PERIOD_PAGE_FETCH_ERROR:
            return {
                ...state,
                fetching: false,
                fetchingError: action.error,
            };

        case PERIOD_SAVE:
            return {
                ...state,
                saving: true,
            };

        case PERIOD_SAVE_SUCCESS:
            const periodIdsByMenu = [...state.byMenuId[action.menuId]];
            const periodIdsByMenuIndex = periodIdsByMenu.findIndex((id) => id === action.period.id);

            if (periodIdsByMenuIndex < 0) {
                periodIdsByMenu.push(action.period.id);
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.period.id]: action.period,
                },
                allIds: [...state.allIds.filter((id) => id !== action.period.id), action.period.id],
                byMenuId: {
                    ...state.byMenuId,
                    [action.menuId]: periodIdsByMenu,
                },
                saving: false,
            };

        case PERIOD_SAVE_ERROR:
            return {
                ...state,
                saving: false,
                savingError: action.error,
            };

        case PERIOD_BLOCKS_SAVE:
            return {
                ...state,
                saving: true,
            };

        case PERIOD_BLOCKS_SAVE_SUCCESS:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.period.id]: {
                        ...action.period,
                        _embedded: {
                            ...action.period._embedded,
                            blocks: action.blocks,
                        },
                    },
                },
                allIds: [...state.allIds.filter((id) => id !== action.period.id), action.period.id],
                byMenuId: {
                    ...state.byMenuId,
                    [action.menuId]: [
                        ...(state.byMenuId[action.menuId] || []).filter((id) => id !== action.period.id),
                        action.period.id,
                    ],
                },
                saving: false,
            };

        case PERIOD_BLOCKS_SAVE_ERROR:
            return {
                ...state,
                saving: false,
                savingError: action.error,
            };

        case ENTITY_DELETE_SUCCESS:
            if (isMasterMenuPeriod(action.entity)) {
                const menuPeriodId = action.entity.id;
                const menuId = action.entity.mms_menu;

                return {
                    ...state,
                    byId: {
                        ...Object.values(state.byId).reduce((allMenus: { [id: string]: Period }, m) => {
                            if (m.id !== menuPeriodId) {
                                allMenus[m.id] = m;
                            }

                            return allMenus;
                        }, {}),
                    },
                    byMenuId: {
                        ...state.byMenuId,
                        ...{ [menuId]: state.byMenuId[menuId].filter((id) => id !== menuPeriodId) },
                    },
                    allIds: state.allIds.filter((id) => id !== menuPeriodId),
                };
            } else {
                return state;
            }

        default:
            return state;
    }
}
