import { call, ForkEffect, put, takeLatest } from "redux-saga/effects";
import { core } from "services/api";
import { entityNutritionDeleteSuccess, entityNutritionUpdateSuccess } from "./actions";
import {
    EntityWithNutritionDelete,
    EntityWithNutritionUpdate,
    ENTITY_WITH_NUTRITION_DELETE,
    ENTITY_WITH_NUTRITION_UPDATE,
} from "./types";

function* update({ entity, nutrition, onComplete }: EntityWithNutritionUpdate) {
    try {
        yield call(entity._embedded.nutrition ? core.put : core.post, entity._links.nutrition.href, nutrition);
        yield put(entityNutritionUpdateSuccess(entity, nutrition));

        if (onComplete) {
            yield call(onComplete);
        }
    } catch (e) {
        console.error("Failed to update nurition for entity", e);
        if (e instanceof Error && onComplete) {
            yield call(onComplete, e);
        }
    }
}

function* del({ entity, onComplete }: EntityWithNutritionDelete) {
    try {
        yield call(core.delete, entity._links.nutrition.href);
        yield put(entityNutritionDeleteSuccess(entity));

        if (onComplete) {
            yield call(onComplete);
        }
    } catch (e) {
        console.error("Failed to delete nurition for entity", e);

        if (e instanceof Error && onComplete) {
            yield call(onComplete, e);
        }
    }
}

export default function* nutritionSaga(): Generator<ForkEffect<void>, void, unknown> {
    yield takeLatest(ENTITY_WITH_NUTRITION_UPDATE, update);
    yield takeLatest(ENTITY_WITH_NUTRITION_DELETE, del);
}
