import { ListRoute } from "store/types";
import { MasterModifier, MasterModifierResp, NewMasterModifier } from "../../modifiers/types";

export const MODIFIER_GROUP_SAVE = "@@ADMINMASTER/MODIFIER_GROUP/SAVE";
export const MODIFIER_GROUP_SAVE_SUCCESS = "@@ADMINMASTER/MODIFIER_GROUP/SAVE_SUCCESS";
export const MODIFIER_GROUP_SAVE_ERROR = "@@ADMINMASTER/MODIFIER_GROUP/SAVE_ERROR";

export const MODIFIER_GROUP_MODIFERS_SAVE = "@@ADMINMASTER/MODIFIER_GROUP/MODIFIERS/SAVE";
export const MODIFIER_GROUP_MODIFERS_SAVE_ERROR = "@@ADMINMASTER/MODIFIER_GROUP/MODIFIERS/SAVE_ERROR";
export const MODIFIER_GROUP_MODIFERS_ADD_NEW = "@@ADMINMASTER/MODIFIER_GROUP/MODIFIERS/ADD_NEW";
export const MODIFIER_GROUP_MODIFERS_ADD_EXISTING = "@@ADMINMASTER/MODIFIER_GROUP/MODIFIERS/ADD_EXISTING";

export const MODIFIER_GROUPS_HYDRATE = "@@ADMINMASTER/MODIFIER_GROUPS/HYDRATE";

export interface ModifierGroup {
    display_name: string;
    id: string;
    reference_name: string;

    _embedded: {
        modifiers: ModifierGroupModifier[];
    };

    _links: {
        modifiers: {
            href: string;
            type: "application/hal+json; name=mms_master_menu_modifier_group_modifier_list";
        };

        self: {
            href: string;
            type: "application/hal+json; name=mms_master_menu_modifier_group";
        };
    };
}

export type NewModifierGroup = Omit<ModifierGroup, "id" | "_embedded" | "_links">;

export interface ModifierGroupModifier {
    id: string;
    enabled: boolean;

    _embedded: {
        modifier: MasterModifier;
    };
}

export type ModifierGroupReq = Omit<ModifierGroup, "_embedded" | "_links">;

export interface ModifierGroupModifierReq {
    modifiers: Omit<ModifierGroupModifier, "_embedded">[];
}

export interface ModifierGroupModifierResp extends Omit<ModifierGroupModifier, "_embedded"> {
    _embedded: {
        modifier: MasterModifierResp;
    };
}

export interface ModifierGroupResp extends Omit<ModifierGroup, "_embedded"> {
    _embedded: {
        modifiers: ModifierGroupModifierResp[];
    };
}

export interface ModifierGroupPageResp extends ListRoute {
    _embedded: {
        modifier_groups: ModifierGroupResp[];
    };
}

export interface ModifierGroupSave {
    type: typeof MODIFIER_GROUP_SAVE;
    group: ModifierGroup;
    url: string;
    callback?: (error?: Error) => void;
}

interface ModifierGroupSaveSuccess {
    type: typeof MODIFIER_GROUP_SAVE_SUCCESS;
    group: ModifierGroup;
}

interface ModifierGroupSaveError {
    type: typeof MODIFIER_GROUP_SAVE_ERROR;
    error: Error;
}

interface ModifierGroupModifiersSave {
    type: typeof MODIFIER_GROUP_MODIFERS_SAVE;
    group: ModifierGroup;
    modifiers: Omit<ModifierGroupModifier, "_embedded">[];
    callback?: (error?: Error) => void;
}

interface ModifierGroupModifiersSaveError {
    type: typeof MODIFIER_GROUP_MODIFERS_SAVE_ERROR;
    group: ModifierGroup;
}

interface ModifierGroupModifersAddNew {
    type: typeof MODIFIER_GROUP_MODIFERS_ADD_NEW;
    group: ModifierGroup;
    modifier: NewMasterModifier;
    url: string;
    callback?: (error?: Error) => void;
}

interface ModifierGroupModifersAddExisting {
    type: typeof MODIFIER_GROUP_MODIFERS_ADD_EXISTING;
    group: ModifierGroup;
    modifier: MasterModifier;
    callback?: (error?: Error) => void;
}

interface ModifierGroupsHydrate {
    type: typeof MODIFIER_GROUPS_HYDRATE;
    groups: ModifierGroup[];
}

export type ModifierGroupActions =
    | ModifierGroupSave
    | ModifierGroupSaveSuccess
    | ModifierGroupSaveError
    | ModifierGroupModifiersSave
    | ModifierGroupModifiersSaveError
    | ModifierGroupModifersAddNew
    | ModifierGroupModifersAddExisting
    | ModifierGroupsHydrate;
