import { Dialog, Grid, Typography, useTheme, Skeleton } from "@mui/material";
import { DialogContent, DialogTitle } from "components/common/ConfirmDialog";
import DefaultLayout from "components/common/layouts/DefaultLayout";
import { OmniDrawer } from "components/common/OmniDrawer";
import DisplayPOSConfig from "components/mms/DisplayPOSConfig";
import { EntityAddModifierGroup } from "components/mms/EntityAddModifierGroup";
import { ModifierDelete } from "components/mms/Modifiers/ModifierDelete";
import { ModifierEditPOSConfig } from "components/mms/Modifiers/ModifierEditPOSConfig";
import { ModifierVisibility } from "components/mms/Modifiers/ModifierVisibility";
import { ModifierAddExisting } from "components/mms/OptionSets/ModifierAddExisting";
import { ModifierAddNew } from "components/mms/OptionSets/ModifierAddNew";
import { OptionSetDelete } from "components/mms/OptionSets/OptionSetDelete";
import { OptionSetEdit } from "components/mms/OptionSets/OptionSetEdit";
import { MasterOptionSetModifiers } from "components/mms/OptionSets/MasterOptionSets";
import { OptionSetAside } from "components/mms/OptionSets/OptionSetAside";
import { OptionSetDefaultModifier } from "components/mms/OptionSets/OptionSetDefaultModifier";
import { OptionSetVisibility } from "components/mms/OptionSets/OptionSetVisibility";
import debounce from "lodash/debounce";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useParams } from "react-router-dom";
import { apiBaseUrl, saveDebounce } from "services/env";
import { getSession } from "services/session";
import { ModifierStrategy } from "store/mms/menus/location/items/modifiers/types";
import { menuFetch } from "store/mms/menus/master/actions";
import { masterItemFetch } from "store/mms/menus/master/items/actions";
import { MasterModifier } from "store/mms/menus/master/items/modifiers/types";
import { optionSetSave } from "store/mms/menus/master/items/option-sets/actions";
import {
    modifierGroupModifersAddExisting,
    modifierGroupModifersAddNew,
    modifierGroupModifiersSave,
    modifierGroupSave,
} from "store/mms/menus/master/items/option-sets/modifier-group/actions";
import { ModifierGroup, ModifierGroupModifier } from "store/mms/menus/master/items/option-sets/modifier-group/types";
import { OptionSet as MasterOptionSet } from "store/mms/menus/master/items/option-sets/types";
import { buildMasterTraversal, MasterItem } from "store/mms/menus/master/items/types";
import { entityMasterPageLink } from "store/mms/menus/types";
import { RootState } from "store/rootReducer";
import { useSharedEntityPageStyle } from "./helpers";
import { ActionButton, ActionButtonContainer } from "components/common/buttons/ActionButton";
import { EntityRemove } from "components/mms/IconButtons/EntityRemove";
import { EntityEdit } from "components/mms/IconButtons/EntityEdit";
import { Header } from "./Header";

export default function OptionSet(): JSX.Element {
    const theme = useTheme();
    const { classes } = useSharedEntityPageStyle();
    const s = getSession();
    const dispatch = useDispatch();
    const { menuId, itemId, modifierId, optionSetId } = useParams() as {
        menuId: string;
        itemId: string;
        modifierId?: string;
        optionSetId: string;
    };
    const menusById = useSelector((state: RootState) => state.mms.menus.master.menus.byId);
    const menusFetching = useSelector((state: RootState) => state.mms.menus.master.menus.fetching);
    const itemsById = useSelector((state: RootState) => state.mms.menus.master.items.byId);
    const itemsFetching = useSelector((state: RootState) => state.mms.menus.master.items.fetching);
    const modsByHref = useSelector((state: RootState) => state.mms.menus.master.modifiers.byHref);
    const osById = useSelector((state: RootState) => state.mms.menus.master.optionSets.byId);
    const groupsById = useSelector((state: RootState) => state.mms.menus.master.modifierGroups.byId);
    const osFetching = useSelector((state: RootState) => state.mms.menus.master.optionSets.fetching);
    const osRequestCount = useSelector((state: RootState) => state.mms.menus.master.optionSets.pageRequestCount);
    const optionSetIdsByParentId = useSelector((state: RootState) => state.mms.menus.master.optionSets.idsByParentId);
    const optionSetsById = useSelector((state: RootState) => state.mms.menus.master.optionSets.byId);
    const [modifierAddGroup, setModifierAddGroup] = useState<MasterModifier | undefined>(undefined);
    const [editModifier, setEditModifier] = useState<MasterModifier | undefined>(undefined);
    const [deleteModifier, setDeleteModifier] = useState<
        { modifier: MasterModifier; group: ModifierGroup } | undefined
    >(undefined);
    const [modifierVisibility, setModifierVisibility] = useState<MasterModifier | undefined>(undefined);
    const [osVisibility, setOSVisibility] = useState<MasterOptionSet | undefined>(undefined);
    const [renameOS, setRenameOS] = useState(false);
    const [addNewModifier, setAddNewModifier] = useState(false);
    const [addExistingModifier, setAddExistingModifier] = useState(false);
    const [deleteOS, setDeleteOS] = useState<
        { optionSet: MasterOptionSet; parent: MasterItem | MasterModifier; redirectUrl?: string } | undefined
    >(undefined);
    const [osDefaultModifier, setOSDefaultModifier] = useState<
        { optionSet: MasterOptionSet; modifier: MasterModifier; isDefault: boolean } | undefined
    >(undefined);
    const [changingPage, setChangingPage] = useState(false);
    const accountUrl = `/1.1/accounts/${s?.accountId}`;
    const menuUrl = `${accountUrl}/mms/menus/${menuId}`;
    const itemUrl = `${menuUrl}/items/${itemId}`;
    const modifierUrl = `${apiBaseUrl}${menuUrl}/modifiers/${modifierId}`;
    // Option Sets can be under either Items or Modifiers. If we have a Modifier ID in the URL, this is a Modifier
    // Option Set, otherwise it is an Item Option Set.
    const osUrl = `${modifierId === undefined ? itemUrl : modifierUrl}/option_sets/${optionSetId}`;
    const masterMenu = menusById[menuId];
    const item = itemsById[itemId];
    const modifier = modsByHref[modifierUrl];
    const optionSet = osById[optionSetId];
    const groupId = (optionSet !== undefined && optionSet._embedded.modifier_group.id) || "";
    const group = groupsById[groupId];
    const isLoading =
        masterMenu === undefined ||
        menusFetching ||
        item === undefined ||
        itemsFetching ||
        optionSet === undefined ||
        osFetching ||
        osRequestCount > 0 ||
        changingPage;

    const handleAddNewModifier = () => setAddNewModifier(true);
    const handleAddExistingModifier = () => setAddExistingModifier(true);
    const handleRename = () => setRenameOS(true);
    const handleRemoveModifierGroup = () =>
        setDeleteOS({
            optionSet,
            parent: modifier !== undefined ? modifier : item,
            redirectUrl: modifier !== undefined ? entityMasterPageLink(modifier, item.id) : entityMasterPageLink(item),
        });
    const handleModifierAddGroup = (modifier: MasterModifier) => setModifierAddGroup(modifier);
    const handleModifierEdit = (modifier: MasterModifier) => setEditModifier(modifier);
    const handleModifierGroupModifierReorder = useMemo(
        () =>
            debounce(
                (group: ModifierGroup, modifiers: Pick<ModifierGroupModifier, "id" | "enabled">[]) =>
                    dispatch(modifierGroupModifiersSave(group, modifiers)),
                saveDebounce,
            ),
        [dispatch],
    );
    const handleModifierVisibility = (modifier: MasterModifier) => setModifierVisibility(modifier);
    const handleModifierDelete = (group: ModifierGroup, modifier: MasterModifier) =>
        setDeleteModifier({ group, modifier });
    const handleOSVisibility = (os: MasterOptionSet) => setOSVisibility(os);
    const handleOSDelete = (os: MasterOptionSet, modifier: MasterModifier) =>
        setDeleteOS({
            optionSet: os,
            parent: modifier,
        });
    const handleOSDefaultModifier = (optionSet: MasterOptionSet, modifier: MasterModifier, isDefault: boolean) =>
        setOSDefaultModifier({
            optionSet,
            modifier,
            isDefault,
        });

    useEffect(() => {
        if (masterMenu === undefined) {
            dispatch(menuFetch(menuUrl));
        }

        if (item === undefined) {
            dispatch(masterItemFetch(itemUrl));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [osUrl]);

    useEffect(() => {
        setChangingPage(true);
        setTimeout(() => setChangingPage(false), 0);
    }, [osUrl]);

    return isLoading ? (
        <LoadingSkeleton />
    ) : (
        <DefaultLayout
            aside={
                <OptionSetAside
                    group={group}
                    optionSet={optionSet}
                    onChange={(enabled, implicit, required, max, min) =>
                        dispatch(optionSetSave({ ...optionSet, enabled, implicit, required, max, min }))
                    }
                />
            }
            breadcrumbs={[
                <RouterLink key="menus" to="/menus">
                    Menus
                </RouterLink>,
                <RouterLink key="menu" to={`/menus/${masterMenu.id}`}>
                    {masterMenu.name}
                </RouterLink>,
                <RouterLink key="item" to={`/menus/${masterMenu.id}/items/${item.id}`}>
                    {item.display_name}
                </RouterLink>,
                modifier !== undefined ? (
                    <RouterLink key="modifier" to={`/menus/${masterMenu.id}/items/${item.id}/modifiers/${modifier.id}`}>
                        {modifier.display_name}
                    </RouterLink>
                ) : null,
                <Typography key="option-set" color="textPrimary">
                    {group.display_name}
                </Typography>,
            ].filter((v) => v)}
            header={isLoading ? <Skeleton /> : <Header menuName={masterMenu.name} />}
        >
            <Grid container justifyContent="space-between" className={classes.details}>
                <Grid item>
                    <Grid container alignItems="center">
                        <Grid item>
                            <Typography variant="h4">{group.display_name}</Typography>
                        </Grid>
                        <Grid item>
                            <EntityEdit
                                title="Rename Modifier Group"
                                onClick={handleRename}
                                sx={{ marginLeft: (theme) => theme.spacing(2) }}
                            />
                        </Grid>
                        <Grid item>
                            <EntityRemove title="Remove Modifier Group" onClick={handleRemoveModifierGroup} />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item>
                    <ActionButtonContainer>
                        <ActionButton label="Add New Modifier" onClick={handleAddNewModifier} />
                        <ActionButton label="Add Existing Modifier" onClick={handleAddExistingModifier} />
                    </ActionButtonContainer>
                </Grid>
            </Grid>

            <MasterOptionSetModifiers
                itemId={itemId}
                modsByHref={modsByHref}
                onModifierAddGroup={handleModifierAddGroup}
                onModifierDelete={handleModifierDelete}
                onModifierEdit={handleModifierEdit}
                onModifierVisibility={handleModifierVisibility}
                onOptionSetVisibility={handleOSVisibility}
                onOptionSetDelete={handleOSDelete}
                onOptionSetDefaultModifier={handleOSDefaultModifier}
                onReorderModifiers={handleModifierGroupModifierReorder}
                optionSet={optionSet}
                modGroupsById={groupsById}
                optionSetIdsByModifierId={optionSetIdsByParentId}
                optionSetsById={optionSetsById}
            />

            {/* Add New Modifier to Group */}
            <OmniDrawer close={() => setAddNewModifier(false)} open={addNewModifier} title="Add New Modifier">
                {addNewModifier && (
                    <ModifierAddNew
                        onClose={() => setAddNewModifier(false)}
                        onSave={(
                            pos_config,
                            strategy: ModifierStrategy,
                            display_name,
                            reference_name,
                            source_location,
                            callback,
                        ) => {
                            const group = groupsById[optionSet._embedded.modifier_group.id];

                            if (group) {
                                dispatch(
                                    modifierGroupModifersAddNew(
                                        group,
                                        {
                                            display_name,
                                            reference_name,
                                            strategy,
                                            pos_config,
                                            source_location,
                                            enabled: true,
                                            linked: true,
                                        },
                                        `${menuUrl}/modifiers`,
                                        callback,
                                    ),
                                );
                            }
                        }}
                        traversal={buildMasterTraversal(item, modifier)}
                        locationUrl={masterMenu._links.source_location.href}
                    />
                )}
            </OmniDrawer>

            {/* Add Existing Modifier to Group */}
            <Dialog
                open={addExistingModifier}
                onClose={() => setAddExistingModifier(false)}
                maxWidth="xl"
                transitionDuration={0}
            >
                <DialogTitle id="add-existing-modifier-dialog-title" onClose={() => setAddExistingModifier(false)}>
                    Add Existing Modifier
                </DialogTitle>
                <DialogContent>
                    <Typography style={{ marginBottom: theme.spacing(3) }} variant="body1">
                        Search for an existing Modifier on Menu {masterMenu.name}.
                    </Typography>
                    {addExistingModifier && (
                        <ModifierAddExisting
                            menuId={menuId}
                            onClose={() => setAddExistingModifier(false)}
                            onSave={(modifier, callback) => {
                                const group = groupsById[optionSet._embedded.modifier_group.id];

                                if (group) {
                                    dispatch(modifierGroupModifersAddExisting(group, modifier, callback));
                                }
                            }}
                        />
                    )}
                </DialogContent>
            </Dialog>

            {/* Rename Modifier Group */}
            <OmniDrawer close={() => setRenameOS(false)} open={renameOS} title="Rename Modifier Group">
                {renameOS && (
                    <OptionSetEdit
                        displayName={groupsById[optionSet._embedded.modifier_group.id].display_name}
                        referenceName={groupsById[optionSet._embedded.modifier_group.id].reference_name}
                        onClose={() => setRenameOS(false)}
                        onSave={(display_name, reference_name, callback) =>
                            dispatch(
                                modifierGroupSave(
                                    {
                                        ...groupsById[optionSet._embedded.modifier_group.id],
                                        display_name,
                                        reference_name,
                                    },
                                    optionSet._embedded.modifier_group._links.self.href,
                                    callback,
                                ),
                            )
                        }
                    />
                )}
            </OmniDrawer>

            {/* Option Set Default Modifier */}
            {osDefaultModifier && (
                <OptionSetDefaultModifier
                    onClose={() => setOSDefaultModifier(undefined)}
                    open={Boolean(osDefaultModifier)}
                    {...osDefaultModifier}
                />
            )}

            {/* Delete Option Set */}
            {deleteOS && (
                <OptionSetDelete onClose={() => setDeleteOS(undefined)} open={Boolean(deleteOS)} {...deleteOS} />
            )}

            {/* Modifier Add Modifer Group */}
            <EntityAddModifierGroup
                entity={modifierAddGroup}
                itemId={itemId}
                menuId={menuId}
                onClose={() => setModifierAddGroup(undefined)}
                open={Boolean(modifierAddGroup)}
            />

            {/* Edit Modifier POS Settings/Relink */}
            <ModifierEditPOSConfig
                item={item}
                modifier={editModifier}
                onClose={() => setEditModifier(undefined)}
                open={Boolean(editModifier)}
            />

            {/* Edit Modifier Visibility */}
            {modifierVisibility && (
                <ModifierVisibility
                    modifier={modifierVisibility}
                    onClose={() => setModifierVisibility(undefined)}
                    open={Boolean(modifierVisibility)}
                />
            )}

            {/* Delete Modifier from Group */}
            {deleteModifier && (
                <ModifierDelete
                    onClose={() => setDeleteModifier(undefined)}
                    open={Boolean(deleteModifier)}
                    {...deleteModifier}
                />
            )}

            {/* Edit Option Set Visibility (under a given modifier) */}
            {osVisibility && (
                <OptionSetVisibility
                    menuName={masterMenu.name}
                    onClose={() => setOSVisibility(undefined)}
                    open={Boolean(osVisibility)}
                    optionSet={osVisibility}
                />
            )}
        </DefaultLayout>
    );
}

function LoadingSkeleton(): JSX.Element {
    return (
        <DefaultLayout
            aside={<DisplayPOSConfig loading />}
            breadcrumbs={[
                <Skeleton key="1" width={100} />,
                <Skeleton key="2" width={155} />,
                <Skeleton key="3" width={100} />,
            ]}
        >
            <Skeleton width={250} height={40} variant="text" />
        </DefaultLayout>
    );
}
