import React from "react";
import { IconButton, Tooltip, useTheme } from "@mui/material";
import { Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from "@mui/icons-material";

interface ToggleDisplayProps {
    hidden: boolean;
    onClick(): void;
}

export function ToggleDisplay({ hidden, onClick }: ToggleDisplayProps): JSX.Element {
    const theme = useTheme();

    return (
        <Tooltip title={!hidden ? "Hide" : "Display"} arrow>
            <IconButton onClick={onClick} size="small">
                {hidden ? (
                    <VisibilityOffIcon fontSize="inherit" style={{ color: theme.palette.text.disabled }} />
                ) : (
                    <VisibilityIcon fontSize="inherit" style={{ color: theme.palette.primary.main }} />
                )}
            </IconButton>
        </Tooltip>
    );
}
