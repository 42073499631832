import { Typography, Alert } from "@mui/material";
import ConfirmDialog from "components/common/ConfirmDialog";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { axiosErrorMessage } from "store/types";
import { entityDelete } from "store/mms/menus/actions";
import { MenuLayer } from "store/mms/menus/master/layers/types";

interface MenuLayerDeleteProps {
    layer: MenuLayer;
    onClose(): void;
    open: boolean;
}

export function MenuLayerDelete({ layer, onClose, open }: MenuLayerDeleteProps): JSX.Element {
    const dispatch = useDispatch();
    const [state, setState] = useState<{ saving: boolean; error?: string }>({
        saving: false,
    });
    const name = "Layer";

    return (
        <ConfirmDialog
            title={`Remove ${name}`}
            open={open}
            onClose={onClose}
            onConfirm={() => {
                setState({ saving: true });
                dispatch(
                    entityDelete(layer, name, (error?: Error): void => {
                        if (error) {
                            return setState({ saving: false, error: axiosErrorMessage(error) });
                        }
                        setState({ saving: false });
                        onClose();
                    }),
                );
            }}
            confirm={`Remove ${name}`}
            confirming={state.saving}
            maxWidth="xl"
            transitionDuration={0}
        >
            <Typography>
                Are you sure you want to delete the Layer <strong>{layer.name}</strong>?
            </Typography>

            <Alert variant="outlined" severity="info">
                All Rules for this Layer will also be deleted. This cannot be undone!
            </Alert>

            {state.error && (
                <Alert variant="outlined" severity="error">
                    {state.error}
                </Alert>
            )}
        </ConfirmDialog>
    );
}
