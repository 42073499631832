import { getSession } from "services/session";
import { core } from "services/api";
import {
    reportsFetchSuccess,
    reportsFetchError,
    reportsFiltersFetchSuccess,
    reportsFiltersFetchError,
    reportsFiltersSetAvailable,
    reportsFetch as fetchReportsAction,
} from "./actions";
import {
    REPORTS_FETCH,
    REPORTS_FILTERS_FETCH,
    REPORTS_FILTERS_SET_START_DATETIME,
    REPORTS_FILTERS_SET_END_DATETIME,
    REPORTS_FILTERS_ADD_RESTAURANT_CONCEPT,
    REPORTS_FILTERS_REMOVE_RESTAURANT_CONCEPT,
    REPORTS_FILTERS_ADD_POS_TYPE,
    REPORTS_FILTERS_REMOVE_POS_TYPE,
    REPORTS_FILTERS_ADD_LOCATION,
    REPORTS_FILTERS_REMOVE_LOCATION,
    POSInjectionStatsResp,
    ReportFiltersResp,
} from "./types";
import { call, put, takeLatest, ForkEffect, select, debounce } from "redux-saga/effects";
import { RootState } from "store/rootReducer";
import { ReportsFiltersState } from "store/reports/types";

const getFilters = (state: RootState) => state.reportFilters;

function* fetchReports() {
    try {
        const filters: ReportsFiltersState = yield select(getFilters);
        const s = getSession();
        const posInjectionStats: { data: POSInjectionStatsResp } = yield call(
            core.post,
            `/1.1/accounts/${s?.accountId}/mms/reports/pos_injection`,
            {
                start: filters.start,
                end: filters.end,
                locations: filters.applied.Locations.map((location) => location.id),
                restaurant_concepts: filters.applied.RestaurantConcepts.map((concept) => concept.id),
                pos_types: filters.applied.POSTypes.map((type) => type.id),
            },
        );

        yield put(
            reportsFetchSuccess({
                success: posInjectionStats.data.success,
                failure: posInjectionStats.data.failure,
                total: posInjectionStats.data.total,
            }),
        );
    } catch (err) {
        console.error(err);
        if (err instanceof Error) {
            yield put(reportsFetchError(err));
        }
    }
}

function* setAppliedFilters() {
    yield put(fetchReportsAction());
}

function* fetchReportFilters() {
    try {
        const s = getSession();
        const reportFilters: { data: ReportFiltersResp } = yield call(
            core.get,
            `/1.1/accounts/${s?.accountId}/mms/reports/filters`,
        );

        yield put(reportsFiltersFetchSuccess());
        yield put(
            reportsFiltersSetAvailable({
                RestaurantConcepts: reportFilters.data._embedded.restaurant_concepts.map((lc) => ({
                    id: lc.id,
                    name: lc.name,
                })),
                POSTypes: reportFilters.data._embedded.pos_types.map((posType) => ({
                    id: posType.id,
                    name: posType.name,
                })),
            }),
        );
    } catch (err) {
        console.error(err);
        if (err instanceof Error) {
            yield put(reportsFiltersFetchError(err));
        }
    }
}

export function* reportsSaga(): Generator<ForkEffect<never>, void, unknown> {
    yield debounce(500, REPORTS_FETCH, fetchReports);

    yield takeLatest(REPORTS_FILTERS_SET_START_DATETIME, setAppliedFilters);
    yield takeLatest(REPORTS_FILTERS_SET_END_DATETIME, setAppliedFilters);
    yield takeLatest(REPORTS_FILTERS_ADD_RESTAURANT_CONCEPT, setAppliedFilters);
    yield takeLatest(REPORTS_FILTERS_REMOVE_RESTAURANT_CONCEPT, setAppliedFilters);
    yield takeLatest(REPORTS_FILTERS_ADD_LOCATION, setAppliedFilters);
    yield takeLatest(REPORTS_FILTERS_REMOVE_LOCATION, setAppliedFilters);
    yield takeLatest(REPORTS_FILTERS_ADD_POS_TYPE, setAppliedFilters);
    yield takeLatest(REPORTS_FILTERS_REMOVE_POS_TYPE, setAppliedFilters);

    yield takeLatest(REPORTS_FILTERS_FETCH, fetchReportFilters);
}
