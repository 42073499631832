import React from "react";
import Header from "components/dashboard/Header";
import Visualizations from "components/dashboard/Visualizations";
import { Theme } from "@mui/material";
import { makeStyles } from "theme";

const useStyle = makeStyles()((theme: Theme) => ({
    root: {
        padding: theme.spacing(6),
    },
}));

export default function Dashboard(): JSX.Element {
    const { classes } = useStyle();

    return (
        <section className={classes.root}>
            <Header />
            <Visualizations />
        </section>
    );
}
