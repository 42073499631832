import { LocationMenuApplication } from "./applications/types";
import {
    LocationMenuActions,
    LOCATION_MENU_PAGE_FETCH,
    LocationMenu,
    LOCATION_MENU_PAGE_FETCH_SUCCESS,
    LOCATION_MENU_PAGE_FETCH_ERROR,
    LOCATION_MENU_ONBOARD,
    LOCATION_MENU_SAVE_SUCCESS,
    LOCATION_MENU_SAVE,
    LOCATION_MENU_SAVE_ERROR,
    LOCATION_BY_MENU_EXPORT_FETCH,
    LOCATION_MENU_APPLICATION_PAGE_FETCH,
    LOCATION_MENU_APPLICATION_PAGE_FETCH_ERROR,
    LOCATION_MENU_APPLICATION_PAGE_FETCH_SUCCESS,
    LocationMenuOnboardReq,
    ADMINLOCATION_RESET,
    AdminlocationReset,
} from "./types";

export function locationMenuPageFetch(url: string): LocationMenuActions {
    return {
        type: LOCATION_MENU_PAGE_FETCH,
        url,
    };
}

export function locationByMenuExportFetch(url: string, callback?: (error?: Error) => void): LocationMenuActions {
    return {
        type: LOCATION_BY_MENU_EXPORT_FETCH,
        url,
        callback,
    };
}

export function locationMenuPageFetchSuccess(locationId: string, menus: LocationMenu[]): LocationMenuActions {
    return {
        type: LOCATION_MENU_PAGE_FETCH_SUCCESS,
        locationId,
        menus,
    };
}

export function locationMenuPageFetchError(error: Error): LocationMenuActions {
    return {
        type: LOCATION_MENU_PAGE_FETCH_ERROR,
        error,
    };
}

export function locationMenuSave(
    menu: LocationMenu,
    url: string,
    callback?: (error?: Error) => void,
): LocationMenuActions {
    return {
        type: LOCATION_MENU_SAVE,
        menu,
        url,
        callback,
    };
}

export function locationMenuSaveSuccess(menu: LocationMenu, locationId: string): LocationMenuActions {
    return {
        type: LOCATION_MENU_SAVE_SUCCESS,
        menu,
        locationId,
    };
}

export function locationMenuSaveError(error: Error): LocationMenuActions {
    return {
        type: LOCATION_MENU_SAVE_ERROR,
        error,
    };
}

export function locationMenuOnboard(
    url: string,
    locationMenus: LocationMenuOnboardReq[],
    callback?: (error?: Error) => void,
): LocationMenuActions {
    return {
        type: LOCATION_MENU_ONBOARD,
        url,
        locationMenus,
        callback,
    };
}

export function locationMenuApplicationPageFetch(url: string): LocationMenuActions {
    return {
        type: LOCATION_MENU_APPLICATION_PAGE_FETCH,
        url,
    };
}

export function locationMenuApplicationPageFetchSuccess(
    menuId: string,
    applications: LocationMenuApplication[],
): LocationMenuActions {
    return {
        type: LOCATION_MENU_APPLICATION_PAGE_FETCH_SUCCESS,
        menuId,
        applications,
    };
}

export function locationMenuApplicationPageFetchError(error: Error): LocationMenuActions {
    return {
        type: LOCATION_MENU_APPLICATION_PAGE_FETCH_ERROR,
        error,
    };
}

export function adminLocationReset(): AdminlocationReset {
    return {
        type: ADMINLOCATION_RESET,
    };
}
