import {
    LocationMenuApplication,
    LocationMenuApplicationActions,
    LocationMenuApplicationChange,
    LOCATION_MENU_APPLICATION_CHANGE_FETCH,
    LOCATION_MENU_APPLICATION_CHANGE_FETCH_SUCCESS,
    LOCATION_MENU_APPLICATION_SAVE,
    LOCATION_MENU_APPLICATION_SAVE_ERROR,
    LOCATION_MENU_APPLICATION_SAVE_SUCCESS,
    LocationMenuApplicationScheduledEvent,
    LOCATION_MENU_APPLICATION_SCHEDULED_EVENT_FETCH,
    LOCATION_MENU_APPLICATION_SCHEDULED_EVENT_FETCH_SUCCESS,
    LOCATION_MENU_APPLICATION_SCHEDULED_EVENT_SAVE,
    LOCATION_MENU_APPLICATION_SCHEDULED_EVENT_SAVE_ERROR,
    LOCATION_MENU_APPLICATION_SCHEDULED_EVENT_SAVE_SUCCESS,
    LOCATION_MENU_APPLICATION_SCHEDULED_EVENT_DELETE,
    LOCATION_MENU_APPLICATION_SCHEDULED_EVENT_DELETE_ERROR,
    LOCATION_MENU_APPLICATION_SCHEDULED_EVENT_DELETE_SUCCESS,
    NewLocationMenuApplicationScheduledEvent,
} from "./types";

export function locationMenuApplicationSave(application: LocationMenuApplication): LocationMenuApplicationActions {
    return {
        type: LOCATION_MENU_APPLICATION_SAVE,
        application,
    };
}

export function locationMenuApplicationSaveSuccess(
    application: LocationMenuApplication,
    changeEvent: LocationMenuApplicationChange,
): LocationMenuApplicationActions {
    return {
        type: LOCATION_MENU_APPLICATION_SAVE_SUCCESS,
        application,
        changeEvent,
    };
}

export function locationMenuApplicationSaveError(error: Error): LocationMenuApplicationActions {
    return {
        type: LOCATION_MENU_APPLICATION_SAVE_ERROR,
        error,
    };
}

export function locationMenuApplicationChangePageFetch(url: string): LocationMenuApplicationActions {
    return {
        type: LOCATION_MENU_APPLICATION_CHANGE_FETCH,
        url,
    };
}

export function locationMenuApplicationChangePageFetchSuccess(
    changeEvents: LocationMenuApplicationChange[],
    href: string,
) {
    return {
        type: LOCATION_MENU_APPLICATION_CHANGE_FETCH_SUCCESS,
        changeEvents,
        href,
    };
}

export function locationMenuApplicationScheduledEventSave(
    application: LocationMenuApplication,
    scheduledEvent: LocationMenuApplicationScheduledEvent | NewLocationMenuApplicationScheduledEvent,
    callback?: (e?: Error) => void,
): LocationMenuApplicationActions {
    return {
        type: LOCATION_MENU_APPLICATION_SCHEDULED_EVENT_SAVE,
        application,
        scheduledEvent,
        callback,
    };
}

export function locationMenuApplicationScheduledEventSaveSuccess(
    application: LocationMenuApplication,
    scheduledEvent: LocationMenuApplicationScheduledEvent,
): LocationMenuApplicationActions {
    return {
        type: LOCATION_MENU_APPLICATION_SCHEDULED_EVENT_SAVE_SUCCESS,
        application,
        scheduledEvent,
    };
}

export function locationMenuApplicationScheduledEventSaveError(error: Error): LocationMenuApplicationActions {
    return {
        type: LOCATION_MENU_APPLICATION_SCHEDULED_EVENT_SAVE_ERROR,
        error,
    };
}

export function locationMenuApplicationScheduledEventDelete(
    scheduledEvent: LocationMenuApplicationScheduledEvent,
    callback?: (e?: Error) => void,
): LocationMenuApplicationActions {
    return {
        type: LOCATION_MENU_APPLICATION_SCHEDULED_EVENT_DELETE,
        scheduledEvent,
        callback,
    };
}

export function locationMenuApplicationScheduledEventDeleteSuccess(
    scheduledEvent: LocationMenuApplicationScheduledEvent,
): LocationMenuApplicationActions {
    return {
        type: LOCATION_MENU_APPLICATION_SCHEDULED_EVENT_DELETE_SUCCESS,
        scheduledEvent,
    };
}

export function locationMenuApplicationScheduledEventDeleteError(error: Error): LocationMenuApplicationActions {
    return {
        type: LOCATION_MENU_APPLICATION_SCHEDULED_EVENT_DELETE_ERROR,
        error,
    };
}

export function locationMenuApplicationScheduledEventPageFetch(url: string): LocationMenuApplicationActions {
    return {
        type: LOCATION_MENU_APPLICATION_SCHEDULED_EVENT_FETCH,
        url,
    };
}

export function locationMenuApplicationScheduledEventPageFetchSuccess(
    scheduledEvents: LocationMenuApplicationScheduledEvent[],
    href: string,
) {
    return {
        type: LOCATION_MENU_APPLICATION_SCHEDULED_EVENT_FETCH_SUCCESS,
        scheduledEvents,
        href,
    };
}
