import React from "react";
import { faFilePlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Card, CardActionArea, CardMedia, Grid, Theme, Link, Typography, Box, Skeleton } from "@mui/material";
import { makeStyles } from "theme";
import { EntityDetails } from "components/common/forms/EntityDetails";
import DefaultLayout from "components/common/layouts/DefaultLayout";
import { OmniDrawer } from "components/common/OmniDrawer";
import EditPOSConfig from "components/mms/EditPOSConfig";
import { EntityAddModifierGroup } from "components/mms/EntityAddModifierGroup";
import { ItemImageDelete } from "components/mms/Items/ItemImageDelete";
import { ModifierDelete } from "components/mms/Modifiers/ModifierDelete";
import { ModifierEditPOSConfig } from "components/mms/Modifiers/ModifierEditPOSConfig";
import { ModifierVisibility } from "components/mms/Modifiers/ModifierVisibility";
import NutritionFactsDelete from "components/mms/NutritionFacts/NutritionFactsDelete";
import NutritionFactsDisplay from "components/mms/NutritionFacts/NutritionFactsDisplay";
import NutritionFactsEdit from "components/mms/NutritionFacts/NutritionFactsEdit";
import { OptionSetDelete } from "components/mms/OptionSets/OptionSetDelete";
import { MasterOptionSetList } from "components/mms/OptionSets/MasterOptionSets";
import { OptionSetDefaultModifier } from "components/mms/OptionSets/OptionSetDefaultModifier";
import { OptionSetVisibility } from "components/mms/OptionSets/OptionSetVisibility";
import Tags from "components/mms/Tags/Tags";
import { Sidebar, SidebarContent, SidebarHeader, SidebarSection } from "components/Sidebar";
import { getBreadcrumbSkeleton, MainContentSkeleton } from "components/skeletons";
import { PickerOverlay } from "filestack-react";
import useToggle from "helpers/useToggle";
import { debounce } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useParams } from "react-router-dom";
import { saveDebounce } from "services/env";
import { getSession } from "services/session";
import { ItemStrategy } from "store/mms/menus/location/items/types";
import { menuFetch } from "store/mms/menus/master/actions";
import {
    masterItemFetch,
    masterItemImageSave,
    masterItemOptionSetsSave,
    masterItemSave,
} from "store/mms/menus/master/items/actions";
import { MasterModifier } from "store/mms/menus/master/items/modifiers/types";
import { ModifierGroup } from "store/mms/menus/master/items/option-sets/modifier-group/types";
import { OptionSet } from "store/mms/menus/master/items/option-sets/types";
import { buildMasterTraversal, MasterItem } from "store/mms/menus/master/items/types";
import { POSConfig } from "store/mms/types";
import { RootState } from "store/rootReducer";
import { DetailsSidebarSection, useSharedEntityPageStyle } from "./helpers";
import { ActionButton } from "components/common/buttons/ActionButton";
import { EntityRemove } from "components/mms/IconButtons/EntityRemove";
import { EntityEdit } from "components/mms/IconButtons/EntityEdit";
import { Header } from "./Header";

const useMenuItemStyle = makeStyles()((theme: Theme) => ({
    container: {
        position: "relative",
    },
    addGroup: {
        position: "absolute",
        right: 0,
        top: 15,
        zIndex: 2,
    },
    optionSetList: {
        "& > .MuiListSubheader-root": {
            paddingTop: theme.spacing(2),

            "& > .MuiTypography-root": {
                marginBottom: theme.spacing(2),
            },
        },
    },
    modifiers: {
        marginTop: theme.spacing(2),
    },
    items: {
        marginTop: theme.spacing(2),
    },
    entityVisibility: {
        marginBottom: theme.spacing(3),
    },
    imageActions: {
        justifyContent: "space-evenly",
    },
}));

export default function MenuItem(): JSX.Element {
    const { classes } = useMenuItemStyle();
    const { classes: masterClasses } = useSharedEntityPageStyle();
    const dispatch = useDispatch();
    const { menuId, itemId } = useParams() as { menuId: string; itemId: string };
    const menusById = useSelector((state: RootState) => state.mms.menus.master.menus.byId);
    const menusFetching = useSelector((state: RootState) => state.mms.menus.master.menus.fetching);
    const itemsById = useSelector((state: RootState) => state.mms.menus.master.items.byId);
    const itemsFetching = useSelector((state: RootState) => state.mms.menus.master.items.fetching);
    const modsByHref = useSelector((state: RootState) => state.mms.menus.master.modifiers.byHref);
    const groupsById = useSelector((state: RootState) => state.mms.menus.master.modifierGroups.byId);
    const optionSetIdsByParentId = useSelector((state: RootState) => state.mms.menus.master.optionSets.idsByParentId);
    const optionSetsById = useSelector((state: RootState) => state.mms.menus.master.optionSets.byId);
    const [itemPOSConfig, setItemPOSConfig] = useState<POSConfig | undefined>(undefined);
    const [addModifierGroup, setAddModifierGroup] = useState<MasterModifier | MasterItem | undefined>(undefined);
    const [deleteOS, setDeleteOS] = useState<{ optionSet: OptionSet; parent: MasterItem | MasterModifier } | undefined>(
        undefined,
    );
    const [osVisibility, setOSVisibility] = useState<OptionSet | undefined>(undefined);
    const [modifierVisibility, setModifierVisibility] = useState<MasterModifier | undefined>(undefined);
    const [editModifier, setEditModifier] = useState<MasterModifier | undefined>(undefined);
    const [osDefaultModifier, setOSDefaultModifier] = useState<
        { optionSet: OptionSet; modifier: MasterModifier; isDefault: boolean } | undefined
    >(undefined);
    const [deleteModifier, setDeleteModifier] = useState<
        { modifier: MasterModifier; group: ModifierGroup } | undefined
    >(undefined);
    const [editDetails, toggleEditDetails] = useToggle();
    const [editImage, toggleEditImage] = useToggle();
    const [deleteImage, toggleDeleteImage] = useToggle();
    const [editNutrition, toggleEditNutrition] = useToggle();
    const [deleteNutrition, toggleDeleteNutrition] = useToggle();

    const accountUrl = `/1.1/accounts/${getSession()?.accountId}`;
    const menuUrl = `${accountUrl}/mms/menus/${menuId}`;
    const itemUrl = `${menuUrl}/items/${itemId}`;
    const masterMenu = menusById[menuId];
    const item = itemsById[itemId];
    const isLoading = masterMenu === undefined || menusFetching || item === undefined || itemsFetching;

    useEffect(() => {
        if (masterMenu === undefined) {
            dispatch(menuFetch(menuUrl));
        }

        if (item === undefined) {
            dispatch(masterItemFetch(itemUrl));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemUrl]);

    const handleModifierAddGroup = (entity: MasterModifier | MasterItem) => setAddModifierGroup(entity);
    const handleResequenceOptionSets = useMemo(
        () =>
            debounce((optionSets: OptionSet[]) => {
                dispatch(masterItemOptionSetsSave(item, optionSets));
            }, saveDebounce),
        [dispatch, item],
    );
    const handleOSDelete = (optionSet: OptionSet, parent: MasterItem | MasterModifier) =>
        setDeleteOS({ optionSet, parent });
    const handleOSVisibility = (os: OptionSet) => setOSVisibility(os);
    const handleModifierVisibility = (modifier: MasterModifier) => setModifierVisibility(modifier);
    const handleModifierEdit = (modifier: MasterModifier) => setEditModifier(modifier);
    const handleOSDefaultModifier = (optionSet: OptionSet, modifier: MasterModifier, isDefault: boolean) =>
        setOSDefaultModifier({ optionSet, modifier, isDefault });
    const handleModifierDelete = (group: ModifierGroup, modifier: MasterModifier) =>
        setDeleteModifier({ group, modifier });

    const breadcrumbs = isLoading
        ? getBreadcrumbSkeleton()
        : [
              <RouterLink key="menu" to={`/menus/${masterMenu.id}`}>
                  {masterMenu.name}
              </RouterLink>,
              <Typography key="item" color="textPrimary">
                  {item.display_name}
              </Typography>,
          ];

    return (
        <DefaultLayout
            aside={
                <Sidebar>
                    <DetailsSidebarSection
                        isItem
                        entityId={itemId}
                        entity={item}
                        onChangeVisibility={(visible) =>
                            dispatch(masterItemSave({ ...item, enabled: visible }, itemUrl))
                        }
                        onEditPOSConfig={() => setItemPOSConfig(item.pos_config || {})}
                    />

                    <SidebarSection>
                        <SidebarHeader>Tags</SidebarHeader>
                        <SidebarContent>
                            <Tags entity={item} />
                        </SidebarContent>
                    </SidebarSection>
                </Sidebar>
            }
            breadcrumbs={[
                <RouterLink key="menus" to="/menus">
                    Menus
                </RouterLink>,
                ...breadcrumbs,
            ]}
            header={isLoading ? <Skeleton /> : <Header menuName={masterMenu.name} />}
        >
            {isLoading ? (
                <MainContentSkeleton />
            ) : (
                <>
                    <Box sx={{ display: "flex" }}>
                        <Grid container justifyContent="space-between">
                            <Grid item xs={12}>
                                <Grid container wrap="nowrap" alignItems="center">
                                    <Grid item>
                                        <Typography variant="h4">{item.display_name}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <EntityEdit
                                            sx={{ marginLeft: (theme) => theme.spacing(2), height: "fit-content" }}
                                            title="Edit Item"
                                            onClick={toggleEditDetails}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid item>
                                    {!item.description && (
                                        <Link href="#" onClick={toggleEditDetails}>
                                            Add Description
                                        </Link>
                                    )}
                                    <Typography
                                        sx={(theme) => ({
                                            paddingRight: theme.spacing(5),
                                            marginTop: theme.spacing(item.description ? 2 : 0),
                                            marginBottom: theme.spacing(2),
                                        })}
                                        variant="subtitle1"
                                    >
                                        {item.description}
                                    </Typography>

                                    {item._embedded.nutrition ? (
                                        <Box sx={{ flexFlow: "column", marginTop: (theme) => theme.spacing(4) }}>
                                            <Box sx={{ display: "inline-flex" }}>
                                                <Typography variant="h6">Nutrition Facts</Typography>
                                                <EntityEdit
                                                    sx={{ marginLeft: (theme) => theme.spacing(1) }}
                                                    title="Edit Nutrition Facts"
                                                    onClick={toggleEditNutrition}
                                                />
                                                <EntityRemove
                                                    title="Remove Nutrition Facts"
                                                    onClick={toggleDeleteNutrition}
                                                />
                                            </Box>
                                            <NutritionFactsDisplay
                                                className={masterClasses.nutrition}
                                                nutrition={item._embedded.nutrition}
                                            />
                                        </Box>
                                    ) : (
                                        <Link href="#" onClick={toggleEditNutrition}>
                                            Add Nutrition Facts
                                        </Link>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid sx={{ margin: (theme) => theme.spacing(1, 0) }} item>
                            <ItemImage item={item} onEdit={toggleEditImage} onRemove={toggleDeleteImage} />
                        </Grid>
                    </Box>

                    <Box
                        sx={{ position: "relative", marginTop: (theme) => theme.spacing(2) }}
                        className={classes.container}
                    >
                        <ActionButton
                            onClick={() => handleModifierAddGroup(item)}
                            label="Add Modifier Group"
                            className={classes.addGroup}
                            variant="contained"
                        />

                        <MasterOptionSetList
                            className={classes.optionSetList}
                            modsByHref={modsByHref}
                            onModifierAddGroup={handleModifierAddGroup}
                            onModifierDelete={handleModifierDelete}
                            onModifierEdit={handleModifierEdit}
                            onModifierVisibility={handleModifierVisibility}
                            onOptionSetVisibility={handleOSVisibility}
                            onOptionSetDelete={handleOSDelete}
                            onOptionSetDefaultModifier={handleOSDefaultModifier}
                            onResequenceOptionSets={handleResequenceOptionSets}
                            modGroupsById={groupsById}
                            optionSetIdsByModifierId={optionSetIdsByParentId}
                            optionSetsById={optionSetsById}
                            masterEntity={item}
                        />
                    </Box>

                    <OmniDrawer close={toggleEditDetails} open={editDetails} title="Edit Item">
                        {editDetails && (
                            <EntityDetails
                                display_name={item.display_name}
                                reference_name={item.reference_name}
                                description={item.description}
                                submitLabel="Update Item"
                                onClose={toggleEditDetails}
                                onSave={(display_name, reference_name, description, callback) =>
                                    dispatch(
                                        masterItemSave(
                                            { ...item, display_name, reference_name, description: description || "" },
                                            itemUrl,
                                            callback,
                                        ),
                                    )
                                }
                            />
                        )}
                    </OmniDrawer>

                    {editImage && (
                        <PickerOverlay
                            apikey={process.env.REACT_APP_SERVICES_FILESTACK_KEY}
                            pickerOptions={{
                                onCancel: toggleEditImage,
                                onClose: toggleEditImage,
                                maxSize: parseInt(
                                    process.env.REACT_APP_SERVICES_FILESTACK_OPTIONS_MAXSIZE || "5242880",
                                    10,
                                ),
                                maxFiles: 1,
                                uploadInBackground: false,
                                accept: "image/*",
                                transformations: {
                                    crop: true,
                                    rotate: true,
                                },
                                fromSources: [
                                    "local_file_system",
                                    "url",
                                    "instagram",
                                    "dropbox",
                                    "googledrive",
                                    "facebook",
                                    "onedrive",
                                    "onedriveforbusiness",
                                    "clouddrive",
                                ],
                            }}
                            onSuccess={(res) => {
                                if (res.filesUploaded.length > 0) {
                                    dispatch(masterItemImageSave(item, res.filesUploaded[0].url));
                                }
                            }}
                        />
                    )}

                    <NutritionFactsEdit open={editNutrition} entity={item} onClose={toggleEditNutrition} />

                    <OmniDrawer
                        close={() => setItemPOSConfig(undefined)}
                        open={itemPOSConfig !== undefined}
                        title="Update Item Settings"
                    >
                        {item !== undefined && itemPOSConfig !== undefined && (
                            <EditPOSConfig
                                posConfig={item.pos_config}
                                strategy={item.strategy}
                                traversal={buildMasterTraversal(item)}
                                locationUrl={`${accountUrl}/locations/${item.source_location}`}
                                onSave={(
                                    pos_config: POSConfig,
                                    strategy: ItemStrategy,
                                    callback,
                                    source_location,
                                    force_location_update,
                                ) => {
                                    if (source_location !== undefined) {
                                        dispatch(
                                            masterItemSave(
                                                {
                                                    ...item,
                                                    linked: true,
                                                    pos_config,
                                                    strategy,
                                                    source_location,
                                                },
                                                itemUrl,
                                                callback,
                                                force_location_update,
                                            ),
                                        );
                                    }
                                }}
                                onClose={() => setItemPOSConfig(undefined)}
                            />
                        )}
                    </OmniDrawer>

                    {deleteImage && <ItemImageDelete item={item} onClose={toggleDeleteImage} open={deleteImage} />}

                    {deleteNutrition && (
                        <NutritionFactsDelete entity={item} onClose={toggleDeleteNutrition} open={deleteNutrition} />
                    )}

                    {/* Item/Modifier Add Modifer Group */}
                    <EntityAddModifierGroup
                        entity={addModifierGroup}
                        itemId={itemId}
                        menuId={menuId}
                        onClose={() => setAddModifierGroup(undefined)}
                        open={Boolean(addModifierGroup)}
                    />

                    {/* Delete Option Set */}
                    {deleteOS && (
                        <OptionSetDelete
                            onClose={() => setDeleteOS(undefined)}
                            open={Boolean(deleteOS)}
                            {...deleteOS}
                        />
                    )}

                    {/* Edit Option Set Visibility */}
                    {osVisibility && (
                        <OptionSetVisibility
                            menuName={masterMenu.name}
                            onClose={() => setOSVisibility(undefined)}
                            open={Boolean(osVisibility)}
                            optionSet={osVisibility}
                        />
                    )}

                    {/* Edit Modifier Visibility */}
                    {modifierVisibility && (
                        <ModifierVisibility
                            modifier={modifierVisibility}
                            onClose={() => setModifierVisibility(undefined)}
                            open={Boolean(modifierVisibility)}
                        />
                    )}

                    {/* Edit Modifier POS Settings/Relink */}
                    <ModifierEditPOSConfig
                        item={item}
                        modifier={editModifier}
                        onClose={() => setEditModifier(undefined)}
                        open={Boolean(editModifier)}
                    />

                    {/* Option Set Default Modifier */}
                    {osDefaultModifier && (
                        <OptionSetDefaultModifier
                            onClose={() => setOSDefaultModifier(undefined)}
                            open={Boolean(osDefaultModifier)}
                            {...osDefaultModifier}
                        />
                    )}

                    {/* Delete Modifier from Group */}
                    {deleteModifier && (
                        <ModifierDelete
                            onClose={() => setDeleteModifier(undefined)}
                            open={Boolean(deleteModifier)}
                            {...deleteModifier}
                        />
                    )}
                </>
            )}
        </DefaultLayout>
    );
}

interface ItemImageProps {
    item: MasterItem;
    onEdit: () => void;
    onRemove: () => void;
}

const ItemImage = ({ item, onEdit, onRemove }: ItemImageProps): JSX.Element => {
    const imageSize = 200;
    return (
        <Grid item>
            <Card>
                {item._embedded.image && (
                    <Box sx={{ position: "relative" }}>
                        <CardActionArea onClick={() => window.open(item._embedded.image?.url, "_blank")}>
                            <CardMedia
                                sx={{
                                    height: imageSize,
                                    width: imageSize,
                                }}
                                image={item._embedded.image.url}
                            />
                        </CardActionArea>
                        <Box
                            sx={{
                                backgroundColor: (theme) => theme.mixins.imageIconBackground,
                                position: "absolute",
                                top: 0,
                                right: 0,
                                width: "max-content",
                                margin: "auto",
                                borderBottomLeftRadius: "4px",
                            }}
                        >
                            <EntityEdit
                                title="Edit Image"
                                onClick={(e) => {
                                    e && e.preventDefault();
                                    onEdit();
                                }}
                                sx={(theme) => ({
                                    fill: theme.mixins.imageIcon,
                                })}
                            />
                            <EntityRemove
                                title="Remove Image"
                                onClick={(e) => {
                                    e && e.preventDefault();
                                    onRemove();
                                }}
                                sx={(theme) => ({
                                    fill: theme.mixins.imageIcon,
                                })}
                            />
                        </Box>
                    </Box>
                )}

                {!item._embedded.image && (
                    <CardActionArea onClick={onEdit}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                height: imageSize,
                                width: imageSize,
                            }}
                        >
                            <FontAwesomeIcon icon={faFilePlus} size="5x" />
                        </Avatar>
                    </CardActionArea>
                )}
            </Card>
        </Grid>
    );
};
