import { faCog } from "@fortawesome/pro-duotone-svg-icons";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeStyles } from "theme";
import { Skeleton } from "@mui/material";
import { money } from "components/common/utils";
import React from "react";
import { ComboPOSConfig, ItemPOSConfig, ModifierPOSConfig } from "store/mms/types";
import { Theme, Typography } from "@mui/material";
import { ActionButton } from "components/common/buttons/ActionButton";

interface DisplayPOSConfigProps {
    loading?: false;
    isModifier?: boolean;
    posConfig?: ComboPOSConfig | ItemPOSConfig | ModifierPOSConfig;
    onClickEdit?: () => void;
    isLinked?: boolean;
}
interface DisplayPOSConfigLoadingProps {
    loading: true;
}

const useStyle = makeStyles()((theme: Theme) => ({
    idNameTitle: {
        fontWeight: theme.typography.fontWeightBold,
    },
    idName: {
        marginBottom: theme.spacing(3),
        display: "flex",
        flexDirection: "column",
        "& > span": {
            flexBasis: "100%",
        },
    },
    priceTitle: {
        fontWeight: theme.typography.fontWeightBold,
    },
    price: {
        marginBottom: theme.spacing(3),
    },
    notFoundTitle: {
        fontWeight: theme.typography.fontWeightBold,
    },
    notFound: {
        marginBottom: theme.spacing(3),
    },
    iconNotFound: {
        color: theme.palette.warning.main,
    },
}));

export default function DisplayPOSConfig({
    loading = false,
    ...props
}: DisplayPOSConfigProps | DisplayPOSConfigLoadingProps): JSX.Element {
    const { classes } = useStyle();

    if (loading) {
        return <Skeleton width="100%" height={210} variant="rectangular" />;
    }

    const { isModifier = false, posConfig, onClickEdit: onClick, isLinked = true } = props as DisplayPOSConfigProps;
    const priceLevel = posConfig?.price_level;

    return (
        <>
            {!isLinked && (
                <Typography variant="body1" title="pos-unlinked" className={classes.notFound}>
                    <FontAwesomeIcon size="lg" className={classes.iconNotFound} icon={faExclamationTriangle} />
                    {` Status: ${isModifier ? "Modifier" : "Item"} Unlinked`}
                </Typography>
            )}

            <Typography variant="subtitle1" className={classes.idNameTitle}>
                POS ID &amp; Name:
            </Typography>
            {posConfig && posConfig.id ? (
                <>
                    <Typography variant="body1" title="pos-id-name" className={classes.idName}>
                        <span>{posConfig?.id}</span>
                        <span>{posConfig?.name}</span>
                    </Typography>
                    <Typography variant="subtitle1" title="pos-price-title" className={classes.priceTitle}>
                        Price{priceLevel && " Level"}:
                    </Typography>
                    <Typography variant="body1" title="pos-price" className={classes.price}>
                        {priceLevel ? (
                            <>
                                {`${posConfig?.price_level?.id} - ${
                                    posConfig?.price_level ? money(posConfig.price_level.price_per_unit || 0) : "N/A"
                                }`}
                            </>
                        ) : (
                            money(posConfig?.price_per_unit || 0)
                        )}
                    </Typography>
                </>
            ) : (
                <>
                    <Typography variant="body1" title="pos-mapping-not-found" className={classes.notFound}>
                        <FontAwesomeIcon size="lg" className={classes.iconNotFound} icon={faExclamationTriangle} /> Not
                        found on POS
                    </Typography>
                </>
            )}

            <ActionButton
                fullWidth
                label="Edit POS Mapping"
                onClick={onClick}
                startIcon={<FontAwesomeIcon icon={faCog} />}
                variant="outlined"
            />
        </>
    );
}
