/* eslint-disable @typescript-eslint/no-explicit-any */
import { SubmissionErrors, FORM_ERROR } from "final-form";
import _ from "lodash";
import { ReactNode } from "react";
import { FieldMetaState } from "react-final-form";
import { axiosErrorMessage } from "store/types";

type FormErrorProps = {
    error: boolean;
    helperText: string;
};

export function handleErrors(meta: FieldMetaState<unknown>, message?: ReactNode): FormErrorProps {
    const hasError = meta.error && (meta.dirty || meta.submitFailed) && meta.touched;
    return {
        error: hasError,
        helperText: hasError ? meta.error : message || " ",
    };
}

export const mapToFormErrors = (error?: any): SubmissionErrors | undefined => {
    if (error !== undefined) {
        return { [FORM_ERROR]: axiosErrorMessage(error) };
    }
};

export const escapeRegExp = (v: string): string => v.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");

export const search = (query: string, rows: any[], setRows: (rs: any[]) => void, includes?: string[]): void => {
    const searchRegex = new RegExp(escapeRegExp(query), "i");
    const filteredRows = rows.filter((row) => {
        let isMatch = false;
        Object.values(row).forEach((value) => {
            if (searchRegex.test(`${value}`)) {
                isMatch = true;
            }
        });
        [...(includes || [])].forEach((include) => {
            const value = _.get(row, include);
            if (searchRegex.test(`${value}`)) {
                isMatch = true;
            }
        });
        return isMatch;
    });
    setRows(filteredRows);
};
