import { all, call, debounce, fork, ForkEffect, put, takeEvery, takeLatest } from "redux-saga/effects";
import { core } from "services/api";
import {
    LocationByMenuResp,
    LocationMenu,
    LocationMenuPageResp,
    LocationMenuReq,
    LocationMenuOnboardReq,
    LOCATION_BY_MENU_EXPORT_FETCH,
    LOCATION_MENU_APPLICATION_PAGE_FETCH,
    LOCATION_MENU_ONBOARD,
    LOCATION_MENU_PAGE_FETCH,
    LOCATION_MENU_SAVE,
} from "./types";
import {
    locationMenuApplicationPageFetchSuccess as locationMenuApplicationPageFetchSuccessAction,
    locationMenuPageFetch as locationMenuPageFetchAction,
    locationMenuPageFetchError as locationMenuPageFetchErrorAction,
    locationMenuPageFetchSuccess as locationMenuPageFetchSuccessAction,
    locationMenuSaveError,
    locationMenuSaveSuccess,
} from "./actions";
import { locationItemsSaga } from "./items/effects";
import { locationModifiersSaga } from "./items/modifiers/effects";
import locationSectionsSaga from "./sections/effects";
import locationPeriodsSaga from "./periods/effects";
import { enqueueSnackbar } from "store/notifications/actions";
import { locationMenuApplicationsSaga } from "./applications/effects";
import { LocationMenuApplication } from "./applications/types";

const mapDomainToReq = (m: LocationMenu): LocationMenuReq => ({
    enabled: m.enabled,
    id: m.id,
    name: m.name,
    onboarding_status: m.onboarding_status,
});

function locationIdMenuResp(r: LocationMenuPageResp | LocationMenu): string {
    return r._links.self.href.replace(/.+locations\/([^\/]*).*/, "$1");
}

function* locationMenuPageFetch({ url }: { url: string; type: typeof LOCATION_MENU_PAGE_FETCH }) {
    try {
        const resp: { data: LocationMenuPageResp } = yield call(core.get, url);

        yield put(
            locationMenuPageFetchSuccessAction(locationIdMenuResp(resp.data), resp.data._embedded.location_menus),
        );
        if (resp.data._links.next !== undefined) {
            yield put(locationMenuPageFetchAction(resp.data._links.next.href));
        }
    } catch (e) {
        console.error("Failed to fetch Location Menu Page", e);
        if (e instanceof Error) {
            yield put(locationMenuPageFetchErrorAction(e));
        }
    }
}

function* locationByMenuExportFetch({
    url,
    callback,
}: {
    url: string;
    callback?: (error?: Error) => void;
    type: typeof LOCATION_BY_MENU_EXPORT_FETCH;
}) {
    try {
        let nextUrl = "";
        let done = false;
        const allLocations: LocationMenu[] = [];

        while (!done) {
            const resp: { data: LocationByMenuResp } = yield call(core.get, nextUrl || url);
            allLocations.push(...resp.data._embedded.locations);
            if (resp.data._links.next) {
                nextUrl = resp.data._links.next.href;
            } else {
                done = true;
            }
        }
        if (callback) yield call(callback);
        yield put(locationMenuPageFetchSuccessAction("", allLocations));
    } catch (e) {
        console.error("Failed to fetch Location Menu Page", e);
        if (e instanceof Error) {
            if (callback) yield call(callback, e);
            yield put(locationMenuPageFetchErrorAction(e));
        }
    }
}

function* locationMenuSave({
    url,
    menu,
    callback,
}: {
    url: string;
    type: typeof LOCATION_MENU_SAVE;
    menu: LocationMenu;
    callback?: (error?: Error) => void;
}) {
    try {
        const resp: { data: LocationMenu } = yield call(core.put, url, mapDomainToReq(menu));
        const savedMenu = resp.data;

        if (callback !== undefined) {
            yield call(callback);
        }
        yield put(locationMenuSaveSuccess(savedMenu, locationIdMenuResp(savedMenu)));
    } catch (e) {
        console.error("Failed to save Location Menu", e);
        if (e instanceof Error) {
            if (callback !== undefined) {
                yield call(callback, e);
            }
            yield put(locationMenuSaveError(e));
        }
    }
}

function* locationMenusOnboard({
    url,
    locationMenus,
    callback,
}: {
    type: typeof LOCATION_MENU_ONBOARD;
    url: string;
    locationMenus: LocationMenuOnboardReq[];
    callback?: (error?: Error) => void;
}) {
    try {
        yield all(locationMenus.map((m) => call(core.post, url, m)));
        yield put(
            enqueueSnackbar({
                message: `Successfully started linking Menu(s).`,
                options: {
                    variant: "success",
                },
            }),
        );
        if (callback) yield call(callback);
    } catch (e) {
        if (callback && e instanceof Error) {
            yield call(callback, e);
        }
    }
}

interface locationMenuApplicationPageResp {
    _embedded: {
        applications: LocationMenuApplication[];
    };
    _links: {
        next?: {
            href: string;
            type: "application/hal+json; name=adminlocation_menu_application_list";
        };

        self: {
            href: string;
            type: "application/hal+json; name=adminlocation_menu_application_list";
        };
    };
}

function* locationMenuApplicationPageFetch({
    url,
}: {
    url: string;
    type: typeof LOCATION_MENU_APPLICATION_PAGE_FETCH;
}) {
    try {
        const resp: { data: locationMenuApplicationPageResp } = yield call(core.get, url);

        const menuId = url.replace(/.+menus\/([^\/]*).*/, "$1");
        yield put(locationMenuApplicationPageFetchSuccessAction(menuId, resp.data._embedded.applications));
    } catch (e) {
        console.error("Failed to fetch Location Menu Applications Page", e);
    }
}

export default function* locationMenusSaga(): Generator<ForkEffect<void>, void, unknown> {
    yield takeLatest(LOCATION_MENU_PAGE_FETCH, locationMenuPageFetch);
    yield takeLatest(LOCATION_MENU_SAVE, locationMenuSave);
    yield takeLatest(LOCATION_MENU_ONBOARD, locationMenusOnboard);
    yield takeEvery(LOCATION_MENU_APPLICATION_PAGE_FETCH, locationMenuApplicationPageFetch);
    yield debounce(500, LOCATION_BY_MENU_EXPORT_FETCH, locationByMenuExportFetch);
    yield fork(locationItemsSaga);
    yield fork(locationModifiersSaga);
    yield fork(locationPeriodsSaga);
    yield fork(locationSectionsSaga);
    yield fork(locationMenuApplicationsSaga);
}
