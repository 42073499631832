import { Alert, Grid, LinearProgress, Theme, Typography } from "@mui/material";
import { makeStyles } from "theme";
import { DataGrid, GridOverlay, GridSortDirection } from "@mui/x-data-grid";
import FileDrop from "components/common/FileDrop";
import { OmniDrawer } from "components/common/OmniDrawer";
import { RuleSetContext } from "pages/Menus/Layers/RuleSets/context";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Field, Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ruleSetMigrateLocationsAdd } from "store/mms/menus/master/layers/rule-sets/actions";
import { RuleSetLocationCsvData } from "store/mms/menus/master/layers/rule-sets/types";
import { RootState } from "store/rootReducer";
import { mapToFormErrors, search } from "components/common/forms/helpers";
import { FormSubmit } from "components/common/FormSubmit";
import { LocationMenu } from "store/mms/menus/location/types";
import { locationByMenuExportFetch } from "store/mms/menus/location/actions";
import { axiosErrorMessage } from "store/types";
import { ListPerPageOptions } from "components/props";
import { QuickSearchToolbar } from "components/common/forms/QuickSearchToolbar";
import { debounce } from "lodash";
import { searchDebounce } from "services/env";

interface RuleSetLocationMoveProps {
    open: boolean;
    onClose: () => void;
}

const useStyles = makeStyles()((theme: Theme) => ({
    subtext: {
        paddingLeft: theme.spacing(1),
        paddingTop: theme.spacing(1),
    },
    prompt: {
        paddingBottom: theme.spacing(3),
    },
    submitFeedback: {
        position: "absolute",
        visibility: "visible",
        display: "flex",
        left: "50%",
        transform: "translate(-50%)",
    },
    submitFeedbackLabel: {
        visibility: "hidden",
    },
    footer: {
        padding: theme.spacing(1, 0),
    },
    cancel: {
        marginLeft: theme.spacing(2),
    },
    section: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    locationContainer: {
        height: "256px",
    },
    info: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
    },
    submitErrors: {
        margin: theme.spacing(4, 0),
    },
}));

export default function RuleSetLocationMove({ open, onClose }: RuleSetLocationMoveProps): JSX.Element {
    const { classes } = useStyles();
    const dispatch = useDispatch();

    // Note: Not all locations are added to a rule-set on creation.  Needs to be pulled from top-level location list.
    const { menuId, layerId } = useParams() as { menuId: string; layerId: string };
    const menu = useSelector((state: RootState) => state.mms.menus.master.menus.byId[menuId]);
    const layer = useSelector((state: RootState) => state.mms.menus.master.layers.byId[layerId]);
    const { ruleSet } = useContext(RuleSetContext);

    const mapLocationsToCsv = (locations: LocationMenu[]): LocationInfo[] => {
        const csvLocations: LocationInfo[] = [];
        (locations || []).forEach((l) => {
            csvLocations.push({ "Omnivore ID": l._embedded.location.id, "Location Name": l._embedded.location.name });
        });
        return csvLocations;
    };
    const locationsByMenuId = useSelector((state: RootState) => state.mms.menus.location.menus.idsByMasterMenuId);
    const locationsById = useSelector((state: RootState) => state.mms.menus.location.menus.byId);
    const fetching = useSelector((state: RootState) => state.mms.menus.location.menus.fetching);
    const locations = mapLocationsToCsv((locationsByMenuId[menuId] || []).map((id) => locationsById[id]));

    const mapLocationsFromCsv = (csv: string): RuleSetLocationCsvData[] => {
        const rows = csv.split("\n");
        const ids: RuleSetLocationCsvData[] = [];
        rows.forEach((row, i) => {
            const values = row.split(",");
            if (values[0] && i > 0) {
                ids.push({ id: values[0], name: values[1] });
            }
        });
        return ids;
    };

    const [downloadCsv, setDownloadCsv] = useState(false);
    const [downloadError, setDownloadError] = useState<string | undefined>(undefined);

    const [searchText, setSearchText] = useState("");
    const [rows, setRows] = useState<RuleSetLocationCsvData[]>();
    const debouncedSearch = useMemo(
        () =>
            debounce((query: string, locs: RuleSetLocationCsvData[]) => {
                search(query, locs, setRows);
            }, searchDebounce),
        [],
    );

    useEffect(() => {
        if (downloadCsv && !fetching && locationsByMenuId[menuId] === undefined && locations.length === 0) {
            setDownloadError(undefined);
            dispatch(
                locationByMenuExportFetch(menu._links.locations.href, (error?: Error) => {
                    if (error) {
                        setDownloadError(axiosErrorMessage(error));
                        setDownloadCsv(false);
                    }
                }),
            );
        } else if (downloadCsv && locations.length > 0) {
            downloadCSV(locations);
            setDownloadCsv(false);
        }
    }, [locationsByMenuId, menuId, fetching, locations, downloadCsv, menu, dispatch]);

    return (
        <OmniDrawer title="Move Locations" close={() => onClose && onClose()} open={open}>
            {ruleSet && (
                <Form
                    initialValues={{
                        ids: [],
                    }}
                    onSubmit={(values, _, errorsCallback) => {
                        dispatch(
                            ruleSetMigrateLocationsAdd(layer, values.ids, ruleSet, (error?: Error) => {
                                if (!error && onClose) onClose();
                                return errorsCallback && errorsCallback(mapToFormErrors(error));
                            }),
                        );
                    }}
                    keepDirtyOnReinitialize
                >
                    {({ handleSubmit, values, submitError }) => (
                        <form onSubmit={handleSubmit} noValidate>
                            <Typography className={classes.prompt} variant="subtitle1">
                                <strong>Which locations should be moved to this Rule Set?</strong>
                            </Typography>
                            <Typography variant="subtitle2">
                                <strong>Select A File</strong>
                            </Typography>
                            <Grid className={classes.section} direction="column" container>
                                <Field name={"ids"}>
                                    {({ input }) => (
                                        <FileDrop
                                            fileType={"csv"}
                                            onDrop={(file: File) => {
                                                const reader = new FileReader();
                                                reader.readAsText(file);
                                                reader.onload = () => {
                                                    input.onChange([]);
                                                    const ls = mapLocationsFromCsv(reader.result as string);
                                                    input.onChange(ls);
                                                };
                                            }}
                                        />
                                    )}
                                </Field>

                                <Typography className={classes.subtext} variant="caption">
                                    Upload a list of locations to move to this rule set using this{" "}
                                    <strong>
                                        <a
                                            style={{ cursor: "pointer" }}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                downloadCSV();
                                            }}
                                        >
                                            .csv template.
                                        </a>
                                    </strong>
                                </Typography>
                            </Grid>
                            <Grid className={classes.section} direction="column" container>
                                <Typography className={classes.prompt} variant="subtitle1">
                                    <strong>Need help getting started?</strong>
                                </Typography>
                                <Typography variant="subtitle2">
                                    <a
                                        onClick={() => {
                                            setDownloadCsv(true);
                                        }}
                                        href="#"
                                    >
                                        Export a list of locations
                                    </a>{" "}
                                    linked to the <strong>{menu.name}</strong> Menu.
                                </Typography>
                            </Grid>
                            <Alert className={classes.info} severity="info">
                                Only locations in the .csv not already on the Rule Set will be moved with this action.
                            </Alert>
                            <DataGrid
                                className={classes.locationContainer}
                                headerHeight={48}
                                rowHeight={32}
                                disableSelectionOnClick
                                loading={false}
                                rows={rows || values.ids}
                                sortModel={[
                                    {
                                        field: "name",
                                        sort: "asc" as GridSortDirection,
                                    },
                                ]}
                                disableColumnFilter
                                disableColumnSelector
                                disableDensitySelector
                                components={{
                                    Toolbar: QuickSearchToolbar,
                                    LoadingOverlay: function CustomLoadingOverlay() {
                                        return (
                                            <GridOverlay>
                                                <div style={{ position: "absolute", top: 0, width: "100%" }}>
                                                    <LinearProgress />
                                                </div>
                                            </GridOverlay>
                                        );
                                    },
                                }}
                                componentsProps={{
                                    toolbar: {
                                        value: searchText,
                                        onChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                            const query = e.target.value;
                                            setSearchText(query);
                                            debouncedSearch(query, values.ids);
                                        },
                                        clearSearch: () => {
                                            setSearchText("");
                                            search("", values.ids, setRows);
                                        },
                                    },
                                }}
                                columns={[
                                    {
                                        field: "name",
                                        headerName: "Name",
                                        disableColumnMenu: true,
                                        flex: 3,
                                    },
                                    {
                                        field: "id",
                                        headerName: "ID",
                                        disableColumnMenu: true,
                                        flex: 2,
                                    },
                                ]}
                                pagination={values.ids.length > ListPerPageOptions[0] ? true : undefined}
                                rowsPerPageOptions={[100]}
                                sx={{
                                    minHeight: "inherit",
                                    borderLeft: "unset",
                                    borderRight: "unset",
                                    borderTop: "unset",
                                }}
                                autoHeight
                            />

                            {downloadError && (
                                <Alert className={classes.submitErrors} severity="error">
                                    {downloadError}
                                </Alert>
                            )}
                            {submitError && (
                                <Alert className={classes.submitErrors} severity="error">
                                    {submitError}
                                </Alert>
                            )}
                            <FormSubmit onClose={onClose} label="Move Locations" />
                        </form>
                    )}
                </Form>
            )}
        </OmniDrawer>
    );
}

interface LocationInfo {
    [key: string]: string | undefined;
}

function convertArrayOfObjectsToCSV(objects: LocationInfo[]) {
    const data = objects.length ? objects : [{ "Omnivore ID": "", "Location Name": "" }];
    const keys = Object.keys(data[0]);

    const results = [...keys.join(","), "\n"];

    if (objects.length > 1) {
        data.forEach((item) => {
            keys.forEach((key, i) => {
                results.push(`${item[key]}${i === keys.length - 1 ? "" : ","}`);
            });
            results.push("\n");
        });
    }

    return results.join("");
}

function downloadCSV(objects?: LocationInfo[]) {
    let csv = convertArrayOfObjectsToCSV(objects || []);
    let data = "";
    if (!csv) return;

    const fileName = "menu_locations.csv";

    if (!csv.match(/^data:text\/csv/i)) {
        csv = "data:text/csv;charset=utf-8," + csv;
    }
    data = encodeURI(csv);

    const link = document.createElement("a");
    link.style.position = "fixed";
    link.setAttribute("href", data);
    link.setAttribute("download", fileName);
    link.setAttribute("defaultPrevented", "true");
    link.click();
}
