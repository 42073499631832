import { TextField, Alert } from "@mui/material";
import ConfirmDialog from "components/common/ConfirmDialog";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { layerAdd, layersSave } from "store/mms/menus/master/layers/actions";
import { MenuLayer, NewMenuLayer } from "store/mms/menus/master/layers/types";
import { MasterMenu } from "store/mms/menus/master/types";
import { axiosErrorMessage } from "store/types";
interface EditMenuLayerProps {
    onClose(): void;
    menu: MasterMenu;
    layer?: MenuLayer;
}

export function EditMenuLayer({ menu, layer, onClose }: EditMenuLayerProps): JSX.Element {
    const dispatch = useDispatch();
    const isNew = layer === undefined;
    const [name, setName] = useState(isNew ? "" : layer.name);
    const [state, setState] = useState<{ saving: boolean; error?: string }>({
        saving: false,
    });
    const onSaveCallback = (error?: Error): void => {
        if (error) {
            return setState(() => ({
                saving: false,
                error: axiosErrorMessage(error),
            }));
        }
        setState(() => ({
            saving: false,
        }));
        onClose();
    };
    const title = `${isNew ? "Add" : "Rename"} Layer`;

    return (
        <ConfirmDialog
            title={title}
            open={true}
            onClose={onClose}
            onConfirm={() => {
                setState(() => ({ saving: true }));
                if (isNew) {
                    return dispatch(layerAdd(menu, createNewMenuLayer(name), onSaveCallback));
                }
                dispatch(layersSave(menu.id, [{ ...layer, name }], onSaveCallback));
            }}
            confirm={title}
            confirming={state.saving}
            maxWidth="xl"
            transitionDuration={0}
            disableConfirm={name.length === 0}
        >
            <TextField
                label="Name"
                variant="outlined"
                helperText="Internal use only — will not appear on the Menu."
                onChange={(e) => setName(e.target.value)}
                value={name}
                InputProps={{ autoFocus: true }}
                fullWidth
            />
            {state.error && (
                <Alert variant="outlined" severity="error">
                    {state.error}
                </Alert>
            )}
        </ConfirmDialog>
    );
}

const createNewMenuLayer = (name: string): NewMenuLayer => ({ name, enabled: false, sequence: -1 });
