import React from "react";
import {
    Avatar,
    Box,
    CardMedia as MuiCardMedia,
    CardMediaProps as MuiCardMediaProps,
    Typography,
    TypographyTypeMap,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/pro-solid-svg-icons";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";

interface CardMediaProps extends Omit<MuiCardMediaProps, "image"> {
    image?: string | null;
    placeHolder?: {
        variant: TypographyTypeMap["props"]["variant"];
        iconSize: SizeProp;
    };
}

export function CardMedia({
    image,
    sx = [],
    placeHolder = { variant: "h6", iconSize: "5x" },
}: CardMediaProps): JSX.Element {
    return image ? (
        <MuiCardMedia image={image} sx={sx} />
    ) : (
        <Avatar variant="square" sx={sx}>
            <Box sx={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
                <Typography variant={placeHolder.variant}>No Photo</Typography>
                <FontAwesomeIcon icon={faImage} size={placeHolder.iconSize} />
            </Box>
        </Avatar>
    );
}
