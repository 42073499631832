import React, { useContext, useEffect, useMemo, useState } from "react";
import { MasterSectionItem, MasterSectionItemProps, Position } from "./MasterSectionItem";
import {
    closestCenter,
    DndContext,
    DragEndEvent,
    DragOverlay,
    DragStartEvent,
    MouseSensor,
    TouchSensor,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import { arrayMove, rectSortingStrategy, SortableContext, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Section } from "store/mms/menus/master/sections/types";
import { MasterMenuContext, MenuEvent } from "pages/Menus/MasterMenu/context";
import { MenuFilterContext, MenuFilterType } from "../MenuFilter";
import { List } from "components/common/List";
import { ListItem } from "components/common/ListItem";

interface MasterMenuSectionsListProps {
    sections: Section[];
}

export function MasterMenuSectionsList({ sections }: MasterMenuSectionsListProps): JSX.Element {
    const { onChange } = useContext(MasterMenuContext);
    const { query, type } = useContext(MenuFilterContext);
    const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
    const filterMenuSections = useMemo(
        () =>
            (sections: Section[]): Section[] => {
                return sections.filter((section) => {
                    if (type === MenuFilterType.Categories && query) {
                        return section.name.toLowerCase().includes(query.toLowerCase()) ? section : null;
                    }

                    return section;
                });
            },
        [query, type],
    );
    const [menuSections, setMenuSections] = useState(filterMenuSections(sections));

    const [activeId, setActiveId] = useState<string | null>(null);
    const activeIndex = activeId ? menuSections.findIndex((s) => s.id === activeId) : -1;
    const handleDragEnd = ({ active, over }: DragEndEvent): void => {
        if (over !== null && active.id !== over.id) {
            const oldIndex = menuSections.findIndex((i) => i.id === active.id);
            const newIndex = menuSections.findIndex((i) => i.id === over.id);
            const newSectionsSequence = arrayMove(menuSections, oldIndex, newIndex);

            setMenuSections(newSectionsSequence);
            onChange({ type: MenuEvent.SectionSequence, sections: newSectionsSequence });
        }

        setActiveId(null);
    };
    const handleDragStart = (event: DragStartEvent): void => setActiveId(event.active.id);
    const handleDragCancel = (): void => setActiveId(null);
    const noMatchingSection = menuSections.length === 0 && type === MenuFilterType.Categories && query;

    useEffect(() => {
        setMenuSections(filterMenuSections(sections));
    }, [filterMenuSections, sections]);

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onDragCancel={handleDragCancel}
        >
            <SortableContext items={menuSections} strategy={rectSortingStrategy}>
                <List>
                    {menuSections.map((section, index) => (
                        <SortableMasterSectionItem
                            activeIndex={activeIndex}
                            section={section}
                            key={section.id}
                            index={index + 1}
                        />
                    ))}
                    {noMatchingSection && <ListItem ListItemTextProps={{ primary: "No matching Categories..." }} />}
                </List>
            </SortableContext>

            <DragOverlay>
                {activeId ? (
                    <MasterSectionItem
                        section={menuSections[menuSections.findIndex((i) => i.id === activeId)]}
                        index={menuSections.findIndex((i) => i.id === activeId)}
                        dragOverlay
                    />
                ) : null}
            </DragOverlay>
        </DndContext>
    );
}

const SortableMasterSectionItem = (props: MasterSectionItemProps & { activeIndex: number }): JSX.Element => {
    const { attributes, index, isDragging, isSorting, listeners, over, setNodeRef, transform, transition } =
        useSortable({
            id: props.section.id,
        });
    const style = {
        transform: isSorting ? undefined : CSS.Transform.toString(transform),
        transition: transition ? transition : undefined,
    };
    const { activeIndex, section, ...rest } = props;

    return (
        <MasterSectionItem
            section={section}
            dragActive={isDragging}
            ref={setNodeRef}
            style={style}
            insertPosition={
                over?.id === section.id ? (index > activeIndex ? Position.After : Position.Before) : undefined
            }
            listeners={listeners}
            {...rest}
            {...attributes}
        />
    );
};
