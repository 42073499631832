import { Period, PERIOD_PAGE_FETCH, PERIOD_BLOCKS_SAVE } from "./types";
import {
    periodPageFetch as periodPageFetchAction,
    periodPageFetchError as periodPageFetchErrorAction,
    periodPageFetchSuccess as periodPageFetchSuccessAction,
    periodBlocksSaveError,
    periodBlocksSaveSuccess,
} from "./actions";
import { call, ForkEffect, put, takeLatest } from "redux-saga/effects";
import { core } from "services/api";
import { Block } from "../../master/periods/types";

interface locationPeriodBlocksResp {
    _embedded: {
        blocks: Block[];
    };
}

interface locationPeriodPageResp {
    _embedded: {
        periods: Period[];
    };
    _links: {
        next?: {
            href: string;
            type: "application/hal+json; name=adminlocation_menu_period_list";
        };

        self: {
            href: string;
            type: "application/hal+json; name=adminlocation_menu_period_list";
        };
    };
}

// takes a url like https://api.omnivore.io/1.1/accounts/MBcyrc7e/locations/zgcB7cL5/mms/menus/koTjETdj/periods
// and makes it /1.1/accounts/MBcyrc7e/locations/zgcB7cL5/mms/menus/koTjETdj for lookup later
function menuUrlMenuResp(r: locationPeriodPageResp | Period): string {
    return r._links.self.href.replace(/.+(\/1.1\/accounts.+\bmenus\b\/[^\/]*).+$/, "$1");
}

function mapPeriodBlocks(blocks: Block[]): Omit<Block, "_links" | "_embedded">[] {
    return blocks.map((b) => ({
        id: b.id,
        day: b.day,
        duration: b.duration,
        eod_buffer: b.eod_buffer,
        start: b.start,
        start_buffer: b.start_buffer,
    }));
}

function* periodPageFetch({ url }: { url: string; type: typeof PERIOD_PAGE_FETCH }) {
    try {
        const resp: { data: locationPeriodPageResp } = yield call(core.get, url);

        yield put(periodPageFetchSuccessAction(menuUrlMenuResp(resp.data), resp.data._embedded.periods));
        if (resp.data._links.next !== undefined) {
            yield put(periodPageFetchAction(resp.data._links.next.href));
        }
    } catch (e) {
        console.error("Failed to fetch Location Menu Periods Page", e);
        if (e instanceof Error) {
            yield put(periodPageFetchErrorAction(e));
        }
    }
}

function* periodBlocksSave({
    url,
    period,
    blocks,
    callback,
}: {
    url: string;
    type: typeof PERIOD_BLOCKS_SAVE;
    period: Period;
    blocks: Block[];
    callback?: (error?: Error) => void;
}) {
    try {
        const resp: { data: locationPeriodBlocksResp } = yield call(core.post, url, mapPeriodBlocks(blocks));

        if (callback !== undefined) {
            yield call(callback);
        }
        yield put(periodBlocksSaveSuccess(period, resp.data._embedded.blocks, menuUrlMenuResp(period)));
    } catch (e) {
        console.error("Failed to save Location Menu Period Blocks", e);
        if (e instanceof Error) {
            if (callback !== undefined) {
                yield call(callback, e);
            }
            yield put(periodBlocksSaveError(e));
        }
    }
}

export default function* periodsSaga(): Generator<ForkEffect<void>, void, unknown> {
    yield takeLatest(PERIOD_PAGE_FETCH, periodPageFetch);
    yield takeLatest(PERIOD_BLOCKS_SAVE, periodBlocksSave);
}
