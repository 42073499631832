import {
    APPLICATION_ACTION_PAGE_FETCH,
    APPLICATION_ACTION_PAGE_FETCH_SUCCESS,
    APPLICATION_ACTION_PAGE_FETCH_ERROR,
    APPLICATION_ACTION_ORDERING_ENABLE,
    APPLICATION_ACTION_ORDERING_ENABLE_SUCCESS,
    APPLICATION_ACTION_ORDERING_ENABLE_ERROR,
    APPLICATION_ACTION_ORDERING_ENABLE_SAVE,
    APPLICATION_ACTION_ORDERING_ENABLE_SAVE_SUCCESS,
    APPLICATION_ACTION_ORDERING_ENABLE_SAVE_ERROR,
    APPLICATION_ACTION_ORDERING_ENABLE_DELETE,
    APPLICATION_ACTION_ORDERING_ENABLE_DELETE_SUCCESS,
    APPLICATION_ACTION_ORDERING_ENABLE_DELETE_ERROR,
    ApplicationActionOperations,
    ApplicationActionTargets,
    ApplicationActionOrderingEnableParams,
    ApplicationAction,
    APPLICATION_ACTION_MENU_SYNC,
    APPLICATION_ACTION_MENU_SYNC_ERROR,
    APPLICATION_ACTION_MENU_SYNC_SUCCESS,
} from "./types";

export function applicationActionPageFetch(
    applicationId?: string,
    locationId?: string,
    menuId?: string,
): ApplicationActionOperations {
    return {
        type: APPLICATION_ACTION_PAGE_FETCH,
        applicationId,
        locationId,
        menuId,
    };
}

export function applicationActionPageFetchSuccess(actions: ApplicationAction[]): ApplicationActionOperations {
    return {
        type: APPLICATION_ACTION_PAGE_FETCH_SUCCESS,
        actions,
    };
}

export function applicationActionPageFetchError(error: Error): ApplicationActionOperations {
    return {
        type: APPLICATION_ACTION_PAGE_FETCH_ERROR,
        error,
    };
}

export function applicationActionOrderingEnable(
    scheduled: number | null | undefined,
    targets: ApplicationActionTargets,
    params: ApplicationActionOrderingEnableParams,
    callback?: (error?: Error) => void,
): ApplicationActionOperations {
    return {
        type: APPLICATION_ACTION_ORDERING_ENABLE,
        scheduled,
        targets,
        params,
        callback,
    };
}

export function applicationActionOrderingEnableSuccess(actions: ApplicationAction[]): ApplicationActionOperations {
    return {
        type: APPLICATION_ACTION_ORDERING_ENABLE_SUCCESS,
        actions,
    };
}

export function applicationActionOrderingEnableError(error: Error): ApplicationActionOperations {
    return {
        type: APPLICATION_ACTION_ORDERING_ENABLE_ERROR,
        error,
    };
}

export function applicationActionOrderingEnableSave(
    url: string,
    scheduled: number | null | undefined,
    params: ApplicationActionOrderingEnableParams,
    callback?: (error?: Error) => void,
): ApplicationActionOperations {
    return {
        type: APPLICATION_ACTION_ORDERING_ENABLE_SAVE,
        url,
        scheduled,
        params,
        callback,
    };
}

export function applicationActionOrderingEnableSaveSuccess(action: ApplicationAction): ApplicationActionOperations {
    return {
        type: APPLICATION_ACTION_ORDERING_ENABLE_SAVE_SUCCESS,
        action,
    };
}

export function applicationActionOrderingEnableSaveError(error: Error): ApplicationActionOperations {
    return {
        type: APPLICATION_ACTION_ORDERING_ENABLE_SAVE_ERROR,
        error,
    };
}

export function applicationActionOrderingEnableDelete(
    url: string,
    callback?: (error?: Error) => void,
): ApplicationActionOperations {
    return {
        type: APPLICATION_ACTION_ORDERING_ENABLE_DELETE,
        url,
        callback,
    };
}

export function applicationActionOrderingEnableDeleteSuccess(action: ApplicationAction): ApplicationActionOperations {
    return {
        type: APPLICATION_ACTION_ORDERING_ENABLE_DELETE_SUCCESS,
        action,
    };
}

export function applicationActionOrderingEnableDeleteError(error: Error): ApplicationActionOperations {
    return {
        type: APPLICATION_ACTION_ORDERING_ENABLE_DELETE_ERROR,
        error,
    };
}

export function applicationActionMenuSync(
    targets: ApplicationActionTargets,
    callback?: (error?: Error) => void,
): ApplicationActionOperations {
    return {
        type: APPLICATION_ACTION_MENU_SYNC,
        targets,
        callback,
    };
}

export function applicationActionMenuSyncSuccess(actions: ApplicationAction[]): ApplicationActionOperations {
    return {
        type: APPLICATION_ACTION_MENU_SYNC_SUCCESS,
        actions,
    };
}

export function applicationActionMenuSyncError(error: Error): ApplicationActionOperations {
    return {
        type: APPLICATION_ACTION_MENU_SYNC_ERROR,
        error,
    };
}
