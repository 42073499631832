import { MasterItem, NewMasterItem } from "../items/types";
import { MasterMenu } from "../types";
import {
    isSection,
    Section,
    SectionActions,
    SectionReq,
    SECTIONS_SAVE,
    SECTIONS_SAVE_ERROR,
    SECTIONS_SAVE_SUCCESS,
    SECTION_DELETE,
    SECTION_DELETE_ERROR,
    SECTION_DELETE_SUCCESS,
    SECTION_ITEMS_SAVE,
    SECTION_ITEMS_SAVE_ERROR,
    SECTION_ITEMS_SAVE_SUCCESS,
    SECTION_ITEM_DELETE,
    SECTION_ITEM_DELETE_ERROR,
    SECTION_ITEM_DELETE_SUCCESS,
    SECTION_ITEM_EXISTING_SAVE,
    SECTION_ITEM_EXISTING_SAVE_SUCCESS,
    SECTION_ITEM_NEW_SAVE,
    SECTION_PAGE_FETCH,
    SECTION_PAGE_FETCH_ERROR,
    SECTION_PAGE_FETCH_SUCCESS,
    SECTION_SAVE,
    SECTION_SAVE_ERROR,
    SECTION_SAVE_SUCCESS,
} from "./types";

export function sectionPageFetch(url: string): SectionActions {
    return {
        type: SECTION_PAGE_FETCH,
        url,
    };
}

export function sectionPageFetchSuccess(menuId: string, sections: Section[]): SectionActions {
    return {
        type: SECTION_PAGE_FETCH_SUCCESS,
        menuId,
        sections,
    };
}

export function sectionPageFetchError(error: Error): SectionActions {
    return {
        type: SECTION_PAGE_FETCH_ERROR,
        error,
    };
}

export function sectionSave(
    section: Section | SectionReq,
    url?: string,
    callback?: (error?: Error) => void,
): SectionActions {
    if (url === undefined) {
        // TODO: fix this hack
        url = isSection(section) ? section._links.self.href : "";
    }

    return {
        type: SECTION_SAVE,
        section,
        url,
        callback,
    };
}

export function sectionSaveSuccess(menuId: string, section: Section): SectionActions {
    return {
        type: SECTION_SAVE_SUCCESS,
        menuId,
        section,
    };
}

export function sectionSaveError(error: Error): SectionActions {
    return {
        type: SECTION_SAVE_ERROR,
        error,
    };
}

export function sectionsSave(sections: Section[], url: string): SectionActions {
    return {
        type: SECTIONS_SAVE,
        sections,
        url,
    };
}

export function sectionsSaveSuccess(menuId: string, sections: Section[]): SectionActions {
    return {
        type: SECTIONS_SAVE_SUCCESS,
        menuId,
        sections,
    };
}

export function sectionsSaveError(error: Error, menuId: string, sections: Section[]): SectionActions {
    return {
        type: SECTIONS_SAVE_ERROR,
        error,
        menuId,
        sections,
    };
}

export function sectionDelete(section: Section, callback?: (error?: Error) => void): SectionActions {
    return {
        type: SECTION_DELETE,
        section,
        callback,
    };
}

export function sectionDeleteSuccess(menuId: string, section: Section): SectionActions {
    return {
        type: SECTION_DELETE_SUCCESS,
        menuId,
        section,
    };
}

export function sectionDeleteError(error: Error): SectionActions {
    return {
        type: SECTION_DELETE_ERROR,
        error,
    };
}

export function sectionItemsSave(section: Section, items: MasterItem[]): SectionActions {
    return {
        type: SECTION_ITEMS_SAVE,
        section,
        items,
    };
}
export function sectionItemsSaveSuccess(section: Section, items: MasterItem[]): SectionActions {
    return {
        type: SECTION_ITEMS_SAVE_SUCCESS,
        section,
        items,
    };
}
export function sectionItemsSaveError(error: Error): SectionActions {
    return {
        type: SECTION_ITEMS_SAVE_ERROR,
        error,
    };
}

export function sectionItemExistingSave(
    section: Section,
    item: MasterItem,
    callback?: (error?: Error) => void,
): SectionActions {
    return {
        type: SECTION_ITEM_EXISTING_SAVE,
        section,
        item,
        callback,
    };
}

export function sectionItemExistingSaveSuccess(section: Section, item: MasterItem): SectionActions {
    return {
        type: SECTION_ITEM_EXISTING_SAVE_SUCCESS,
        section,
        item,
    };
}

export function sectionItemNewSave(
    menu: MasterMenu,
    section: Section,
    item: NewMasterItem,
    callback?: (error?: Error) => void,
): SectionActions {
    return {
        type: SECTION_ITEM_NEW_SAVE,
        menu,
        section,
        item,
        callback,
    };
}

export function sectionItemDelete(
    section: Section,
    item: MasterItem,
    callback?: (error?: Error) => void,
): SectionActions {
    return {
        type: SECTION_ITEM_DELETE,
        section,
        item,
        callback,
    };
}

export function sectionItemDeleteSuccess(section: Section, item: MasterItem): SectionActions {
    return {
        type: SECTION_ITEM_DELETE_SUCCESS,
        section,
        item,
    };
}

export function sectionItemDeleteError(error: Error): SectionActions {
    return {
        type: SECTION_ITEM_DELETE_ERROR,
        error,
    };
}
