import { Alert, Typography } from "@mui/material";
import ConfirmDialog from "components/common/ConfirmDialog";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { masterItemSave } from "store/mms/menus/master/items/actions";
import { MasterItem } from "store/mms/menus/master/items/types";
import { axiosErrorMessage } from "store/types";

interface ItemVisibilityProps {
    item: MasterItem;
    onClose(): void;
    open: boolean;
}

export function ItemVisibility({ item, onClose, open }: ItemVisibilityProps): JSX.Element {
    const dispatch = useDispatch();
    const [state, setState] = useState<{ saving: boolean; error?: string }>({
        saving: false,
    });

    return (
        <ConfirmDialog
            title={`${item.enabled ? "Hide" : "Display"} Item`}
            open={open}
            onClose={onClose}
            onConfirm={() => {
                setState({ saving: true });
                dispatch(
                    masterItemSave({ ...item, enabled: !item.enabled }, item._links.self.href, (error?: Error) => {
                        if (error) {
                            return setState({
                                saving: false,
                                error: axiosErrorMessage(error),
                            });
                        }

                        setState({ saving: false });
                        onClose();
                    }),
                );
            }}
            confirm={`${item.enabled ? "Hide" : "Display"} Item`}
            confirming={state.saving}
            maxWidth="xl"
            transitionDuration={0}
        >
            <Typography>
                The Item <strong>{item.display_name}</strong> will be {item.enabled ? "hidden" : "displayed"} across any
                Category where it is used.
            </Typography>

            <Alert variant="outlined" severity="info">
                This change will affect <strong>all Locations</strong>.
            </Alert>

            {state.error && (
                <Alert variant="outlined" severity="error">
                    {state.error}
                </Alert>
            )}
        </ConfirmDialog>
    );
}
