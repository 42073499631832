import axios, { AxiosRequestConfig } from "axios";
import { apiBaseUrl } from "./env";
import { getSession, login } from "./session";

export const core = axios.create({
    baseURL: apiBaseUrl,
});

core.interceptors.request.use((config: AxiosRequestConfig) => {
    const s = getSession();

    if (s && s.apiKey && config && config.headers) {
        config.headers = { ["Api-Key"]: s.apiKey };
    }

    return config;
});

core.interceptors.response.use(
    (response) => response,
    function (error) {
        if (error.response.status === 401) {
            return login();
        }
        const s = getSession();
        if (error.response && error.response.status === 404 && !s) {
            return login();
        }
        return Promise.reject(error);
    },
);
