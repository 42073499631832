import { Skeleton, Typography } from "@mui/material";
import useRand from "helpers/useRand";
import React from "react";

export function MainContentSkeleton(): JSX.Element {
    return (
        <>
            <Skeleton width={useRand(100, 150)} height={60} variant="text" />

            <Skeleton width={useRand(100, 150)} height={20} variant="text" />
            <Typography variant="h6">
                <Skeleton width={useRand(50, 100)} height={35} variant="text" />
            </Typography>
            <hr />
            <Skeleton width="100%" height={useRand(200, 300)} variant="rectangular" />
        </>
    );
}

export function getBreadcrumbSkeleton(length = 2): JSX.Element[] {
    // This is not a component so we cannot use the useRand hook since this function could be called conditionally.
    // Conditionally calling this function, and thereby any hooks it would call, would lead to an inconsistent number of
    // hooks executed and is a fatal React error.
    //
    // There is nothing special about this math so don't get hung up on it.  It's just using cos/sin because they repeat
    // values.  This has the effect of creating Random Looking™ widths without actually being random and causing the
    // error that useRand fixes for components.
    const w = (i: number) => (100 * Math.abs(Math.cos(i) + Math.sin(i))) >>> 0;

    return Array.from({ length: length }, (_, i) => <Skeleton key={i} width={w(i)} />);
}
