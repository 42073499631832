import { Divider, Grid, Tab, Tabs, Typography } from "@mui/material";
import TabPanel from "components/common/TabPanel";
import React, { useState } from "react";
import { MasterModifier } from "store/mms/menus/master/items/modifiers/types";
import { Section } from "store/mms/menus/master/sections/types";
import { EntitiesSelected } from "./EntitiesSelected";
import { SectionList } from "./SectionList";
import { useSelector } from "react-redux";
import { RootState } from "store/rootReducer";
import { flattenOptionSets } from "store/mms/menus/master/items/option-sets/effects";
import { ModifierList } from "./ModifierList";
import { FormError } from "components/common/forms/FormError";
import { entitySelectErrors, selectedItems, selectedModifiers } from "./constants";

interface EntitySelectProps {
    sections: Section[];
}

export function EntitySelect({ sections }: EntitySelectProps): JSX.Element {
    const [tab, setTab] = useState(menuEntityTab.items);
    const itemsById = useSelector((state: RootState) => state.mms.menus.master.items.byId);
    const itemIds = new Set<string>();

    sections.forEach((s) => s._embedded.items.forEach((i) => itemIds.add(i.id)));
    const items = Array.from(itemIds).map((id) => itemsById[id]);

    const modifiersByHref = useSelector((state: RootState) => state.mms.menus.master.modifiers.byHref);
    const modsSet = new Set<MasterModifier>();
    for (const item of items) {
        const flattenedOSes = flattenOptionSets(item._embedded.option_sets);
        flattenedOSes.forEach((os) =>
            os._embedded.modifier_group._embedded.modifiers.forEach((mgm) => {
                modsSet.add(modifiersByHref[mgm._embedded.modifier._links.self.href]);
            }),
        );
    }
    const modifiers = Array.from(modsSet);

    return (
        <Grid container spacing={3} justifyContent="space-between">
            <Grid item xs={9}>
                <Typography variant="h5">Select the Menu entities that will be updated by this Rule.</Typography>

                <FormError sx={{ margin: (theme) => theme.spacing(2, 0) }} name={entitySelectErrors} />

                <Tabs
                    indicatorColor="secondary"
                    textColor="primary"
                    value={tab}
                    onChange={(e, tab: menuEntityTab) => setTab(tab)}
                >
                    <Tab label="Items" {...a11yProps(menuEntityTab.items)} />
                    <Tab label="Modifiers" {...a11yProps(menuEntityTab.modifiers)} />
                </Tabs>

                <Divider />

                <TabPanel index={menuEntityTab.items} value={tab} disableHiddenChildren>
                    <SectionList name={selectedItems} sections={sections} />
                </TabPanel>

                <TabPanel index={menuEntityTab.modifiers} value={tab} disableHiddenChildren>
                    <ModifierList name={selectedModifiers} modifiers={modifiers} />
                </TabPanel>
            </Grid>

            <Grid item xs={3}>
                <EntitiesSelected sx={{ marginTop: (theme) => theme.spacing(3) }} />
            </Grid>
        </Grid>
    );
}

enum menuEntityTab {
    items,
    modifiers,
}

function a11yProps(index: number) {
    return {
        id: `entity-tab-${index}`,
        "aria-controls": `entity-tabpanel-${index}`,
    };
}
