import { LocationMenuApplication } from "../menus/location/applications/types";
import { MasterMenu } from "../menus/master/types";
import { Location } from "store/locations/types";

export const APPLICATION_ACTION_TYPE_ORDERING_ENABLE = "ordering_enable";
export const APPLICATION_ACTION_TYPE_MENU_SYNC = "menu_sync";

export const APPLICATION_ACTION_PAGE_FETCH = "@@ADMINMASTER/APPLICATION/PAGE/FETCH";
export const APPLICATION_ACTION_PAGE_FETCH_SUCCESS = "@@ADMINMASTER/APPLICATION/PAGE/FETCH_SUCCESS";
export const APPLICATION_ACTION_PAGE_FETCH_ERROR = "@@ADMINMASTER/APPLICATION/PAGE/FETCH_ERROR";

export const APPLICATION_ACTION_ORDERING_ENABLE = "@ADMINMASTER/APPLICATION/ORDERING_ENABLE";
export const APPLICATION_ACTION_ORDERING_ENABLE_SUCCESS = "@ADMINMASTER/APPLICATION/ORDERING_ENABLE_SUCCESS";
export const APPLICATION_ACTION_ORDERING_ENABLE_ERROR = "@ADMINMASTER/APPLICATION/ORDERING_ENABLE_FAILED";

export const APPLICATION_ACTION_ORDERING_ENABLE_SAVE = "@ADMINMASTER/APPLICATION/ORDERING_ENABLE/SAVE";
export const APPLICATION_ACTION_ORDERING_ENABLE_SAVE_SUCCESS = "@ADMINMASTER/APPLICATION/ORDERING_ENABLE/SAVE_SUCCESS";
export const APPLICATION_ACTION_ORDERING_ENABLE_SAVE_ERROR = "@ADMINMASTER/APPLICATION/ORDERING_ENABLE/SAVE_ERROR";

export const APPLICATION_ACTION_ORDERING_ENABLE_DELETE = "@ADMINMASTER/APPLICATION/ORDERING_ENABLE/DELETE";
export const APPLICATION_ACTION_ORDERING_ENABLE_DELETE_SUCCESS =
    "@ADMINMASTER/APPLICATION/ORDERING_ENABLE/DELETE_SUCCESS";
export const APPLICATION_ACTION_ORDERING_ENABLE_DELETE_ERROR = "@ADMINMASTER/APPLICATION/ORDERING_ENABLE/DELETE_ERROR";

export const APPLICATION_ACTION_MENU_SYNC = "@ADMINMASTER/APPLICATION/MENU_SYNC";
export const APPLICATION_ACTION_MENU_SYNC_SUCCESS = "@ADMINMASTER/APPLICATION/MENU_SYNC_SUCCESS";
export const APPLICATION_ACTION_MENU_SYNC_ERROR = "@ADMINMASTER/APPLICATION/MENU_SYNC_ERROR";

export interface ApplicationAction {
    id: string;

    type: string;
    params: string;

    scheduled: number | undefined | null;
    started: number;

    status: string;
    success: boolean | undefined | null;

    _embedded: {
        events: ApplicationActionEvent[];
        user: User;
    };

    _links: {
        self: {
            href: string;
            type: string;
        };
        events: {
            href: string;
            type: string;
        };
        user: {
            href: string;
            type: string;
        };
    };
}

export interface ApplicationActionEvent {
    id: string;

    actionId: string;
    actionType: string;

    created: number;
    started: number;

    status: string;
    success: boolean | undefined | null;

    enabled: boolean;
    user: string;

    scheduled: boolean;
    start_time: number | undefined | null;

    _links: {
        self: {
            href: string;
            type: string;
        };
        application: {
            href: string;
            type: string;
        };
        action: {
            href: string;
            type: string;
        };
        attempts: {
            href: string;
            type: string;
        };
    };

    _embedded: {
        user: User;
        application: LocationMenuApplication;
        menu: MasterMenu;
        location: Location;
    };
}

export const defaultNullUser = (): User => ({
    id: "",
    admin: false,
    full_name: "",
    email: "",
    phone: "",
    created: 0,
    modified: 0,
    pending_invite: false,
});

interface User {
    id: string;
    admin: boolean;
    full_name: string;
    email: string;
    phone: string;
    created: number;
    modified: number;
    pending_invite: boolean;
}

interface ApplicationActionPageFetch {
    type: typeof APPLICATION_ACTION_PAGE_FETCH;
    applicationId?: string;
    locationId?: string;
    menuId?: string;
}
interface ApplicationActionPageFetchSuccess {
    type: typeof APPLICATION_ACTION_PAGE_FETCH_SUCCESS;
    actions: ApplicationAction[];
}
interface ApplicationActionPageFetchError {
    type: typeof APPLICATION_ACTION_PAGE_FETCH_ERROR;
    error: Error;
}

export interface ApplicationActionTargets {
    locations: string[];
    menus: string[];
    applications: string[];
}

export interface ApplicationActionOrderingEnableParams {
    enabled: boolean;
}

interface ApplicationActionOrderingEnable {
    type: typeof APPLICATION_ACTION_ORDERING_ENABLE;
    scheduled: number | null | undefined;
    targets: ApplicationActionTargets;
    params: ApplicationActionOrderingEnableParams;
    callback?: (error?: Error) => void;
}

interface ApplicationActionOrderingEnableSuccess {
    type: typeof APPLICATION_ACTION_ORDERING_ENABLE_SUCCESS;
    actions: ApplicationAction[];
}

interface ApplicationActionOrderingEnableError {
    type: typeof APPLICATION_ACTION_ORDERING_ENABLE_ERROR;
    error: Error;
}

interface ApplicationActionOrderingEnableSave {
    type: typeof APPLICATION_ACTION_ORDERING_ENABLE_SAVE;
    url: string;
    scheduled: number | null | undefined;
    params: ApplicationActionOrderingEnableParams;
    callback?: (error?: Error) => void;
}

interface ApplicationActionOrderingEnableSaveSuccess {
    type: typeof APPLICATION_ACTION_ORDERING_ENABLE_SAVE_SUCCESS;
    action: ApplicationAction;
}

interface ApplicationActionOrderingEnableSaveError {
    type: typeof APPLICATION_ACTION_ORDERING_ENABLE_SAVE_ERROR;
    error: Error;
}

interface ApplicationActionOrderingEnableDelete {
    type: typeof APPLICATION_ACTION_ORDERING_ENABLE_DELETE;
    url: string;
    callback?: (error?: Error) => void;
}

interface ApplicationActionOrderingEnableDeleteSuccess {
    type: typeof APPLICATION_ACTION_ORDERING_ENABLE_DELETE_SUCCESS;
    action: ApplicationAction;
}

interface ApplicationActionOrderingEnableDeleteError {
    type: typeof APPLICATION_ACTION_ORDERING_ENABLE_DELETE_ERROR;
    error: Error;
}

interface ApplicationActionMenuSync {
    type: typeof APPLICATION_ACTION_MENU_SYNC;
    targets: ApplicationActionTargets;
    callback?: (error?: Error) => void;
}

interface ApplicationActionMenuSyncSuccess {
    type: typeof APPLICATION_ACTION_MENU_SYNC_SUCCESS;
    actions: ApplicationAction[];
}

interface ApplicationActionMenuSyncError {
    type: typeof APPLICATION_ACTION_MENU_SYNC_ERROR;
    error: Error;
}

export type ApplicationActionOperations =
    | ApplicationActionPageFetch
    | ApplicationActionPageFetchSuccess
    | ApplicationActionPageFetchError
    | ApplicationActionOrderingEnable
    | ApplicationActionOrderingEnableSuccess
    | ApplicationActionOrderingEnableError
    | ApplicationActionMenuSync
    | ApplicationActionMenuSyncSuccess
    | ApplicationActionMenuSyncError
    | ApplicationActionOrderingEnableSave
    | ApplicationActionOrderingEnableSaveSuccess
    | ApplicationActionOrderingEnableSaveError
    | ApplicationActionOrderingEnableDelete
    | ApplicationActionOrderingEnableDeleteSuccess
    | ApplicationActionOrderingEnableDeleteError;
