import { Alert, Skeleton, TextField, Typography, Theme } from "@mui/material";
import { makeStyles } from "theme";
import { handleErrors } from "components/common/forms/helpers";
import { FormSubmit } from "components/common/FormSubmit";
import { OmniDrawer } from "components/common/OmniDrawer";
import React from "react";
import { Field, Form } from "react-final-form";
import { useDispatch } from "react-redux";
import { sectionSave } from "store/mms/menus/master/sections/actions";
import { Section } from "store/mms/menus/master/sections/types";
import { MasterMenu } from "store/mms/menus/master/types";

const useStyles = makeStyles()((theme: Theme) => ({
    alert: { marginTop: theme.spacing(4) },
    header: { marginBottom: theme.spacing(4) },
    form: {
        "& .MuiFormControl-root": {
            marginBottom: theme.spacing(4),

            "&:last-of-type": {
                marginBottom: 0,
            },
        },
    },
    submitErrors: { margin: theme.spacing(4, 0) },
}));

interface SectionEditProps {
    menu?: MasterMenu;
    onClose(): void;
    open: boolean;
    section?: Section;
}

export function SectionEdit({ menu, section, onClose, open }: SectionEditProps): JSX.Element {
    const { classes } = useStyles();
    const dispatch = useDispatch();

    return (
        <OmniDrawer close={onClose} open={open} title="Edit Category">
            {menu === undefined || section === undefined ? (
                <Skeleton />
            ) : (
                <Form
                    onSubmit={(values, _, errorsCallback) => {
                        dispatch(
                            sectionSave(
                                {
                                    id: section.id,
                                    name: values.name,
                                    description: values.description,
                                    enabled: section.enabled,
                                    sequence: section.sequence,
                                },
                                section._links.self.href,
                                (errors?: Error) => {
                                    if (errors) {
                                        return errorsCallback && errorsCallback(errors);
                                    }
                                    onClose();
                                },
                            ),
                        );
                    }}
                    initialValues={{
                        name: section.name,
                        description: section.description,
                    }}
                    validate={(values) => ({
                        name: !values.name ? "Required" : undefined,
                    })}
                >
                    {({ handleSubmit, submitError }) => (
                        <form onSubmit={handleSubmit} className={classes.form}>
                            <Typography className={classes.header} variant="h5">
                                {menu.name}
                            </Typography>

                            <Field name="name">
                                {({ input, meta }) => (
                                    <TextField
                                        {...input}
                                        {...handleErrors(meta)}
                                        label="Category Name"
                                        variant="outlined"
                                        autoFocus
                                        fullWidth
                                        data-lpignore
                                    />
                                )}
                            </Field>

                            <Field name="description">
                                {({ input, meta }) => (
                                    <TextField
                                        {...input}
                                        {...handleErrors(meta)}
                                        label="Category Description"
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        rows={8}
                                    />
                                )}
                            </Field>

                            <Alert className={classes.alert} variant="outlined" severity="info">
                                This change will affect <strong>all linked Locations</strong>.
                            </Alert>

                            {submitError && (
                                <Alert className={classes.submitErrors} severity="error">
                                    {submitError}
                                </Alert>
                            )}

                            <FormSubmit onClose={onClose} label="Edit Category" />
                        </form>
                    )}
                </Form>
            )}
        </OmniDrawer>
    );
}
