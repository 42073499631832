import * as CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import { cookieCipherKey, loginUrl } from "./env";

export interface Session {
    accountId: string | undefined;
    userId: string | undefined;
    apiKey: string | undefined;
    isSuperUser: boolean | undefined;
}

let session: {
    accountId: string;
    userId: string;
    apiKey: string;
    isSuperUser: boolean;
};

export const getSession = (): Session | undefined => {
    const encryptedDough = Cookies.get("sso_auth");

    // Login session is either non-existent or expired
    if (encryptedDough === undefined) {
        return;
    }

    if (session !== undefined) {
        return session;
    }

    try {
        const dough = decryptCookie(encryptedDough);
        const cookie = JSON.parse(dough);
        session = {
            accountId: cookie.account_id,
            userId: cookie.user_id,
            apiKey: cookie.api_key,
            isSuperUser: cookie.isSuperUser,
        };

        return session;
    } catch (e) {
        console.error("Failed to parse session cookie", e);
    }
};

export const decryptCookie = (cipherText: string): string => {
    const decoded = window.atob(cipherText);
    const plaintext = CryptoJS.AES.decrypt(decoded, cookieCipherKey);

    return plaintext.toString(CryptoJS.enc.Utf8);
};

export const logout = (): void => {
    Cookies.remove("sso_auth");
};

export const login = (): void => {
    window.location.href = `${loginUrl}?client_id=${
        process.env.REACT_APP_OM_OAUTH_APP_MERCHANT_CLIENT_ID
    }&redirect=${encodeURIComponent(window.location.pathname)}`;
};
