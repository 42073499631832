import { Alert, Typography, Theme } from "@mui/material";
import { makeStyles } from "theme";
import ConfirmDialog from "components/common/ConfirmDialog";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { axiosErrorMessage } from "store/types";
import { MasterItem } from "store/mms/menus/master/items/types";
import { Section } from "store/mms/menus/master/sections/types";
import { sectionItemDelete } from "store/mms/menus/master/sections/actions";

const sectionItemDeleteStyles = makeStyles()((theme: Theme) => ({
    alert: {
        marginTop: theme.spacing(4),
    },
}));

interface SectionItemDeleteProps {
    item: MasterItem;
    section: Section;
    onClose(): void;
    open: boolean;
}

export function SectionItemDelete({ item, section, onClose, open }: SectionItemDeleteProps): JSX.Element {
    const { classes } = sectionItemDeleteStyles();
    const dispatch = useDispatch();
    const [state, setState] = useState<{ saving: boolean; error?: string }>({
        saving: false,
    });
    const name = "Menu Item";

    return (
        <ConfirmDialog
            title={`Remove ${name}`}
            open={open}
            onClose={onClose}
            onConfirm={() => {
                setState({ saving: true });
                dispatch(
                    sectionItemDelete(section, item, (error?: Error): void => {
                        if (error) {
                            return setState({ saving: false, error: axiosErrorMessage(error) });
                        }
                        setState({ saving: false });
                        onClose();
                    }),
                );
            }}
            confirm={`Remove ${name}`}
            confirming={state.saving}
            maxWidth="xl"
            transitionDuration={0}
        >
            <Typography>
                Are you sure you want to remove the Menu Item <strong>{item.display_name}</strong> from the{" "}
                <strong>{section.name}</strong> Category?
            </Typography>

            {state.error && (
                <Alert className={classes.alert} variant="outlined" severity="error">
                    {state.error}
                </Alert>
            )}
        </ConfirmDialog>
    );
}
