import { AdminlocationReset, ADMINLOCATION_RESET } from "../types";
import {
    Period,
    PeriodActions,
    PERIOD_PAGE_FETCH,
    PERIOD_PAGE_FETCH_ERROR,
    PERIOD_PAGE_FETCH_SUCCESS,
    PERIOD_BLOCKS_SAVE,
    PERIOD_BLOCKS_SAVE_ERROR,
    PERIOD_BLOCKS_SAVE_SUCCESS,
} from "./types";

interface PeriodsState {
    readonly byUrl: {
        [key: string]: Period;
    };
    readonly byMenuUrl: {
        [menuUrl: string]: string[];
    };
    readonly allIds: string[];
    readonly fetching: boolean;
    readonly saving: boolean;
    readonly savingError?: Error;
    readonly fetchingError?: Error;
}

const initialState: PeriodsState = {
    byUrl: {},
    byMenuUrl: {},
    allIds: [],
    fetching: false,
    saving: false,
};

export default function periodsReducer(state = initialState, action: PeriodActions | AdminlocationReset): PeriodsState {
    switch (action.type) {
        case PERIOD_PAGE_FETCH:
            return {
                ...state,
                fetching: true,
            };

        case PERIOD_PAGE_FETCH_SUCCESS:
            const newUrls = action.periods.map((p) => `${action.menuUrl.replace(/\/?$/, `/periods/${p.id}`)}`);
            const newIds = action.periods.map((m) => m.id);

            return {
                ...state,
                byUrl: {
                    ...state.byUrl,
                    ...action.periods.reduce((allPeriods: { [periodId: string]: Period }, m) => {
                        allPeriods[action.menuUrl.replace(/\/?$/, `/periods/${m.id}`)] = m;

                        return allPeriods;
                    }, {}),
                },
                byMenuUrl: {
                    ...state.byMenuUrl,
                    [action.menuUrl]: [
                        ...(state.byMenuUrl[action.menuUrl] || []).filter((url) => !newUrls.includes(url)),
                        ...newUrls,
                    ],
                },
                allIds: [...state.allIds.filter((id) => !newIds.includes(id)), ...newIds],
                fetching: false,
            };

        case PERIOD_PAGE_FETCH_ERROR:
            return {
                ...state,
                fetching: false,
                fetchingError: action.error,
            };

        case PERIOD_BLOCKS_SAVE:
            return {
                ...state,
                saving: true,
            };

        case PERIOD_BLOCKS_SAVE_SUCCESS:
            const periodUrl = action.menuUrl.replace(/\/?$/, `/periods/${action.period.id}`);
            const periodIndexById = state.allIds.findIndex((id) => id === action.period.id);
            const periodIndexByMenuUrl = state.byMenuUrl[action.menuUrl].findIndex((pUrl) => pUrl === periodUrl);

            return {
                ...state,
                byUrl: {
                    ...state.byUrl,
                    [periodUrl]: {
                        ...action.period,
                        _embedded: { ...action.period._embedded, blocks: action.blocks },
                    },
                },
                allIds: [
                    ...state.allIds.slice(0, periodIndexById),
                    action.period.id,
                    ...state.allIds.slice(periodIndexById + 1),
                ],
                byMenuUrl: {
                    ...state.byMenuUrl,
                    [action.menuUrl]: [
                        ...state.byMenuUrl[action.menuUrl].slice(0, periodIndexByMenuUrl),
                        periodUrl,
                        ...state.byMenuUrl[action.menuUrl].slice(periodIndexByMenuUrl + 1),
                    ],
                },
                saving: false,
            };

        case PERIOD_BLOCKS_SAVE_ERROR:
            return {
                ...state,
                saving: false,
                savingError: action.error,
            };

        case ADMINLOCATION_RESET:
            return initialState;

        default:
            return state;
    }
}
