import { alpha, darken, lighten, Theme, useTheme } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { ComponentsOverrides } from "@mui/material/styles/overrides";
import { createMakeAndWithStyles } from "tss-react";

declare module "@mui/material/styles/createMixins" {
    interface Mixins {
        accordionElevation: number;
        borderColor: string;
        isDraggingBackground: string;
        pageElevation: number;
        mainNavColor: string;
        imageIconBackground: string;
        imageIcon: string;
    }
}

declare module "@mui/material/styles" {
    interface BreakpointOverrides {
        xs: true;
        sm: true;
        md: true;
        lg: true;
        xl: true;
    }
}

// get original configs to be used for following `createMixins`
const { palette, typography } = createTheme();
const lightDivider = "rgba(0, 0, 0, 0.12)";
const lightIsDraggingBackground = palette.grey[200];
const darkDivider = "rgba(255, 255, 255, 0.12)";
const darkIsDraggingBackground = palette.grey[400];
const accordionElevation = 6;
const pageElevation = 1;
const darkSecondaryColor = "#14a37f";
const mainNavColor = "#172431";
const breakpointValues = {
    values: {
        xs: 310,
        sm: 810,
        md: 1366,
        lg: 1440,
        xl: 1920,
    },
};
const fontFamilies = ["Helvetica", "Arial", "sans-serif"];

const overrides = (mainNavColor: string, anchorColor: string, dividerColor: string): ComponentsOverrides => ({
    // Relevant docs https://material-ui.com/customization/globals/#global-css
    MuiCssBaseline: {
        html: { minHeight: "100vh", overflowY: "scroll" },
        body: { minHeight: "100vh" },
        "#root": {
            minHeight: "100vh",
            minWidth: "100%",
            maxWidth: "100%",
            display: "flex",
            flexDirection: "column",
        },

        a: { color: anchorColor, textDecoration: "underline" },
        "a.visited": { color: anchorColor, textDecoration: "underline" },
        "a.focus": { color: anchorColor, textDecoration: "underline" },
        "a.hover": { color: anchorColor, textDecoration: "underline" },

        hr: { borderColor: dividerColor },

        ul: {
            margin: 0,
        },

        figure: {
            margin: 0,
        },
    },

    MuiCard: {
        root: {
            "&.MuiPaper-root.MuiPaper-outlined": {
                backgroundColor: "unset",
            },
        },
    },

    MuiTableCell: {
        head: {
            fontWeight: typography.fontWeightBold,
        },
    },

    MuiTooltip: {
        tooltip: {
            fontSize: typography.caption.fontSize,
        },
    },

    MuiAppBar: {
        colorPrimary: {
            backgroundColor: mainNavColor,
        },
    },

    MuiSwitch: {
        colorSecondary: {
            "&$checked": {
                color: darkSecondaryColor,
            },
            "&$checked + $track": {
                backgroundColor: darkSecondaryColor,
            },
        },
    },
    MuiToggleButton: {
        root: {
            "&.Mui-selected": {},
        },
    },
});

// Relevant docs: https://material-ui.com/customization/palette/
// Theme builders:
// - https://material.io/resources/color/#!/?view.left=0&view.right=1&primary.color=00695C&secondary.color=08053a
// - https://in-your-saas.github.io/material-ui-theme-editor/

const lightOverrides = overrides(mainNavColor, "#005A66", lightDivider);
const light = createTheme({
    mixins: {
        accordionElevation,
        pageElevation,
        mainNavColor,
        borderColor: lighten(alpha(lightDivider, 1), 0.88),
        isDraggingBackground: lightIsDraggingBackground,
        imageIconBackground: "rgba(155,155,155,0.54)",
        imageIcon: "rgba(0,0,0,0.54)",
    },

    palette: {
        mode: "light",
        warning: { main: "#ff9800" },
        info: { main: "#2196f3" },
        success: { main: "#4caf50" },

        primary: { main: "#00819c" },
        secondary: { main: "#172431" },
        error: { main: "#f44336" },

        text: {
            primary: "#111",
            secondary: "rgba(0, 0, 0, 0.87)",
            disabled: "rgba(0, 0, 0, 0.38)",
        },

        background: { paper: "#fff", default: "#fafafa" },
        divider: lightDivider,
    },
    breakpoints: breakpointValues,
    components: {
        MuiButton: {
            defaultProps: {
                disableElevation: true,
            },
        },
        MuiCard: {
            styleOverrides: lightOverrides.MuiCard,
        },
        MuiContainer: {
            defaultProps: {
                maxWidth: "xl",
            },
        },
        MuiCssBaseline: {
            styleOverrides: lightOverrides.MuiCssBaseline,
        },
        MuiTooltip: {
            styleOverrides: lightOverrides.MuiTooltip,
        },
        MuiTableCell: {
            styleOverrides: lightOverrides.MuiTableCell,
        },
        MuiAppBar: {
            styleOverrides: lightOverrides.MuiAppBar,
        },
        MuiSwitch: {
            styleOverrides: lightOverrides.MuiSwitch,
        },
        MuiToggleButton: {
            styleOverrides: lightOverrides.MuiToggleButton,
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    backgroundColor: "unset",
                },
            },
        },
        MuiGrid: {
            styleOverrides: {
                root: {
                    "& .MuiDataGrid-colCell:focus, & .MuiDataGrid-cell:focus, & .MuiDataGrid-colCell:focus-within, & .MuiDataGrid-cell:focus-within":
                        {
                            outline: "none !important",
                        },
                },
            },
        },
    },
    typography: {
        fontFamily: fontFamilies.join(","),
    },
});

const darkOverrides = overrides(mainNavColor, "#48a3cb", darkDivider);
const dark = createTheme({
    mixins: {
        accordionElevation,
        pageElevation,
        mainNavColor,
        borderColor: darken(alpha(darkDivider, 1), 0.68),
        isDraggingBackground: darkIsDraggingBackground,
        toolbar: {},
        imageIconBackground: "rgba(155,155,155,0.54)",
        imageIcon: "rgba(0,0,0,0.54)",
    },
    palette: {
        mode: "dark",

        warning: { main: "#ff9800" },
        info: { main: "#2196f3" },
        success: { main: "#4caf50" },

        primary: { main: "#047db3" },
        secondary: { main: darkSecondaryColor },
        error: { main: "#f44336" },

        text: {
            primary: "#fff",
            secondary: "rgba(255, 255, 255, 0.7)",
            disabled: "rgba(255, 255, 255, 0.5)",
        },

        background: { paper: "#121212", default: "#121212" },
        divider: darkDivider,
    },
    breakpoints: breakpointValues,
    components: {
        MuiButton: {
            defaultProps: {
                disableElevation: true,
            },
        },
        MuiCard: {
            styleOverrides: darkOverrides.MuiCard,
        },
        MuiContainer: {
            defaultProps: {
                maxWidth: "xl",
            },
        },
        MuiCssBaseline: {
            styleOverrides: darkOverrides.MuiCssBaseline,
        },
        MuiTooltip: {
            styleOverrides: darkOverrides.MuiTooltip,
        },
        MuiTableCell: {
            styleOverrides: darkOverrides.MuiTableCell,
        },
        MuiAppBar: {
            styleOverrides: darkOverrides.MuiAppBar,
        },
        MuiSwitch: {
            styleOverrides: darkOverrides.MuiSwitch,
        },
        MuiToggleButton: {
            styleOverrides: darkOverrides.MuiToggleButton,
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    backgroundColor: "unset",
                },
            },
        },
        MuiListSubheader: {
            styleOverrides: {
                root: {
                    backgroundColor: "unset",
                },
            },
        },
        MuiGrid: {
            styleOverrides: {
                root: {
                    "& .MuiDataGrid-colCell:focus, & .MuiDataGrid-cell:focus, & .MuiDataGrid-colCell:focus-within, & .MuiDataGrid-cell:focus-within":
                        {
                            outline: "none !important",
                        },
                },
            },
        },
    },
    typography: {
        fontFamily: fontFamilies.join(","),
    },
});

/**
 * Get the theme based on the OS color scheme preferences.
 *
 * @returns Theme
 */
export default function getTheme(override?: string): Theme {
    // If framed, always use the light theme. TODO: this can go away when the MMS-UI proxy iframe shows only the
    // merchant UI and none of the MMS UI (e.g. the frame is full page and doesn't show the old header).

    const savedTheme = localStorage.getItem("theme");

    if ([savedTheme, override].includes("light")) return light;
    if ([savedTheme, override].includes("dark")) return dark;

    if (window && window.top && window.top.location !== window.self.location) {
        return light;
    }

    if (window && window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
        return dark;
    }

    return light;
}

export const { makeStyles, withStyles } = createMakeAndWithStyles({ useTheme });
