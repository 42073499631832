import { Box, Grid, IconButton, ListItemIcon, Menu, MenuItem, Tooltip, Typography, useTheme } from "@mui/material";
import React, { CSSProperties, RefObject, useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { MasterItem } from "store/mms/menus/master/items/types";
import { Section } from "store/mms/menus/master/sections/types";
import { RootState } from "store/rootReducer";
import { MenuFilterContext, MenuFilterType } from "../MenuFilter";
import {
    Delete as DeleteIcon,
    Edit as EditIcon,
    DragIndicator as DragIndicatorIcon,
    MoreVert as MoreVertIcon,
    Visibility as VisibilityIcon,
    VisibilityOff as VisibilityOffIcon,
} from "@mui/icons-material";
import { MasterItemCard, MasterItemCardProps } from "./MasterItemCard";
import {
    closestCenter,
    DndContext,
    DragEndEvent,
    DraggableSyntheticListeners,
    DragOverlay,
    DragStartEvent,
    MouseSensor,
    TouchSensor,
    useDndMonitor,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import { arrayMove, rectSortingStrategy, SortableContext, useSortable, UseSortableArguments } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { MasterMenuContext, MenuEvent } from "pages/Menus/MasterMenu/context";
import { DraggableListItemStyles, ListItem } from "components/common/ListItem";

export enum Position {
    Before = -1,
    After = 1,
}

export interface MasterSectionItemProps extends Partial<UseSortableArguments> {
    dragActive?: boolean;
    dragOverlay?: boolean;
    insertPosition?: Position;
    index?: number;
    listeners?: DraggableSyntheticListeners;
    section: Section;
    style?: CSSProperties;
}

export const MasterSectionItem = React.forwardRef(function MasterSectionItem(
    { dragOverlay, insertPosition, section, style, listeners, ...rest }: MasterSectionItemProps,
    ref: ((instance: HTMLAnchorElement | null) => void) | RefObject<HTMLAnchorElement> | null,
) {
    const theme = useTheme();
    const { onChange } = useContext(MasterMenuContext);
    const itemsById = useSelector((state: RootState) => state.mms.menus.master.items.byId);
    const [activeId, setActiveId] = useState<string | null>(null);
    const sensors = useSensors(
        useSensor(MouseSensor, { activationConstraint: { distance: 10 } }),
        useSensor(TouchSensor),
    );
    const { query, type } = useContext(MenuFilterContext);
    const filterSectionItems = useMemo(
        () =>
            (items: MasterItem[]): MasterItem[] => {
                return items
                    .filter((i) => itemsById[i.id])
                    .filter((item) => {
                        if (type === MenuFilterType.Items && query) {
                            return item.display_name.toLowerCase().includes(query.toLowerCase()) ? item : null;
                        }
                        return item;
                    })
                    .map((i) => itemsById[i.id]);
            },
        [itemsById, query, type],
    );
    const [items, setItems] = useState(filterSectionItems(section._embedded.items));
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const handleClose = () => setAnchorEl(null);
    const handleDragEnd = ({ active, over }: DragEndEvent): void => {
        if (over !== null && active.id !== over.id) {
            const oldIndex = items.findIndex((i) => i.id === active.id);
            const newIndex = items.findIndex((i) => i.id === over.id);
            const newItemSequence = arrayMove(items, oldIndex, newIndex);

            setItems(newItemSequence);
            onChange({ type: MenuEvent.ItemSequence, section, items: newItemSequence });
        }
        setActiveId(null);
    };
    const handleDragStart = (event: DragStartEvent): void => setActiveId(event.active.id);
    const handleDragCancel = (): void => setActiveId(null);
    const isDisabled = !section.enabled;
    const [dragging, setDragging] = useState(false);

    useDndMonitor({
        onDragStart() {
            setDragging(true);
        },
        onDragEnd() {
            setDragging(false);
        },
        onDragCancel() {
            setDragging(false);
        },
    });

    useEffect(() => {
        setItems(filterSectionItems(section._embedded.items));
    }, [filterSectionItems, section]);

    return (
        <ListItem
            ListItemTextProps={{
                primary: (
                    <>
                        <ListItemIcon
                            sx={(theme) => ({
                                justifyContent: "flex-start",
                                minWidth: "unset",
                                marginRight: theme.spacing(0.2),
                                alignSelf: "flex-start",
                                marginTop: theme.spacing(0.375),
                            })}
                            {...listeners}
                        >
                            <DragIndicatorIcon />
                        </ListItemIcon>
                        <Typography component="span" variant="h5">
                            {section.name}
                        </Typography>
                    </>
                ),
                secondary: section.description,
                className: isDisabled ? "disabled" : undefined,
            }}
            actions={
                <>
                    <IconButton
                        sx={{ display: { sm: "none", xs: "flex" } }}
                        onClick={(event) => setAnchorEl(event.currentTarget)}
                        size="small"
                    >
                        <MoreVertIcon fontSize="inherit" />
                    </IconButton>
                    <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onBackdropClick={handleClose}>
                        <MenuItem
                            onClick={() => {
                                handleClose();
                                onChange({ type: MenuEvent.SectionEdit, section });
                            }}
                        >
                            Update
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                handleClose();
                                onChange({ type: MenuEvent.SectionVisibility, section });
                            }}
                        >
                            {section.enabled ? "Hide" : "Display"}
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                handleClose();
                                onChange({ type: MenuEvent.SectionDelete, section });
                            }}
                        >
                            Remove Category
                        </MenuItem>
                    </Menu>

                    <Box sx={{ display: { xs: "none", sm: "flex" } }}>
                        <Tooltip title="Update" arrow>
                            <IconButton size="small" onClick={() => onChange({ type: MenuEvent.SectionEdit, section })}>
                                <EditIcon fontSize="inherit" />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title={section.enabled ? "Hide" : "Display"} arrow>
                            <IconButton
                                size="small"
                                onClick={() => onChange({ type: MenuEvent.SectionVisibility, section })}
                            >
                                {section.enabled ? (
                                    <VisibilityIcon fontSize="inherit" style={{ color: theme.palette.primary.main }} />
                                ) : (
                                    <VisibilityOffIcon
                                        fontSize="inherit"
                                        style={{ color: theme.palette.text.disabled }}
                                    />
                                )}
                            </IconButton>
                        </Tooltip>

                        <Tooltip title="Remove Category" arrow>
                            <IconButton
                                size="small"
                                onClick={() => onChange({ type: MenuEvent.SectionDelete, section })}
                            >
                                <DeleteIcon fontSize="inherit" />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </>
            }
            sx={(theme) =>
                DraggableListItemStyles(
                    theme,
                    insertPosition === Position.After,
                    insertPosition === Position.Before,
                    dragOverlay,
                )
            }
            disableCollapse={dragOverlay || dragging}
            defaultExpanded={false}
            expanded={query !== ""}
            style={style}
            ref={ref}
            {...rest}
        >
            {items.length === 0 ? (
                <Typography
                    variant="subtitle1"
                    sx={{ marginLeft: (theme) => theme.spacing(1.5) }}
                    color={isDisabled ? "text.disabled" : "textSecondary"}
                >
                    No matching Items...
                </Typography>
            ) : (
                <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragStart={handleDragStart}
                    onDragEnd={handleDragEnd}
                    onDragCancel={handleDragCancel}
                >
                    <SortableContext items={items} strategy={rectSortingStrategy}>
                        <Grid container spacing={3}>
                            {items.map((item, index) => (
                                <SortableMasterItemCard
                                    item={item}
                                    section={section}
                                    index={index}
                                    key={item.id + index}
                                />
                            ))}
                        </Grid>
                    </SortableContext>

                    <DragOverlay>
                        {activeId ? (
                            <MasterItemCard
                                item={items[items.findIndex((i) => i.id === activeId)]}
                                section={section}
                                index={items.findIndex((i) => i.id === activeId)}
                                dragOverlay
                            />
                        ) : null}
                    </DragOverlay>
                </DndContext>
            )}
        </ListItem>
    );
});

const SortableMasterItemCard = (props: MasterItemCardProps): JSX.Element => {
    const sortable = useSortable({ id: props.item.id });
    const { attributes, isDragging, listeners, setNodeRef, transform, transition } = sortable;
    const style = {
        transform: CSS.Transform.toString(transform),
        transition: transition ? transition : undefined,
    };

    return (
        <MasterItemCard
            dragActive={isDragging}
            ref={setNodeRef}
            style={style}
            {...props}
            {...attributes}
            {...listeners}
        />
    );
};
