import { Account, ACCOUNT_FETCH_SUCCESS, ACCOUNT_FETCH_ERROR, ACCOUNT_FETCH, AccountActions } from "./types";

export function accountFetch(): AccountActions {
    return {
        type: ACCOUNT_FETCH,
    };
}

export function accountFetchSuccess(account: Account): AccountActions {
    return {
        type: ACCOUNT_FETCH_SUCCESS,
        payload: account,
    };
}

export function accountFetchError(err: Error): AccountActions {
    return {
        type: ACCOUNT_FETCH_ERROR,
        payload: err,
    };
}
