import { byId } from "store/types";
import {
    Location,
    LocationsActions,
    LOCATION_SEARCH,
    LOCATION_SEARCH_ERROR,
    LOCATION_SEARCH_SUCCESS,
    LOCATION_SEARCH_RESET,
    LOCATION_FETCH,
    LOCATION_FETCH_ERROR,
    LOCATION_FETCH_SUCCESS,
    LOCATION_PAGE_FETCH,
    LOCATION_PAGE_FETCH_SUCCESS,
    LOCATION_PAGE_FETCH_ERROR,
} from "./types";

const DEFAULT_PAGE_SIZE = 50;

interface LocationsState {
    readonly byId: {
        [key: string]: Location;
    };
    readonly allIds: string[];
    readonly fetching: boolean;
    readonly saving: boolean;
    readonly searching: boolean;
    readonly searchError?: Error;
    readonly fetchError?: Error;
    readonly pagination: {
        readonly pages: { [key: number]: { ids: string[]; fetching: boolean } };
        readonly page: number;
        readonly pageSize: number;
        readonly total: number;
    };
}

const initialState: LocationsState = {
    byId: {},
    allIds: [],
    fetching: false,
    searching: false,
    saving: false,
    pagination: {
        pages: {},
        page: 0,
        pageSize: DEFAULT_PAGE_SIZE,
        total: 0,
    },
};

export function locationsReducer(state = initialState, action: LocationsActions): LocationsState {
    switch (action.type) {
        case LOCATION_FETCH:
            return {
                ...state,
                fetching: true,
            };

        case LOCATION_FETCH_SUCCESS:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.location.id]: action.location,
                },
                allIds: [...state.allIds.filter((id) => id !== action.location.id), action.location.id],
                fetching: false,
            };

        case LOCATION_FETCH_ERROR:
            return {
                ...state,
                fetching: false,
            };

        case LOCATION_SEARCH:
            return {
                ...state,
                searching: true,
            };

        case LOCATION_SEARCH_SUCCESS:
            return {
                ...state,
                byId: byId(action.locations),
                allIds: action.locations.map((l) => l.id),
                searching: false,
            };

        case LOCATION_SEARCH_ERROR:
            return {
                ...state,
                searching: false,
                searchError: action.error,
            };

        case LOCATION_SEARCH_RESET:
            return {
                ...state,
                byId: {},
                allIds: [],
                pagination: {
                    pages: {},
                    page: 0,
                    pageSize: DEFAULT_PAGE_SIZE,
                    total: 0,
                },
            };

        case LOCATION_PAGE_FETCH:
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    pages: {
                        ...state.pagination.pages,
                        [action.page]: {
                            ids: [],
                            fetching: true,
                        },
                    },
                    page: action.page,
                    pageSize: state.pagination.pageSize || action.pageSize,
                    total: state.pagination.total,
                },
            };

        case LOCATION_PAGE_FETCH_SUCCESS:
            const allIds = action.locations.map((l) => l.id);

            return {
                ...state,
                byId: {
                    ...state.byId,
                    ...byId(action.locations),
                },
                allIds: [...state.allIds.filter((id) => !allIds.includes(id)), ...allIds],
                pagination: {
                    ...state.pagination,
                    pages: {
                        ...state.pagination.pages,
                        [action.page]: {
                            ids: allIds,
                            fetching: false,
                        },
                    },
                    page: action.page,
                    pageSize: action.pageSize,
                    total: action.total,
                },
            };

        case LOCATION_PAGE_FETCH_ERROR:
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    pages: {
                        ...state.pagination.pages,
                        [action.page]: {
                            ...state.pagination.pages[action.page],
                            fetching: false,
                        },
                    },
                    page: action.page,
                },
            };

        default:
            return state;
    }
}
