import React from "react";
import { Alert, Divider, FormControl, FormLabel, Stack, TextField, Typography } from "@mui/material";
import { LocationMenuApplication } from "store/mms/menus/location/applications/types";
import { useDispatch } from "react-redux";
import ConfirmDialog from "components/common/ConfirmDialog";
import { Field, Form } from "react-final-form";
import { handleErrors, mapToFormErrors } from "components/common/forms/helpers";
import { LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { DateTimePicker, Switches } from "mui-rff";
import { FormSubmit } from "components/common/FormSubmit";
import { ApplicationActionEvent } from "store/mms/application-actions/types";
import { applicationActionOrderingEnableSave } from "store/mms/application-actions/actions";

interface ApplicationScheduledEventEditDialogProps {
    application: LocationMenuApplication;
    scheduledEvent: ApplicationActionEvent;
    onClose: () => void;
}

export default function ApplicationScheduledEventEditDialog({
    application,
    scheduledEvent,
    onClose,
}: ApplicationScheduledEventEditDialogProps): JSX.Element {
    const dispatch = useDispatch();
    const minDate = new Date();

    return (
        <ConfirmDialog title="Edit Scheduled Change" open={true} hideActions onClose={onClose} transitionDuration={0}>
            <Form
                initialValues={{ enabled: scheduledEvent.enabled, startTime: (scheduledEvent.start_time || 0) * 1000 }}
                validate={(values) => ({
                    startTime: !values.startTime ? "Required" : undefined,
                })}
                onSubmit={(values, form, errorsCallback) => {
                    dispatch(
                        applicationActionOrderingEnableSave(
                            scheduledEvent._links.action.href,
                            Math.round(values.startTime / 1000),
                            {
                                enabled: values.enabled,
                            },
                            (error?: Error) => {
                                if (!error && onClose) onClose();
                                return errorsCallback && errorsCallback(mapToFormErrors(error));
                            },
                        ),
                    );
                }}
            >
                {({ handleSubmit, submitError, values }) => (
                    <form onSubmit={handleSubmit} noValidate>
                        <FormLabel component="legend" sx={{ marginBottom: (theme) => theme.spacing(4) }}>
                            Scheduled change for {application.name}.
                        </FormLabel>
                        <Stack>
                            <FormControl
                                component="fieldset"
                                sx={{ minWidth: 120, marginBottom: (theme) => theme.spacing(1) }}
                            >
                                <FormLabel sx={{ marginBottom: (theme) => theme.spacing(2) }} component="legend">
                                    Scheduled for
                                    <Field name="startTime">
                                        {({ input, meta }) => (
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DateTimePicker
                                                    {...input}
                                                    {...handleErrors(meta)}
                                                    inputFormat="yyyy/MM/dd hh:mm a"
                                                    minDate={minDate}
                                                    renderInput={(props) => <TextField {...props} error={false} />}
                                                />
                                            </LocalizationProvider>
                                        )}
                                    </Field>
                                </FormLabel>
                            </FormControl>
                            <FormControl component="fieldset" sx={{ minWidth: 120 }}>
                                <FormLabel sx={{ marginBottom: (theme) => theme.spacing(2) }} component="legend">
                                    New ordering status
                                    <Field name="enabled">
                                        {({ input }) => (
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <Typography>Disabled</Typography>
                                                <Switches
                                                    label=""
                                                    name="enabled"
                                                    required={true}
                                                    data={{ label: "Enabled", value: input.value }}
                                                />
                                            </Stack>
                                        )}
                                    </Field>
                                </FormLabel>
                            </FormControl>
                            {submitError && (
                                <Alert variant="outlined" severity="error">
                                    {submitError}
                                </Alert>
                            )}
                        </Stack>
                        <Divider sx={(theme) => ({ margin: theme.spacing(3, -3) })} />
                        <FormSubmit
                            sx={(theme) => ({
                                marginRight: theme.spacing(0),
                                marginLeft: "auto",
                                width: "max-content",
                            })}
                            disableSubmit={!values.startTime}
                            label="Update Scheduled Change"
                            onClose={onClose}
                        />
                    </form>
                )}
            </Form>
        </ConfirmDialog>
    );
}
