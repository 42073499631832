import { Grid, Typography } from "@mui/material";
import { DataGrid, GridCellParams, GridSortItem } from "@mui/x-data-grid";
import { QuickSearchToolbar } from "components/common/forms/QuickSearchToolbar";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/rootReducer";
import { searchDebounce } from "services/env";
import { search } from "components/common/forms/helpers";
import { debounce } from "lodash";
import { format } from "date-fns";
import { ListPerPageOptions } from "components/props";
import { applicationActionPageFetch } from "store/mms/application-actions/actions";
import { LocationContext, LocationEvent } from "pages/Locations/context";
import { ApplicationActionEvent } from "store/mms/application-actions/types";
import { ActionButton } from "components/common/buttons/ActionButton";

export const LocationMenuSyncLog = () => {
    const dispatch = useDispatch();
    const { onChange, locationId } = useContext(LocationContext);

    const changeEventsById = useSelector((state: RootState) => state.mms.applicationActions.eventsById);
    const changeEvents = useMemo(
        () =>
            mapToSearchable(
                Object.values(changeEventsById)
                    .filter((e) => e.actionType === "menu_sync" && e._embedded.location.id === locationId)
                    .map((id) => id) || [],
            ),
        [changeEventsById, locationId],
    );

    const refreshCycle = useRef<NodeJS.Timer>();
    const refreshTime = 5000;
    useEffect(() => {
        let pendingChange = false;

        Object.values(changeEventsById).forEach((e) => {
            if (e.success === null || e.status === "pending") pendingChange = true;
        });

        if (refreshCycle.current) clearInterval(refreshCycle.current);

        if (pendingChange) {
            refreshCycle.current = setInterval(() => {
                dispatch(applicationActionPageFetch(undefined, locationId, undefined));
            }, refreshTime);
        }
    }, [changeEventsById, dispatch, locationId, refreshTime]);

    useEffect(() => {
        return () => {
            if (refreshCycle.current) clearInterval(refreshCycle.current);
        };
    }, []);

    const [events, setEvents] = useState<SearchableApplicationEvent[]>([]);
    useEffect(() => {
        setEvents(changeEvents);
    }, [setEvents, changeEvents]);

    useEffect(() => {
        dispatch(applicationActionPageFetch(undefined, locationId, undefined));
    }, [dispatch, locationId]);

    const [searchText, setSearchText] = useState("");
    const debouncedSearch = useMemo(
        () =>
            debounce((query: string) => {
                search(query, changeEvents, setEvents);
            }, searchDebounce),
        [changeEvents],
    );
    const [pageSize, setPageSize] = useState(ListPerPageOptions[0]);
    const [sortModel, setSortModel] = React.useState<GridSortItem[]>([
        {
            field: "created",
            sort: "desc",
        },
    ]);

    const isLoading = changeEventsById === undefined;

    return (
        <DataGrid
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={ListPerPageOptions}
            sx={{ padding: (theme) => theme.spacing(2, 2, 0, 2) }}
            loading={isLoading}
            sortModel={sortModel}
            onSortModelChange={(model) => setSortModel(model)}
            components={{
                Toolbar: QuickSearchToolbar,
            }}
            componentsProps={{
                toolbar: {
                    value: searchText,
                    onChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                        const query = e.target.value;
                        setSearchText(query);
                        debouncedSearch(query);
                    },
                    clearSearch: () => {
                        setSearchText("");
                        search("", changeEvents, setEvents);
                    },
                    title: (
                        <Grid container alignItems="flex-start" justifyContent="space-between">
                            <Grid item>
                                <Typography variant="h4">Menu Syncs</Typography>
                            </Grid>
                            <Grid item>
                                <ActionButton
                                    label="Sync Menus"
                                    onClick={() => {
                                        onChange({
                                            type: LocationEvent.ApplicationMenuSync,
                                        });
                                    }}
                                    sx={{ marginRight: (theme) => theme.spacing(2) }}
                                />
                            </Grid>
                        </Grid>
                    ),
                    enableExports: true,
                },
            }}
            rows={events}
            columns={[
                {
                    field: "created",
                    headerName: "Created",
                    flex: 0.1,
                    renderCell: (params: GridCellParams) => dataCaption(params.row.created),
                },
                {
                    field: "action",
                    headerName: "Action",
                    flex: 0.04,
                    renderCell: (params: GridCellParams) => dataCaption(params.row.actionId),
                },
                {
                    field: "user",
                    headerName: "User",
                    flex: 0.1,
                    renderCell: (params: GridCellParams) => dataCaption(params.row._embedded.user.full_name),
                },
                {
                    field: "location",
                    headerName: "Location",
                    flex: 0.12,
                    renderCell: (params: GridCellParams) =>
                        dataCaption(`${params.row._embedded.location.name} (${params.row._embedded.location.id})`),
                },
                {
                    field: "menu",
                    headerName: "Menu",
                    flex: 0.18,
                    renderCell: (params: GridCellParams) =>
                        dataCaption(`${params.row._embedded.menu.name} (${params.row._embedded.menu.id})`),
                },
                {
                    field: "application",
                    headerName: "Application",
                    flex: 0.12,
                    renderCell: (params: GridCellParams) => dataCaption(params.row._embedded.application.name),
                },
                {
                    field: "menu",
                    headerName: "Menu",
                    flex: 0.18,
                    renderCell: (params: GridCellParams) =>
                        dataCaption(`${params.row._embedded.menu.name} (${params.row._embedded.menu.id})`),
                },
                {
                    field: "status",
                    headerName: "Result",
                    flex: 0.06,
                    renderCell: (params: GridCellParams) => dataCaption(params.row.status, true),
                },
            ]}
        />
    );
};

interface SearchableApplicationEvent
    extends Omit<ApplicationActionEvent, "created" | "status" | "enabled" | "_embedded"> {
    created: string;
    status: string;
    enabled: string;
}
const mapToSearchable = (changes: ApplicationActionEvent[]): SearchableApplicationEvent[] =>
    changes
        .filter((c) => c !== undefined)
        .map((change) => {
            return {
                ...change,
                created: format(new Date((change.created || 0) * 1000), "yyyy-MM-dd HH:mm a"),
                status: change.status[0].toUpperCase() + change.status.substring(1),
                enabled: change.enabled ? "Enabled" : "Disabled",
            };
        });

const dataCaption = (input: string, isStatus?: boolean): JSX.Element => {
    return (
        <Typography
            sx={(theme) => {
                if (!isStatus) return {};

                let color;
                switch (input) {
                    case "Success":
                        color = theme.palette.success.main;
                        break;
                    case "Failed":
                        color = theme.palette.error.main;
                        break;
                    default:
                        color = theme.palette.warning.main;
                }
                return {
                    color,
                };
            }}
            variant="caption"
        >
            {input}
        </Typography>
    );
};
