import { combineReducers } from "redux";
import master from "./master/reducers";
import location from "./location/reducers";

const menusReducer = combineReducers({
    master,
    location,
});

export default menusReducer;
