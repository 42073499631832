import { Alert, Divider, FormControl, FormLabel, Grid, TextField } from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider, DatePicker, DateTimePicker } from "@mui/lab";
import ConfirmDialog from "components/common/ConfirmDialog";
import React from "react";
import { useDispatch } from "react-redux";
import { MenuLayerRule, TransformType } from "store/mms/menus/master/layers/rule-sets/rules/types";
import { Field, Form } from "react-final-form";
import { FormSubmit } from "components/common/FormSubmit";
import { ruleSave } from "store/mms/menus/master/layers/rule-sets/rules/actions";
import { mapToFormErrors } from "components/common/forms/helpers";
import { sub } from "date-fns";
import { formatDate, ruleType } from "./constants";

interface RuleActiveTimesProps {
    rule: MenuLayerRule;
    onClose(): void;
}

export function RuleActiveTimes({ rule, onClose }: RuleActiveTimesProps): JSX.Element {
    const dispatch = useDispatch();
    const selectedRuleType = rule.definition.transforms.some((t) => t.type === TransformType.AdjustMenuPeriods)
        ? ruleType.Periods
        : ruleType.Menu;
    const startDt = rule.local_start ? new Date(rule.local_start) : null;
    const endDt = rule.local_end ? new Date(rule.local_end) : null;
    const minDate = sub(new Date(), { months: 1 });
    const prompt = "Update Active Time";

    return (
        <ConfirmDialog hideActions title={prompt} open onClose={onClose} maxWidth="xl" transitionDuration={0}>
            <Form
                onSubmit={(values, form, errorsCallback) => {
                    const local_start = values.start ? formatDate(values.start, selectedRuleType) : null;
                    const local_end = values.end ? formatDate(values.end, selectedRuleType) : null;

                    dispatch(
                        ruleSave(
                            rule._links.self.href,
                            {
                                ...rule,
                                local_start,
                                local_end,
                            },
                            (error?: Error) => {
                                if (!error && onClose) onClose();
                                return errorsCallback && errorsCallback(mapToFormErrors(error));
                            },
                        ),
                    );
                }}
                initialValues={{
                    start: startDt,
                    end: endDt,
                }}
            >
                {({ handleSubmit, submitError }) => (
                    <form onSubmit={handleSubmit} noValidate>
                        <FormControl component="fieldset">
                            <FormLabel sx={{ marginBottom: (theme) => theme.spacing(4) }} component="legend">
                                Set when the Rule should be active. All times are local to each Location.
                            </FormLabel>

                            <Grid container spacing={2} justifyContent="space-between">
                                <Grid item>
                                    <Field name="start">
                                        {({ input }) => (
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                {selectedRuleType === ruleType.Periods ? (
                                                    <DatePicker
                                                        {...input}
                                                        label="Start (Optional)"
                                                        minDate={minDate}
                                                        renderInput={(props) => <TextField {...props} error={false} />}
                                                    />
                                                ) : (
                                                    <DateTimePicker
                                                        {...input}
                                                        label="Start (Optional)"
                                                        inputFormat="yyyy/MM/dd hh:mm a"
                                                        minDate={minDate}
                                                        renderInput={(props) => <TextField {...props} error={false} />}
                                                    />
                                                )}
                                            </LocalizationProvider>
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item>
                                    <Field name="end">
                                        {({ input }) => (
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                {selectedRuleType === ruleType.Periods ? (
                                                    <DatePicker
                                                        {...input}
                                                        label="End (Optional)"
                                                        minDate={minDate}
                                                        renderInput={(props) => <TextField {...props} error={false} />}
                                                    />
                                                ) : (
                                                    <DateTimePicker
                                                        {...input}
                                                        label="End (Optional)"
                                                        inputFormat="yyyy/MM/dd hh:mm a"
                                                        minDate={minDate}
                                                        renderInput={(props) => <TextField {...props} error={false} />}
                                                    />
                                                )}
                                            </LocalizationProvider>
                                        )}
                                    </Field>
                                </Grid>
                            </Grid>
                        </FormControl>
                        {submitError && (
                            <Alert variant="outlined" severity="error">
                                {submitError}
                            </Alert>
                        )}
                        <Divider sx={(theme) => ({ margin: theme.spacing(3, -3) })} />
                        <FormSubmit
                            sx={(theme) => ({
                                marginRight: theme.spacing(0),
                                marginLeft: "auto",
                                width: "max-content",
                            })}
                            label="Update Active Time"
                            onClose={onClose}
                        />
                    </form>
                )}
            </Form>
        </ConfirmDialog>
    );
}
