import { Chip, Skeleton } from "@mui/material";
import useRand from "helpers/useRand";
import React from "react";
import { isTag, Tag } from "store/mms/tags/types";
import { useStyle } from "./helpers";

interface Props {
    tags: Tag[] | string[];
    onDelete?: (tag: Tag) => void;
    loading?: boolean;
}

export default function TagCloud({ tags, onDelete, loading = false }: Props): JSX.Element {
    const { classes } = useStyle();
    const [min, max] = [80, 150];
    const rands = [useRand(min, max), useRand(min, max), useRand(min, max)];

    if (loading) {
        return (
            <>
                {rands.map((w, idx) => (
                    <Skeleton
                        key={idx}
                        variant="text"
                        width={w}
                        style={{ display: "inline-block" }}
                        className={classes.chip}
                    />
                ))}
            </>
        );
    }

    if (tags.length === 0) {
        return <span>No tags currently active.</span>;
    }

    return (
        <>
            {tags.map((tag: Tag | string, idx: number) => {
                if (isTag(tag as Tag)) {
                    const t = tag as Tag;
                    return (
                        <Chip
                            key={t.id}
                            label={t.value}
                            size="small"
                            color="secondary"
                            onDelete={onDelete ? () => onDelete(t) : undefined}
                            className={classes.chip}
                        />
                    );
                }

                tag = tag as string;
                return <Chip key={idx} label={tag} size="small" color="secondary" className={classes.chip} />;
            })}
        </>
    );
}
