import { Skeleton, Grid, IconButton, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import { Edit as EditIcon, Delete as DeleteIcon, Restore as RestoreIcon } from "@mui/icons-material";
import React from "react";

import { Period as LocationPeriod } from "store/mms/menus/location/periods/types";
import { Period as MasterPeriod } from "store/mms/menus/master/periods/types";
import { generateUIBlocks, uiBlocksDescription } from "./helpers";

interface PeriodRowProps {
    isInherited?: boolean;
    onEdit?(p: LocationPeriod | MasterPeriod): void;
    onReset?(p: LocationPeriod | MasterPeriod): void;
    onDelete?(p: LocationPeriod | MasterPeriod): void;
    period: LocationPeriod | MasterPeriod;
}

export function PeriodRow({ isInherited, onEdit, onReset, onDelete, period }: PeriodRowProps): JSX.Element {
    const blocks = uiBlocksDescription(generateUIBlocks(period));

    return (
        <TableRow>
            <TableCell>
                <Grid container direction="column">
                    <Grid item>{period.display_name}</Grid>

                    {isInherited === true && (
                        <Grid item>
                            <Typography variant="caption">Inherited from Master Menu</Typography>
                        </Grid>
                    )}
                </Grid>
            </TableCell>
            <TableCell>
                <Grid container spacing={1} direction="column">
                    {blocks.map((uib, idx) => (
                        <Grid item key={`${period.id}-${idx}`}>
                            <Grid container direction="column">
                                <Grid item>{uib.days}</Grid>
                                <Grid item>{uib.startEnd}</Grid>
                            </Grid>
                        </Grid>
                    ))}
                    {blocks.length === 0 && <Grid item>No hours set for Period.</Grid>}
                </Grid>
            </TableCell>
            <TableCell align="right">
                <Tooltip title="Update Menu Period" arrow>
                    <IconButton size="small" onClick={() => onEdit && onEdit(period)}>
                        <EditIcon fontSize="inherit" />
                    </IconButton>
                </Tooltip>
                {!isInherited && onReset && (
                    <Tooltip arrow title="Reset to Master Menu Settings" aria-label="Reset to Master Menu Settings">
                        <IconButton size="small" onClick={() => onReset(period)}>
                            <RestoreIcon fontSize="inherit" />
                        </IconButton>
                    </Tooltip>
                )}
                {onDelete && (
                    <Tooltip arrow title={<Typography variant="subtitle2">Delete Menu Period</Typography>}>
                        <IconButton size="small" onClick={() => onDelete && onDelete(period)}>
                            <DeleteIcon fontSize="inherit" />
                        </IconButton>
                    </Tooltip>
                )}
            </TableCell>
        </TableRow>
    );
}

export function LoadingSkeletonRow(): JSX.Element {
    return (
        <TableRow>
            <TableCell>
                <Skeleton width={100} height={16} />
            </TableCell>

            <TableCell>
                <Skeleton width={150} height={16} />
                <Skeleton width={100} height={14} />
            </TableCell>

            <TableCell>&nbsp;</TableCell>
        </TableRow>
    );
}
