import { call, ForkEffect, put, takeEvery } from "redux-saga/effects";
import { core } from "services/api";
import { enqueueSnackbar } from "store/notifications/actions";
import { layersPageFetchSuccess } from "../../actions";
import { MenuLayer } from "../../types";
import {
    isMenuLayerRule,
    MenuLayerRule,
    MenuLayerRuleReq,
    NewMenuLayerRule,
    RuleSave,
    RuleDelete,
    RULE_DELETE,
    RULE_SAVE,
} from "./types";

const getLayerUrl = (r: MenuLayerRule): string => r._links.self.href.replace(/(.+\blayers\b\/[^\/]*)\/.+/, "$1");
const menuId = (r: MenuLayerRule): string => r._links.self.href.replace(/.+menus\/([^\/]*).*/, "$1");
const mapToReq = (r: MenuLayerRule | NewMenuLayerRule): MenuLayerRuleReq => ({
    id: isMenuLayerRule(r) ? r.id : undefined,
    enabled: r.enabled,
    local_end: r.local_end,
    name: r.name,
    local_start: r.local_start,
    definition: r.definition,
});

function* ruleSave({ url, rule, callback }: RuleSave) {
    try {
        const resp: { data: MenuLayerRule } = yield call(
            isMenuLayerRule(rule) ? core.put : core.post,
            url,
            mapToReq(rule),
        );
        const layerResp: { data: MenuLayer } = yield call(core.get, getLayerUrl(resp.data));

        yield put(layersPageFetchSuccess(menuId(resp.data), [layerResp.data]));
        if (callback) {
            yield call(callback);
        }
    } catch (e) {
        console.error("Failed to save Menu Layer Rule", e);
        if (e instanceof Error && callback) {
            yield call(callback, e);
        }
    }
}

function* ruleDelete({ rule, callback }: RuleDelete) {
    try {
        const resp: { data: MenuLayerRule } = yield call(core.delete, rule._links.self.href);
        const layerResp: { data: MenuLayer } = yield call(core.get, getLayerUrl(resp.data));

        yield put(layersPageFetchSuccess(menuId(resp.data), [layerResp.data]));
        if (callback) {
            yield call(callback);
        }
        yield put(
            enqueueSnackbar({
                message: `Successfully deleted Menu Layer Rule ${rule.name}.`,
                options: {
                    variant: "success",
                },
            }),
        );
    } catch (e) {
        console.error("Failed to delete Menu Layer Rule", e);
        if (e instanceof Error && callback) {
            yield call(callback, e);
        }
    }
}

export default function* rulesSaga(): Generator<ForkEffect<void>, void, unknown> {
    yield takeEvery(RULE_SAVE, ruleSave);
    yield takeEvery(RULE_DELETE, ruleDelete);
}
