import React, { useContext, useEffect } from "react";
import { Period as LocationPeriod } from "store/mms/menus/location/periods/types";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { LoadingSkeletonRow, PeriodRow } from "./MenuPeriodRows";
import { LocationContext, LocationEvent } from "pages/Locations/context";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/rootReducer";
import { periodPageFetch as locationPeriodPageFetch } from "store/mms/menus/location/periods/actions";
import { periodPageFetch as masterPeriodPageFetch } from "store/mms/menus/master/periods/actions";

interface LocationMenuPeriodsProps {
    className?: string;
    onEdit?(p: LocationPeriod): void;
    onReset?(p: LocationPeriod): void;
}

export default function LocationMenuPeriods({ className, ...rest }: LocationMenuPeriodsProps): JSX.Element {
    const dispatch = useDispatch();
    const { menuUrl, menuId, onChange } = useContext(LocationContext);

    const masterPeriodsById = useSelector((state: RootState) => state.mms.menus.master.periods.byId);
    const masterPeriodsByMenuId = useSelector((state: RootState) => state.mms.menus.master.periods.byMenuId);
    const masterPeriodsFetching = useSelector((state: RootState) => state.mms.menus.master.periods.fetching);
    const masterPeriods = masterPeriodsByMenuId[menuId]
        ? Object.values(masterPeriodsByMenuId[menuId].map((pId) => masterPeriodsById[pId]))
        : [];

    const locationPeriodsByUrl = useSelector((state: RootState) => state.mms.menus.location.periods.byUrl);
    const locationPeriodsByMenuUrl = useSelector((state: RootState) => state.mms.menus.location.periods.byMenuUrl);
    const locationPeriodsFetching = useSelector((state: RootState) => state.mms.menus.location.periods.fetching);
    const locationMenuUrl = menuUrl || "";
    const locationPeriods = locationPeriodsByMenuUrl[locationMenuUrl]
        ? Object.values(locationPeriodsByMenuUrl[locationMenuUrl].map((pUrl) => locationPeriodsByUrl[pUrl]))
        : [];

    const loading =
        masterPeriodsFetching ||
        locationPeriodsFetching ||
        masterPeriodsByMenuId[menuId] === undefined ||
        locationPeriodsByMenuUrl[locationMenuUrl] === undefined;

    useEffect(() => {
        if (!locationMenuUrl || !menuId) return;
        if (!masterPeriodsByMenuId[menuId] && !masterPeriodsFetching) {
            dispatch(masterPeriodPageFetch(menuId));
        }
        if (!locationPeriodsByMenuUrl[locationMenuUrl] && !locationPeriodsFetching) {
            dispatch(locationPeriodPageFetch(`${locationMenuUrl}/periods`));
        }
    }, [
        dispatch,
        locationPeriodsFetching,
        masterPeriodsByMenuId,
        masterPeriodsFetching,
        menuId,
        locationMenuUrl,
        locationPeriodsByMenuUrl,
    ]);

    return (
        <TableContainer className={className} component={Paper} variant="outlined" square>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Time Blocks</TableCell>
                        <TableCell>&nbsp;</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {loading ? (
                        <LoadingSkeletonRow />
                    ) : (
                        locationPeriods.map((p: LocationPeriod) => {
                            const masterPeriod = masterPeriods.find((mp) => mp.id === p.id);
                            let isInherited = false;

                            if (p._embedded.blocks.length === 0) {
                                isInherited = true;
                            }

                            return (
                                <PeriodRow
                                    key={p.id}
                                    isInherited={isInherited}
                                    period={
                                        isInherited
                                            ? {
                                                  ...p,
                                                  _embedded: {
                                                      ...p._embedded,
                                                      blocks: masterPeriod?._embedded.blocks || [],
                                                  },
                                              }
                                            : p
                                    }
                                    onEdit={(period: LocationPeriod) =>
                                        onChange({ type: LocationEvent.PeriodEdit, period })
                                    }
                                    onReset={(period: LocationPeriod) =>
                                        onChange({ type: LocationEvent.PeriodReset, period })
                                    }
                                    {...rest}
                                />
                            );
                        })
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
