import { combineReducers } from "redux";
import items from "./items/reducers";
import layers from "./layers/reducers";
import periods from "./periods/reducers";
import sections from "./sections/reducers";
import optionSets from "./items/option-sets/reducers";
import modifiers from "./items/modifiers/reducers";
import modifierGroups from "./items/option-sets/modifier-group/reducers";
import {
    isMasterMenu,
    MasterMenu,
    MasterMenuActions,
    MENU_FETCH,
    MENU_FETCH_ERROR,
    MENU_FETCH_SUCCESS,
    MENU_PAGE_FETCH,
    MENU_PAGE_FETCH_ERROR,
    MENU_PAGE_FETCH_SUCCESS,
    MENU_ADD_SUCCESS,
    MENU_EDIT_SUCCESS,
} from "./types";
import { ENTITY_DELETE_SUCCESS, MasterEntityActions } from "../types";

interface MasterMenusState {
    readonly allIds: string[];
    readonly byId: { [key: string]: MasterMenu };
    readonly fetching: boolean;
    readonly fetchingError?: Error;
    readonly creatingMenuError?: Error;
    readonly requests: number;
}

const initialState: MasterMenusState = {
    allIds: [],
    byId: {},
    fetching: false,
    requests: 0,
};

function menus(state = initialState, action: MasterMenuActions | MasterEntityActions): MasterMenusState {
    switch (action.type) {
        case MENU_FETCH:
            return {
                ...state,
                fetching: true,
            };

        case MENU_FETCH_SUCCESS:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.menu.id]: action.menu,
                },
                allIds: [...state.allIds.filter((id) => id !== action.menu.id), action.menu.id],
                fetching: false,
            };

        case MENU_FETCH_ERROR:
            return {
                ...state,
                fetching: false,
                fetchingError: action.error,
            };

        case MENU_PAGE_FETCH:
            return {
                ...state,
                fetching: true,
                requests: state.requests + 1,
            };

        case MENU_PAGE_FETCH_SUCCESS:
            const newIds = action.menus.map((m) => m.id);

            return {
                ...state,
                byId: {
                    ...state.byId,
                    ...action.menus.reduce((allMenus: { [id: string]: MasterMenu }, m) => {
                        allMenus[m.id] = m;
                        return allMenus;
                    }, {}),
                },
                allIds: [...state.allIds.filter((id) => !newIds.includes(id)), ...newIds],
                requests: Math.max(state.requests - 1, 0),
                fetching: false,
            };

        case MENU_PAGE_FETCH_ERROR:
            return {
                ...state,
                fetching: false,
                fetchingError: action.error,
                requests: Math.max(state.requests - 1, 0),
            };

        case MENU_ADD_SUCCESS:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.menu.id]: action.menu,
                },
                allIds: [...state.allIds, action.menu.id],
            };

        case MENU_EDIT_SUCCESS:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.changedMenu.id]: action.changedMenu,
                },
            };

        case ENTITY_DELETE_SUCCESS:
            if (isMasterMenu(action.entity)) {
                const menuId = action.entity.id;

                return {
                    ...state,
                    byId: {
                        ...Object.values(state.byId).reduce((allMenus: { [id: string]: MasterMenu }, m) => {
                            if (m.id !== menuId) {
                                allMenus[m.id] = m;
                            }

                            return allMenus;
                        }, {}),
                    },
                    allIds: state.allIds.filter((id) => id !== menuId),
                };
            }

            return state;

        default:
            return state;
    }
}

const masterReducer = combineReducers({
    items,
    menus,
    modifiers,
    modifierGroups,
    optionSets,
    periods,
    layers,
    sections,
});

export default masterReducer;
