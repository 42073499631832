import pluralize from "pluralize";
import { call, ForkEffect, put, takeEvery } from "redux-saga/effects";
import { core } from "services/api";
import { apiBaseUrl } from "services/env";
import { getSession } from "services/session";
import { enqueueSnackbar } from "store/notifications/actions";
import {
    applicationActionOrderingEnableSuccess,
    applicationActionOrderingEnableError,
    applicationActionPageFetch as applicationActionPageFetchAction,
    applicationActionPageFetchSuccess,
    applicationActionPageFetchError,
    applicationActionOrderingEnableDeleteSuccess,
    applicationActionOrderingEnableDeleteError,
    applicationActionOrderingEnableSaveSuccess,
    applicationActionMenuSyncSuccess,
    applicationActionMenuSyncError,
} from "./actions";
import {
    ApplicationAction,
    ApplicationActionOrderingEnableParams,
    ApplicationActionTargets,
    APPLICATION_ACTION_ORDERING_ENABLE,
    APPLICATION_ACTION_ORDERING_ENABLE_DELETE,
    APPLICATION_ACTION_ORDERING_ENABLE_SAVE,
    APPLICATION_ACTION_PAGE_FETCH,
    APPLICATION_ACTION_MENU_SYNC,
    APPLICATION_ACTION_TYPE_MENU_SYNC,
    APPLICATION_ACTION_TYPE_ORDERING_ENABLE,
} from "./types";

const endpointURL = `${apiBaseUrl}/1.1/accounts/${getSession()?.accountId || ""}/mms/application_actions`;

interface ApplicationActionResp {
    data: {
        count: number;
        limit: number;
        _embedded: {
            actions: ApplicationAction[];
        };
        _links: {
            next?: {
                href: string;
                type: "application/hal+json; name=application_action_list";
            };
            self: {
                href: string;
                type: "application/hal+json; name=application_action_list";
            };
        };
    };
}

function* applicationActionPageFetch({
    application,
    locationId,
    menuId,
}: {
    type: typeof APPLICATION_ACTION_PAGE_FETCH;
    application?: string;
    locationId?: string;
    menuId?: string;
}) {
    const whereQuery = buildWhereQuery(application, locationId, menuId);

    const updatedUrl = `${endpointURL}${whereQuery}`;

    try {
        const resp: ApplicationActionResp = yield call(core.get, updatedUrl);

        yield put(applicationActionPageFetchSuccess(resp.data._embedded.actions));
    } catch (e) {
        console.error("Failed to fetch Application Action page", e);
        if (e instanceof Error) {
            yield put(applicationActionPageFetchError(e));
        }
    }
}

const buildWhereQuery = (application?: string, locationId?: string, menuId?: string): string => {
    const clauses = [];

    if (locationId) {
        clauses.push(`eq(location,"${locationId}")`);
    }
    if (menuId) {
        clauses.push(`eq(menu,"${menuId}")`);
    }
    if (application) {
        clauses.push(`eq(application,"${application}")`);
    }

    if (clauses.length === 0) return "";

    return `?where=and(${clauses.join(",")})`;
};

function* applicationActionOrderingEnable({
    scheduled,
    targets,
    params,
    callback,
}: {
    type: typeof APPLICATION_ACTION_ORDERING_ENABLE;
    scheduled: number;
    targets: ApplicationActionTargets;
    params: ApplicationActionOrderingEnableParams;
    callback?: (error?: Error) => void;
}) {
    try {
        const resp: ApplicationActionResp = yield call(core.post, endpointURL, {
            scheduled: scheduled,
            menus: targets.menus,
            locations: targets.locations,
            applications: targets.applications,
            params: {
                ...params,
                type: APPLICATION_ACTION_TYPE_ORDERING_ENABLE,
            },
        });

        if (callback) yield callback();

        yield put(applicationActionOrderingEnableSuccess(resp.data._embedded.actions));
        yield put(applicationActionPageFetchAction(resp.data._links.self.href));
    } catch (e) {
        console.error("Failed to create Application Action(s)", e);
        if (e instanceof Error) {
            if (callback) yield callback(e);
            yield put(applicationActionOrderingEnableError(e));
        }

        yield put(
            enqueueSnackbar({
                message: `Failed to update Application state.`,
                options: {
                    variant: "error",
                },
            }),
        );
    }
}

function* applicationActionOrderingEnableSave({
    url,
    scheduled,
    params,
    callback,
}: {
    type: typeof APPLICATION_ACTION_ORDERING_ENABLE_SAVE;
    url: string;
    scheduled: number;
    params: ApplicationActionOrderingEnableParams;
    callback?: (error?: Error) => void;
}) {
    try {
        const resp: { data: ApplicationAction } = yield call(core.put, url, {
            scheduled: scheduled,
            params: {
                ...params,
                type: APPLICATION_ACTION_TYPE_ORDERING_ENABLE,
            },
        });

        if (callback) yield callback();

        yield put(applicationActionOrderingEnableSaveSuccess(resp.data));
        yield put(applicationActionPageFetchAction(resp.data._links.self.href));
    } catch (e) {
        console.error("Failed to create Application Action(s)", e);
        if (e instanceof Error) {
            if (callback) yield callback(e);
            yield put(applicationActionOrderingEnableError(e));
        }

        yield put(
            enqueueSnackbar({
                message: `Failed to update Application state.`,
                options: {
                    variant: "error",
                },
            }),
        );
    }
}

function* applicationActionOrderingEnableDelete({
    url,
    callback,
}: {
    type: typeof APPLICATION_ACTION_ORDERING_ENABLE_DELETE;
    url: string;
    callback?: (error?: Error) => void;
}) {
    try {
        const resp: { data: ApplicationAction } = yield call(core.delete, url, {});

        if (callback) yield callback();

        yield put(applicationActionOrderingEnableDeleteSuccess(resp.data));
        yield put(applicationActionPageFetchAction(resp.data._links.self.href));
    } catch (e) {
        console.error("Failed to delete Application Action", e);
        if (e instanceof Error) {
            if (callback) yield callback(e);
            yield put(applicationActionOrderingEnableDeleteError(e));
        }

        yield put(
            enqueueSnackbar({
                message: `Failed to delete Application Action.`,
                options: {
                    variant: "error",
                },
            }),
        );
    }
}

function* applicationActionMenuSync({
    targets,
    callback,
}: {
    type: typeof APPLICATION_ACTION_ORDERING_ENABLE;
    targets: ApplicationActionTargets;
    callback?: (error?: Error) => void;
}) {
    try {
        const resp: ApplicationActionResp = yield call(core.post, endpointURL, {
            menus: targets.menus,
            locations: targets.locations,
            applications: targets.applications,
            params: {
                type: APPLICATION_ACTION_TYPE_MENU_SYNC,
            },
        });

        if (callback) yield callback();

        yield put(applicationActionMenuSyncSuccess(resp.data._embedded.actions));
        yield put(applicationActionPageFetchAction(resp.data._links.self.href));

        yield put(
            enqueueSnackbar({
                message: `Menu Syncs successfully published`,
                options: {
                    variant: "success",
                },
            }),
        );
    } catch (e) {
        console.error(`Failed to sync ${pluralize("menu", targets.menus.length)}`, e);
        if (e instanceof Error) {
            if (callback) yield callback(e);
            yield put(applicationActionMenuSyncError(e));
        }

        yield put(
            enqueueSnackbar({
                message: `Failed to sync ${pluralize("Menu", targets.menus.length)}`,
                options: {
                    variant: "error",
                },
            }),
        );
    }
}

export function* applicationActionsSaga(): Generator<ForkEffect<never>, void, unknown> {
    yield takeEvery(APPLICATION_ACTION_PAGE_FETCH, applicationActionPageFetch);
    yield takeEvery(APPLICATION_ACTION_ORDERING_ENABLE, applicationActionOrderingEnable);
    yield takeEvery(APPLICATION_ACTION_ORDERING_ENABLE_SAVE, applicationActionOrderingEnableSave);
    yield takeEvery(APPLICATION_ACTION_ORDERING_ENABLE_DELETE, applicationActionOrderingEnableDelete);
    yield takeEvery(APPLICATION_ACTION_MENU_SYNC, applicationActionMenuSync);
}
