import { HALEntity, HALLink } from "../../types";

export const TAGS_FETCH = "@@TAGS/FETCH";
export const TAGS_FETCH_SUCCESS = "@@TAGS/FETCH_SUCCESS";
export const TAGS_FETCH_COMPLETE = "@@TAGS/FETCH_COMPLETE";
export const TAGS_FETCH_ERROR = "@@TAGS/PAGE/FETCH_ERROR";

export const TAGGED_ENTITY_UPDATE = "@@TAGS/UPDATE";
export const TAGGED_ENTITY_UPDATE_SUCCESS = "@@TAGS/UPDATE_SUCCESS";
export const TAGGED_ENTITY_UPDATE_ERROR = "@@TAGS/UPDATE_ERROR";

export interface Tag {
    id: string;
    value: string;

    _links: {
        self: {
            href: string;
            type: "application/hal+json; name=mms_tag";
        };
    };
}

export interface TaggedMenuEntity {
    readonly _embedded: {
        readonly tags: Tag[];
    };

    readonly _links: {
        readonly tags: HALLink;
        readonly self: HALLink;
    };
}

export interface TagsFetch {
    type: typeof TAGS_FETCH;
    url: string;
}

interface TagsFetchPage {
    type: typeof TAGS_FETCH_SUCCESS;
    tags: Tag[];
}

interface TagsFetchSuccess {
    type: typeof TAGS_FETCH_COMPLETE;
}

interface TagsFetchError {
    type: typeof TAGS_FETCH_ERROR;
    error: Error;
}

export interface TaggedEntityUpdate {
    type: typeof TAGGED_ENTITY_UPDATE;
    entity: TaggedMenuEntity;
    tags: Tag[];
}

export interface TaggedEntityUpdateSuccess {
    type: typeof TAGGED_ENTITY_UPDATE_SUCCESS;
    entity: TaggedMenuEntity;
    tags: Tag[];
}

interface TaggedEntityUpdateError {
    type: typeof TAGGED_ENTITY_UPDATE_ERROR;
    error: Error;
}

export type TagActions =
    | TagsFetch
    | TagsFetchPage
    | TagsFetchSuccess
    | TagsFetchError
    | TaggedEntityUpdate
    | TaggedEntityUpdateSuccess
    | TaggedEntityUpdateError;

export const isTag = (entity: HALEntity): entity is Tag => {
    return entity?._links?.self?.type === "application/hal+json; name=mms_tag";
};
