import { Alert, Typography } from "@mui/material";
import ConfirmDialog from "components/common/ConfirmDialog";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { axiosErrorMessage } from "store/types";
import { TaxRate } from "store/mms/menus/location/taxes/types";
import { taxRateDelete } from "store/mms/menus/location/taxes/actions";

interface TaxRateDeleteProps {
    taxRate: TaxRate;
    onClose(): void;
    open: boolean;
}

export function LocationTaxRateDelete({ taxRate, onClose, open }: TaxRateDeleteProps): JSX.Element {
    const dispatch = useDispatch();
    const [state, setState] = useState<{ saving: boolean; error?: string }>({
        saving: false,
    });
    const name = "Location Tax Rate";

    return (
        <ConfirmDialog
            title={`Remove ${name}`}
            open={open}
            onClose={onClose}
            onConfirm={() => {
                setState({ saving: true });
                dispatch(
                    taxRateDelete(taxRate, (error?: Error): void => {
                        if (error) {
                            return setState({ saving: false, error: axiosErrorMessage(error) });
                        }
                        setState({ saving: false });
                        onClose();
                    }),
                );
            }}
            confirm={`Remove ${name}`}
            confirming={state.saving}
            maxWidth="xl"
            transitionDuration={0}
        >
            <Typography>
                Are you sure you want to remove the {name} <strong>{taxRate.name}</strong>?
            </Typography>

            <Alert variant="outlined" severity="info">
                This tax rate will no longer be used on any menu for this Location.
            </Alert>

            {state.error && (
                <Alert variant="outlined" severity="error">
                    {state.error}
                </Alert>
            )}
        </ConfirmDialog>
    );
}
