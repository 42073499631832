import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Chip, Grid, IconButton, Skeleton, Theme, Tooltip, Typography } from "@mui/material";
import DateRangePicker from "./DateRangePicker";
import FiltersDrawer from "./Filters";
import { accountFetch } from "store/account/actions";
import {
    reportsFiltersRemoveRestaurantConcept,
    reportsFiltersRemovePOSType,
    reportsFetch,
    reportsFiltersRemoveLocation,
} from "store/reports/actions";
import { RestaurantConcept, POSType } from "store/reports/types";
import { RootState } from "store/rootReducer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedoAlt } from "@fortawesome/pro-solid-svg-icons";
import { Location } from "store/locations/types";
import { makeStyles } from "theme";

const useStyle = makeStyles()((theme: Theme) => ({
    root: {
        marginBottom: theme.spacing(10),
    },
    company: {
        position: "relative",
    },
    companySkeleton: {
        position: "absolute",
    },
    controls: {
        "& > *": {
            display: "inline-block",
        },
    },
    chipContainer: {
        minHeight: 48,
    },
    chipLabel: {
        textTransform: "capitalize",
    },
}));

export default function Header(): JSX.Element {
    const { classes } = useStyle();
    const dispatch = useDispatch();
    const account = useSelector((state: RootState) => state.account);
    const appliedFilters = useSelector((state: RootState) => state.reportFilters.applied);

    useEffect(() => {
        dispatch(accountFetch());
    }, [dispatch]);

    const removeRestaurantConcept = (loc: RestaurantConcept) => {
        dispatch(reportsFiltersRemoveRestaurantConcept(loc));
    };
    const removePOSType = (posType: POSType) => {
        dispatch(reportsFiltersRemovePOSType(posType));
    };
    const removeLocation = (location: Location) => {
        dispatch(reportsFiltersRemoveLocation(location));
    };
    const refreshReport = () => {
        dispatch(reportsFetch());
    };

    return (
        <header className={classes.root}>
            <Grid container justifyContent="space-between" spacing={2}>
                <Grid item>
                    <Grid item>
                        <div className={classes.company}>
                            {account.loading && (
                                <Skeleton className={classes.companySkeleton} variant="text" width={300} height={50} />
                            )}
                            <Typography variant="h3" style={{ visibility: account.loading ? "hidden" : "visible" }}>
                                {account.company}
                            </Typography>
                        </div>
                    </Grid>
                </Grid>

                <Grid item>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            <Tooltip arrow title={<Typography variant="subtitle2">Refresh</Typography>}>
                                <IconButton onClick={refreshReport} aria-label="reload report">
                                    <FontAwesomeIcon icon={faRedoAlt} />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <FiltersDrawer />
                        </Grid>
                        <Grid item>
                            <DateRangePicker />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} className={classes.chipContainer}>
                    <Grid container spacing={2}>
                        {appliedFilters.RestaurantConcepts.map((loc) => (
                            <Grid item key={loc.id}>
                                <Chip
                                    label={loc.name}
                                    onDelete={() => removeRestaurantConcept(loc)}
                                    size="medium"
                                ></Chip>
                            </Grid>
                        ))}
                        {appliedFilters.POSTypes.map((posType) => (
                            <Grid item key={posType.id}>
                                <Chip
                                    classes={{
                                        label: classes.chipLabel,
                                    }}
                                    label={posType.name}
                                    onDelete={() => removePOSType(posType)}
                                    size="medium"
                                ></Chip>
                            </Grid>
                        ))}
                        {appliedFilters.Locations.map((location) => (
                            <Grid item key={location.id}>
                                <Chip
                                    classes={{
                                        label: classes.chipLabel,
                                    }}
                                    label={location.display_name}
                                    onDelete={() => removeLocation(location)}
                                    size="medium"
                                ></Chip>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </header>
    );
}
