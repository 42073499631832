import { Tag } from "store/mms/tags/types";
import { HALLink } from "store/types";
import { MasterItem, MasterItemResp, NewMasterItem } from "../items/types";
import { Period } from "../periods/types";
import { MasterMenu } from "../types";

export const SECTION_PAGE_FETCH = "@@ADMINMASTER/MENU/SECTION/PAGE/FETCH";
export const SECTION_PAGE_FETCH_SUCCESS = "@@ADMINMASTER/MENU/SECTION/PAGE/FETCH_SUCCESS";
export const SECTION_PAGE_FETCH_ERROR = "@@ADMINMASTER/MENU/SECTION/PAGE/FETCH_ERROR";

export const SECTION_ITEMS_SAVE = "@@ADMINMASTER/MENU/SECTION/ITEMS/SAVE";
export const SECTION_ITEMS_SAVE_SUCCESS = "@@ADMINMASTER/MENU/SECTION/ITEMS/SAVE_SUCCESS";
export const SECTION_ITEMS_SAVE_ERROR = "@@ADMINMASTER/MENU/SECTION/ITEMS/SAVE_ERROR";

export const SECTION_ITEM_EXISTING_SAVE = "@@ADMINMASTER/MENU/SECTION/ITEM/EXISTING/SAVE";
export const SECTION_ITEM_EXISTING_SAVE_SUCCESS = "@@ADMINMASTER/MENU/SECTION/ITEM/EXISTING/SAVE_SUCCESS";

export const SECTION_ITEM_NEW_SAVE = "@@ADMINMASTER/MENU/SECTION/ITEM/NEW/SAVE";

export const SECTION_ITEM_DELETE = "@@ADMINMASTER/MENU/SECTION/ITEM/DELETE";
export const SECTION_ITEM_DELETE_SUCCESS = "@@ADMINMASTER/MENU/SECTION/ITEM/DELETE_SUCCESS";
export const SECTION_ITEM_DELETE_ERROR = "@@ADMINMASTER/MENU/SECTION/ITEM/DELETE_ERROR";

export const SECTION_SAVE = "@@ADMINMASTER/MENU/SECTION/SAVE";
export const SECTION_SAVE_SUCCESS = "@@ADMINMASTER/MENU/SECTION/SAVE_SUCCESS";
export const SECTION_SAVE_ERROR = "@@ADMINMASTER/MENU/SECTION/SAVE_ERROR";

export const SECTION_DELETE = "@@ADMINMASTER/MENU/SECTION/DELETE";
export const SECTION_DELETE_SUCCESS = "@@ADMINMASTER/MENU/SECTION/DELETE_SUCCESS";
export const SECTION_DELETE_ERROR = "@@ADMINMASTER/MENU/SECTION/DELETE_ERROR";

export const SECTIONS_SAVE = "@@ADMINMASTER/MENU/SECTIONS/SAVE";
export const SECTIONS_SAVE_SUCCESS = "@@ADMINMASTER/MENU/SECTIONS/SAVE_SUCCESS";
export const SECTIONS_SAVE_ERROR = "@@ADMINMASTER/MENU/SECTIONS/SAVE_ERROR";

export interface Section {
    description: string;
    enabled: boolean;
    id: string;
    name: string;
    sequence: number;

    _embedded: {
        disabled_periods: Period[];
        items: MasterItem[];
        tags: Tag[];
    };

    _links: {
        disabled_periods: HALLink;
        items: HALLink;
        self: HALLink;
        tags: HALLink;
    };
}

export interface SectionResp extends Omit<Section, "_embedded"> {
    _embedded: {
        disabled_periods: Period[];
        items: MasterItemResp[];
        tags: Tag[];
    };
}

export type SectionReq = Omit<Section, "_embedded" | "_links">;

export function isSection(s: Section | SectionReq): s is Section {
    return Object.prototype.hasOwnProperty.call(s as Section, "_links");
}

export interface SectionPageResp {
    _embedded: {
        sections: SectionResp[];
    };
    _links: {
        next?: {
            href: string;
            type: "application/hal+json; name=mms_master_menu_section_list";
        };

        self: {
            href: string;
            type: "application/hal+json; name=mms_master_menu_section_list";
        };
    };
}

interface SectionPageFetch {
    type: typeof SECTION_PAGE_FETCH;
    url: string;
}

interface SectionPageFetchSuccess {
    type: typeof SECTION_PAGE_FETCH_SUCCESS;
    sections: Section[];
    menuId: string;
}

interface SectionPageFetchError {
    type: typeof SECTION_PAGE_FETCH_ERROR;
    error: Error;
}

interface SectionSave {
    type: typeof SECTION_SAVE;
    section: Section | SectionReq;
    url: string;
    callback?: (error?: Error) => void;
}

interface SectionSaveSuccess {
    type: typeof SECTION_SAVE_SUCCESS;
    section: Section;
    menuId: string;
}

interface SectionSaveError {
    type: typeof SECTION_SAVE_ERROR;
    error: Error;
}

interface SectionsSave {
    type: typeof SECTIONS_SAVE;
    sections: Section[];
    url: string;
}

interface SectionsSaveSuccess {
    type: typeof SECTIONS_SAVE_SUCCESS;
    sections: Section[];
    menuId: string;
}

interface SectionsSaveError {
    type: typeof SECTIONS_SAVE_ERROR;
    sections: Section[];
    menuId: string;
    error: Error;
}

interface SectionDelete {
    type: typeof SECTION_DELETE;
    section: Section;
    callback?: (error?: Error) => void;
}

interface SectionDeleteSuccess {
    type: typeof SECTION_DELETE_SUCCESS;
    section: Section;
    menuId: string;
}

interface SectionDeleteError {
    type: typeof SECTION_DELETE_ERROR;
    error: Error;
}

interface SectionItemsSave {
    type: typeof SECTION_ITEMS_SAVE;
    section: Section;
    items: MasterItem[];
}

interface SectionItemsSaveSuccess {
    type: typeof SECTION_ITEMS_SAVE_SUCCESS;
    section: Section;
    items: MasterItem[];
}

interface SectionItemsSaveError {
    type: typeof SECTION_ITEMS_SAVE_ERROR;
    error: Error;
}

interface SectionItemExistingSave {
    type: typeof SECTION_ITEM_EXISTING_SAVE;
    section: Section;
    item: MasterItem;
    callback?: (error?: Error) => void;
}

interface SectionItemExistingSaveSuccess {
    type: typeof SECTION_ITEM_EXISTING_SAVE_SUCCESS;
    section: Section;
    item: MasterItem;
}

interface SectionItemNewSave {
    type: typeof SECTION_ITEM_NEW_SAVE;
    menu: MasterMenu;
    section: Section;
    item: NewMasterItem;
    callback?: (error?: Error) => void;
}

interface SectionItemDelete {
    type: typeof SECTION_ITEM_DELETE;
    section: Section;
    item: MasterItem;
    callback?: (error?: Error) => void;
}

interface SectionItemDeleteSuccess {
    type: typeof SECTION_ITEM_DELETE_SUCCESS;
    section: Section;
    item: MasterItem;
}

interface SectionItemDeleteError {
    type: typeof SECTION_ITEM_DELETE_ERROR;
    error: Error;
}

export type SectionActions =
    | SectionPageFetch
    | SectionPageFetchSuccess
    | SectionPageFetchError
    | SectionsSave
    | SectionsSaveSuccess
    | SectionsSaveError
    | SectionItemsSave
    | SectionItemsSaveSuccess
    | SectionItemsSaveError
    | SectionItemExistingSave
    | SectionItemExistingSaveSuccess
    | SectionItemNewSave
    | SectionItemDelete
    | SectionItemDeleteSuccess
    | SectionItemDeleteError
    | SectionDelete
    | SectionDeleteSuccess
    | SectionDeleteError
    | SectionSave
    | SectionSaveSuccess
    | SectionSaveError;
