import { UseSortableArguments } from "@dnd-kit/sortable";
import { Box, Grid, Link, Typography } from "@mui/material";
import { Card } from "components/common/Card";
import { MasterMenuContext, MenuEvent } from "pages/Menus/MasterMenu/context";
import React, { RefObject, useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import { MasterItem } from "store/mms/menus/master/items/types";
import { Section } from "store/mms/menus/master/sections/types";
import { entityMasterPageLink } from "store/mms/menus/types";
import { EntityRemove } from "../IconButtons/EntityRemove";
import { ToggleDisplay } from "../IconButtons/ToggleDisplay";
export interface MasterItemCardProps extends Partial<UseSortableArguments> {
    dragActive?: boolean;
    dragOverlay?: boolean;
    item: MasterItem;
    section: Section;
    index: number;
    style?: React.CSSProperties;
}

export const MasterItemCard = React.forwardRef(function MasterItemCard(
    { dragActive, dragOverlay, item, section, style, ...rest }: MasterItemCardProps,
    ref: ((instance: HTMLDivElement | null) => void) | RefObject<HTMLDivElement> | null,
) {
    const { onChange } = useContext(MasterMenuContext);
    const disableText = !section.enabled || !item.enabled;

    return (
        <Grid
            item
            ref={ref}
            sx={(theme) => ({
                flexBasis: "50%",
                "&:focus-visible": {
                    outline: "none",
                },

                [theme.breakpoints.up("xl")]: {
                    flexBasis: "33%",
                },

                [theme.breakpoints.down("sm")]: {
                    flexBasis: "100%",
                },

                ...(dragOverlay && {
                    backgroundColor: theme.palette.background.paper,
                }),
            })}
            style={style}
            {...rest}
        >
            <Card
                className={disableText ? "disabled" : undefined}
                sx={{
                    ...(dragActive && {
                        backgroundColor: (theme) => `${theme.mixins.isDraggingBackground} !important`,

                        "& .MuiCardContent-root > a": {
                            color: (theme) => theme.palette.text.primary,
                        },
                    }),
                }}
                CardContentProps={{ sx: { cursor: "grab" } }}
                cardActions={
                    <Box
                        sx={{
                            "& > *:first-of-type": {
                                marginRight: (theme) => theme.spacing(1),
                            },
                        }}
                    >
                        <ToggleDisplay
                            hidden={!item.enabled}
                            onClick={() => onChange({ type: MenuEvent.ItemVisibility, item })}
                        />

                        <EntityRemove onClick={() => onChange({ type: MenuEvent.ItemDelete, section, item })} />
                    </Box>
                }
                CardActionsProps={{ sx: { cursor: "grab" } }}
                image={item._embedded.image?.url}
                variant="outlined"
            >
                <>
                    <Link
                        component={RouterLink}
                        to={entityMasterPageLink(item)}
                        title={item.display_name}
                        underline="always"
                    >
                        <Typography
                            sx={{
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                WebkitLineClamp: 1,
                                overflow: "hidden",
                                wordBreak: "break-word",
                            }}
                            variant="h6"
                        >
                            {item.display_name}
                        </Typography>
                    </Link>
                    {item.description && (
                        <Typography
                            sx={{
                                marginBottom: (theme) => theme.spacing(0.25),
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                WebkitLineClamp: 2,
                                overflow: "hidden",
                                wordBreak: "break-word",
                            }}
                            variant="subtitle2"
                            title={item.description}
                        >
                            {item.description}
                        </Typography>
                    )}
                </>
            </Card>
        </Grid>
    );
});
