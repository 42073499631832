import React, { ChangeEvent, FormEvent, useEffect, useReducer } from "react";
import {
    Alert,
    AlertTitle,
    Button,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    TextField,
    Theme,
    Tooltip,
    Typography,
    useTheme,
} from "@mui/material";
import { makeStyles } from "theme";
import {
    LocationOrderProfile,
    OrderProfile,
    OrderProfileAdjustment,
    OrderProfileAdjustmentStrategyTypes,
} from "store/mms/order-profiles/types";
import { formErrors, getInitialState, reducer } from "./reducer";
import POSEmployeeSearch from "components/autocomplete/POSEmployee";
import POSOrderTypeSearch from "components/autocomplete/POSOrderType";
import POSRevenueCenterSearch from "components/autocomplete/POSRevenueCenter";
import POSServiceChargeSearch from "components/autocomplete/POSServiceCharge";
import POSDiscountSearch from "components/autocomplete/POSDiscount";
import POSTenderTypeSearch from "components/autocomplete/POSTenderType";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronRight, faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { locationOrderProfileSave } from "store/mms/order-profiles/actions";
import { RootState } from "store/rootReducer";
import {
    Discount,
    Employee,
    ItemOrderMode,
    OrderType,
    RevenueCenter,
    ServiceCharge,
    TenderType,
} from "components/autocomplete/reducer";
import { Location, POSType } from "store/locations/types";
import LocationSearch from "components/autocomplete/Location";
import POSItemOrderModeSearch from "components/autocomplete/POSItemOrderMode";

interface props {
    masterProfile?: OrderProfile;
    profile?: LocationOrderProfile;
    close(): void;
}

const useStyles = makeStyles()((theme: Theme) => ({
    body: {
        padding: theme.spacing(5),
        "& > *": {
            marginBottom: theme.spacing(4),
        },
    },
    loader: {
        minHeight: 15,
    },
    radioGroup: radioGroup(theme),
    holdViaReadyTimeAddition: {
        marginTop: theme.spacing(4),
    },
    holdViaReadyTimeAdditionLabel: {
        display: "flex",
    },
    iconWrapper: {
        display: "inline-flex",
        marginLeft: theme.spacing(1),
    },
    footer: {
        display: "flex",
        marginTop: theme.spacing(2),
    },
    cancel: {
        marginLeft: theme.spacing(2),
    },
    submit: {
        position: "relative",
    },
}));

const validateProfile = (
    profile: LocationOrderProfile,
    masterAdjustment: OrderProfileAdjustment,
    enableTenderType: boolean,
    enableGuestCount: boolean,
    enableAccountNumber: boolean,
): formErrors | null => {
    const errors = {
        location: !profile.location.id,
        employee: !profile.posConfig.employeeId,
        orderType: !profile.posConfig.orderTypeId,
        revenueCenter: !profile.posConfig.revenueCenterId,
        tenderType: enableTenderType && !profile.posConfig.tenderTypeId,
        guestCount: enableGuestCount && profile.ticketConfig.guestCount === null,
        guestCountInvalid:
            enableGuestCount &&
            profile.ticketConfig.guestCount !== undefined &&
            profile.ticketConfig.guestCount !== null &&
            profile.ticketConfig.guestCount < 0,
        accountNumber: enableAccountNumber && profile.posConfig.accountNumber?.trim().length === 0,
    } as formErrors;

    if (masterAdjustment.enabled) {
        if (masterAdjustment.strategy === OrderProfileAdjustmentStrategyTypes.TicketServiceCharge) {
            errors.serviceCharge = !profile.posConfig.adjustmentId;
        } else if (masterAdjustment.strategy === OrderProfileAdjustmentStrategyTypes.TicketDiscount) {
            errors.discount = !profile.posConfig.adjustmentId;
        }
    }

    if (Object.values(errors).some((hasError) => hasError)) {
        return errors;
    }

    return null;
};
const locationSupportsItemOrderModes = (l: Location): boolean => {
    return [POSType.ALOHA].includes(l.pos_type);
};

export default function EditLocationOrderProfile(props: props): JSX.Element {
    const theme = useTheme();
    const { classes } = useStyles();
    const [state, formDispatch] = useReducer(
        reducer,
        { masterProfile: props.masterProfile, profile: props.profile },
        getInitialState,
    );
    const dispatch = useDispatch();
    const { selectedProfile: profile, saving, saveError } = useSelector((state: RootState) => state.mms.orderProfiles);
    const masterAdjustment = state.masterProfile?.adjustment;

    useEffect(() => {
        if (state.saving && !saving) {
            if (!saveError) {
                const { close } = props;
                // we tried to save, profiles store changed, and we did not get an error from the server.
                close();
            } else {
                formDispatch({ type: "SHOW_ERROR", error: saveError });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profile, saveError]);

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formErrors = validateProfile(
            state.profile,
            state.masterProfile.adjustment,
            state.enableTenderType,
            state.enableGuestCount,
            state.enableAccountNumber,
        );

        if (!formErrors) {
            formDispatch({
                type: "SAVE_PROFILE",
                save: (m: OrderProfile, l: LocationOrderProfile) => dispatch(locationOrderProfileSave(m, l)),
            });
        } else {
            formDispatch({ type: "SHOW_VALIDATION_ERRORS", formErrors });
        }
    };

    return (
        <form noValidate className={classes.body} onSubmit={handleSubmit}>
            {!state.isNew && state.profile.location.id ? (
                <Typography variant="h5">{state.profile.location.name}</Typography>
            ) : (
                <LocationSearch
                    setLocation={(location) => {
                        if (location) {
                            formDispatch({ type: "SET_LOCATION", location });
                        }
                    }}
                    error={state.formErrors?.location}
                />
            )}

            <POSEmployeeSearch
                locationId={state.profile.location.id}
                employeeId={state.profile.posConfig.employeeId}
                disableFetch={state.isNew}
                setEmployee={(e: Employee | null) => {
                    formDispatch({ type: "SET_EMPLOYEE", employeeId: e ? e.id : "" });
                }}
                error={state.formErrors?.employee}
            />

            <POSOrderTypeSearch
                locationId={state.profile.location.id}
                orderTypeId={state.profile.posConfig.orderTypeId}
                disableFetch={state.isNew}
                setOrderType={(o: OrderType | null) => {
                    formDispatch({ type: "SET_ORDER_TYPE", orderTypeId: o ? o.id : "" });
                }}
                error={state.formErrors?.orderType}
            />

            <POSRevenueCenterSearch
                locationId={state.profile.location.id}
                revenueCenterId={state.profile.posConfig.revenueCenterId}
                disableFetch={state.isNew}
                setRevenueCenter={(r: RevenueCenter | null) => {
                    formDispatch({ type: "SET_REVENUE_CENTER", revenueCenterId: r ? r.id : "" });
                }}
                error={state.formErrors?.revenueCenter}
            />

            {locationSupportsItemOrderModes(state.profile.location) && (
                <POSItemOrderModeSearch
                    locationId={state.profile.location.id}
                    itemOrderModeId={state.profile.posConfig.itemOrderModesId}
                    disableFetch={state.isNew}
                    setItemOrderMode={(i: ItemOrderMode | null) => {
                        formDispatch({ type: "SET_ITEM_ORDER_MODE", itemOrderModeId: i?.id || "" });
                    }}
                />
            )}

            {masterAdjustment &&
                masterAdjustment.enabled &&
                masterAdjustment.strategy === OrderProfileAdjustmentStrategyTypes.TicketServiceCharge && (
                    <POSServiceChargeSearch
                        locationId={state.profile.location.id}
                        serviceChargeId={state.profile.posConfig.adjustmentId}
                        disableFetch={state.isNew}
                        setServiceCharge={(s: ServiceCharge | null) => {
                            formDispatch({ type: "SET_ADJUSTMENT_ID", adjustmentId: s ? s.id : "" });
                        }}
                        error={state.formErrors?.serviceCharge}
                    />
                )}

            {masterAdjustment &&
                masterAdjustment.enabled &&
                masterAdjustment.strategy === OrderProfileAdjustmentStrategyTypes.TicketDiscount && (
                    <POSDiscountSearch
                        locationId={state.profile.location.id}
                        discountId={state.profile.posConfig.adjustmentId}
                        disableFetch={state.isNew}
                        setDiscount={(d: Discount | null) => {
                            formDispatch({ type: "SET_ADJUSTMENT_ID", adjustmentId: d ? d.id : "" });
                        }}
                        error={state.formErrors?.discount}
                    />
                )}

            <OptIn
                label="Do you want to allow payments on Tickets?"
                enable={state.enableTenderType}
                onChange={(enabled: boolean) => formDispatch({ type: "SET_TENDER_ENABLED", enabled })}
            >
                <POSTenderTypeSearch
                    locationId={state.profile.location.id}
                    tenderTypeId={state.profile.posConfig.tenderTypeId}
                    disableFetch={state.isNew}
                    setTenderType={(t: TenderType | null) => {
                        formDispatch({ type: "SET_TENDER_TYPE", tenderTypeId: t ? t.id : "" });
                    }}
                    error={state.formErrors?.tenderType}
                />
            </OptIn>

            <Typography variant="subtitle1">
                <Button
                    startIcon={<FontAwesomeIcon icon={state.showAdvanced ? faChevronDown : faChevronRight} size="sm" />}
                    onClick={() => formDispatch({ type: "SET_SHOW_ADVANCED", showAdvanced: !state.showAdvanced })}
                >
                    Advanced
                </Button>
            </Typography>

            {state.showAdvanced && (
                <>
                    <FormControl fullWidth component="fieldset" margin="dense">
                        <FormLabel color="primary">
                            Do you want to automatically send Tickets to your kitchen?
                        </FormLabel>
                        <RadioGroup
                            row
                            value={
                                state.profile.autoSend === null || state.profile.autoSend === undefined
                                    ? ""
                                    : state.profile.autoSend
                                    ? "yes"
                                    : "no"
                            }
                            defaultValue=""
                            className={classes.radioGroup}
                            onChange={(e: unknown, value: string) => {
                                let autoSend = null;
                                if (value) {
                                    autoSend = value === "yes" ? true : false;
                                }

                                formDispatch({ type: "SET_AUTO_SEND", autoSend });
                            }}
                        >
                            <FormControlLabel label="Yes" value="yes" control={<Radio />} />
                            <FormControlLabel label="No" value="no" control={<Radio />} />
                            <FormControlLabel label="Let the POS decide" value="" control={<Radio />} />
                        </RadioGroup>
                    </FormControl>

                    <FormControl fullWidth component="fieldset" margin="dense">
                        <FormLabel color="primary">Do you want to automatically close Tickets on the POS?</FormLabel>
                        <RadioGroup
                            row
                            value={
                                state.profile.autoClose === null || state.profile.autoClose === undefined
                                    ? ""
                                    : state.profile.autoClose
                                    ? "yes"
                                    : "no"
                            }
                            defaultValue=""
                            className={classes.radioGroup}
                            onChange={(e: unknown, value: string) => {
                                let autoClose = null;
                                if (value) {
                                    autoClose = value === "yes" ? true : false;
                                }

                                formDispatch({ type: "SET_AUTO_CLOSE", autoClose });
                            }}
                        >
                            <FormControlLabel label="Yes" value="yes" control={<Radio />} />
                            <FormControlLabel label="No" value="no" control={<Radio />} />
                            <FormControlLabel label="Let the POS decide" value="" control={<Radio />} />
                        </RadioGroup>
                    </FormControl>

                    <FormControl fullWidth component="fieldset" margin="dense">
                        <FormLabel color="primary">Do you want to include order ready time on Tickets?</FormLabel>
                        <RadioGroup
                            row
                            value={!state.profile.ticketConfig.omitReadyTime ? "yes" : "no"}
                            defaultValue="yes"
                            className={classes.radioGroup}
                            onChange={(e: unknown, value: string) =>
                                formDispatch({
                                    type: "SET_OMIT_READY_TIME",
                                    // omit ready time means that we _do not want to include it_.
                                    omitReadyTime: value === "no" ? true : false,
                                })
                            }
                        >
                            <FormControlLabel label="Yes" value="yes" control={<Radio />} />
                            <FormControlLabel label="No" value="no" control={<Radio />} />
                        </RadioGroup>
                    </FormControl>

                    <FormControl fullWidth>
                        <FormLabel className={classes.holdViaReadyTimeAdditionLabel} color="primary">
                            Hold POS Tickets by optionally adding up to 24-hours to Order ready time.
                            <Tooltip
                                arrow
                                title={
                                    <Typography variant="subtitle2">
                                        Use this feature if you wish to delay order ready times to prevent orders from
                                        being prepared before driver / customer arrival. Staff must manually bump each
                                        order to the kitchen.
                                    </Typography>
                                }
                            >
                                <div className={classes.iconWrapper}>
                                    <FontAwesomeIcon icon={faQuestionCircle} color={theme.palette.info.main} />
                                </div>
                            </Tooltip>
                        </FormLabel>
                        <TextField
                            select
                            className={classes.holdViaReadyTimeAddition}
                            value={state.profile.ticketConfig.holdViaReadyTimeAddition}
                            onChange={(e: React.ChangeEvent<{ name?: string; value: string }>) =>
                                formDispatch({
                                    type: "SET_HOLD_VIA_READY_TIME_ADDITION",
                                    holdViaReadyTimeAddition: parseFloat(e.target.value),
                                })
                            }
                            SelectProps={{
                                native: true,
                            }}
                            variant="outlined"
                        >
                            {[
                                { title: "0 minutes", value: 0 },
                                { title: "5 minutes", value: 5 },
                                { title: "10 minutes", value: 10 },
                                { title: "15 minutes", value: 15 },
                                { title: "20 minutes", value: 20 },
                                { title: "25 minutes", value: 25 },
                                { title: "30 minutes", value: 30 },
                                { title: "35 minutes", value: 35 },
                                { title: "40 minutes", value: 40 },
                                { title: "45 minutes", value: 45 },
                                { title: "50 minutes", value: 50 },
                                { title: "55 minutes", value: 55 },
                                { title: "1 hour", value: 60 },
                                { title: "2 hours", value: 120 },
                                { title: "3 hours", value: 180 },
                                { title: "4 hours", value: 240 },
                                { title: "5 hours", value: 300 },
                                { title: "6 hours", value: 360 },
                                { title: "7 hours", value: 420 },
                                { title: "8 hours", value: 480 },
                                { title: "9 hours", value: 540 },
                                { title: "10 hours", value: 600 },
                                { title: "11 hours", value: 660 },
                                { title: "12 hours", value: 720 },
                                { title: "13 hours", value: 780 },
                                { title: "14 hours", value: 840 },
                                { title: "15 hours", value: 900 },
                                { title: "16 hours", value: 960 },
                                { title: "17 hours", value: 1020 },
                                { title: "18 hours", value: 1080 },
                                { title: "19 hours", value: 1140 },
                                { title: "20 hours", value: 1200 },
                                { title: "21 hours", value: 1260 },
                                { title: "22 hours", value: 1320 },
                                { title: "23 hours", value: 1380 },
                                { title: "24 hours", value: 1440 },
                            ].map(({ title, value }, idx) => (
                                <option key={idx} value={value}>
                                    {title}
                                </option>
                            ))}
                        </TextField>
                    </FormControl>

                    <OptIn
                        label="Do you want to set guest count on Tickets?"
                        enable={state.enableGuestCount}
                        onChange={(enabled: boolean) => formDispatch({ type: "ENABLE_GUEST_COUNT", enabled })}
                    >
                        <TextField
                            autoFocus
                            name="guest-count"
                            defaultValue="1"
                            value={state.guestCount}
                            variant="outlined"
                            inputProps={{ min: 0 }}
                            type="number"
                            label="Guest Count"
                            onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                                formDispatch({
                                    type: "SET_GUEST_COUNT",
                                    guestCount: e.target.value,
                                })
                            }
                            error={state.formErrors?.guestCount || state.formErrors?.guestCountInvalid}
                            helperText={
                                (state.formErrors?.guestCount && "Guest count is required.") ||
                                (state.formErrors?.guestCountInvalid && "Guest count must be 0 or greater.")
                            }
                        />
                    </OptIn>

                    <OptIn
                        label="Do you want to set an account number on Tickets?"
                        enable={state.enableAccountNumber}
                        onChange={(enabled: boolean) => formDispatch({ type: "ENABLE_ACCOUNT_NUMBER", enabled })}
                    >
                        <TextField
                            autoFocus
                            label="Account Number"
                            name="account-number"
                            variant="outlined"
                            value={state.accountNumber}
                            inputProps={{
                                minLength: 1,
                                // prevent LastPass from adding itself to this field (it probably thinks that "account
                                // number" sounds login related
                                "data-lpignore": true,
                            }}
                            onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                                formDispatch({
                                    type: "SET_ACCOUNT_NUMBER",
                                    accountNumber: e.target.value,
                                })
                            }
                            error={state.formErrors?.accountNumber}
                            helperText={state.formErrors?.accountNumber && "Account Number is required."}
                        />
                    </OptIn>
                </>
            )}

            {state.saveError && (
                <Alert severity="error">
                    <AlertTitle>Save Failed</AlertTitle>
                    An error has occurred. {state.saveError.message}
                </Alert>
            )}

            <footer className={classes.footer}>
                <Button
                    className={classes.submit}
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={state.saving}
                >
                    {`${state.profile.id ? "Update" : "Create"} Location Profile`}
                </Button>
                <Button onClick={props.close} className={classes.cancel}>
                    Cancel
                </Button>
            </footer>
        </form>
    );
}

const useOptInStyles = makeStyles()((theme: Theme) => ({
    radioGroupStd: radioGroup(theme),
    radioGroupExt: {
        marginBottom: theme.spacing(2),
    },
}));

interface OptInProps {
    label: string;
    enable: boolean;
    onChange: (enable: boolean) => void;
    children: React.ReactNode;
}
function OptIn(p: OptInProps) {
    const { classes } = useOptInStyles();

    return (
        <FormControl fullWidth component="fieldset" margin="dense">
            <FormLabel color="primary">{p.label}</FormLabel>
            <RadioGroup
                row
                value={p.enable ? "yes" : "no"}
                onChange={(e: ChangeEvent<HTMLInputElement>, v: string) => p.onChange(v === "yes")}
                className={[classes.radioGroupStd, classes.radioGroupExt].join(" ")}
            >
                <FormControlLabel label="Yes" value="yes" control={<Radio />} />
                <FormControlLabel label="No" value="no" control={<Radio />} />
            </RadioGroup>
            {p.enable && p.children}
        </FormControl>
    );
}

const radioGroup = (theme: Theme) => ({
    margin: theme.spacing(2, 0, 0, 2),
});
