import React, { useCallback, useEffect, useMemo, useReducer } from "react";
import { faCheckCircle, faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { core } from "services/api";
import { AxiosResponse } from "axios";
import { AutocompleteTypes, getLabel, initState, reducer } from "./reducer";
import throttle from "lodash/throttle";
import PaperWrapper from "components/common/PaperWrapper";
import { useTheme } from "@mui/material/styles";
import Autocomplete from "@mui/material/Autocomplete";
import { CircularProgress, TextField } from "@mui/material";

type props = {
    id: string;
    entity: AutocompleteTypes | null;
    setEntity(e: AutocompleteTypes | null): void;
    inputLabel: string;
    inputPlaceholder?: string;
    noOptionsText?: string;
    error?: boolean;
    helperText?: string;
    searchUrl: string;
    searchRespMapper(resp: AxiosResponse<unknown>): AutocompleteTypes[];
    fetchUrl?: string;
    fetchRespMapper?(resp: AxiosResponse<unknown>): AutocompleteTypes;
    disableFetch?: boolean;
    disabled?: boolean;
    focused?: boolean;
};

export default function OmniComplete(props: props): JSX.Element {
    const {
        id,
        entity,
        setEntity,
        inputLabel,
        inputPlaceholder = "Search by Name or ID",
        noOptionsText = `No ${inputLabel} matches your search, try again.`,
        error,
        helperText = "Not Found",
        searchUrl,
        searchRespMapper,
        fetchUrl,
        fetchRespMapper,
        disableFetch = false,
        disabled = false,
        focused = false,
    } = props;
    const theme = useTheme();
    const [state, dispatch] = useReducer(reducer, entity, initState);
    const search = useMemo(
        () =>
            throttle(async (isActive: () => boolean, query: string) => {
                dispatch({ type: "SEARCH" });

                try {
                    const resp = await core.get(searchUrl + query);

                    if (isActive()) {
                        dispatch({
                            type: "SEARCH_SUCCESS",
                            entities: searchRespMapper(resp),
                        });
                    } else {
                        dispatch({ type: "SEARCH_CLEANUP" });
                    }
                } catch (e) {
                    if (e instanceof Error) {
                        dispatch({ type: "SEARCH_ERROR", error: e });
                    }
                }
            }, 500),
        [searchUrl, searchRespMapper],
    );
    const fetch = useCallback(() => {
        if (!fetchUrl || !fetchRespMapper) {
            return undefined;
        }

        dispatch({ type: "FETCH" });
        (async () => {
            try {
                const resp = await core.get(fetchUrl);

                dispatch({ type: "FETCH_SUCCESS", entity: fetchRespMapper(resp) });
            } catch (e) {
                if (e instanceof Error) {
                    dispatch({ type: "FETCH_ERROR", error: e });
                }
            }
        })();
    }, [fetchUrl, fetchRespMapper]);

    useEffect(() => {
        if ((!state.entity && !state.inputValue) || !state.inputValue) {
            return undefined;
        }

        // don't search after selecting the entity
        if (state.entity && getLabel(state.entity) === state.inputValue) {
            return undefined;
        }

        let active = true;
        search(() => active, state.inputValue);

        return () => {
            active = false;
        };
    }, [search, state.entity, state.inputValue]);

    useEffect(() => {
        if (disableFetch || !fetchUrl || !fetchRespMapper || state.found) {
            return undefined;
        }
        fetch();
    }, [disableFetch, fetch, fetchUrl, fetchRespMapper, state.found]);

    const showIcons = !disableFetch && !state.fetching && fetchUrl ? true : false;

    return (
        <Autocomplete
            id={id}
            autoComplete
            includeInputInList
            loading={state.searching}
            options={state.options}
            noOptionsText={noOptionsText}
            value={state.entity}
            disabled={disabled}
            PaperComponent={PaperWrapper}
            onChange={(e: unknown, entity: AutocompleteTypes | null) => {
                dispatch({ type: "SET_ENTITY", entity });
                setEntity(entity);
            }}
            onInputChange={(e, newValue) => dispatch({ type: "SET_VALUE", newValue })}
            renderInput={(params) => (
                <>
                    <TextField
                        {...params}
                        label={inputLabel}
                        variant="outlined"
                        placeholder={inputPlaceholder}
                        fullWidth
                        focused={focused}
                        error={error || (showIcons && !state.found)}
                        helperText={error || (showIcons && !state.found) ? helperText : ""}
                        InputProps={{
                            ...params.InputProps,
                            autoFocus: focused,
                            startAdornment: (
                                <>
                                    {showIcons && state.found ? (
                                        <FontAwesomeIcon
                                            icon={faCheckCircle}
                                            fixedWidth
                                            color={theme.palette.success.main}
                                        />
                                    ) : null}
                                    {showIcons && !state.found ? (
                                        <FontAwesomeIcon
                                            icon={faExclamationTriangle}
                                            fixedWidth
                                            color={theme.palette.warning.main}
                                        />
                                    ) : null}
                                    {params.InputProps.startAdornment}
                                </>
                            ),
                            endAdornment: (
                                <>
                                    {state.searching || state.fetching ? (
                                        <CircularProgress color="inherit" size={20} />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                    />
                </>
            )}
            getOptionLabel={getLabel}
        />
    );
}
