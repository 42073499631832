import React, { useEffect } from "react";
import { OmniDrawer } from "components/common/OmniDrawer";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Box, Checkbox, FormControlLabel, InputAdornment, Skeleton, TextField } from "@mui/material";
import { Autocomplete } from "mui-rff";
import { Field, Form, useForm, useFormState } from "react-final-form";
import { NewTaxRate, TaxRate } from "store/mms/menus/location/taxes/types";
import { taxRateSave } from "store/mms/menus/location/taxes/actions";
import { handleErrors } from "components/common/forms/helpers";
import { FormSubmit } from "components/common/FormSubmit";
import PaperWrapper from "components/common/PaperWrapper";
import { RootState } from "store/rootReducer";
import { Tag } from "store/mms/tags/types";
import { tagsFetch } from "store/mms/tags/actions";
import { useParams } from "react-router-dom";
import Hint from "components/common/info/Hint";

interface LocationTaxRateEditProps {
    taxRate?: TaxRate | NewTaxRate;
    onClose(): void;
    open: boolean;
}

export function LocationTaxRateEdit({ taxRate, onClose, open }: LocationTaxRateEditProps): JSX.Element {
    const dispatch = useDispatch();
    const { locationId } = useParams() as { locationId: string };
    const tagById = useSelector((state: RootState) => state.mms.tags.byId);
    const allTags = Object.values(tagById);
    const tagFetchingError = useSelector((state: RootState) => state.mms.tags.fetchingError);
    const tagIdsByTaxRateId = useSelector((state: RootState) => state.mms.menus.location.taxRates.tagIdsByTaxRateId);
    const taxRateTagIds = tagIdsByTaxRateId[taxRate?.id || ""];
    const tags = taxRateTagIds?.map((tid) => tagById[tid]) || [];
    const title = taxRate?.id === "" ? "Add" : "Edit";

    useEffect(() => {
        if (Object.keys(tagById).length === 0 && tagFetchingError === undefined) {
            dispatch(tagsFetch());
        }
    }, [dispatch, tagById, tagFetchingError]);

    return (
        <OmniDrawer close={onClose} open={open} title={`${title} Location Tax Rate`}>
            {taxRate === undefined ? (
                <Skeleton />
            ) : (
                <Form
                    onSubmit={(values, _, errorsCallback) => {
                        dispatch(
                            taxRateSave(
                                {
                                    ...taxRate,
                                    name: values.name,
                                    rate: +(values.rate * 100).toFixed(2),
                                    default: values.default,
                                    enabled: values.enabled,
                                    _embedded: {
                                        tags: values.tags,
                                    },
                                },
                                locationId,
                                (errors?: Error) => {
                                    if (errors) {
                                        return errorsCallback && errorsCallback(errors);
                                    }
                                    onClose();
                                },
                            ),
                        );
                    }}
                    initialValues={{
                        name: taxRate.name,
                        rate: +(taxRate.rate / 100).toFixed(3),
                        default: taxRate.default,
                        initiallyDefault: taxRate.default,
                        enabled: taxRate.enabled,
                        tags: tags,
                    }}
                    validate={(values) => ({
                        name: !values.name ? "Required" : undefined,
                        rate: values.rate === undefined ? "Required" : undefined,
                    })}
                >
                    {({ handleSubmit }) => (
                        <Box
                            component="form"
                            onSubmit={handleSubmit}
                            sx={{
                                "& .MuiFormControl-root": {
                                    marginBottom: (theme) => theme.spacing(4),

                                    "&:last-of-type": {
                                        marginBottom: (theme) => theme.spacing(0),
                                    },
                                },
                            }}
                        >
                            <LocationTaxRateEditForm
                                tags={allTags}
                                onClose={onClose}
                                submitLabel={`${title} Tax Rate`}
                            />
                        </Box>
                    )}
                </Form>
            )}
        </OmniDrawer>
    );
}

interface LocationTaxRateEditFormProps {
    tags: Tag[];
    onClose: () => void;
    submitLabel: string;
}

export function LocationTaxRateEditForm({ tags, onClose, submitLabel }: LocationTaxRateEditFormProps): JSX.Element {
    const { values, submitError } = useFormState();
    const { change } = useForm();

    return (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Field name="name">
                {({ input, meta }) => (
                    <TextField
                        {...input}
                        {...handleErrors(meta)}
                        label="Name"
                        variant="outlined"
                        autoFocus
                        fullWidth
                        data-lpignore
                    />
                )}
            </Field>

            <Field name="rate">
                {({ input, meta }) => (
                    <TextField
                        {...input}
                        {...handleErrors(meta)}
                        label="Rate"
                        variant="outlined"
                        type="number"
                        fullWidth
                        data-lpignore
                        InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            inputProps: {
                                min: 0,
                                max: 100,
                                step: "0.001",
                                precision: "3",
                            },
                        }}
                    />
                )}
            </Field>

            <Autocomplete
                name="tags"
                label="Tags"
                options={tags}
                getOptionLabel={(t) => t.value}
                getOptionValue={(t) => t}
                isOptionEqualToValue={(o, v) => o.id === v.id}
                renderOption={(props, t, { selected }) => (
                    <li {...props}>
                        <Checkbox sx={{ marginRight: (theme) => theme.spacing(1) }} checked={selected} />
                        {t.value}
                    </li>
                )}
                textFieldProps={{
                    variant: "outlined",
                }}
                PaperComponent={PaperWrapper}
                ChipProps={{
                    color: "primary",
                }}
                multiple
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                disableCloseOnSelect
                fullWidth
            />

            {values.initiallyDefault && (
                <Alert severity="info" sx={{ margin: (theme) => theme.spacing(5, 0, 1, 0) }}>
                    To change the default Tax Rate, set another Tax Rate as default.
                </Alert>
            )}
            <Box sx={{ marginTop: (theme) => (values.initiallyDefault ? theme.spacing(0) : theme.spacing(5)) }}>
                <Field name="default" type="checkbox">
                    {({ input: { checked, onChange, ...restInput } }) => (
                        <FormControlLabel
                            label="Default"
                            control={
                                <Checkbox
                                    {...restInput}
                                    checked={checked}
                                    disabled={values.initiallyDefault}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        onChange(event);
                                        if (event.currentTarget.checked) {
                                            change("enabled", true);
                                        }
                                    }}
                                />
                            }
                        />
                    )}
                </Field>

                <Field name="enabled" type="checkbox">
                    {({ input }) => (
                        <FormControlLabel
                            label="Enabled"
                            control={<Checkbox {...input} disabled={values.default} style={{ paddingLeft: "24px" }} />}
                        />
                    )}
                </Field>

                <Hint title="A Tax Rate set as default cannot be disabled." />
            </Box>

            {submitError && <Alert severity="error">{submitError}</Alert>}

            <FormSubmit onClose={onClose} label={submitLabel} sx={{ marginTop: (theme) => theme.spacing(5) }} />
        </Box>
    );
}
