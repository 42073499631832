import { Box, BoxProps } from "@mui/material";
import clsx from "clsx";
import React from "react";

interface TabPanelProps extends BoxProps {
    disableHiddenChildren?: boolean;
    index: number;
    value: number;
}

export default function TabPanel({
    children,
    className,
    disableHiddenChildren = false,
    value,
    index,
    ...boxRest
}: TabPanelProps): JSX.Element {
    return (
        <Box
            className={clsx("TabPanel-root", className)}
            sx={{ marginTop: (theme) => theme.spacing(2) }}
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...boxRest}
        >
            {value === index ? children : disableHiddenChildren ? null : children}
        </Box>
    );
}
