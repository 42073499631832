import {
    Block,
    Period,
    PERIOD_SAVE,
    PERIOD_BLOCKS_SAVE,
    PERIOD_PAGE_FETCH,
    isMasterMenuPeriod,
    PeriodSave,
} from "./types";
import {
    periodSaveError,
    periodPageFetch as periodPageFetchAction,
    periodPageFetchError as periodPageFetchErrorAction,
    periodPageFetchSuccess as periodPageFetchSuccessAction,
    periodBlocksSaveSuccess,
    periodSaveSuccess,
} from "./actions";
import { call, ForkEffect, put, takeLatest } from "redux-saga/effects";
import { core } from "services/api";
import { enqueueSnackbar } from "store/notifications/actions";

interface masterPeriodPageResp {
    _embedded: {
        periods: Period[];
    };
    _links: {
        next?: {
            href: string;
            type: "application/hal+json; name=mms_master_menu_period_list";
        };

        self: {
            href: string;
            type: "application/hal+json; name=mms_master_menu_period_list";
        };
    };
}

function menuIdMenuResp(r: masterPeriodPageResp | Period): string {
    return r._links.self.href.replace(/.+menus\/([^\/]*).*/, "$1");
}

function* periodPageFetch({ url }: { url: string; type: typeof PERIOD_PAGE_FETCH }) {
    try {
        const resp: { data: masterPeriodPageResp } = yield call(core.get, url);

        yield put(periodPageFetchSuccessAction(menuIdMenuResp(resp.data), resp.data._embedded.periods));
        if (resp.data._links.next !== undefined) {
            yield put(periodPageFetchAction(resp.data._links.next.href));
        }
    } catch (e) {
        console.error("Failed to fetch Master Menu Periods Page", e);
        if (e instanceof Error) {
            yield put(periodPageFetchErrorAction(e));
        }
    }
}
function* periodSave({ url, period, blocks, disabledSections, callback }: PeriodSave) {
    let periodUrl: string = isMasterMenuPeriod(period) ? period._links.self.href : "";
    try {
        const isNew = !isMasterMenuPeriod(period);

        const resp: { data: Period } = isNew
            ? yield call(core.post, url, {
                  display_name: period.display_name,
                  reference_name: period.display_name,
              })
            : yield call(core.put, `${url}/${period.id}`, {
                  id: period.id,
                  display_name: period.display_name,
                  reference_name: period.reference_name,
              });

        periodUrl = resp.data._links.self.href;
        yield call(core.post, resp.data._links.blocks.href, mapPeriodBlocks(blocks));

        // delete any old disabled sections
        if (isMasterMenuPeriod(period)) {
            for (const ds of period._embedded.disabled_sections) {
                yield call(core.delete, ds._links.self.href);
            }
        }
        // create any disabled sections
        for (const ds of disabledSections) {
            yield call(core.post, resp.data._links.disabled_sections.href, { mms_menu_section: ds });
        }

        if (callback) {
            yield call(callback);
        }
        yield put(
            enqueueSnackbar({
                message: `Successfully updated Master Menu Period, ${period.display_name}.`,
                options: {
                    variant: "success",
                },
            }),
        );
    } catch (e) {
        console.error("Failed to edit Master Menu Period", e);
        if (e instanceof Error) {
            if (callback !== undefined) {
                yield call(callback, e);
            }
            yield put(periodSaveError(e));
        }
    } finally {
        if (periodUrl) {
            const resp: { data: Period } = yield call(core.get, periodUrl);

            yield put(periodSaveSuccess(resp.data, menuIdMenuResp(resp.data)));
        }
    }
}

function mapPeriodBlocks(blocks: Block[]): Omit<Block, "_links" | "_embedded">[] {
    return blocks.map((b) => ({
        id: b.id,
        day: b.day,
        duration: b.duration,
        eod_buffer: b.eod_buffer,
        start: b.start,
        start_buffer: b.start_buffer,
    }));
}

function* periodBlocksSave({
    url,
    period,
    blocks,
    callback,
}: {
    url: string;
    type: typeof PERIOD_BLOCKS_SAVE;
    period: Period;
    blocks: Block[];
    callback?: (error?: Error) => void;
}) {
    try {
        const resp: { data: Period } = yield call(core.post, url, mapPeriodBlocks(blocks));

        yield put(periodBlocksSaveSuccess(period, resp.data._embedded.blocks, menuIdMenuResp(period)));

        if (callback !== undefined) {
            yield call(callback);
        }
    } catch (e) {
        console.error("Failed to update Master Menu Period Blocks", e);
        if (e instanceof Error) {
            if (callback !== undefined) {
                yield call(callback, e);
            }
        }
    }
}

export default function* periodsSaga(): Generator<ForkEffect<void>, void, unknown> {
    yield takeLatest(PERIOD_PAGE_FETCH, periodPageFetch);
    yield takeLatest(PERIOD_SAVE, periodSave);
    yield takeLatest(PERIOD_BLOCKS_SAVE, periodBlocksSave);
}
