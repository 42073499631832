import {
    TaxRate,
    TaxRateActions,
    TAX_RATES_FETCH,
    TAX_RATES_FETCH_COMPLETE,
    TAX_RATES_FETCH_SUCCESS,
    TAX_RATES_FETCH_ERROR,
    TAX_RATES_SAVE,
    TAX_RATES_SAVE_SUCCESS,
    TAX_RATES_SAVE_ERROR,
    TAX_RATE_SAVE,
    TAX_RATE_SAVE_SUCCESS,
    TAX_RATE_SAVE_ERROR,
    TAX_RATE_DELETE,
    TAX_RATE_DELETE_SUCCESS,
    TAX_RATE_DELETE_ERROR,
    NewTaxRate,
} from "./types";

export function taxRatesFetch(url: string): TaxRateActions {
    return {
        type: TAX_RATES_FETCH,
        url,
    };
}

export function taxRatesFetchSuccess(locationId: string, taxRates: TaxRate[]): TaxRateActions {
    return { type: TAX_RATES_FETCH_SUCCESS, locationId, taxRates };
}

export function taxRatesFetchComplete(): TaxRateActions {
    return { type: TAX_RATES_FETCH_COMPLETE };
}

export function taxRatesFetchError(error: Error): TaxRateActions {
    return {
        type: TAX_RATES_FETCH_ERROR,
        error,
    };
}

export function taxRatesSave(taxRates: TaxRate[], url: string): TaxRateActions {
    return {
        type: TAX_RATES_SAVE,
        taxRates,
        url,
    };
}

export function taxRatesSaveSuccess(locationId: string, taxRates: TaxRate[]): TaxRateActions {
    return {
        type: TAX_RATES_SAVE_SUCCESS,
        locationId,
        taxRates,
    };
}

export function taxRatesSaveError(error: Error, taxRates: TaxRate[]): TaxRateActions {
    return {
        type: TAX_RATES_SAVE_ERROR,
        error,
        taxRates,
    };
}

export function taxRateSave(
    taxRate: TaxRate | NewTaxRate,
    locationId: string,
    callback?: (error?: Error) => void,
): TaxRateActions {
    return {
        type: TAX_RATE_SAVE,
        taxRate,
        locationId,
        callback,
    };
}

export function taxRateSaveSuccess(locationId: string, taxRate: TaxRate): TaxRateActions {
    return {
        type: TAX_RATE_SAVE_SUCCESS,
        locationId,
        taxRate,
    };
}

export function taxRateSaveError(error: Error): TaxRateActions {
    return {
        type: TAX_RATE_SAVE_ERROR,
        error,
    };
}

export function taxRateDelete(taxRate: TaxRate, callback?: (error?: Error) => void): TaxRateActions {
    return {
        type: TAX_RATE_DELETE,
        taxRate,
        callback,
    };
}

export function taxRateDeleteSuccess(locationId: string, taxRate: TaxRate): TaxRateActions {
    return {
        type: TAX_RATE_DELETE_SUCCESS,
        locationId,
        taxRate,
    };
}

export function taxRateDeleteError(error: Error): TaxRateActions {
    return {
        type: TAX_RATE_DELETE_ERROR,
        error,
    };
}
