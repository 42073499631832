import { LocationModifier, ModifierStrategy } from "store/mms/menus/location/items/modifiers/types";
import { ItemStrategy, LocationItem } from "store/mms/menus/location/items/types";
import { MenuEntity } from "store/mms/menus/types";
import {
    ComboPOSConfig,
    getEntityPOSConfig,
    ItemPOSConfig,
    ModifierPOSConfig,
    POSCombo,
    POSConfig,
    POSConfigKey,
    POSItem,
    POSModifier,
    PriceLevel,
} from "store/mms/types";

interface strategyOption {
    strategy: ItemStrategy | ModifierStrategy;
    title: string;
}

export const getStrategyOptions = (isModifier: boolean, includeComboModifiers: boolean): strategyOption[] => {
    const options: strategyOption[] = [];

    if (isModifier) {
        options.push(
            { strategy: ModifierStrategy.Modify, title: "Modify" },
            { strategy: ModifierStrategy.ReplaceParent, title: "Replace Parent" },
            { strategy: ModifierStrategy.AddItem, title: "Add Item" },
        );

        if (includeComboModifiers) {
            options.push(
                { strategy: ModifierStrategy.AddComboItem, title: "Add Combo Item" },
                { strategy: ModifierStrategy.ModifyComboItem, title: "Modify Embedded Combo Item" },
            );
        }
    } else {
        options.push(
            { strategy: ItemStrategy.AddItem, title: "Add Item" },
            { strategy: ItemStrategy.AddCombo, title: "Add Combo" },
        );
    }

    return options;
};

export const entityIsModifier = (e: LocationItem | LocationModifier): e is LocationModifier => {
    return (e as LocationModifier)._links.self.type === "application/hal+json; name=adminlocation_menu_modifier";
};

export const entityIsCombo = (c?: ComboPOSConfig | ItemPOSConfig | ModifierPOSConfig): c is ComboPOSConfig => {
    return (c as ComboPOSConfig)?.item_groups !== undefined;
};

export const posConfigTypeIsCombo = (p?: POSCombo | POSItem | POSModifier): p is POSCombo => {
    return (p as POSCombo)?._links?.self?.type === "application/hal+json; name=mms_pos_combo";
};

export const getConcreteLinkable = (traversal: MenuEntity[], startAtParent = true): MenuEntity => {
    let stratIndex = Math.max(0, startAtParent ? traversal.length - 2 : traversal.length - 1);
    let linkable = traversal[stratIndex] || {
        id: "",
        name: "",
        strategy: ItemStrategy.AddItem,
        pos_entity_type: POSConfigKey.Item,
        pathname: "",
    };

    while (linkable.strategy === ModifierStrategy.ReplaceParent && stratIndex > 0) {
        linkable = traversal[--stratIndex];
    }

    return linkable;
};

export const getEntityBaseUrl = (locationUrl: string, posEntityType: POSConfigKey): string => {
    return `${locationUrl}/mms/pos/${posEntityType.valueOf()}s`;
};

export const getEntityType = (
    strategy: ItemStrategy | ModifierStrategy,
    replaceParentStrategy: () => ItemStrategy | ModifierStrategy,
): POSConfigKey => {
    switch (strategy) {
        case ModifierStrategy.ReplaceParent:
            return getEntityType(replaceParentStrategy(), replaceParentStrategy);
        case ModifierStrategy.Modify:
            return POSConfigKey.Modifier;
        case ModifierStrategy.AddItem:
        case ItemStrategy.AddItem:
            return POSConfigKey.Item;
        case ItemStrategy.AddCombo:
        case ModifierStrategy.AddComboItem:
        case ModifierStrategy.ModifyComboItem:
            return POSConfigKey.ComboItem;
        default:
            return POSConfigKey.Item;
    }
};

export const getPriceLevelId = (posConfig: POSConfig | null): string => {
    if (!posConfig) {
        return "";
    }

    const priceLevelIdOrEmptyString = (p: PriceLevel | null | undefined): string => {
        if (p === undefined || p === null || p.id === null) {
            return "";
        }
        return p.id;
    };

    if (posConfig.combo !== undefined) {
        return priceLevelIdOrEmptyString(posConfig.combo.price_level);
    } else if (posConfig.item !== undefined) {
        return priceLevelIdOrEmptyString(posConfig.item.price_level);
    } else if (posConfig.modifier !== undefined) {
        return priceLevelIdOrEmptyString(posConfig.modifier.price_level);
    }

    return "";
};

export const getEntityPriceLevel = (posConfig: POSConfig | null): PriceLevel | null => {
    const priceLevelOrNull = (p: PriceLevel | null | undefined): PriceLevel | null => {
        if (p === undefined || p === null) {
            return null;
        } else {
            return p;
        }
    };
    const c = getEntityPOSConfig(posConfig);

    if (c !== undefined) {
        return priceLevelOrNull(c.price_level);
    } else {
        return null;
    }
};

export const getPriceLevels = (posConfig: POSConfig | null): PriceLevel[] | null => {
    const priceLevelOrNull = (p: PriceLevel | null | undefined): PriceLevel[] | null => {
        if (p === undefined || p === null) {
            return null;
        } else {
            return [p];
        }
    };
    const c = getEntityPOSConfig(posConfig);

    if (c !== undefined) {
        return priceLevelOrNull(c.price_level);
    } else {
        return null;
    }
};

export const hasPriceLevels = (p?: POSItem | POSModifier | POSCombo): boolean => {
    return p ? p?._embedded?.price_levels?.length > 0 : false;
};

export const mapItemGroupEmbeddedMods = (c: ComboPOSConfig): ModifierPOSConfig[] => {
    const mods: ModifierPOSConfig[] = [];

    c.item_groups.forEach((ig) =>
        ig.items.forEach((i) => {
            if (i.modifiers !== null) {
                mods.push(...i.modifiers);
            }
        }),
    );

    return mods;
};
