import { Tag } from "store/mms/tags/types";
import { HALLink } from "store/types";
import { LocationItem } from "../items/types";
import { Period } from "../periods/types";

export const SECTION_PAGE_FETCH = "@@ADMINLOCATION/MENU/SECTION/PAGE/FETCH";
export const SECTION_PAGE_FETCH_SUCCESS = "@@ADMINLOCATION/MENU/SECTION/PAGE/FETCH_SUCCESS";
export const SECTION_PAGE_FETCH_ERROR = "@@ADMINLOCATION/MENU/SECTION/PAGE/FETCH_ERROR";

export interface Section {
    description: string;
    enabled: boolean;
    id: string;
    name: string;
    tags: Tag[];
    _embedded: {
        disabled_periods: Omit<Period, "_links">[];
        items: LocationItem[];
    };
    _links: { self: HALLink };
}

export interface SectionPageResp {
    _embedded: {
        sections: Section[];
    };
    _links: {
        next?: {
            href: string;
            type: "application/hal+json; name=adminlocation_menu_section_list";
        };

        self: {
            href: string;
            type: "application/hal+json; name=adminlocation_menu_section_list";
        };
    };
}

interface SectionPageFetch {
    type: typeof SECTION_PAGE_FETCH;
    url: string;
}

interface SectionPageFetchSuccess {
    type: typeof SECTION_PAGE_FETCH_SUCCESS;
    menuUrl: string;
    sections: Section[];
}

interface SectionPageFetchError {
    type: typeof SECTION_PAGE_FETCH_ERROR;
    error: Error;
}

export type SectionActions = SectionPageFetch | SectionPageFetchSuccess | SectionPageFetchError;
