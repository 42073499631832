import React, { ReactNode } from "react";
import { Clear as ClearIcon, Search as SearchIcon } from "@mui/icons-material";
import { Box, IconButton, TextField, TextFieldProps } from "@mui/material";
import { GridToolbarExport } from "@mui/x-data-grid";

interface QuickSearchToolbarProps {
    clearSearch: () => void;
    onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> | undefined;
    value: string;
    textFieldProps?: Partial<TextFieldProps>;
    title?: ReactNode;
    enableExports?: boolean;
}

export function QuickSearchToolbar({
    clearSearch,
    onChange,
    value,
    textFieldProps,
    title,
    enableExports = false,
}: QuickSearchToolbarProps): JSX.Element {
    return (
        <Box
            sx={{
                padding: (theme) => theme.spacing(0.5, 0.5, 0),
                display: "flex",
                alignItems: "flex-start",
                justifyContent: title ? "space-between" : "flex-end",

                "& .MuiInputBase-input": {
                    height: "37px",
                    padding: 0,
                },
            }}
        >
            {title}
            <Box display="flex" flexDirection="column" alignItems="right">
                <TextField
                    name="search"
                    variant="outlined"
                    value={value}
                    onChange={onChange}
                    placeholder="Search..."
                    InputProps={{
                        startAdornment: <SearchIcon fontSize="small" />,
                        endAdornment: (
                            <IconButton
                                title="Clear"
                                aria-label="Clear"
                                size="small"
                                style={{ visibility: value ? "visible" : "hidden" }}
                                onClick={clearSearch}
                            >
                                <ClearIcon fontSize="small" />
                            </IconButton>
                        ),
                    }}
                    {...textFieldProps}
                />
                {enableExports && (
                    <GridToolbarExport
                        sx={{
                            width: "min-content",
                            margin: (theme) => theme.spacing(1, 0, 1, "auto"),
                        }}
                    />
                )}
            </Box>
        </Box>
    );
}
