import React, { useEffect } from "react";
import {
    Typography,
    Alert,
    Skeleton,
    Checkbox,
    ListItemText,
    ListItem,
    ListItemButton,
    ListItemIcon,
    Box,
    Divider,
    Tooltip,
    Chip,
} from "@mui/material";
import { MasterMenu } from "store/mms/menus/master/types";
import ConfirmDialog from "components/common/ConfirmDialog";
import { locationMenuPageFetch } from "store/mms/menus/location/actions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/rootReducer";
import pluralize from "pluralize";
import PaperWrapper from "components/common/PaperWrapper";
import { Autocomplete } from "mui-rff";
import { Form } from "react-final-form";
import { FormSubmit } from "components/common/FormSubmit";
import { mapToFormErrors } from "components/common/forms/helpers";
import { Location } from "store/locations/types";
import { applicationActionMenuSync } from "store/mms/application-actions/actions";

interface LocationMenuSyncProps {
    location: Location;
    open: boolean;
    onClose?(): void;
}

export function LocationMenuSync({ location, open, onClose }: LocationMenuSyncProps): JSX.Element {
    const dispatch = useDispatch();

    const menusFetching = useSelector((state: RootState) => state.mms.menus.location.menus.fetching);
    const menusByLocationId = useSelector(
        (state: RootState) => state.mms.menus.location.menus.byLocationId[location.id],
    );
    const locationMenusById = useSelector((state: RootState) => state.mms.menus.location.menus.byId);
    const locationMenus = (menusByLocationId || []).map((m) => locationMenusById[m]);

    const isSaving = useSelector((state: RootState) => state.mms.applicationActions.saving);

    useEffect(() => {
        if (location && open) {
            dispatch(locationMenuPageFetch(`${location._links.self.href}mms/menus`));
        }
    }, [dispatch, location, open]);

    const isLoading = location === undefined || !locationMenusById || menusFetching;

    return (
        <ConfirmDialog
            hideActions
            title="Sync one or more Menus"
            open={open}
            onClose={onClose}
            maxWidth="xl"
            transitionDuration={0}
            sx={{
                "& .MuiDialog-paper": {
                    minWidth: "565px",
                },
            }}
        >
            <section>
                {isLoading ? (
                    <MenuSyncSkeleton />
                ) : (
                    <>
                        <Typography>Location</Typography>
                        <Typography
                            sx={(theme) => ({
                                marginBottom: theme.spacing(4),
                                fontWeight: theme.typography.fontWeightBold,
                            })}
                        >
                            {`${location?.display_name} (${location?.id})`}
                        </Typography>
                        <Form
                            initialValues={{
                                menus: locationMenus.filter((m) => m._embedded.applications.length > 0),
                            }}
                            onSubmit={(values, _, errorsCallback) => {
                                if (location) {
                                    dispatch(
                                        applicationActionMenuSync(
                                            {
                                                menus: values.menus.map((m: MasterMenu) => m.id),
                                                locations: [location.id],
                                                applications: [],
                                            },
                                            (errors?: Error) => {
                                                if (errors) {
                                                    return errorsCallback && errorsCallback(mapToFormErrors(errors));
                                                }
                                                onClose && onClose();
                                            },
                                        ),
                                    );
                                }
                            }}
                        >
                            {({ values, handleSubmit, submitError }) => (
                                <Box onSubmit={handleSubmit} component="form">
                                    <Autocomplete
                                        sx={{ maxWidth: "500px" }}
                                        name="menus"
                                        label="Menus"
                                        options={locationMenus}
                                        getOptionDisabled={(menu) => menu._embedded.applications.length === 0}
                                        getOptionLabel={(menu) => menu.name}
                                        getOptionValue={(menu) => menu}
                                        isOptionEqualToValue={(o, v) => o.id === v.id}
                                        renderOption={(props, menu, { selected }) => {
                                            const labelId = `checkbox-list-label-${menu.id}`;
                                            const noApplications = menu._embedded.applications.length === 0;

                                            const checkbox = (
                                                <ListItem sx={{ width: "100%" }} disablePadding {...props}>
                                                    <ListItemButton>
                                                        <ListItemIcon>
                                                            <Checkbox
                                                                checked={selected}
                                                                tabIndex={-1}
                                                                disableRipple
                                                                inputProps={{ "aria-labelledby": labelId }}
                                                            />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            id={labelId}
                                                            primary={`${menu.name} (${menu.id})`}
                                                        />
                                                    </ListItemButton>
                                                </ListItem>
                                            );
                                            return noApplications ? (
                                                <Tooltip
                                                    key={menu.id}
                                                    arrow
                                                    title={
                                                        <Typography variant="subtitle2">
                                                            No Applications have been onboarded to this Location Menu.
                                                            Please contact support if you would like to onboard any
                                                            Applications.
                                                        </Typography>
                                                    }
                                                >
                                                    <Box key={menu.id}>{checkbox}</Box>
                                                </Tooltip>
                                            ) : (
                                                checkbox
                                            );
                                        }}
                                        renderTags={(menus, getTagProps) =>
                                            menus.map((menu, index) => (
                                                <Chip
                                                    color="primary"
                                                    label={`${menu.name} (${menu.id})`}
                                                    {...getTagProps({ index })}
                                                    key={menu.id}
                                                />
                                            ))
                                        }
                                        textFieldProps={{
                                            variant: "outlined",
                                        }}
                                        PaperComponent={PaperWrapper}
                                        ChipProps={{
                                            color: "primary",
                                        }}
                                        multiple
                                        selectOnFocus
                                        clearOnBlur
                                        handleHomeEndKeys
                                        disableCloseOnSelect
                                        fullWidth
                                    />
                                    {submitError && (
                                        <Alert variant="outlined" severity="error">
                                            {submitError}
                                        </Alert>
                                    )}
                                    <Divider sx={(theme) => ({ margin: theme.spacing(3, -3) })} />
                                    <FormSubmit
                                        disableSubmit={values.menus.length === 0 || isSaving}
                                        sx={(theme) => ({
                                            marginRight: theme.spacing(0),
                                            marginLeft: "auto",
                                            width: "max-content",
                                        })}
                                        alignItems="right"
                                        label={pluralize("Sync Menu", values.menus.length)}
                                        onClose={() => onClose && onClose()}
                                    />
                                </Box>
                            )}
                        </Form>
                    </>
                )}
            </section>
        </ConfirmDialog>
    );
}

const MenuSyncSkeleton = () => (
    <Box>
        <Skeleton width={60} />
        <Skeleton width={100} sx={{ marginBottom: (theme) => theme.spacing() }} />
        <Skeleton height={80} />
        <Divider sx={(theme) => ({ margin: theme.spacing(1, -3) })} />{" "}
        <Box
            display="flex"
            flexDirection="row"
            alignItems="right"
            sx={{ marginRight: 0, marginLeft: "auto", width: "max-content" }}
        >
            <Skeleton height={60} width={115} />
            <Skeleton sx={{ marginLeft: (theme) => theme.spacing(2.5) }} width={70} />
        </Box>
    </Box>
);
