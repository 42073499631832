import { HALLink, SaveAction } from "store/types";
import { MasterEntity, MasterEntityActions } from "../../types";
import { Period as LocationPeriod } from "../../location/periods/types";

export const PERIOD_PAGE_FETCH = "@@ADMINMASTER/MENU/MASTER/PERIOD/PAGE/FETCH";
export const PERIOD_PAGE_FETCH_SUCCESS = "@@ADMINMASTER/MENU/MASTER/PERIOD/PAGE/FETCH_SUCCESS";
export const PERIOD_PAGE_FETCH_ERROR = "@@ADMINMASTER/MENU/MASTER/PERIOD/PAGE/FETCH_ERROR";

export const PERIOD_SAVE = "@@ADMINMASTER/MENU/MASTER/PERIOD/EDIT";
export const PERIOD_SAVE_SUCCESS = "@@ADMINMASTER/MENU/MASTER/PERIOD/EDIT_SUCCESS";
export const PERIOD_SAVE_ERROR = "@@ADMINMASTER/MENU/MASTER/PERIOD/EDIT_ERROR";

export const PERIOD_BLOCKS_SAVE = "@@ADMINMASTER/MENU/MASTER/PERIOD/BLOCKS/SAVE";
export const PERIOD_BLOCKS_SAVE_SUCCESS = "@@ADMINMASTER/MENU/MASTER/PERIOD/BLOCKS/SAVE_SUCCESS";
export const PERIOD_BLOCKS_SAVE_ERROR = "@@ADMINMASTER/MENU/MASTER/PERIOD/BLOCKS/SAVE_ERROR";

export const MAX_DURATION = 1440;
export const MAX_GRACE_PERIOD = 60;
export const DEFAULT_GRACE_PERIOD = 45;
export const MAX_START_BUFFER = 90;
export const DEFAULT_START_BUFFER = 0;

export enum DayOfWeek {
    Sunday = "sunday",
    Monday = "monday",
    Tuesday = "tuesday",
    Wednesday = "wednesday",
    Thursday = "thursday",
    Friday = "friday",
    Saturday = "saturday",
}

export interface Block {
    day: DayOfWeek;
    duration: number;
    eod_buffer: number;
    id?: string;
    start: string;
    start_buffer: number;
}

export type NewPeriod = Pick<Period, "id" | "display_name" | "reference_name" | "mms_menu">;

export interface Period {
    display_name: string;
    id: string;
    mms_menu: string;
    reference_name: string;

    _embedded: {
        blocks: Block[];
        disabled_sections: DisabledSection[];
    };

    _links: {
        blocks: {
            href: string;
            type: "application/hal+json; name=mms_master_menu_period_block_list";
        };

        disabled_sections: HALLink;

        self: {
            href: string;
            type: "application/hal+json; name=mms_master_menu_period";
        };
    };
}

export interface DisabledSection {
    id: string;
    mms_menu_period: string;
    mms_menu_section: string;

    _links: {
        self: {
            href: string;
            type: "application/hal+json; name=mms_master_menu_period_disabled_section";
        };
    };
}

export interface PeriodPageFetch {
    type: typeof PERIOD_PAGE_FETCH;
    url: string;
}

interface PeriodPageFetchSuccess {
    type: typeof PERIOD_PAGE_FETCH_SUCCESS;
    periods: Period[];
    menuId: string;
}

interface PeriodPageFetchError {
    type: typeof PERIOD_PAGE_FETCH_ERROR;
    error: Error;
}

export interface PeriodSave extends SaveAction {
    type: typeof PERIOD_SAVE;
    period: Period | NewPeriod;
    blocks: Block[];
    disabledSections: string[];
    url: string;
}

interface PeriodSaveSuccess {
    type: typeof PERIOD_SAVE_SUCCESS;
    period: Period;
    menuId: string;
}

interface PeriodSaveError {
    type: typeof PERIOD_SAVE_ERROR;
    error: Error;
}

interface PeriodBlocksSave {
    type: typeof PERIOD_BLOCKS_SAVE;
    period: Period;
    blocks: Block[];
    url: string;
    callback?: (error?: Error) => void;
}

interface PeriodBlocksSaveSuccess {
    type: typeof PERIOD_BLOCKS_SAVE_SUCCESS;
    period: Period;
    blocks: Block[];
    menuId: string;
}

interface PeriodBlocksSaveError {
    type: typeof PERIOD_BLOCKS_SAVE_ERROR;
    error: Error;
}

export type PeriodActions =
    | PeriodPageFetch
    | PeriodPageFetchSuccess
    | PeriodPageFetchError
    | PeriodSave
    | PeriodSaveSuccess
    | PeriodSaveError
    | PeriodBlocksSave
    | PeriodBlocksSaveSuccess
    | PeriodBlocksSaveError
    | MasterEntityActions;

export function isMasterMenuPeriod(entity: MasterEntity | NewPeriod | LocationPeriod): entity is Period {
    return (entity as Period)?._links?.self?.type === "application/hal+json; name=mms_master_menu_period";
}
