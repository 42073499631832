import React from "react";
import { getSession } from "services/session";
import { OrderType } from "./reducer";
import { AxiosResponse } from "axios";
import OmniComplete from "./OmniComplete";

interface props {
    orderTypeId?: string;
    locationId: string;
    setOrderType(o: OrderType | null): void;
    error?: boolean;
    errorText?: string;
    disableFetch?: boolean;
}

interface orderTypeResponse {
    id: string;
    name: string;
    available: boolean;
    pos_id: string;
}

const mapToDomain = (o: orderTypeResponse): OrderType => ({
    id: o.id,
    available: o.available,
    name: o.name,
    posId: o.pos_id,
    kind: "order_type",
});

export default function POSOrderTypeSearch(props: props): JSX.Element {
    const s = getSession();
    const { orderTypeId, disableFetch, locationId, error, errorText = "Required", setOrderType } = props;

    return (
        <OmniComplete
            id="order-type-id"
            entity={orderTypeId ? ({ id: orderTypeId } as OrderType) : null}
            error={error}
            helperText={error ? errorText : "Not Found on POS"}
            setEntity={setOrderType}
            inputLabel="Order Type"
            disabled={!locationId}
            disableFetch={disableFetch}
            searchUrl={`/1.1/accounts/${s?.accountId}/locations/${locationId}/mms/pos/order_types/?search=`}
            searchRespMapper={(
                resp: AxiosResponse<{
                    _embedded: {
                        order_types: orderTypeResponse[];
                    };
                }>,
            ) => resp.data._embedded.order_types.map(mapToDomain)}
            fetchUrl={
                orderTypeId &&
                `/1.1/accounts/${s?.accountId}/locations/${locationId}/mms/pos/order_types/${orderTypeId}`
            }
            fetchRespMapper={(resp: AxiosResponse<orderTypeResponse>) => mapToDomain(resp.data)}
        />
    );
}
