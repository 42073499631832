import { HALEntity, HALLink, ListRoute, SaveAction } from "store/types";

export const MENU_FETCH = "@@ADMINMASTER/MENU/FETCH";
export const MENU_FETCH_SUCCESS = "@@ADMINMASTER/MENU/FETCH_SUCCESS";
export const MENU_FETCH_ERROR = "@@ADMINMASTER/MENU/FETCH_ERROR";

export const MENU_PAGE_FETCH = "@@ADMINMASTER/MENU/PAGE/FETCH";
export const MENU_PAGE_FETCH_SUCCESS = "@@ADMINMASTER/MENU/PAGE/FETCH_SUCCESS";
export const MENU_PAGE_FETCH_ERROR = "@@ADMINMASTER/MENU/PAGE/FETCH_ERROR";

export const MENU_ADD = "@@ADMINMASTER/MENU/CREATE";
export const MENU_ADD_SUCCESS = "@@ADMINMASTER/MENU/CREATE/SUCCESS";

export const MENU_EDIT = "@@ADMINMASTER/MENU/EDIT";
export const MENU_EDIT_SUCCESS = "@@ADMINMASTER/MENU/EDIT/SUCCESS";

// https://github.com/omnivore/giganto/blob/e3a1dfab4323d9a2ca8bb63509839a86236af88e/om/backend/internal/models/mms_menu.go
export enum MenuType {
    Delivery = "delivery",
    DineIn = "dine-in",
}

export type MasterMenuUpdate = Pick<MasterMenu, "id" | "name" | "type">;

export interface MasterMenu {
    id: string;
    name: string;
    type: MenuType;

    _links: {
        items: HALLink;
        layers: HALLink;
        locations: {
            href: string;
            type: "application/hal+json; name=mms_location_menu_list";
        };

        periods: HALLink;
        sections: HALLink;

        self: {
            href: string;
            type: "application/hal+json; name=mms_master_menu";
        };

        source_location: HALLink;
    };
}

export interface MasterMenuPageResp extends ListRoute {
    _embedded: {
        menus: MasterMenu[];
    };

    _links: {
        next?: {
            href: string;
            type: "application/hal+json; name=mms_master_menu_list";
        };

        self: {
            href: string;
            type: "application/hal+json; name=mms_master_menu_list";
        };
    };
}

interface MenuFetch {
    type: typeof MENU_FETCH;
    url: string;
}
interface MenuFetchSuccess {
    type: typeof MENU_FETCH_SUCCESS;
    menu: MasterMenu;
}
interface MenuFetchError {
    type: typeof MENU_FETCH_ERROR;
    error: Error;
}

interface MenuPageFetch {
    type: typeof MENU_PAGE_FETCH;
    url?: string;
}
interface MenuPageFetchSuccess {
    type: typeof MENU_PAGE_FETCH_SUCCESS;
    menus: MasterMenu[];
}
interface MenuPageFetchError {
    type: typeof MENU_PAGE_FETCH_ERROR;
    error: Error;
}

export interface MenuAddReq {
    name: string;
    type: MenuType;
    source_location: string;
    restaurant_concept: string;
}
export interface MenuAdd extends SaveAction {
    type: typeof MENU_ADD;
    url: string;
    menu: MenuAddReq;
}

interface MenuAddSuccess {
    type: typeof MENU_ADD_SUCCESS;
    menu: MasterMenu;
}

interface MenuEdit extends SaveAction {
    type: typeof MENU_EDIT;
    url: string;
    menu: MasterMenuUpdate;
}

interface MenuEditSuccess {
    type: typeof MENU_EDIT_SUCCESS;
    changedMenu: MasterMenu;
}

export type MasterMenuActions =
    | MenuFetch
    | MenuFetchSuccess
    | MenuFetchError
    | MenuPageFetch
    | MenuPageFetchSuccess
    | MenuPageFetchError
    | MenuAdd
    | MenuAddSuccess
    | MenuEdit
    | MenuEditSuccess;

export function isMasterMenu(entity: HALEntity): entity is MasterMenu {
    return entity._links.self.type === "application/hal+json; name=mms_master_menu";
}
