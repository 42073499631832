import { Job, JobActions, JOB_CREATE, JOB_CREATE_ERROR } from "./types";

export function jobCreate(job: Job, url: string): JobActions {
    return {
        job,
        url,
        type: JOB_CREATE,
    };
}

export function jobCreateError(error: Error): JobActions {
    return {
        error,
        type: JOB_CREATE_ERROR,
    };
}
