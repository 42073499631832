import React, { useEffect, useState } from "react";
import { Alert, AlertTitle, Skeleton, Grid, Typography, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useParams } from "react-router-dom";
import { orderProfileFetch } from "store/mms/order-profiles/actions";
import { RootState } from "store/rootReducer";
import {
    OrderProfile as MasterOrderProfile,
    OrderProfileAdjustmentTypes,
    OrderProfileAdjustmentStrategyTypes,
    OrderProfileTypes,
} from "store/mms/order-profiles/types";
import EditOrderProfile from "components/mms/OrderProfiles/EditOrderProfile";
import LocationOrderProfileList from "components/mms/OrderProfiles/Location/LocationOrderProfileList";
import { OmniDrawer } from "components/common/OmniDrawer";
import DefaultLayout from "components/common/layouts/DefaultLayout";
import { ActionButton } from "components/common/buttons/ActionButton";

export default function OrderProfile(): JSX.Element {
    const { id: profileId } = useParams() as { id: string };
    const dispatch = useDispatch();
    const { fetching, selectedProfile, fetchError: error } = useSelector((state: RootState) => state.mms.orderProfiles);
    const [editMasterProfile, setEditMasterProfile] = useState<{ profile: MasterOrderProfile | undefined }>({
        profile: undefined,
    });
    const isLoading = fetching && !error && selectedProfile?.id !== profileId;

    useEffect(() => {
        if (!fetching && !error && selectedProfile?.id !== profileId) {
            dispatch(orderProfileFetch(profileId));
        }
    }, [dispatch, fetching, profileId, selectedProfile, error]);

    return (
        <DefaultLayout
            breadcrumbs={[
                <RouterLink key="order-profiles" to="/order-profiles">
                    Order Profiles
                </RouterLink>,
                <Typography key="order-profile" color="textPrimary">
                    {isLoading ? <Skeleton width={100} /> : `${selectedProfile?.name} (${selectedProfile?.id})`}
                </Typography>,
            ]}
            header={
                <Grid container justifyContent="space-between" sx={{ marginTop: (theme) => theme.spacing(2) }}>
                    <Grid item>
                        <Typography variant="h5" sx={{ marginBottom: (theme) => theme.spacing(4) }}>
                            {isLoading ? (
                                <Skeleton width={210} />
                            ) : (
                                `Order Profile - ${selectedProfile?.id} - ${selectedProfile?.type}`
                            )}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="subtitle1">
                            {isLoading ? (
                                <Skeleton width={125} />
                            ) : (
                                <ActionButton
                                    label="Edit Settings"
                                    onClick={() => setEditMasterProfile({ profile: selectedProfile })}
                                />
                            )}
                        </Typography>
                    </Grid>
                </Grid>
            }
            aside={<Aside isLoading={isLoading} profile={selectedProfile} />}
        >
            {error ? (
                <Alert severity="error">
                    <AlertTitle>Loading Error</AlertTitle>
                    An error has occurred.
                </Alert>
            ) : (
                <LocationOrderProfileList profileId={profileId} />
            )}
            <OmniDrawer
                open={editMasterProfile.profile !== undefined}
                close={() => setEditMasterProfile({ profile: undefined })}
                title="Update Order Profile"
            >
                {editMasterProfile.profile !== undefined && (
                    <EditOrderProfile
                        profile={editMasterProfile.profile}
                        close={() => setEditMasterProfile({ profile: undefined })}
                    />
                )}
            </OmniDrawer>
        </DefaultLayout>
    );
}

function priceAdjustmentText(profile: MasterOrderProfile): string {
    if (!profile.adjustment.enabled) {
        return "";
    }

    const amount = parseFloat((profile.adjustment.amount as unknown as string) || "0") / 100;
    const formatedAdjustment =
        profile.adjustment.type === OrderProfileAdjustmentTypes.Dollar ? `$${amount.toFixed(2)}` : `${amount}%`;

    if (profile.adjustment.strategy === OrderProfileAdjustmentStrategyTypes.TicketDiscount) {
        if (!profile.adjustment.type) {
            return "A closed Discount configured on the POS applied to all Tickets.";
        }

        return `${formatedAdjustment} Discount applied to all Tickets.`;
    }

    return `${formatedAdjustment} uplift applied to all Menu Items.`;
}

function priceAdjustment(profile: MasterOrderProfile) {
    return (
        <>
            <Typography component="dt" variant="body1">
                Price Adjustment:
            </Typography>
            <Typography component="dd" variant="body1">
                {priceAdjustmentText(profile)}
            </Typography>

            {upliftTicketText(profile)}
        </>
    );
}
function upliftTicketText(profile: MasterOrderProfile) {
    let upliftText;

    if (profile.adjustment.strategy === OrderProfileAdjustmentStrategyTypes.TicketServiceCharge) {
        upliftText = "Broken out as a Service Charge.";
    } else if (profile.adjustment.strategy === OrderProfileAdjustmentStrategyTypes.ForcePriceAll) {
        upliftText = "Included in the price of Items.";
    }

    return (
        upliftText && (
            <>
                <Typography component="dt" variant="body1">
                    Uplift on Ticket:
                </Typography>
                <Typography component="dd" variant="body1">
                    {upliftText}
                </Typography>
            </>
        )
    );
}

const profileTypeDisplayText = (type: OrderProfileTypes): string => {
    let displayText = "";
    switch (type) {
        case OrderProfileTypes.Delivery:
            displayText = "Delivery";
            break;
        case OrderProfileTypes.DineIn:
            displayText = "Dine-In";
            break;
        case OrderProfileTypes.Pickup:
            displayText = "Pickup";
            break;
    }
    return displayText;
};

interface AsideProps {
    isLoading: boolean;
    profile?: MasterOrderProfile;
}

function Aside({
    isLoading,
    profile = {
        name: "",
        type: OrderProfileTypes.Delivery,
        locations: 0,
        adjustment: {
            enabled: false,
        },
        locationOrderProfiles: [],
        _links: {
            self: {
                href: "",
                type: "application/hal+json; name=mms_master_order_profile",
            },
        },
    },
}: AsideProps): JSX.Element {
    return (
        <Box sx={{ marginLeft: (theme) => theme.spacing(3) }}>
            <Typography variant="h5">Details</Typography>
            <Box
                component="dl"
                sx={{
                    display: "flex",
                    flexDirection: "column",

                    "& > dt": {
                        color: "text.disabled",
                    },

                    "& > dd": {
                        marginBottom: (theme) => theme.spacing(1.5),
                    },
                }}
            >
                <Typography component="dt" variant="body1">
                    {isLoading ? <Skeleton width={120} /> : "Name:"}
                </Typography>
                <Typography component="dd" variant="body1">
                    {isLoading ? <Skeleton width={330} /> : `${profile.name} (${profile.id})`}
                </Typography>

                <Typography component="dt" variant="body1">
                    {isLoading ? <Skeleton width={120} /> : "Profile Type:"}
                </Typography>
                <Typography component="dd" variant="body1">
                    {isLoading ? <Skeleton width={330} /> : profileTypeDisplayText(profile.type)}
                </Typography>

                {!isLoading && profile.adjustment.enabled && priceAdjustment(profile)}
            </Box>
        </Box>
    );
}
