import React, { useState } from "react";
import { Alert, Typography } from "@mui/material";
import { axiosErrorMessage } from "store/types";
import { LocationMenuApplication } from "store/mms/menus/location/applications/types";
import { useDispatch } from "react-redux";
import ConfirmDialog from "components/common/ConfirmDialog";
import { dateTimePMStringFormatter } from "components/common/utils";
import { ApplicationActionEvent } from "store/mms/application-actions/types";
import { applicationActionOrderingEnableDelete } from "store/mms/application-actions/actions";

interface ApplicationScheduledEventConfirmDeleteDialogProps {
    application: LocationMenuApplication;
    scheduledEvent: ApplicationActionEvent;
    onClose: () => void;
}

export default function ApplicationScheduledEventConfirmDeleteDialog({
    application,
    scheduledEvent,
    onClose,
}: ApplicationScheduledEventConfirmDeleteDialogProps): JSX.Element {
    const [state, setState] = useState<{ saving: boolean; error?: string }>({ saving: false });
    const dispatch = useDispatch();
    const handleDelete = () => {
        setState({ saving: true });
        dispatch(
            applicationActionOrderingEnableDelete(scheduledEvent._links.action.href, (error?: Error) => {
                if (error) {
                    return setState({
                        saving: false,
                        error: axiosErrorMessage(error).replace(
                            "Target adminmaster_application_action not found.",
                            "The scheduled change could not be found.",
                        ),
                    });
                }
                setState({ saving: false });
                onClose();
            }),
        );
    };

    return (
        <ConfirmDialog
            title="Confirm Delete Scheduled Change"
            open={true}
            onConfirm={handleDelete}
            onClose={onClose}
            confirm="Delete"
            confirming={state.saving}
            maxWidth="xl"
            transitionDuration={0}
        >
            <Typography>
                Are you sure you wish to delete the scheduled change to {scheduledEvent.enabled ? "enable" : "disable"}{" "}
                {application.name} at {dateTimePMStringFormatter(new Date((scheduledEvent.start_time || 0) * 1000))}?
            </Typography>
            {state.error && (
                <Alert variant="outlined" severity="error">
                    {state.error}
                </Alert>
            )}
        </ConfirmDialog>
    );
}
