import { SaveAction } from "store/types";

export const LOCATION_MENU_APPLICATION_SAVE = "@@ADMINLOCATION/MENU/APPLICATION/SAVE";
export const LOCATION_MENU_APPLICATION_SAVE_SUCCESS = "@@ADMINLOCATION/MENU/APPLICATION/SAVE_SUCCESS";
export const LOCATION_MENU_APPLICATION_SAVE_ERROR = "@@ADMINLOCATION/MENU/APPLICATION/SAVE_ERROR";

export const LOCATION_MENU_APPLICATION_CHANGE_FETCH = "@@ADMINLOCATION/MENU/APPLICATION/CHANGES/FETCH";
export const LOCATION_MENU_APPLICATION_CHANGE_FETCH_SUCCESS = "@@ADMINLOCATION/MENU/APPLICATION/CHANGES/FETCH_SUCCESS";

export const LOCATION_MENU_APPLICATION_SCHEDULED_EVENT_SAVE = "@@ADMINLOCATION/MENU/APPLICATION/SCHEDULED_EVENT/SAVE";
export const LOCATION_MENU_APPLICATION_SCHEDULED_EVENT_SAVE_SUCCESS =
    "@@ADMINLOCATION/MENU/APPLICATION/SCHEDULED_EVENT/SAVE_SUCCESS";
export const LOCATION_MENU_APPLICATION_SCHEDULED_EVENT_SAVE_ERROR =
    "@@ADMINLOCATION/MENU/APPLICATION/SCHEDULED_EVENT/SAVE_ERROR";

export const LOCATION_MENU_APPLICATION_SCHEDULED_EVENT_DELETE =
    "@@ADMINLOCATION/MENU/APPLICATION/SCHEDULED_EVENT/DELETE";
export const LOCATION_MENU_APPLICATION_SCHEDULED_EVENT_DELETE_SUCCESS =
    "@@ADMINLOCATION/MENU/APPLICATION/SCHEDULED_EVENT/DELETE_SUCCESS";
export const LOCATION_MENU_APPLICATION_SCHEDULED_EVENT_DELETE_ERROR =
    "@@ADMINLOCATION/MENU/APPLICATION/SCHEDULED_EVENT/DELETE_ERROR";

export const LOCATION_MENU_APPLICATION_SCHEDULED_EVENT_FETCH =
    "@@ADMINLOCATION/MENU/APPLICATION/SCHEDULED_EVENT/CHANGES/FETCH";
export const LOCATION_MENU_APPLICATION_SCHEDULED_EVENT_FETCH_SUCCESS =
    "@@ADMINLOCATION/MENU/APPLICATION/SCHEDULED_EVENT/CHANGES/FETCH_SUCCESS";

export interface LocationMenuApplication {
    id: string;
    name: string;
    logo_url: string;
    actual_enabled: boolean | null;
    expected_enabled: boolean;
    last_synced: string;
    _embedded: {
        last_event: LocationMenuApplicationChange | null;
        next_event: LocationMenuApplicationScheduledEvent | null;
    };
    _links: {
        self: {
            href: string;
            type: "application/hal+json; name=adminlocation_menu_application";
        };
        events: {
            href: string;
            type: "application/hal+json; name=adminlocation_menu_application_event_list";
        };
        scheduled_events: {
            href: string;
            type: "application/hal+json; name=adminlocation_menu_application_scheduled_event_list";
        };
    };
}

export interface LocationMenuApplicationChange {
    created: number;
    modified: number;
    user: string;
    enabled: boolean;
    status: string;
    menu: string;
    location: string;
    id: string;
    started: number;
    _links: {
        self: {
            href: string;
            type: "application/hal+json; name=adminlocation_menu_application_event";
        };
    };
}

interface LocationMenuApplicationSave {
    type: typeof LOCATION_MENU_APPLICATION_SAVE;
    application: LocationMenuApplication;
}

interface LocationMenuApplicationSaveSuccess {
    type: typeof LOCATION_MENU_APPLICATION_SAVE_SUCCESS;
    application: LocationMenuApplication;
    changeEvent: LocationMenuApplicationChange;
}

interface LocationMenuApplicationSaveError {
    type: typeof LOCATION_MENU_APPLICATION_SAVE_ERROR;
    error: Error;
}

interface LocationMenuApplicationChangePageFetch {
    type: typeof LOCATION_MENU_APPLICATION_CHANGE_FETCH;
    url: string;
}

interface LocationMenuApplicationChangePageFetchSuccess {
    type: typeof LOCATION_MENU_APPLICATION_CHANGE_FETCH_SUCCESS;
    changeEvents: LocationMenuApplicationChange[];
    href: string;
}

export interface LocationMenuApplicationScheduledEvent {
    created: number;
    modified: number;
    user: string;
    enabled: boolean;
    status: string;
    menu: string;
    location: string;
    id: string;
    start_time: number;
    _links: {
        self: {
            href: string;
            type: "application/hal+json; name=adminlocation_menu_application_scheduled_event";
        };
    };
}

export type NewLocationMenuApplicationScheduledEvent = Pick<
    LocationMenuApplicationScheduledEvent,
    "enabled" | "start_time"
>;

interface LocationMenuApplicationScheduledEventSave extends SaveAction {
    type: typeof LOCATION_MENU_APPLICATION_SCHEDULED_EVENT_SAVE;
    application: LocationMenuApplication;
    scheduledEvent: LocationMenuApplicationScheduledEvent | NewLocationMenuApplicationScheduledEvent;
}

interface LocationMenuApplicationScheduledEventSaveSuccess {
    type: typeof LOCATION_MENU_APPLICATION_SCHEDULED_EVENT_SAVE_SUCCESS;
    application: LocationMenuApplication;
    scheduledEvent: LocationMenuApplicationScheduledEvent;
}

interface LocationMenuApplicationScheduledEventSaveError {
    type: typeof LOCATION_MENU_APPLICATION_SCHEDULED_EVENT_SAVE_ERROR;
    error: Error;
}

interface LocationMenuApplicationScheduledEventDelete extends SaveAction {
    type: typeof LOCATION_MENU_APPLICATION_SCHEDULED_EVENT_DELETE;
    scheduledEvent: LocationMenuApplicationScheduledEvent;
}

interface LocationMenuApplicationScheduledEventDeleteSuccess {
    type: typeof LOCATION_MENU_APPLICATION_SCHEDULED_EVENT_DELETE_SUCCESS;
    scheduledEvent: LocationMenuApplicationScheduledEvent;
}

interface LocationMenuApplicationScheduledEventDeleteError {
    type: typeof LOCATION_MENU_APPLICATION_SCHEDULED_EVENT_DELETE_ERROR;
    error: Error;
}

interface LocationMenuApplicationScheduledEventChangePageFetch {
    type: typeof LOCATION_MENU_APPLICATION_SCHEDULED_EVENT_FETCH;
    url: string;
}

interface LocationMenuApplicationScheduledEventChangePageFetchSuccess {
    type: typeof LOCATION_MENU_APPLICATION_SCHEDULED_EVENT_FETCH_SUCCESS;
    scheduledEvents: LocationMenuApplicationScheduledEvent[];
    href: string;
}

export type LocationMenuApplicationActions =
    | LocationMenuApplicationSave
    | LocationMenuApplicationSaveSuccess
    | LocationMenuApplicationSaveError
    | LocationMenuApplicationChangePageFetch
    | LocationMenuApplicationChangePageFetchSuccess
    | LocationMenuApplicationScheduledEventSave
    | LocationMenuApplicationScheduledEventSaveSuccess
    | LocationMenuApplicationScheduledEventSaveError
    | LocationMenuApplicationScheduledEventDelete
    | LocationMenuApplicationScheduledEventDeleteSuccess
    | LocationMenuApplicationScheduledEventDeleteError
    | LocationMenuApplicationScheduledEventChangePageFetch
    | LocationMenuApplicationScheduledEventChangePageFetchSuccess;
