import {
    IconButton,
    IconButtonProps,
    MenuItem,
    MenuList,
    MenuListProps,
    Popover,
    PopoverProps,
    Tooltip,
    TooltipProps,
} from "@mui/material";
import React, { ReactNode, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/pro-light-svg-icons";
import { makeStyles } from "theme";

const useStyles = makeStyles()(() => ({
    item: {
        "& > *": {
            flexGrow: 1,
        },
    },
}));

interface ActionsGroupProps {
    children: ReactNode;
    iconButtonProps?: Partial<Omit<IconButtonProps, "onClick">>;
    menuListProps?: Partial<MenuListProps>;
    popoverProps?: Partial<
        Omit<PopoverProps, "id" | "open" | "anchorEl" | "onClose" | "anchorOrigin" | "transformOrigin">
    >;
    tooltipProps?: Partial<TooltipProps>;
}

export default function ActionsGroup({
    children,
    iconButtonProps,
    menuListProps,
    popoverProps,
    tooltipProps,
}: ActionsGroupProps): JSX.Element {
    const { classes } = useStyles();
    const [anchorEl, setAnchor] = useState<(EventTarget & HTMLButtonElement) | null>(null);
    const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => setAnchor(e.currentTarget);
    const handleClose = () => setAnchor(null);
    const open = Boolean(anchorEl);
    const id = open ? "actions-group" : undefined;
    const title = tooltipProps?.title || "Actions";

    return (
        <>
            <Tooltip title={title} {...tooltipProps}>
                <IconButton aria-describedby={id} onClick={handleClick} {...iconButtonProps}>
                    <FontAwesomeIcon size="xs" icon={faBars} />
                </IconButton>
            </Tooltip>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "center",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                {...popoverProps}
            >
                <MenuList {...menuListProps}>
                    {React.Children.toArray(children).map((child, index) => (
                        <MenuItem className={classes.item} key={index} onClick={handleClose}>
                            {child}
                        </MenuItem>
                    ))}
                </MenuList>
            </Popover>
        </>
    );
}
