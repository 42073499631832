import { Button } from "@mui/material";
import { SnackbarKey, useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeSnackbar as closeSnackbarAction, removeSnackbar } from "store/notifications/actions";
import { RootState } from "store/rootReducer";

let displayed: SnackbarKey[] = [];

export default function Notifier(): JSX.Element {
    const dispatch = useDispatch();
    const notifications = useSelector((state: RootState) => state.app.notifications);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const closeAction = (key: SnackbarKey): JSX.Element => (
        <Button onClick={() => dispatch(closeSnackbarAction(key))}>Dismiss</Button>
    );

    const storeDisplayed = (id: SnackbarKey) => {
        displayed = [...displayed, id];
    };

    const removeDisplayed = (id: SnackbarKey) => {
        displayed = [...displayed.filter((key: SnackbarKey) => id !== key)];
    };

    useEffect(() => {
        notifications.forEach(({ key, message, options, dismissed = false }) => {
            if (dismissed) {
                // dismiss snackbar using notistack
                closeSnackbar(key);
                return;
            }

            // do nothing if snackbar is already displayed
            if (displayed.includes(key)) {
                return;
            }
            // display snackbar using notistack
            enqueueSnackbar(message, {
                key,
                ...options,
                action: (key) => closeAction(key),
                onClose: (event, reason, myKey) => {
                    if (options.onClose) {
                        options.onClose(event, reason, myKey);
                    }
                },
                onExited: (e, myKey) => {
                    // remove this snackbar from redux store
                    dispatch(removeSnackbar(myKey));
                    removeDisplayed(myKey);
                },
            });

            // keep track of snackbars that we've displayed
            storeDisplayed(key);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);

    return <></>;
}
