import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import RestaurantConcepts from "./RestaurantConcepts";
import POSTypes from "./POSTypes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/pro-solid-svg-icons";
import { reportsFiltersFetch } from "store/reports/actions";
import LocationSearch from "./LocationSearch";
import { makeStyles } from "theme";
import { Clear as ClearIcon } from "@mui/icons-material";
import { ClickAwayListener, Drawer, IconButton, List, Theme, Tooltip, Typography } from "@mui/material";

const useStyles = makeStyles()((theme: Theme) => ({
    list: {
        width: "auto",
    },
    fullList: {
        width: "auto",
    },
    drawerPaper: {
        width: "30%",
    },
    drawerHeader: {
        ...{ ...theme.mixins.toolbar, "@font-face": undefined },
        display: "flex",
        alignItems: "center",
        backgroundColor: theme.palette.grey["A400"],
        padding: theme.spacing(1, 3),
        // necessary for content to be below app bar
        justifyContent: "space-between",
        color: theme.palette.getContrastText(theme.palette.grey["A400"]),
    },
    headerCloseIcon: {
        color: theme.palette.getContrastText(theme.palette.grey["A400"]),
    },
    drawerBody: {
        padding: theme.spacing(5),
    },
    locationSearch: {
        padding: theme.spacing(0, 0, 3, 0),
    },
}));

export default function FiltersDrawer(): JSX.Element {
    const { classes } = useStyles();
    const [isOpen, setIsOpen] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(reportsFiltersFetch());
    }, [dispatch]);

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === "keydown" &&
            ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")
        ) {
            return;
        }

        setIsOpen(open);
    };

    return (
        <ClickAwayListener onClickAway={() => setIsOpen(false)}>
            <div>
                <Tooltip arrow title={<Typography variant="subtitle2">Filter</Typography>}>
                    <IconButton onClick={toggleDrawer(!isOpen)} aria-label="open filters">
                        <FontAwesomeIcon icon={faFilter} />
                    </IconButton>
                </Tooltip>
                <Drawer
                    anchor="right"
                    open={isOpen}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    PaperProps={{
                        elevation: 16,
                    }}
                >
                    <div className={classes.drawerHeader}>
                        <Typography variant="h6">Filters</Typography>
                        <IconButton onClick={toggleDrawer(false)} className={classes.headerCloseIcon}>
                            <ClearIcon fontSize="small" />
                        </IconButton>
                    </div>
                    <div className={classes.drawerBody}>
                        <div className={classes.locationSearch}>
                            <LocationSearch />
                        </div>
                        <List className={classes.list}>
                            <RestaurantConcepts />
                        </List>
                        <List className={classes.list}>
                            <POSTypes />
                        </List>
                    </div>
                </Drawer>
            </div>
        </ClickAwayListener>
    );
}
