import { Container, Theme, Typography } from "@mui/material";
import { makeStyles } from "theme";
import React, { ReactNode } from "react";

interface SidebarProps {
    children: ReactNode | ReactNode[];
}
export function Sidebar({ children }: SidebarProps): JSX.Element {
    return <aside>{children}</aside>;
}

interface SidebarSectionProps {
    children: ReactNode[];
}
export function SidebarSection({ children }: SidebarSectionProps): JSX.Element {
    const { classes } = useSectionStyle();

    return <Container className={classes.root}>{children}</Container>;
}
const useSectionStyle = makeStyles()((theme: Theme) => ({
    root: {
        padding: theme.spacing(0, 0, 3, 3),
        "& > hr": {
            marginBottom: theme.spacing(4),
        },
    },
}));

interface SidebarHeaderProps {
    children: string | ReactNode | ReactNode[];
}
export function SidebarHeader({ children }: SidebarHeaderProps): JSX.Element {
    const { classes } = useHeaderStyle();
    let header;

    if (typeof children === "string") {
        header = (
            <header>
                <Typography variant="subtitle2" title="entity-details">
                    {children}
                </Typography>
            </header>
        );
    } else {
        header = <header>{children}</header>;
    }

    return (
        <>
            {header}
            <hr className={classes.hr} />
        </>
    );
}
const useHeaderStyle = makeStyles()((theme: Theme) => ({
    hr: {
        marginBottom: theme.spacing(4),
    },
}));

interface SidebarContentProps {
    children: ReactNode | ReactNode[];
}
export function SidebarContent({ children }: SidebarContentProps): JSX.Element {
    // This component is just a hedge.  It just wraps in a fragment for now, but we're identifying this as a content area
    // now so that we can eaily expand upon this in the future without having to go around to every sidebar and wrap the
    // content later.
    return <>{children}</>;
}
