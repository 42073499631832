import { ENTITY_DELETE_SUCCESS } from "../../types";
import {
    isMenuLayerRuleSet,
    RULE_SET_ADD_SUCCESS,
    RULE_SET_MIGRATE_LOCATIONS_ADD_SUCCESS,
    RULE_SET_MIGRATE_LOCATIONS_DELETE_SUCCESS,
    RULE_SET_SAVE_SUCCESS,
} from "./rule-sets/types";
import {
    isMenuLayer,
    LAYERS_PAGE_FETCH,
    LAYERS_PAGE_FETCH_ERROR,
    LAYERS_PAGE_FETCH_SUCCESS,
    LAYERS_SAVE_SUCCESS,
    LAYER_ADD_SUCCESS,
    MenuLayer,
    MenuLayerActions,
} from "./types";

interface LayersState {
    readonly byId: { [id: string]: MenuLayer };
    readonly layerIdsByMenuId: { [menuId: string]: string[] };
    readonly fetching: boolean;
    readonly fetchingError?: Error;
}

const initialState: LayersState = {
    byId: {},
    layerIdsByMenuId: {},
    fetching: false,
};

export default function layersReducer(state = initialState, action: MenuLayerActions): LayersState {
    switch (action.type) {
        case LAYERS_PAGE_FETCH:
            return {
                ...state,
                fetching: true,
            };

        case LAYERS_PAGE_FETCH_SUCCESS:
        case LAYERS_SAVE_SUCCESS:
            const layerIds = action.layers.map((l) => l.id);
            return {
                ...state,
                byId: {
                    ...state.byId,
                    ...action.layers.reduce((allLayers: { [id: string]: MenuLayer }, l) => {
                        allLayers[l.id] = l;
                        return allLayers;
                    }, {}),
                },
                layerIdsByMenuId: {
                    ...state.layerIdsByMenuId,
                    [action.menuId]: [
                        ...(state.layerIdsByMenuId[action.menuId] || []).filter((id) => !layerIds.includes(id)),
                        ...layerIds,
                    ],
                },
                fetching: false,
            };

        case LAYERS_PAGE_FETCH_ERROR:
            return {
                ...state,
                fetching: false,
                fetchingError: action.error,
            };

        case LAYER_ADD_SUCCESS:
        case RULE_SET_MIGRATE_LOCATIONS_ADD_SUCCESS:
        case RULE_SET_MIGRATE_LOCATIONS_DELETE_SUCCESS:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.layer.id]: action.layer,
                },
                layerIdsByMenuId: {
                    ...state.layerIdsByMenuId,
                    [action.menuId]: [
                        ...(state.layerIdsByMenuId[action.menuId] || []).filter((id) => id !== action.layer.id),
                        action.layer.id,
                    ],
                },
            };

        case RULE_SET_ADD_SUCCESS:
        case RULE_SET_SAVE_SUCCESS:
            const ruleSets = [...state.byId[action.layerId]._embedded.rule_sets];
            const existingRuleSetIndex = ruleSets.findIndex((rs) => rs.id === action.ruleSet.id);

            if (existingRuleSetIndex === -1) {
                ruleSets.push(action.ruleSet);
            } else {
                ruleSets[existingRuleSetIndex] = action.ruleSet;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.layerId]: {
                        ...state.byId[action.layerId],
                        _embedded: {
                            ...state.byId[action.layerId]._embedded,
                            rule_sets: ruleSets,
                        },
                    },
                },
            };

        case ENTITY_DELETE_SUCCESS:
            if (isMenuLayer(action.entity)) {
                return {
                    ...state,
                    byId: {
                        ...Object.values(state.byId).reduce((allLayers: { [id: string]: MenuLayer }, l) => {
                            if (l.id !== action.entity.id) {
                                allLayers[l.id] = l;
                            }
                            return allLayers;
                        }, {}),
                    },
                    layerIdsByMenuId: {
                        ...Object.entries(state.layerIdsByMenuId).reduce(
                            (allIds: { [menuId: string]: string[] }, [menuId, layerIds]) => {
                                allIds[menuId] = layerIds.filter((id) => id !== action.entity.id);
                                return allIds;
                            },
                            {},
                        ),
                    },
                };
            } else if (isMenuLayerRuleSet(action.entity)) {
                return {
                    ...state,
                    byId: {
                        ...Object.values(state.byId).reduce((allLayers: { [id: string]: MenuLayer }, l) => {
                            allLayers[l.id] = {
                                ...l,
                                _embedded: {
                                    ...l._embedded,
                                    rule_sets: l._embedded.rule_sets.filter((rs) => rs.id !== action.entity.id),
                                },
                            };
                            return allLayers;
                        }, {}),
                    },
                };
            }

            return state;

        default:
            return state;
    }
}
