import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sectionPageFetch } from "store/mms/menus/master/sections/actions";
import { RootState } from "store/rootReducer";
import { MenuFilter } from "../MenuFilter";
import { MasterMenuContext, MenuEvent } from "pages/Menus/MasterMenu/context";
import { ZeroState } from "../ZeroState";
import { Skeleton } from "@mui/material";
import { MasterMenuSectionsList } from "./MasterMenuSectionsList";
import { Theme } from "@mui/material";
import { makeStyles } from "theme";

const useMasterMenuSectionsStyles = makeStyles()((theme: Theme) => ({
    menuFilter: {
        marginBottom: theme.spacing(1),
    },
}));

export function MasterMenuSections(): JSX.Element {
    const { menu, onChange } = useContext(MasterMenuContext);
    const { classes } = useMasterMenuSectionsStyles();
    const dispatch = useDispatch();
    const sectionIdsByMenuId = useSelector((state: RootState) => state.mms.menus.master.sections.sectionIdsByMenuId);
    const sectionsById = useSelector((state: RootState) => state.mms.menus.master.sections.byId);
    const sectionFetching = useSelector((state: RootState) => state.mms.menus.master.sections.fetching);
    const isLoading = sectionFetching || menu === undefined || sectionIdsByMenuId[menu.id] === undefined;

    const noSections =
        !isLoading && sectionIdsByMenuId[menu.id] !== undefined && sectionIdsByMenuId[menu.id].length === 0;

    useEffect(() => {
        if (menu === undefined || sectionIdsByMenuId[menu.id] !== undefined) {
            return;
        }
        dispatch(sectionPageFetch(menu._links.sections.href));
    }, [dispatch, menu, sectionIdsByMenuId]);

    return isLoading ? (
        <Skeleton />
    ) : noSections ? (
        <ZeroState
            createLabel="Add Category"
            title="Create a Category to get started."
            onClick={() => onChange({ type: MenuEvent.SectionAdd })}
        />
    ) : (
        <MenuFilter className={classes.menuFilter}>
            <MasterMenuSectionsList sections={sectionIdsByMenuId[menu.id].map((id) => sectionsById[id])} />
        </MenuFilter>
    );
}
