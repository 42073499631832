import { Theme } from "@mui/material";
import { makeStyles } from "theme";

export const useStyle = makeStyles()((theme: Theme) => ({
    skeleton: {
        margin: theme.spacing(-3.5, 0, -1),
    },
    autocomplete: {
        marginBottom: theme.spacing(3),
    },
    chip: {
        margin: theme.spacing(0, 1, 1, 0),
    },
}));
