import { Grid, LinearProgress, Typography, Box } from "@mui/material";
import { DataGrid, GridCellParams, GridOverlay } from "@mui/x-data-grid";
import React, { useEffect, useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { MasterMenu } from "store/mms/menus/master/types";
import { menuAdd } from "store/mms/menus/master/actions";
import { RestaurantConcept } from "store/account/restaurant_concepts/types";
import { restaurantConceptsFetch } from "store/account/restaurant_concepts/actions";
import { RootState } from "store/rootReducer";
import { OmniDrawer } from "components/common/OmniDrawer";
import { MasterMenuAdd } from "../../components/mms/MasterMenuAdd";
import { MasterMenuDelete } from "components/mms/MasterMenuDelete";
import { ListPerPageOptions } from "components/props";
import { ZeroState } from "components/mms/ZeroState";
import { QuickSearchToolbar } from "components/common/forms/QuickSearchToolbar";
import { search } from "components/common/forms/helpers";
import { debounce } from "lodash";
import DefaultLayout from "components/common/layouts/DefaultLayout";
import { EntityRemove } from "components/mms/IconButtons/EntityRemove";
import { ActionButton } from "components/common/buttons/ActionButton";

function ConnectedMenusList({
    fetchingMenus,
    menusById = {},
    menuFetchRequests = 0,
    fetchingConcepts,
    conceptsById = {},
    conceptFetchRequests = 0,
}: MenusListProps): JSX.Element {
    const dispatch = useDispatch();
    const [deleteMenu, setDeleteMenu] = useState<{ menu: MasterMenu } | undefined>(undefined);
    const [searchText, setSearchText] = useState("");

    const navigate = useNavigate();

    useEffect(() => {
        dispatch(restaurantConceptsFetch());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [createMasterMenu, toggleCreateMasterMenu] = useState<boolean>(window.location.pathname.includes("create"));

    const menus: MasterMenu[] = useMemo(() => Object.values(menusById), [menusById]);
    const [rows, setRows] = useState(menus);
    const debouncedSearch = useMemo(
        () =>
            debounce((query: string) => {
                search(query, menus, setRows);
            }, 300),
        [menus],
    );

    useEffect(() => {
        setRows(menus);
    }, [menus]);

    const concepts: RestaurantConcept[] = Object.values(conceptsById);
    const noConcepts = concepts.length === 0 && !fetchingConcepts && conceptFetchRequests > 0;
    const noMenus = menus.length === 0 && !fetchingMenus && menuFetchRequests > 0;

    const handleCreate = (open: boolean) => {
        toggleCreateMasterMenu(open);
        navigate(`/menus${open ? "/create" : ""}`, { replace: true });
    };

    return (
        <DefaultLayout>
            {noConcepts ? (
                <Box
                    sx={{
                        marginTop: (theme) => theme.spacing(10),
                        alignItems: "center",
                        justifyContent: "center",
                        position: "relative",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Typography variant="h4">A restaurant concept has not been set up on your account.</Typography>
                    <Typography variant="h5" sx={{ paddingTop: (theme) => theme.spacing(4) }}>
                        {`Please contact `}
                        <a href={`mailto:support@omnivore.io?subject=Set up a Restaurant Concept`}>
                            support@omnivore.io
                        </a>
                    </Typography>
                </Box>
            ) : (
                <>
                    {noMenus && (
                        <ZeroState
                            createLabel="Create Menu"
                            title="Create a Master Menu to get started."
                            onClick={() => toggleCreateMasterMenu(true)}
                        />
                    )}
                    {!noMenus && (
                        <>
                            <DataGrid
                                rows={rows}
                                columns={[
                                    {
                                        field: "name",
                                        headerName: "Master Menu",
                                        width: 560,
                                        flex: 3,
                                        resizable: false,
                                        disableColumnMenu: true,
                                        renderCell: function renderLocation(params: GridCellParams) {
                                            return <Link to={`/menus/${params.row.id}`}>{params.row.name}</Link>;
                                        },
                                    },
                                    {
                                        field: "id",
                                        headerName: "ID",
                                        width: 190,
                                        flex: 2,
                                        resizable: false,
                                        disableColumnMenu: true,
                                    },
                                    {
                                        field: "actions",
                                        headerClassName: "hidden",
                                        resizable: false,
                                        disableColumnMenu: true,
                                        width: 190,
                                        flex: 1,
                                        renderCell: function renderActions(params: GridCellParams) {
                                            return (
                                                <EntityRemove
                                                    title="Delete Master Menu"
                                                    onClick={() => setDeleteMenu({ menu: menusById[params.row.id] })}
                                                />
                                            );
                                        },
                                        align: "right",
                                        cellClassName: "actions-cell",
                                        filterable: false,
                                    },
                                ]}
                                pagination={menus.length > ListPerPageOptions[0] ? true : undefined}
                                page={0}
                                rowCount={menus.length}
                                paginationMode="server"
                                loading={fetchingMenus || fetchingConcepts}
                                rowsPerPageOptions={ListPerPageOptions}
                                autoHeight
                                disableSelectionOnClick
                                sortModel={[
                                    {
                                        field: "name",
                                        sort: "asc",
                                    },
                                ]}
                                components={{
                                    Toolbar: QuickSearchToolbar,
                                    LoadingOverlay: function CustomLoadingOverlay() {
                                        return (
                                            <GridOverlay>
                                                <div style={{ position: "absolute", top: 0, width: "100%" }}>
                                                    <LinearProgress />
                                                </div>
                                            </GridOverlay>
                                        );
                                    },
                                }}
                                componentsProps={{
                                    toolbar: {
                                        value: searchText,
                                        onChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                            setSearchText(e.target.value);
                                            debouncedSearch(e.target.value);
                                        },
                                        clearSearch: () => {
                                            setSearchText("");
                                            search("", menus, setRows);
                                        },
                                        title: (
                                            <Grid container alignItems="flex-start" justifyContent="space-between">
                                                <Grid item>
                                                    <Typography variant="h4">Master Menus</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <ActionButton
                                                        label="Create Menu"
                                                        onClick={() => handleCreate(true)}
                                                        sx={{ marginRight: (theme) => theme.spacing(2) }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        ),
                                    },
                                }}
                                sx={{
                                    minHeight: "inherit",
                                    borderLeft: "unset",
                                    borderRight: "unset",
                                    borderTop: "unset",
                                    "& .actions-cell": {
                                        "& > div": {
                                            cursor: "pointer",
                                        },
                                    },
                                    "& .hidden": {
                                        visibility: "hidden",
                                    },
                                    "& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within": {
                                        outline: "none",
                                    },
                                }}
                            />
                        </>
                    )}

                    {/* Create Master Menu */}
                    <OmniDrawer
                        hideClose
                        close={() => handleCreate(false)}
                        open={createMasterMenu}
                        title="Create Master Menu"
                    >
                        <MasterMenuAdd
                            onClose={() => handleCreate(false)}
                            open={createMasterMenu}
                            onSave={(name, location, type, concept, callback) =>
                                dispatch(menuAdd(name, location, type, concept, callback))
                            }
                        />
                    </OmniDrawer>

                    {/* Delete Master Menu */}
                    {deleteMenu && (
                        <MasterMenuDelete
                            onClose={() => setDeleteMenu(undefined)}
                            open={Boolean(deleteMenu)}
                            {...deleteMenu}
                        />
                    )}
                </>
            )}
        </DefaultLayout>
    );
}

interface MenusListProps {
    fetchingMenus?: boolean;
    menuFetchRequests?: number;
    menusById?: { [key: string]: MasterMenu };
    fetchingConcepts?: boolean;
    conceptFetchRequests?: number;
    conceptsById?: { [key: string]: RestaurantConcept };
}
const mapStateToProps = (state: RootState) => ({
    fetchingMenus: state.mms.menus.master.menus.fetching,
    menuFetchRequests: state.mms.menus.master.menus.requests,
    menusById: state.mms.menus.master.menus.byId,
    fetchingConcepts: state.restaurantConcepts.fetching,
    conceptFetchRequests: state.restaurantConcepts.requests,
    conceptsById: state.restaurantConcepts.byId,
});
export default connect(mapStateToProps, null)(ConnectedMenusList);
