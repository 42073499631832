import { Typography } from "@mui/material";
import DefaultLayout from "components/common/layouts/DefaultLayout";
import { getBreadcrumbSkeleton } from "components/skeletons";
import React from "react";
import { useSelector } from "react-redux";
import { Link as RouterLink, useParams } from "react-router-dom";
import { RootState } from "store/rootReducer";
import { RuleEdit } from "components/mms/MenuLayers/RuleSets/Rules/RuleEdit";

export function LayerRules(): JSX.Element {
    const { menuId, layerId, ruleSetId, ruleId } = useParams() as {
        menuId: string;
        layerId: string;
        ruleSetId: string;
        ruleId?: string;
    };

    const menusById = useSelector((state: RootState) => state.mms.menus.master.menus.byId);
    const masterMenusFetching = useSelector((state: RootState) => state.mms.menus.master.menus.fetching);
    const menu = menusById[menuId];

    const layersById = useSelector((state: RootState) => state.mms.menus.master.layers.byId);
    const layersFetching = useSelector((state: RootState) => state.mms.menus.master.layers.fetching);
    const layer = layersById[layerId];
    const ruleSet = layer && layer._embedded.rule_sets.find((rs) => rs.id === ruleSetId);

    const orderProfilesFetching = useSelector((state: RootState) => state.mms.orderProfiles.fetching);
    const tagsFetching = useSelector((state: RootState) => state.mms.tags.fetching);

    const isLoading =
        masterMenusFetching ||
        menu === undefined ||
        layersFetching ||
        layer === undefined ||
        ruleSet === undefined ||
        orderProfilesFetching ||
        tagsFetching;
    const breadcrumbs = isLoading
        ? getBreadcrumbSkeleton(3)
        : [
              <RouterLink key="menu" to={`/menus/${menuId}`}>
                  {menu.name}
              </RouterLink>,
              <RouterLink key="layer" to={`/menus/${menuId}/layers`}>
                  {layer.name}
              </RouterLink>,
              <RouterLink key="rule-set" to={`/menus/${menuId}/layers/${layerId}/rule-sets/${ruleSetId}`}>
                  {ruleSet.name}
              </RouterLink>,
              <Typography key="menu" color="textPrimary">
                  {ruleId ? ruleSet._embedded.rules.find((r) => r.id === ruleId)?.name : "New Rule"}
              </Typography>,
          ];

    return (
        <DefaultLayout
            breadcrumbs={[
                <RouterLink key="menus" to="/menus">
                    Menus
                </RouterLink>,
                ...breadcrumbs,
            ]}
        >
            <RuleEdit menuId={menuId} layerId={layerId} ruleSetId={ruleSetId} ruleId={ruleId} />
        </DefaultLayout>
    );
}
