import { TaggedEntityUpdateSuccess, TAGGED_ENTITY_UPDATE_SUCCESS } from "store/mms/tags/types";
import {
    EntityWithNutritionDeleteSuccess,
    EntityWithNutritionUpdateSuccess,
    ENTITY_WITH_NUTRITION_DELETE_SUCCESS,
    ENTITY_WITH_NUTRITION_UPDATE_SUCCESS,
} from "../nutrition/types";
import {
    isMasterItem,
    MasterItem,
    MasterItemActions,
    MASTER_ITEM_FETCH,
    MASTER_ITEM_FETCH_ERROR,
    MASTER_ITEM_FETCH_SUCCESS,
    MASTER_ITEM_IMAGE_DELETE_SUCCESS,
    MASTER_ITEM_IMAGE_SAVE_SUCCESS,
    MASTER_ITEM_OPTION_SETS_SAVE,
    MASTER_ITEM_OPTION_SETS_SAVE_ERROR,
    MASTER_ITEM_OPTION_SET_DELETE_SUCCESS,
    MASTER_ITEM_SAVE_SUCCESS,
} from "./types";

interface MasterItemsState {
    readonly allIds: string[];
    readonly byId: { [key: string]: MasterItem };
    readonly fetching: boolean;
    readonly fetchingError?: Error;
}

const initialState: MasterItemsState = {
    byId: {},
    allIds: [],
    fetching: false,
};

export default function masterItemsReducer(
    state = initialState,
    action:
        | MasterItemActions
        | TaggedEntityUpdateSuccess
        | EntityWithNutritionUpdateSuccess
        | EntityWithNutritionDeleteSuccess,
): MasterItemsState {
    switch (action.type) {
        case MASTER_ITEM_FETCH:
            return {
                ...state,
                fetching: true,
            };

        case MASTER_ITEM_SAVE_SUCCESS:
        case MASTER_ITEM_FETCH_SUCCESS:
            return {
                ...state,
                fetching: false,
                byId: {
                    ...state.byId,
                    [action.item.id]: action.item,
                },
                allIds: [...state.allIds.filter((id) => id !== action.item.id), action.item.id],
            };

        case MASTER_ITEM_FETCH_ERROR:
            return {
                ...state,
                fetching: false,
                fetchingError: action.error,
            };

        case MASTER_ITEM_IMAGE_SAVE_SUCCESS:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.itemId]: {
                        ...state.byId[action.itemId],
                        _embedded: { ...state.byId[action.itemId]._embedded, image: { url: action.imageUrl } },
                    },
                },
            };

        case MASTER_ITEM_IMAGE_DELETE_SUCCESS:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.itemId]: {
                        ...state.byId[action.itemId],
                        _embedded: { ...state.byId[action.itemId]._embedded, image: null },
                    },
                },
            };

        // optimistically save, revert on error
        case MASTER_ITEM_OPTION_SETS_SAVE:
        case MASTER_ITEM_OPTION_SETS_SAVE_ERROR:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.item.id]: {
                        ...state.byId[action.item.id],
                        _embedded: { ...state.byId[action.item.id]._embedded, option_sets: action.optionSets },
                    },
                },
            };

        case MASTER_ITEM_OPTION_SET_DELETE_SUCCESS:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.item.id]: {
                        ...state.byId[action.item.id],
                        _embedded: {
                            ...state.byId[action.item.id]._embedded,
                            option_sets: state.byId[action.item.id]._embedded.option_sets.filter(
                                (os) => os.id !== action.optionSet.id,
                            ),
                        },
                    },
                },
            };

        case TAGGED_ENTITY_UPDATE_SUCCESS:
            if (isMasterItem(action.entity)) {
                const item = action.entity as MasterItem;
                return {
                    ...state,
                    byId: {
                        ...state.byId,
                        [item.id]: {
                            ...state.byId[item.id],
                            _embedded: {
                                ...state.byId[item.id]._embedded,
                                tags: action.tags,
                            },
                        },
                    },
                };
            }

            return state;

        case ENTITY_WITH_NUTRITION_DELETE_SUCCESS:
            if (isMasterItem(action.entity)) {
                const item = action.entity as MasterItem;
                return {
                    ...state,
                    byId: {
                        ...state.byId,
                        [item.id]: {
                            ...state.byId[item.id],
                            _embedded: {
                                ...state.byId[item.id]._embedded,
                                nutrition: null,
                            },
                        },
                    },
                };
            }

            return state;

        case ENTITY_WITH_NUTRITION_UPDATE_SUCCESS:
            if (isMasterItem(action.entity)) {
                const item = action.entity as MasterItem;
                return {
                    ...state,
                    byId: {
                        ...state.byId,
                        [item.id]: {
                            ...state.byId[item.id],
                            _embedded: {
                                ...state.byId[item.id]._embedded,
                                nutrition: action.nutrition,
                            },
                        },
                    },
                };
            }

            return state;

        default:
            return state;
    }
}
