import {
    RestaurantConceptActions,
    RestaurantConcept,
    RESTAURANT_CONCEPT_FETCH,
    RESTAURANT_CONCEPT_FETCH_SUCCESS,
    RESTAURANT_CONCEPT_FETCH_ERROR,
} from "./types";
import { getSession } from "services/session";

export function restaurantConceptsFetch(): RestaurantConceptActions {
    return {
        type: RESTAURANT_CONCEPT_FETCH,
        url: `/1.1/accounts/${getSession()?.accountId}/restaurant_concepts`,
    };
}

export function restaurantConceptsFetchSuccess(restaurant_concepts: RestaurantConcept[]): RestaurantConceptActions {
    return {
        type: RESTAURANT_CONCEPT_FETCH_SUCCESS,
        restaurant_concepts,
    };
}

export function restaurantConceptsFetchError(error: Error): RestaurantConceptActions {
    return {
        type: RESTAURANT_CONCEPT_FETCH_ERROR,
        error,
    };
}
