import { ListRoute } from "store/types";
import { MasterModifier } from "../modifiers/types";
import { MasterItem } from "../types";
import { ModifierGroup, ModifierGroupResp, NewModifierGroup } from "./modifier-group/types";

export const OPTION_SET_FETCH = "@@ADMINMASTER/OPTION_SET/FETCH";
export const OPTION_SET_FETCH_SUCCESS = "@@ADMINMASTER/OPTION_SET/FETCH_SUCCESS";
export const OPTION_SET_FETCH_ERROR = "@@ADMINMASTER/OPTION_SET/FETCH_ERROR";

export const OPTION_SET_PAGE_FETCH = "@@ADMINMASTER/OPTION_SET/PAGE/FETCH";
export const OPTION_SET_PAGE_FETCH_SUCCESS = "@@ADMINMASTER/OPTION_SET/PAGE/FETCH_SUCCESS";
export const OPTION_SET_PAGE_FETCH_ERROR = "@@ADMINMASTER/OPTION_SET/PAGE/FETCH_ERROR";

export const OPTION_SET_CREATE = "@@ADMINMASTER/OPTION_SET/CREATE";
export const OPTION_SET_CREATE_SUCCESS = "@@ADMINMASTER/OPTION_SET/CREATE_SUCCESS";
export const OPTION_SET_CREATE_ERROR = "@@ADMINMASTER/OPTION_SET/CREATE_ERROR";

export const OPTION_SET_SAVE = "@@ADMINMASTER/OPTION_SET/SAVE";
export const OPTION_SET_SAVE_SUCCESS = "@@ADMINMASTER/OPTION_SET/SAVE_SUCCESS";

export const OPTION_SET_MODIFIER_SAVE = "@@ADMINMASTER/OPTION_SET/MODIFIER/SAVE";
export const OPTION_SET_MODIFIER_SAVE_SUCCESS = "@@ADMINMASTER/OPTION_SET/MODIFIER/SAVE_SUCCESS";

export const OPTION_SET_DELETE = "@@ADMINMASTER/OPTION_SET/DELETE";
export const OPTION_SET_DELETE_SUCCESS = "@@ADMINMASTER/OPTION_SET/DELETE_SUCCESS";
export const OPTION_SET_DELETE_ERROR = "@@ADMINMASTER/OPTION_SET/DELETE_ERROR";

export const OPTION_SETS_HYDRATE = "@@ADMINMASTER/OPTION_SETS/HYDRATE";

export interface OptionSetModifier {
    id: string;
    default: boolean;
}

export interface OptionSet {
    description: string;
    enabled: boolean;
    id: string;
    implicit: boolean;
    max: number;
    min: number;
    name: string;
    required: boolean;
    sequence: number;

    _embedded: {
        modifier_group: ModifierGroup;

        modifiers: OptionSetModifier[];
    };

    _links: {
        modifiers: {
            href: string;
            type: "application/hal+json; name=mms_master_menu_option_set_modifier_list";
        };

        self: {
            href: string;
            type: "application/hal+json; name=mms_master_menu_option_set";
        };
    };
}

export interface OptionSetPageResp extends ListRoute {
    _embedded: {
        option_sets: OptionSetResp[];
    };

    _links: {
        self: {
            href: string;
            type: "application/hal+json; name=mms_master_menu_option_set_list";
        };
    };
}
export interface OptionSetResp extends Omit<OptionSet, "_embedded"> {
    _embedded: {
        modifier_group: ModifierGroupResp;

        modifiers: OptionSetModifier[];
    };
}

export type OptionSetReq = Omit<OptionSet, "_embedded" | "_links">;

interface OptionSetFetch {
    type: typeof OPTION_SET_FETCH;
    url: string;
}

interface OptionSetFetchSuccess {
    type: typeof OPTION_SET_FETCH_SUCCESS;
    optionSet: OptionSet;
}

interface OptionSetFetchError {
    type: typeof OPTION_SET_FETCH_ERROR;
    error: Error;
}

interface OptionSetPageFetch {
    type: typeof OPTION_SET_PAGE_FETCH;
    url: string;
}

interface OptionSetPageFetchSuccess {
    type: typeof OPTION_SET_PAGE_FETCH_SUCCESS;
    optionSets: OptionSet[];
}

interface OptionSetPageFetchError {
    type: typeof OPTION_SET_PAGE_FETCH_ERROR;
    error: Error;
}

interface OptionSetCreate {
    type: typeof OPTION_SET_CREATE;
    group: ModifierGroup | NewModifierGroup;
    parent: MasterItem | MasterModifier;
    callback?: (optionSet?: OptionSet, error?: Error) => void;
}

interface OptionSetCreateSuccess {
    type: typeof OPTION_SET_CREATE_SUCCESS;
    optionSet: OptionSet;
}

interface OptionSetCreateError {
    type: typeof OPTION_SET_CREATE_ERROR;
    error: Error;
}

interface OptionSetSave {
    type: typeof OPTION_SET_SAVE;
    optionSet: OptionSet;
}

interface OptionSetSaveSuccess {
    type: typeof OPTION_SET_SAVE_SUCCESS;
    optionSet: OptionSet;
}

interface OptionSetModifierSave {
    type: typeof OPTION_SET_MODIFIER_SAVE;
    optionSet: OptionSet;
    optionSetModifier: OptionSetModifier;
    callback?: (error?: Error) => void;
}

interface OptionSetModifierSaveSuccess {
    type: typeof OPTION_SET_MODIFIER_SAVE_SUCCESS;
    optionSet: OptionSet;
    optionSetModifier: OptionSetModifier;
}

interface OptionSetDelete {
    type: typeof OPTION_SET_DELETE;
    url: string;
    callback?: (error?: Error) => void;
}

interface OptionSetPageDeleteSuccess {
    type: typeof OPTION_SET_DELETE_SUCCESS;
    optionSet: OptionSet;
}

interface OptionSetPageDeleteError {
    type: typeof OPTION_SET_DELETE_ERROR;
    error: Error;
}

interface OptionSetsHydrate {
    type: typeof OPTION_SETS_HYDRATE;
    optionSets: OptionSet[];
}

export type OptionSetsActions =
    | OptionSetFetch
    | OptionSetFetchSuccess
    | OptionSetFetchError
    | OptionSetPageFetch
    | OptionSetPageFetchSuccess
    | OptionSetPageFetchError
    | OptionSetCreate
    | OptionSetCreateSuccess
    | OptionSetCreateError
    | OptionSetSave
    | OptionSetSaveSuccess
    | OptionSetModifierSave
    | OptionSetModifierSaveSuccess
    | OptionSetDelete
    | OptionSetPageDeleteSuccess
    | OptionSetPageDeleteError
    | OptionSetsHydrate;
