import Promise from "bluebird";
import {
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    IconButton,
    MenuItem,
    TextField,
    Theme,
    Typography,
    useTheme,
} from "@mui/material";
import { makeStyles } from "theme";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Form, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import { default as createCalculateDecorator } from "final-form-calculate";
import { ModifierStrategy } from "store/mms/menus/location/items/modifiers/types";
import { ItemStrategy } from "store/mms/menus/location/items/types";
import { MenuEntity } from "store/mms/menus/types";
import {
    ComboPOSConfig,
    getEntityPOSConfig,
    ItemPOSConfig,
    ModifierPOSConfig,
    POSCombo,
    POSConfig,
    POSConfigKey,
    POSItem,
    POSModifier,
} from "store/mms/types";
import {
    getConcreteLinkable,
    getEntityType,
    getEntityBaseUrl,
    getStrategyOptions,
    getPriceLevelId,
    entityIsCombo,
    hasPriceLevels,
    mapItemGroupEmbeddedMods,
    posConfigTypeIsCombo,
} from "./helpers";
import { mapToFormErrors } from "components/common/forms/helpers";
import { core } from "services/api";
import { Decorator, getIn, Mutator } from "final-form";
import { capitalize } from "components/common/utils";
import { default as createSubmitListenerDecorator } from "final-form-submit-listener";
import { default as createFocusDecorator } from "final-form-focus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle, faPlusCircle } from "@fortawesome/pro-solid-svg-icons";
import { Skeleton } from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import POSEntitySearch from "./POSEntitySearch";
import { EditPOSConfigFormValues, EditPOSConfigValidationValues, EmbeddedModifier } from "./types";
import EmbeddedComboItem from "./EmbeddedComboItem";
import isEmpty from "lodash/isEmpty";
import { Location } from "store/locations/types";
import LocationSearch from "./LocationSearch";
import { AxiosResponse } from "axios";
import { FormSubmit } from "components/common/FormSubmit";
import { handleErrors } from "components/common/forms/helpers";

const useStyles = makeStyles()((theme: Theme) => ({
    root: {
        padding: theme.spacing(5),
        "& > *": {
            marginBottom: theme.spacing(4),
        },
        "& > hr": {
            color: theme.mixins.borderColor,
            marginBottom: theme.spacing(2),
        },
    },
    forceLocationUpdate: {
        marginLeft: "-7px",
    },
    submitErrors: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: theme.palette.error.main,
    },
    submitErrorIcon: {
        marginRight: theme.spacing(1),
    },
}));
const validate = (values: EditPOSConfigValidationValues, allowLocationSearch: boolean) => {
    const errors: {
        display_name?: string;
        reference_name?: string;
        posEntity?: string;
        strategy?: string;
        location?: string;
    } = {};

    if (allowLocationSearch) {
        if (!values.location) {
            errors.location = "Required";
        }
    }

    if (!values.posEntity) {
        errors.posEntity = "Required";
    }

    if (!values.strategy) {
        errors.strategy = "Required";
    }

    return errors;
};
interface EmbeddedModifierError {
    posEntity?: string;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const validateEmbeddedMods = (possibleMods: any[]) => {
    const embeddedMods = possibleMods as EmbeddedModifier[];
    const errors: EmbeddedModifierError[] = [];

    embeddedMods?.forEach((mod) => {
        const error: EmbeddedModifierError = {};

        if (!mod.posEntity) {
            error.posEntity = "Required";
        }
        errors.push(error);
    });

    if (errors.some((e) => !isEmpty(e))) {
        return errors;
    }

    return undefined;
};
const mapEmbeddedMods = (mods?: EmbeddedModifier[]): ModifierPOSConfig[] => {
    if (mods === undefined) {
        return [];
    }

    return mods.map((m) => ({
        id: m.posEntity.id,
        exclude_price: m.exclude_price,
        name: m.posEntity.name,
        price_per_unit: m.posEntity.price_per_unit,
        price_level:
            m.price_level !== undefined && m.price_level !== null
                ? m.posEntity._embedded.price_levels.find((pl) => pl.id === m.price_level)
                : undefined,
        modifiers: [],
    }));
};
const calculateDecorator = createCalculateDecorator(
    {
        field: "strategy",
        updates: {
            posEntity: (strategy, values, prevValues) => {
                if (!Object.prototype.hasOwnProperty.call(prevValues, "strategy")) {
                    const v = values as EditPOSConfigFormValues;

                    return v.posEntity;
                }

                return null;
            },
            embeddedMods: (strategy, values, prevValues) => {
                if (!Object.prototype.hasOwnProperty.call(prevValues, "strategy")) {
                    const v = values as EditPOSConfigFormValues;

                    return v.embeddedMods;
                }

                return [];
            },
        },
    },
    {
        field: "location",
        updates: {
            locationUrl: (location) => {
                if (location === null || location === undefined) {
                    return "";
                }
                return (location as Location)._links?.self.href.replace(/.+(\/1\.1.+)/, "$1").replace(/\/$/, "");
            },
            posEntity: (location, values, prevValues) => {
                const { location: prevLocation } = prevValues as EditPOSConfigFormValues;

                if (location === null || location === undefined || (prevLocation && prevLocation.id !== location.id)) {
                    return null;
                }

                const { posEntity } = values as EditPOSConfigFormValues;

                return posEntity;
            },
        },
    },
    {
        field: "posEntity",
        updates: {
            price_level: (posEntity, values, prevValues) => {
                if (posEntity === null) {
                    return null;
                }
                const { price_level } = values as EditPOSConfigFormValues;
                const { price_level: prevPriceLevel } = prevValues as EditPOSConfigFormValues;

                if (!price_level) {
                    if (prevPriceLevel) {
                        return prevPriceLevel;
                    } else if (hasPriceLevels(posEntity)) {
                        const p = posEntity as POSItem | POSModifier | POSCombo;

                        return p._embedded.price_levels !== undefined ? p._embedded.price_levels[0].id : "";
                    }
                }

                return price_level;
            },
            embeddedMods: (posEntity, values, prevValues) => {
                if (!Object.prototype.hasOwnProperty.call(prevValues, "posEntity")) {
                    const v = values as EditPOSConfigFormValues;

                    return v.embeddedMods;
                }

                return [];
            },
            embeddedItemGroups: (posEntity) => {
                return posConfigTypeIsCombo(posEntity) ? (posEntity as POSCombo).item_groups : [];
            },
            group: (posEntity, values, prevValues) => {
                if (!Object.prototype.hasOwnProperty.call(prevValues, "group")) {
                    const v = values as EditPOSConfigFormValues;

                    return v.group;
                }
                const isCombo = posConfigTypeIsCombo(posEntity);

                return isCombo
                    ? (values as EditPOSConfigFormValues).group || (prevValues as EditPOSConfigFormValues).group
                    : undefined;
            },
        },
    },
    {
        field: "group",
        updates: (group, name, allValues) => {
            if (group.groupId === undefined) {
                return { group: undefined };
            }
            const embeddedItemGroups = (allValues as EditPOSConfigFormValues).embeddedItemGroups;
            const selectedGroup = embeddedItemGroups.find((g) => g.id === group.groupId);
            const items = selectedGroup?.items;
            const selectedItem = items?.find((item) => item.id === group.itemId);

            if (selectedItem !== undefined) {
                return { "group.itemId": selectedItem.id };
            } else if (items !== undefined) {
                return { "group.itemId": items[0].id };
            }
            return { "group.itemId": embeddedItemGroups[0].items[0].id };
        },
    },
    {
        field: /embeddedMods\[\d\]\.posEntity/,
        updates: (posEntity, name, allValues, prevValues) => {
            if (posEntity === undefined) {
                return {};
            }
            const priceLevelField = name.replace(/\bposEntity\b([^\bposEntity\b]*)$/, "$1" + "price_level");
            if (posEntity === null) {
                return { [priceLevelField]: undefined };
            }

            const priceLevel = getIn(allValues as EditPOSConfigFormValues, priceLevelField);
            const prevPriceLevel = getIn(prevValues as EditPOSConfigFormValues, priceLevelField);
            if (!priceLevel) {
                if (prevPriceLevel) {
                    return { [priceLevelField]: prevPriceLevel };
                } else if (hasPriceLevels(posEntity)) {
                    const p = posEntity as POSItem | POSModifier | POSCombo;

                    return {
                        [priceLevelField]: p._embedded.price_levels !== undefined ? p._embedded.price_levels[0].id : "",
                    };
                }
            }

            return { [priceLevelField]: priceLevel };
        },
    },
);
const focusDecorator = createFocusDecorator();

interface EditPOSConfigProps {
    disableLocationSearch?: boolean;
    disableForceLocationUpdate?: boolean;
    isModifier?: boolean;
    locationUrl?: string;
    onClose(): void;
    onSave(
        posConfig: POSConfig,
        strategy: ItemStrategy | ModifierStrategy,
        callback?: ((error?: Error) => void) | undefined,
        sourceLocation?: string,
        forceLocationUpdate?: boolean,
    ): void;
    posConfig: POSConfig | null;
    submitLabel?: string;
    strategy: ItemStrategy | ModifierStrategy;
    traversal: MenuEntity[];
}

export default function EditPOSConfig({
    disableLocationSearch = false,
    disableForceLocationUpdate = false,
    isModifier = false,
    locationUrl,
    onClose,
    onSave,
    posConfig,
    submitLabel,
    strategy,
    traversal,
}: EditPOSConfigProps): JSX.Element {
    const { classes } = useStyles();
    const theme = useTheme();
    const entityPOSConfig = getEntityPOSConfig(posConfig);
    const isCombo = entityIsCombo(entityPOSConfig);
    const submitListener = useMemo(
        () =>
            createSubmitListenerDecorator({
                afterSubmitSucceeded: onClose,
            }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );
    const replaceParentStrategy = useCallback(
        (): ItemStrategy | ModifierStrategy =>
            getConcreteLinkable(traversal, strategy !== ModifierStrategy.ReplaceParent).strategy,
        [strategy, traversal],
    );
    const initialEntityType = getEntityType(strategy, replaceParentStrategy);
    const [state, setState] = useState<{
        entityBaseUrl?: string;
        fetching: boolean;
        posEntity?: POSCombo | POSItem | POSModifier;
        location?: Location;
        embeddedMods?: EmbeddedModifier[];
    }>({
        fetching: true,
        entityBaseUrl: locationUrl ? getEntityBaseUrl(locationUrl, initialEntityType) : undefined,
    });

    // get the pos entity
    useEffect(() => {
        if (entityPOSConfig === undefined && !locationUrl) {
            return setState((prevState) => ({
                ...prevState,
                fetching: false,
                posEntity: undefined,
                embeddedMods: [],
            }));
        }
        let mounted = true;
        (async () => {
            try {
                const embeddedModifierPOSConfigs: { [key: string]: ModifierPOSConfig } = (
                    entityPOSConfig && !isCombo
                        ? (entityPOSConfig as ItemPOSConfig | ModifierPOSConfig).modifiers ?? []
                        : (entityPOSConfig && mapItemGroupEmbeddedMods(entityPOSConfig as ComboPOSConfig)) || []
                ).reduce((prev: { [key: string]: ModifierPOSConfig }, m) => {
                    prev[m.id || ""] = m;
                    return prev;
                }, {});
                const [entity, embeddedMods, location] = await Promise.all([
                    entityPOSConfig
                        ? core.get(`${state.entityBaseUrl}/${entityPOSConfig?.id}`)
                        : new Promise((resolve) => resolve(undefined)),
                    Promise.map(entityPOSConfig ? Object.values(embeddedModifierPOSConfigs) : [], (m) =>
                        core.get<unknown, AxiosResponse<POSModifier>>(
                            `${getEntityBaseUrl(locationUrl || "", POSConfigKey.Modifier)}/${m.id}`,
                        ),
                    ),
                    !disableLocationSearch && locationUrl !== undefined && locationUrl !== ""
                        ? core.get<unknown, AxiosResponse<Location>>(locationUrl)
                        : new Promise((resolve) => resolve(undefined)),
                ]);

                if (mounted) {
                    let posEntity: POSCombo | POSItem | POSModifier | undefined;
                    if (entity) {
                        switch (initialEntityType) {
                            case POSConfigKey.ComboItem:
                            case POSConfigKey.ComboModifier:
                                posEntity = entity.data as POSCombo;
                                break;

                            case POSConfigKey.Item:
                                posEntity = entity.data as POSItem;
                                break;

                            case POSConfigKey.Modifier:
                                posEntity = entity.data as POSModifier;
                                break;
                        }
                    }

                    setState((prevState) => ({
                        ...prevState,
                        fetching: false,
                        embeddedMods: embeddedMods.map(
                            (m) =>
                                ({
                                    posEntity: m.data,
                                    price_level: embeddedModifierPOSConfigs[m.data.id]?.price_level?.id,
                                    exclude_price: embeddedModifierPOSConfigs[m.data.id]?.exclude_price,
                                } as EmbeddedModifier),
                        ),
                        location: (location?.data as Location) || undefined,
                        posEntity,
                    }));
                }
            } catch (error) {
                console.error("Failed to get POS Entity, Embedded Modifiers, and/or Location info", error);
                return setState((prevState) => ({
                    ...prevState,
                    fetching: false,
                }));
            }
        })();

        return () => {
            mounted = false;
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // This nonsense is here because the mutators don't super support Typescript.
    const mutators: { [x: string]: Mutator<EditPOSConfigValidationValues, EditPOSConfigFormValues> } = {};
    for (const [key, value] of Object.entries(arrayMutators)) {
        mutators[key] = value as Mutator<EditPOSConfigValidationValues, EditPOSConfigFormValues>;
    }

    return state.fetching ? (
        <LoadingSkeleton />
    ) : (
        <Form
            initialValues={{
                strategy: strategy,
                price_level: getPriceLevelId(posConfig),
                posEntity: state.posEntity,
                embeddedMods: state.embeddedMods,
                embeddedItemGroups: posConfigTypeIsCombo(state.posEntity)
                    ? (state.posEntity as POSCombo).item_groups
                    : [],
                group:
                    posConfigTypeIsCombo(state.posEntity) && posConfig?.combo?.item_groups[0] !== undefined
                        ? {
                              groupId: posConfig?.combo?.item_groups[0].id || "",
                              itemId: posConfig?.combo?.item_groups[0].items[0].id || "",
                              price_level: posConfig?.combo?.item_groups[0].items[0].price_level?.id,
                          }
                        : undefined,
                exclude_price: posConfig?.modifier?.exclude_price,
                location: state.location,
                locationUrl: locationUrl || "",
            }}
            decorators={[
                calculateDecorator as Decorator<EditPOSConfigValidationValues, EditPOSConfigFormValues>,
                submitListener as Decorator<EditPOSConfigValidationValues, EditPOSConfigFormValues>,
                focusDecorator as Decorator<EditPOSConfigValidationValues, EditPOSConfigFormValues>,
            ]}
            mutators={{ ...mutators }}
            validate={(values) => validate(values, !disableLocationSearch)}
            onSubmit={(values, form, errorsCallback) => {
                const replaceParentStrategy = (): ItemStrategy | ModifierStrategy =>
                    getConcreteLinkable(traversal).strategy;
                const entityType = getEntityType(values.strategy, replaceParentStrategy);
                const newPOSEntity = values.posEntity;
                const hasPriceLevel = values.price_level !== "";
                const newPriceLevel = hasPriceLevel
                    ? newPOSEntity._embedded.price_levels.find((pl) => pl.id === values.price_level)
                    : undefined;
                const newConfig = {} as POSConfig;

                switch (entityType) {
                    case POSConfigKey.ComboModifier:
                    case POSConfigKey.ComboItem:
                        newConfig[entityType] = {
                            id: newPOSEntity.id,
                            name: newPOSEntity.name,
                            price_per_unit: newPOSEntity.price_per_unit,
                            price_level: newPriceLevel,
                            item_groups: values.embeddedItemGroups
                                .filter((g) => g.id === values.group?.groupId)
                                .map((g) => ({
                                    id: g.id,
                                    name: g.name,
                                    items: g.items
                                        .filter((i) => i.id === values.group?.itemId)
                                        .map((i) => ({
                                            id: i.id,
                                            name: i.name,
                                            price_per_unit: i.price_per_unit,
                                            price_level: i._embedded.price_levels.find(
                                                (pl) => pl.id === values.group?.price_level,
                                            ),
                                            modifiers: mapEmbeddedMods(values.embeddedMods),
                                        })),
                                })),
                        };
                        break;
                    case POSConfigKey.Item:
                        newConfig[entityType] = {
                            id: newPOSEntity.id,
                            name: newPOSEntity.name,
                            price_per_unit: newPOSEntity.price_per_unit,
                            price_level: newPriceLevel,
                            modifiers: mapEmbeddedMods(values.embeddedMods),
                        };
                        break;
                    case POSConfigKey.Modifier:
                        newConfig[entityType] = {
                            id: newPOSEntity.id,
                            name: newPOSEntity.name,
                            price_per_unit: newPOSEntity.price_per_unit,
                            price_level: newPriceLevel,
                            modifiers: mapEmbeddedMods(values.embeddedMods),
                            exclude_price: values.exclude_price,
                        };
                        break;
                }

                onSave(
                    newConfig,
                    values.strategy,
                    (error?: Error) => {
                        if (errorsCallback !== undefined) {
                            errorsCallback(mapToFormErrors(error));
                        }
                    },
                    disableLocationSearch ? undefined : values.location?.id,
                    disableLocationSearch || disableForceLocationUpdate ? undefined : values.force_location_update,
                );
            }}
        >
            {({
                handleSubmit,
                submitError,
                values,
                form: {
                    change,
                    mutators: { push },
                },
            }) => (
                <form onSubmit={handleSubmit} className={classes.root} data-testid="edit_pos_config">
                    <Typography variant="h6">POS Mapping</Typography>
                    <Field name="strategy">
                        {({ input, meta }) => (
                            <TextField
                                select
                                id="strategy"
                                {...input}
                                {...handleErrors(meta)}
                                title="Ordering Strategy"
                                label="Ordering Strategy"
                                variant="outlined"
                                fullWidth
                            >
                                {getStrategyOptions(
                                    isModifier,
                                    getConcreteLinkable(traversal).strategy === ItemStrategy.AddCombo,
                                ).map((o, idx) => (
                                    <MenuItem value={o.strategy} key={idx}>
                                        {o.title}
                                    </MenuItem>
                                ))}
                            </TextField>
                        )}
                    </Field>

                    {!disableLocationSearch && (
                        <LocationSearch
                            entityType={capitalize(getEntityType(values.strategy, replaceParentStrategy).valueOf())}
                        />
                    )}

                    <POSEntitySearch
                        disabled={!values.locationUrl}
                        excludePrice={isModifier}
                        entityType={capitalize(getEntityType(values.strategy, replaceParentStrategy).valueOf())}
                        entityBaseUrl={getEntityBaseUrl(
                            values.locationUrl,
                            getEntityType(values.strategy, replaceParentStrategy),
                        )}
                    />

                    {posConfigTypeIsCombo(values.posEntity) && (
                        <>
                            <hr />
                            {values.group !== undefined ? (
                                <>
                                    <Grid container justifyContent="space-between">
                                        <Grid item>
                                            <Typography variant="h6">Embedded Combo Item</Typography>
                                        </Grid>

                                        <Grid item>
                                            <Button onClick={() => change("group", undefined)}>Remove</Button>
                                        </Grid>
                                    </Grid>
                                    <EmbeddedComboItem
                                        name="group"
                                        groups={values.embeddedItemGroups}
                                        disabled={!values.locationUrl}
                                        items={
                                            values.group?.itemId !== undefined
                                                ? values.embeddedItemGroups.find((g) => g.id === values.group?.groupId)
                                                      ?.items || []
                                                : []
                                        }
                                        priceLevels={
                                            values.group?.itemId !== undefined
                                                ? values.embeddedItemGroups
                                                      .find((g) => g.id === values.group?.groupId)
                                                      ?.items.find((i) => i.id === values.group?.itemId)?._embedded
                                                      .price_levels || []
                                                : []
                                        }
                                    />
                                </>
                            ) : (
                                <Button
                                    disabled={!values.locationUrl}
                                    onClick={() =>
                                        change("group", {
                                            groupId: values.embeddedItemGroups[0].id || "",
                                            itemId: values.embeddedItemGroups[0].items[0].id || "",
                                        })
                                    }
                                    startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
                                >
                                    Add Embedded Combo Item
                                </Button>
                            )}
                        </>
                    )}

                    {values.posEntity !== undefined &&
                        (!posConfigTypeIsCombo(values.posEntity) || values.group !== undefined) && (
                            <>
                                <hr />
                                {values.embeddedMods !== undefined && values.embeddedMods.length > 0 && (
                                    <Typography variant="h6">Embedded Modifiers</Typography>
                                )}
                                <FieldArray name="embeddedMods" validate={validateEmbeddedMods}>
                                    {({ fields }) =>
                                        fields.map((name, index) => (
                                            <Grid container key={index} spacing={2}>
                                                <Grid item md={11}>
                                                    <POSEntitySearch
                                                        disabled={!values.locationUrl}
                                                        excludePrice={true}
                                                        name={name}
                                                        entityType={capitalize(POSConfigKey.Modifier.valueOf())}
                                                        entityBaseUrl={getEntityBaseUrl(
                                                            values.locationUrl,
                                                            POSConfigKey.Modifier,
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid item md={1}>
                                                    <IconButton
                                                        title="Remove Embedded Modifier"
                                                        onClick={() => fields.remove(index)}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        ))
                                    }
                                </FieldArray>

                                {values.embeddedMods !== undefined && values.embeddedMods.length > 0 && <hr />}
                                <Button
                                    title="Add Embedded Modifier"
                                    disabled={!values.locationUrl}
                                    onClick={() => push("embeddedMods", { posEntity: null })}
                                    startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
                                >
                                    Add Embedded Modifier
                                </Button>
                            </>
                        )}

                    {/* This is intentional. If you can't choose a location you can't force an update to all other locations. */}
                    {!disableLocationSearch && !disableForceLocationUpdate && (
                        <Grid container>
                            <Grid item xs={12}>
                                <Field name="force_location_update" type="checkbox">
                                    {({ input }) => (
                                        <FormControlLabel
                                            className={classes.forceLocationUpdate}
                                            control={<Checkbox {...input} />}
                                            label="Push POS mapping to all linked Locations"
                                        />
                                    )}
                                </Field>
                            </Grid>
                        </Grid>
                    )}

                    {submitError && (
                        <div className={classes.submitErrors}>
                            <FontAwesomeIcon
                                className={classes.submitErrorIcon}
                                icon={faExclamationTriangle}
                                color={theme.palette.error.main}
                            />
                            {submitError}
                        </div>
                    )}

                    <FormSubmit onClose={onClose} label={submitLabel || `Update ${isModifier ? "Modifier" : "Item"}`} />
                </form>
            )}
        </Form>
    );
}

function LoadingSkeleton(): JSX.Element {
    const { classes } = useStyles();

    return (
        <div className={classes.root} data-testid="loading">
            <Skeleton width="100%" height={85} />
            <Skeleton width="100%" height={85} />
            <Skeleton width={105} height={20} variant="text" />
            <Skeleton width="100%" height={85} />
            <Skeleton width="100%" height={85} />
        </div>
    );
}
