import { faCircle, faEyeSlash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Skeleton, useTheme, Typography, Grid, MenuItem, TextField } from "@mui/material";
import { Sidebar, SidebarSection, SidebarHeader, SidebarContent } from "components/Sidebar";
import { RuleSetContext, RuleSetEvent } from "pages/Menus/Layers/RuleSets/context";
import React, { useContext } from "react";

interface RuleSetSidebarProps {
    loading: boolean;
}

export default function RuleSetSidebar({ loading }: RuleSetSidebarProps): JSX.Element {
    const theme = useTheme();

    const { ruleSet, onChange } = useContext(RuleSetContext);

    return loading ? (
        <Skeleton />
    ) : (
        <Sidebar>
            <SidebarSection>
                <SidebarHeader>
                    <Typography variant="subtitle2">Rule Set Configuration</Typography>
                </SidebarHeader>
                <SidebarContent>
                    <TextField
                        label="Status:"
                        id="entity-visibility"
                        value={ruleSet?.enabled ? "visible" : "invisible"}
                        variant="outlined"
                        select
                        fullWidth
                        onChange={() => {
                            onChange({
                                type: RuleSetEvent.RuleSetVisibility,
                            });
                        }}
                    >
                        <MenuItem value={"visible"}>
                            <Grid container direction="row">
                                <FontAwesomeIcon icon={faCircle} fixedWidth color={theme.palette.success.main} />
                                <div style={{ marginLeft: "16px" }}>Active</div>
                            </Grid>
                        </MenuItem>
                        <MenuItem value={"invisible"}>
                            <Grid container direction="row">
                                <FontAwesomeIcon icon={faEyeSlash} fixedWidth />
                                <div style={{ marginLeft: "16px" }}>Inactive</div>
                            </Grid>
                        </MenuItem>
                    </TextField>
                </SidebarContent>
            </SidebarSection>
        </Sidebar>
    );
}
