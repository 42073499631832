import { faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Box,
    Checkbox,
    FormControl,
    FormHelperText,
    FormLabel,
    InputAdornment,
    TextField,
    Tooltip,
    Typography,
    useTheme,
} from "@mui/material";
import { Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from "@mui/icons-material";
import { Accordion } from "components/common/forms/Accordion";
import { handleErrors } from "components/common/forms/helpers";
import { FormError } from "components/common/forms/FormError";
import PaperWrapper from "components/common/PaperWrapper";
import { getIn } from "final-form";
import { Autocomplete, Radios } from "mui-rff";
import React from "react";
import { Field, useForm, useFormState } from "react-final-form";
import { useSelector } from "react-redux";
import { OrderProfileAdjustmentStrategyTypes, OrderProfileAdjustmentTypes } from "store/mms/order-profiles/types";
import { RootState } from "store/rootReducer";
import {
    enableTransformTypeEnabled,
    enableTransformTypeAdjustPrice,
    enableTransformTypeAddTags,
    transformTypeAdjustPriceConfig,
    transformTypeEnabledConfig,
    transformTypeAddTagsConfig,
    enableTransformTypePeriods,
    constraintsOrderProfiles,
    ruleType,
    transformErrors,
    selectedModifiers,
} from "./constants";
import { PeriodsList } from "./PeriodsList";
import { useConfigurationsStyles } from "./styles";

export function Transformations(): JSX.Element {
    const theme = useTheme();
    const { classes } = useConfigurationsStyles();
    const orderProfiles = useSelector((state: RootState) => state.mms.orderProfiles.profiles);
    const filteredOrderProfiles = orderProfiles
        .filter(
            (op) =>
                op.adjustment.enabled && op.adjustment.strategy !== OrderProfileAdjustmentStrategyTypes.TicketDiscount,
        )
        .map((op) => ({ label: `${op.name} (${op.id})`, value: op.id }));
    const tags = useSelector((state: RootState) => state.mms.tags.tags);
    const { values } = useFormState();
    const { change } = useForm();
    const hasModifiers = (getIn(values, selectedModifiers) || []).length > 0;
    const selectedRuleType = getIn(values, "ruleType");
    const transformEnabled = getIn(values, enableTransformTypeEnabled) || false;
    const transformAdjustPrice = getIn(values, enableTransformTypeAdjustPrice) || false;
    const transformAddTags = getIn(values, enableTransformTypeAddTags) || false;
    const constrainedOrderProfiles = getIn(values, constraintsOrderProfiles) || [];
    const selectedOrderProfile = getIn(values, `${transformTypeAdjustPriceConfig}.orderProfile`);
    const selectedOPAdjustment = getIn(values, `${transformTypeAdjustPriceConfig}.type`);

    return (
        <Box className={classes.root}>
            <Typography variant="h5">Rules</Typography>
            <Typography variant="subtitle1">
                We have narrowed down the list of Rules that can be applied to your Menu selections. Select and
                configure the one(s) that you want to apply.
            </Typography>

            <FormError className={classes.formError} name={transformErrors} />

            {selectedRuleType === ruleType.Menu && (
                <>
                    <Accordion
                        label="Change Visibility"
                        name={enableTransformTypeEnabled}
                        disableCheckbox={hasModifiers}
                    >
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Set my selections as...</FormLabel>
                            <Radios
                                name={`${transformTypeEnabledConfig}.enabled`}
                                label=""
                                formControlLabelProps={{
                                    classes: {
                                        label: classes.iconLabel,
                                    },
                                }}
                                data={[
                                    {
                                        label: (
                                            <>
                                                <VisibilityIcon />
                                                Visible On Menu
                                            </>
                                        ),
                                        value: "true",
                                    },
                                    {
                                        label: (
                                            <>
                                                <VisibilityOffIcon />
                                                Not Visible On Menu
                                            </>
                                        ),
                                        value: "false",
                                    },
                                ]}
                                disabled={!transformEnabled}
                            />
                            <FormHelperText>
                                Any selected Modifiers will be hidden across any Item or Modifier Group where it is
                                used.
                            </FormHelperText>
                        </FormControl>
                    </Accordion>

                    {!hasModifiers && (
                        <>
                            <Accordion label="Adjust Pricing" name={enableTransformTypeAdjustPrice}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">
                                        Which order profile do you want to use for this price adjustment?
                                        <Tooltip
                                            title="Adjust pricing rules can be created for Order Profiles that already have price adjustments. If an Order Profile condition is added above, other Order Profiles will be disabled in this list."
                                            arrow
                                        >
                                            <span>
                                                <FontAwesomeIcon
                                                    icon={faQuestionCircle}
                                                    color={theme.palette.info.main}
                                                />
                                            </span>
                                        </Tooltip>
                                    </FormLabel>
                                    <Autocomplete
                                        name={`${transformTypeAdjustPriceConfig}.orderProfile`}
                                        label="Order Profile"
                                        options={filteredOrderProfiles}
                                        onChange={(event, option) => {
                                            if (option && !Array.isArray(option) && typeof option !== "string") {
                                                const op = orderProfiles.find((op) => op.id === option.value);

                                                if (op) {
                                                    change(
                                                        `${transformTypeAdjustPriceConfig}.type`,
                                                        op.adjustment.type ? op.adjustment.type : "",
                                                    );
                                                    change(
                                                        `${transformTypeAdjustPriceConfig}.enabled`,
                                                        op.adjustment.enabled,
                                                    );
                                                    change(
                                                        `${transformTypeAdjustPriceConfig}.amount`,
                                                        op.adjustment.amount ? op.adjustment.amount / 100 : "",
                                                    );
                                                }
                                            }
                                        }}
                                        getOptionValue={(o) => o.value}
                                        getOptionLabel={(o) => o.label}
                                        isOptionEqualToValue={(o, v) => {
                                            return o.value === v.value;
                                        }}
                                        textFieldProps={{
                                            variant: "outlined",
                                        }}
                                        getOptionDisabled={(o) =>
                                            constrainedOrderProfiles.length
                                                ? !constrainedOrderProfiles.includes(o.value)
                                                : false
                                        }
                                        PaperComponent={PaperWrapper}
                                        disabled={!transformAdjustPrice}
                                        freeSolo
                                        fullWidth
                                        selectOnFocus
                                        clearOnBlur
                                        handleHomeEndKeys
                                    />
                                </FormControl>

                                <FormControl component="fieldset">
                                    <FormLabel component="legend">
                                        How do you want to adjust pricing for your selections?
                                    </FormLabel>
                                    <Radios
                                        name={`${transformTypeAdjustPriceConfig}.type`}
                                        data={[
                                            {
                                                label: "No adjustment needed.",
                                                value: "",
                                            },
                                            {
                                                label: "Apply percent uplift to my selections.",
                                                value: OrderProfileAdjustmentTypes.Percent,
                                            },
                                            {
                                                label: "Apply dollar uplift to my selections.",
                                                value: OrderProfileAdjustmentTypes.Dollar,
                                            },
                                        ]}
                                        disabled={!transformAdjustPrice || !selectedOrderProfile}
                                        radioGroupProps={{
                                            onChange: (e, v) =>
                                                change(`${transformTypeAdjustPriceConfig}.enabled`, v ? true : false),
                                        }}
                                    />

                                    {selectedOPAdjustment && (
                                        <Field name={`${transformTypeAdjustPriceConfig}.amount`}>
                                            {({ input, meta }) => (
                                                <TextField
                                                    label="Amount"
                                                    {...input}
                                                    {...handleErrors(meta)}
                                                    InputLabelProps={{ shrink: true }}
                                                    style={{ display: "inline-block" }}
                                                    InputProps={{
                                                        startAdornment: selectedOPAdjustment ===
                                                            OrderProfileAdjustmentTypes.Dollar && (
                                                            <InputAdornment position="start">$</InputAdornment>
                                                        ),
                                                        endAdornment: selectedOPAdjustment ===
                                                            OrderProfileAdjustmentTypes.Percent && (
                                                            <InputAdornment position="end">%</InputAdornment>
                                                        ),
                                                    }}
                                                    disabled={!transformAdjustPrice || !selectedOrderProfile}
                                                />
                                            )}
                                        </Field>
                                    )}
                                </FormControl>
                            </Accordion>

                            <Accordion label="Add Tags" name={enableTransformTypeAddTags}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">
                                        Choose the Tags that should be assigned to your selections.
                                    </FormLabel>

                                    <Autocomplete
                                        name={`${transformTypeAddTagsConfig}.addTags`}
                                        label="Tags"
                                        options={tags}
                                        getOptionLabel={(t) => t.value}
                                        getOptionValue={(t) => t.id}
                                        isOptionEqualToValue={(o, v) => o.id === v.id}
                                        renderOption={(props, t, { selected }) => (
                                            <li {...props}>
                                                <Checkbox
                                                    style={{ marginRight: theme.spacing(1) }}
                                                    checked={selected}
                                                />
                                                {t.value}
                                            </li>
                                        )}
                                        textFieldProps={{
                                            variant: "outlined",
                                        }}
                                        PaperComponent={PaperWrapper}
                                        ChipProps={{
                                            color: "primary",
                                        }}
                                        disabled={!transformAddTags}
                                        multiple
                                        selectOnFocus
                                        clearOnBlur
                                        handleHomeEndKeys
                                        disableCloseOnSelect
                                    />
                                </FormControl>
                            </Accordion>
                        </>
                    )}
                </>
            )}

            {selectedRuleType === ruleType.Periods && (
                <Accordion label="Adjust Menu Periods" name={enableTransformTypePeriods} disableCheckbox>
                    <FormControl component="fieldset">
                        <PeriodsList />
                    </FormControl>
                </Accordion>
            )}
        </Box>
    );
}
