import { AdminlocationReset, ADMINLOCATION_RESET } from "../types";
import {
    Section,
    SectionActions,
    SECTION_PAGE_FETCH,
    SECTION_PAGE_FETCH_SUCCESS,
    SECTION_PAGE_FETCH_ERROR,
} from "./types";

interface SectionsState {
    readonly byUrl: {
        [key: string]: Section;
    };
    readonly byMenuUrl: {
        [key: string]: string[];
    };
    readonly allIds: string[];
    readonly fetching: boolean;
    readonly saving: boolean;
    readonly savingError?: Error;
    readonly fetchingError?: Error;
}

const initialState: SectionsState = {
    byUrl: {},
    byMenuUrl: {},
    allIds: [],
    fetching: false,
    saving: false,
};

export default function sectionsReducer(
    state = initialState,
    action: SectionActions | AdminlocationReset,
): SectionsState {
    switch (action.type) {
        case SECTION_PAGE_FETCH:
            return {
                ...state,
                fetching: true,
            };

        case SECTION_PAGE_FETCH_SUCCESS:
            const newUrls = action.sections.map((s) => new URL(s._links.self.href).pathname);
            const newIds = action.sections.map((m) => m.id);

            return {
                ...state,
                fetching: false,
                byUrl: {
                    ...state.byUrl,
                    ...action.sections.reduce((allSections: { [url: string]: Section }, s) => {
                        const sectionUrl = new URL(s._links.self.href).pathname;
                        allSections[sectionUrl] = s;
                        return allSections;
                    }, {}),
                },
                byMenuUrl: {
                    ...state.byMenuUrl,
                    [action.menuUrl]: [
                        ...(state.byMenuUrl[action.menuUrl] || []).filter((url) => !newUrls.includes(url)),
                        ...newUrls,
                    ],
                },
                allIds: [...state.allIds.filter((id) => !newIds.includes(id)), ...newIds],
            };

        case SECTION_PAGE_FETCH_ERROR:
            return {
                ...state,
                fetching: false,
                fetchingError: action.error,
            };

        case ADMINLOCATION_RESET:
            return initialState;

        default:
            return state;
    }
}
