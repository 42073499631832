import { makeStyles } from "theme";
import { Theme } from "@mui/material";

export const useConfigurationsStyles = makeStyles()((theme: Theme) => ({
    root: {
        "& > .MuiTypography-subtitle1": {
            marginBottom: theme.spacing(1.5),
        },
        "& .MuiAccordionDetails-root": {
            "& > fieldset": {
                flex: 1,
                padding: theme.spacing(0, 4, 2),

                [theme.breakpoints.down("md")]: {
                    "&:not(:last-of-type)": {
                        marginBottom: theme.spacing(2),
                    },

                    "& > .MuiFormControl-root": {
                        "&:not(:last-of-type)": {
                            marginBottom: theme.spacing(2),
                        },
                    },
                },

                "& > legend": {
                    marginBottom: theme.spacing(2),
                },
            },
            [theme.breakpoints.down("md")]: {
                flexDirection: "column",
            },
        },
    },
    iconLabel: {
        display: "inline-flex",
        alignItems: "center",
        padding: theme.spacing(1),

        "& > svg": {
            marginRight: theme.spacing(1.5),
        },
    },
    formError: {
        margin: theme.spacing(2, 0),
    },
    name: {
        marginTop: theme.spacing(4),
    },
}));
