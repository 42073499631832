import { Button, ButtonProps, Grid, SxProps, Theme } from "@mui/material";
import React, { ReactNode } from "react";

interface ActionButtonProps extends ButtonProps {
    label: string;
    onClick?: () => void;
    startIcon?: JSX.Element;
}
export const ActionButton = ({ onClick, label, ...rest }: ActionButtonProps): JSX.Element => {
    return (
        <Button title={label} variant="contained" color="primary" onClick={onClick} {...rest}>
            {label}
        </Button>
    );
};

interface ActionButtonContainerProps {
    children: ReactNode;
    sx?: SxProps<Theme>;
}
export const ActionButtonContainer = ({ children, sx = [] }: ActionButtonContainerProps): JSX.Element => (
    <Grid
        sx={[
            { gap: (theme) => theme.spacing(1), width: { xs: "100%", sm: "max-content" } },
            ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        container
        alignItems="center"
        justifyContent="space-between"
    >
        {children}
    </Grid>
);
