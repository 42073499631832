import { Skeleton } from "@mui/material";
import { SectionAdd } from "components/mms/MenuSections/SectionAdd";
import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { MasterItem } from "store/mms/menus/master/items/types";
import { Section } from "store/mms/menus/master/sections/types";
import { MasterMenu } from "store/mms/menus/master/types";
import { RootState } from "store/rootReducer";
import debounce from "lodash/debounce";
import { sectionItemsSave, sectionsSave } from "store/mms/menus/master/sections/actions";
import { ItemVisibility } from "components/mms/Items/ItemVisibility";
import { SectionItemDelete } from "components/mms/MenuSections/SectionItemDelete";
import { SectionEdit } from "components/mms/MenuSections/SectionEdit";
import { MasterSectionVisibility } from "components/mms/MenuSections/MasterSectionVisibility";
import { SectionDelete } from "components/mms/MenuSections/SectionDelete";
import { ItemAdd } from "components/mms/Items/ItemAdd";
import EditMenuPeriod from "components/mms/MenuPeriods/EditMenuPeriod";
import { MasterMenuPeriodDelete } from "components/mms/MenuPeriods/MasterMenuPeriodDelete";
import { Block, NewPeriod, Period as MasterPeriod } from "store/mms/menus/master/periods/types";
import { periodSave } from "store/mms/menus/master/periods/actions";
import { getEmptyMenuPeriod } from "components/mms/MenuPeriods/helpers";
import { MenuLayer } from "store/mms/menus/master/layers/types";
import { layersSave } from "store/mms/menus/master/layers/actions";
import { MenuLayerDelete } from "components/mms/MenuLayers/MenuLayerDelete";
import { EditMenuLayer } from "components/mms/MenuLayers/EditMenuLayer";
import { MenuLayerVisibility } from "components/mms/MenuLayers/MenuLayerVisibility";
import { RuleSetAdd } from "components/mms/MenuLayers/RuleSets/RuleSetAdd";
import { MasterMenuEdit } from "components/mms/MasterMenuEdit";
import { MenuLayerRuleSet } from "store/mms/menus/master/layers/rule-sets/types";
import { RuleSetVisibility } from "components/mms/MenuLayers/RuleSets/RuleSetVisibility";
import { RuleSetDelete } from "components/mms/MenuLayers/RuleSets/RuleSetDelete";
import { ApplicationActionTargets } from "store/mms/application-actions/types";
import { MasterMenuSync } from "components/mms/MasterMenuSync";

const resequenceDebounce = 1000;

interface MasterMenuContextProps {
    menu: MasterMenu | undefined;
    onChange(action: onChangeActions): void;
}

export const MasterMenuContext = React.createContext<MasterMenuContextProps>({
    menu: undefined,
    onChange: () => {
        /* */
    },
});

interface MasterMenuProviderProps {
    children: ReactNode;
}

export function MasterMenuProvider({ children }: MasterMenuProviderProps): JSX.Element {
    const { menuId } = useParams() as { menuId: string };
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const menusById = useSelector((state: RootState) => state.mms.menus.master.menus.byId);
    const masterMenusFetching = useSelector((state: RootState) => state.mms.menus.master.menus.fetching);

    const menu = menusById[menuId];

    const [sectionEdit, setSectionEdit] = useState<{ section: Section; menu: MasterMenu } | undefined>(undefined);
    const [sectionAdd, setSectionAdd] = useState(false);

    const periodsById = useSelector((state: RootState) => state.mms.menus.master.periods.byId);
    const periodsByMenuId = useSelector((state: RootState) => state.mms.menus.master.periods.byMenuId);
    const periods = (periodsByMenuId[menu?.id] || []).map((id) => periodsById[id]);
    const [periodEdit, setPeriodEdit] = useState<{ period: MasterPeriod | NewPeriod } | undefined>(undefined);

    const [itemAdd, setItemAdd] = useState(false);
    const [editDialog, setEditDialog] = useState<JSX.Element | undefined>(undefined);

    const [state, setState] = useState({ menu, periods });
    const isLoading = masterMenusFetching || state.menu === undefined || menu === undefined;
    const handleResequenceSections = useMemo(
        () =>
            debounce((sections: Section[], menuSectionsUrl: string) => {
                dispatch(sectionsSave(sections, menuSectionsUrl));
            }, resequenceDebounce),
        [dispatch],
    );
    const handleResequenceSectionItems = useMemo(
        () =>
            debounce((section: Section, items: MasterItem[]) => {
                dispatch(sectionItemsSave(section, items));
            }, resequenceDebounce),
        [dispatch],
    );
    const handleChangePeriods = (
        periodToSave: MasterPeriod | NewPeriod,
        blocks: Block[],
        callback?: (error?: Error | undefined) => void,
        disabledSections?: string[],
    ) => dispatch(periodSave(periodToSave, blocks, disabledSections || [], menu._links.periods.href, callback));
    const handleResequenceLayers = useMemo(
        () =>
            debounce((layers: MenuLayer[]) => {
                dispatch(layersSave(menuId, layers));
            }, resequenceDebounce),
        [dispatch, menuId],
    );

    const handleOnCloseDialog = () => setEditDialog(undefined);
    const handleOnChange = (action: onChangeActions) => {
        switch (action.type) {
            case MenuEvent.MenuSelect:
                const id = action.menuId;
                if (menusById[id] !== undefined) {
                    setState((prevState) => ({ ...prevState, menu: menusById[id] }));
                    navigate(location.pathname.replace(/\bmenus\b\/(?:[^\/]*)(.*)/i, `menus/${id}$1`));
                }
                return;
            case MenuEvent.MenuEdit:
                return setEditDialog(<MasterMenuEdit onClose={handleOnCloseDialog} menu={menu} />);
            case MenuEvent.SectionAdd:
                return setSectionAdd(true);

            case MenuEvent.SectionEdit:
                return setSectionEdit({ section: action.section, menu });

            case MenuEvent.SectionDelete:
                return setEditDialog(
                    <SectionDelete
                        onClose={handleOnCloseDialog}
                        open={true}
                        section={action.section}
                        menuName={menu.name}
                    />,
                );

            case MenuEvent.SectionSequence:
                return handleResequenceSections(action.sections, menu._links.sections.href);

            case MenuEvent.SectionVisibility:
                return setEditDialog(
                    <MasterSectionVisibility
                        onClose={handleOnCloseDialog}
                        open={true}
                        section={action.section}
                        menuName={menu.name}
                    />,
                );

            case MenuEvent.ItemAdd:
                return setItemAdd(true);

            case MenuEvent.ItemDelete:
                return setEditDialog(
                    <SectionItemDelete
                        onClose={handleOnCloseDialog}
                        open={true}
                        item={action.item}
                        section={action.section}
                    />,
                );

            case MenuEvent.ItemSequence:
                return handleResequenceSectionItems(action.section, action.items);

            case MenuEvent.ItemVisibility:
                return setEditDialog(<ItemVisibility item={action.item} onClose={handleOnCloseDialog} open={true} />);

            case MenuEvent.PeriodAdd:
                return setPeriodEdit({
                    period: getEmptyMenuPeriod(),
                });

            case MenuEvent.PeriodEdit:
                return setPeriodEdit({ period: action.period });

            case MenuEvent.PeriodDelete:
                return setEditDialog(
                    <MasterMenuPeriodDelete onClose={handleOnCloseDialog} open={true} period={action.period} />,
                );

            case MenuEvent.LayerAdd:
                return setEditDialog(<EditMenuLayer onClose={handleOnCloseDialog} menu={menu} />);

            case MenuEvent.LayerEdit:
                return setEditDialog(<EditMenuLayer onClose={handleOnCloseDialog} menu={menu} layer={action.layer} />);

            case MenuEvent.LayerDelete:
                return setEditDialog(
                    <MenuLayerDelete open={true} onClose={handleOnCloseDialog} layer={action.layer} />,
                );

            case MenuEvent.LayerSequence:
                return handleResequenceLayers(action.layers);

            case MenuEvent.LayerVisibility:
                return setEditDialog(
                    <MenuLayerVisibility
                        open={true}
                        onClose={handleOnCloseDialog}
                        menuId={menu.id}
                        layer={action.layer}
                    />,
                );

            case MenuEvent.RuleSetAdd:
                return setEditDialog(<RuleSetAdd onClose={handleOnCloseDialog} layer={action.layer} />);

            case MenuEvent.RuleSetDelete:
                return setEditDialog(
                    <RuleSetDelete onClose={handleOnCloseDialog} layer={action.layer} ruleSet={action.ruleSet} />,
                );

            case MenuEvent.RuleSetVisibility:
                return setEditDialog(
                    <RuleSetVisibility
                        layerId={action.layerId}
                        ruleSet={action.ruleSet}
                        onClose={handleOnCloseDialog}
                    />,
                );

            case MenuEvent.MenuSync:
                return setEditDialog(
                    <MasterMenuSync open={true} onClose={handleOnCloseDialog} targets={action.targets} />,
                );
        }
    };

    useEffect(() => {
        if (menu === undefined || state.menu === menu) {
            return;
        }

        setState((prevState) => ({ ...prevState, menu }));
    }, [state, menu]);

    return (
        <MasterMenuContext.Provider
            value={{
                ...state,
                onChange: handleOnChange,
            }}
        >
            <>
                <SectionAdd open={sectionAdd} onClose={() => setSectionAdd(false)} />

                <SectionEdit onClose={() => setSectionEdit(undefined)} open={Boolean(sectionEdit)} {...sectionEdit} />

                <ItemAdd open={itemAdd} onClose={() => setItemAdd(false)} />

                <EditMenuPeriod
                    isMaster
                    onSave={handleChangePeriods}
                    period={periodEdit?.period}
                    onClose={() => setPeriodEdit(undefined)}
                    open={Boolean(periodEdit)}
                />

                {editDialog}
                {isLoading ? <Skeleton /> : children}
            </>
        </MasterMenuContext.Provider>
    );
}

export enum MenuEvent {
    MenuSync,
    MenuSelect,
    MenuEdit,

    SectionAdd,
    SectionEdit,
    SectionDelete,
    SectionSequence,
    SectionVisibility,

    ItemAdd,
    ItemDelete,
    ItemSequence,
    ItemVisibility,

    PeriodAdd,
    PeriodEdit,
    PeriodDelete,

    LayerAdd,
    LayerEdit,
    LayerDelete,
    LayerSequence,
    LayerVisibility,

    RuleSetAdd,
    RuleSetDelete,
    RuleSetVisibility,
}

interface menuSync {
    type: MenuEvent.MenuSync;
    targets: ApplicationActionTargets;
}

interface menuSelect {
    type: MenuEvent.MenuSelect;
    menuId: string;
}

interface menuEdit {
    type: MenuEvent.MenuEdit;
}
interface sectionAdd {
    type: MenuEvent.SectionAdd;
}
interface sectionEdit {
    type: MenuEvent.SectionEdit;
    section: Section;
}
interface sectionDelete {
    type: MenuEvent.SectionDelete;
    section: Section;
}
interface sectionSequence {
    type: MenuEvent.SectionSequence;
    sections: Section[];
}
interface sectionVisibility {
    type: MenuEvent.SectionVisibility;
    section: Section;
}

interface itemAdd {
    type: MenuEvent.ItemAdd;
}

interface itemDelete {
    type: MenuEvent.ItemDelete;
    section: Section;
    item: MasterItem;
}
interface itemSequence {
    type: MenuEvent.ItemSequence;
    section: Section;
    items: MasterItem[];
}
interface itemVisibility {
    type: MenuEvent.ItemVisibility;
    item: MasterItem;
}

interface periodAdd {
    type: MenuEvent.PeriodAdd;
}
interface periodEdit {
    type: MenuEvent.PeriodEdit;
    period: MasterPeriod;
}
interface periodDelete {
    type: MenuEvent.PeriodDelete;
    period: MasterPeriod;
}

interface layerAdd {
    type: MenuEvent.LayerAdd;
}
interface layerEdit {
    type: MenuEvent.LayerEdit;
    layer: MenuLayer;
}
interface layerDelete {
    type: MenuEvent.LayerDelete;
    layer: MenuLayer;
}
interface layerSequence {
    type: MenuEvent.LayerSequence;
    layers: MenuLayer[];
}
interface layerVisibility {
    type: MenuEvent.LayerVisibility;
    layer: MenuLayer;
}

interface ruleSetAdd {
    type: MenuEvent.RuleSetAdd;
    layer: MenuLayer;
}

interface ruleSetDelete {
    type: MenuEvent.RuleSetDelete;
    layer: MenuLayer;
    ruleSet: MenuLayerRuleSet;
}

interface ruleSetVisibility {
    type: MenuEvent.RuleSetVisibility;
    layerId: string;
    ruleSet: MenuLayerRuleSet;
}

type onChangeActions =
    | menuSync
    | menuSelect
    | menuEdit
    | sectionAdd
    | sectionEdit
    | sectionDelete
    | sectionSequence
    | sectionVisibility
    | itemAdd
    | itemDelete
    | itemSequence
    | itemVisibility
    | periodAdd
    | periodEdit
    | periodDelete
    | layerAdd
    | layerEdit
    | layerSequence
    | layerVisibility
    | layerDelete
    | ruleSetAdd
    | ruleSetDelete
    | ruleSetVisibility;
