import { Skeleton, Theme } from "@mui/material";
import { makeStyles } from "theme";
import DisplayPOSConfig from "components/mms/DisplayPOSConfig";
import EntityVisibility from "components/mms/EntityVisibility";
import { SidebarContent, SidebarHeader, SidebarSection } from "components/Sidebar";
import React from "react";
import { MasterModifier } from "store/mms/menus/master/items/modifiers/types";
import { MasterItem } from "store/mms/menus/master/items/types";
import { getEntityPOSConfig } from "store/mms/types";

export const useSharedEntityPageStyle = makeStyles()((theme: Theme) => ({
    title: {
        marginBottom: theme.spacing(3),
    },
    details: {
        marginBottom: theme.spacing(3),
    },
    nutrition: {
        marginTop: theme.spacing(1),
    },
}));

interface DetailsSidebarSectionProps {
    isItem?: boolean;
    entityId: string;
    entity?: MasterItem | MasterModifier;
    onChangeVisibility: (visible: boolean) => void;
    onEditPOSConfig: () => void;
}

export function DetailsSidebarSection({
    isItem = false,
    entityId,
    entity,
    onChangeVisibility,
    onEditPOSConfig,
}: DetailsSidebarSectionProps): JSX.Element {
    const { classes } = useDetailsSidebarSectionStyle();
    const header = (
        <SidebarHeader>
            {isItem ? "Item" : "Modifier"} Details (ID: {entityId})
        </SidebarHeader>
    );

    if (entity === undefined) {
        return (
            <SidebarSection>
                {header}
                <SidebarContent>
                    <Skeleton height={75} className={classes.skeletonContent} />
                    <DisplayPOSConfig loading />
                </SidebarContent>
            </SidebarSection>
        );
    }

    return (
        <SidebarSection>
            {header}
            <SidebarContent>
                <EntityVisibility
                    className={classes.visibility}
                    visible={entity.enabled}
                    onChange={onChangeVisibility}
                />
                <DisplayPOSConfig
                    isModifier={!isItem}
                    posConfig={getEntityPOSConfig(entity?.pos_config)}
                    onClickEdit={onEditPOSConfig}
                    isLinked={entity?.linked || false}
                />
            </SidebarContent>
        </SidebarSection>
    );
}

const useDetailsSidebarSectionStyle = makeStyles()((theme: Theme) => ({
    visibility: {
        marginBottom: theme.spacing(3),
    },
    skeletonContent: {
        marginTop: theme.spacing(-2),
    },
}));
