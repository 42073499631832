import { call, ForkEffect, put, takeLatest } from "redux-saga/effects";
import { core } from "services/api";
import { enqueueSnackbar } from "store/notifications/actions";
import {
    taggedEntityUpdateError,
    taggedEntityUpdateSuccess,
    tagsFetch,
    tagsFetchComplete,
    tagsFetchError,
    tagsFetchSuccess,
} from "./actions";
import { Tag, TaggedEntityUpdate, TAGGED_ENTITY_UPDATE, TagsFetch, TAGS_FETCH } from "./types";

interface PageResponse {
    _embedded: {
        tags: Tag[];
    };
    _links: {
        next?: {
            href: string;
            type: "application/hal+json; name=mms_tag_list";
        };

        self: {
            href: string;
            type: "application/hal+json; name=mms_tag_list";
        };
    };
}

function* getTags({ url }: TagsFetch) {
    try {
        const resp: { data: PageResponse } = yield call(core.get, url);
        yield put(tagsFetchSuccess(resp.data._embedded.tags));

        if (resp.data._links.next !== undefined) {
            yield put(tagsFetch(resp.data._links.next.href));
        } else {
            yield put(tagsFetchComplete());
        }
    } catch (e) {
        console.error("Failed to fetch tag list", e);
        if (e instanceof Error) {
            yield put(tagsFetchError(e));
        }
    }
}

function* updateTags({ entity, tags }: TaggedEntityUpdate) {
    try {
        const req = { tags: tags.map((tag) => ({ tag: tag.id })) };
        yield call(core.put, entity._links.tags.href, req);
        yield put(taggedEntityUpdateSuccess(entity, tags));
    } catch (e) {
        console.error("Failed to update tags for entity", e);
        if (e instanceof Error) {
            yield put(taggedEntityUpdateError(e));
        }
        yield put(
            enqueueSnackbar({
                message: "Error saving Tags. Try again later.",
                options: { variant: "error" },
            }),
        );
    }
}

export default function* tagsSaga(): Generator<ForkEffect<void>, void, unknown> {
    yield takeLatest(TAGS_FETCH, getTags);
    yield takeLatest(TAGGED_ENTITY_UPDATE, updateTags);
}
