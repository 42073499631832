import { byHref } from "store/types";
import { AdminlocationReset, ADMINLOCATION_RESET } from "../../types";
import {
    LocationModifier,
    LocationModifierActions,
    LOCATION_MODIFIERS_HYDRATE,
    LOCATION_MODIFIER_FETCH,
    LOCATION_MODIFIER_FETCH_ERROR,
    LOCATION_MODIFIER_FETCH_SUCCESS,
    LOCATION_MODIFIER_SAVE,
    LOCATION_MODIFIER_SAVE_ERROR,
    LOCATION_MODIFIER_SAVE_SUCCESS,
} from "./types";

interface LocationModifiersState {
    readonly fetching: boolean;
    readonly byHref: { [key: string]: LocationModifier };
    readonly hrefsById: { [key: string]: string[] };
    readonly allIds: string[];
    readonly saving: boolean;
}

const initialState: LocationModifiersState = { fetching: false, byHref: {}, hrefsById: {}, allIds: [], saving: false };

export default function locationModifierReducer(
    state = initialState,
    action: LocationModifierActions | AdminlocationReset,
): LocationModifiersState {
    switch (action.type) {
        case LOCATION_MODIFIER_FETCH:
            return {
                ...state,
                fetching: true,
            };

        case LOCATION_MODIFIER_FETCH_SUCCESS:
            return {
                ...state,
                byHref: {
                    ...state.byHref,
                    [action.modifier._links.self.href]: action.modifier,
                },
                hrefsById: {
                    ...state.hrefsById,
                    [action.modifier.id]: [
                        ...(state.hrefsById[action.modifier.id] || []),
                        action.modifier._links.self.href,
                    ],
                },
                allIds: [...state.allIds.filter((id) => id !== action.modifier.id), action.modifier.id],
                fetching: false,
            };

        case LOCATION_MODIFIER_FETCH_ERROR:
            return {
                ...state,
                fetching: false,
            };

        case LOCATION_MODIFIER_SAVE:
            return {
                ...state,
                saving: true,
            };

        case LOCATION_MODIFIER_SAVE_SUCCESS:
            return {
                ...state,
                byHref: {
                    ...state.byHref,
                    [action.modifier._links.self.href]: action.modifier,
                },
                hrefsById: {
                    ...state.hrefsById,
                    [action.modifier.id]: [
                        ...(state.hrefsById[action.modifier.id] || []),
                        action.modifier._links.self.href,
                    ],
                },
                allIds: [...state.allIds.filter((id) => id !== action.modifier.id), action.modifier.id],
                saving: false,
            };

        case LOCATION_MODIFIER_SAVE_ERROR:
            return {
                ...state,
                saving: false,
            };

        case LOCATION_MODIFIERS_HYDRATE:
            const newIds = action.modifiers.map((m) => m.id);
            const hrefsById = action.modifiers.reduce((ids: { [key: string]: string[] }, m) => {
                if (!ids[m.id]) {
                    ids[m.id] = [...(state.hrefsById[m.id] || []), m._links.self.href];
                } else {
                    ids[m.id].push(m._links.self.href);
                }
                return ids;
            }, {});

            return {
                ...state,
                byHref: {
                    ...state.byHref,
                    ...byHref(action.modifiers),
                },
                hrefsById: {
                    ...state.hrefsById,
                    ...hrefsById,
                },
                allIds: [...state.allIds.filter((id) => !newIds.includes(id)), ...newIds],
            };

        case ADMINLOCATION_RESET:
            return initialState;

        default:
            return state;
    }
}
