import { byId } from "store/types";
import {
    ModifierGroup,
    ModifierGroupActions,
    MODIFIER_GROUP_SAVE,
    MODIFIER_GROUP_SAVE_SUCCESS,
    MODIFIER_GROUP_SAVE_ERROR,
    MODIFIER_GROUPS_HYDRATE,
    MODIFIER_GROUP_MODIFERS_SAVE,
    MODIFIER_GROUP_MODIFERS_SAVE_ERROR,
} from "./types";

interface ModifierGroupsState {
    readonly byId: { [key: string]: ModifierGroup };
    readonly allIds: string[];
    readonly saving: boolean;
    readonly saveError?: Error;
}

const initialState: ModifierGroupsState = { byId: {}, allIds: [], saving: false };

export default function modifierGroupsReducer(state = initialState, action: ModifierGroupActions): ModifierGroupsState {
    switch (action.type) {
        case MODIFIER_GROUP_SAVE:
            return {
                ...state,
                saving: true,
            };

        case MODIFIER_GROUP_SAVE_SUCCESS:
            return {
                ...state,
                saving: false,
                byId: {
                    ...state.byId,
                    [action.group.id]: action.group,
                },
                allIds: [...state.allIds.filter((id) => id !== action.group.id), action.group.id],
            };

        case MODIFIER_GROUP_SAVE_ERROR:
            return {
                ...state,
                saving: false,
                saveError: action.error,
            };

        case MODIFIER_GROUP_MODIFERS_SAVE:
            const newModIds = action.modifiers.map((mgm) => mgm.id);
            const group = {
                ...action.group,
                _embedded: {
                    modifiers: action.group._embedded.modifiers
                        .filter((mgm) => newModIds.includes(mgm.id))
                        .sort((a, b) => newModIds.indexOf(a.id) - newModIds.indexOf(b.id)),
                },
            };
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [group.id]: group,
                },
            };

        case MODIFIER_GROUP_MODIFERS_SAVE_ERROR:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.group.id]: action.group,
                },
            };

        case MODIFIER_GROUPS_HYDRATE:
            const newIds = action.groups.map((g) => g.id);

            return {
                ...state,
                byId: {
                    ...state.byId,
                    ...byId(action.groups),
                },
                allIds: [...state.allIds.filter((id) => !newIds.includes(id)), ...newIds],
            };

        default:
            return state;
    }
}
