import { Alert, Button, Grid, IconButton, Skeleton, TextField, Tooltip } from "@mui/material";
import { FormSubmit } from "components/common/FormSubmit";
import { OmniDrawer } from "components/common/OmniDrawer";
import React, { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/rootReducer";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { Mutator } from "final-form";
import { LinkTime } from "store/mms/link-times/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/pro-solid-svg-icons";
import { linkTimesPageFetch, linkTimesSave } from "store/mms/link-times/actions";
import { LocalizationProvider, TimePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { format, parse } from "date-fns";
import isEmpty from "lodash/isEmpty";
import { handleErrors, mapToFormErrors } from "components/common/forms/helpers";

interface POSSyncTimesProps {
    onClose(): void;
}

export function POSSyncTimes({ onClose }: POSSyncTimesProps): JSX.Element {
    const dispatch = useDispatch();
    const [fetchedLinkTimes, setFetchedLinkTimes] = useState(false);
    const linkTimesById = useSelector((state: RootState) => state.mms.linkTimes.byId);
    const linkTimesFetching = useSelector((state: RootState) => state.mms.linkTimes.fetching);

    // This has to exist because mutators still don't support Typescript.
    const mutators: { [x: string]: Mutator<POSSyncTimesFormValues, POSSyncTimesFormValues> } = {};
    for (const [key, value] of Object.entries(arrayMutators)) {
        mutators[key] = value as Mutator<POSSyncTimesFormValues, POSSyncTimesFormValues>;
    }

    const isLoading = !fetchedLinkTimes || linkTimesFetching;

    useEffect(() => {
        if (!linkTimesFetching && !fetchedLinkTimes) {
            dispatch(linkTimesPageFetch());
            setFetchedLinkTimes(true);
        }
    }, [dispatch, fetchedLinkTimes, linkTimesFetching]);

    return (
        <OmniDrawer title="Edit POS Sync Times" close={onClose} open={true}>
            {isLoading ? (
                <form>
                    <Skeleton />
                </form>
            ) : (
                <Form
                    initialValues={{ linkTimes: Object.entries(linkTimesById).map(([, linkTime]) => linkTime) }}
                    onSubmit={(values, _, errorsCallback) => {
                        dispatch(
                            linkTimesSave(values.linkTimes, (errors) => {
                                if (errors) {
                                    return errorsCallback && errorsCallback(mapToFormErrors(errors));
                                }
                                return onClose();
                            }),
                        );
                    }}
                    mutators={{ ...mutators }}
                >
                    {({
                        handleSubmit,
                        submitError,
                        form: {
                            mutators: { push },
                        },
                    }) => (
                        <form onSubmit={handleSubmit} noValidate>
                            <Grid
                                container
                                spacing={2}
                                flexDirection="column"
                                sx={{ marginBottom: (theme) => theme.spacing(2) }}
                            >
                                <FieldArray
                                    name="linkTimes"
                                    validate={(v) => {
                                        const uniqLinkTimes = new Set<string>();
                                        const linkTimes = v as LinkTime[];
                                        const formErrors: POSSyncTimesError[] = [];

                                        linkTimes.forEach((lt) => {
                                            const error: POSSyncTimesError = {};

                                            if (!uniqLinkTimes.has(lt.link_time)) {
                                                uniqLinkTimes.add(lt.link_time);
                                            } else {
                                                error.link_time = "Sync Times must be unique.";
                                            }

                                            formErrors.push(error);
                                        });

                                        if (formErrors.some((e) => !isEmpty(e))) {
                                            return formErrors;
                                        }
                                        return undefined;
                                    }}
                                >
                                    {({ fields }) =>
                                        fields.length ? (
                                            <>
                                                <Grid item flexGrow="1">
                                                    <Grid container spacing={2} alignItems="center" flexGrow="1">
                                                        <Grid item flexGrow="1">
                                                            <Alert variant="outlined" severity="info">
                                                                All Locations will be synced with their POS at the below{" "}
                                                                <em>local</em> times. POS Sync Times must be at least an
                                                                hour apart.
                                                            </Alert>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                {fields.map((name, index) => (
                                                    <Grid item key={index} flexGrow="1">
                                                        <Grid container alignItems="center" flexGrow="1">
                                                            <Grid item>
                                                                <Field
                                                                    name={`${name}.link_time`}
                                                                    format={(v) => parse(v, "HH:mm", new Date())}
                                                                    parse={(v) => format(v, "HH:mm")}
                                                                >
                                                                    {({ input, meta }) => (
                                                                        <LocalizationProvider
                                                                            dateAdapter={AdapterDateFns}
                                                                        >
                                                                            <TimePicker
                                                                                {...input}
                                                                                inputFormat="hh:mm a"
                                                                                mask="__:__ a"
                                                                                views={["hours", "minutes"]}
                                                                                renderInput={(params) => (
                                                                                    <TextField
                                                                                        {...params}
                                                                                        {...handleErrors(meta)}
                                                                                    />
                                                                                )}
                                                                            />
                                                                        </LocalizationProvider>
                                                                    )}
                                                                </Field>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                sx={(theme) => ({
                                                                    alignSelf: "flex-start",
                                                                    marginLeft: theme.spacing(2),
                                                                    marginTop: theme.spacing(1),
                                                                })}
                                                            >
                                                                <IconButton
                                                                    title="Remove Sync Time"
                                                                    onClick={() => fields.remove(index)}
                                                                >
                                                                    <Tooltip title="Remove Sync Time" arrow>
                                                                        <DeleteIcon />
                                                                    </Tooltip>
                                                                </IconButton>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                ))}
                                            </>
                                        ) : (
                                            <Grid item flexGrow="1">
                                                <Grid container spacing={2} alignItems="center" flexGrow="1">
                                                    <Grid item flexGrow="1">
                                                        <Alert variant="outlined" severity="info">
                                                            Each Location automatically syncs with the POS 2 hours
                                                            before the start of its first Menu Period. If you want to
                                                            control this, you can manually configure POS sync times.
                                                        </Alert>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )
                                    }
                                </FieldArray>
                            </Grid>
                            <Button
                                onClick={() => push("linkTimes", { link_time: "08:00" })}
                                startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
                            >
                                Add Sync Time
                            </Button>

                            {submitError && (
                                <Alert variant="outlined" severity="error">
                                    {submitError}
                                </Alert>
                            )}

                            <FormSubmit label="Save POS Sync Times" onClose={onClose} />
                        </form>
                    )}
                </Form>
            )}
        </OmniDrawer>
    );
}

interface POSSyncTimesFormValues {
    linkTimes: LinkTime[];
}

interface POSSyncTimesError {
    link_time?: string;
}
