import { MasterMenu } from "../types";
import {
    LAYERS_PAGE_FETCH,
    LAYERS_PAGE_FETCH_ERROR,
    LAYERS_PAGE_FETCH_SUCCESS,
    LAYERS_SAVE,
    LAYERS_SAVE_ERROR,
    LAYERS_SAVE_SUCCESS,
    LAYER_ADD,
    LAYER_ADD_SUCCESS,
    MenuLayer,
    MenuLayerActions,
    NewMenuLayer,
} from "./types";

export function layersPageFetch(menu: MasterMenu, url?: string): MenuLayerActions {
    return {
        type: LAYERS_PAGE_FETCH,
        menu,
        url: url || menu._links.layers.href,
    };
}

export function layersPageFetchSuccess(menuId: string, layers: MenuLayer[]): MenuLayerActions {
    return {
        type: LAYERS_PAGE_FETCH_SUCCESS,
        menuId,
        layers,
    };
}

export function layersPageFetchError(error: Error): MenuLayerActions {
    return {
        type: LAYERS_PAGE_FETCH_ERROR,
        error,
    };
}

export function layersSave(menuId: string, layers: MenuLayer[], callback?: (error?: Error) => void): MenuLayerActions {
    return {
        type: LAYERS_SAVE,
        menuId,
        layers,
        callback,
    };
}

export function layersSaveSuccess(menuId: string, layers: MenuLayer[]): MenuLayerActions {
    return {
        type: LAYERS_SAVE_SUCCESS,
        menuId,
        layers,
    };
}

export function layersSaveError(error: Error): MenuLayerActions {
    return {
        type: LAYERS_SAVE_ERROR,
        error,
    };
}

export function layerAdd(menu: MasterMenu, layer: NewMenuLayer, callback?: (error?: Error) => void): MenuLayerActions {
    return {
        type: LAYER_ADD,
        menu,
        layer,
        callback,
    };
}

export function layerAddSuccess(menuId: string, layer: MenuLayer): MenuLayerActions {
    return {
        type: LAYER_ADD_SUCCESS,
        menuId,
        layer,
    };
}
