import { fork, ForkEffect } from "redux-saga/effects";
import { applicationActionsSaga } from "./application-actions/effects";
import jobsSaga from "./jobs/effects";
import linkTimesSaga from "./link-times/effects";
import menusSaga from "./menus/effects";
import taxRatesSaga from "./menus/location/taxes/effects";
import { orderProfilesSaga } from "./order-profiles/effects";
import tagsSaga from "./tags/effects";

export default function* mmsSaga(): Generator<ForkEffect<void>, void, unknown> {
    yield fork(orderProfilesSaga);
    yield fork(menusSaga);
    yield fork(jobsSaga);
    yield fork(tagsSaga);
    yield fork(taxRatesSaga);
    yield fork(linkTimesSaga);
    yield fork(applicationActionsSaga);
}
