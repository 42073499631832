import { AdminlocationReset, ADMINLOCATION_RESET } from "../types";
import {
    TaxRate,
    TaxRateActions,
    TAX_RATES_FETCH,
    TAX_RATES_FETCH_SUCCESS,
    TAX_RATES_FETCH_COMPLETE,
    TAX_RATES_SAVE_SUCCESS,
    TAX_RATE_SAVE_SUCCESS,
    TAX_RATE_DELETE_SUCCESS,
} from "./types";

interface TaxRatesState {
    readonly byId: {
        [key: string]: TaxRate;
    };
    readonly idsByLocationId: {
        [key: string]: string[];
    };
    readonly tagIdsByTaxRateId: {
        [key: string]: string[];
    };

    readonly fetching: boolean;
    readonly fetchingError?: Error;
}

const initialState: TaxRatesState = {
    byId: {},
    idsByLocationId: {},
    tagIdsByTaxRateId: {},
    fetching: false,
};

export default function taxRates(state = initialState, action: TaxRateActions | AdminlocationReset): TaxRatesState {
    switch (action.type) {
        case TAX_RATES_FETCH:
            return {
                ...state,
                fetching: true,
            };

        case TAX_RATES_FETCH_SUCCESS:
        case TAX_RATES_SAVE_SUCCESS:
            const newIds = action.taxRates.map((tr) => tr.id);

            return {
                ...state,
                fetching: false,
                byId: {
                    ...state.byId,
                    ...action.taxRates.reduce((allTaxRates, r) => ({ ...allTaxRates, [r.id]: r }), {}),
                },
                idsByLocationId: {
                    ...state.idsByLocationId,
                    [action.locationId]: [
                        ...(state.idsByLocationId[action.locationId] || []).filter((id) => !newIds.includes(id)),
                        ...newIds,
                    ],
                },
                tagIdsByTaxRateId: {
                    ...state.tagIdsByTaxRateId,
                    ...action.taxRates.reduce(
                        (allTaxRates, r) => ({ ...allTaxRates, [r.id]: r._embedded.tags.map((t) => t.id) }),
                        {},
                    ),
                },
            };

        case TAX_RATES_FETCH_COMPLETE:
            return {
                ...state,
                fetching: false,
                fetchingError: undefined,
            };

        case TAX_RATE_SAVE_SUCCESS:
            const idsByLocationId = [...state.idsByLocationId[action.locationId]] || [];

            if (!idsByLocationId.includes(action.taxRate.id)) {
                idsByLocationId.unshift(action.taxRate.id);
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.taxRate.id]: action.taxRate,
                },
                idsByLocationId: {
                    ...state.idsByLocationId,
                    [action.locationId]: idsByLocationId,
                },
                tagIdsByTaxRateId: {
                    [action.taxRate.id]: [...action.taxRate._embedded.tags.map((t) => t.id)],
                },
            };

        case TAX_RATE_DELETE_SUCCESS:
            return {
                ...state,
                byId: Object.values(state.byId).reduce((byId: { [key: string]: TaxRate }, tr: TaxRate) => {
                    if (tr.id !== action.taxRate.id) {
                        byId[tr.id] = tr;
                    }
                    return byId;
                }, {}),
                idsByLocationId: {
                    ...state.idsByLocationId,
                    [action.locationId]: state.idsByLocationId[action.locationId].filter(
                        (id) => id !== action.taxRate.id,
                    ),
                },
            };

        case ADMINLOCATION_RESET:
            return initialState;

        default:
            return state;
    }
}
