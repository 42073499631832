export const JOB_CREATE = "@JOB/CREATE";
export const JOB_CREATE_ERROR = "@JOB/CREATE/ERROR";

// taken from https://github.com/omnivore/giganto/blob/09a6cb6/om/backend/internal/models/mms_job.go#L34
export enum JobType {
    Import = "import",
    Link = "link",
    Scaffold = "scaffold",
}

export interface Job {
    location?: string | null;
    menu?: string | null;
    type: JobType;
    diff_tolerance?: number;
}

interface JobCreate {
    job: Job;
    url: string;
    type: typeof JOB_CREATE;
}

interface JobCreateError {
    type: typeof JOB_CREATE_ERROR;
    error: Error;
}

export type JobActions = JobCreate | JobCreateError;
