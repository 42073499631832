import { byId } from "store/types";
import {
    OptionSet,
    OptionSetsActions,
    OPTION_SET_CREATE_SUCCESS,
    OPTION_SET_DELETE,
    OPTION_SET_DELETE_ERROR,
    OPTION_SET_DELETE_SUCCESS,
    OPTION_SET_FETCH,
    OPTION_SET_FETCH_ERROR,
    OPTION_SET_FETCH_SUCCESS,
    OPTION_SET_PAGE_FETCH,
    OPTION_SET_PAGE_FETCH_ERROR,
    OPTION_SET_PAGE_FETCH_SUCCESS,
    OPTION_SET_SAVE_SUCCESS,
    OPTION_SET_MODIFIER_SAVE_SUCCESS,
    OPTION_SETS_HYDRATE,
} from "./types";

interface OptionSetState {
    readonly allIds: string[];
    readonly byId: { [key: string]: OptionSet };
    readonly fetching: boolean;
    readonly fetchingError?: Error;
    readonly idsByParentId: { [key: string]: string[] };
    readonly pageRequestCount: number;
    readonly saving: boolean;
    readonly deleting: boolean;
    readonly deleteError?: Error;
}

const initialState: OptionSetState = {
    byId: {},
    allIds: [],
    idsByParentId: {},
    fetching: false,
    pageRequestCount: 0,
    saving: false,
    deleting: false,
};
const getParentId = (os: OptionSet): string =>
    os._links.self.href.replace(/.+(?:\bitems\b|\bmodifiers\b)\/([^\/]+).+/, "$1");

export default function optionSetsReducer(state = initialState, action: OptionSetsActions): OptionSetState {
    switch (action.type) {
        case OPTION_SET_FETCH:
            return {
                ...state,
                fetching: true,
            };

        case OPTION_SET_CREATE_SUCCESS:
        case OPTION_SET_SAVE_SUCCESS:
        case OPTION_SET_FETCH_SUCCESS:
            const parentId = getParentId(action.optionSet);
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.optionSet.id]: action.optionSet,
                },
                allIds: [...state.allIds.filter((id) => id !== action.optionSet.id), action.optionSet.id],
                idsByParentId: {
                    ...state.idsByParentId,
                    [parentId]: [
                        ...(state.idsByParentId[parentId] || []).filter((id) => id !== action.optionSet.id),
                        action.optionSet.id,
                    ],
                },
                fetching: false,
            };

        case OPTION_SET_FETCH_ERROR:
            return {
                ...state,
                fetching: false,
                fetchingError: action.error,
            };

        case OPTION_SET_PAGE_FETCH:
            return {
                ...state,
                pageRequestCount: state.pageRequestCount + 1,
            };

        case OPTION_SETS_HYDRATE:
        case OPTION_SET_PAGE_FETCH_SUCCESS:
            const newIds = action.optionSets.map((os) => os.id);
            const newIdsByParentId = action.optionSets.reduce((vals: { [key: string]: string[] }, os) => {
                const parentId = getParentId(os);
                const uniqueOSIDs = [
                    ...new Set([...(state.idsByParentId[parentId] || []), ...(vals[parentId] || []), os.id]),
                ];
                vals[parentId] = uniqueOSIDs;
                return vals;
            }, {});
            const newPageRequestCount = state.pageRequestCount <= 0 ? 0 : state.pageRequestCount - 1;
            return {
                ...state,
                byId: {
                    ...state.byId,
                    ...byId(action.optionSets),
                },
                allIds: [...state.allIds.filter((id) => !newIds.includes(id)), ...newIds],
                idsByParentId: {
                    ...state.idsByParentId,
                    ...newIdsByParentId,
                },
                pageRequestCount: newPageRequestCount,
            };

        case OPTION_SET_PAGE_FETCH_ERROR:
            return {
                ...state,
                pageRequestCount: state.pageRequestCount - 1,
                fetchingError: action.error,
            };

        case OPTION_SET_MODIFIER_SAVE_SUCCESS:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.optionSet.id]: {
                        ...action.optionSet,
                        _embedded: {
                            ...action.optionSet._embedded,
                            modifiers: action.optionSet._embedded.modifiers.map((osm) => {
                                if (osm.id === action.optionSetModifier.id) {
                                    return action.optionSetModifier;
                                }
                                return osm;
                            }),
                        },
                    } as OptionSet,
                },
            };

        case OPTION_SET_DELETE:
            return {
                ...state,
                deleting: true,
            };

        case OPTION_SET_DELETE_SUCCESS:
            return {
                ...state,
                deleting: false,
                byId: Object.entries(state.byId).reduce((newById: { [key: string]: OptionSet }, [osId, os]) => {
                    if (osId !== action.optionSet.id) {
                        newById[osId] = os;
                    }
                    return newById;
                }, {}),
                allIds: state.allIds.filter((id) => id !== action.optionSet.id),
                idsByParentId: Object.entries(state.idsByParentId).reduce(
                    (newIds: { [key: string]: string[] }, [parentId, optionSetIds]) => {
                        const remainingIds = optionSetIds.filter((id) => id !== action.optionSet.id);

                        if (remainingIds.length > 0) {
                            newIds[parentId] = remainingIds;
                        }
                        return newIds;
                    },
                    {},
                ),
            };

        case OPTION_SET_DELETE_ERROR:
            return {
                ...state,
                deleting: false,
                deleteError: action.error,
            };

        default:
            return state;
    }
}
