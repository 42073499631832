import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    useTheme,
    Container,
    Typography,
    Grid,
    FormControlLabel,
    Switch,
    Tooltip,
    TextField,
    Theme,
} from "@mui/material";
import { makeStyles } from "theme";
import { AutoSave } from "components/common/forms/AutoSave";
import { getIn } from "final-form";
import React from "react";
import { Form, Field } from "react-final-form";
import EntityVisibility from "../EntityVisibility";
import { OptionSet as MasterOptionSet } from "store/mms/menus/master/items/option-sets/types";
import { ModifierGroup } from "store/mms/menus/master/items/option-sets/modifier-group/types";

const optionSetAsideStyles = makeStyles()((theme: Theme) => ({
    root: {
        padding: theme.spacing(0, 0, 3, 3),
        "& > hr": {
            marginBottom: theme.spacing(4),
        },
    },

    selections: {
        marginTop: theme.spacing(4),
    },

    selectionTitle: {
        marginBottom: theme.spacing(-1),
    },

    switchLabel: {
        userSelect: "none",
    },
}));

interface formValues {
    enabled: boolean;
    required: boolean;
    implicit: boolean;
    max: string;
    min: string;
}

interface OptionSetAsideProps {
    group: ModifierGroup;
    onChange: (enabled: boolean, implicit: boolean, required: boolean, max: number, min: number) => void;
    optionSet: MasterOptionSet;
}

export function OptionSetAside({ group, onChange, optionSet }: OptionSetAsideProps): JSX.Element {
    const { classes } = optionSetAsideStyles();
    const theme = useTheme();
    const groupModsCount = group._embedded.modifiers.length;
    const handleSubmit = ({ enabled, implicit, required, max, min }: formValues): void =>
        onChange(enabled, implicit, required, Number(max), Number(min));
    const getMaxMin = (prop: "min" | "max"): string => {
        if (optionSet.implicit) {
            return groupModsCount.toString();
        }
        return (optionSet[prop] > groupModsCount ? groupModsCount : optionSet[prop]).toString();
    };

    return (
        <Container className={classes.root}>
            <Typography variant="subtitle2" title="entity-details">
                {`Modifier Group Details (ID: ${optionSet.id})`}
            </Typography>

            <hr />

            <Form
                initialValues={{
                    enabled: optionSet.enabled,
                    required: optionSet.required,
                    implicit: optionSet.implicit,
                    max: getMaxMin("max"),
                    min: getMaxMin("min"),
                }}
                /* NOT USED, but required by RFF */
                onSubmit={handleSubmit}
                subscription={{ values: true }}
            >
                {({ form: { change }, values }) => (
                    <form>
                        <Grid className={classes.selections} container spacing={4}>
                            <AutoSave debounce={1000} onSave={(values: formValues) => handleSubmit(values)} />

                            <Grid item xs={12}>
                                <EntityVisibility
                                    visible={optionSet.enabled}
                                    onChange={(enabled) => change("enabled", enabled)}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant="h6" className={classes.selectionTitle}>
                                    Selections
                                </Typography>
                            </Grid>

                            <Grid item xs={6}>
                                <FormControlLabel
                                    className={classes.switchLabel}
                                    control={
                                        <Field type="checkbox" name="required">
                                            {({ input: { name, value, onChange, checked, ...restInput } }) => (
                                                <Switch
                                                    color="secondary"
                                                    name={name}
                                                    value={value}
                                                    onChange={(e) => {
                                                        onChange(e);
                                                        if (getIn(values, "implicit")) {
                                                            change("implicit", false);
                                                        }
                                                    }}
                                                    checked={checked}
                                                    inputProps={{ ...restInput }}
                                                    title={`Option Set is ${checked ? "required" : "optional"}.`}
                                                />
                                            )}
                                        </Field>
                                    }
                                    label="Required"
                                    labelPlacement="end"
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <FormControlLabel
                                    className={classes.switchLabel}
                                    control={
                                        <Field type="checkbox" name="implicit">
                                            {({ input: { name, value, onChange, checked, ...restInput } }) => (
                                                <Switch
                                                    color="secondary"
                                                    name={name}
                                                    value={value}
                                                    onChange={(e) => {
                                                        onChange(e);
                                                        if (!checked) {
                                                            change("required", true);
                                                            change("min", groupModsCount.toString());
                                                            change("max", groupModsCount.toString());
                                                        }
                                                    }}
                                                    checked={checked}
                                                    inputProps={{ ...restInput }}
                                                />
                                            )}
                                        </Field>
                                    }
                                    label={
                                        <>
                                            <Typography noWrap>
                                                Implicit{" "}
                                                <Tooltip
                                                    arrow
                                                    title={
                                                        <>
                                                            <p>
                                                                Modifier Groups can be marked as implicit when every
                                                                Modifier must be selected by the customer.
                                                            </p>
                                                            <p>
                                                                An implicit Modifier Group will not appear on the
                                                                customer facing Menu. Modifiers within the group are
                                                                automatically added to a customer&apos;s Order when the
                                                                associated Item is ordered.
                                                            </p>
                                                            <p>
                                                                Modifier Groups nested under implicit Modifier Groups
                                                                are still visible.
                                                            </p>
                                                        </>
                                                    }
                                                >
                                                    <span>
                                                        <FontAwesomeIcon
                                                            icon={faInfoCircle}
                                                            color={theme.palette.info.main}
                                                        />
                                                    </span>
                                                </Tooltip>
                                            </Typography>
                                        </>
                                    }
                                    labelPlacement="end"
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <Field
                                    name="min"
                                    validate={(value, allValues) => {
                                        const min = Number(value);
                                        const max = Number(getIn(allValues, "max"));
                                        const required = getIn(allValues, "required");

                                        if (required && min === 0) {
                                            return "Min must be greater than zero if required";
                                        }

                                        if (max > 0 && max < min) {
                                            return "Min must be less than max";
                                        }
                                    }}
                                >
                                    {({ input: { onChange, ...restInput }, meta: { error } }) => (
                                        <TextField
                                            label="Min"
                                            variant="outlined"
                                            type="number"
                                            error={Boolean(error)}
                                            helperText={error ? error : ""}
                                            fullWidth
                                            onChange={(e) => {
                                                if (getIn(values, "implicit")) {
                                                    change("implicit", false);
                                                }
                                                onChange(e);
                                            }}
                                            {...restInput}
                                            inputProps={{ min: 0, max: groupModsCount }}
                                            id="option-set-min"
                                        />
                                    )}
                                </Field>
                            </Grid>

                            <Grid item xs={6}>
                                <Field name="max">
                                    {({ input: { onChange, ...restInput } }) => (
                                        <TextField
                                            label="Max"
                                            variant="outlined"
                                            type="number"
                                            fullWidth
                                            onChange={(e) => {
                                                if (getIn(values, "implicit")) {
                                                    change("implicit", false);
                                                }
                                                onChange(e);
                                            }}
                                            {...restInput}
                                            inputProps={{ min: 0, max: groupModsCount }}
                                            id="option-set-max"
                                        />
                                    )}
                                </Field>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Form>
        </Container>
    );
}
