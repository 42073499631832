import { call, fork, ForkEffect, put, takeEvery } from "redux-saga/effects";
import masterMenusSaga from "./master/effects";
import locationMenusSaga from "./location/effects";
import { ENTITY_DELETE, MasterEntity } from "./types";
import { core } from "services/api";
import { entityDeleteError, entityDeleteSuccess } from "./actions";
import { enqueueSnackbar } from "store/notifications/actions";

function* entityDelete({
    entity,
    entityName,
    callback,
}: {
    entity: MasterEntity;
    entityName: string;
    callback?: (error?: Error) => void;
    type: typeof ENTITY_DELETE;
}) {
    try {
        const resp: { data: MasterEntity } = yield call(core.delete, entity._links.self.href);

        if (callback) {
            yield call(callback);
        }
        yield put(entityDeleteSuccess(resp.data));
    } catch (e) {
        console.error(`Failed to delete ${entityName}`, e);
        if (e instanceof Error) {
            if (callback) {
                yield call(callback, e);
            }
            yield put(entityDeleteError(e));
        }

        yield put(
            enqueueSnackbar({
                message: `Failed to delete ${entityName}.`,
                options: {
                    variant: "error",
                },
            }),
        );
    }
}

export default function* menusSaga(): Generator<ForkEffect<void>, void, unknown> {
    yield takeEvery(ENTITY_DELETE, entityDelete);
    yield fork(masterMenusSaga);
    yield fork(locationMenusSaga);
}
