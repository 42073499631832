import { Tag } from "store/mms/tags/types";
import { entityPOSConfigKey, Nutrition } from "store/mms/types";
import { HALEntity, ListRoute } from "store/types";
import { LocationItem } from "../../location/items/types";
import { entityMasterPageLink, MenuEntity } from "../../types";
import { MasterModifier } from "./modifiers/types";
import { OptionSet } from "./option-sets/types";

export const MASTER_ITEM_FETCH = "@@ADMINMASTER/ITEM/FETCH";
export const MASTER_ITEM_FETCH_SUCCESS = "@@ADMINMASTER/ITEM/FETCH_SUCCESS";
export const MASTER_ITEM_FETCH_ERROR = "@@ADMINMASTER/ITEM/FETCH_ERROR";

export const MASTER_ITEM_SAVE = "@@ADMINMASTER/ITEM/SAVE";
export const MASTER_ITEM_SAVE_SUCCESS = "@@ADMINMASTER/ITEM/SAVE_SUCCESS";
export const MASTER_ITEM_SAVE_ERROR = "@@ADMINMASTER/ITEM/SAVE_ERROR";

export const MASTER_ITEM_IMAGE_SAVE = "@@ADMINMASTER/ITEM_IMAGE/SAVE";
export const MASTER_ITEM_IMAGE_SAVE_SUCCESS = "@@ADMINMASTER/ITEM_IMAGE/SAVE_SUCCESS";
export const MASTER_ITEM_IMAGE_SAVE_ERROR = "@@ADMINMASTER/ITEM_IMAGE/SAVE_ERROR";
export const MASTER_ITEM_IMAGE_DELETE = "@@ADMINMASTER/ITEM_IMAGE/DELETE";
export const MASTER_ITEM_IMAGE_DELETE_SUCCESS = "@@ADMINMASTER/ITEM_IMAGE/DELETE_SUCCESS";
export const MASTER_ITEM_IMAGE_DELETE_ERROR = "@@ADMINMASTER/ITEM_IMAGE/DELETE_ERROR";

export const MASTER_ITEM_OPTION_SETS_SAVE = "@@ADMINMASTER/ITEM_OPTION_SETS/SAVE";
export const MASTER_ITEM_OPTION_SETS_SAVE_ERROR = "@@ADMINMASTER/ITEM_OPTION_SETS/SAVE_ERROR";

export const MASTER_ITEM_OPTION_SET_DELETE_SUCCESS = "@@ADMINMASTER/ITEM_OPTION_SET/DELETE_SUCCESS";

export interface MasterItem
    extends Omit<
        LocationItem,
        "_embedded" | "_links" | "unlinkedModifiers" | "tags" | "nutrition" | "master_enabled" | "price_per_unit"
    > {
    // TODO: `has_unlinked_mods` can be removed once the old UI is gone.
    has_unlinked_mods: boolean;
    source_location: string;

    _embedded: {
        nutrition: Nutrition | null;
        image: {
            url: string;
        } | null;
        option_sets: OptionSet[];
        tags: Tag[];
    };

    _links: {
        image: {
            href: string;
            type: "application/hal+json; name=mms_master_menu_item_image";
        };

        nutrition: {
            href: string;
            type: "application/hal+json; name=mms_master_menu_nutrition";
        };

        option_sets: {
            href: string;
            type: "application/hal+json; name=mms_master_menu_option_set_list";
        };

        self: {
            href: string;
            type: "application/hal+json; name=mms_master_menu_item";
        };

        tags: {
            href: string;
            type: "application/hal+json; name=mms_master_menu_item_tag_list";
        };
    };
}

export type NewMasterItem = Omit<
    MasterItem,
    "id" | "_embedded" | "_links" | "base_price_per_unit" | "has_unlinked_mods"
>;

export interface MasterItemResp extends Omit<MasterItem, "pos_config"> {
    pos_config: string;
}

export interface MasterItemPageResp extends ListRoute {
    _embedded: {
        items: MasterItemResp[];
    };
}

export interface MasterItemReq extends Omit<MasterItem, "_embedded" | "_links" | "pos_config" | "has_unlinked_mods"> {
    pos_config: string;
    force_location_update?: boolean;
}

export interface MasterItemImageResp {
    url: string;
}

interface MasterItemFetch {
    type: typeof MASTER_ITEM_FETCH;
    url: string;
}

interface MasterItemFetchSuccess {
    type: typeof MASTER_ITEM_FETCH_SUCCESS;
    item: MasterItem;
}

interface MasterItemFetchError {
    type: typeof MASTER_ITEM_FETCH_ERROR;
    error: Error;
}

interface MasterItemSave {
    type: typeof MASTER_ITEM_SAVE;
    item: MasterItem;
    url: string;
    callback?: (error?: Error) => void;
    force_location_update?: boolean;
}

interface MasterItemSaveSuccess {
    type: typeof MASTER_ITEM_SAVE_SUCCESS;
    item: MasterItem;
}

interface MasterItemSaveError {
    type: typeof MASTER_ITEM_SAVE_ERROR;
    error: Error;
}

interface MasterItemImageSave {
    type: typeof MASTER_ITEM_IMAGE_SAVE;
    item: MasterItem;
    imageUrl: string;
    callback?: (error?: Error) => void;
}

interface MasterItemImageSaveSuccess {
    type: typeof MASTER_ITEM_IMAGE_SAVE_SUCCESS;
    itemId: string;
    imageUrl: string;
}

interface MasterItemImageSaveError {
    type: typeof MASTER_ITEM_IMAGE_SAVE_ERROR;
    error: Error;
}

interface MasterItemImageDelete {
    type: typeof MASTER_ITEM_IMAGE_DELETE;
    item: MasterItem;
    callback?: (error?: Error) => void;
}

interface MasterItemImageDeleteSuccess {
    type: typeof MASTER_ITEM_IMAGE_DELETE_SUCCESS;
    itemId: string;
}

interface MasterItemImageDeleteError {
    type: typeof MASTER_ITEM_IMAGE_DELETE_ERROR;
    error: Error;
}

interface MasterItemOptionSetsSave {
    type: typeof MASTER_ITEM_OPTION_SETS_SAVE;
    item: MasterItem;
    optionSets: OptionSet[];
    callback?: (error?: Error) => void;
}

interface MasterItemOptionSetsSaveError {
    type: typeof MASTER_ITEM_OPTION_SETS_SAVE_ERROR;
    item: MasterItem;
    optionSets: OptionSet[];
    error: Error;
}

interface MasterItemOptionSetDeleteSuccess {
    type: typeof MASTER_ITEM_OPTION_SET_DELETE_SUCCESS;
    item: MasterItem;
    optionSet: OptionSet;
}

export type MasterItemActions =
    | MasterItemFetch
    | MasterItemFetchSuccess
    | MasterItemFetchError
    | MasterItemSave
    | MasterItemSaveSuccess
    | MasterItemSaveError
    | MasterItemImageSave
    | MasterItemImageSaveSuccess
    | MasterItemImageSaveError
    | MasterItemImageDelete
    | MasterItemImageDeleteSuccess
    | MasterItemImageDeleteError
    | MasterItemOptionSetsSave
    | MasterItemOptionSetsSaveError
    | MasterItemOptionSetDeleteSuccess;

export function buildMasterTraversal(i: MasterItem, m?: MasterModifier): MenuEntity[] {
    const traversal: MenuEntity[] = [];

    traversal.push({
        id: i.id,
        name: i.display_name,
        strategy: i.strategy,
        pos_entity_type: entityPOSConfigKey(i.pos_config),
        pathname: entityMasterPageLink(i),
    });

    if (m) {
        traversal.push({
            id: m.id,
            name: m.display_name,
            strategy: m.strategy,
            pos_entity_type: entityPOSConfigKey(m.pos_config),
            pathname: entityMasterPageLink(m),
        });
    }

    return traversal;
}

export const isMasterItem = (entity: HALEntity): entity is MasterItem => {
    return entity._links.self.type === "application/hal+json; name=mms_master_menu_item";
};
