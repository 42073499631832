import React, { useMemo } from "react";
import { Decorator } from "final-form";
import { default as createSubmitListenerDecorator } from "final-form-submit-listener";
import { Field, Form } from "react-final-form";
import { FormSubmit } from "../FormSubmit";
import { RenameFieldset } from "./RenameFieldset";
import { handleErrors, mapToFormErrors } from "components/common/forms/helpers";
import { makeStyles } from "theme";
import { Alert, TextField, Theme } from "@mui/material";

const useStyles = makeStyles()((theme: Theme) => ({
    description: {
        marginTop: theme.spacing(4),
    },
    footer: {
        marginTop: theme.spacing(8),
        textAlign: "end",
    },
    submitErrors: {
        margin: theme.spacing(4, 0),
    },
}));
interface EntityDetailsProps {
    display_name: string;
    reference_name: string;
    description?: string;
    submitLabel: string;
    onClose(): void;
    onSave?(
        display_name: string,
        reference_name: string,
        description?: string,
        callback?: (error?: Error) => void,
    ): void;
}

interface formValues {
    display_name: string;
    reference_name: string;
    description?: string;
}

export function EntityDetails({
    display_name,
    reference_name,
    description,
    submitLabel,
    onClose,
    onSave,
}: EntityDetailsProps): JSX.Element {
    const { classes } = useStyles();
    const submitListener = useMemo(
        () =>
            createSubmitListenerDecorator({
                afterSubmitSucceeded: onClose,
            }),
        [onClose],
    );

    return (
        <Form
            initialValues={{
                display_name,
                reference_name,
                description,
            }}
            decorators={[submitListener as Decorator<formValues, formValues>]}
            onSubmit={(values, form, errorsCallback) =>
                onSave &&
                onSave(values.display_name, values.reference_name, values.description, (error?: Error) => {
                    if (errorsCallback !== undefined) {
                        errorsCallback(mapToFormErrors(error));
                    }
                })
            }
            validate={(values) => {
                const errors: { display_name?: string; reference_name?: string } = {};

                if (!values.display_name) {
                    errors.display_name = "Required";
                }

                if (!values.reference_name) {
                    errors.reference_name = "Required";
                }

                return errors;
            }}
        >
            {({ handleSubmit, submitError }) => (
                <form onSubmit={handleSubmit}>
                    <RenameFieldset />

                    {description !== undefined && (
                        <Field name="description">
                            {({ input, meta }) => (
                                <TextField
                                    {...input}
                                    {...handleErrors(meta)}
                                    label="Description (Optional)"
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={4}
                                    className={classes.description}
                                />
                            )}
                        </Field>
                    )}

                    {submitError && (
                        <Alert className={classes.submitErrors} severity="error">
                            {submitError}
                        </Alert>
                    )}

                    <FormSubmit className={classes.footer} onClose={onClose} label={submitLabel} />
                </form>
            )}
        </Form>
    );
}
