import React, { ChangeEvent, FormEvent, useEffect, useReducer } from "react";
import {
    Button,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Input,
    InputAdornment,
    InputLabel,
    Radio,
    RadioGroup,
    TextField,
    Theme,
} from "@mui/material";
import { makeStyles } from "theme";
import {
    OrderProfile,
    OrderProfileTypes,
    OrderProfileAdjustmentStrategyTypes,
    OrderProfileAdjustmentTypes,
} from "store/mms/order-profiles/types";
import { useDispatch, useSelector } from "react-redux";
import { orderProfileSave } from "store/mms/order-profiles/actions";
import { RootState } from "store/rootReducer";
import { getInitialState, reducer } from "./reducer";

interface editProfileProps {
    profile?: OrderProfile;
    close(): void;
}

const useStyles = makeStyles()((theme: Theme) => ({
    body: {
        padding: theme.spacing(5),
        "& > *": {
            marginBottom: theme.spacing(4),
        },
    },
    radioGroup: {
        margin: theme.spacing(2, 0, 0, 2),
    },
    adjustmentAmount: {
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(-2),
    },
    footer: {
        display: "flex",
        marginTop: theme.spacing(2),
    },
    cancel: {
        marginLeft: theme.spacing(2),
    },
    submit: {
        position: "relative",
    },
}));

export default function EditOrderProfile(props: editProfileProps): JSX.Element {
    const { classes } = useStyles();
    const [state, formDispatch] = useReducer(reducer, props.profile, getInitialState);
    const { profiles, fetchError: error } = useSelector((state: RootState) => state.mms.orderProfiles);
    const dispatch = useDispatch();

    useEffect(() => {
        if (state.saving && !error) {
            const { close } = props;
            // we tried to save, profiles store changed, and we did not get an error from the server.
            close();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profiles]);

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const p = state.profile;
        const adjustmentAmountError =
            p.adjustment.enabled &&
            (p.adjustment.amount === undefined || p.adjustment.amount < 0) &&
            p.adjustment.strategy !== OrderProfileAdjustmentStrategyTypes.TicketDiscount;

        if (!p.name || !p.type || adjustmentAmountError) {
            return formDispatch({
                type: "UPDATE_FORM_ERRORS",
                formErrors: {
                    name: !p.name,
                    type: !p.type,
                    adjustmentAmount: adjustmentAmountError,
                },
            });
        }
        formDispatch({ type: "SAVE_PROFILE", save: () => dispatch(orderProfileSave(p)) });
    };

    return (
        <form className={classes.body} noValidate onSubmit={handleSubmit}>
            <FormControl fullWidth>
                <TextField
                    name="name"
                    onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                        formDispatch({ type: "CHANGE_NAME", name: e.target.value })
                    }
                    value={state.profile.name}
                    variant="outlined"
                    autoFocus
                    label="Name"
                    error={state.formErrors.name}
                    helperText={state.formErrors.name && "Name is required."}
                />
            </FormControl>

            <FormControl fullWidth>
                <TextField
                    select
                    value={state.profile.type}
                    onChange={(e: ChangeEvent<{ name?: string; value: unknown }>) =>
                        formDispatch({ type: "CHANGE_TYPE", profileType: e.target.value as OrderProfileTypes })
                    }
                    SelectProps={{
                        native: true,
                    }}
                    label="Type"
                    variant="outlined"
                    error={state.formErrors.type}
                    helperText={state.formErrors.type && "Type is required."}
                >
                    {Object.entries(OrderProfileTypes).map(([title, profileType], idx) => (
                        <option key={idx} value={profileType}>
                            {title}
                        </option>
                    ))}
                </TextField>
            </FormControl>

            <FormControl fullWidth component="fieldset" margin="dense">
                <FormLabel color="primary">Do you need to adjust Menu pricing?</FormLabel>
                <RadioGroup
                    value={state.adjustmentEnabledType}
                    defaultValue=""
                    className={classes.radioGroup}
                    onChange={(e: ChangeEvent<HTMLInputElement>, value: string) =>
                        formDispatch({ type: "CHANGE_ADJUSTMENT_ENABLED", enabledType: value })
                    }
                >
                    <FormControlLabel label="No adjustments are needed." value="" control={<Radio />} />
                    <FormControlLabel label="Uplift pricing on the Menu." value="uplift" control={<Radio />} />
                    <FormControlLabel
                        label="Discount as a line item on the Ticket."
                        value="discount"
                        control={<Radio />}
                    />
                </RadioGroup>
            </FormControl>

            {state.profile.adjustment.enabled && state.adjustmentEnabledType === "uplift" && (
                <FormControl fullWidth component="fieldset" margin="dense" name="uplift-details">
                    <FormLabel color="primary">How do you want the uplift amount configured?</FormLabel>
                    <RadioGroup
                        defaultValue={OrderProfileAdjustmentTypes.Percent}
                        value={state.profile.adjustment.type || ""}
                        onChange={(e: ChangeEvent<HTMLInputElement>, v: string) =>
                            formDispatch({
                                type: "CHANGE_ADJUSTMENT_TYPE",
                                adjustmentType: (v as OrderProfileAdjustmentTypes) || undefined,
                            })
                        }
                        className={classes.radioGroup}
                    >
                        <FormControlLabel
                            label="Apply percent uplift to all Menu Items."
                            value={OrderProfileAdjustmentTypes.Percent}
                            control={<Radio />}
                        />
                        <FormControlLabel
                            label="Apply dollar uplift to all Menu Items."
                            value={OrderProfileAdjustmentTypes.Dollar}
                            control={<Radio />}
                        />
                    </RadioGroup>
                </FormControl>
            )}

            {state.profile.adjustment.enabled && state.adjustmentEnabledType === "discount" && (
                <FormControl fullWidth component="fieldset" margin="dense" name="discount-details">
                    <FormLabel color="primary">How do you want the discount amount configured?</FormLabel>
                    <RadioGroup
                        defaultValue=""
                        value={state.profile.adjustment.type || ""}
                        onChange={(e: ChangeEvent<HTMLInputElement>, v: string) =>
                            formDispatch({
                                type: "CHANGE_ADJUSTMENT_TYPE",
                                adjustmentType: (v as OrderProfileAdjustmentTypes) || undefined,
                            })
                        }
                        className={classes.radioGroup}
                    >
                        <FormControlLabel
                            label="Apply a closed Discount configured on the POS to all Tickets."
                            value=""
                            control={<Radio />}
                        />
                        <FormControlLabel
                            label="Apply percent Discount to all Tickets."
                            value={OrderProfileAdjustmentTypes.Percent}
                            control={<Radio />}
                        />
                        <FormControlLabel
                            label="Apply dollar Discount to all Tickets."
                            value={OrderProfileAdjustmentTypes.Dollar}
                            control={<Radio />}
                        />
                    </RadioGroup>
                </FormControl>
            )}

            {state.profile.adjustment.enabled && state.profile.adjustment.type && (
                <FormControl
                    component="fieldset"
                    margin="dense"
                    name="adjustment-amount"
                    className={classes.adjustmentAmount}
                >
                    <InputLabel htmlFor="adjustmentAmount" shrink>
                        Amount
                    </InputLabel>
                    <Input
                        value={state.formattedAmount}
                        onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                            formDispatch({
                                type: "CHANGE_ADJUSTMENT_AMOUNT",
                                adjustmentAmount: e.target.value ? parseFloat(e.target.value) * 100 : undefined,
                                formattedAmount: e.target.value,
                            })
                        }
                        onBlur={() => formDispatch({ type: "CHANGE_FORMATTED_AMOUNT" })}
                        name="adjustmentAmount"
                        error={state.formErrors.adjustmentAmount}
                        startAdornment={
                            state.profile.adjustment.type === OrderProfileAdjustmentTypes.Dollar && (
                                <InputAdornment position="start">$</InputAdornment>
                            )
                        }
                        endAdornment={
                            state.profile.adjustment.type === OrderProfileAdjustmentTypes.Percent && (
                                <InputAdornment position="end">%</InputAdornment>
                            )
                        }
                    />
                    {state.formErrors.adjustmentAmount && (
                        <FormHelperText error>Adjustment amount required.</FormHelperText>
                    )}
                </FormControl>
            )}

            {state.profile.adjustment.enabled && state.adjustmentEnabledType === "uplift" && (
                <FormControl fullWidth component="fieldset" margin="dense" name="uplift-details">
                    <FormLabel color="primary">How do you want the uplift to appear on the Ticket?</FormLabel>
                    <RadioGroup
                        value={state.upliftStrategy}
                        onChange={(e: ChangeEvent<HTMLInputElement>, v: string) =>
                            formDispatch({
                                type: "CHANGE_ADJUSTMENT_STRATEGY",
                                strategy: v as OrderProfileAdjustmentStrategyTypes,
                            })
                        }
                        className={classes.radioGroup}
                    >
                        <FormControlLabel
                            label="Include it in the price of items."
                            value={OrderProfileAdjustmentStrategyTypes.ForcePriceAll}
                            control={<Radio />}
                        />
                        <FormControlLabel
                            label="Break it out as a Service Charge."
                            value={OrderProfileAdjustmentStrategyTypes.TicketServiceCharge}
                            control={<Radio />}
                        />
                    </RadioGroup>
                </FormControl>
            )}

            <footer className={classes.footer}>
                <Button
                    className={classes.submit}
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={state.saving}
                >
                    Update Profile
                </Button>
                <Button onClick={props.close} className={classes.cancel}>
                    Cancel
                </Button>
            </footer>
        </form>
    );
}
