import { Typography, Alert } from "@mui/material";
import ConfirmDialog from "components/common/ConfirmDialog";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { masterModifierSave } from "store/mms/menus/master/items/modifiers/actions";
import { MasterModifier } from "store/mms/menus/master/items/modifiers/types";
import { axiosErrorMessage } from "store/types";

interface ModifierVisibilityProps {
    modifier: MasterModifier;
    onClose(): void;
    open: boolean;
}

export function ModifierVisibility({ modifier, onClose, open }: ModifierVisibilityProps): JSX.Element {
    const dispatch = useDispatch();
    const [state, setState] = useState<{ saving: boolean; error?: string }>({
        saving: false,
    });

    return (
        <ConfirmDialog
            title={`${modifier.enabled ? "Hide" : "Display"} Modifier`}
            open={open}
            onClose={onClose}
            onConfirm={() => {
                setState({ saving: true });
                dispatch(
                    masterModifierSave(
                        { ...modifier, enabled: !modifier.enabled },
                        modifier._links.self.href,
                        (error?: Error) => {
                            if (error) {
                                return setState({
                                    saving: false,
                                    error: axiosErrorMessage(error),
                                });
                            }

                            setState({ saving: false });
                            onClose();
                        },
                    ),
                );
            }}
            confirm={`${modifier.enabled ? "Hide" : "Display"} Modifier`}
            confirming={state.saving}
            maxWidth="xl"
            transitionDuration={0}
        >
            <Typography>
                The Modifier <strong>{modifier.display_name}</strong> will be{" "}
                {modifier.enabled ? "hidden" : "displayed"} across any Item or Modifier Group where it is used.
            </Typography>

            <Alert variant="outlined" severity="info">
                This change will affect <strong>all Locations</strong>.
            </Alert>

            {state.error && (
                <Alert variant="outlined" severity="error">
                    {state.error}
                </Alert>
            )}
        </ConfirmDialog>
    );
}
