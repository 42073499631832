import { Settings as SettingsIcon, Warning as WarningIcon } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React from "react";

interface EditPOSMappingProps {
    linked?: boolean;
    onClick(): void;
}

export function EditPOSMapping({ linked = true, onClick }: EditPOSMappingProps): JSX.Element {
    return (
        <Tooltip title={linked ? "Edit POS Mapping" : "Re-link"} arrow>
            <IconButton onClick={onClick} size="small">
                {linked ? (
                    <SettingsIcon fontSize="inherit" color="primary" />
                ) : (
                    <WarningIcon fontSize="inherit" color="warning" />
                )}
            </IconButton>
        </Tooltip>
    );
}
