import { Tag } from "store/mms/tags/types";

export const TAX_RATES_FETCH = "@@TAX_RATES/FETCH";
export const TAX_RATES_FETCH_SUCCESS = "@@TAX_RATES/FETCH_SUCCESS";
export const TAX_RATES_FETCH_COMPLETE = "@@TAX_RATES/FETCH_COMPLETE";
export const TAX_RATES_FETCH_ERROR = "@@TAX_RATES/FETCH_ERROR";

export const TAX_RATES_SAVE = "@@TAX_RATES/SAVE";
export const TAX_RATES_SAVE_SUCCESS = "@@TAX_RATES/SAVE_SUCCESS";
export const TAX_RATES_SAVE_ERROR = "@@TAX_RATES/SAVE_ERROR";

export const TAX_RATE_SAVE = "@@TAX_RATE/SAVE";
export const TAX_RATE_SAVE_SUCCESS = "@@TAX_RATE/SAVE_SUCCESS";
export const TAX_RATE_SAVE_ERROR = "@@TAX_RATE/SAVE_ERROR";

export const TAX_RATE_DELETE = "@@TAX_RATE/DELETE";
export const TAX_RATE_DELETE_SUCCESS = "@@TAX_RATE/DELETE_SUCCESS";
export const TAX_RATE_DELETE_ERROR = "@@TAX_RATE/DELETE_ERROR";

export interface TaxRatePage {
    _embedded: {
        tax_rates: TaxRate[];
    };
}

export interface TaxRate {
    id: string;
    name: string;
    default: boolean;
    enabled: boolean;
    rate: number;
    vat_rate: number;
    sequence: number;

    _embedded: {
        tags: Tag[];
    };

    _links: {
        self: {
            href: string;
            type: "application/hal+json; name=mms_adminlocation_settings_tax_rate";
        };
    };
}

export type NewTaxRate = Omit<TaxRate, "vat_rate" | "_links">;

export function getNewTaxRate(): NewTaxRate {
    return {
        id: "",
        name: "",
        default: false,
        enabled: false,
        rate: 0,
        sequence: 0,
        _embedded: {
            tags: [],
        },
    };
}

export interface TaxRatesFetch {
    type: typeof TAX_RATES_FETCH;
    url: string;
}

interface TaxRatesFetchSuccess {
    type: typeof TAX_RATES_FETCH_SUCCESS;
    taxRates: TaxRate[];
    locationId: string;
}

interface TaxRatesFetchComplete {
    type: typeof TAX_RATES_FETCH_COMPLETE;
}

interface TaxRatesFetchError {
    type: typeof TAX_RATES_FETCH_ERROR;
    error: Error;
}

interface TaxRatesSave {
    type: typeof TAX_RATES_SAVE;
    taxRates: TaxRate[];
    url: string;
}

interface TaxRatesSaveSuccess {
    type: typeof TAX_RATES_SAVE_SUCCESS;
    taxRates: TaxRate[];
    locationId: string;
}

interface TaxRatesSaveError {
    type: typeof TAX_RATES_SAVE_ERROR;
    taxRates: TaxRate[];
    error: Error;
}

export interface TaxRateSave {
    type: typeof TAX_RATE_SAVE;
    taxRate: TaxRate | NewTaxRate;
    locationId: string;
    callback?: (error?: Error) => void;
}

interface TaxRateSaveSuccess {
    type: typeof TAX_RATE_SAVE_SUCCESS;
    taxRate: TaxRate;
    locationId: string;
}

interface TaxRateSaveError {
    type: typeof TAX_RATE_SAVE_ERROR;
    error: Error;
}

interface TaxRateDelete {
    type: typeof TAX_RATE_DELETE;
    taxRate: TaxRate;
    callback?: (error?: Error) => void;
}

interface TaxRateDeleteSuccess {
    type: typeof TAX_RATE_DELETE_SUCCESS;
    taxRate: TaxRate;
    locationId: string;
}

interface TaxRateDeleteError {
    type: typeof TAX_RATE_DELETE_ERROR;
    error: Error;
}

export type TaxRateActions =
    | TaxRatesFetch
    | TaxRatesFetchSuccess
    | TaxRatesFetchComplete
    | TaxRatesFetchError
    | TaxRatesSave
    | TaxRatesSaveSuccess
    | TaxRatesSaveError
    | TaxRateSave
    | TaxRateSaveSuccess
    | TaxRateSaveError
    | TaxRateDelete
    | TaxRateDeleteSuccess
    | TaxRateDeleteError;
