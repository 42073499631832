import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { LinearProgress, TextField, Autocomplete } from "@mui/material";
import { makeStyles } from "theme";
import { RootState } from "store/rootReducer";
import { Location } from "store/locations/types";
import { locationSearch, locationSearchReset } from "store/locations/actions";
import { reportsFiltersAddLocation } from "store/reports/actions";

const useStyle = makeStyles()(() => ({
    loader: {
        minHeight: 15,
    },
}));

export default function LocationSearch(): JSX.Element {
    const { classes } = useStyle();
    const dispatch = useDispatch();
    const { searching, byId: locationsById } = useSelector((state: RootState) => state.locations);
    const appliedFilters = useSelector((state: RootState) => state.reportFilters.applied);

    return (
        <Autocomplete
            id="account-locations-autocomplete"
            getOptionLabel={(option) => `${option.display_name} (${option.id})`}
            filterOptions={(options) =>
                options.filter((option) => !appliedFilters.Locations.some((loc) => loc.id === option.id))
            }
            noOptionsText="No results, try again."
            options={Object.values(locationsById)}
            autoComplete
            includeInputInList
            clearOnBlur
            loading={searching}
            onChange={(event: unknown, location: Location | null) => {
                if (location) {
                    dispatch(reportsFiltersAddLocation(location));
                }
            }}
            onInputChange={(event, newInputValue, reason) => {
                if (newInputValue) {
                    if (reason === "input") {
                        dispatch(locationSearch(newInputValue));
                    }
                } else {
                    dispatch(locationSearchReset());
                }
            }}
            renderInput={(params) => (
                <div>
                    <div className={classes.loader}>{searching && <LinearProgress />}</div>
                    <TextField
                        {...params}
                        label="Locations"
                        variant="outlined"
                        placeholder="Search by Name or ID"
                        fullWidth
                    />
                </div>
            )}
        />
    );
}
