import { ListSubheader, Tooltip, Typography, Theme, IconButton, SxProps, Box } from "@mui/material";
import { money } from "components/common/utils";
import React from "react";
import { Link } from "react-router-dom";
import { LocationModifier } from "store/mms/menus/location/items/modifiers/types";
import { OptionSet as LocationOptionSet } from "store/mms/menus/location/items/option-sets/types";
import { LocationItem } from "store/mms/menus/location/items/types";
import { entityLocationPageLink } from "store/mms/menus/types";
import { Nutrition } from "store/mms/types";
import { List } from "components/common/List";
import { ListItem } from "components/common/ListItem";
import { ToggleDisplay } from "../IconButtons/ToggleDisplay";
import { EditPOSMapping } from "../IconButtons/EditPOSMapping";
import { VisibilityOff as VisibilityOffIcon } from "@mui/icons-material";
interface LocationOptionSetsProps {
    className?: string;
    isMaster?: boolean;
    itemId?: string;
    modsByHref: { [key: string]: LocationModifier };
    onModifierEdit?(modifier: LocationModifier): void;
    onSetVisibility?(modifier: LocationModifier): void;
    optionSets: LocationOptionSet[];
    parent: LocationItem | LocationModifier;
}

export default function LocationOptionSets({
    className,
    optionSets,
    parent,
    ...rest
}: LocationOptionSetsProps): JSX.Element {
    return (
        <List
            className={className}
            sx={(theme) => ({
                marginTop: theme.spacing(4),
                borderBottom: `1.5px solid ${theme.palette.divider}`,
                paddingBottom: 0,

                "& .MuiList-root": {
                    paddingLeft: theme.spacing(6.5),
                },

                "& > .MuiListSubheader-root > hr": {
                    marginBottom: 0,
                },

                "& .MuiListItemText-secondary": {
                    color: theme.palette.text.secondary,
                },
            })}
            aria-labelledby="modifiers-header"
            subheader={
                <ListSubheader id="modifiers-header" disableGutters>
                    <Typography variant="h6">Modifier Groups</Typography>
                    <hr />
                </ListSubheader>
            }
        >
            {optionSets.length ? (
                optionSets.map((os) => (
                    <OptionSetItem optionSet={os} isParentDisabled={!parent.enabled} key={os.id} {...rest} />
                ))
            ) : (
                <ListItem
                    ListItemTextProps={{
                        primary: "No modifier groups",
                    }}
                />
            )}
        </List>
    );
}

interface OptionSetItemProps {
    modsByHref: { [key: string]: LocationModifier };
    onModifierEdit?(modifier: LocationModifier): void;
    onSetVisibility?(modifier: LocationModifier): void;
    optionSet: LocationOptionSet;
    itemId?: string;
    isParentDisabled: boolean;
}

function OptionSetItem({ modsByHref, optionSet, isParentDisabled, itemId, ...rest }: OptionSetItemProps): JSX.Element {
    const mods: LocationModifier[] = optionSet._embedded.modifier_group._embedded.modifiers.filter(
        (m) => modsByHref[m._links.self.href],
    );
    const isDisabled = isParentDisabled || !optionSet.enabled;

    return (
        <ListItem
            ListItemTextProps={{
                className: isDisabled ? "disabled" : undefined,
                primary: optionSet._embedded.modifier_group.display_name,
                secondary: optionSetHelperText(optionSet),
                primaryTypographyProps: { variant: "body1" },
                secondaryTypographyProps: { sx: { marginLeft: 0 } },
            }}
            CollapseContainerProps={{ sx: collapseContainerStyle }}
            actions={
                isDisabled ? (
                    <Tooltip title="Hidden on Menu" arrow>
                        <IconButton size="small" disableRipple disableTouchRipple disableFocusRipple>
                            <VisibilityOffIcon fontSize="inherit" sx={{ color: "text.disabled" }} />
                        </IconButton>
                    </Tooltip>
                ) : null
            }
        >
            <List disablePadding>
                {mods.map((m: LocationModifier) => (
                    <ModifierItem
                        modsByHref={modsByHref}
                        modifier={modsByHref[m._links.self.href]}
                        isParentDisabled={isParentDisabled}
                        key={m.id}
                        itemId={itemId}
                        {...rest}
                    />
                ))}
            </List>
        </ListItem>
    );
}

interface ModifierItemProps {
    modifier: LocationModifier;
    modsByHref: { [key: string]: LocationModifier };
    onModifierEdit?(modifier: LocationModifier): void;
    onSetVisibility?(modifier: LocationModifier): void;
    itemId?: string;
    isParentDisabled: boolean;
}

function ModifierItem({
    modifier,
    modsByHref,
    onModifierEdit,
    onSetVisibility,
    itemId,
    isParentDisabled,
}: ModifierItemProps): JSX.Element {
    const handleEdit = () => {
        if (onModifierEdit) {
            onModifierEdit(modifier);
        }
    };
    const isUnlinked = !modifier.linked;
    const isDisabled = !modifier.enabled;
    const hasOptionSets = modifier._embedded.option_sets.length > 0;
    const optionSets = modifier._embedded.option_sets;
    const calInfo = getCalories(modifier.nutrition);
    const modifierPath = entityLocationPageLink(modifier);
    const modifierActions = (
        <>
            {!isParentDisabled && (
                <ToggleDisplay
                    hidden={isDisabled}
                    onClick={() => {
                        if (onSetVisibility) {
                            onSetVisibility(modifier);
                        }
                    }}
                />
            )}
            <EditPOSMapping linked={!isUnlinked} onClick={handleEdit} />
        </>
    );

    return (
        <>
            {hasOptionSets ? (
                <>
                    <ListItem
                        className="modifier"
                        ListItemTextProps={{
                            primary: (
                                <ModifierLabel
                                    calorieInfo={calInfo}
                                    pathname={modifierPath}
                                    name={modifier.display_name}
                                    defaultPrice={modifier.base_price_per_unit}
                                />
                            ),
                            primaryTypographyProps: { variant: "body1" },
                            className: isDisabled || isParentDisabled ? "disabled" : undefined,
                        }}
                        CollapseContainerProps={{ sx: collapseContainerStyle }}
                        actions={modifierActions}
                    >
                        <List disablePadding>
                            {optionSets.map((os: LocationOptionSet) => (
                                <OptionSetItem
                                    modsByHref={modsByHref}
                                    onModifierEdit={onModifierEdit}
                                    onSetVisibility={onSetVisibility}
                                    optionSet={os}
                                    isParentDisabled={isDisabled || isParentDisabled}
                                    itemId={itemId}
                                    key={os.id}
                                />
                            ))}
                        </List>
                    </ListItem>
                </>
            ) : (
                <ListItem
                    divider
                    className="modifier"
                    ListItemTextProps={{
                        primary: (
                            <ModifierLabel
                                calorieInfo={calInfo}
                                defaultPrice={modifier.base_price_per_unit}
                                name={modifier.display_name}
                                pathname={modifierPath}
                            />
                        ),
                        primaryTypographyProps: { variant: "body1" },
                        className: isDisabled || isParentDisabled ? "disabled" : undefined,
                    }}
                    CollapseContainerProps={{ sx: collapseContainerStyle }}
                    actions={modifierActions}
                />
            )}
        </>
    );
}

interface ModifierLabelProps {
    calorieInfo?: string;
    defaultPrice: number;
    name: string;
    pathname: string;
}

function ModifierLabel({ calorieInfo, defaultPrice, name, pathname }: ModifierLabelProps): JSX.Element {
    return (
        <Box
            component="span"
            sx={{
                "& .calories": {
                    fontStyle: "italic",
                },

                "& .price": {
                    fontWeight: (theme) => theme.typography.fontWeightBold,
                },
            }}
        >
            <Link to={pathname}>{name}</Link>
            {defaultPrice ? <span className="price">{` (+ ${money(defaultPrice)})`}</span> : null}
            {calorieInfo && <span className="calories">{` (${calorieInfo})`}</span>}
        </Box>
    );
}

function optionSetHelperText(os: LocationOptionSet): string {
    if (os.implicit) {
        return "Implicit";
    }

    const max = os.max;
    const min = os.min;
    const req = os.required ? "Required | " : "Optional | ";

    if (min === 0) {
        return max === 0 ? req + "Choose any" : req + `Choose up to ${max}`;
    }

    if (max === 0) {
        return req + `Choose at least ${min}`;
    }

    return min === max ? req + `Choose ${max}` : req + `Choose between ${min} and ${max}`;
}

function getCalories(nutrition?: Nutrition | null): string | undefined {
    if (!nutrition) {
        return;
    }
    const calories = nutrition.calories;

    return `${calories.lower}${calories.upper ? " - " + calories.upper : ""} cals`;
}

const collapseContainerStyle: SxProps<Theme> = { padding: (theme) => theme.spacing(2, 0) };
