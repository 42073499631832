import React, { useEffect, useMemo, useState } from "react";
import { Field, useFormState } from "react-final-form";
import { POSCombo, POSItem, POSModifier, PriceLevel } from "store/mms/types";
import { Autocomplete } from "mui-rff";
import { core } from "services/api";
import { AxiosResponse } from "axios";
import debounce from "lodash/debounce";
import get from "lodash/get";
import { Checkbox, FormControlLabel, Grid, MenuItem, TextField, Typography } from "@mui/material";
import { makeStyles } from "theme";
import PaperWrapper from "components/common/PaperWrapper";
import { hasPriceLevels } from "./helpers";
import { posSearchables } from "./types";
import { searchDebounce } from "services/env";
import { handleErrors } from "components/common/forms/helpers";

interface searchRespTypes {
    _embedded: {
        items?: POSItem[];
        modifiers?: POSModifier[];
        combos?: POSCombo[];
    };
}

const mapOptions = (resp: searchRespTypes): posSearchables =>
    resp._embedded.combos || resp._embedded.items || resp._embedded.modifiers || [];

const useStyles = makeStyles()(() => ({
    priceLevels: {
        "& > .MuiInputBase-root > .MuiSelect-selectMenu": {
            maxHeight: "1.1876em",
        },
    },
}));

interface POSEntitySearchProps {
    disabled?: boolean;
    entityType: string;
    entityBaseUrl: string;
    excludePrice: boolean;
    name?: string;
}

export default function POSEntitySearch({
    disabled = false,
    entityType,
    entityBaseUrl,
    excludePrice,
    name = "",
}: POSEntitySearchProps): JSX.Element {
    const prefix = `${name !== "" ? name + "." : ""}`;
    const posEntityName = `${prefix}posEntity`;
    const formState = useFormState();
    const initialEntity = get(formState.values, posEntityName);
    const { classes } = useStyles();
    const [state, setState] = useState<{
        entityBaseUrl: string;
        searching: boolean;
        posEntityOptions: posSearchables;
        query?: string;
    }>({
        entityBaseUrl,
        searching: false,
        posEntityOptions: initialEntity !== undefined ? [initialEntity] : [],
    });
    const handleSearch = (event: unknown, value: string) =>
        setState((prevState) => ({ ...prevState, query: value, searching: true }));
    const search = useMemo(
        () =>
            debounce(async (isMounted: () => boolean, url: string) => {
                try {
                    const resp: AxiosResponse<searchRespTypes> = await core.get(url);

                    if (!isMounted()) {
                        return;
                    }

                    setState((prevState) => ({
                        ...prevState,
                        searching: false,
                        posEntityOptions: mapOptions(resp.data),
                    }));
                } catch (e) {
                    console.error("Failed to search for POS Entity", e);
                    setState((prevState) => ({
                        ...prevState,
                        searching: false,
                    }));
                }
            }, searchDebounce),
        [],
    );

    // Entity Autocomplete
    useEffect(() => {
        if (!state.searching || !entityBaseUrl || disabled) {
            return undefined;
        }
        let mounted = true;
        const url = `${entityBaseUrl}?search=${state.query}`;

        search(() => mounted, url);

        return () => {
            mounted = false;
        };
    }, [search, state.query, entityBaseUrl, state.searching, disabled]);

    // If the entityBaseUrl changes, the user is choosing a difference source. Clear out any previous options.
    useEffect(() => {
        if (state.entityBaseUrl !== entityBaseUrl) {
            setState((prevState) => ({ ...prevState, posEntityOptions: [] }));
        }
    }, [entityBaseUrl, state.entityBaseUrl]);

    return (
        <Grid container spacing={3}>
            <Grid item md={8}>
                <Autocomplete
                    PaperComponent={PaperWrapper}
                    loading={state.searching}
                    name={posEntityName}
                    options={state.posEntityOptions}
                    filterOptions={(options, state) => {
                        return options.filter((o) => {
                            if (
                                o &&
                                (o.pos_id.toLowerCase().includes(state.inputValue.toLowerCase()) ||
                                    o.name.toLowerCase().includes(state.inputValue.toLowerCase()))
                            ) {
                                return o;
                            }
                        });
                    }}
                    getOptionLabel={(o) => {
                        if (o) {
                            return `${o.name} (${o.id})`;
                        }
                        return "";
                    }}
                    showError={({ meta: { submitError, dirtySinceLastSubmit, error, touched } }) => {
                        return !!(((submitError && !dirtySinceLastSubmit) || error) && touched);
                    }}
                    label={`POS ${entityType}`}
                    helperText={`Find the correct ${entityType} on the POS.`}
                    fullWidth
                    freeSolo
                    disabled={disabled}
                    textFieldProps={{
                        title: `POS ${entityType}`,
                        variant: "outlined",
                        autoFocus: true,
                        disabled: disabled,
                    }}
                    onInputChange={handleSearch}
                />
            </Grid>

            <Grid item md={4}>
                <Grid container direction="column">
                    <Grid item md={12}>
                        <Field name={`${prefix}price_level`}>
                            {({ input, meta }) => (
                                <TextField
                                    id="price_level"
                                    select={hasPriceLevels(get(formState.values, posEntityName))}
                                    {...input}
                                    {...handleErrors(meta)}
                                    fullWidth
                                    title="Price Level"
                                    label="Price Level"
                                    variant="outlined"
                                    className={classes.priceLevels}
                                    disabled={disabled || !hasPriceLevels(get(formState.values, posEntityName))}
                                    required={hasPriceLevels(get(formState.values, posEntityName))}
                                >
                                    {hasPriceLevels(get(formState.values, posEntityName)) &&
                                        get(formState.values, posEntityName)?._embedded.price_levels.map(
                                            (pl: PriceLevel, idx: number) => (
                                                <MenuItem value={pl.id || ""} key={idx}>
                                                    <Typography noWrap>
                                                        {`${pl.id} - $${(pl.price_per_unit !== null
                                                            ? pl.price_per_unit / 100
                                                            : 0
                                                        ).toFixed(2)}${pl.name !== null ? ` (${pl.name})` : ""}`}
                                                    </Typography>
                                                </MenuItem>
                                            ),
                                        )}
                                </TextField>
                            )}
                        </Field>
                    </Grid>

                    {excludePrice && (
                        <Grid item md={12}>
                            <Field name={`${prefix}exclude_price`} type="checkbox">
                                {({ input }) => (
                                    <FormControlLabel
                                        label={<Typography variant="caption">Exclude Price</Typography>}
                                        control={
                                            <Checkbox
                                                {...input}
                                                title="Exclude Price"
                                                size="small"
                                                disabled={
                                                    disabled || !hasPriceLevels(get(formState.values, posEntityName))
                                                }
                                            />
                                        }
                                    />
                                )}
                            </Field>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
}
