import React from "react";
import ReactDOM from "react-dom";
import App from "pages/App";
import reportWebVitals from "reportWebVitals";
import initAnalytics from "analytics";
import { Provider as ReduxProvider } from "react-redux";
import store from "store";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

Bugsnag.start({
    apiKey: "ac3b9f621ee36dac6f297e4484aa1ca9",
    plugins: [new BugsnagPluginReact()],
});
const plugin = Bugsnag.getPlugin("react");
const ErrorBoundary = plugin ? plugin.createErrorBoundary(React) : (children: React.ReactNode) => <>{children}</>;

ReactDOM.render(
    <ErrorBoundary>
        <ReduxProvider store={store}>
            <React.StrictMode>
                <App />
            </React.StrictMode>
        </ReduxProvider>
    </ErrorBoundary>,
    document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
initAnalytics();
