import { MasterEntityActions, ENTITY_DELETE, ENTITY_DELETE_SUCCESS, ENTITY_DELETE_ERROR, MasterEntity } from "./types";

export function entityDelete(
    entity: MasterEntity,
    entityName: string,
    callback?: (error?: Error) => void,
): MasterEntityActions {
    return {
        type: ENTITY_DELETE,
        entity,
        entityName,
        callback,
    };
}

export function entityDeleteSuccess(entity: MasterEntity): MasterEntityActions {
    return {
        type: ENTITY_DELETE_SUCCESS,
        entity,
    };
}

export function entityDeleteError(error: Error): MasterEntityActions {
    return {
        type: ENTITY_DELETE_ERROR,
        error,
    };
}
