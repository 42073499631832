import { MasterModifier, NewMasterModifier } from "../../modifiers/types";
import {
    ModifierGroup,
    ModifierGroupActions,
    ModifierGroupModifier,
    MODIFIER_GROUP_MODIFERS_SAVE,
    MODIFIER_GROUP_SAVE,
    MODIFIER_GROUP_SAVE_ERROR,
    MODIFIER_GROUP_SAVE_SUCCESS,
    MODIFIER_GROUPS_HYDRATE,
    MODIFIER_GROUP_MODIFERS_ADD_NEW,
    MODIFIER_GROUP_MODIFERS_ADD_EXISTING,
    MODIFIER_GROUP_MODIFERS_SAVE_ERROR,
} from "./types";

export function modifierGroupSave(
    group: ModifierGroup,
    url: string,
    callback?: (error?: Error) => void,
): ModifierGroupActions {
    return {
        type: MODIFIER_GROUP_SAVE,
        group,
        url,
        callback,
    };
}

export function modifierGroupSaveSuccess(group: ModifierGroup): ModifierGroupActions {
    return {
        type: MODIFIER_GROUP_SAVE_SUCCESS,
        group,
    };
}

export function modifierGroupSaveError(error: Error): ModifierGroupActions {
    return {
        type: MODIFIER_GROUP_SAVE_ERROR,
        error,
    };
}

export function modifierGroupModifiersSave(
    group: ModifierGroup,
    modifiers: Omit<ModifierGroupModifier, "_embedded">[],
    callback?: (error?: Error) => void,
): ModifierGroupActions {
    return {
        type: MODIFIER_GROUP_MODIFERS_SAVE,
        group,
        modifiers,
        callback,
    };
}

export function modifierGroupModifiersSaveError(group: ModifierGroup): ModifierGroupActions {
    return {
        type: MODIFIER_GROUP_MODIFERS_SAVE_ERROR,
        group,
    };
}

export function modifierGroupModifersAddNew(
    group: ModifierGroup,
    modifier: NewMasterModifier,
    url: string,
    callback?: (error?: Error) => void,
): ModifierGroupActions {
    return {
        type: MODIFIER_GROUP_MODIFERS_ADD_NEW,
        group,
        modifier,
        url,
        callback,
    };
}

export function modifierGroupModifersAddExisting(
    group: ModifierGroup,
    modifier: MasterModifier,
    callback?: (error?: Error) => void,
): ModifierGroupActions {
    return {
        type: MODIFIER_GROUP_MODIFERS_ADD_EXISTING,
        group,
        modifier,
        callback,
    };
}

export function modifierGroupsHydrate(groups: ModifierGroup[]): ModifierGroupActions {
    return {
        type: MODIFIER_GROUPS_HYDRATE,
        groups,
    };
}
