import { call, debounce, ForkEffect, put, takeLatest } from "redux-saga/effects";
import { core } from "services/api";
import { getSession } from "services/session";
import {
    locationSearchSuccess,
    locationSearchError,
    locationFetchError,
    locationFetchSuccess,
    locationPageFetchSuccess,
    locationPageFetchError,
} from "./actions";
import {
    Location,
    LocationFetch,
    LocationListResp,
    LocationPageFetch,
    LocationsSearch,
    LOCATION_FETCH,
    LOCATION_PAGE_FETCH,
    LOCATION_SEARCH,
} from "./types";

function* locationPageFetch({ url, page, pageSize, query }: LocationPageFetch) {
    try {
        const search = query !== undefined && query !== "" ? "&search=" + query : "";
        const resp: { data: LocationListResp } = yield call(
            core.get,
            `${url}?limit=${pageSize}&start=${page * pageSize}&sort=display_name${search}`,
        );

        yield put(locationPageFetchSuccess(resp.data._embedded.locations, page, resp.data.limit, resp.data.total ?? 0));
    } catch (e) {
        console.error("Failed to get Location page", e);
        if (e instanceof Error) {
            yield put(locationPageFetchError(e, page));
        }
    }
}

function* locationFetch({ url }: LocationFetch) {
    try {
        const resp: { data: Location } = yield call(core.get, url);

        yield put(locationFetchSuccess(resp.data));
    } catch (error) {
        console.error("Failed to fetch Location", error);
        if (error instanceof Error) {
            yield put(locationFetchError(error));
        }
    }
}

function* locationsSearch({ query }: LocationsSearch) {
    try {
        const s = getSession();
        const results: { data: LocationListResp } = yield call(
            core.get,
            `/1.1/accounts/${s?.accountId}/locations/?search=${query}&sort=display_name`,
        );

        yield put(locationSearchSuccess(results.data._embedded.locations as Location[]));
    } catch (e) {
        console.error("Failed to search account locations", e);
        if (e instanceof Error) {
            yield put(locationSearchError(e));
        }
    }
}

export function* locationsSaga(): Generator<ForkEffect<never>, void, unknown> {
    yield takeLatest(LOCATION_PAGE_FETCH, locationPageFetch);
    yield takeLatest(LOCATION_FETCH, locationFetch);
    yield debounce(500, LOCATION_SEARCH, locationsSearch);
}
