import { call, ForkEffect, put, takeEvery, takeLatest } from "redux-saga/effects";
import { core } from "services/api";
import { getSession } from "services/session";
import { enqueueSnackbar } from "store/notifications/actions";
import {
    taxRatesFetchError,
    taxRatesFetchSuccess,
    taxRatesFetchComplete,
    taxRatesSaveSuccess,
    taxRateDeleteSuccess,
    taxRateDeleteError,
    taxRateSaveError,
} from "./actions";
import {
    NewTaxRate,
    TaxRate,
    TaxRatePage,
    TaxRateSave,
    TaxRatesFetch,
    TAX_RATES_FETCH,
    TAX_RATES_SAVE,
    TAX_RATE_DELETE,
    TAX_RATE_SAVE,
} from "./types";

function* getTaxRates({ url }: TaxRatesFetch) {
    try {
        const resp: { data: TaxRatePage } = yield call(core.get, url);
        yield put(taxRatesFetchSuccess(url.replace(/.+locations\/([^\/]*).*/, "$1"), resp.data._embedded.tax_rates));

        // TODO: Check for a next link and do proper paging...
        yield put(taxRatesFetchComplete());
    } catch (e) {
        console.error("Failed to fetch location tax rate list", e);
        if (e instanceof Error) {
            yield put(taxRatesFetchError(e));
        }
    }
}

function* taxRatesSave({ taxRates, url }: { type: typeof TAX_RATES_SAVE; taxRates: TaxRate[]; url: string }) {
    try {
        for (let i = 0; i < taxRates.length; i++) {
            const resp: { data: TaxRate } = yield call(core.put, `${url}/${taxRates[i].id}`, {
                id: taxRates[i].id,
                name: taxRates[i].name,
                rate: taxRates[i].rate,
                sequence: i + 1,
                enabled: taxRates[i].enabled,
                default: taxRates[i].default,
                tags: taxRates[i]._embedded.tags.map((t) => t.id),
            });
            taxRates[i] = resp.data;
        }

        yield put(taxRatesSaveSuccess(url.replace(/.+locations\/([^\/]*).*/, "$1"), taxRates));
    } catch (e) {
        console.error("Failed to save Location Tax Rates", e);

        yield put(
            enqueueSnackbar({
                message: `Failed to save Location Tax Rates.`,
                options: {
                    variant: "error",
                },
            }),
        );
    }
}

function* taxRateSave({ locationId, taxRate, callback }: TaxRateSave) {
    const taxRateUrl: string = isExistingTaxRate(taxRate) ? taxRate._links.self.href : "";
    let taxRatesUrl = "";

    try {
        const isNew = !isExistingTaxRate(taxRate);

        if (isNew) {
            const s = getSession();

            taxRatesUrl = `/1.1/accounts/${s?.accountId}/locations/${locationId}/mms/settings/tax_rates`;
        } else {
            taxRatesUrl = taxRateUrl.replace(/(.+\btax_rates\b).*$/, "$1");
        }

        if (isNew) {
            yield call(core.post, taxRatesUrl, {
                name: taxRate.name,
                rate: taxRate.rate,
                sequence: taxRate.sequence,
                enabled: taxRate.enabled,
                default: taxRate.default,
                tags: taxRate._embedded.tags.map((t) => t.id),
            });
        } else {
            yield call(core.put, taxRateUrl, {
                id: taxRate.id,
                name: taxRate.name,
                rate: taxRate.rate,
                sequence: taxRate.sequence,
                enabled: taxRate.enabled,
                default: taxRate.default,
                tags: taxRate._embedded.tags.map((t) => t.id),
            });
        }

        if (callback !== undefined) {
            yield call(callback);
        }
    } catch (e) {
        console.error("Failed to save Location Tax Rate", e);

        if (e instanceof Error) {
            if (callback !== undefined) {
                yield call(callback, e);
            }
            yield put(taxRateSaveError(e));
        }

        yield put(
            enqueueSnackbar({
                message: `Failed to save Location Tax Rate.`,
                options: {
                    variant: "error",
                },
            }),
        );
    } finally {
        const resp: { data: TaxRatePage } = yield call(core.get, taxRatesUrl);
        yield put(
            taxRatesFetchSuccess(taxRatesUrl.replace(/.+locations\/([^\/]*).*/, "$1"), resp.data._embedded.tax_rates),
        );

        // TODO: Check for a next link and do proper paging...
        yield put(taxRatesFetchComplete());
    }
}

function* taxRateDelete({
    taxRate,
    callback,
}: {
    type: typeof TAX_RATE_DELETE;
    taxRate: TaxRate;
    callback?: (error?: Error) => void;
}) {
    try {
        const url = taxRate._links.self.href;
        yield call(core.delete, url);

        if (callback !== undefined) {
            yield call(callback);
        }

        yield put(taxRateDeleteSuccess(url.replace(/.+locations\/([^\/]*).*/, "$1"), taxRate));
    } catch (e) {
        console.error("Failed to delete Location Tax Rate", e);

        if (e instanceof Error) {
            if (callback !== undefined) {
                yield call(callback, e);
            }
            yield put(taxRateDeleteError(e));
        }
        yield put(
            enqueueSnackbar({
                message: `Failed to delete Location Tax Rate.`,
                options: {
                    variant: "error",
                },
            }),
        );
    }
}

export default function* taxRatesSaga(): Generator<ForkEffect<void>, void, unknown> {
    yield takeLatest(TAX_RATES_FETCH, getTaxRates);
    yield takeEvery(TAX_RATES_SAVE, taxRatesSave);
    yield takeEvery(TAX_RATE_SAVE, taxRateSave);
    yield takeEvery(TAX_RATE_DELETE, taxRateDelete);
}

export function isExistingTaxRate(taxRate: TaxRate | NewTaxRate): taxRate is TaxRate {
    return (
        (taxRate as TaxRate)?._links?.self?.type === "application/hal+json; name=mms_adminlocation_settings_tax_rate"
    );
}
