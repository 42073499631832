import { Skeleton, Theme, Tooltip } from "@mui/material";
import DisplayPOSConfig from "components/mms/DisplayPOSConfig";
import EntityVisibility from "components/mms/EntityVisibility";
import { SidebarContent, SidebarHeader, SidebarSection } from "components/Sidebar";
import React from "react";
import { Link } from "react-router-dom";
import { LocationModifier } from "store/mms/menus/location/items/modifiers/types";
import { LocationItem } from "store/mms/menus/location/items/types";
import { entityMasterPageLink } from "store/mms/menus/types";
import { getEntityPOSConfig } from "store/mms/types";
import { makeStyles } from "theme";

export const useSharedEntityPageStyle = makeStyles()((theme: Theme) => ({
    title: {
        marginBottom: theme.spacing(3),
    },
    nutrition: {
        marginTop: theme.spacing(1),
    },
    modifiers: {
        marginTop: theme.spacing(2),
    },
    items: {
        marginTop: theme.spacing(2),
    },
    image: {
        height: 200,
        width: 200,
    },
}));

interface DetailsSidebarSectionProps {
    isItem?: boolean;
    entityId: string;
    entity?: LocationItem | LocationModifier;
    onChangeVisibility: (visible: boolean) => void;
    onEditPOSConfig: () => void;
}

export function DetailsSidebarSection({
    isItem = false,
    entityId,
    entity,
    onChangeVisibility,
    onEditPOSConfig,
}: DetailsSidebarSectionProps): JSX.Element {
    const { classes } = useDetailsSidebarSectionStyle();
    const type = isItem ? "Item" : "Modifier";
    const header = `${type} Details (ID: ${entityId})`;

    if (entity === undefined) {
        return (
            <SidebarSection>
                <SidebarHeader>{header}</SidebarHeader>
                <SidebarContent>
                    <Skeleton height={75} className={classes.skeletonContent} />
                    <DisplayPOSConfig loading />
                </SidebarContent>
            </SidebarSection>
        );
    }

    return (
        <SidebarSection>
            <SidebarHeader>
                <Tooltip title={`View Master ${type}`} arrow>
                    <Link className={classes.masterLink} to={entityMasterPageLink(entity, undefined)}>
                        {header}
                    </Link>
                </Tooltip>
            </SidebarHeader>
            <SidebarContent>
                <EntityVisibility
                    className={classes.visibility}
                    visible={entity.enabled}
                    onChange={onChangeVisibility}
                />
                <DisplayPOSConfig
                    isModifier={!isItem}
                    posConfig={getEntityPOSConfig(entity?.pos_config)}
                    onClickEdit={onEditPOSConfig}
                    isLinked={entity?.linked || false}
                />
            </SidebarContent>
        </SidebarSection>
    );
}

const useDetailsSidebarSectionStyle = makeStyles()((theme: Theme) => ({
    masterLink: {
        display: "flex",
        justifyContent: "space-between",
    },
    visibility: {
        marginBottom: theme.spacing(3),
    },
    skeletonContent: {
        marginTop: theme.spacing(-2),
    },
}));
