import {
    Tag,
    TagActions,
    TAGGED_ENTITY_UPDATE,
    TAGGED_ENTITY_UPDATE_ERROR,
    TAGGED_ENTITY_UPDATE_SUCCESS,
    TAGS_FETCH,
    TAGS_FETCH_COMPLETE,
    TAGS_FETCH_ERROR,
    TAGS_FETCH_SUCCESS,
} from "./types";

interface TagsState {
    readonly tags: Tag[];
    readonly byId: {
        [key: string]: Tag;
    };

    readonly fetching: boolean;
    readonly fetchingError?: Error;

    readonly saving: boolean;
    readonly savingError?: Error;
}

export default function tags(
    state: TagsState = {
        tags: [],
        byId: {},
        fetching: false,
        saving: false,
    },
    action: TagActions,
): TagsState {
    switch (action.type) {
        case TAGS_FETCH:
            return {
                ...state,
                fetching: true,
            };

        case TAGS_FETCH_SUCCESS:
            return {
                ...state,
                tags: action.tags,
                byId: {
                    ...state.byId,
                    ...action.tags.reduce((allTags: { [key: string]: Tag }, t) => {
                        allTags[t.id] = t;
                        return allTags;
                    }, {}),
                },
            };

        case TAGS_FETCH_COMPLETE:
            return {
                ...state,
                fetching: false,
                fetchingError: undefined,
            };

        case TAGS_FETCH_ERROR:
            return {
                ...state,
                fetching: false,
                fetchingError: action.error,
            };

        case TAGGED_ENTITY_UPDATE:
            return {
                ...state,
                saving: true,
            };

        case TAGGED_ENTITY_UPDATE_SUCCESS:
            return {
                ...state,
                saving: false,
                savingError: undefined,
            };

        case TAGGED_ENTITY_UPDATE_ERROR:
            return {
                ...state,
                saving: false,
                savingError: action.error,
            };

        default:
            return state;
    }
}
