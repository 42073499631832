import {
    LocationModifier,
    LocationModifierActions,
    LOCATION_MODIFIERS_HYDRATE,
    LOCATION_MODIFIER_FETCH,
    LOCATION_MODIFIER_FETCH_ERROR,
    LOCATION_MODIFIER_FETCH_SUCCESS,
    LOCATION_MODIFIER_SAVE,
    LOCATION_MODIFIER_SAVE_ERROR,
    LOCATION_MODIFIER_SAVE_SUCCESS,
} from "./types";

export function locationModifierFetch(url: string): LocationModifierActions {
    return {
        type: LOCATION_MODIFIER_FETCH,
        url,
    };
}

export function locationModifierFetchSuccess(modifier: LocationModifier): LocationModifierActions {
    return {
        type: LOCATION_MODIFIER_FETCH_SUCCESS,
        modifier,
    };
}

export function locationModifierFetchError(error: Error): LocationModifierActions {
    return {
        type: LOCATION_MODIFIER_FETCH_ERROR,
        error,
    };
}

export function locationModifierSave(
    modifier: LocationModifier,
    url: string,
    callback?: (error?: Error) => void,
): LocationModifierActions {
    return {
        type: LOCATION_MODIFIER_SAVE,
        modifier,
        url,
        callback,
    };
}

export function locationModifierSaveSuccess(modifier: LocationModifier): LocationModifierActions {
    return {
        type: LOCATION_MODIFIER_SAVE_SUCCESS,
        modifier,
    };
}

export function locationModifierSaveError(error: Error): LocationModifierActions {
    return {
        type: LOCATION_MODIFIER_SAVE_ERROR,
        error,
    };
}

export function locationModifiersHydrate(modifiers: LocationModifier[]): LocationModifierActions {
    return {
        type: LOCATION_MODIFIERS_HYDRATE,
        modifiers,
    };
}
