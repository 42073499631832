import { SnackbarKey } from "notistack";
import { ENQUEUE_SNACKBAR, CLOSE_SNACKBAR, Notification, REMOVE_SNACKBAR, NotificationActions } from "./types";

type enqueueNotification = Omit<Notification, "key">;

export const enqueueSnackbar = (notification: enqueueNotification): NotificationActions => {
    return {
        type: ENQUEUE_SNACKBAR,
        notification: {
            ...notification,
            key: new Date().getTime() + Math.random(),
        },
    };
};

export const closeSnackbar = (key?: SnackbarKey): NotificationActions => ({
    type: CLOSE_SNACKBAR,
    dismissAll: !key, // dismiss all if no key has been defined
    key,
});

export const removeSnackbar = (key: SnackbarKey): NotificationActions => ({
    type: REMOVE_SNACKBAR,
    key,
});
