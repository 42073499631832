import { OptionSet } from "./option-sets/types";
import {
    MasterItemActions,
    MASTER_ITEM_FETCH,
    MasterItem,
    MASTER_ITEM_FETCH_SUCCESS,
    MASTER_ITEM_FETCH_ERROR,
    MASTER_ITEM_SAVE,
    MASTER_ITEM_SAVE_SUCCESS,
    MASTER_ITEM_SAVE_ERROR,
    MASTER_ITEM_IMAGE_SAVE,
    MASTER_ITEM_IMAGE_SAVE_SUCCESS,
    MASTER_ITEM_IMAGE_SAVE_ERROR,
    MASTER_ITEM_IMAGE_DELETE,
    MASTER_ITEM_IMAGE_DELETE_SUCCESS,
    MASTER_ITEM_IMAGE_DELETE_ERROR,
    MASTER_ITEM_OPTION_SETS_SAVE,
    MASTER_ITEM_OPTION_SETS_SAVE_ERROR,
    MASTER_ITEM_OPTION_SET_DELETE_SUCCESS,
} from "./types";

export function masterItemFetch(url: string): MasterItemActions {
    return {
        type: MASTER_ITEM_FETCH,
        url,
    };
}

export function masterItemFetchSuccess(item: MasterItem): MasterItemActions {
    return {
        type: MASTER_ITEM_FETCH_SUCCESS,
        item,
    };
}

export function masterItemFetchError(error: Error): MasterItemActions {
    return {
        type: MASTER_ITEM_FETCH_ERROR,
        error,
    };
}

export function masterItemSave(
    item: MasterItem,
    url: string,
    callback?: (error?: Error) => void,
    force_location_update?: boolean,
): MasterItemActions {
    return {
        type: MASTER_ITEM_SAVE,
        item,
        url,
        callback,
        force_location_update,
    };
}

export function masterItemSaveSuccess(item: MasterItem): MasterItemActions {
    return {
        type: MASTER_ITEM_SAVE_SUCCESS,
        item,
    };
}

export function masterItemSaveError(error: Error): MasterItemActions {
    return {
        type: MASTER_ITEM_SAVE_ERROR,
        error,
    };
}

export function masterItemImageSave(
    item: MasterItem,
    imageUrl: string,
    callback?: (error?: Error) => void,
): MasterItemActions {
    return {
        type: MASTER_ITEM_IMAGE_SAVE,
        item,
        imageUrl,
        callback,
    };
}

export function masterItemImageSaveSuccess(itemId: string, imageUrl: string): MasterItemActions {
    return {
        type: MASTER_ITEM_IMAGE_SAVE_SUCCESS,
        itemId,
        imageUrl,
    };
}

export function masterItemImageSaveError(error: Error): MasterItemActions {
    return {
        type: MASTER_ITEM_IMAGE_SAVE_ERROR,
        error,
    };
}

export function masterItemImageDelete(item: MasterItem, callback?: (error?: Error) => void): MasterItemActions {
    return {
        type: MASTER_ITEM_IMAGE_DELETE,
        item,
        callback,
    };
}

export function masterItemImageDeleteSuccess(itemId: string): MasterItemActions {
    return {
        type: MASTER_ITEM_IMAGE_DELETE_SUCCESS,
        itemId,
    };
}

export function masterItemImageDeleteError(error: Error): MasterItemActions {
    return {
        type: MASTER_ITEM_IMAGE_DELETE_ERROR,
        error,
    };
}

export function masterItemOptionSetsSave(
    item: MasterItem,
    optionSets: OptionSet[],
    callback?: (error?: Error) => void,
): MasterItemActions {
    return {
        type: MASTER_ITEM_OPTION_SETS_SAVE,
        item,
        optionSets,
        callback,
    };
}

export function masterItemOptionSetsSaveError(
    error: Error,
    item: MasterItem,
    optionSets: OptionSet[],
): MasterItemActions {
    return {
        type: MASTER_ITEM_OPTION_SETS_SAVE_ERROR,
        error,
        item,
        optionSets,
    };
}

export function masterItemOptionSetDeleteSuccess(item: MasterItem, optionSet: OptionSet): MasterItemActions {
    return {
        type: MASTER_ITEM_OPTION_SET_DELETE_SUCCESS,
        item,
        optionSet,
    };
}
