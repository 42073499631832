import { Typography } from "@mui/material";
import React from "react";

interface HeaderProps {
    menuName: string;
}

export function Header({ menuName }: HeaderProps): JSX.Element {
    return (
        <Typography variant="h5" sx={{ margin: (theme) => theme.spacing(2, 0) }}>
            {menuName}
        </Typography>
    );
}
