import { Typography } from "@mui/material";
import ConfirmDialog from "components/common/ConfirmDialog";
import DefaultLayout from "components/common/layouts/DefaultLayout";
import { LocationMenuInfo } from "components/common/LocationMenuInfo";
import { OmniDrawer } from "components/common/OmniDrawer";
import EditPOSConfig from "components/mms/EditPOSConfig/index";
import NutritionFactsDisplay from "components/mms/NutritionFacts/NutritionFactsDisplay";
import LocationOptionSets from "components/mms/OptionSets/LocationOptionSets";
import { Sidebar } from "components/Sidebar";
import { getBreadcrumbSkeleton, MainContentSkeleton } from "components/skeletons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useLocation, useParams } from "react-router-dom";
import { apiBaseUrl } from "services/env";
import { getSession } from "services/session";
import { locationFetch } from "store/locations/actions";
import { locationMenuPageFetch } from "store/mms/menus/location/actions";
import { locationItemFetch } from "store/mms/menus/location/items/actions";
import { locationModifierSave } from "store/mms/menus/location/items/modifiers/actions";
import { LocationModifier, ModifierStrategy } from "store/mms/menus/location/items/modifiers/types";
import { buildTraversal } from "store/mms/menus/location/items/types";
import { entityLocationPageLink, MenuEntity } from "store/mms/menus/types";
import { POSCombo, POSConfig, POSItem, POSModifier } from "store/mms/types";
import { RootState } from "store/rootReducer";
import { DetailsSidebarSection, useSharedEntityPageStyle } from "./helpers";

export default function LocationMenuModifier(): JSX.Element {
    const s = getSession();
    const { classes } = useSharedEntityPageStyle();
    const { locationId, menuId } = useParams() as { locationId: string; menuId: string };
    const location = useLocation();
    const locations = useSelector((state: RootState) => state.locations.byId);
    const fetchingLocation = useSelector((state: RootState) => state.locations.fetching);
    const menusById = useSelector((state: RootState) => state.mms.menus.location.menus.byId);
    const menusFetching = useSelector((state: RootState) => state.mms.menus.location.menus.fetching);
    const items = useSelector((state: RootState) => state.mms.menus.location.items);
    const fetchingItem = useSelector((state: RootState) => state.mms.menus.location.items.fetching);
    const modifiers = useSelector((state: RootState) => state.mms.menus.location.modifiers);
    const dispatch = useDispatch();
    const [state, setState] = useState<{
        posConfig?: POSConfig;
        posEntity?: POSCombo | POSItem | POSModifier;
    }>({});
    const [editModifier, setEditModifier] = useState<LocationModifier | undefined>(undefined);
    const [modifierVisibility, setModifierVisibility] = useState<{
        modifier?: LocationModifier;
        saving: boolean;
    }>({ saving: false });
    const modUrl = `/1.1/accounts/${s?.accountId}${location.pathname.replace(/-/g, "_").replace("menus", "mms/menus")}`;
    const locationUrl = modUrl.replace(/(\/locations\/[^\/]*)(.+)/, "$1");
    const itemUrl = modUrl.replace(/(\/items\/[^\/]*)(.+)/, "$1");
    const traversal: MenuEntity[] = [];
    const modifier: LocationModifier = modifiers.byHref[apiBaseUrl + modUrl];
    const selectedItem = items.byHref[apiBaseUrl + itemUrl];
    const accountLocation = locations[locationId];
    const locationMenu = menusById[menuId];
    const isLoading =
        selectedItem === undefined ||
        fetchingItem ||
        accountLocation === undefined ||
        fetchingLocation ||
        locationMenu === undefined ||
        menusFetching ||
        modifier === undefined;

    if (selectedItem !== undefined) {
        traversal.push(...buildTraversal(selectedItem, location.pathname));
    }

    // get the item
    useEffect(() => {
        if (selectedItem !== undefined) {
            return;
        }

        dispatch(locationItemFetch(itemUrl));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // get the location
    useEffect(() => {
        if (accountLocation !== undefined) {
            return;
        }

        dispatch(locationFetch(locationUrl));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // get the location menus
    useEffect(() => {
        if (locationMenu !== undefined) {
            return;
        }

        dispatch(locationMenuPageFetch(`${locationUrl}/mms/menus`));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const breadcrumbs = isLoading
        ? getBreadcrumbSkeleton(3)
        : [
              <RouterLink key="location" to={`/locations/${accountLocation.id}`}>
                  {accountLocation.display_name}
              </RouterLink>,
              ...traversal.map((p, idx) => {
                  if (idx === traversal.length - 1) {
                      return (
                          <Typography key={idx} color="textPrimary">
                              {p.name}
                          </Typography>
                      );
                  }
                  return (
                      <RouterLink key={idx} to={p.pathname}>
                          {p.name}
                      </RouterLink>
                  );
              }),
          ];

    return (
        <DefaultLayout
            aside={
                <Sidebar>
                    <DetailsSidebarSection
                        entityId={modUrl.replace(/.*\//, "")}
                        entity={modifier}
                        onChangeVisibility={(visible) =>
                            dispatch(
                                locationModifierSave(
                                    { ...modifier, enabled: visible },
                                    modifier._links.self.href || modUrl,
                                ),
                            )
                        }
                        onEditPOSConfig={() =>
                            setState((prevState) => ({
                                ...prevState,
                                posConfig: modifier?.pos_config || {},
                            }))
                        }
                    />
                </Sidebar>
            }
            breadcrumbs={[
                <RouterLink key="locations" to="/locations">
                    Locations
                </RouterLink>,
                ...breadcrumbs,
            ]}
            header={<LocationMenuInfo location={accountLocation} locationMenu={locationMenu} />}
        >
            {isLoading ? (
                <MainContentSkeleton />
            ) : (
                <>
                    <Typography variant="h4" className={classes.title}>
                        {modifier.display_name}
                    </Typography>
                    <NutritionFactsDisplay className={classes.nutrition} nutrition={modifier.nutrition} />
                    <LocationOptionSets
                        modsByHref={modifiers.byHref}
                        className={classes.modifiers}
                        optionSets={modifier._embedded.option_sets}
                        parent={modifier}
                        onModifierEdit={(m: LocationModifier) => setEditModifier(m)}
                        onSetVisibility={(m: LocationModifier) => setModifierVisibility({ modifier: m, saving: false })}
                    />

                    <OmniDrawer
                        close={() => setState((prevState) => ({ ...prevState, posConfig: undefined }))}
                        open={state.posConfig !== undefined}
                        title="Update Modifier Settings"
                    >
                        {modifier !== undefined && state.posConfig !== undefined && (
                            <EditPOSConfig
                                posConfig={modifier.pos_config}
                                strategy={modifier.strategy}
                                isModifier
                                traversal={traversal}
                                locationUrl={locationUrl}
                                onSave={(pos_config: POSConfig, strategy: ModifierStrategy, callback) =>
                                    dispatch(
                                        locationModifierSave(
                                            { ...modifier, linked: true, pos_config, strategy },
                                            modUrl,
                                            callback,
                                        ),
                                    )
                                }
                                onClose={() => setState((prevState) => ({ ...prevState, posConfig: undefined }))}
                                disableLocationSearch
                            />
                        )}
                    </OmniDrawer>

                    <OmniDrawer
                        close={() => setEditModifier(undefined)}
                        open={editModifier !== undefined}
                        title="Update Modifier Settings"
                    >
                        {selectedItem !== undefined && editModifier !== undefined && (
                            <EditPOSConfig
                                posConfig={editModifier.pos_config}
                                strategy={editModifier.strategy}
                                isModifier
                                traversal={buildTraversal(selectedItem, entityLocationPageLink(editModifier))}
                                locationUrl={locationUrl}
                                onSave={(pos_config: POSConfig, strategy: ModifierStrategy, callback) =>
                                    dispatch(
                                        locationModifierSave(
                                            { ...editModifier, linked: true, pos_config, strategy },
                                            editModifier._links.self.href,
                                            callback,
                                        ),
                                    )
                                }
                                onClose={() => setEditModifier(undefined)}
                                disableLocationSearch
                            />
                        )}
                    </OmniDrawer>

                    <ConfirmDialog
                        title={`${modifierVisibility.modifier?.enabled ? "Hide" : "Display"} Modifier`}
                        open={modifierVisibility.modifier !== undefined}
                        confirming={modifierVisibility.saving}
                        onConfirm={() => {
                            if (modifierVisibility.modifier) {
                                setModifierVisibility((prevState) => ({ ...prevState, saving: true }));
                                dispatch(
                                    locationModifierSave(
                                        {
                                            ...modifierVisibility.modifier,
                                            enabled: !modifierVisibility.modifier.enabled,
                                        },
                                        modifierVisibility.modifier._links.self.href,
                                        () => setModifierVisibility({ modifier: undefined, saving: false }),
                                    ),
                                );
                            }
                        }}
                        onClose={() => setModifierVisibility({ modifier: undefined, saving: false })}
                    >
                        <Typography>
                            The Modifier <strong>{modifierVisibility.modifier?.display_name}</strong> will be{" "}
                            {modifierVisibility.modifier?.enabled ? "hidden" : "displayed"} across any Item or Modifier
                            Group where it is used.
                        </Typography>
                    </ConfirmDialog>
                </>
            )}
        </DefaultLayout>
    );
}
