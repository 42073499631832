import React, { useContext, useEffect } from "react";
import { Period as MasterPeriod } from "store/mms/menus/master/periods/types";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { LoadingSkeletonRow, PeriodRow } from "./MenuPeriodRows";
import { MasterMenuContext, MenuEvent } from "pages/Menus/MasterMenu/context";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/rootReducer";
import { ZeroState } from "../ZeroState";
import { periodPageFetch } from "store/mms/menus/master/periods/actions";

interface MasterMenuPeriodsProps {
    className?: string;
}

export default function MasterMenuPeriods({ className }: MasterMenuPeriodsProps): JSX.Element {
    const { menu, onChange } = useContext(MasterMenuContext);
    const dispatch = useDispatch();
    const periodsById = useSelector((state: RootState) => state.mms.menus.master.periods.byId);
    const periodsByMenuId = useSelector((state: RootState) => state.mms.menus.master.periods.byMenuId);
    const masterPeriodsFetching = useSelector((state: RootState) => state.mms.menus.master.periods.fetching);
    const isLoading = menu === undefined || periodsByMenuId[menu.id] === undefined || masterPeriodsFetching;
    const periods = (!isLoading && periodsByMenuId[menu.id].map((id) => periodsById[id])) || [];
    const noPeriods = !isLoading && periods.length === 0;

    useEffect(() => {
        if (menu !== undefined && periodsByMenuId[menu.id] === undefined) {
            dispatch(periodPageFetch(menu.id));
        }
    }, [dispatch, periodsByMenuId, menu]);

    return noPeriods ? (
        <ZeroState
            createLabel="Create Period"
            title="Create a Menu Period to get started."
            onClick={() => onChange({ type: MenuEvent.PeriodAdd })}
        />
    ) : (
        <TableContainer className={className} component={Paper} variant="outlined" square>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Time Blocks</TableCell>
                        <TableCell>&nbsp;</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {isLoading ? (
                        <LoadingSkeletonRow />
                    ) : (
                        periods.map((p: MasterPeriod) => {
                            return (
                                <PeriodRow
                                    key={p.id}
                                    period={p}
                                    onEdit={(period: MasterPeriod) => onChange({ type: MenuEvent.PeriodEdit, period })}
                                    onDelete={(period: MasterPeriod) =>
                                        onChange({ type: MenuEvent.PeriodDelete, period })
                                    }
                                />
                            );
                        })
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
