import {
    LocationItem,
    LOCATION_ITEM_SAVE,
    LOCATION_ITEM_SAVE_ERROR,
    LOCATION_ITEM_SAVE_SUCCESS,
    LocationItemActions,
    LOCATION_ITEM_FETCH,
    LOCATION_ITEM_FETCH_ERROR,
    LOCATION_ITEM_FETCH_SUCCESS,
    LOCATION_ITEMS_HYDRATE,
} from "./types";

export function locationItemFetch(url: string): LocationItemActions {
    return {
        type: LOCATION_ITEM_FETCH,
        url,
    };
}

export function locationItemFetchSuccess(item: LocationItem): LocationItemActions {
    return {
        type: LOCATION_ITEM_FETCH_SUCCESS,
        item,
    };
}

export function locationItemFetchError(error: Error): LocationItemActions {
    return {
        type: LOCATION_ITEM_FETCH_ERROR,
        error,
    };
}

export function locationItemSave(
    item: LocationItem,
    url: string,
    callback?: (error?: Error) => void,
): LocationItemActions {
    return {
        type: LOCATION_ITEM_SAVE,
        item,
        url,
        callback,
    };
}

export function locationItemSaveSuccess(item: LocationItem): LocationItemActions {
    return {
        type: LOCATION_ITEM_SAVE_SUCCESS,
        item,
    };
}

export function locationItemSaveError(error: Error): LocationItemActions {
    return {
        type: LOCATION_ITEM_SAVE_ERROR,
        error,
    };
}

export function locationItemsHydrate(items: LocationItem[]): LocationItemActions {
    return {
        type: LOCATION_ITEMS_HYDRATE,
        items,
    };
}
