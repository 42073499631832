import React, { useContext, useEffect, useMemo, useState } from "react";
import { Box, Grid, Skeleton, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
    closestCenter,
    DndContext,
    DragEndEvent,
    DragOverlay,
    DragStartEvent,
    MouseSensor,
    TouchSensor,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import { arrayMove, rectSortingStrategy, SortableContext, useSortable } from "@dnd-kit/sortable";
import { debounce } from "lodash";
import { getSession } from "services/session";
import { saveDebounce } from "services/env";
import { taxRatesFetch, taxRatesSave } from "store/mms/menus/location/taxes/actions";
import { RootState } from "store/rootReducer";
import { List } from "components/common/List";
import { CSS } from "@dnd-kit/utilities";
import { LocationTaxRateItem, LocationTaxRateItemProps, Position } from "./LocationTaxRateItem";
import { TaxRate } from "store/mms/menus/location/taxes/types";
import { useParams } from "react-router-dom";
import { ActionButton } from "components/common/buttons/ActionButton";
import { LocationContext, LocationEvent } from "pages/Locations/context";
import { ZeroState } from "../ZeroState";

export function LocationTaxRatesList(): JSX.Element {
    const taxRatesById = useSelector((state: RootState) => state.mms.menus.location.taxRates.byId);
    const taxRateIdsByLocationId = useSelector((state: RootState) => state.mms.menus.location.taxRates.idsByLocationId);
    const fetching = useSelector((state: RootState) => state.mms.menus.location.taxRates.fetching);
    const dispatch = useDispatch();
    const { onChange } = useContext(LocationContext);

    const s = getSession();
    const accountUrl = `/1.1/accounts/${s?.accountId}`;
    const locationUrl = `${accountUrl}${location.pathname
        .replace("menus", "mms/menus")
        .replace(/(\/locations\/[^\/]*).*/, "$1")}`;
    const { locationId } = useParams() as { locationId: string };
    const taxRateUrl = `${locationUrl}/mms/settings/tax_rates`;
    const storeLocationTaxRates = taxRateIdsByLocationId[locationId] || [];
    const [locationTaxRates, setLocationTaxRates] = useState(storeLocationTaxRates.map((id) => taxRatesById[id]));

    const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
    const [activeId, setActiveId] = useState<string | null>(null);
    const activeIndex = activeId ? locationTaxRates.findIndex((s) => s.id === activeId) : -1;
    const handleResequence = useMemo(
        () =>
            debounce((taxRates: TaxRate[]) => {
                dispatch(taxRatesSave(taxRates, taxRateUrl));
            }, saveDebounce),
        [dispatch, taxRateUrl],
    );
    const handleDragEnd = ({ active, over }: DragEndEvent): void => {
        if (over !== null && active.id !== over.id) {
            const oldIndex = locationTaxRates.findIndex((i) => i.id === active.id);
            const newIndex = locationTaxRates.findIndex((i) => i.id === over.id);
            const newTaxRateSequence = arrayMove(locationTaxRates, oldIndex, newIndex);

            setLocationTaxRates(newTaxRateSequence);
            handleResequence(newTaxRateSequence);
        }

        setActiveId(null);
    };
    const handleDragStart = (event: DragStartEvent): void => setActiveId(event.active.id);
    const handleDragCancel = (): void => setActiveId(null);

    useEffect(() => {
        dispatch(taxRatesFetch(taxRateUrl));
    }, [dispatch, taxRateUrl]);

    useEffect(() => {
        if (storeLocationTaxRates.length > 0) {
            setLocationTaxRates(taxRateIdsByLocationId[locationId].map((id) => taxRatesById[id]));
        }
    }, [taxRateIdsByLocationId, locationId, taxRatesById, storeLocationTaxRates.length]);

    return fetching ? (
        <Skeleton />
    ) : (
        <>
            {locationTaxRates.length === 0 ? (
                <Box sx={{ display: "flex" }}>
                    <ZeroState
                        onClick={() => onChange({ type: LocationEvent.TaxRateAdd })}
                        createLabel="Add Tax Rate"
                        title="No Tax Rates have been created for this Location."
                    />
                </Box>
            ) : (
                <>
                    <Box sx={{ display: "flex" }}>
                        <Typography sx={{ marginBottom: (theme) => theme.spacing(2) }}>
                            Individualize your Location&apos;s taxes by tagging Items or Modifiers and creating a new
                            Tax Rate. The Tax Rate with the most matching Tags will be applied. If multiple Tax Rates
                            have the same number of matching Tags, the Tax Rate higher in the list will be used.
                        </Typography>
                        <Grid container sx={{ width: "50%", paddingLeft: (theme) => theme.spacing(1) }}>
                            <ActionButton
                                key="tax-rate-add"
                                label="Add Tax Rate"
                                onClick={() => onChange({ type: LocationEvent.TaxRateAdd })}
                                sx={{ marginRight: 0, marginLeft: "auto", height: "fit-content" }}
                            />
                        </Grid>
                    </Box>
                    <DndContext
                        sensors={sensors}
                        collisionDetection={closestCenter}
                        onDragStart={handleDragStart}
                        onDragEnd={handleDragEnd}
                        onDragCancel={handleDragCancel}
                    >
                        <SortableContext items={locationTaxRates} strategy={rectSortingStrategy}>
                            <List>
                                {locationTaxRates.map((rate, index) => (
                                    <SortableTaxRateItem
                                        activeIndex={activeIndex}
                                        taxRate={rate}
                                        key={rate.id}
                                        index={index + 1}
                                    />
                                ))}
                            </List>
                        </SortableContext>
                        <DragOverlay>
                            {activeId ? (
                                <LocationTaxRateItem
                                    taxRate={locationTaxRates[locationTaxRates.findIndex((i) => i.id === activeId)]}
                                    index={locationTaxRates.findIndex((i) => i.id === activeId)}
                                    dragOverlay
                                />
                            ) : null}
                        </DragOverlay>
                    </DndContext>
                </>
            )}
        </>
    );
}

const SortableTaxRateItem = (props: LocationTaxRateItemProps & { activeIndex: number }): JSX.Element => {
    const { attributes, index, isDragging, isSorting, listeners, over, setNodeRef, transform, transition } =
        useSortable({
            id: props.taxRate.id,
        });
    const style = {
        transform: isSorting ? undefined : CSS.Transform.toString(transform),
        transition: transition ? transition : undefined,
    };
    const { activeIndex, taxRate, ...rest } = props;

    return (
        <LocationTaxRateItem
            taxRate={taxRate}
            dragActive={isDragging}
            insertPosition={
                over?.id === taxRate.id ? (index > activeIndex ? Position.After : Position.Before) : undefined
            }
            style={style}
            ref={setNodeRef}
            listeners={listeners}
            {...rest}
            {...attributes}
        />
    );
};
