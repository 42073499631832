import { Drawer, DrawerProps, IconButton, Theme, Typography } from "@mui/material";
import { Clear as ClearIcon } from "@mui/icons-material";
import { makeStyles } from "theme";
import React from "react";

interface OmniDrawerProps extends DrawerProps {
    title: string;
    hideClose?: boolean;
    close(): void;
}

const useStyle = makeStyles()((theme: Theme) => ({
    drawerPaper: {
        minHeight: "100%",
        width: "40%",
        overflowX: "hidden",

        "& > form": {
            padding: theme.spacing(5),

            "& > footer": {
                marginBottom: 0,
                marginTop: theme.spacing(5),
            },
        },
    },
    drawerHeader: {
        // necessary for content to be below app bar
        ...{ ...theme.mixins.toolbar, "@font-face": undefined },
        display: "flex",
        alignItems: "center",
        backgroundColor: theme.palette.grey["A400"],
        padding: theme.spacing(1, 3),
        justifyContent: "space-between",
        color: theme.palette.getContrastText(theme.palette.grey["A400"]),
    },
    headerCloseIcon: {
        color: theme.palette.getContrastText(theme.palette.grey["A400"]),
    },
}));

export const OmniDrawer = React.forwardRef(function OmniDrawer(
    props: OmniDrawerProps,
    ref: ((instance: HTMLDivElement | null) => void) | React.RefObject<HTMLDivElement> | null | undefined,
) {
    const { classes } = useStyle();
    const { children, open, close, hideClose, title, ...rest } = props;

    return (
        <Drawer
            anchor="right"
            open={open}
            classes={{ paper: classes.drawerPaper }}
            onClose={(e, reason) => {
                if (reason === "backdropClick") {
                    close();
                }
            }}
            {...rest}
            ref={ref}
        >
            <header className={classes.drawerHeader}>
                <Typography variant="h6">{title}</Typography>
                {!hideClose && (
                    <IconButton onClick={close} className={classes.headerCloseIcon}>
                        <ClearIcon fontSize="small" />
                    </IconButton>
                )}
            </header>
            {children}
        </Drawer>
    );
});
