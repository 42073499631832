import React from "react";
import { Autocomplete } from "mui-rff";
import PaperWrapper from "components/common/PaperWrapper";
import { POSComboItemGroup, POSComboItemGroupItem, PriceLevel } from "store/mms/types";
import { Grid, MenuItem, TextField } from "@mui/material";
import { Field } from "react-final-form";
import { handleErrors } from "components/common/forms/helpers";

interface EmbeddedComboItemProps {
    disabled?: boolean;
    groups: POSComboItemGroup[];
    items: POSComboItemGroupItem[];
    priceLevels: PriceLevel[];
    name?: string;
}

export default function EmbeddedComboItem({
    disabled = false,
    groups,
    items,
    priceLevels,
    name = "",
}: EmbeddedComboItemProps): JSX.Element {
    const prefix = name !== "" ? `${name}.` : "";
    const hasPriceLevels = priceLevels.length > 0;

    return (
        <>
            <Field name={`${prefix}groupId`}>
                {({ input, meta }) => (
                    <TextField
                        select
                        {...input}
                        {...handleErrors(meta)}
                        fullWidth
                        label="Group"
                        title="Group"
                        variant="outlined"
                        required
                        disabled={disabled}
                    >
                        {groups.map((g: POSComboItemGroup, idx: number) => (
                            <MenuItem value={g.id} key={idx}>
                                {g.name}
                            </MenuItem>
                        ))}
                    </TextField>
                )}
            </Field>

            <Grid container spacing={3}>
                <Grid item md={9}>
                    <Autocomplete
                        PaperComponent={PaperWrapper}
                        name={`${prefix}itemId`}
                        options={items}
                        getOptionLabel={(i) => `${i.name} (${i.pos_id})`}
                        getOptionValue={(o) => o.id}
                        showError={({ meta }) => {
                            return meta.error && meta.dirty;
                        }}
                        label="POS Item"
                        helperText="Find the correct Item on the POS."
                        fullWidth
                        freeSolo
                        disableClearable
                        textFieldProps={{
                            title: "POS Item",
                            variant: "outlined",
                            disabled: disabled,
                        }}
                    />
                </Grid>

                <Grid item md={3}>
                    <Field name={`${prefix}price_level`}>
                        {({ input, meta }) => (
                            <TextField
                                select={hasPriceLevels}
                                {...input}
                                {...handleErrors(meta)}
                                fullWidth
                                title="Price Level"
                                label="Price Level"
                                variant="outlined"
                                disabled={disabled || !hasPriceLevels}
                                required={hasPriceLevels}
                            >
                                {hasPriceLevels &&
                                    priceLevels.map((pl: PriceLevel, idx: number) => (
                                        <MenuItem value={pl.id || ""} key={idx}>
                                            {pl.id} - $
                                            {(pl.price_per_unit !== null ? pl.price_per_unit / 100 : 0).toFixed(2)}
                                            {pl.name !== null ? ` (${pl.name})` : ""}
                                        </MenuItem>
                                    ))}
                            </TextField>
                        )}
                    </Field>
                </Grid>
            </Grid>
        </>
    );
}
