import { getSession } from "services/session";
import {
    Tag,
    TagActions,
    TaggedMenuEntity,
    TAGGED_ENTITY_UPDATE,
    TAGGED_ENTITY_UPDATE_ERROR,
    TAGGED_ENTITY_UPDATE_SUCCESS,
    TAGS_FETCH,
    TAGS_FETCH_COMPLETE,
    TAGS_FETCH_ERROR,
    TAGS_FETCH_SUCCESS,
} from "./types";

export function tagsFetch(url?: string): TagActions {
    if (url === undefined) {
        const s = getSession();
        if (!s) {
            return tagsFetchError(new Error("No logged in session"));
        }

        url = `/1.1/accounts/${s.accountId}/mms/tags`;
    }

    return { url, type: TAGS_FETCH };
}

export function tagsFetchSuccess(tags: Tag[]): TagActions {
    return { type: TAGS_FETCH_SUCCESS, tags };
}

export function tagsFetchComplete(): TagActions {
    return { type: TAGS_FETCH_COMPLETE };
}

export function tagsFetchError(error: Error): TagActions {
    return { type: TAGS_FETCH_ERROR, error };
}

export function taggedEntityUpdate(entity: TaggedMenuEntity, tags: Tag[]): TagActions {
    return { type: TAGGED_ENTITY_UPDATE, entity, tags };
}

export function taggedEntityUpdateSuccess(entity: TaggedMenuEntity, tags: Tag[]): TagActions {
    return { type: TAGGED_ENTITY_UPDATE_SUCCESS, entity, tags };
}

export function taggedEntityUpdateError(error: Error): TagActions {
    return { type: TAGGED_ENTITY_UPDATE_ERROR, error };
}
