import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, IconButton, Typography, Skeleton, Link, Tooltip } from "@mui/material";
import { Help as HelpIcon, VisibilityOff as VisibilityOffIcon, Warning as WarningIcon } from "@mui/icons-material";
import { Section } from "store/mms/menus/location/sections/types";
import { LocationItem } from "store/mms/menus/location/items/types";
import { money } from "components/common/utils";
import { Link as RouterLink } from "react-router-dom";
import { entityLocationPageLink } from "store/mms/menus/types";
import { RootState } from "store/rootReducer";
import pluralize from "pluralize";
import { MenuFilterContext, MenuFilterType } from "components/mms/MenuFilter";
import { ToggleDisplay } from "../IconButtons/ToggleDisplay";
import { EditPOSMapping } from "../IconButtons/EditPOSMapping";
import { List } from "components/common/List";
import { ListItem } from "components/common/ListItem";
import { LocationContext, LocationEvent } from "pages/Locations/context";
import { Card } from "components/common/Card";
import { sectionPageFetch } from "store/mms/menus/location/sections/actions";

export default function LocationMenuSections(): JSX.Element {
    const dispatch = useDispatch();
    const { menuUrl, menuId, onChange } = useContext(LocationContext);
    const { query, type } = useContext(MenuFilterContext);

    const sectionsFetching = useSelector((state: RootState) => state.mms.menus.location.sections.fetching);
    const sectionsByMenuUrl = useSelector((state: RootState) => state.mms.menus.location.sections.byMenuUrl);
    const sectionsByUrl = useSelector((state: RootState) => state.mms.menus.location.sections.byUrl);
    const sections = (sectionsByMenuUrl[menuUrl || ""] || []).map((sectionUrl) => sectionsByUrl[sectionUrl]);

    const isLoading = !menuUrl || !menuId || sectionsByMenuUrl[menuUrl] === undefined || sectionsFetching;

    useEffect(() => {
        if (!menuUrl || !menuId) return;
        if (!sectionsFetching && sectionsByMenuUrl[menuUrl] === undefined) {
            dispatch(sectionPageFetch(`${menuUrl}/sections`));
        }
    }, [dispatch, menuId, menuUrl, sectionsByMenuUrl, sectionsFetching]);

    if (isLoading) {
        return <LoadingSkeleton />;
    }

    const menuSections = sections.filter((section) => {
        if (type === MenuFilterType.Categories && query) {
            return section.name.toLowerCase().includes(query.toLowerCase()) ? section : null;
        }
        return section;
    });

    return (
        <List>
            {menuSections.map((section) => (
                <SectionItem
                    section={section}
                    key={section.id}
                    onEdit={(item) => onChange({ type: LocationEvent.ItemEdit, item })}
                    onChangeVisibility={(item) => onChange({ type: LocationEvent.ItemVisibility, item })}
                />
            ))}
            {menuSections.length === 0 && (
                <ListItem
                    ListItemTextProps={{
                        primary:
                            type === MenuFilterType.Categories && query
                                ? "No matching Categories..."
                                : "Menu not configured",
                    }}
                />
            )}
        </List>
    );
}

interface unlinkedEntities {
    items: number;
    modifiers: number;
}

interface SectionItemProps {
    section: Section;
    onEdit?(item: LocationItem): void;
    onChangeVisibility?(item: LocationItem): void;
}

function SectionItem({ section, ...rest }: SectionItemProps): JSX.Element {
    // Use the store to get the item so the section does not need to be refetched when a item changes.
    const itemsByHref = useSelector((state: RootState) => state.mms.menus.location.items.byHref);
    const unlinkedCount: unlinkedEntities = { items: 0, modifiers: 0 };
    const { query, type } = useContext(MenuFilterContext);
    const items = section._embedded.items
        .filter((i) => itemsByHref[i._links.self.href] !== undefined)
        .filter((item) => {
            if (type === MenuFilterType.Items && query) {
                return item.display_name.toLowerCase().includes(query.toLowerCase()) ? item : null;
            }
            return item;
        });

    for (const sectionItem of items) {
        const item = itemsByHref[sectionItem._links.self.href];

        if (!item.linked) {
            unlinkedCount.items += 1;
        }
        if (item.unlinkedModifiers !== undefined) {
            unlinkedCount.modifiers += item.unlinkedModifiers;
        }
    }
    const isDisabled = !section.enabled;
    const actions = (
        <>
            {unlinkedCount.items > 0 || unlinkedCount.modifiers > 0 ? (
                <UnlinkedEntityCount count={unlinkedCount} />
            ) : null}
            {isDisabled ? (
                <Tooltip title="Hidden on Menu" arrow>
                    <IconButton
                        size="small"
                        sx={{ marginLeft: (theme) => theme.spacing(1.5) }}
                        disableRipple
                        disableTouchRipple
                        disableFocusRipple
                    >
                        <VisibilityOffIcon fontSize="inherit" sx={{ color: "text.disabled" }} />
                    </IconButton>
                </Tooltip>
            ) : null}
        </>
    );

    return (
        <ListItem
            ListItemTextProps={{
                primary: section.name,
                secondary: section.description,
                secondaryTypographyProps: { sx: { marginLeft: 0 } },
                className: isDisabled ? "disabled" : undefined,
            }}
            ListItemSecondaryActionProps={{
                sx: {
                    "& button": {
                        marginRight: 0,
                    },
                },
            }}
            actions={actions}
            expanded={query !== ""}
        >
            {items.length === 0 ? (
                <Typography variant="subtitle1" color={isDisabled ? "text.disabled" : "textSecondary"}>
                    No matching Items...
                </Typography>
            ) : (
                <Grid container spacing={3}>
                    {items.map((i, idx) => (
                        <ItemCard
                            parentDisabled={isDisabled}
                            item={itemsByHref[i._links.self.href]}
                            key={i.id + idx}
                            {...rest}
                        />
                    ))}
                </Grid>
            )}
        </ListItem>
    );
}

interface UnlinkedEntityCountProps {
    count: unlinkedEntities;
}

function UnlinkedEntityCount({ count }: UnlinkedEntityCountProps): JSX.Element {
    const counts = [];

    if (count.items > 0) {
        counts.push(`${count.items} ${pluralize("Item", count.items)}`);
    }
    if (count.modifiers) {
        counts.push(`${count.modifiers} ${pluralize("Modifier", count.modifiers)}`);
    }

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
            }}
        >
            <IconButton sx={{ marginRight: (theme) => theme.spacing(0.5) }} disabled size="small">
                <WarningIcon fontSize="inherit" color="warning" />
            </IconButton>
            <Typography noWrap>{`Unlinked from POS: ${counts.join(" | ")}`}</Typography>
        </Box>
    );
}

interface ItemCardProps {
    item: LocationItem;
    parentDisabled: boolean;
    onEdit?(item: LocationItem): void;
    onChangeVisibility?(item: LocationItem): void;
}

function ItemCard({ item, parentDisabled, onEdit, onChangeVisibility }: ItemCardProps): JSX.Element {
    const unlinkedStatuses = [];
    const disabledText = !item.enabled || parentDisabled;

    if (!item.linked) {
        unlinkedStatuses.push("Item");
    }

    if (item.unlinkedModifiers !== undefined && item.unlinkedModifiers > 0) {
        unlinkedStatuses.push("Modifiers");
    }

    return (
        <Grid
            item
            sx={(theme) => ({
                flexBasis: "50%",
                "&:focus-visible": {
                    outline: "none",
                },

                [theme.breakpoints.up("xl")]: {
                    flexBasis: "33%",
                },

                [theme.breakpoints.down("sm")]: {
                    flexBasis: "100%",
                },
            })}
        >
            <Card
                className={disabledText ? "disabled" : undefined}
                image={item._embedded.image?.url}
                cardActions={
                    <Box sx={{ display: "flex", justifyContent: "space-between", flex: "1 0 auto" }}>
                        <Box
                            sx={{
                                "& > *:first-of-type": {
                                    marginRight: (theme) => theme.spacing(1),
                                },
                            }}
                        >
                            <EditPOSMapping
                                linked={item.linked}
                                onClick={() => {
                                    onEdit && onEdit(item);
                                }}
                            />

                            <ToggleDisplay
                                hidden={!item.enabled}
                                onClick={() => {
                                    onChangeVisibility && onChangeVisibility(item);
                                }}
                            />
                        </Box>

                        {unlinkedStatuses.length > 0 && (
                            <Box>
                                <IconButton disabled size="small">
                                    <WarningIcon fontSize="inherit" color="warning" />
                                </IconButton>
                                <Typography variant="caption">
                                    {`Status: ${unlinkedStatuses.join(" & ")} Unlinked`}
                                </Typography>
                            </Box>
                        )}
                        {unlinkedStatuses.length === 0 && !item.master_enabled && (
                            <Box>
                                <Tooltip
                                    title="The master Menu or one of its Layers has hidden this Item. While hidden, this Item cannot be ordered."
                                    arrow
                                >
                                    <Box component="span">
                                        <IconButton size="small" disableRipple disableTouchRipple disableFocusRipple>
                                            <HelpIcon fontSize="inherit" color="info" />
                                        </IconButton>

                                        <Typography variant="caption">{`Status: Hidden on Master Menu/Layer`}</Typography>
                                    </Box>
                                </Tooltip>
                            </Box>
                        )}
                    </Box>
                }
            >
                <Link component={RouterLink} to={entityLocationPageLink(item)}>
                    <Typography
                        sx={{
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 1,
                            overflow: "hidden",
                            wordBreak: "break-word",
                        }}
                        variant="h6"
                    >
                        {item.display_name}
                    </Typography>
                </Link>
                {item.description && (
                    <Typography
                        sx={{
                            marginBottom: (theme) => theme.spacing(0.25),
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 2,
                            overflow: "hidden",
                            wordBreak: "break-word",
                        }}
                        variant="subtitle2"
                    >
                        {item.description}
                    </Typography>
                )}
                {item.price_per_unit ? (
                    <Typography
                        variant="subtitle2"
                        sx={
                            disabledText
                                ? {
                                      color: "text.disabled",
                                      textDecorationColor: (theme) => theme.palette.text.disabled,
                                  }
                                : {}
                        }
                    >
                        {money(item.price_per_unit)}
                    </Typography>
                ) : null}
            </Card>
        </Grid>
    );
}

function LoadingSkeleton(): JSX.Element {
    return (
        <List>
            <ListItem
                disabled
                ListItemTextProps={{
                    primary: (
                        <Grid container direction="column">
                            <Grid item>
                                <Skeleton height={42} width={275} />
                            </Grid>
                            <Grid item>
                                <Skeleton height={25} width={500} />
                            </Grid>
                        </Grid>
                    ),
                }}
            />
            <ListItem
                disabled
                ListItemTextProps={{
                    primary: (
                        <Grid container direction="column">
                            <Grid item>
                                <Skeleton height={42} width={275} />
                            </Grid>
                            <Grid item>
                                <Skeleton height={25} width={500} />
                            </Grid>
                        </Grid>
                    ),
                }}
            />
            <ListItem
                disabled
                ListItemTextProps={{
                    primary: (
                        <Grid container direction="column">
                            <Grid item>
                                <Skeleton height={42} width={275} />
                            </Grid>
                            <Grid item>
                                <Skeleton height={25} width={500} />
                            </Grid>
                        </Grid>
                    ),
                }}
            />
        </List>
    );
}
