import React from "react";
import { List as MuiList, ListProps as MuiListProps } from "@mui/material";

export const List = React.forwardRef(function List(
    { children, ...rest }: Omit<MuiListProps, "component">,
    ref: React.ForwardedRef<HTMLElement | null>,
) {
    const { sx = [], ...lessRest } = rest;
    return (
        <MuiList
            component="nav"
            sx={[
                {
                    "& .MuiListItem-container": {
                        display: "flex",
                    },

                    "& > .MuiListItemButton-root:last-child": {
                        borderBottom: "none",
                    },
                },
                ...(Array.isArray(sx) ? sx : [sx]),
            ]}
            ref={ref}
            {...lessRest}
        >
            {children}
        </MuiList>
    );
});
