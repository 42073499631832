import { OrderProfileAdjustmentTypes } from "store/mms/order-profiles/types";
import { Tag } from "store/mms/tags/types";
import { SaveAction } from "store/types";
import { DayOfWeek } from "../../../periods/types";

export const RULE_SAVE = "@@ADMINMASTER/MENU/LAYER/RULE/SAVE";
export const RULE_DELETE = "@@ADMINMASTER/MENU/LAYER/RULE/DELETE";

export interface MenuLayerRule {
    id: string;
    enabled: boolean;
    name: string;
    local_start: string | null;
    local_end: string | null;
    definition: MenuLayerRuleDefinition;

    _links: {
        self: {
            href: string;
            type: "application/hal+json; name=mms_master_menu_layer_rule";
        };
    };
}

export type NewMenuLayerRule = Omit<MenuLayerRule, "id" | "_links">;

export interface MenuLayerRuleReq extends Omit<MenuLayerRule, "id" | "_links"> {
    id?: string;
}

export interface MenuLayerRuleDefinition {
    constraints: {
        order_profiles: string[];
    };
    matches: {
        items: string[];
        modifiers: string[];
        sections: string[];
    };
    transforms: MenuLayerRuleDefinitionTransform[];
}

export enum TransformType {
    Enabled = "enabled",
    AddTags = "add_tags",
    AdjustPrice = "adjust_price",
    AdjustMenuPeriods = "adjust_menu_periods",
}

export type MenuLayerRuleDefinitionTransform =
    | MenuLayerRuleTransformEnabled
    | MenuLayerRuleTransformAdjustPrice
    | MenuLayerRuleTransformAddTags
    | MenuLayerRuleTransformAdjustMenuPeriods;

export interface MenuLayerRuleTransformEnabled {
    config: {
        enabled: boolean;
    };
    type: TransformType.Enabled;
}

export interface MenuLayerRuleTransformAdjustPrice {
    config: {
        amount: number | null;
        type: OrderProfileAdjustmentTypes | null;
        order_profile: string;
        enabled: boolean;
    };
    type: TransformType.AdjustPrice;
}

export interface MenuLayerRuleTransformAddTags {
    config: {
        tags: Omit<Tag, "_links">[];
    };
    type: TransformType.AddTags;
}

export interface MenuLayerRuleTransformAdjustMenuPeriods {
    config: {
        periods: MenuLayerRuleTransformMenuPeriod[];
    };
    type: TransformType.AdjustMenuPeriods;
}

export interface MenuLayerRuleTransformMenuPeriod {
    id: string;
    prevent_location_override: boolean;
    blocks: MenuLayerRuleTransformMenuPeriodBlock[];
}

export interface MenuLayerRuleTransformMenuPeriodBlock {
    day: DayOfWeek;
    start: string;
    start_buffer: number;
    duration: number;
    eod_buffer: number;
}

export interface RuleSave extends SaveAction {
    type: typeof RULE_SAVE;
    url: string;
    rule: MenuLayerRule | NewMenuLayerRule;
}

export interface RuleDelete extends SaveAction {
    type: typeof RULE_DELETE;
    rule: MenuLayerRule;
}

export type RuleActions = RuleSave | RuleDelete;

export const isMenuLayerRule = (e: MenuLayerRule | NewMenuLayerRule): e is MenuLayerRule =>
    (e as MenuLayerRule)?._links?.self?.type === "application/hal+json; name=mms_master_menu_layer_rule";
