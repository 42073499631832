import { Box, Skeleton, Typography } from "@mui/material";
import { DataGrid, GridCellParams, GridSortItem } from "@mui/x-data-grid";
import { QuickSearchToolbar } from "components/common/forms/QuickSearchToolbar";
import { OmniDrawer } from "components/common/OmniDrawer";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LocationMenuApplication } from "store/mms/menus/location/applications/types";
import { RootState } from "store/rootReducer";
import { searchDebounce } from "services/env";
import { search } from "components/common/forms/helpers";
import { debounce } from "lodash";
import { LocationMenu } from "store/mms/menus/location/types";
import { format } from "date-fns";
import { ListPerPageOptions } from "components/props";
import { applicationActionPageFetch } from "store/mms/application-actions/actions";
import { LocationContext } from "pages/Locations/context";
import { ApplicationActionEvent, APPLICATION_ACTION_TYPE_ORDERING_ENABLE } from "store/mms/application-actions/types";

interface ApplicationAuditLogProps {
    open: boolean;
    onClose: () => void;
    menu: LocationMenu | undefined;
    application: LocationMenuApplication | undefined;
}
export const ApplicationAuditLog = ({ open = false, onClose, menu, application }: ApplicationAuditLogProps) => {
    const { locationId, menuId } = useContext(LocationContext);

    const applicationHref = application?._links.self.href || "";

    const changeEventsByHref = useSelector((state: RootState) => state.mms.applicationActions.eventsByApplicationHref);
    const changeEventsById = useSelector((state: RootState) => state.mms.applicationActions.eventsById);
    const changeEvents = useMemo(
        () =>
            mapToSearchable(
                (changeEventsByHref[applicationHref] || [])
                    .filter(
                        (id) =>
                            !changeEventsById[id].scheduled &&
                            changeEventsById[id].actionType === APPLICATION_ACTION_TYPE_ORDERING_ENABLE,
                    )
                    .map((id) => changeEventsById[id]) || [],
            ),
        [changeEventsByHref, applicationHref, changeEventsById],
    );
    const [events, setEvents] = useState<SearchableApplicationEvent[]>([]);
    useEffect(() => {
        setEvents(changeEvents);
    }, [setEvents, changeEvents]);

    const dispatch = useDispatch();
    useEffect(() => {
        if (application) {
            dispatch(applicationActionPageFetch(application.id, locationId, menuId));
        }
    }, [dispatch, application, locationId, menuId]);

    const [searchText, setSearchText] = useState("");
    const debouncedSearch = useMemo(
        () =>
            debounce((query: string) => {
                search(query, changeEvents, setEvents);
            }, searchDebounce),
        [changeEvents],
    );
    const [pageSize, setPageSize] = useState(ListPerPageOptions[0]);
    const [sortModel, setSortModel] = React.useState<GridSortItem[]>([
        {
            field: "created",
            sort: "desc",
        },
    ]);

    const isLoading =
        application === undefined ||
        menu === undefined ||
        changeEventsByHref[applicationHref] === undefined ||
        events.length === 0;

    return (
        <OmniDrawer open={open} title="Change Log" close={onClose}>
            <DataGrid
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={ListPerPageOptions}
                sx={{ padding: (theme) => theme.spacing(2, 2, 0, 2) }}
                loading={isLoading}
                sortModel={sortModel}
                onSortModelChange={(model) => setSortModel(model)}
                components={{
                    Toolbar: QuickSearchToolbar,
                }}
                componentsProps={{
                    toolbar: {
                        value: searchText,
                        onChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                            const query = e.target.value;
                            setSearchText(query);
                            debouncedSearch(query);
                        },
                        clearSearch: () => {
                            setSearchText("");
                            search("", changeEvents, setEvents);
                        },
                        title: (
                            <Box
                                display="flex"
                                flexDirection="column"
                                sx={(theme) => ({ marginLeft: theme.spacing(1) })}
                            >
                                {!menu || !application ? (
                                    <Skeleton />
                                ) : (
                                    <>
                                        <Typography variant="h5">{menu.name}</Typography>
                                        <Typography variant="h6" sx={{ marginTop: (theme) => theme.spacing(0.5) }}>
                                            {application.name}
                                        </Typography>
                                    </>
                                )}
                            </Box>
                        ),
                        enableExports: true,
                    },
                }}
                rows={events}
                columns={[
                    {
                        field: "created",
                        headerName: "Created",
                        flex: 0.35,
                        renderCell: function (params: GridCellParams) {
                            return params.row.created;
                        },
                    },
                    {
                        field: "user",
                        headerName: "User",
                        flex: 0.18,
                        renderCell: function (params: GridCellParams) {
                            return <Box>{params.row._embedded.user.full_name}</Box>;
                        },
                    },
                    {
                        field: "location",
                        headerName: "Location ID",
                        flex: 0.18,
                        renderCell: function (params: GridCellParams) {
                            return <Box>{params.row._embedded.location.id}</Box>;
                        },
                    },
                    {
                        field: "menu",
                        headerName: "Menu ID",
                        flex: 0.18,
                        renderCell: function (params: GridCellParams) {
                            return <Box>{params.row._embedded.menu.id}</Box>;
                        },
                    },
                    {
                        field: "enabled",
                        headerName: "New Status",
                        flex: 0.18,
                        renderCell: function (params: GridCellParams) {
                            return (
                                <Box
                                    sx={{
                                        color: (theme) =>
                                            params.row.enabled === "Enabled"
                                                ? theme.palette.success.main
                                                : theme.palette.grey[800],
                                    }}
                                >
                                    {params.row.enabled}
                                </Box>
                            );
                        },
                    },
                    {
                        field: "status",
                        headerName: "Result",
                        flex: 0.18,
                        renderCell: function (params: GridCellParams) {
                            return params.row.status;
                        },
                    },
                ]}
            />
        </OmniDrawer>
    );
};

interface SearchableApplicationEvent
    extends Omit<ApplicationActionEvent, "created" | "status" | "enabled" | "_embedded"> {
    created: string;
    status: string;
    enabled: string;
}
const mapToSearchable = (changes: ApplicationActionEvent[]): SearchableApplicationEvent[] =>
    changes
        .filter((c) => c !== undefined)
        .map((change) => {
            return {
                ...change,
                created: format(new Date((change.created || 0) * 1000), "yyyy-MM-dd HH:mm a"),
                status: change.status[0].toUpperCase() + change.status.substring(1),
                enabled: change.enabled ? "Enabled" : "Disabled",
            };
        });
