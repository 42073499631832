import { TextField, Alert, Autocomplete, AutocompleteInputChangeReason, Skeleton } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { taggedEntityUpdate, tagsFetch } from "store/mms/tags/actions";
import { Tag, TaggedMenuEntity } from "store/mms/tags/types";
import { RootState } from "store/rootReducer";
import { useStyle } from "./helpers";
import TagCloud from "./TagCloud";

interface Props {
    entity: TaggedMenuEntity;
}

export default function Tags({ entity }: Props): JSX.Element {
    const dispatch = useDispatch();
    const allTags = useSelector((state: RootState) => state.mms.tags.tags);
    const fetching = useSelector((state: RootState) => state.mms.tags.fetching);
    const fetchingError = useSelector((state: RootState) => state.mms.tags.fetchingError);
    const [disabled, setDisabled] = useState(true);
    const ref = useRef<HTMLDivElement | undefined>();
    const { classes } = useStyle();

    const usedTags = entity?._embedded.tags || [];
    const usedTagsById = usedTags.reduce((acc, t) => ({ ...acc, [t.id]: t }), {});
    const unusedTags = allTags.filter((t) => !(t.id in usedTagsById)) || [];

    useEffect(() => {
        if (!allTags.length && fetchingError === undefined) {
            dispatch(tagsFetch());
        } else if (allTags.length) {
            // taggedEntityUpdate is handled by not just the tags reducer, but also every by the concrete type for every
            // TaggedMenuEntity (e.g. MasterItems are TaggedMenuEntities and its reducer handles this event).  Because
            // of this, every time we change the dispatch this action, we'll get updated tags on the entity.
            setDisabled(usedTags.length === allTags.length);
        }
    }, [dispatch, allTags, fetchingError, usedTags.length]);

    const handleAdd = (event: unknown, tag: Tag | null): void => {
        if (tag) {
            const newTags = [...usedTags, tag];
            dispatch(taggedEntityUpdate(entity, newTags));
        }
    };

    const handleDelete = (tag: Tag): void => {
        const newTags = usedTags.filter((t) => t.id !== tag.id);
        dispatch(taggedEntityUpdate(entity, newTags));
        setDisabled(newTags.length === allTags.length);
    };

    // Clear the input after something is selected.  I got this idea from
    // https://stackoverflow.com/questions/59790956/material-ui-autocomplete-clear-value.  It feels gross but I spent a
    // couple of hours and this is the only thing that works with the UX that I want.  Alternatively, you can change the
    // `key` on the Autocomplete, but that loses keyboard focus which is strongly undesireable.
    //
    // Disabled must be managed state rather than just a plain property (e.g. `disabled={usedTags.length ===
    // allTags.length}`) because we need to control the timing of disabling to ensure that we can click the clear
    // button.  Otherwise, disabling happens first and the clear button goes away before we can click it.
    const onInputChange = (event: React.ChangeEvent<unknown>, value: string, reason: AutocompleteInputChangeReason) => {
        if (reason === "reset" && ref?.current) {
            ref.current.querySelector("button")?.click();
            setDisabled(usedTags.length === allTags.length);
        }
    };

    return (
        <>
            {fetching ? (
                <>
                    <Skeleton height={100} className={classes.skeleton} />
                </>
            ) : fetchingError ? (
                <Alert severity="error" className={classes.autocomplete}>
                    Error loading Tags. Try again later.
                </Alert>
            ) : (
                <Autocomplete
                    ref={ref}
                    autoComplete
                    autoHighlight
                    clearOnBlur
                    openOnFocus
                    selectOnFocus
                    className={classes.autocomplete}
                    options={unusedTags}
                    disabled={disabled}
                    getOptionLabel={(option) => option.value}
                    isOptionEqualToValue={(): boolean => true}
                    onChange={handleAdd}
                    onInputChange={onInputChange}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label={unusedTags.length ? "Add Tags" : "All Tags used"}
                        />
                    )}
                />
            )}

            <TagCloud
                tags={usedTags}
                // If allTags cannot be loaded, don't allow deletes.  An accidental delete wont be fixable until
                // allTags can be loaded again.
                onDelete={fetchingError ? undefined : handleDelete}
                loading={fetching}
            />
        </>
    );
}
