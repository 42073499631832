import { IconButton, IconButtonProps, Tooltip } from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import React from "react";

interface EntityRemoveProps extends IconButtonProps {
    onClick(e?: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
    title?: string;
}

export function EntityRemove({ onClick, title = "Remove", sx = [] }: EntityRemoveProps): JSX.Element {
    return (
        <Tooltip title={title} arrow>
            <IconButton aria-label={title.toLowerCase().split(" ").join("-")} sx={sx} onClick={onClick} size="small">
                <DeleteIcon fontSize="inherit" />
            </IconButton>
        </Tooltip>
    );
}
