import { Grid, MenuItem, TextField, Theme, Tooltip, Typography, useTheme } from "@mui/material";
import { makeStyles } from "theme";
import { Link as RouterLink, useParams } from "react-router-dom";
import React, { useContext, useEffect } from "react";
import DefaultLayout from "components/common/layouts/DefaultLayout";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/rootReducer";
import { getBreadcrumbSkeleton } from "components/skeletons";
import { RuleSetContext, RuleSetEvent, RuleSetProvider } from "./context";
import Rules from "components/mms/MenuLayers/RuleSets/RuleSetRulesList";
import Locations from "components/mms/MenuLayers/RuleSets/RuleSetLocationsList";
import { layersPageFetch } from "store/mms/menus/master/layers/actions";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RuleSetSidebar from "components/mms/MenuLayers/RuleSets/RuleSetSidebar";
import { Skeleton } from "@mui/material";
import { ActionButton, ActionButtonContainer } from "components/common/buttons/ActionButton";
import { EntityEdit } from "components/mms/IconButtons/EntityEdit";

const useRuleSetStyles = makeStyles()((theme: Theme) => ({
    layerLabel: { margin: "auto", paddingRight: theme.spacing(1) },
    layerLight: { margin: "auto", paddingBottom: theme.spacing(0.25) },
}));

export function RuleSet(): JSX.Element {
    return (
        <RuleSetProvider>
            <RuleSetIndex />
        </RuleSetProvider>
    );
}

function RuleSetIndex(): JSX.Element {
    const theme = useTheme();
    const { classes } = useRuleSetStyles();

    const dispatch = useDispatch();

    const { onChange, onRuleSetChange } = useContext(RuleSetContext);
    const { menuId, layerId, ruleSetId } = useParams() as { menuId: string; layerId: string; ruleSetId: string };

    const menu = useSelector((state: RootState) => state.mms.menus.master.menus.byId[menuId]);
    const layer = useSelector((state: RootState) => state.mms.menus.master.layers.byId[layerId]);

    const ruleSets = layer?._embedded.rule_sets || [];
    const ruleSet = ruleSets[ruleSets.findIndex((set) => set.id === ruleSetId)];

    const isLoading = menu === undefined || layer === undefined || ruleSet === undefined;

    const breadcrumbs = isLoading
        ? getBreadcrumbSkeleton()
        : [
              <RouterLink key="menu" to={`/menus/${menu.id}`}>
                  {menu.name}
              </RouterLink>,
              <Tooltip
                  style={{ cursor: "default" }}
                  key="layer"
                  title={
                      <>
                          This <strong>Menu Layer</strong> is {layer.enabled ? "enabled." : "disabled."}
                      </>
                  }
              >
                  <Grid container direction="row">
                      <RouterLink className={classes.layerLabel} key="menu" to={`/menus/${menu.id}/layers`}>
                          {layer.name}
                      </RouterLink>{" "}
                      {layer.enabled ? (
                          <FontAwesomeIcon
                              className={classes.layerLight}
                              icon={faCircle}
                              fixedWidth
                              color={theme.palette.success.main}
                          />
                      ) : (
                          <FontAwesomeIcon
                              className={classes.layerLight}
                              icon={faCircle}
                              fixedWidth
                              color={theme.palette.grey[600]}
                          />
                      )}
                  </Grid>
              </Tooltip>,
              <Typography key="ruleSet" color="textPrimary">
                  {ruleSet.name}
              </Typography>,
          ];

    useEffect(() => {
        if (menu !== undefined && layer === undefined) {
            dispatch(layersPageFetch(menu));
        }
    }, [dispatch, menu, layer]);

    const actions = [
        <ActionButton
            key="Add Rule"
            label="Add Rule"
            onClick={() => onChange({ type: RuleSetEvent.RuleAdd })}
            sx={{ margin: (theme) => theme.spacing(1) }}
        />,
    ];

    return (
        <DefaultLayout
            aside={<RuleSetSidebar loading={isLoading} />}
            breadcrumbs={[
                <RouterLink key="menus" to="/menus">
                    Menus
                </RouterLink>,
                ...breadcrumbs,
            ]}
        >
            <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
                <Grid item xs={6}>
                    {isLoading ? (
                        <Skeleton />
                    ) : (
                        <Grid container alignItems="center">
                            <Grid item>
                                <TextField
                                    value={ruleSet?.id || ""}
                                    select
                                    margin="dense"
                                    color="primary"
                                    variant="standard"
                                    fullWidth
                                    onChange={onRuleSetChange}
                                >
                                    {ruleSets.map((ruleSet) => (
                                        <MenuItem value={ruleSet.id} key={ruleSet.id}>
                                            <Typography display="inline" variant="h5">
                                                {ruleSet.name}
                                            </Typography>
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item>
                                <EntityEdit
                                    onClick={() => onChange({ type: RuleSetEvent.RuleSetEdit })}
                                    title="Edit Ruleset Name"
                                />
                            </Grid>
                        </Grid>
                    )}
                </Grid>

                {!isLoading && <ActionButtonContainer>{actions}</ActionButtonContainer>}

                <Grid item xs={12}>
                    {isLoading && <Skeleton />}
                </Grid>
            </Grid>
            <Rules />
            <Locations />
        </DefaultLayout>
    );
}
