import { ForkEffect, fork } from "redux-saga/effects";
import { accountSaga } from "./account/effects";
import restaurantConceptsSaga from "./account/restaurant_concepts/effects";
import { locationsSaga } from "./locations/effects";
import { reportsSaga } from "./reports/effects";
import mmsSaga from "./mms/effects";

export default function* rootSaga(): Generator<ForkEffect<void>, void, unknown> {
    yield fork(accountSaga);
    yield fork(restaurantConceptsSaga);
    yield fork(locationsSaga);
    yield fork(reportsSaga);
    yield fork(mmsSaga);
}
