import React from "react";
import logo from "./logo-lg-no-font.png";
import { Link as RouterLink, useLocation } from "react-router-dom";
import {
    Link,
    AppBar,
    Box,
    IconButton,
    Menu,
    MenuItem,
    Theme,
    Popover,
    useMediaQuery,
    Drawer,
    List,
    ListItemButton,
    Divider,
    Tooltip,
} from "@mui/material";
import {
    Campaign as CampaignIcon,
    Settings as SettingsIcon,
    Menu as MenuIcon,
    Logout as LogoutIcon,
} from "@mui/icons-material";

const NAVBAR_MOBILE_HEIGHT = "50px";
export const navBarWidth = "240px";

interface NavButtonProps {
    component?: React.ElementType;
    NavButtonType?: "button" | "item";
    onClick?: () => void;
    title: string;
    to: string;
}
const releasesUrl = "https://omnivoreio.notion.site/Release-Notes-2022-c8d6bbe39ef04ef3b557cdbfd78ea4ec";
const handleReleasesClick = () => window.open(releasesUrl, "_blank");

function NavButton({
    component = RouterLink,
    NavButtonType = "button",
    onClick,
    title,
    to,
}: NavButtonProps): JSX.Element {
    const loc = useLocation();
    const isSelected = loc.pathname.startsWith(to);

    return NavButtonType === "button" ? (
        <ListItemButton
            sx={(theme) => ({
                "&.Mui-selected": {
                    backgroundColor: (theme) => theme.palette.primary.main,
                    "&:hover": {
                        backgroundColor: (theme) => theme.palette.augmentColor({ color: theme.palette.primary }).dark,
                    },
                },
                ...(theme.palette.mode === "light"
                    ? {
                          "&:hover": {
                              backgroundColor: (theme) =>
                                  theme.palette.augmentColor({ color: { main: theme.mixins.mainNavColor } }).light,
                          },
                      }
                    : {}),
                width: "100%",
                my: 2,
                display: "block",
                paddingLeft: theme.spacing(3),
                margin: theme.spacing(1, 0),
            })}
            selected={isSelected}
            onClick={onClick}
            component={component}
            to={to}
        >
            {title}
        </ListItemButton>
    ) : (
        <MenuItem selected={isSelected} component={component} to={to} onClick={onClick}>
            {title}
        </MenuItem>
    );
}

interface MainNavProps {
    themeSwitch?: JSX.Element;
}

export default function MainNav({ themeSwitch }: MainNavProps): JSX.Element {
    const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => setMenuAnchorEl(event.currentTarget);
    const handleMenuClose = () => setMenuAnchorEl(null);
    const handleThemeClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
    const handleThemeClose = () => setAnchorEl(null);

    const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

    const pages: { to: string; title: string; onClick: () => void; component?: React.ElementType }[] = [
        { to: "/menus", title: "Menus", onClick: handleMenuClose },
        { to: "/locations", title: "Locations", onClick: handleMenuClose },
        { to: "/order-profiles", title: "Order Profiles", onClick: handleMenuClose },
    ];

    // Only include the logout and release note options in the hamburger menu.  Otherwise, make them an icon.
    if (isSmallScreen)
        pages.push(
            {
                to: "/release-notes",
                title: "What's New?",
                onClick: () => {
                    handleReleasesClick();
                    handleMenuClose();
                },
                component: "li",
            },
            { to: "/logout", title: "Logout", onClick: handleMenuClose },
        );

    const content = (
        <AppBar
            position={isSmallScreen ? "fixed" : "static"}
            sx={(theme) => ({
                [theme.breakpoints.up("sm")]: {
                    width: "100%",
                    height: "100%",
                    position: "relative",
                },
                [theme.breakpoints.down("sm")]: {
                    height: NAVBAR_MOBILE_HEIGHT,
                    flexFlow: "row",
                    justifyContent: "space-between",
                },
            })}
        >
            <Link
                sx={(theme) => ({
                    objectFit: "contain",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    [theme.breakpoints.up("sm")]: {
                        margin: theme.spacing(2, 1),
                    },
                    [theme.breakpoints.down("sm")]: {
                        maxWidth: "184px",
                        marginTop: "auto",
                        marginBottom: "auto",
                        marginLeft: theme.spacing(1),
                    },
                })}
                href="/"
            >
                <img src={logo} width="100%" alt="Omnivore - Merchant" />
            </Link>
            <Divider
                sx={(theme) => ({
                    margin: theme.spacing(0, 0.5),
                    borderColor: "#6f6f6f",
                    [theme.breakpoints.down("sm")]: { display: "none" },
                })}
            />
            <List sx={(theme) => ({ [theme.breakpoints.down("sm")]: { display: "none" }, width: "100%" })}>
                {pages.map((page) => (
                    <NavButton
                        key={page.title}
                        to={page.to}
                        title={page.title}
                        onClick={page.onClick}
                        component={page.component}
                    />
                ))}
            </List>

            {/* Logout button (Mobile only) */}
            <Box
                sx={(theme) => ({
                    [theme.breakpoints.up("sm")]: {
                        marginTop: "auto",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: theme.spacing(1, 2),
                    },
                    [theme.breakpoints.down("sm")]: {
                        width: "100px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    },
                })}
            >
                <Tooltip title="Log out" arrow>
                    <IconButton
                        sx={(theme) => ({
                            [theme.breakpoints.down("sm")]: { display: "none" },
                            marginLeft: "0px",
                            ...(theme.palette.mode === "light"
                                ? {
                                      "&:hover": {
                                          backgroundColor: (theme) =>
                                              theme.palette.augmentColor({ color: { main: theme.mixins.mainNavColor } })
                                                  .light,
                                      },
                                  }
                                : {}),
                        })}
                        component={RouterLink}
                        to={"/logout"}
                        aria-label="Logout"
                        color="inherit"
                    >
                        <LogoutIcon />
                    </IconButton>
                </Tooltip>

                {/* Theme Switcher */}
                <Tooltip title="Settings" arrow>
                    <IconButton
                        aria-label="Settings"
                        sx={(theme) => ({
                            color: "white",
                            ...(theme.palette.mode === "light"
                                ? {
                                      "&:hover": {
                                          backgroundColor: (theme) =>
                                              theme.palette.augmentColor({ color: { main: theme.mixins.mainNavColor } })
                                                  .light,
                                      },
                                  }
                                : {}),
                        })}
                        onClick={handleThemeClick}
                    >
                        <SettingsIcon />
                    </IconButton>
                </Tooltip>
                <Popover
                    sx={(theme) => ({ marginTop: theme.spacing(2) })}
                    onClose={handleThemeClose}
                    anchorEl={anchorEl}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    transformOrigin={{ vertical: "center", horizontal: "left" }}
                    open={Boolean(anchorEl)}
                    disableScrollLock
                >
                    {themeSwitch}
                </Popover>

                <Tooltip title="What's New?" arrow>
                    <IconButton
                        aria-label="What's New?"
                        sx={(theme) => ({
                            color: "white",
                            [theme.breakpoints.down("sm")]: { display: "none" },
                            ...(theme.palette.mode === "light"
                                ? {
                                      "&:hover": {
                                          backgroundColor: (theme) =>
                                              theme.palette.augmentColor({ color: { main: theme.mixins.mainNavColor } })
                                                  .light,
                                      },
                                  }
                                : {}),
                        })}
                        onClick={handleReleasesClick}
                    >
                        <CampaignIcon />
                    </IconButton>
                </Tooltip>
                <Box sx={(theme) => ({ [theme.breakpoints.up("sm")]: { display: "none" } })}>
                    <IconButton onClick={handleMenuClick} size="large" edge="start" color="inherit">
                        <MenuIcon />
                    </IconButton>
                    <Menu
                        anchorEl={menuAnchorEl}
                        open={Boolean(menuAnchorEl)}
                        onClose={handleMenuClose}
                        sx={(theme) => ({ [theme.breakpoints.up("sm")]: { display: "none" } })}
                    >
                        {pages.map((page) => (
                            <NavButton
                                key={page.title}
                                to={page.to}
                                title={page.title}
                                component={page.component}
                                NavButtonType="item"
                                onClick={page.onClick}
                            />
                        ))}
                    </Menu>
                </Box>
            </Box>
        </AppBar>
    );

    //TODO: Drawer should be used in mobile mode in future PR.
    return !isSmallScreen ? (
        <Drawer
            sx={{
                width: navBarWidth,
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                    width: navBarWidth,
                    boxSizing: "border-box",
                },
            }}
            anchor="left"
            variant="permanent"
            open={true}
        >
            {content}
        </Drawer>
    ) : (
        content
    );
}
