import { Alert, Typography, Theme } from "@mui/material";
import { makeStyles } from "theme";
import ConfirmDialog from "components/common/ConfirmDialog";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { axiosErrorMessage } from "store/types";
import { Period as MasterPeriod } from "store/mms/menus/master/periods/types";
import { entityDelete } from "store/mms/menus/actions";

const masterMenuDeleteStyles = makeStyles()((theme: Theme) => ({
    alert: {
        marginTop: theme.spacing(4),
    },
}));

interface MasterMenuPeriodDeleteProps {
    period: MasterPeriod;
    onClose(): void;
    open: boolean;
}

export function MasterMenuPeriodDelete({ period, onClose, open }: MasterMenuPeriodDeleteProps): JSX.Element {
    const { classes } = masterMenuDeleteStyles();
    const dispatch = useDispatch();
    const [state, setState] = useState<{ saving: boolean; error?: string }>({
        saving: false,
    });
    const name = "Menu Period";

    return (
        <ConfirmDialog
            title={`Remove ${name}`}
            open={open}
            onClose={onClose}
            onConfirm={() => {
                setState({ saving: true });
                dispatch(
                    entityDelete(period, name, (error?: Error): void => {
                        if (error) {
                            return setState({ saving: false, error: axiosErrorMessage(error) });
                        }
                        setState({ saving: false });
                        onClose();
                    }),
                );
            }}
            confirm={`Remove ${name}`}
            confirming={state.saving}
            maxWidth="xl"
            transitionDuration={0}
        >
            <Typography>
                Are you sure you want to delete the <strong>{period.display_name}</strong> Menu Period?
            </Typography>
            <Alert severity="info">
                Categories only subscribed to this Period will no longer appear on your Menu until a new Period is
                created.
            </Alert>

            {state.error && (
                <Alert className={classes.alert} variant="outlined" severity="error">
                    {state.error}
                </Alert>
            )}
        </ConfirmDialog>
    );
}
