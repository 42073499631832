import { combineReducers } from "redux";
import menus from "./menus/reducers";
import orderProfiles from "./order-profiles/reducers";
import linkTimes from "./link-times/reducers";
import tags from "./tags/reducers";
import applicationActions from "./application-actions/reducers";

const mmsReducer = combineReducers({
    orderProfiles,
    menus,
    linkTimes,
    tags,
    applicationActions,
});

export default mmsReducer;
