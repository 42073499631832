import React, { useState } from "react";
import { Alert, Typography } from "@mui/material";
import ConfirmDialog from "components/common/ConfirmDialog";
import { LocationMenu, OnboardingStatus } from "store/mms/menus/location/types";
import { useDispatch } from "react-redux";
import { ActionButton } from "components/common/buttons/ActionButton";
import { locationMenuSave } from "store/mms/menus/location/actions";
import { axiosErrorMessage } from "store/types";

interface LocationMenuEnableOrderingProps {
    menu: LocationMenu;
}

export function LocationMenuEnableOrdering({ menu }: LocationMenuEnableOrderingProps): JSX.Element {
    const [state, setState] = useState<{ confirm: boolean; saving: boolean; savingError?: string }>({
        confirm: false,
        saving: false,
    });
    const dispatch = useDispatch();
    const handleEnableOrdering = (): void => {
        setState((prevState) => ({ ...prevState, confirm: true }));
    };

    return (
        <>
            <Alert
                action={<ActionButton label="Enable Ordering" onClick={handleEnableOrdering} />}
                sx={{ marginBottom: (theme) => theme.spacing(2) }}
                variant="outlined"
                severity="info"
            >
                Ordering has not been enabled for this Menu.
            </Alert>

            <ConfirmDialog
                title="Enable Ordering"
                open={state.confirm}
                onClose={() => setState({ confirm: false, saving: false, savingError: undefined })}
                onConfirm={() => {
                    setState((prevState) => ({ ...prevState, saving: true }));
                    dispatch(
                        locationMenuSave(
                            { ...menu, enabled: true, onboarding_status: OnboardingStatus.Complete },
                            menu._links.self.href,
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            (error?: any) => {
                                if (!error) {
                                    return setState((prevState) => ({
                                        ...prevState,
                                        confirm: false,
                                        saving: false,
                                    }));
                                }
                                let savingError: string;

                                if (
                                    error.response &&
                                    error.response.data &&
                                    error.response.data.errors &&
                                    error.response.data.errors.length === 1 &&
                                    error.response.data.errors[0].metadata
                                ) {
                                    const missingLayers: string[] = [];

                                    for (const missingLayer of Object.values(error.response.data.errors[0].metadata)) {
                                        missingLayers.push(missingLayer as string);
                                    }

                                    savingError = `Failed to enable ordering. Location needs to be added to the following layers: ${missingLayers.join(
                                        ", ",
                                    )}`;
                                } else {
                                    savingError = axiosErrorMessage(error);
                                }

                                setState((prevState) => ({
                                    ...prevState,
                                    saving: false,
                                    savingError,
                                }));
                            },
                        ),
                    );
                }}
                confirm="Enable Ordering"
                confirming={state.saving}
                disableConfirm={state.savingError !== undefined && state.savingError !== ""}
                transitionDuration={0}
            >
                <Typography>Are you sure you want to enable Ordering on this Menu for this store?</Typography>
                {state.savingError !== undefined && state.savingError !== "" && (
                    <Alert sx={{ marginTop: (theme) => theme.spacing(2) }} severity="error" variant="outlined">
                        {state.savingError}
                    </Alert>
                )}
            </ConfirmDialog>
        </>
    );
}
