import { Alert, Typography } from "@mui/material";
import ConfirmDialog from "components/common/ConfirmDialog";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { axiosErrorMessage } from "store/types";
import { ruleSave } from "store/mms/menus/master/layers/rule-sets/rules/actions";
import { MenuLayerRule } from "store/mms/menus/master/layers/rule-sets/rules/types";

interface RuleVisibilityProps {
    rule: MenuLayerRule;
    onClose(): void;
}

export function RuleVisibility({ rule, onClose }: RuleVisibilityProps): JSX.Element {
    const dispatch = useDispatch();
    const [state, setState] = useState<{ saving: boolean; error?: string }>({
        saving: false,
    });
    const enabledness = rule.enabled ? "Disable" : "Enable";
    const name = `${enabledness} Rule`;

    return (
        <ConfirmDialog
            title={name}
            open={true}
            onClose={onClose}
            onConfirm={() => {
                setState({ saving: true });
                dispatch(
                    ruleSave(rule._links.self.href, { ...rule, enabled: !rule.enabled }, (error?: Error): void => {
                        if (error) {
                            return setState({ saving: false, error: axiosErrorMessage(error) });
                        }
                        setState({ saving: false });
                        onClose();
                    }),
                );
            }}
            confirm={name}
            confirming={state.saving}
            maxWidth="xl"
            transitionDuration={0}
        >
            <Typography>
                Are you sure you want to <strong>{enabledness.toLowerCase()}</strong> the Rule{" "}
                <strong>{rule.name}</strong>?
            </Typography>

            <Alert variant="outlined" severity="info">
                This Rule will {rule.enabled && "no longer "}be applied to specified Locations.
            </Alert>

            {state.error && (
                <Alert variant="outlined" severity="error">
                    {state.error}
                </Alert>
            )}
        </ConfirmDialog>
    );
}
