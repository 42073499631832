import { Card, CardActions, CardContent, Divider, Grid, Typography, GridProps, SxProps, Theme } from "@mui/material";
import React from "react";
import { useForm, useFormState } from "react-final-form";
import { useSelector } from "react-redux";
import { MasterItem } from "store/mms/menus/master/items/types";
import { RootState } from "store/rootReducer";
import { MasterModifier } from "store/mms/menus/master/items/modifiers/types";
import { selectedItems, selectedModifiers } from "./constants";
import { EntityRemove } from "components/mms/IconButtons/EntityRemove";

interface EntitiesSelectedProps extends Partial<GridProps> {
    disableCardActions?: boolean;
}

export function EntitiesSelected({ disableCardActions = false, ...restGrid }: EntitiesSelectedProps): JSX.Element {
    const { values } = useFormState();
    const itemsById = useSelector((state: RootState) => state.mms.menus.master.items.byId);
    const modifiersByHref = useSelector((state: RootState) => state.mms.menus.master.modifiers.byHref);
    const modifierHrefsById = useSelector((state: RootState) => state.mms.menus.master.modifiers.hrefsById);
    const items = (values && values[selectedItems]) || [];
    const modifiers = (values && values[selectedModifiers]) || [];

    return values.ruleType ? (
        <Grid container direction="column" spacing={2} {...restGrid}>
            <Grid item>
                <Typography sx={{ marginBottom: (theme) => theme.spacing(1) }} variant="h6">
                    Selected
                </Typography>
                <Divider />
            </Grid>

            {items.length ? (
                <Grid item>
                    <Typography variant="subtitle1">Items</Typography>
                    <Divider />
                </Grid>
            ) : null}
            {items
                .filter((i: string) => itemsById[i])
                .map((i: string) => (
                    <Grid item key={i}>
                        <SelectedEntity
                            disableCardActions={disableCardActions}
                            name={selectedItems}
                            entity={itemsById[i]}
                        />
                    </Grid>
                ))}

            {modifiers.length ? (
                <Grid item>
                    <Typography variant="subtitle1">Modifiers</Typography>
                    <Divider />
                </Grid>
            ) : null}
            {modifiers
                .filter((i: string) => {
                    return modifierHrefsById[i] ? modifiersByHref[modifierHrefsById[i][0]] : [];
                })
                .map(
                    (i: string) =>
                        (modifierHrefsById[i] && (
                            <Grid item key={i}>
                                <SelectedEntity
                                    disableCardActions={disableCardActions}
                                    name={selectedModifiers}
                                    entity={modifiersByHref[modifierHrefsById[i][0]]}
                                />
                            </Grid>
                        )) ||
                        null,
                )}
        </Grid>
    ) : (
        <></>
    );
}

interface SelectedEntityProps {
    disableCardActions: boolean;
    name: string;
    entity: MasterItem | MasterModifier;
}

function SelectedEntity({ disableCardActions, name, entity }: SelectedEntityProps): JSX.Element {
    const { change } = useForm();
    const { values } = useFormState();
    const handleDelete = () => {
        const selectedItems = ((values && values[name]) || []).filter((id: string) => id !== entity.id);
        change(name, selectedItems);
    };
    const textStyle: SxProps<Theme> = {
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: 1,
        overflow: "hidden",
        wordBreak: "break-word",
    };

    return (
        <Card
            sx={{
                display: "flex",
                alignContent: "center",
                justifyContent: "space-between",

                "& .MuiCardContent-root": {
                    display: "flex",
                    flexDirection: "column",
                },
            }}
            variant="outlined"
        >
            <CardContent>
                <Typography sx={textStyle} title={entity.display_name}>
                    {entity.display_name}
                </Typography>
                <Typography
                    sx={textStyle}
                    variant="caption"
                    color="textSecondary"
                    title={`Reference: ${entity.reference_name}`}
                >{`Reference: ${entity.reference_name}`}</Typography>
            </CardContent>

            <CardActions>{!disableCardActions && <EntityRemove onClick={handleDelete} />}</CardActions>
        </Card>
    );
}
