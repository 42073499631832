import { Skeleton } from "@mui/material";
import { MasterMenuContext, MenuEvent } from "pages/Menus/MasterMenu/context";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { layersPageFetch } from "store/mms/menus/master/layers/actions";
import { RootState } from "store/rootReducer";
import { LayerListItemProps, MenuLayerListItem, Position } from "./MenuLayerListItem";
import { CSS } from "@dnd-kit/utilities";
import { arrayMove, rectSortingStrategy, SortableContext, useSortable } from "@dnd-kit/sortable";
import {
    closestCenter,
    DndContext,
    DragEndEvent,
    DragOverlay,
    DragStartEvent,
    MouseSensor,
    TouchSensor,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import { MenuLayer } from "store/mms/menus/master/layers/types";
import { ZeroState } from "../ZeroState";
import { List } from "components/common/List";

export function MenuLayersList(): JSX.Element {
    const { menu, onChange } = useContext(MasterMenuContext);
    const dispatch = useDispatch();
    const layersById = useSelector((state: RootState) => state.mms.menus.master.layers.byId);
    const layersFetching = useSelector((state: RootState) => state.mms.menus.master.layers.fetching);
    const layerIdsByMenuId = useSelector((state: RootState) => state.mms.menus.master.layers.layerIdsByMenuId);
    const menuLayers = layerIdsByMenuId[menu?.id || ""];
    const isLoading = menu === undefined || menuLayers === undefined || layersFetching;
    const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
    const [activeId, setActiveId] = useState<string | null>(null);
    const filterMenuLayers = useMemo(
        () =>
            (layerIds: string[]): MenuLayer[] => {
                return layerIds.map((id) => layersById[id]).sort((a, b) => a.sequence - b.sequence);
            },
        [layersById],
    );
    const [layers, setLayers] = useState(filterMenuLayers((menu && menuLayers) || []));
    const activeIndex = activeId ? layers.findIndex((l) => l.id === activeId) : -1;
    const handleDragEnd = ({ active, over }: DragEndEvent): void => {
        if (over !== null && active.id !== over.id) {
            const oldIndex = layers.findIndex((i) => i.id === active.id);
            const newIndex = layers.findIndex((i) => i.id === over.id);
            const newLayersSequence = arrayMove(layers, oldIndex, newIndex).map((l, i) => ({ ...l, sequence: i }));

            setLayers(newLayersSequence);
            onChange({ type: MenuEvent.LayerSequence, layers: newLayersSequence });
        }

        setActiveId(null);
    };
    const handleDragStart = (event: DragStartEvent): void => setActiveId(event.active.id);
    const handleDragCancel = (): void => setActiveId(null);
    const noLayers = !isLoading && menuLayers.length === 0;

    useEffect(() => {
        if (menu === undefined || menuLayers !== undefined) {
            return;
        }
        dispatch(layersPageFetch(menu));
    }, [dispatch, menuLayers, menu]);

    useEffect(() => {
        if (menu === undefined || menuLayers === undefined) {
            return;
        }

        setLayers(filterMenuLayers(menuLayers));
    }, [filterMenuLayers, menu, menuLayers]);

    return isLoading ? (
        <Skeleton />
    ) : noLayers ? (
        <ZeroState
            createLabel="Create Menu Layer"
            title="Create a Menu Layer to get started."
            onClick={() => onChange({ type: MenuEvent.LayerAdd })}
        />
    ) : (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onDragCancel={handleDragCancel}
        >
            <SortableContext items={layers} strategy={rectSortingStrategy}>
                <List>
                    {layers.map((l, index) => (
                        <SortableMenuLayerListItem activeIndex={activeIndex} layer={l} key={l.id} index={index + 1} />
                    ))}
                </List>
            </SortableContext>

            <DragOverlay>
                {activeId ? (
                    <MenuLayerListItem
                        layer={layers[layers.findIndex((i) => i.id === activeId)]}
                        index={layers.findIndex((i) => i.id === activeId)}
                        dragOverlay
                    />
                ) : null}
            </DragOverlay>
        </DndContext>
    );
}

const SortableMenuLayerListItem = (props: LayerListItemProps & { activeIndex: number }): JSX.Element => {
    const { activeIndex, layer, ...rest } = props;
    const { attributes, index, isDragging, isSorting, listeners, over, setNodeRef, transform, transition } =
        useSortable({
            id: layer.id,
        });
    const style = {
        transform: isSorting ? undefined : CSS.Transform.toString(transform),
        transition: transition ? transition : undefined,
    };

    return (
        <MenuLayerListItem
            layer={layer}
            dragActive={isDragging}
            ref={setNodeRef}
            style={style}
            insertPosition={
                over?.id === layer.id ? (index > activeIndex ? Position.After : Position.Before) : undefined
            }
            listeners={listeners}
            {...rest}
            {...attributes}
        />
    );
};
