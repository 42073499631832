import {
    MasterMenu,
    MenuType,
    MasterMenuActions,
    MENU_FETCH,
    MENU_FETCH_ERROR,
    MENU_FETCH_SUCCESS,
    MENU_PAGE_FETCH,
    MENU_PAGE_FETCH_ERROR,
    MENU_PAGE_FETCH_SUCCESS,
    MENU_ADD,
    MENU_ADD_SUCCESS,
    MENU_EDIT,
    MENU_EDIT_SUCCESS,
    MasterMenuUpdate,
} from "./types";
import { getSession } from "services/session";

export function menuFetch(url: string): MasterMenuActions {
    return {
        type: MENU_FETCH,
        url,
    };
}

export function menuFetchSuccess(menu: MasterMenu): MasterMenuActions {
    return {
        type: MENU_FETCH_SUCCESS,
        menu,
    };
}

export function menuFetchError(error: Error): MasterMenuActions {
    return {
        type: MENU_FETCH_ERROR,
        error,
    };
}

export function menuPageFetch(): MasterMenuActions {
    return {
        type: MENU_PAGE_FETCH,
        url: `/1.1/accounts/${getSession()?.accountId}/mms/menus`,
    };
}

export function menuPageFetchSuccess(menus: MasterMenu[]): MasterMenuActions {
    return {
        type: MENU_PAGE_FETCH_SUCCESS,
        menus,
    };
}

export function menuPageFetchError(error: Error): MasterMenuActions {
    return {
        type: MENU_PAGE_FETCH_ERROR,
        error,
    };
}

export function menuAdd(
    name: string,
    source_location: string,
    type: MenuType,
    restaurant_concept: string,
    callback?: (error?: Error) => void,
): MasterMenuActions {
    return {
        type: MENU_ADD,
        url: `/1.1/accounts/${getSession()?.accountId}/mms/menus`,
        menu: { name, source_location, type, restaurant_concept },
        callback,
    };
}

export function menuAddSuccess(menu: MasterMenu): MasterMenuActions {
    return {
        type: MENU_ADD_SUCCESS,
        menu,
    };
}

export function menuEdit(url: string, menu: MasterMenuUpdate, callback?: (error?: Error) => void): MasterMenuActions {
    return {
        type: MENU_EDIT,
        url,
        menu,
        callback,
    };
}

export function menuEditSuccess(changedMenu: MasterMenu): MasterMenuActions {
    return {
        type: MENU_EDIT_SUCCESS,
        changedMenu,
    };
}
