import { DataGrid, GridOverlay, GridSortDirection } from "@mui/x-data-grid";
import { Grid, LinearProgress, Theme, Typography } from "@mui/material";
import { makeStyles } from "theme";
import { ListPerPageOptions } from "components/props";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { RuleSetContext, RuleSetEvent } from "../../../../pages/Menus/Layers/RuleSets/context";
import { QuickSearchToolbar } from "components/common/forms/QuickSearchToolbar";
import { search } from "components/common/forms/helpers";
import { debounce } from "lodash";
import { searchDebounce } from "services/env";
import { ActionButton } from "components/common/buttons/ActionButton";

const useStyles = makeStyles()((theme: Theme) => ({
    button: {
        right: theme.spacing(1),
    },
    container: {
        margin: "auto",
    },

    header: {
        marginBottom: theme.spacing(3.5),
    },
    description: {
        marginBottom: theme.spacing(2),
    },
    tempTable: {
        width: "100%",
        margin: "auto",
    },
    tempHeaderRow: {
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
    },
    tempHeader: { width: "50%", backgroundColor: "grey" },
}));
export default function RuleSetLocationsList(): JSX.Element {
    const { classes } = useStyles();

    const { ruleSet, onChange } = useContext(RuleSetContext);

    const locations = useMemo(() => ruleSet?._embedded.locations || [], [ruleSet]);

    const [searchText, setSearchText] = useState("");
    const [rows, setRows] = useState(locations);
    useEffect(() => {
        setRows(locations);
    }, [locations]);
    const debouncedSearch = useMemo(
        () =>
            debounce((query: string) => {
                search(query, locations, setRows);
            }, searchDebounce),
        [locations],
    );

    return (
        <>
            <DataGrid
                headerHeight={48}
                rowHeight={32}
                disableSelectionOnClick
                loading={false}
                rows={
                    rows.map((l) => ({
                        name: l.name,
                        id: l.id,
                    })) || []
                }
                sortModel={[
                    {
                        field: "name",
                        sort: "asc" as GridSortDirection,
                    },
                ]}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                components={{
                    Toolbar: QuickSearchToolbar,
                    LoadingOverlay: function CustomLoadingOverlay() {
                        return (
                            <GridOverlay>
                                <div style={{ position: "absolute", top: 0, width: "100%" }}>
                                    <LinearProgress />
                                </div>
                            </GridOverlay>
                        );
                    },
                }}
                componentsProps={{
                    toolbar: {
                        value: searchText,
                        onChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                            const query = e.target.value;
                            setSearchText(query);
                            debouncedSearch(query);
                        },
                        clearSearch: () => {
                            setSearchText("");
                            search("", locations, setRows);
                        },
                        title: (
                            <Grid container direction="column">
                                <Grid item>
                                    <Grid container justifyContent="space-between">
                                        <Grid item>
                                            <Typography className={classes.header} variant="h6">
                                                <strong>Locations</strong> ({(locations || []).length})
                                            </Typography>
                                        </Grid>

                                        <Grid item>
                                            <ActionButton
                                                onClick={() => onChange({ type: RuleSetEvent.LocationsMove })}
                                                className={classes.button}
                                                type="submit"
                                                label="Move Locations"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ),
                    },
                }}
                columns={[
                    {
                        field: "name",
                        headerName: "Name",
                        flex: 3,
                        disableColumnMenu: true,
                    },
                    {
                        field: "id",
                        headerName: "ID",
                        flex: 2,
                        disableColumnMenu: true,
                    },
                ]}
                rowsPerPageOptions={ListPerPageOptions}
                sx={{
                    minHeight: "inherit",
                    borderLeft: "unset",
                    borderRight: "unset",
                    borderTop: "unset",
                }}
                autoHeight
            />
        </>
    );
}
