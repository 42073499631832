import { Nutrition } from "store/mms/types";
import { HALLink } from "store/types";

export const ENTITY_WITH_NUTRITION_UPDATE = "@@NUTRITION/UPDATE";
export const ENTITY_WITH_NUTRITION_UPDATE_SUCCESS = "@@NUTRITION/UPDATE_SUCCESS";

export const ENTITY_WITH_NUTRITION_DELETE = "@@NUTRITION/DELETE";
export const ENTITY_WITH_NUTRITION_DELETE_SUCCESS = "@@NUTRITION/DELETE_SUCCESS";

// EntityWithNutrition is a generic menu entity (e.g. item or modifier) that has nutrition facts.
export interface EntityWithNutrition {
    readonly _embedded: {
        readonly nutrition: Nutrition | null;
    };

    readonly _links: {
        readonly nutrition: HALLink;
        readonly self: HALLink;
    };
}

export interface EntityWithNutritionUpdate {
    type: typeof ENTITY_WITH_NUTRITION_UPDATE;
    entity: EntityWithNutrition;
    nutrition: Nutrition;
    onComplete?: (error?: Error) => void;
}
export interface EntityWithNutritionUpdateSuccess {
    type: typeof ENTITY_WITH_NUTRITION_UPDATE_SUCCESS;
    entity: EntityWithNutrition;
    nutrition: Nutrition;
}

export interface EntityWithNutritionDelete {
    type: typeof ENTITY_WITH_NUTRITION_DELETE;
    entity: EntityWithNutrition;
    onComplete?: (error?: Error) => void;
}
export interface EntityWithNutritionDeleteSuccess {
    type: typeof ENTITY_WITH_NUTRITION_DELETE_SUCCESS;
    entity: EntityWithNutrition;
}

export type NutritionActions =
    | EntityWithNutritionUpdate
    | EntityWithNutritionUpdateSuccess
    | EntityWithNutritionDelete
    | EntityWithNutritionDeleteSuccess;
