import {
    useTheme,
    Grid,
    IconButton,
    LinearProgress,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
    Typography,
    Box,
    Button,
} from "@mui/material";
import {
    Circle as CircleIcon,
    DeviceHub as DeviceHubIcon,
    Sync as SyncIcon,
    QuestionMark as QuestionMarkIcon,
} from "@mui/icons-material";
import {
    DataGrid,
    GridCellParams,
    GridFilterModel,
    GridOverlay,
    GridRowModel,
    GridValueGetterParams,
} from "@mui/x-data-grid";
import debounce from "lodash/debounce";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { searchDebounce } from "services/env";
import { getSession } from "services/session";
import { locationPageFetch, locationSearchReset } from "store/locations/actions";
import { Location, POSTypeTitle, Status, StatusTitle } from "store/locations/types";
import { jobCreate } from "store/mms/jobs/actions";
import { JobType } from "store/mms/jobs/types";
import { menuPageFetch } from "store/mms/menus/master/actions";
import { RootState } from "store/rootReducer";
import { QuickSearchToolbar } from "components/common/forms/QuickSearchToolbar";
import DefaultLayout from "components/common/layouts/DefaultLayout";
import { LocationMenuLink } from "components/mms/Menus/LocationMenuLink";
import { locationMenuPageFetch } from "store/mms/menus/location/actions";
import { POSSyncTimes } from "components/mms/POSSyncTimes";

const s = getSession();
const accountUrl = `/1.1/accounts/${s?.accountId}`;
const locationUrl = `${accountUrl}/locations`;
const jobsUrl = `${accountUrl}/mms/jobs`;

export default function LocationsList(): JSX.Element {
    const locationsById = useSelector((state: RootState) => state.locations.byId);
    const pagination = useSelector((state: RootState) => state.locations.pagination);
    const masterMenusFetching = useSelector((state: RootState) => state.mms.menus.master.menus.fetching);
    const menusById = useSelector((state: RootState) => state.mms.menus.master.menus.byId);
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(pagination.page);
    const [linkLocation, setLinkLocation] = useState<Location | undefined>(undefined);
    const page = pagination.pages[currentPage];
    const isLoading = masterMenusFetching || (page !== undefined ? page.fetching : false);

    const [searchText, setSearchText] = useState("");
    const search = useMemo(
        () =>
            debounce((query: string | undefined) => {
                dispatch(locationSearchReset());
                dispatch(locationPageFetch(locationUrl, 0, pagination.pageSize, query));
                setCurrentPage(0);
            }, searchDebounce),
        [dispatch, pagination.pageSize],
    );
    const [filterModel, setFilterModel] = useState<GridFilterModel>({
        items: [{ columnField: "production", operatorValue: "is", value: undefined }],
    });
    const [toggleFilter, setToggleFilter] = useState<"true" | "false" | "all">("all");

    useEffect(() => {
        if (page === undefined) {
            dispatch(locationPageFetch(locationUrl, 0, pagination.pageSize));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch(menuPageFetch());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Cleanup Locations when navigating away
    useEffect(() => {
        return () => {
            dispatch(locationSearchReset());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        search(searchText);
    }, [search, searchText]);

    const pageRows: Location[] = page !== undefined ? page.ids.map((id) => locationsById[id]) : [];
    const handlePageSizeChange = (pageSize: number) => {
        if (pageSize !== pagination.pageSize) {
            dispatch(locationSearchReset());
            dispatch(locationPageFetch(locationUrl, currentPage, pageSize));
        }
    };
    const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);

        if (pagination.pages[newPage] === undefined) {
            dispatch(locationPageFetch(locationUrl, newPage, pagination.pageSize));
        }
    };
    const [editPOSSyncTimes, setEditPOSSyncTimes] = useState(false);

    return (
        <DefaultLayout>
            <DataGrid
                rows={pageRows}
                filterModel={filterModel}
                columns={[
                    {
                        field: "name",
                        headerName: "Location",
                        width: 560,
                        flex: 3,
                        resizable: false,
                        sortable: false,
                        disableColumnMenu: true,
                        renderCell: function renderLocation(params: GridCellParams) {
                            return <Link to={`/locations/${params.row.id}`}>{params.row.name}</Link>;
                        },
                    },
                    {
                        field: "id",
                        headerName: "ID",
                        width: 190,
                        flex: 1,
                        resizable: false,
                        sortable: false,
                        disableColumnMenu: true,
                    },
                    {
                        field: "pos_type",
                        headerName: "POS Type",
                        flex: 0.5,
                        resizable: false,
                        sortable: false,
                        disableColumnMenu: true,
                        valueGetter: function posTypeTitle(params: GridValueGetterParams) {
                            return POSTypeTitle(params.row.pos_type);
                        },
                    },
                    {
                        field: "status",
                        headerName: "Status",
                        flex: 0.5,
                        resizable: false,
                        sortable: false,
                        disableColumnMenu: true,
                        headerAlign: "center",
                        align: "center",
                        renderCell: function renderStatus(params: GridCellParams) {
                            return <StatusIndicator status={params.row.status} />;
                        },
                    },
                    {
                        field: "production",
                        headerName: "Production",
                        type: "boolean",
                        flex: 0.5,
                        resizable: false,
                        sortable: false,
                        disableColumnMenu: true,
                        hide: true,
                        valueGetter: function productionGetter(params: GridValueGetterParams) {
                            return !params.row.development;
                        },
                    },
                    {
                        field: "actions",
                        headerClassName: "hidden",
                        resizable: false,
                        sortable: false,
                        disableColumnMenu: true,
                        width: 190,
                        flex: 1,
                        renderCell: function renderActions(params: GridCellParams) {
                            return (
                                <LocationActions
                                    row={params.row}
                                    onClick={() => setLinkLocation(locationsById[params.row.id])}
                                />
                            );
                        },
                        align: "right",
                        cellClassName: "actions-cell",
                        filterable: false,
                    },
                ]}
                pagination
                page={currentPage}
                pageSize={pagination.pageSize}
                rowCount={pagination.total}
                paginationMode="server"
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                loading={isLoading}
                autoHeight
                disableSelectionOnClick
                sortModel={[
                    {
                        field: "name",
                        sort: "asc",
                    },
                ]}
                components={{
                    Toolbar: QuickSearchToolbar,
                    LoadingOverlay: function CustomLoadingOverlay() {
                        return (
                            <GridOverlay>
                                <div style={{ position: "absolute", top: 0, width: "100%" }}>
                                    <LinearProgress />
                                </div>
                            </GridOverlay>
                        );
                    },
                }}
                componentsProps={{
                    toolbar: {
                        value: searchText,
                        onChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                            setSearchText(e.target.value);
                        },
                        clearSearch: () => {
                            setSearchText("");
                        },
                        title: (
                            <Grid
                                container
                                justifyContent="space-between"
                                sx={{ marginRight: (theme) => theme.spacing(2) }}
                            >
                                <Grid item flexGrow="1">
                                    <Grid container alignItems="center">
                                        <Grid item>
                                            <Typography variant="h4">Locations</Typography>
                                        </Grid>
                                        <Grid item sx={{ marginLeft: (theme) => theme.spacing(2) }}>
                                            <Button variant="contained" onClick={() => setEditPOSSyncTimes(true)}>
                                                POS Sync Times
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <ToggleButtonGroup
                                        size="small"
                                        exclusive
                                        value={toggleFilter}
                                        onChange={(e, value) => {
                                            setToggleFilter(value);
                                            setFilterModel({
                                                items: [
                                                    {
                                                        columnField: "production",
                                                        operatorValue: "is",
                                                        value: value === "all" ? undefined : value,
                                                    },
                                                ],
                                            });
                                        }}
                                        color="primary"
                                        sx={{
                                            "& > .MuiToggleButton-root": {
                                                paddingBottom: (theme) => theme.spacing(0.75),
                                            },
                                        }}
                                    >
                                        <ToggleButton value="all">All</ToggleButton>
                                        <ToggleButton value="true">Prod</ToggleButton>
                                        <ToggleButton value="false">Dev</ToggleButton>
                                    </ToggleButtonGroup>
                                </Grid>
                            </Grid>
                        ),
                    },
                }}
                sx={{
                    minHeight: "inherit",
                    borderLeft: "unset",
                    borderRight: "unset",
                    borderTop: "unset",
                    "& .actions-cell": {
                        "& > div": {
                            cursor: "pointer",
                        },
                    },
                    "& .hidden": {
                        visibility: "hidden",
                    },
                    "& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within": {
                        outline: "none",
                    },
                }}
            />

            {editPOSSyncTimes && <POSSyncTimes onClose={() => setEditPOSSyncTimes(false)} />}

            {!isLoading && (
                <LocationMenuLink
                    open={linkLocation !== undefined}
                    onClose={() => setLinkLocation(undefined)}
                    onConfirm={() =>
                        linkLocation && dispatch(locationMenuPageFetch(linkLocation._links.self.href + "mms/menus"))
                    }
                    location={linkLocation}
                    menusById={menusById}
                />
            )}
        </DefaultLayout>
    );
}

interface StatusIndicatorProps {
    status: Status;
}

function StatusIndicator(props: StatusIndicatorProps): JSX.Element {
    const theme = useTheme();
    let icon = <QuestionMarkIcon fontSize="inherit" />;
    const tooltipTitle = StatusTitle(props.status);
    switch (props.status) {
        case Status.ONLINE:
        case Status.DEGRADED:
            icon = <CircleIcon fontSize="inherit" style={{ color: theme.palette.success.main }} />;
            break;
        // For now, we are simplifying status interpretation to "if you are not ONLINE, you are OFFLINE". We can easily
        // distinguish other statuses if the need arises.
        case Status.ACTIVE:
        case Status.ACTIVATING:
        case Status.ACTIVATING_ERROR:
        case Status.INITIALIZING:
        case Status.INITIALIZING_ERROR:
        case Status.OFFBOARDED:
        case Status.OFFLINE:
        case Status.PENDING:
        case Status.REPLACED:
            icon = <CircleIcon fontSize="inherit" style={{ color: theme.palette.error.main }} />;
            break;
    }

    return <Tooltip title={tooltipTitle}>{icon}</Tooltip>;
}

interface LocationActionProps {
    row: GridRowModel;
    onClick: () => void;
}

function LocationActions(props: LocationActionProps): JSX.Element {
    const dispatch = useDispatch();

    if (props.row.status !== Status.ONLINE && props.row.status !== Status.DEGRADED) {
        return (
            <>
                <Tooltip arrow title={"Actions are unavailable because Location is offline."}>
                    <Box>
                        <IconButton size="small" disabled>
                            <DeviceHubIcon fontSize="inherit" />
                        </IconButton>
                        <IconButton size="small" disabled>
                            <SyncIcon fontSize="inherit" />
                        </IconButton>
                    </Box>
                </Tooltip>
            </>
        );
    }

    return (
        <>
            <Tooltip arrow title={"Re-Sync Menus with POS"}>
                <IconButton
                    size="small"
                    onClick={() =>
                        dispatch(
                            jobCreate(
                                {
                                    location: props.row.id,
                                    type: JobType.Link,
                                },
                                jobsUrl,
                            ),
                        )
                    }
                >
                    <SyncIcon fontSize="inherit" />
                </IconButton>
            </Tooltip>
            <Tooltip arrow title={"Link Menu"}>
                <IconButton size="small" onClick={() => props.onClick()}>
                    <DeviceHubIcon fontSize="inherit" />
                </IconButton>
            </Tooltip>
        </>
    );
}
