import { call, ForkEffect, put, takeLatest } from "redux-saga/effects";
import { core } from "services/api";
import { locationItemsHydrate } from "../items/actions";
import { flattenMods, mapItemResp } from "../items/effects";
import { locationModifiersHydrate } from "../items/modifiers/actions";
import { LocationItem, LocationItemResp } from "../items/types";
import {
    sectionPageFetch as sectionPageFetchAction,
    sectionPageFetchError as sectionPageFetchErrorAction,
    sectionPageFetchSuccess as sectionPageFetchSuccessAction,
} from "./actions";
import { Section, SectionPageResp, SECTION_PAGE_FETCH } from "./types";

const flattenItems = (sections: Section[]): LocationItem[] => {
    const items: LocationItem[] = [];

    sections.forEach((s) => {
        items.push(...s._embedded.items.map((i) => mapItemResp(i as LocationItemResp)));
    });
    return items;
};
function* sectionPageFetch({ url }: { url: string; type: typeof SECTION_PAGE_FETCH }) {
    try {
        const resp: { data: SectionPageResp } = yield call(core.get, url);
        const sections = resp.data._embedded.sections;
        const items = flattenItems(sections);

        const menuUrl = url.replace(/(?:\/sections)?$/, "");
        yield put(sectionPageFetchSuccessAction(menuUrl, sections));
        for (const item of items) {
            const mods = flattenMods(item);

            item.unlinkedModifiers = mods.reduce((count, m) => (m.linked === true ? count : (count += 1)), 0);

            yield put(locationModifiersHydrate(mods));
        }
        yield put(locationItemsHydrate(items));
        if (resp.data._links.next !== undefined) {
            yield put(sectionPageFetchAction(resp.data._links.next.href));
        }
    } catch (e) {
        console.error("Failed to fetch Location Menu Page", e);
        if (e instanceof Error) {
            yield put(sectionPageFetchErrorAction(e));
        }
    }
}

export default function* sectionsSaga(): Generator<ForkEffect<void>, void, unknown> {
    yield takeLatest(SECTION_PAGE_FETCH, sectionPageFetch);
}
