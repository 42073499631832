export enum POSConfigKey {
    Item = "item",
    ComboItem = "combo",
    Modifier = "modifier",
    ComboModifier = "combo_item_groups",
}

export const entityPOSConfigKey = (posConfig?: POSConfig | null): POSConfigKey => {
    switch (posConfig) {
        case ["combo"] !== undefined:
            return POSConfigKey.ComboItem;
        case ["item"] !== undefined:
            return POSConfigKey.Item;
        case ["modifier"] !== undefined:
            return POSConfigKey.Modifier;
        default:
            return POSConfigKey.Item;
    }
};

export const getEntityPOSConfig = (
    posConfig?: POSConfig | null,
): ComboPOSConfig | ItemPOSConfig | ModifierPOSConfig | undefined =>
    posConfig?.combo || posConfig?.item || posConfig?.modifier;

export interface PriceLevel {
    id: string | null;
    name: string | null;
    price_per_unit: number | null;
}

interface basePOSConfig {
    id: string | null;
    name: string | null;
    price_level?: PriceLevel | null;
    price_per_unit: number | null;
    out_of_stock?: boolean | null;
}

interface ItemGroup {
    id: string;
    name: string;
    items: ItemPOSConfig[];
}

export interface ComboPOSConfig extends basePOSConfig {
    item_groups: ItemGroup[];
}

export interface ItemPOSConfig extends basePOSConfig {
    modifiers: ModifierPOSConfig[] | null;
}

export interface ModifierPOSConfig extends basePOSConfig {
    modifiers: this[] | null;
    exclude_price?: boolean;
}

export interface POSConfig {
    combo?: ComboPOSConfig;
    item?: ItemPOSConfig;
    modifier?: ModifierPOSConfig;
    combo_item_groups?: ComboPOSConfig;
}

export enum CalorieMode {
    single = "single",
    double = "double",
    multiple = "multiple",
}
export interface Nutrition {
    calories: {
        mode: CalorieMode;
        lower: number;
        upper?: number;
    };
    serving: {
        base: number;
        total: number;
        unit: string;
    };
}

interface POSMenuEntity {
    _embedded: {
        price_levels: PriceLevel[];
    };
    id: string;
    name: string;
    open: boolean;
    pos_id: string;
    price_per_unit: number;
}

export interface POSItem extends POSMenuEntity {
    _links: {
        self: {
            href: string;
            type: "application/hal+json; name=mms_pos_item";
        };
    };
}

export interface POSModifier extends POSMenuEntity {
    _links: {
        self: {
            href: string;
            type: "application/hal+json; name=mms_pos_modifier";
        };
    };
}

export type POSComboItemGroupItem = Omit<POSMenuEntity, "open">;

export interface POSComboItemGroup extends Pick<POSMenuEntity, "id" | "name" | "pos_id"> {
    items: POSComboItemGroupItem[];
}

export interface POSCombo extends Omit<POSMenuEntity, "open"> {
    _links: {
        self: {
            href: string;
            type: "application/hal+json; name=mms_pos_combo";
        };
    };
    item_groups: POSComboItemGroup[];
}
