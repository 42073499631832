import { DraggableSyntheticListeners } from "@dnd-kit/core";
import { UseSortableArguments } from "@dnd-kit/sortable";
import { Box, Divider, Grid, IconButton, Link, ListItemIcon, Tooltip, Typography, useTheme } from "@mui/material";
import React, { CSSProperties, useContext } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import {
    Delete as DeleteIcon,
    DragIndicator as DragIndicatorIcon,
    Edit as EditIcon,
    PostAdd as PostAddIcon,
    Visibility as VisibilityIcon,
    VisibilityOff as VisibilityOffIcon,
} from "@mui/icons-material";
import { MenuLayer } from "store/mms/menus/master/layers/types";
import pluralize from "pluralize";
import { MasterMenuContext, MenuEvent } from "pages/Menus/MasterMenu/context";
import { MenuLayerRuleSet } from "store/mms/menus/master/layers/rule-sets/types";
import { DraggableListItemStyles, ListItem } from "components/common/ListItem";
import { Card } from "components/common/Card";

export enum Position {
    Before = -1,
    After = 1,
}

export interface LayerListItemProps extends Partial<UseSortableArguments> {
    dragActive?: boolean;
    dragOverlay?: boolean;
    insertPosition?: Position;
    index?: number;
    layer: MenuLayer;
    listeners?: DraggableSyntheticListeners;
    style?: CSSProperties;
}

export const MenuLayerListItem = React.forwardRef(function MenuLayerListItem(
    { dragOverlay, insertPosition, style, layer, listeners, ...rest }: LayerListItemProps,
    ref: React.ForwardedRef<HTMLAnchorElement | null>,
) {
    const { onChange } = useContext(MasterMenuContext);
    const theme = useTheme();
    const isDisabled = !layer.enabled;

    return (
        <ListItem
            ref={ref}
            ListItemTextProps={{
                primary: (
                    <>
                        <ListItemIcon
                            sx={(theme) => ({
                                justifyContent: "flex-start",
                                minWidth: "unset",
                                marginRight: theme.spacing(0.2),

                                [theme.breakpoints.down("sm")]: {
                                    alignSelf: "flex-start",
                                    marginTop: theme.spacing(0.5),
                                },
                            })}
                            {...listeners}
                        >
                            <DragIndicatorIcon />
                        </ListItemIcon>
                        <Typography component="span" variant="h6">
                            {layer.name}
                        </Typography>
                    </>
                ),
                secondary: `${layer._embedded.rule_sets.length} ${pluralize(
                    "Rule Set",
                    layer._embedded.rule_sets.length,
                )}`,
                primaryTypographyProps: { sx: isDisabled ? { color: "text.disabled" } : {} },
                secondaryTypographyProps: { sx: { ...(isDisabled ? { color: "text.disabled" } : {}) } },
            }}
            actions={
                <>
                    <Tooltip title="Add Rule Set" arrow>
                        <IconButton size="small" onClick={() => onChange({ type: MenuEvent.RuleSetAdd, layer })}>
                            <PostAddIcon fontSize="inherit" />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Rename" arrow>
                        <IconButton size="small" onClick={() => onChange({ type: MenuEvent.LayerEdit, layer })}>
                            <EditIcon fontSize="inherit" />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title={layer.enabled ? "Disable" : "Enable"} arrow>
                        <IconButton size="small" onClick={() => onChange({ type: MenuEvent.LayerVisibility, layer })}>
                            {layer.enabled ? (
                                <VisibilityIcon fontSize="inherit" style={{ color: theme.palette.primary.main }} />
                            ) : (
                                <VisibilityOffIcon fontSize="inherit" style={{ color: theme.palette.text.disabled }} />
                            )}
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Remove" arrow>
                        <IconButton size="small" onClick={() => onChange({ type: MenuEvent.LayerDelete, layer })}>
                            <DeleteIcon fontSize="inherit" />
                        </IconButton>
                    </Tooltip>
                </>
            }
            sx={(theme) =>
                DraggableListItemStyles(
                    theme,
                    insertPosition === Position.After,
                    insertPosition === Position.Before,
                    dragOverlay,
                )
            }
            style={style}
            {...rest}
            disableCollapse={dragOverlay}
            defaultExpanded={false}
        >
            <Grid container spacing={3}>
                {layer._embedded.rule_sets.map((rs) => (
                    <RuleSet key={rs.id} layer={layer} ruleSet={rs} />
                ))}
            </Grid>
        </ListItem>
    );
});

interface RuleSetProps {
    layer: MenuLayer;
    ruleSet: MenuLayerRuleSet;
}

function RuleSet({ layer, ruleSet }: RuleSetProps): JSX.Element {
    const { onChange } = useContext(MasterMenuContext);
    const theme = useTheme();
    const { menuId } = useParams() as { menuId: string };
    const disableText = !layer.enabled || !ruleSet.enabled;

    return (
        <Grid
            item
            key={ruleSet.id}
            sx={(theme) => ({
                flexBasis: "25%",
                [theme.breakpoints.down("sm")]: {
                    flexBasis: "50%",
                },
            })}
        >
            <Card
                cardActions={
                    <>
                        <Tooltip title={ruleSet.enabled ? "Disable" : "Enable"} arrow>
                            <IconButton
                                size="small"
                                onClick={() =>
                                    onChange({ type: MenuEvent.RuleSetVisibility, layerId: layer.id, ruleSet })
                                }
                            >
                                {ruleSet.enabled ? (
                                    <VisibilityIcon fontSize="inherit" style={{ color: theme.palette.primary.main }} />
                                ) : (
                                    <VisibilityOffIcon
                                        fontSize="inherit"
                                        style={{ color: theme.palette.text.disabled }}
                                    />
                                )}
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Remove" arrow>
                            <IconButton
                                size="small"
                                onClick={() => onChange({ type: MenuEvent.RuleSetDelete, layer, ruleSet })}
                            >
                                <DeleteIcon fontSize="inherit" />
                            </IconButton>
                        </Tooltip>
                    </>
                }
                disableImage
            >
                <Link
                    component={RouterLink}
                    title={ruleSet.name}
                    to={`/menus/${menuId}/layers/${layer.id}/rule-sets/${ruleSet.id}`}
                    sx={{
                        color: disableText ? "text.disabled" : "primary",
                        textDecorationColor: (theme) => (disableText ? theme.palette.text.disabled : "primary"),
                    }}
                    underline="always"
                >
                    <Typography
                        variant="h6"
                        sx={{
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 1,
                            overflow: "hidden",
                            wordBreak: "break-word",
                        }}
                        gutterBottom
                    >
                        {ruleSet.name}
                    </Typography>
                </Link>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        color: disableText ? "text.disabled" : "primary",
                        textDecorationColor: (theme) => (disableText ? theme.palette.text.disabled : "primary"),
                    }}
                >
                    <Box sx={{ textAlign: "center" }}>
                        <Typography>{ruleSet._embedded.rules.length}</Typography>
                        <Typography>{pluralize("Rule", ruleSet._embedded.rules.length)}</Typography>
                    </Box>
                    <Divider orientation="vertical" flexItem />
                    <Box sx={{ textAlign: "center" }}>
                        <Typography>{ruleSet._embedded.locations.length}</Typography>
                        <Typography>{pluralize("Location", ruleSet._embedded.locations.length)}</Typography>
                    </Box>
                </Box>
            </Card>
        </Grid>
    );
}
