import { byHref } from "store/types";
import {
    EntityWithNutritionDeleteSuccess,
    EntityWithNutritionUpdateSuccess,
    ENTITY_WITH_NUTRITION_DELETE_SUCCESS,
    ENTITY_WITH_NUTRITION_UPDATE_SUCCESS,
} from "../../nutrition/types";
import {
    isMasterModifier,
    MasterModifier,
    MasterModifierActions,
    MASTER_MODIFIERS_HYDRATE,
    MASTER_MODIFIER_FETCH,
    MASTER_MODIFIER_FETCH_ERROR,
    MASTER_MODIFIER_FETCH_SUCCESS,
    MASTER_MODIFIER_OPTION_SETS_SAVE,
    MASTER_MODIFIER_OPTION_SETS_SAVE_ERROR,
    MASTER_MODIFIER_OPTION_SET_DELETE_SUCCESS,
    MASTER_MODIFIER_SAVE,
    MASTER_MODIFIER_SAVE_ERROR,
    MASTER_MODIFIER_SAVE_SUCCESS,
} from "./types";
interface MasterModifiersState {
    readonly fetching: boolean;
    readonly fetchError?: Error;
    readonly byHref: { [key: string]: MasterModifier };
    readonly hrefsById: { [key: string]: string[] };
    readonly allIds: string[];
    readonly saving: boolean;
    readonly saveError?: Error;
}

const initialState: MasterModifiersState = { fetching: false, byHref: {}, hrefsById: {}, allIds: [], saving: false };

export default function masterModifierReducer(
    state = initialState,
    action: MasterModifierActions | EntityWithNutritionUpdateSuccess | EntityWithNutritionDeleteSuccess,
): MasterModifiersState {
    switch (action.type) {
        case MASTER_MODIFIER_FETCH:
            return {
                ...state,
                fetching: true,
            };

        case MASTER_MODIFIER_FETCH_SUCCESS:
            return {
                ...state,
                byHref: {
                    ...state.byHref,
                    [action.modifier._links.self.href]: action.modifier,
                },
                hrefsById: {
                    ...state.hrefsById,
                    [action.modifier.id]: [
                        ...(state.hrefsById[action.modifier.id] || []),
                        action.modifier._links.self.href,
                    ],
                },
                allIds: [...state.allIds.filter((id) => id !== action.modifier.id), action.modifier.id],
                fetching: false,
            };

        case MASTER_MODIFIER_FETCH_ERROR:
            return {
                ...state,
                fetching: false,
                fetchError: action.error,
            };

        case MASTER_MODIFIER_SAVE:
            return {
                ...state,
                saving: true,
            };

        case MASTER_MODIFIER_SAVE_SUCCESS:
            return {
                ...state,
                byHref: {
                    ...state.byHref,
                    [action.modifier._links.self.href]: action.modifier,
                },
                hrefsById: {
                    ...state.hrefsById,
                    [action.modifier.id]: [
                        ...(state.hrefsById[action.modifier.id] || []),
                        action.modifier._links.self.href,
                    ],
                },
                allIds: [...state.allIds.filter((id) => id !== action.modifier.id), action.modifier.id],
                saving: false,
            };

        case MASTER_MODIFIER_SAVE_ERROR:
            return {
                ...state,
                saving: false,
                saveError: action.error,
            };

        // optimistically save, revert on error
        case MASTER_MODIFIER_OPTION_SETS_SAVE:
        case MASTER_MODIFIER_OPTION_SETS_SAVE_ERROR:
            return {
                ...state,
                byHref: {
                    ...state.byHref,
                    [action.modifier._links.self.href]: {
                        ...state.byHref[action.modifier._links.self.href],
                        _embedded: {
                            ...state.byHref[action.modifier._links.self.href]._embedded,
                            option_sets: action.optionSets,
                        },
                    },
                },
            };

        case MASTER_MODIFIER_OPTION_SET_DELETE_SUCCESS:
            return {
                ...state,
                byHref: {
                    ...state.byHref,
                    [action.modifier._links.self.href]: {
                        ...state.byHref[action.modifier._links.self.href],
                        _embedded: {
                            ...state.byHref[action.modifier._links.self.href]._embedded,
                            option_sets: state.byHref[action.modifier._links.self.href]._embedded.option_sets.filter(
                                (os) => os.id !== action.optionSet.id,
                            ),
                        },
                    },
                },
            };

        case MASTER_MODIFIERS_HYDRATE:
            const newIds = action.modifiers.map((m) => m.id);
            const hrefsById = action.modifiers.reduce((ids: { [key: string]: string[] }, m) => {
                if (!ids[m.id]) {
                    ids[m.id] = [...(state.hrefsById[m.id] || []), m._links.self.href];
                } else {
                    ids[m.id].push(m._links.self.href);
                }
                return ids;
            }, {});

            return {
                ...state,
                byHref: {
                    ...state.byHref,
                    ...byHref(action.modifiers),
                },
                hrefsById: {
                    ...state.hrefsById,
                    ...hrefsById,
                },
                allIds: [...state.allIds.filter((id) => !newIds.includes(id)), ...newIds],
            };

        case ENTITY_WITH_NUTRITION_DELETE_SUCCESS:
            if (isMasterModifier(action.entity)) {
                state = {
                    ...state,
                    byHref: { ...state.byHref },
                };

                state.hrefsById[action.entity.id].forEach((href) => {
                    const mod = state.byHref[href];
                    state.byHref[href] = {
                        ...mod,
                        _embedded: {
                            ...mod._embedded,
                            nutrition: null,
                        },
                    };
                });
            }

            return state;

        case ENTITY_WITH_NUTRITION_UPDATE_SUCCESS:
            if (isMasterModifier(action.entity)) {
                state = {
                    ...state,
                    byHref: { ...state.byHref },
                };

                state.hrefsById[action.entity.id].forEach((href) => {
                    const mod = state.byHref[href];
                    state.byHref[href] = {
                        ...mod,
                        _embedded: {
                            ...mod._embedded,
                            nutrition: action.nutrition,
                        },
                    };
                });
            }

            return state;

        default:
            return state;
    }
}
