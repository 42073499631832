import { capitalize } from "components/common/utils";
import { call, ForkEffect, put, takeEvery } from "redux-saga/effects";
import { core } from "services/api";
import { enqueueSnackbar } from "store/notifications/actions";
import { jobCreateError } from "./actions";
import { Job, JobType, JOB_CREATE } from "./types";

function jobTypeText(jt: JobType): string {
    switch (jt) {
        case JobType.Link:
            return "Re-Sync";
    }
    return capitalize(jt.valueOf());
}

function* jobCreate({ job, url }: { job: Job; url: string; type: typeof JOB_CREATE }) {
    try {
        yield call(core.post, url, job);

        yield put(
            enqueueSnackbar({
                message: `${jobTypeText(job.type)} started.`,
                options: {
                    variant: "success",
                },
            }),
        );
    } catch (e) {
        if (e instanceof Error) {
            yield put(jobCreateError(e));
        }
        yield put(
            enqueueSnackbar({
                message: `Failed to start ${jobTypeText(job.type)}.`,
                options: {
                    variant: "error",
                },
            }),
        );
    }
}

export default function* jobsSaga(): Generator<ForkEffect<never>, void, unknown> {
    yield takeEvery(JOB_CREATE, jobCreate);
}
