import {
    LOCATION_ORDER_PROFILE_DELETE,
    LOCATION_ORDER_PROFILE_DELETE_ERROR,
    LOCATION_ORDER_PROFILE_SAVE,
    LOCATION_ORDER_PROFILE_SAVE_ERROR,
    LOCATION_ORDER_PROFILE_SAVE_SUCCESS,
    OrderProfile,
    OrderProfilesActions,
    ORDER_PROFILES_FETCH,
    ORDER_PROFILES_FETCH_ERROR,
    ORDER_PROFILES_FETCH_SUCCESS,
    ORDER_PROFILE_DELETE_SUCCESS,
    ORDER_PROFILE_FETCH,
    ORDER_PROFILE_FETCH_ERROR,
    ORDER_PROFILE_FETCH_SUCCESS,
    ORDER_PROFILE_SAVE,
    ORDER_PROFILE_SAVE_ERROR,
    ORDER_PROFILE_SAVE_SUCCESS,
} from "./types";

interface OrderProfileState {
    readonly profiles: OrderProfile[];
    readonly selectedProfile?: OrderProfile;
    readonly fetching: boolean;
    readonly loaded: boolean;
    readonly fetchError?: Error;
    readonly saving: boolean;
    readonly saveError?: Error;
    readonly deleteError?: Error;
}

const initialState: OrderProfileState = {
    profiles: [],
    fetching: false,
    loaded: false,
    saving: false,
};

export default function orderProfilesReducer(state = initialState, action: OrderProfilesActions): OrderProfileState {
    switch (action.type) {
        case ORDER_PROFILES_FETCH:
            return {
                ...state,
                fetching: true,
            };

        case ORDER_PROFILES_FETCH_SUCCESS:
            return {
                ...state,
                profiles: action.profiles,
                fetching: false,
                loaded: true,
            };

        case ORDER_PROFILES_FETCH_ERROR:
            return {
                ...state,
                fetchError: action.fetchError,
                fetching: false,
            };

        case ORDER_PROFILE_SAVE:
            return {
                ...state,
                saving: true,
            };

        case ORDER_PROFILE_SAVE_SUCCESS:
            return {
                ...state,
                profiles: [...state.profiles.filter((p) => p.id !== action.profile.id), action.profile],
                selectedProfile: action.profile,
                saving: false,
            };

        case ORDER_PROFILE_SAVE_ERROR:
            return {
                ...state,
                saveError: action.saveError,
                saving: false,
            };

        case ORDER_PROFILE_FETCH:
            return {
                ...state,
                fetching: true,
            };

        case ORDER_PROFILE_FETCH_SUCCESS:
            return {
                ...state,
                fetching: false,
                selectedProfile: action.profile,
                loaded: true,
            };

        case ORDER_PROFILE_FETCH_ERROR:
            return {
                ...state,
                fetching: false,
                fetchError: action.fetchError,
            };

        case ORDER_PROFILE_DELETE_SUCCESS:
            return {
                ...state,
                profiles: state.profiles.filter((p) => p.id !== action.profile.id),
            };

        case LOCATION_ORDER_PROFILE_SAVE:
            return {
                ...state,
                saving: true,
                saveError: undefined,
            };

        case LOCATION_ORDER_PROFILE_SAVE_SUCCESS:
            // We need to keep the state of the master profile as correct as we can because it is in redux. The master
            // is where the LOPs are "stored" as children. Since the LOP that is being saved doesn't have any
            // information about the parent id, and because we need to update the stored child LOP up-to-date once it is
            // saved (it might be new and not have an association yet), we need the master id to find where the LOP
            // should be in the redux store.
            const master =
                state.selectedProfile?.id === action.masterId
                    ? state.selectedProfile
                    : state.profiles.find((p) => p.id === action.masterId);

            if (!master) {
                return {
                    ...state,
                    saving: false,
                };
            }

            const masterWithLocationOrderProfiles = {
                ...master,
                locationOrderProfiles: [
                    ...master.locationOrderProfiles.filter((l) => l.id !== action.profile.id),
                    action.profile,
                ],
            };

            return {
                ...state,
                profiles: [...state.profiles.filter((p) => p.id !== action.masterId), masterWithLocationOrderProfiles],
                selectedProfile: {
                    ...masterWithLocationOrderProfiles,
                },
                saving: false,
            };

        case LOCATION_ORDER_PROFILE_SAVE_ERROR:
            return {
                ...state,
                saveError: action.saveError,
                saving: false,
            };

        case LOCATION_ORDER_PROFILE_DELETE: {
            const master =
                state.selectedProfile?.id === action.master.id
                    ? state.selectedProfile
                    : state.profiles.find((p) => p.id === action.master.id);

            if (!master) {
                return {
                    ...state,
                    deleteError: undefined,
                };
            }

            const masterWithLocationOrderProfiles = {
                ...master,
                locationOrderProfiles: [...master.locationOrderProfiles.filter((l) => l.id !== action.profile.id)],
            };

            return {
                ...state,
                profiles: [...state.profiles.filter((p) => p.id !== action.master.id), masterWithLocationOrderProfiles],
                selectedProfile: {
                    ...masterWithLocationOrderProfiles,
                },
                deleteError: undefined,
            };
        }

        case LOCATION_ORDER_PROFILE_DELETE_ERROR: {
            const master =
                state.selectedProfile?.id === action.masterId
                    ? state.selectedProfile
                    : state.profiles.find((p) => p.id === action.masterId);

            if (!master) {
                return {
                    ...state,
                    deleteError: action.deleteError,
                };
            }

            const masterWithLocationOrderProfiles = {
                ...master,
                locationOrderProfiles: [...master.locationOrderProfiles.filter((l) => l.id !== action.profile.id)],
            };

            return {
                ...state,
                profiles: [...state.profiles.filter((p) => p.id !== action.masterId), masterWithLocationOrderProfiles],
                selectedProfile: {
                    ...masterWithLocationOrderProfiles,
                },
                deleteError: action.deleteError,
            };
        }

        default:
            return state;
    }
}
