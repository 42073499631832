import { Alert, Typography } from "@mui/material";
import ConfirmDialog from "components/common/ConfirmDialog";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { axiosErrorMessage } from "store/types";
import { TaxRate } from "store/mms/menus/location/taxes/types";
import { taxRateSave } from "store/mms/menus/location/taxes/actions";
import { useParams } from "react-router-dom";

interface TaxRateVisibilityProps {
    taxRate: TaxRate;
    onClose(): void;
    open: boolean;
}

export function LocationTaxRateVisibility({ taxRate, onClose, open }: TaxRateVisibilityProps): JSX.Element {
    const dispatch = useDispatch();
    const [state, setState] = useState<{ saving: boolean; error?: string }>({
        saving: false,
    });
    const { locationId } = useParams() as { locationId: string };
    const name = "Location Tax Rate";
    const verb = taxRate.enabled ? "Disable" : "Enable";

    return (
        <ConfirmDialog
            title={`${verb} ${name}`}
            open={open}
            onClose={onClose}
            onConfirm={() => {
                setState({ saving: true });
                dispatch(
                    taxRateSave({ ...taxRate, enabled: !taxRate.enabled }, locationId, (error?: Error): void => {
                        if (error) {
                            return setState({ saving: false, error: axiosErrorMessage(error) });
                        }
                        setState({ saving: false });
                        onClose();
                    }),
                );
            }}
            confirm={`${verb} ${name}`}
            confirming={state.saving}
            maxWidth="xl"
            transitionDuration={0}
        >
            <Typography>
                Are you sure you want to {verb.toLowerCase()} the {name} <strong>{taxRate.name}</strong>?
            </Typography>

            {taxRate.enabled && (
                <Alert variant="outlined" severity="info">
                    This tax rate will no longer be used on any menu for this Location.
                </Alert>
            )}

            {state.error && (
                <Alert variant="outlined" severity="error">
                    {state.error}
                </Alert>
            )}
        </ConfirmDialog>
    );
}
