import { call, ForkEffect, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { core } from "services/api";
import { POSConfig } from "store/mms/types";
import { enqueueSnackbar } from "store/notifications/actions";
import { RootState } from "store/rootReducer";
import {
    locationModifierFetch as locationModifierFetchAction,
    locationModifierFetchError,
    locationModifierFetchSuccess,
    locationModifierSaveError,
    locationModifierSaveSuccess,
} from "./actions";
import {
    LocationModifier,
    LocationModifierReq,
    LocationModifierResp,
    LOCATION_MODIFIER_FETCH,
    LOCATION_MODIFIER_SAVE,
} from "./types";

const mapDomainToReq = (lm: LocationModifier): LocationModifierReq => {
    return {
        base_price_per_unit: lm.base_price_per_unit,
        display_name: lm.display_name,
        enabled: lm.enabled,
        id: lm.id,
        linked: lm.linked,
        master_enabled: lm.master_enabled,
        nutrition: lm.nutrition,
        pos_config: JSON.stringify(lm.pos_config),
        price_per_unit: lm.price_per_unit,
        reference_name: lm.reference_name,
        strategy: lm.strategy,
    };
};
const mapRespToDomain = (r: LocationModifierResp): LocationModifier => {
    return {
        ...r,
        pos_config: r.pos_config !== "null" ? (JSON.parse(r.pos_config) as POSConfig) : ({} as POSConfig),
    };
};
const hrefsById = (state: RootState) => state.mms.menus.location.modifiers.hrefsById;

function* locationModifierFetch({ url }: { url: string; type: typeof LOCATION_MODIFIER_FETCH }) {
    try {
        const resp: { data: LocationModifierResp } = yield call(core.get, url);

        yield put(locationModifierFetchSuccess(mapRespToDomain(resp.data)));
    } catch (error) {
        console.error("Failed to fetch Menu Location Modifier", error);
        if (error instanceof Error) {
            yield put(locationModifierFetchError(error));
        }
    }
}

function* locationModifierSave({
    url,
    modifier,
    callback,
}: {
    url: string;
    type: typeof LOCATION_MODIFIER_SAVE;
    modifier: LocationModifier;
    callback?: (error?: Error) => void;
}) {
    try {
        const resp: { data: LocationModifierResp } = yield call(
            modifier.id === "" ? core.post : core.put,
            url,
            mapDomainToReq(modifier),
        );
        const mod = mapRespToDomain(resp.data);
        const modsMap: {
            [key: string]: string[];
        } = yield select(hrefsById);

        if (callback !== undefined) {
            yield call(callback);
        }
        yield put(locationModifierSaveSuccess(mod));
        yield put(
            enqueueSnackbar({
                message: `Successfully saved Modifier, ${mod.display_name}.`,
                options: {
                    variant: "success",
                },
            }),
        );
        for (const url of modsMap[mod.id]) {
            if (url !== mod._links.self.href) {
                yield put(locationModifierFetchAction(url));
            }
        }
    } catch (e) {
        console.error("Failed to save Menu Location Modifier", e);
        if (e instanceof Error) {
            if (callback !== undefined) {
                yield call(callback, e);
            }
            yield put(locationModifierSaveError(e));
        }

        yield put(
            enqueueSnackbar({
                message: `Failed to save Modifier${modifier.display_name && ", " + modifier.display_name}.`,
                options: {
                    variant: "error",
                },
            }),
        );
    }
}

export function* locationModifiersSaga(): Generator<ForkEffect<never>, void, unknown> {
    yield takeLatest(LOCATION_MODIFIER_SAVE, locationModifierSave);
    yield takeEvery(LOCATION_MODIFIER_FETCH, locationModifierFetch);
}
