import { Box, Breadcrumbs, Breakpoint, Container, ContainerProps, Grid, GridSize, Paper } from "@mui/material";
import React from "react";

interface DefaultLayoutProps extends ContainerProps {
    aside?: React.ReactNode;
    asideLeft?: boolean;
    asideSizes?: Partial<Record<Breakpoint, boolean | GridSize>>;
    breadcrumbs?: React.ReactNode[];
    contentSizes?: Partial<Record<Breakpoint, boolean | GridSize>>;
    header?: React.ReactNode;
}

export default function DefaultLayout({
    aside = null,
    asideLeft = false,
    breadcrumbs,
    children,
    asideSizes = { xs: 3, xl: 2 },
    contentSizes = { xs: aside ? 9 : 12, xl: aside ? 10 : 12 },
    header,
    ...rest
}: DefaultLayoutProps): JSX.Element {
    const { sx = [], ...lessRest } = rest;

    return (
        <Box sx={{ display: "flex", flexDirection: "column", flex: "1" }}>
            {(breadcrumbs || header) && (
                <Box
                    sx={(theme) => ({
                        display: "flex",
                        flexDirection: "column",
                        padding: theme.spacing(2, 3, 0),
                        backgroundColor: theme.palette.background.paper,

                        [theme.breakpoints.down("sm")]: {
                            marginTop: theme.spacing(7),
                        },
                    })}
                    borderBottom={1}
                    borderColor={(theme) => theme.mixins.borderColor}
                >
                    <Container
                        sx={(theme) => ({
                            [theme.breakpoints.up("sm")]: {
                                paddingLeft: 0,
                                paddingRight: 0,
                            },
                        })}
                    >
                        {breadcrumbs && (
                            <Breadcrumbs
                                maxItems={4}
                                aria-label="breadcrumb"
                                sx={(theme) => ({
                                    marginBottom: theme.spacing(1),

                                    [theme.breakpoints.down("sm")]: {
                                        display: "none",
                                    },
                                })}
                            >
                                {breadcrumbs}
                            </Breadcrumbs>
                        )}

                        {header}
                    </Container>
                </Box>
            )}
            <Container
                sx={[
                    (theme) => ({
                        display: "flex",
                        flexDirection: "column",
                        flex: "1 auto",
                        [theme.breakpoints.up("sm")]: {
                            paddingLeft: 0,
                            paddingRight: 0,
                        },

                        [theme.breakpoints.down("sm")]: {
                            paddingLeft: 0,
                            paddingRight: 0,
                            marginBottom: 0,
                        },
                    }),
                    ...(Array.isArray(sx) ? sx : [sx]),
                ]}
                {...lessRest}
            >
                <Grid
                    className={asideLeft ? "left" : "right"}
                    sx={{
                        flex: "1 auto",
                        marginTop: (theme) => theme.spacing(3),
                        marginBottom: (theme) => theme.spacing(7),
                        padding: (theme) => theme.spacing(2, 3.5, 0),

                        "&.right > .MuiGrid-item:first-of-type": {
                            order: 1,
                        },
                        "&.right > .MuiGrid-item:last-of-type": {
                            order: 0,
                        },
                    }}
                    container
                >
                    {aside && (
                        <Grid item {...asideSizes}>
                            {aside}
                        </Grid>
                    )}

                    <Grid item sx={{ display: "flex" }} {...contentSizes}>
                        <Paper
                            sx={(theme) => ({
                                display: "flex",
                                flexDirection: "column",
                                flex: "1 auto",
                                position: "relative",
                                padding: theme.spacing(2),

                                "& > hr": {
                                    margin: theme.spacing(3, 0),
                                },

                                [theme.breakpoints.down("sm")]: {
                                    padding: theme.spacing(1, 1),
                                    borderRadius: 0,
                                },
                            })}
                            variant="outlined"
                            square
                        >
                            {children}
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
