import React from "react";
import { getSession } from "services/session";
import { RevenueCenter } from "./reducer";
import { AxiosResponse } from "axios";
import OmniComplete from "./OmniComplete";

interface props {
    revenueCenterId?: string;
    locationId: string;
    setRevenueCenter(r: RevenueCenter | null): void;
    error?: boolean;
    errorText?: string;
    disableFetch?: boolean;
}

interface revenueCenterResponse {
    id: string;
    name: string;
    default: boolean;
    pos_id: string;
}

const mapToDomain = (r: revenueCenterResponse): RevenueCenter => ({
    id: r.id,
    default: r.default,
    name: r.name,
    posId: r.pos_id,
    kind: "revenue_center",
});

export default function POSRevenueCenterSearch(props: props): JSX.Element {
    const s = getSession();
    const { revenueCenterId, disableFetch, locationId, error, errorText = "Required", setRevenueCenter } = props;

    return (
        <OmniComplete
            id="revenue-center-id"
            entity={revenueCenterId ? ({ id: revenueCenterId } as RevenueCenter) : null}
            error={error}
            helperText={error ? errorText : "Not Found on POS"}
            setEntity={setRevenueCenter}
            inputLabel="Revenue Center"
            disableFetch={disableFetch}
            disabled={!locationId}
            searchUrl={`/1.1/accounts/${s?.accountId}/locations/${locationId}/mms/pos/revenue_centers/?search=`}
            searchRespMapper={(
                resp: AxiosResponse<{
                    _embedded: {
                        revenue_centers: revenueCenterResponse[];
                    };
                }>,
            ) => resp.data._embedded.revenue_centers.map(mapToDomain)}
            fetchUrl={
                revenueCenterId &&
                `/1.1/accounts/${s?.accountId}/locations/${locationId}/mms/pos/revenue_centers/${revenueCenterId}`
            }
            fetchRespMapper={(resp: AxiosResponse<revenueCenterResponse>) => mapToDomain(resp.data)}
        />
    );
}
