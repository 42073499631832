import { call, fork, ForkEffect, put, takeEvery, takeLatest } from "redux-saga/effects";
import { core } from "services/api";
import { enqueueSnackbar } from "store/notifications/actions";
import { MasterMenu } from "../types";
import {
    layerAddSuccess,
    layersPageFetch as layerPageFetchAction,
    layersPageFetchError,
    layersPageFetchSuccess,
    layersSaveError,
    layersSaveSuccess,
} from "./actions";
import {
    LayerAdd,
    LayersSave,
    LAYERS_PAGE_FETCH,
    LAYERS_SAVE,
    LAYER_ADD,
    MenuLayer,
    MenuLayerPageResp,
    MenuLayerReq,
} from "./types";
import ruleSetsSaga from "./rule-sets/effects";

const mapToReq = (l: MenuLayer): MenuLayerReq => ({
    id: l.id,
    enabled: l.enabled,
    name: l.name,
    sequence: l.sequence,
});

function* layersPageFetch({ menu, url }: { menu: MasterMenu; url: string; type: typeof LAYERS_PAGE_FETCH }) {
    try {
        const resp: { data: MenuLayerPageResp } = yield call(core.get, url);

        if (resp.data._links.next !== undefined) {
            yield put(layerPageFetchAction(menu, resp.data._links.next.href));
        }
        yield put(layersPageFetchSuccess(menu.id, resp.data._embedded.layers));
    } catch (e) {
        console.error("Failed to fetch Menu Layers Page", e);
        if (e instanceof Error) {
            yield put(layersPageFetchError(e));
        }
    }
}

function* layersSave({ menuId, layers, callback }: LayersSave) {
    try {
        const savedLayers: MenuLayer[] = [];

        for (const l of layers) {
            const resp: { data: MenuLayer } = yield call(core.put, l._links.self.href, mapToReq(l));
            savedLayers.push(resp.data);
        }

        yield put(layersSaveSuccess(menuId, savedLayers));
        if (callback) {
            yield call(callback);
        }
    } catch (e) {
        console.error("Failed to save Menu Layers", e);
        // TODO: this is currently silent to the user, and that is intentional. Any of the calls above could fail, and
        // we do not want to inform the user if one out of three fail. However, this needs to be updated once the API
        // supports batch updating of Menu Layers. Once that is in place, this will need to be updated to inform users
        // of any potential failures.
        if (e instanceof Error) {
            yield put(layersSaveError(e));
            if (callback) {
                yield call(callback, e);
            }
        }
    }
}

function* layerAdd({ menu, layer, callback }: LayerAdd) {
    try {
        const resp: { data: MenuLayer } = yield call(core.post, menu._links.layers.href, layer);

        yield put(layerAddSuccess(menu.id, resp.data));
        if (callback) {
            yield call(callback);
        }
    } catch (e) {
        console.error("Failed to create Menu Layer", e);

        if (e instanceof Error && callback) {
            yield call(callback, e);
        }

        yield put(
            enqueueSnackbar({
                message: `Failed to create Menu Layer ${layer.name}.`,
                options: {
                    variant: "error",
                },
            }),
        );
    }
}

export default function* layersSaga(): Generator<ForkEffect<void>, void, unknown> {
    yield takeLatest(LAYERS_PAGE_FETCH, layersPageFetch);
    yield takeEvery(LAYERS_SAVE, layersSave);
    yield takeEvery(LAYER_ADD, layerAdd);
    yield fork(ruleSetsSaga);
}
