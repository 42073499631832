import { MenuLayer } from "../types";
import {
    MenuLayerRuleSet,
    NewMenuLayerRuleSet,
    RuleSetActions,
    RuleSetLocationCsvData,
    RULE_SET_ADD,
    RULE_SET_ADD_SUCCESS,
    RULE_SET_MIGRATE_LOCATIONS_ADD,
    RULE_SET_MIGRATE_LOCATIONS_ADD_SUCCESS,
    RULE_SET_MIGRATE_LOCATIONS_DELETE,
    RULE_SET_MIGRATE_LOCATIONS_DELETE_SUCCESS,
    RULE_SET_SAVE,
    RULE_SET_SAVE_SUCCESS,
} from "./types";

export function ruleSetAdd(
    layer: MenuLayer,
    ruleSet: NewMenuLayerRuleSet,
    callback?: (error?: Error) => void,
): RuleSetActions {
    return {
        type: RULE_SET_ADD,
        layer,
        ruleSet,
        callback,
    };
}

export function ruleSetAddSuccess(layerId: string, ruleSet: MenuLayerRuleSet): RuleSetActions {
    return {
        type: RULE_SET_ADD_SUCCESS,
        layerId,
        ruleSet,
    };
}

export function ruleSetSave(
    layerId: string,
    ruleSet: MenuLayerRuleSet,
    callback?: (error?: Error) => void,
): RuleSetActions {
    return {
        type: RULE_SET_SAVE,
        layerId,
        ruleSet,
        callback,
    };
}

export function ruleSetSaveSuccess(layerId: string, ruleSet: MenuLayerRuleSet): RuleSetActions {
    return {
        type: RULE_SET_SAVE_SUCCESS,
        layerId,
        ruleSet,
    };
}

export function ruleSetMigrateLocationsAdd(
    layer: MenuLayer,
    locations: RuleSetLocationCsvData[],
    destinationRuleSet: MenuLayerRuleSet,
    callback?: (error?: Error) => void,
): RuleSetActions {
    return {
        type: RULE_SET_MIGRATE_LOCATIONS_ADD,
        layer,
        locations,
        destinationRuleSet,
        callback,
    };
}

export function ruleSetMigrateLocationsAddSuccess(menuId: string, layer: MenuLayer): RuleSetActions {
    return {
        type: RULE_SET_MIGRATE_LOCATIONS_ADD_SUCCESS,
        menuId,
        layer,
    };
}

export function ruleSetMigrateLocationsDelete(
    layer: MenuLayer,
    ruleSet: MenuLayerRuleSet,
    destinationRuleSet: MenuLayerRuleSet,
    callback?: (error?: Error) => void,
): RuleSetActions {
    return {
        type: RULE_SET_MIGRATE_LOCATIONS_DELETE,
        layer,
        ruleSet,
        destinationRuleSet,
        callback,
    };
}

export function ruleSetMigrateLocationsDeleteSuccess(menuId: string, layer: MenuLayer): RuleSetActions {
    return {
        type: RULE_SET_MIGRATE_LOCATIONS_DELETE_SUCCESS,
        menuId,
        layer,
    };
}
