import React, { CSSProperties, ReactNode } from "react";
import {
    Box,
    BoxProps,
    Collapse,
    CollapseProps,
    IconButton,
    ListItemButton as MuiListItemButton,
    ListItemButtonProps as MuiListItemButtonProps,
    ListItemIcon,
    ListItemSecondaryActionProps,
    ListItemText,
    ListItemTextProps,
    Theme,
} from "@mui/material";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import useToggle from "helpers/useToggle";
import { ListItemSecondaryAction } from "./ListItemSecondaryAction";

interface ListItemProps extends MuiListItemButtonProps {
    CollapseProps?: Partial<Omit<CollapseProps, "in">>;
    CollapseContainerProps?: Partial<BoxProps>;
    disableCollapse?: boolean;
    ListItemTextProps: ListItemTextProps;
    ListItemSecondaryActionProps?: ListItemSecondaryActionProps;
    actions?: ReactNode;
    defaultExpanded?: boolean;
    expanded?: boolean;
}

export const ListItem = React.forwardRef(function ListItem(
    {
        children,
        CollapseProps,
        CollapseContainerProps,
        disableCollapse = false,
        ListItemTextProps,
        ListItemSecondaryActionProps,
        actions,
        defaultExpanded = false,
        expanded = false,
        ...listItemRest
    }: ListItemProps,
    ref: React.ForwardedRef<HTMLAnchorElement | null>,
) {
    const { sx = [] } = listItemRest;
    const [open, toggleOpen] = useToggle(defaultExpanded);
    const {
        primaryTypographyProps,
        secondaryTypographyProps,
        sx: ListItemTextSx = [],
        ...restListItemTextProps
    } = ListItemTextProps;
    const { sx: PrimaryTypographySx = [], ...restPrimaryTypographyProps } = primaryTypographyProps || { sx: [] };
    const { sx: SecondaryTypographySx = [], ...restSecondaryTypographyProps } = secondaryTypographyProps || { sx: [] };
    const collapseIsExpanded = open || expanded;

    return (
        <>
            <MuiListItemButton
                divider
                disableGutters
                disableRipple
                onClick={toggleOpen}
                sx={[
                    {
                        alignItems: "flex-start",
                        paddingLeft: (theme) => theme.spacing(2),
                        paddingRight: (theme) => theme.spacing(2),

                        "& > .MuiListItemIcon-root": {
                            justifyContent: "flex-end",
                        },
                        "&:hover": {
                            backgroundColor: "initial",
                        },
                    },
                    ...(Array.isArray(sx) ? sx : [sx]),
                ]}
                href="#"
                ref={ref}
            >
                <ListItemText
                    primaryTypographyProps={{
                        variant: "h5",
                        sx: [
                            (theme) => ({
                                display: "flex",
                                alignItems: "center",
                                [theme.breakpoints.down("sm")]: {
                                    display: "-webkit-box",
                                    WebkitBoxOrient: "vertical",
                                    WebkitLineClamp: 1,
                                    overflow: "hidden",
                                    wordBreak: "break-word",
                                    verticalAlign: "top",

                                    "& > .MuiTypography-root": {
                                        verticalAlign: "top",
                                    },
                                },
                            }),
                            ...(Array.isArray(PrimaryTypographySx) ? PrimaryTypographySx : [PrimaryTypographySx]),
                        ],
                        ...restPrimaryTypographyProps,
                    }}
                    secondaryTypographyProps={{
                        component: "span",
                        sx: [
                            (theme) => ({
                                marginLeft: theme.spacing(3.5),
                                [theme.breakpoints.down("sm")]: {
                                    display: "none",
                                },
                            }),
                            ...(Array.isArray(SecondaryTypographySx) ? SecondaryTypographySx : [SecondaryTypographySx]),
                        ],
                        ...restSecondaryTypographyProps,
                    }}
                    sx={[
                        {
                            "&.disabled .MuiListItemText-secondary, &.disabled .MuiListItemText-primary,  &.disabled .MuiListItemText-primary a, &.disabled .MuiListItemText-secondary a":
                                {
                                    color: "text.disabled",
                                },
                        },
                        ...(Array.isArray(ListItemTextSx) ? ListItemTextSx : [ListItemTextSx]),
                    ]}
                    {...restListItemTextProps}
                />
                {actions && (
                    <ListItemSecondaryAction {...ListItemSecondaryActionProps}>{actions}</ListItemSecondaryAction>
                )}
                {children && (
                    <ListItemIcon
                        onClick={toggleOpen}
                        sx={(theme) => ({
                            minWidth: "unset",
                            marginTop: theme.spacing(0.75),
                            display: "flex",
                        })}
                    >
                        <IconButton size="small">
                            <ExpandMoreIcon
                                fontSize="inherit"
                                sx={{
                                    transform: collapseIsExpanded ? "rotate(180deg)" : "rotate(0deg)",
                                    transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                                }}
                            />
                        </IconButton>
                    </ListItemIcon>
                )}
            </MuiListItemButton>
            {!disableCollapse && children && (
                <Collapse timeout="auto" {...CollapseProps} in={collapseIsExpanded} mountOnEnter unmountOnExit>
                    <Box component="section" sx={{ padding: (theme) => theme.spacing(2) }} {...CollapseContainerProps}>
                        {children}
                    </Box>
                </Collapse>
            )}
        </>
    );
});

export const DraggableListItemStyles = (
    theme: Theme,
    insertAfter: boolean,
    insertBefore: boolean,
    dragOverlay?: boolean,
): CSSProperties => ({
    paddingLeft: 0,
    paddingRight: theme.spacing(1),

    ...(dragOverlay && {
        maxWidth: "95%",
        transform: "scale(1.025)",
        animation: `$pop ${theme.transitions.duration.short} ${theme.transitions.easing.easeOut}`,
        boxShadow: theme.shadows[10],
        cursor: "grabbing",

        "&:hover": {
            backgroundColor: theme.palette.background.paper,
        },
    }),

    ...((insertAfter || insertBefore) && {
        "& .MuiListItemSecondaryAction-root::after, & .MuiListItemSecondaryAction-root::after": {
            height: 2,
            left: 0,
            right: 0,
            content: "''",
            position: "absolute",
            backgroundColor: theme.palette.info.main,
        },
    }),

    ...(insertAfter && {
        "& .MuiListItemSecondaryAction-root": {
            ...(dragOverlay && {
                marginBottom: "120px",
            }),
            "&::after": {
                bottom: "-1.5px",
            },
        },
    }),

    ...(insertBefore && {
        "& .MuiListItemSecondaryAction-root": {
            ...(dragOverlay && {
                marginBottom: "-120px",
            }),
            "&::after": {
                top: "-5px",
            },
        },
    }),
    ...{
        "@keyframes pop": {
            "0%": {
                transform: "translate3d(-10px, -10px, 0) scale(1)",
            },
            "100%": {
                transform: "translate3d(0px, 0px, 0) scale(1.025)",
            },
        },
    },
});
