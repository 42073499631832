import {
    OrderProfile,
    OrderProfilesActions,
    ORDER_PROFILES_FETCH,
    ORDER_PROFILES_FETCH_ERROR,
    ORDER_PROFILES_FETCH_SUCCESS,
    ORDER_PROFILE_SAVE,
    ORDER_PROFILE_SAVE_SUCCESS,
    ORDER_PROFILE_SAVE_ERROR,
    ORDER_PROFILE_FETCH,
    ORDER_PROFILE_FETCH_SUCCESS,
    ORDER_PROFILE_FETCH_ERROR,
    LOCATION_ORDER_PROFILE_SAVE,
    LOCATION_ORDER_PROFILE_SAVE_ERROR,
    LOCATION_ORDER_PROFILE_SAVE_SUCCESS,
    LocationOrderProfile,
    LOCATION_ORDER_PROFILE_DELETE,
    LOCATION_ORDER_PROFILE_DELETE_ERROR,
    LOCATION_ORDER_PROFILE_DELETE_SUCCESS,
    ORDER_PROFILE_DELETE,
    ORDER_PROFILE_DELETE_SUCCESS,
} from "./types";

export function orderProfilesFetch(): OrderProfilesActions {
    return {
        type: ORDER_PROFILES_FETCH,
    };
}

export function orderProfilesFetchSuccess(profiles: OrderProfile[]): OrderProfilesActions {
    return {
        type: ORDER_PROFILES_FETCH_SUCCESS,
        profiles,
    };
}

export function orderProfilesFetchError(fetchError: Error): OrderProfilesActions {
    return {
        type: ORDER_PROFILES_FETCH_ERROR,
        fetchError,
    };
}

export function orderProfileSave(profile: OrderProfile): OrderProfilesActions {
    return {
        type: ORDER_PROFILE_SAVE,
        profile,
    };
}

export function orderProfileSaveSuccess(profile: OrderProfile): OrderProfilesActions {
    return {
        type: ORDER_PROFILE_SAVE_SUCCESS,
        profile,
    };
}

export function orderProfileSaveError(saveError: Error): OrderProfilesActions {
    return {
        type: ORDER_PROFILE_SAVE_ERROR,
        saveError,
    };
}

export function orderProfileFetch(id: string): OrderProfilesActions {
    return {
        type: ORDER_PROFILE_FETCH,
        id,
    };
}

export function orderProfileFetchSuccess(profile: OrderProfile): OrderProfilesActions {
    return {
        type: ORDER_PROFILE_FETCH_SUCCESS,
        profile,
    };
}

export function orderProfileFetchError(fetchError: Error): OrderProfilesActions {
    return {
        type: ORDER_PROFILE_FETCH_ERROR,
        fetchError,
    };
}

export function orderProfileDelete(profile: OrderProfile, callback?: (error?: Error) => void): OrderProfilesActions {
    return {
        type: ORDER_PROFILE_DELETE,
        profile,
        callback,
    };
}

export function orderProfileDeleteSuccess(profile: OrderProfile): OrderProfilesActions {
    return {
        type: ORDER_PROFILE_DELETE_SUCCESS,
        profile,
    };
}

export function locationOrderProfileSave(master: OrderProfile, profile: LocationOrderProfile): OrderProfilesActions {
    return {
        type: LOCATION_ORDER_PROFILE_SAVE,
        master,
        profile,
    };
}

export function locationOrderProfileSaveSuccess(masterId: string, profile: LocationOrderProfile): OrderProfilesActions {
    return {
        type: LOCATION_ORDER_PROFILE_SAVE_SUCCESS,
        masterId,
        profile,
    };
}

export function locationOrderProfileSaveError(saveError: Error): OrderProfilesActions {
    return {
        type: LOCATION_ORDER_PROFILE_SAVE_ERROR,
        saveError,
    };
}

export function locationOrderProfileDelete(master: OrderProfile, profile: LocationOrderProfile): OrderProfilesActions {
    return {
        type: LOCATION_ORDER_PROFILE_DELETE,
        master,
        profile,
    };
}

export function locationOrderProfileDeleteSuccess(
    masterId: string,
    profile: LocationOrderProfile,
): OrderProfilesActions {
    return {
        type: LOCATION_ORDER_PROFILE_DELETE_SUCCESS,
        masterId,
        profile,
    };
}

export function locationOrderProfileDeleteError(
    masterId: string,
    profile: LocationOrderProfile,
    deleteError: Error,
): OrderProfilesActions {
    return {
        type: LOCATION_ORDER_PROFILE_DELETE_ERROR,
        masterId,
        profile,
        deleteError,
    };
}
