import React, { useMemo, useState } from "react";
import { Field, useForm } from "react-final-form";
import { MasterModifier } from "store/mms/menus/master/items/modifiers/types";
import { DataGrid } from "@mui/x-data-grid";
import { search } from "components/common/forms/helpers";
import { QuickSearchToolbar } from "components/common/forms/QuickSearchToolbar";
import { enableTransformTypeEnabled } from "./constants";
import { debounce } from "lodash";
import { searchDebounce } from "services/env";

interface ModifierListProps {
    modifiers: MasterModifier[];
    name: string;
}

export function ModifierList({ modifiers, name }: ModifierListProps): JSX.Element {
    const { change } = useForm();

    const [searchText, setSearchText] = useState("");
    const [rows, setRows] = useState(modifiers);
    const debouncedSearch = useMemo(
        () =>
            debounce((query: string) => {
                search(query, modifiers, setRows);
            }, searchDebounce),
        [modifiers],
    );

    return (
        <Field name={name}>
            {({ input: { onChange, value } }) => (
                <DataGrid
                    rows={rows}
                    columns={[
                        {
                            field: "display_name",
                            headerName: "Display Name",
                            flex: 2,
                        },
                        {
                            field: "reference_name",
                            headerName: "Reference Name",
                            flex: 1,
                        },
                    ]}
                    components={{
                        Toolbar: QuickSearchToolbar,
                    }}
                    componentsProps={{
                        toolbar: {
                            value: searchText,
                            onChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                const query = e.target.value;
                                setSearchText(query);
                                debouncedSearch(query);
                            },
                            clearSearch: () => {
                                setSearchText("");
                                search("", modifiers, setRows);
                            },
                        },
                    }}
                    onSelectionModelChange={(m) => {
                        if (value && Array.isArray(value)) {
                            // If the previously selected rows are no longer in the datagrid's rows, then a filter was
                            // applied. We don't want to lose the previously selected rows, so we'll add them to the
                            // GridSelectionModel (m) before we call onChange.
                            for (const mod_id of value) {
                                if (!rows.some((row) => row.id === mod_id)) {
                                    if (m.indexOf(mod_id) === -1) {
                                        m.push(mod_id);
                                    }
                                }
                            }
                        }

                        onChange(m);
                        if (Array.isArray(m) && m.length > 0) {
                            change(enableTransformTypeEnabled, true);
                        }
                    }}
                    selectionModel={value}
                    sx={{
                        minHeight: "inherit",
                        borderLeft: "unset",
                        borderRight: "unset",
                        borderTop: "unset",
                    }}
                    autoHeight
                    checkboxSelection
                    disableSelectionOnClick
                    disableColumnSelector
                />
            )}
        </Field>
    );
}
