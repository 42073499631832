import React, { useState } from "react";
import {
    Accordion as MuiAccordion,
    AccordionDetails,
    AccordionProps as MuiAccordionProps,
    AccordionSummary,
    Checkbox,
    FormControlLabel,
} from "@mui/material";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import { Field, useFormState } from "react-final-form";
import { getIn } from "final-form";

interface AccordionProps extends Partial<MuiAccordionProps> {
    label: string;
    name: string;
    disableCheckbox?: boolean;
}

export function Accordion({
    children,
    label,
    name,
    disableCheckbox = false,
    ...accordionProps
}: AccordionProps): JSX.Element {
    const { values } = useFormState();
    const [expanded, setExpanded] = useState(getIn(values, name) || false);

    return (
        <MuiAccordion
            expanded={expanded}
            onChange={(e, expanded) => setExpanded(expanded)}
            {...accordionProps}
            sx={{
                borderRight: "unset",
                borderLeft: "unset",

                "&:not(:last-of-type)": {
                    borderBottom: "unset",
                },

                "&.Mui-expanded": {
                    margin: 0,
                },
            }}
            variant="outlined"
            square
        >
            <AccordionSummary expandIcon={<ExpandMoreIcon fontSize="inherit" />}>
                <FormControlLabel
                    label={label}
                    control={
                        <Field
                            name={name}
                            type="checkbox"
                            render={({ input: { name, checked, onChange, value } }) => (
                                <Checkbox
                                    name={name}
                                    checked={checked}
                                    onChange={(e) => {
                                        if (e.target.checked && !expanded) {
                                            setExpanded(true);
                                        }
                                        onChange(e);
                                    }}
                                    value={value}
                                    disabled={disableCheckbox}
                                />
                            )}
                        />
                    }
                    onClick={(event) => event.stopPropagation()}
                    onFocus={(event) => event.stopPropagation()}
                />
            </AccordionSummary>
            <AccordionDetails>{children}</AccordionDetails>
        </MuiAccordion>
    );
}
