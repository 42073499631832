import {
    RestaurantConcept,
    RestaurantConceptActions,
    RESTAURANT_CONCEPT_FETCH,
    RESTAURANT_CONCEPT_FETCH_ERROR,
    RESTAURANT_CONCEPT_FETCH_SUCCESS,
} from "./types";

interface RestaurantConceptsState {
    readonly byId: {
        [key: string]: RestaurantConcept;
    };
    readonly allIds: string[];
    readonly fetching: boolean;
    readonly fetchingError?: Error;
    readonly requests: number;
}

const initialState: RestaurantConceptsState = {
    byId: {},
    allIds: [],
    fetching: false,
    requests: 0,
};

function restaurantConceptsReducer(state = initialState, action: RestaurantConceptActions): RestaurantConceptsState {
    switch (action.type) {
        case RESTAURANT_CONCEPT_FETCH:
            return {
                ...state,
                fetching: true,
                requests: state.requests + 1,
            };

        case RESTAURANT_CONCEPT_FETCH_SUCCESS:
            const concepts = action.restaurant_concepts;
            const newIds = concepts.map((rc) => rc.id);

            return {
                ...state,
                fetching: false,
                byId: {
                    ...state.byId,
                    ...concepts.reduce((allConcepts, rc) => ({ ...allConcepts, [rc.id]: rc.name }), {}),
                },
                allIds: [...state.allIds.filter((id) => !newIds.includes(id)), ...newIds],
            };

        case RESTAURANT_CONCEPT_FETCH_ERROR:
            return {
                ...state,
                fetching: false,
                fetchingError: action.error,
            };

        default:
            return state;
    }
}

export default restaurantConceptsReducer;
