import React from "react";
import { getSession } from "services/session";
import { AxiosResponse } from "axios";
import OmniComplete from "./OmniComplete";
import { ServiceCharge, RevenueCenter } from "./reducer";

interface props {
    serviceChargeId?: string;
    locationId: string;
    setServiceCharge(s: ServiceCharge | null): void;
    error?: boolean;
    errorText?: string;
    disableFetch?: boolean;
}

interface serviceChargeResponse {
    id: string;
    name: string;
    open: boolean;
    pos_id: string;
    price: number | null;
    type: string;
}

const mapToDomain = (r: serviceChargeResponse): ServiceCharge => ({
    id: r.id,
    open: r.open,
    name: r.name,
    posId: r.pos_id,
    price: r.price,
    type: r.type,
    kind: "service_charge",
});

export default function POSServiceChargeSearch(props: props): JSX.Element {
    const s = getSession();
    const { serviceChargeId, disableFetch, locationId, error, errorText = "Required", setServiceCharge } = props;

    return (
        <OmniComplete
            id="service-charge-id"
            entity={serviceChargeId ? ({ id: serviceChargeId } as RevenueCenter) : null}
            error={error}
            helperText={error ? errorText : "Not Found on POS"}
            setEntity={setServiceCharge}
            inputLabel="Service Charge"
            disabled={!locationId}
            disableFetch={disableFetch}
            searchUrl={`/1.1/accounts/${s?.accountId}/locations/${locationId}/mms/pos/service_charges/?search=`}
            searchRespMapper={(
                resp: AxiosResponse<{
                    _embedded: {
                        service_charges: serviceChargeResponse[];
                    };
                }>,
            ) => resp.data._embedded.service_charges.map(mapToDomain)}
            fetchUrl={
                serviceChargeId &&
                `/1.1/accounts/${s?.accountId}/locations/${locationId}/mms/pos/service_charges/${serviceChargeId}`
            }
            fetchRespMapper={(resp: AxiosResponse<serviceChargeResponse>) => mapToDomain(resp.data)}
        />
    );
}
