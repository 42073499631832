import { RenameFieldset } from "components/common/forms/RenameFieldset";
import { FormSubmit } from "components/common/FormSubmit";
import React, { useState } from "react";
import { Form } from "react-final-form";
import { ModifierStrategy } from "store/mms/menus/location/items/modifiers/types";
import { ItemStrategy } from "store/mms/menus/location/items/types";
import { MenuEntity } from "store/mms/menus/types";
import { POSConfig } from "store/mms/types";
import EditPOSConfig from "../EditPOSConfig";

enum pageType {
    Naming,
    DefinePOSMapping,
}

interface ModifierAddNewProps {
    onClose(): void;
    onSave(
        posConfig: POSConfig,
        strategy: ModifierStrategy | ItemStrategy,
        displayName: string,
        referenceName: string,
        sourceLocation: string,
        callback?: (error?: Error) => void,
    ): void;
    traversal: MenuEntity[];
    locationUrl: string;
}

export function ModifierAddNew({ onClose, onSave, traversal, locationUrl }: ModifierAddNewProps): JSX.Element {
    const [page, setPage] = useState<pageType>(pageType.Naming);
    const [names, setNames] = useState({ display_name: "", reference_name: "" });

    return page === pageType.Naming ? (
        <Form
            onSubmit={({ display_name, reference_name }) => {
                setNames({ display_name, reference_name });
                setPage(pageType.DefinePOSMapping);
            }}
            validate={(values) => {
                const errors: { display_name?: string; reference_name?: string } = {};

                if (!values.display_name || !values.display_name.trim()) {
                    errors.display_name = "Required";
                }

                if (!values.reference_name || !values.reference_name.trim()) {
                    errors.reference_name = "Required";
                }

                return errors;
            }}
        >
            {({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                    <RenameFieldset />

                    <FormSubmit onClose={onClose} label="Next: Define POS Mapping" />
                </form>
            )}
        </Form>
    ) : (
        <EditPOSConfig
            isModifier
            posConfig={null}
            strategy={ModifierStrategy.Modify}
            traversal={traversal}
            onClose={onClose}
            onSave={(posConfig, strategy, callback, sourceLocation) =>
                onSave(posConfig, strategy, names.display_name, names.reference_name, sourceLocation || "", callback)
            }
            submitLabel="Create Modifier"
            locationUrl={locationUrl}
        />
    );
}
