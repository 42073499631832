import {
    LinkTime,
    LinkTimesActions,
    LINK_TIMES_PAGE_FETCH,
    LINK_TIMES_PAGE_FETCH_SUCCESS,
    LINK_TIMES_SAVE_SUCCESS,
} from "./types";

interface LinkTimesState {
    readonly byId: { [id: string]: LinkTime };
    readonly fetching: boolean;
}

const initialState: LinkTimesState = {
    byId: {},
    fetching: false,
};

export default function linkTimesReducer(state = initialState, action: LinkTimesActions): LinkTimesState {
    switch (action.type) {
        case LINK_TIMES_PAGE_FETCH:
            return {
                ...state,
                fetching: true,
            };

        case LINK_TIMES_PAGE_FETCH_SUCCESS:
        case LINK_TIMES_SAVE_SUCCESS:
            return {
                ...state,
                byId: {
                    ...action.linkTimes.reduce((allLinkTimes: { [id: string]: LinkTime }, lt) => {
                        allLinkTimes[lt.id] = lt;
                        return allLinkTimes;
                    }, {}),
                },
                fetching: false,
            };

        default:
            return state;
    }
}
