import React from "react";
import { getSession } from "services/session";
import { AxiosResponse } from "axios";
import OmniComplete from "./OmniComplete";
import { Location } from "./reducer";
import { Location as AccountLocation, LocationListResp } from "store/locations/types";

interface props {
    error?: boolean;
    focused?: boolean;
    errorText?: string;
    setLocation(l: Location | null): void;
}

const mapToDomain = (l: AccountLocation): Location => ({
    ...l,
    kind: "location",
});

export default function LocationSearch(props: props): JSX.Element {
    const s = getSession();
    const { focused, error, errorText = "Required", setLocation } = props;

    return (
        <OmniComplete
            focused={focused}
            id="location-id"
            error={error}
            helperText={error ? errorText : undefined}
            entity={null}
            setEntity={setLocation}
            inputLabel="Location"
            searchUrl={`/1.1/accounts/${s?.accountId}/locations/?where=neq(pos_type,"mock")&sort=display_name&search=`}
            searchRespMapper={(resp: AxiosResponse<LocationListResp>) => resp.data._embedded.locations.map(mapToDomain)}
        />
    );
}
