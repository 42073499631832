import {
    FormControl,
    FormLabel,
    MenuItem,
    Tab,
    Tabs,
    TextField,
    Typography,
    Alert,
    Autocomplete,
    AutocompleteInputChangeReason,
    Skeleton,
    Theme,
} from "@mui/material";
import { makeStyles } from "theme";
import { handleErrors } from "components/common/forms/helpers";
import { FormSubmit } from "components/common/FormSubmit";
import { OmniDrawer } from "components/common/OmniDrawer";
import { MasterMenuContext } from "pages/Menus/MasterMenu/context";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Field, Form } from "react-final-form";
import { MasterItem, MasterItemPageResp } from "store/mms/menus/master/items/types";
import debounce from "lodash/debounce";
import { core } from "services/api";
import { AxiosResponse } from "axios";
import { searchDebounce } from "services/env";
import { mapItemResp } from "store/mms/menus/master/items/effects";
import PaperWrapper from "components/common/PaperWrapper";
import { RootState } from "store/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import TabPanel from "components/common/TabPanel";
import { RenameFieldset } from "components/common/forms/RenameFieldset";
import EditPOSConfig from "../EditPOSConfig";
import { ItemStrategy } from "store/mms/menus/location/items/types";
import { POSConfig } from "store/mms/types";
import { Section } from "store/mms/menus/master/sections/types";
import { sectionItemExistingSave, sectionItemNewSave } from "store/mms/menus/master/sections/actions";
import { FORM_ERROR } from "final-form";
import { axiosErrorMessage } from "store/types";

const useStyles = makeStyles()((theme: Theme) => ({
    header: { marginBottom: theme.spacing(4) },
    form: {
        "& .MuiFormControl-root": {
            marginBottom: theme.spacing(4),

            "&:last-of-type": {
                marginBottom: 0,
            },
        },
        "& > fieldset": {
            "& > *": {
                marginBottom: theme.spacing(3),
            },
            "& > *:last-child": {
                marginBottom: 0,
            },
        },
    },
    names: {
        display: "inline-flex",
        justifyContent: "space-between",
        marginBottom: theme.spacing(3),
        width: "100%",

        "& > *": {
            marginBottom: "0 !important",
            flexBasis: "48%",
        },
    },
    submitErrors: { margin: theme.spacing(4, 0) },
}));

interface ItemAddProps {
    onClose(): void;
    open: boolean;
}

export function ItemAdd({ onClose, open }: ItemAddProps): JSX.Element {
    const { menu } = useContext(MasterMenuContext);
    const dispatch = useDispatch();
    const sectionIdsByMenuId = useSelector((s: RootState) => s.mms.menus.master.sections.sectionIdsByMenuId);
    const sectionsById = useSelector((s: RootState) => s.mms.menus.master.sections.byId);
    const sectionIds = menu && sectionIdsByMenuId[menu.id];
    const sections = (sectionIds || []).map((id) => sectionsById[id]);
    const { classes } = useStyles();
    const searchUrl = `${menu && menu._links.items.href}?search=`;
    const [state, setState] = useState<{
        configurationStep: configurationStep;
        options: MasterItem[];
        searching: boolean;
        selectedItem?: MasterItem;
        newItem?: {
            section: Section;
            display_name: string;
            reference_name: string;
            description: string;
        };
        tab: panel;
        query: string;
    }>(initialState);
    const handleSearch = (event: unknown, value: string, reason: AutocompleteInputChangeReason): void => {
        if (reason === "reset") {
            return;
        }

        setState((prevState) => ({ ...prevState, query: value, searching: true }));
    };
    const search = useMemo(
        () =>
            debounce(async (isMounted: () => boolean, query: string) => {
                try {
                    const resp: AxiosResponse<MasterItemPageResp> = await core.get(searchUrl + query);

                    if (!isMounted) {
                        return;
                    }

                    setState((prevState) => ({
                        ...prevState,
                        searching: false,
                        options: resp.data._embedded.items.map(mapItemResp),
                    }));
                } catch (e) {
                    console.error("Failed to search for Menu Modifier Groups", e);
                    setState((prevState) => ({ ...prevState, searching: false }));
                }
            }, searchDebounce),
        [searchUrl],
    );

    useEffect(() => {
        if (!state.searching) {
            return;
        }

        let mounted = true;
        search(() => mounted, state.query);

        return () => {
            mounted = false;
        };
    }, [search, state.query, state.searching]);

    useEffect(() => {
        if (!open) {
            setState(initialState);
        }
    }, [open]);

    return (
        <OmniDrawer close={onClose} open={open} title="Add Menu Item">
            {menu === undefined || sectionIds === undefined ? (
                <Skeleton />
            ) : state.configurationStep === configurationStep.step1 ? (
                <Form
                    initialValues={{ existingItem: null, sectionId: sections[0]?.id }}
                    onSubmit={(values, _, errorsCallback) => {
                        const section = sectionsById[values.sectionId];

                        if (state.tab === panel.Existing) {
                            return dispatch(
                                sectionItemExistingSave(section, values.existingItem, (error?: Error) => {
                                    if (error) {
                                        return (
                                            errorsCallback &&
                                            errorsCallback({
                                                [FORM_ERROR]: axiosErrorMessage(error).replace(/section/gi, "Category"),
                                            })
                                        );
                                    }
                                    onClose();
                                }),
                            );
                        }

                        setState((prevState) => ({
                            ...prevState,
                            newItem: {
                                section,
                                display_name: values.display_name,
                                reference_name: values.reference_name,
                                description: values.description || "",
                            },
                            configurationStep: configurationStep.step2,
                        }));
                    }}
                    validate={(values) => {
                        if (state.tab === panel.Existing) {
                            return { existingItem: !values.existingItem ? "Required" : undefined };
                        }
                        return {
                            display_name: !values.display_name ? "Required" : undefined,
                            reference_name: !values.reference_name ? "Required" : undefined,
                        };
                    }}
                >
                    {({ handleSubmit, submitError }) => (
                        <form onSubmit={handleSubmit} className={classes.form}>
                            <FormControl component="fieldset" fullWidth>
                                <FormLabel>
                                    <Typography variant="h5">
                                        What Category should the Menu Item be added to?
                                    </Typography>
                                </FormLabel>

                                <Field name="sectionId">
                                    {({ input, meta }) => (
                                        <TextField
                                            label="Category"
                                            id="sectionId"
                                            variant="outlined"
                                            {...input}
                                            {...handleErrors(meta)}
                                            select
                                            fullWidth
                                        >
                                            {sections.map((s, idx) => (
                                                <MenuItem value={s.id} key={idx}>
                                                    <Typography variant="inherit" noWrap>
                                                        {s.name}
                                                    </Typography>
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    )}
                                </Field>
                            </FormControl>

                            <FormControl component="fieldset" fullWidth>
                                <FormLabel>
                                    <Typography variant="h5">What kind of Menu Item would you like to add?</Typography>
                                </FormLabel>

                                <Tabs
                                    indicatorColor="secondary"
                                    textColor="primary"
                                    value={state.tab}
                                    onChange={(e, tab: panel) => setState((prevState) => ({ ...prevState, tab }))}
                                >
                                    <Tab value={panel.Existing} label="Existing" {...a11yProps(panel.Existing)} />
                                    <Tab value={panel.New} label="New" {...a11yProps(panel.New)} />
                                </Tabs>

                                <TabPanel index={panel.Existing} value={state.tab}>
                                    <Field name="existingItem">
                                        {({ input: { name, value, onChange, ...restInput }, meta }) => (
                                            <Autocomplete
                                                options={[value, ...state.options]}
                                                getOptionLabel={(o) => {
                                                    if (o && typeof o !== "string") {
                                                        return `${o.display_name}${
                                                            o.reference_name ? ` (${o.reference_name})` : ""
                                                        }`;
                                                    }

                                                    return "";
                                                }}
                                                isOptionEqualToValue={(o, v) => {
                                                    if (typeof o === "string" && typeof v === "string") {
                                                        return o === v;
                                                    } else if (typeof o === "string" && v && typeof v !== "string") {
                                                        return o === v.id;
                                                    } else if (o && typeof o !== "string" && typeof v === "string") {
                                                        return o.id === v;
                                                    } else if (o && v) {
                                                        return o.id === v.id;
                                                    }
                                                    return false;
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        {...restInput}
                                                        name={name}
                                                        variant="outlined"
                                                        {...handleErrors(meta, "Search by Menu Item name")}
                                                        autoFocus
                                                    />
                                                )}
                                                loading={state.searching}
                                                loadingText="Searching..."
                                                noOptionsText="No Menu Item meets your search criteria. Please try again."
                                                onChange={(
                                                    event: React.ChangeEvent<unknown>,
                                                    selectedItem: MasterItem | null,
                                                ) => onChange(selectedItem)}
                                                onInputChange={handleSearch}
                                                filterOptions={() => state.options}
                                                PaperComponent={PaperWrapper}
                                                value={value}
                                                fullWidth
                                            />
                                        )}
                                    </Field>
                                </TabPanel>

                                <TabPanel index={panel.New} value={state.tab}>
                                    <RenameFieldset className={classes.names} />

                                    <Field name="description">
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                {...handleErrors(meta)}
                                                label="Description (Optional)"
                                                variant="outlined"
                                                fullWidth
                                                multiline
                                                rows={4}
                                            />
                                        )}
                                    </Field>
                                </TabPanel>
                            </FormControl>

                            {submitError && (
                                <Alert className={classes.submitErrors} severity="error">
                                    {submitError}
                                </Alert>
                            )}

                            <FormSubmit onClose={onClose} label={state.tab === panel.Existing ? "Add Item" : "Next"} />
                        </form>
                    )}
                </Form>
            ) : (
                <EditPOSConfig
                    posConfig={null}
                    strategy={ItemStrategy.AddItem}
                    traversal={[]}
                    onSave={(pos_config: POSConfig, strategy: ItemStrategy, callback, source_location) => {
                        if (source_location !== undefined && state.newItem !== undefined) {
                            dispatch(
                                sectionItemNewSave(
                                    menu,
                                    state.newItem.section,
                                    {
                                        display_name: state.newItem.display_name,
                                        reference_name: state.newItem.reference_name,
                                        description: state.newItem.description,
                                        enabled: false,
                                        linked: true,
                                        pos_config,
                                        strategy,
                                        source_location,
                                    },
                                    callback,
                                ),
                            );
                        }
                    }}
                    locationUrl={menu._links.source_location.href}
                    submitLabel="Create Item"
                    onClose={onClose}
                    disableForceLocationUpdate
                />
            )}
        </OmniDrawer>
    );
}

enum configurationStep {
    step1,
    step2,
}

enum panel {
    New = 0,
    Existing = 1,
}

const initialState = {
    configurationStep: configurationStep.step1,
    options: [],
    searching: false,
    tab: panel.Existing,
    query: "",
};

function a11yProps(index: number) {
    return {
        id: `tab-${index}`,
        "aria-controls": `tabpanel-${index}`,
    };
}
