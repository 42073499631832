import {
    ReportsState,
    ReportsActions,
    ReportsFiltersState,
    ReportFiltersActions,
    REPORTS_FETCH,
    REPORTS_FETCH_SUCCESS,
    REPORTS_FETCH_ERROR,
    REPORTS_FILTERS_SET_AVAILABLE,
    REPORTS_FILTERS_SET_START_DATETIME,
    REPORTS_FILTERS_SET_END_DATETIME,
    REPORTS_FILTERS_ADD_RESTAURANT_CONCEPT,
    REPORTS_FILTERS_REMOVE_RESTAURANT_CONCEPT,
    REPORTS_FILTERS_RESET_RESTAURANT_CONCEPT,
    REPORTS_FILTERS_ADD_POS_TYPE,
    REPORTS_FILTERS_REMOVE_POS_TYPE,
    REPORTS_FILTERS_RESET_POS_TYPE,
    REPORTS_FILTERS_FETCH,
    REPORTS_FILTERS_FETCH_SUCCESS,
    REPORTS_FILTERS_FETCH_ERROR,
    REPORTS_FILTERS_ADD_LOCATION,
    REPORTS_FILTERS_REMOVE_LOCATION,
} from "./types";
import { format, sub } from "date-fns";

const initialState: ReportsState = {
    posInjectionSuccessRate: {
        success: 0,
        failure: 0,
        total: 0,
        loading: true,
    },
};

export function reportsReducer(state = initialState, action: ReportsActions): ReportsState {
    switch (action.type) {
        case REPORTS_FETCH:
            return {
                ...state,
                posInjectionSuccessRate: {
                    ...state.posInjectionSuccessRate,
                    loading: true,
                },
            };
        case REPORTS_FETCH_SUCCESS:
            return {
                ...state,
                posInjectionSuccessRate: {
                    ...action.payload,
                    loading: false,
                },
            };
        case REPORTS_FETCH_ERROR:
            return {
                ...state,
                posInjectionSuccessRate: {
                    failure: 0,
                    success: 0,
                    total: 0,
                    loading: false,
                },
            };
        default:
            return state;
    }
}

const initialFiltersState: ReportsFiltersState = {
    applied: {
        Locations: [],
        RestaurantConcepts: [],
        POSTypes: [],
    },
    available: {
        RestaurantConcepts: [],
        POSTypes: [],
    },
    start: format(sub(new Date(new Date().toUTCString().substring(0, 26)), { weeks: 1 }), "yyyy-MM-dd HH:mm:ss"),
    end: format(new Date(new Date().toUTCString().substring(0, 26)), "yyyy-MM-dd HH:mm:ss"),
    loading: true,
};

export function reportFiltersReducer(state = initialFiltersState, action: ReportFiltersActions): ReportsFiltersState {
    switch (action.type) {
        case REPORTS_FILTERS_FETCH:
            return {
                ...state,
                loading: true,
            };
        case REPORTS_FILTERS_FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case REPORTS_FILTERS_FETCH_ERROR:
            return {
                ...state,
                loading: false,
            };
        case REPORTS_FILTERS_SET_AVAILABLE:
            return {
                ...state,
                available: {
                    ...action.payload,
                },
            };
        case REPORTS_FILTERS_SET_START_DATETIME:
            return {
                ...state,
                start: action.payload,
            };
        case REPORTS_FILTERS_SET_END_DATETIME:
            return {
                ...state,
                end: action.payload,
            };
        case REPORTS_FILTERS_ADD_RESTAURANT_CONCEPT:
            return {
                ...state,
                applied: {
                    ...state.applied,
                    RestaurantConcepts: [action.payload, ...state.applied.RestaurantConcepts],
                },
            };
        case REPORTS_FILTERS_REMOVE_RESTAURANT_CONCEPT:
            return {
                ...state,
                applied: {
                    ...state.applied,
                    RestaurantConcepts: state.applied.RestaurantConcepts.filter((c) => c.id !== action.payload.id),
                },
            };
        case REPORTS_FILTERS_RESET_RESTAURANT_CONCEPT:
            return {
                ...state,
                applied: {
                    ...state.applied,
                    RestaurantConcepts: [],
                },
            };
        case REPORTS_FILTERS_ADD_LOCATION:
            return {
                ...state,
                applied: {
                    ...state.applied,
                    Locations: [...state.applied.Locations, action.payload],
                },
            };
        case REPORTS_FILTERS_REMOVE_LOCATION:
            return {
                ...state,
                applied: {
                    ...state.applied,
                    Locations: state.applied.Locations.filter((loc) => loc.id !== action.payload.id),
                },
            };
        case REPORTS_FILTERS_ADD_POS_TYPE:
            return {
                ...state,
                applied: {
                    ...state.applied,
                    POSTypes: [action.payload, ...state.applied.POSTypes],
                },
            };
        case REPORTS_FILTERS_REMOVE_POS_TYPE:
            return {
                ...state,
                applied: {
                    ...state.applied,
                    POSTypes: state.applied.POSTypes.filter((p) => p.id !== action.payload.id),
                },
            };
        case REPORTS_FILTERS_RESET_POS_TYPE:
            return {
                ...state,
                applied: {
                    ...state.applied,
                    POSTypes: [],
                },
            };
        default:
            return state;
    }
}
