import React, { ReactNode, useState } from "react";
import { Grid, InputAdornment, MenuItem, Select, TextField } from "@mui/material";
import { faSearch } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export enum MenuFilterType {
    Categories = "categories",
    Items = "items",
}

export const MenuFilterContext = React.createContext({ query: "", type: MenuFilterType.Items });

interface MenuFilterProps {
    children: ReactNode;
    className?: string;
}

export function MenuFilter({ children, className }: MenuFilterProps): JSX.Element {
    const [state, setState] = useState({ query: "", type: MenuFilterType.Items });

    const handleTypeChange = (type: MenuFilterType): void => setState((prevState) => ({ ...prevState, type }));

    const handleOnChange = (q: string) => {
        setState((prevState) => ({ ...prevState, query: q }));
    };

    return (
        <MenuFilterContext.Provider value={state}>
            <Grid container justifyContent="space-between" spacing={1} className={className}>
                <Grid item xs={8} md={10}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Search..."
                        type="search"
                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                            handleOnChange(e.target.value)
                        }
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <FontAwesomeIcon size="xs" icon={faSearch} />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid item xs={4} md={2}>
                    <Select
                        fullWidth
                        value={state.type}
                        variant="outlined"
                        data-testid="filter-type"
                        onChange={(e) => handleTypeChange(e.target.value as MenuFilterType)}
                    >
                        <MenuItem value={MenuFilterType.Categories}>Categories</MenuItem>
                        <MenuItem value={MenuFilterType.Items}>Items</MenuItem>
                    </Select>
                </Grid>
            </Grid>
            {children}
        </MenuFilterContext.Provider>
    );
}
