import { MenuItem, SxProps, TextField, Theme, Typography } from "@mui/material";
import React, { ChangeEvent } from "react";
import { LocationMenu } from "store/mms/menus/location/types";
import { MenuLayerRuleSet } from "store/mms/menus/master/layers/rule-sets/types";
import { MasterMenu } from "store/mms/menus/master/types";

interface EntitySelectorProps {
    entities: MenuLayerRuleSet[] | MasterMenu[] | LocationMenu[];
    selectedId: string;
    onChange: (selectedId: string) => void;
    sx?: SxProps<Theme>;
}

export const EntitySelector = ({ entities, selectedId, onChange, sx = [] }: EntitySelectorProps): JSX.Element => {
    return (
        <TextField
            sx={sx}
            select
            fullWidth
            value={selectedId}
            margin="dense"
            color="primary"
            variant="standard"
            onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => onChange(e.target.value)}
        >
            {entities.map((entity) => (
                <MenuItem value={entity.id} key={entity.id}>
                    <Typography display="inline" variant="h5">
                        {entity.name}
                    </Typography>
                </MenuItem>
            ))}
        </TextField>
    );
};
