export const RESTAURANT_CONCEPT_FETCH = "@@ADMINMASTER/RESTAURANT_CONCEPT/FETCH";
export const RESTAURANT_CONCEPT_FETCH_SUCCESS = "@@ADMINMASTER/RESTAURANT_CONCEPT/FETCH_SUCCESS";
export const RESTAURANT_CONCEPT_FETCH_ERROR = "@@ADMINMASTER/RESTAURANT_CONCEPT/FETCH_ERROR";

export interface RestaurantConcept {
    id: string;
    name: string;
}

export interface RestaurantConceptsFetchResp {
    _embedded: {
        restaurant_concepts: RestaurantConcept[];
    };
}

export type RestaurantConceptsFetchReq = Omit<RestaurantConcept, "_embedded">;

interface RestaurantConceptsFetch {
    type: typeof RESTAURANT_CONCEPT_FETCH;
    url: string;
}

interface RestaurantConceptsFetchSuccess {
    type: typeof RESTAURANT_CONCEPT_FETCH_SUCCESS;
    restaurant_concepts: RestaurantConcept[];
}

interface RestaurantConceptsFetchError {
    type: typeof RESTAURANT_CONCEPT_FETCH_ERROR;
    error: Error;
}

export type RestaurantConceptActions =
    | RestaurantConceptsFetch
    | RestaurantConceptsFetchSuccess
    | RestaurantConceptsFetchError;
