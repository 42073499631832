import {
    Alert,
    Autocomplete,
    AutocompleteInputChangeReason,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Radio,
    TextField,
    Typography,
    Theme,
} from "@mui/material";
import { makeStyles } from "theme";
import React, { useMemo } from "react";
import { useState } from "react";
import { getSession } from "services/session";
import {
    ModifierGroup,
    ModifierGroupPageResp,
    NewModifierGroup,
} from "store/mms/menus/master/items/option-sets/modifier-group/types";
import debounce from "lodash/debounce";
import { core } from "services/api";
import { AxiosResponse } from "axios";
import { searchDebounce } from "services/env";
import { mapModifierGroupPageRespToDomain } from "store/mms/menus/master/items/option-sets/modifier-group/effects";
import PaperWrapper from "components/common/PaperWrapper";
import { useEffect } from "react";
import { Field, Form } from "react-final-form";
import { FormSubmit } from "components/common/FormSubmit";
import { axiosErrorMessage } from "store/types";
import { Decorator, FORM_ERROR } from "final-form";
import { default as createSubmitListenerDecorator } from "final-form-submit-listener";
import { handleErrors } from "components/common/forms/helpers";

const s = getSession();
const useStyles = makeStyles()((theme: Theme) => ({
    fieldset: {
        "& > *": {
            marginBottom: theme.spacing(2),
        },
        "& > *:last-child": {
            marginBottom: 0,
        },
    },
}));

interface ModifierGroupAddProps {
    menuId: string;
    onClose(): void;
    onSubmit(group: ModifierGroup | NewModifierGroup, callback?: (error?: Error) => void): void;
}

enum groupType {
    New = "new",
    Existing = "existing",
}

interface formValues {
    groupType: groupType;
    existingGroup: ModifierGroup | null;
    display_name?: string | null;
    reference_name?: string | null;
}

export function ModifierGroupAdd({ menuId, onClose, onSubmit }: ModifierGroupAddProps): JSX.Element {
    const { classes } = useStyles();
    const searchUrl = `/1.1/accounts/${s?.accountId}/mms/menus/${menuId}/modifier_groups?search=`;
    const [state, setState] = useState<{
        options: ModifierGroup[];
        searching: boolean;
        selectedGroup?: ModifierGroup;
        query: string;
    }>({
        options: [],
        searching: false,
        query: "",
    });
    const search = useMemo(
        () =>
            debounce(async (isMounted: () => boolean, query: string) => {
                try {
                    const resp: AxiosResponse<ModifierGroupPageResp> = await core.get(searchUrl + query);

                    if (!isMounted) {
                        return;
                    }

                    setState((prevState) => ({
                        ...prevState,
                        searching: false,
                        options: mapModifierGroupPageRespToDomain(resp.data),
                    }));
                } catch (e) {
                    console.error("Failed to search for Menu Modifier Groups", e);
                    setState((prevState) => ({ ...prevState, searching: false }));
                }
            }, searchDebounce),
        [searchUrl],
    );
    const submitListener = useMemo(
        () =>
            createSubmitListenerDecorator({
                afterSubmitSucceeded: onClose,
            }),

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );
    const handleSearch = (event: unknown, value: string, reason: AutocompleteInputChangeReason): void => {
        if (reason === "reset") {
            return;
        }

        setState((prevState) => ({ ...prevState, query: value, searching: true }));
    };
    const handleSubmitError = (error?: Error) => {
        if (error !== undefined) {
            return { [FORM_ERROR]: `Saving failed with the following message: ${axiosErrorMessage(error)}` };
        }
    };

    useEffect(() => {
        if (!state.searching) {
            return;
        }

        let mounted = true;
        search(() => mounted, state.query);

        return () => {
            mounted = false;
        };
    }, [search, state.query, state.searching]);

    return (
        <Form
            initialValues={{ groupType: groupType.Existing, existingGroup: null }}
            onSubmit={(values, form, errorsCallback) => {
                const group =
                    values.groupType === groupType.New
                        ? ({
                              display_name: values.display_name || "",
                              reference_name: values.reference_name || "",
                          } as NewModifierGroup)
                        : (values.existingGroup as ModifierGroup);
                onSubmit(group, (error?: Error) => {
                    if (errorsCallback !== undefined) {
                        errorsCallback(handleSubmitError(error));
                    }
                });
            }}
            validate={(values: formValues) => {
                const errors: { existingGroup?: string; display_name?: string; reference_name?: string } = {};
                if (values.groupType === "new") {
                    if (!values.display_name) {
                        errors.display_name = "Required";
                    }

                    if (!values.reference_name) {
                        errors.reference_name = "Required";
                    }
                } else {
                    if (!values.existingGroup) {
                        errors.existingGroup = "Required";
                    }
                }
                return errors;
            }}
            decorators={[submitListener as Decorator<formValues, formValues>]}
        >
            {({ handleSubmit, form: { change }, submitError }) => (
                <form onSubmit={handleSubmit}>
                    <FormControl className={classes.fieldset} component="fieldset" fullWidth>
                        <FormLabel>
                            <Typography variant="h5">What type of Modifier Group do you want to add?</Typography>
                        </FormLabel>

                        <FormControlLabel
                            value="existing"
                            control={
                                <Field name="groupType" type="radio">
                                    {({ input: { name, value, onChange, checked, ...restInput } }) => (
                                        <Radio
                                            name={name}
                                            value={value}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                change("display_name", null);
                                                change("reference_name", null);
                                                onChange(event);
                                            }}
                                            checked={checked}
                                            inputProps={{ ...restInput }}
                                        />
                                    )}
                                </Field>
                            }
                            label="Existing Modifier Group"
                        />

                        <Field name="existingGroup">
                            {({ input: { name, value, onChange, ...restInput }, meta }) => (
                                <Autocomplete
                                    options={state.options}
                                    getOptionLabel={(o) => {
                                        if (o) {
                                            return `${o.display_name}${
                                                o.reference_name ? ` (${o.reference_name})` : ""
                                            }`;
                                        }

                                        return "";
                                    }}
                                    isOptionEqualToValue={(o, v) => o && v && o.id === v.id}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            {...restInput}
                                            name={name}
                                            onFocus={() => change("groupType", groupType.Existing)}
                                            variant="outlined"
                                            error={meta.error && meta.touched}
                                            helperText={
                                                meta.error && meta.touched
                                                    ? meta.error
                                                    : "Search by Modifier Group name"
                                            }
                                            autoFocus
                                        />
                                    )}
                                    noOptionsText="No Modifier Groups meet your search criteria. Please try again."
                                    onChange={(
                                        event: React.ChangeEvent<unknown>,
                                        selectedGroup: ModifierGroup | null,
                                    ) => onChange(selectedGroup)}
                                    onInputChange={handleSearch}
                                    PaperComponent={PaperWrapper}
                                    value={value}
                                    fullWidth
                                />
                            )}
                        </Field>

                        <FormControlLabel
                            value="new"
                            control={
                                <Field name="groupType" type="radio">
                                    {({ input: { name, value, onChange, checked, ...restInput } }) => (
                                        <Radio
                                            name={name}
                                            value={value}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                change("existingGroup", null);
                                                onChange(event);
                                            }}
                                            checked={checked}
                                            inputProps={{ ...restInput }}
                                        />
                                    )}
                                </Field>
                            }
                            label="New Modifier Group"
                        />

                        <Grid container justifyContent="space-between" spacing={2}>
                            <Grid item xs={6}>
                                <Field name="display_name">
                                    {({ input, meta }) => (
                                        <TextField
                                            {...input}
                                            {...handleErrors(meta, "Customers will see this name on the Menu.")}
                                            label="Display Name"
                                            onFocus={() => change("groupType", groupType.New)}
                                            variant="outlined"
                                            fullWidth
                                        />
                                    )}
                                </Field>
                            </Grid>

                            <Grid item xs={6}>
                                <Field name="reference_name">
                                    {({ input, meta }) => (
                                        <TextField
                                            {...input}
                                            {...handleErrors(meta, "Internal use only — will not appear on the Menu.")}
                                            variant="outlined"
                                            label="Reference Name"
                                            onFocus={() => change("groupType", groupType.New)}
                                            fullWidth
                                        />
                                    )}
                                </Field>
                            </Grid>
                        </Grid>
                    </FormControl>

                    {submitError && (
                        <Alert variant="outlined" severity="error">
                            {submitError}
                        </Alert>
                    )}

                    <FormSubmit onClose={onClose} label="Add Modifier Group" />
                </form>
            )}
        </Form>
    );
}
