import React, { useEffect, useMemo, useState } from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    LinearProgress,
    Typography,
} from "@mui/material";
import { DataGrid, GridCellParams, GridOverlay, GridSortDirection } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { orderProfileFetch, locationOrderProfileDelete } from "store/mms/order-profiles/actions";
import { RootState } from "store/rootReducer";
import { Skeleton } from "@mui/material";
import { ListPerPageOptions } from "components/props";
import { LocationOrderProfile } from "store/mms/order-profiles/types";
import EditLocationOrderProfile from "./EditLocationOrderProfile";
import { OmniDrawer } from "components/common/OmniDrawer";
import { QuickSearchToolbar } from "components/common/forms/QuickSearchToolbar";
import { search } from "components/common/forms/helpers";
import { searchDebounce } from "services/env";
import { debounce } from "lodash";
import { EntityEdit } from "components/mms/IconButtons/EntityEdit";
import { EntityRemove } from "components/mms/IconButtons/EntityRemove";
import { ActionButton } from "components/common/buttons/ActionButton";

interface props {
    profileId: string;
}

export default function LocationOrderProfileList(props: props): JSX.Element {
    const dispatch = useDispatch();
    const {
        fetching,
        selectedProfile: profile,
        fetchError: error,
    } = useSelector((state: RootState) => state.mms.orderProfiles);
    const [editLocationProfile, setEditLocationProfile] = useState<{ profile: LocationOrderProfile | undefined }>({
        profile: undefined,
    });
    const [deleteProfile, setDeleteProfile] = useState<LocationOrderProfile | undefined>();
    const profileId = props.profileId;
    const isLoading = fetching && !error && profile?.id !== profileId;

    const lops = useMemo(() => profile?.locationOrderProfiles || [], [profile]);

    const [searchText, setSearchText] = useState("");
    const [rows, setRows] = useState(lops);
    useEffect(() => setRows(lops), [lops]);
    const debouncedSearch = useMemo(
        () =>
            debounce((query: string) => {
                search(query, lops, setRows, ["location.id", "location.name"]);
            }, searchDebounce),
        [lops],
    );

    // Note: The Master Order profile should be doing the fetching. But, this is a fall back, just in case.
    useEffect(() => {
        if (!fetching && !error && profile?.id !== profileId) {
            dispatch(orderProfileFetch(profileId));
        }
    }, [dispatch, fetching, profileId, profile, error]);

    return (
        <Box sx={{ display: "flex", flex: "1 0 auto" }}>
            <DataGrid
                autoHeight
                disableSelectionOnClick
                loading={fetching}
                rows={rows.map((p) => ({
                    id: p.location.id,
                    profileId: p.id || "",
                    status: p.linked,
                    name: p.location.name,
                }))}
                sortModel={[
                    {
                        field: "name",
                        sort: "asc" as GridSortDirection,
                    },
                ]}
                components={{
                    Toolbar: QuickSearchToolbar,
                    LoadingOverlay: function CustomLoadingOverlay() {
                        return (
                            <GridOverlay>
                                <div style={{ position: "absolute", top: 0, width: "100%" }}>
                                    <LinearProgress />
                                </div>
                            </GridOverlay>
                        );
                    },
                }}
                componentsProps={{
                    toolbar: {
                        value: searchText,
                        onChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                            const query = e.target.value;
                            setSearchText(query);
                            debouncedSearch(query);
                        },
                        clearSearch: () => {
                            setSearchText("");
                            search("", lops, setRows);
                        },
                        title: (
                            <Grid container justifyContent="space-between">
                                <Grid item>
                                    <Typography sx={{ marginBottom: (theme) => theme.spacing(4) }} variant="h5">
                                        {isLoading ? <Skeleton width="155" /> : "Locations"}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="subtitle1">
                                        {isLoading ? (
                                            <Skeleton width={125} />
                                        ) : (
                                            <ActionButton
                                                label="Link Location"
                                                onClick={() =>
                                                    setEditLocationProfile({ profile: {} as LocationOrderProfile })
                                                }
                                                sx={{ marginRight: (theme) => theme.spacing(2) }}
                                            />
                                        )}
                                    </Typography>
                                </Grid>
                            </Grid>
                        ),
                    },
                }}
                columns={[
                    /* https://omnivoreio.atlassian.net/browse/MERCH-269
                        * This column is hidden until we fully work out and develop the link status.
                    {
                        field: "status",
                        headerName: "Status",
                        headerAlign: "center",
                        width: 60,
                        flex: 0.6,
                        renderCell: function renderStatus(params: GridCellParams) {
                            const linked = params.value as boolean;

                            return (
                                <Tooltip
                                    arrow
                                    title={
                                        <Typography variant="subtitle2">
                                            Order Profile {linked ? "Linked" : "Unlinked"}.
                                        </Typography>
                                    }
                                >
                                    <div className={classes.iconWrapper}>
                                        <FontAwesomeIcon
                                            size="lg"
                                            icon={linked ? faCheckCircle : faExclamationTriangle}
                                            fixedWidth
                                            color={linked ? theme.palette.success.main : theme.palette.warning.main}
                                        />
                                    </div>
                                </Tooltip>
                            );
                        },
                        cellClassName: "status-cell",
                        filterable: false,
                    },*/
                    {
                        field: "name",
                        headerName: "Name",
                        width: 560,
                        flex: 4,
                        disableColumnMenu: true,
                        resizable: false,
                    },
                    {
                        field: "id",
                        headerName: "ID",
                        width: 190,
                        flex: 1,
                        sortable: false,
                        resizable: false,
                        disableColumnMenu: true,
                    },
                    {
                        field: "actions",
                        headerClassName: "hidden",
                        sortable: false,
                        resizable: false,
                        disableColumnMenu: true,
                        width: 190,
                        flex: 1,
                        align: "right",
                        renderCell: function renderActions(params: GridCellParams) {
                            return (
                                <>
                                    <EntityEdit
                                        onClick={() =>
                                            setEditLocationProfile({
                                                profile: profile?.locationOrderProfiles.find(
                                                    (p) => p.id === params.row.profileId,
                                                ),
                                            })
                                        }
                                        title="Update Order Profile"
                                    />

                                    <EntityRemove
                                        onClick={() =>
                                            setDeleteProfile(
                                                profile?.locationOrderProfiles.find(
                                                    (p) => p.id === params.row.profileId,
                                                ),
                                            )
                                        }
                                        title="Remove Order Profile"
                                    />
                                </>
                            );
                        },
                        cellClassName: "actions-cell",
                        filterable: false,
                    },
                ]}
                rowsPerPageOptions={ListPerPageOptions}
                sx={{
                    minHeight: "inherit",
                    borderLeft: "unset",
                    borderRight: "unset",
                    borderTop: "unset",
                    "& .actions-cell": {
                        "& > div": {
                            cursor: "pointer",
                        },
                    },
                    "& .hidden": {
                        visibility: "hidden",
                    },
                }}
            />

            <OmniDrawer
                open={editLocationProfile.profile !== undefined}
                close={() => setEditLocationProfile({ profile: undefined })}
                title="Update Order Profile"
            >
                <EditLocationOrderProfile
                    masterProfile={profile}
                    profile={editLocationProfile.profile}
                    close={() => setEditLocationProfile({ profile: undefined })}
                />
            </OmniDrawer>

            <Dialog
                open={deleteProfile !== undefined}
                keepMounted
                onClose={() => setDeleteProfile(undefined)}
                aria-labelledby="delete-dialog-title"
                aria-describedby="delete-dialog-description"
            >
                <DialogTitle id="delete-dialog-title">Delete Location Order Profile</DialogTitle>
                <DialogContent>
                    <DialogContentText id="delete-dialog-description">
                        {`Are you sure you want to delete the Order Profile for ${deleteProfile?.location.name}?`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            if (!profile || !deleteProfile) {
                                return setDeleteProfile(undefined);
                            }
                            dispatch(locationOrderProfileDelete(profile, deleteProfile));
                            setDeleteProfile(undefined);
                        }}
                        variant="contained"
                        color="primary"
                    >
                        Delete Profile
                    </Button>
                    <Button onClick={() => setDeleteProfile(undefined)} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
