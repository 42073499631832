import format from "date-fns/format";

export function capitalize(s: string): string {
    return s.charAt(0).toUpperCase() + s.slice(1);
}

const currencyFormatter = new Intl.NumberFormat("en-us", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
});

export function money(price?: number | null): string {
    return currencyFormatter.format((price || 0) / 100);
}

export function dateTimeStringFormatter(d: Date): string {
    return format(d, "yyyy-MM-dd HH:mm:ss");
}

export function dateStringFormatter(d: Date): string {
    return format(d, "yyyy-MM-dd");
}

export function dateTimePMStringFormatter(d: Date): string {
    return format(d, "yyyy-MM-dd p");
}
