import { call, ForkEffect, put, debounce, takeEvery, takeLatest } from "redux-saga/effects";
import { core } from "services/api";
import { getSession } from "services/session";
import {
    locationOrderProfileDeleteError,
    locationOrderProfileDeleteSuccess,
    locationOrderProfileSaveError,
    locationOrderProfileSaveSuccess,
    orderProfileDeleteSuccess,
    orderProfileFetchError,
    orderProfileFetchSuccess,
    orderProfileSaveError,
    orderProfileSaveSuccess,
    orderProfilesFetchError,
    orderProfilesFetchSuccess,
} from "./actions";
import {
    OrderProfile,
    LocationOrderProfile,
    ORDER_PROFILES_FETCH,
    ORDER_PROFILE_FETCH,
    ORDER_PROFILE_SAVE,
    LOCATION_ORDER_PROFILE_SAVE,
    LOCATION_ORDER_PROFILE_DELETE,
    LocationOrderProfileResp,
    OrderProfileResp,
    OrderProfilesPageResp,
    ORDER_PROFILE_DELETE,
    LocationOrderProfileReq,
} from "./types";

const mapLOPToDomain = (lop: LocationOrderProfileResp): LocationOrderProfile => ({
    id: lop.id,
    autoClose: lop.auto_close,
    autoSend: lop.auto_send,
    linked: lop.linked,
    posConfig: {
        employeeId: lop.pos_config.employee_id,
        orderTypeId: lop.pos_config.order_type_id,
        revenueCenterId: lop.pos_config.revenue_center_id,

        adjustmentId: lop.pos_config.adjustment_id,
        tenderTypeId: lop.pos_config.tender_type_id,
        itemOrderModesId: lop.pos_config.item_order_mode_id,
        accountNumber: lop.pos_config.account_number,
    },
    ticketConfig: {
        holdViaReadyTimeAddition: lop.ticket_config.hold_via_ready_time_addition,
        omitPriceLevel: lop.ticket_config.omit_price_level,
        omitReadyTime: lop.ticket_config.omit_ready_time,
        guestCount: lop.ticket_config.guest_count,
    },
    location: lop._embedded.location,
});
const mapOPToDomain = (profile: OrderProfileResp): OrderProfile => ({
    id: profile.id,
    name: profile.name,
    type: profile.type,
    locations: profile.stats.locations,
    adjustment: {
        amount: profile.adjustment_amount,
        enabled: profile.adjustment_enabled,
        strategy: profile.adjustment_strategy,
        type: profile.adjustment_type,
    },
    locationOrderProfiles: profile._embedded.location_order_profiles.map(mapLOPToDomain),
    _links: profile._links,
});

function* orderProfilesFetch() {
    try {
        const s = getSession();
        const results: { data: OrderProfilesPageResp } = yield call(
            core.get,
            `/1.1/accounts/${s?.accountId}/mms/order_profiles`,
        );

        yield put(orderProfilesFetchSuccess(results.data._embedded.order_profiles.map(mapOPToDomain)));
    } catch (e) {
        console.error("Failed to fetch order profiles", e);
        if (e instanceof Error) {
            yield put(orderProfilesFetchError(e));
        }
    }
}

function* orderProfileSave({ profile }: { profile: OrderProfile; type: typeof ORDER_PROFILE_SAVE }) {
    try {
        const s = getSession();
        const results: { data: OrderProfileResp } = yield call(
            profile.id ? core.put : core.post,
            `/1.1/accounts/${s?.accountId}/mms/order_profiles${profile.id ? "/" + profile.id : ""}`,
            {
                id: profile.id,
                name: profile.name,
                type: profile.type,
                adjustment_amount: profile.adjustment.amount,
                adjustment_enabled: profile.adjustment.enabled,
                adjustment_strategy: profile.adjustment.strategy,
                adjustment_type: profile.adjustment.type,
            },
        );

        yield put(orderProfileSaveSuccess(mapOPToDomain(results.data)));
    } catch (e) {
        console.error("Failed to save order profile", e);
        if (e instanceof Error) {
            yield put(orderProfileSaveError(e));
        }
    }
}

function* orderProfileDelete({
    profile,
    callback,
}: {
    profile: OrderProfile;
    callback?: (error?: Error) => void;
    type: typeof ORDER_PROFILE_DELETE;
}) {
    try {
        const resp: { data: OrderProfileResp } = yield call(core.delete, profile._links.self.href);

        if (callback !== undefined) {
            yield call(callback);
        }
        yield put(orderProfileDeleteSuccess(mapOPToDomain(resp.data)));
    } catch (e) {
        console.error("Failed to delete order profile", e);
        if (e instanceof Error && callback !== undefined) {
            yield call(callback, e);
        }
    }
}

function* locationOrderProfileSave({
    master,
    profile,
}: {
    master: OrderProfile;
    profile: LocationOrderProfile;
    type: typeof LOCATION_ORDER_PROFILE_SAVE;
}) {
    try {
        if (!master.id) {
            throw Error("Unexpected result; Location Order Profile save can not proceed without Master Profile ID.");
        }

        const s = getSession();
        let locationOrderProfile: LocationOrderProfileReq = {
            id: profile.id,
            auto_close: profile.autoClose,
            auto_send: profile.autoSend,
            linked: profile.linked,
            pos_config: {
                adjustment_id: profile.posConfig.adjustmentId,
                employee_id: profile.posConfig.employeeId,
                order_type_id: profile.posConfig.orderTypeId,
                revenue_center_id: profile.posConfig.revenueCenterId,
                tender_type_id: profile.posConfig.tenderTypeId,
                account_number: profile.posConfig.accountNumber,
            },
            ticket_config: {
                hold_via_ready_time_addition: profile.ticketConfig.holdViaReadyTimeAddition,
                omit_price_level: profile.ticketConfig.omitPriceLevel,
                omit_ready_time: profile.ticketConfig.omitReadyTime,
                guest_count: profile.ticketConfig.guestCount,
            },
            location: profile.location.id,
        };

        // Only include item_order_mode_id if it was set
        if (profile.posConfig.itemOrderModesId !== "") {
            locationOrderProfile = {
                ...locationOrderProfile,
                pos_config: {
                    ...locationOrderProfile.pos_config,
                    item_order_mode_id: profile.posConfig.itemOrderModesId,
                },
            };
        }

        const results: { data: LocationOrderProfileResp } = yield call(
            profile.id ? core.put : core.post,
            `/1.1/accounts/${s?.accountId}/mms/order_profiles/${master.id}/locations${
                profile.id ? "/" + profile.id : ""
            }`,
            locationOrderProfile,
        );

        yield put(locationOrderProfileSaveSuccess(master.id, mapLOPToDomain(results.data)));
    } catch (e) {
        console.error("Failed to save location order profile", e);
        if (e instanceof Error) {
            yield put(locationOrderProfileSaveError(e));
        }
    }
}

function* locationOrderProfileDelete({
    master,
    profile,
}: {
    master: OrderProfile;
    profile: LocationOrderProfile;
    type: typeof LOCATION_ORDER_PROFILE_DELETE;
}) {
    if (!master.id || !profile.id) {
        throw Error(
            "Unexpected result; Location Order Profile delete can not proceed without a Master Profile ID and Location Order Profile ID.",
        );
    }
    try {
        const s = getSession();
        const results: { data: LocationOrderProfileResp } = yield call(
            core.delete,
            `/1.1/accounts/${s?.accountId}/mms/order_profiles/${master.id}/locations/${profile.id}`,
        );

        yield put(locationOrderProfileDeleteSuccess(master.id, mapLOPToDomain(results.data)));
    } catch (e) {
        console.error("Failed to delete location order profile", e);
        if (e instanceof Error) {
            yield put(locationOrderProfileDeleteError(master.id, profile, e));
        }
    }
}

function* orderProfileFetch({ id }: { id: string; type: typeof ORDER_PROFILE_FETCH }) {
    try {
        const s = getSession();
        const result: { data: OrderProfileResp } = yield call(
            core.get,
            `/1.1/accounts/${s?.accountId}/mms/order_profiles/${id}`,
        );

        yield put(orderProfileFetchSuccess(mapOPToDomain(result.data)));
    } catch (e) {
        console.error(`Failed to fetch order profile id: ${id}`, e);
        if (e instanceof Error) {
            yield put(orderProfileFetchError(e));
        }
    }
}

export function* orderProfilesSaga(): Generator<ForkEffect<never>, void, unknown> {
    yield debounce(500, ORDER_PROFILES_FETCH, orderProfilesFetch);
    yield takeEvery(ORDER_PROFILE_SAVE, orderProfileSave);
    yield takeEvery(ORDER_PROFILE_DELETE, orderProfileDelete);
    yield takeEvery(LOCATION_ORDER_PROFILE_SAVE, locationOrderProfileSave);
    yield takeEvery(LOCATION_ORDER_PROFILE_DELETE, locationOrderProfileDelete);
    yield takeLatest(ORDER_PROFILE_FETCH, orderProfileFetch);
}
