import { LocationModifier } from "store/mms/menus/location/items/modifiers/types";
import { Nutrition } from "store/mms/types";
import { HALEntity, ListRoute } from "store/types";
import { OptionSet } from "../option-sets/types";

export const MASTER_MODIFIER_FETCH = "@@ADMINMASTER/MODIFIER/FETCH";
export const MASTER_MODIFIER_FETCH_SUCCESS = "@@ADMINMASTER/MODIFIER/FETCH_SUCCESS";
export const MASTER_MODIFIER_FETCH_ERROR = "@@ADMINMASTER/MODIFIER/FETCH_ERROR";

export const MASTER_MODIFIER_SAVE = "@@ADMINMASTER/MODIFIER/SAVE";
export const MASTER_MODIFIER_SAVE_SUCCESS = "@@ADMINMASTER/MODIFIER/SAVE_SUCCESS";
export const MASTER_MODIFIER_SAVE_ERROR = "@@ADMINMASTER/MODIFIER/SAVE_ERROR";

export const MASTER_MODIFIER_OPTION_SETS_SAVE = "@@ADMINMASTER/MODIFIER_OPTION_SETS/SAVE";
export const MASTER_MODIFIER_OPTION_SETS_SAVE_ERROR = "@@ADMINMASTER/MODIFIER_OPTION_SETS/SAVE_ERROR";

export const MASTER_MODIFIER_OPTION_SET_DELETE_SUCCESS = "@@ADMINMASTER/MODIFIER_OPTION_SET/DELETE_SUCCESS";

export const MASTER_MODIFIERS_HYDRATE = "@@ADMINMASTER/MODIFIERS/HYDRATE";
export const MASTER_MODIFIER_OPTION_SETS_HYDRATE = "@@ADMINMASTER/MODIFIER/OPTION_SETS/HYDRATE";

export interface MasterModifier
    extends Omit<LocationModifier, "master_enabled" | "nutrition" | "price_per_unit" | "_links" | "_embedded"> {
    source_location: string;

    _embedded: {
        option_sets: OptionSet[];
        nutrition: Nutrition | null;
    };

    _links: {
        nutrition: {
            href: string;
            type: "application/hal+json; name=mms_master_menu_nutrition";
        };

        option_sets: {
            href: string;
            type: "application/hal+json; name=mms_master_menu_option_set_list";
        };

        self: {
            href: string;
            type: "application/hal+json; name=mms_master_menu_modifier";
        };
    };
}

export type NewMasterModifier = Omit<MasterModifier, "_embedded" | "_links" | "id" | "base_price_per_unit">;

export interface MasterModifierReq extends Omit<MasterModifier, "_embedded" | "_links" | "pos_config"> {
    pos_config: string;
    force_location_update?: boolean;
}

export interface MasterModifierResp extends Omit<MasterModifier, "pos_config"> {
    pos_config: string;
}

export interface MasterModifierPageResp extends ListRoute {
    _embedded: {
        modifiers: MasterModifierResp[];
    };
}

interface MasterModifierFetch {
    type: typeof MASTER_MODIFIER_FETCH;
    url: string;
}

interface MasterModifierFetchSuccess {
    type: typeof MASTER_MODIFIER_FETCH_SUCCESS;
    modifier: MasterModifier;
}

interface MasterModifierFetchError {
    type: typeof MASTER_MODIFIER_FETCH_ERROR;
    error: Error;
}

export interface MasterModifierSave {
    type: typeof MASTER_MODIFIER_SAVE;
    modifier: MasterModifier;
    url: string;
    callback?: (error?: Error) => void;
    force_location_update?: boolean;
}

interface MasterModifierSaveSuccess {
    type: typeof MASTER_MODIFIER_SAVE_SUCCESS;
    modifier: MasterModifier;
}

interface MasterModifierSaveError {
    type: typeof MASTER_MODIFIER_SAVE_ERROR;
    error: Error;
}

interface MasterModifierOptionSetsSave {
    type: typeof MASTER_MODIFIER_OPTION_SETS_SAVE;
    modifier: MasterModifier;
    optionSets: OptionSet[];
    callback?: (error?: Error) => void;
}

interface MasterModifierOptionSetsSaveError {
    type: typeof MASTER_MODIFIER_OPTION_SETS_SAVE_ERROR;
    modifier: MasterModifier;
    optionSets: OptionSet[];
    error: Error;
}

interface MasterModifiersHydrate {
    type: typeof MASTER_MODIFIERS_HYDRATE;
    modifiers: MasterModifier[];
}

interface MasterModifierOptionSetDeleteSuccess {
    type: typeof MASTER_MODIFIER_OPTION_SET_DELETE_SUCCESS;
    modifier: MasterModifier;
    optionSet: OptionSet;
}

export type MasterModifierActions =
    | MasterModifierFetch
    | MasterModifierFetchSuccess
    | MasterModifierFetchError
    | MasterModifierSave
    | MasterModifierSaveSuccess
    | MasterModifierSaveError
    | MasterModifierOptionSetsSave
    | MasterModifierOptionSetsSaveError
    | MasterModifiersHydrate
    | MasterModifierOptionSetDeleteSuccess;

export const isMasterModifier = (entity: HALEntity): entity is MasterModifier => {
    return entity._links.self.type === "application/hal+json; name=mms_master_menu_modifier";
};
