import React, { useContext } from "react";
import { RuleSetContext, RuleSetEvent } from "pages/Menus/Layers/RuleSets/context";
import { Grid, Typography, IconButton, Theme, Tooltip, SxProps, Box } from "@mui/material";
import {
    Visibility as VisibilityIcon,
    VisibilityOff as VisibilityOffIcon,
    Delete as DeleteIcon,
    DateRange as DateRangeIcon,
} from "@mui/icons-material";
import { MenuLayerRule, TransformType } from "store/mms/menus/master/layers/rule-sets/rules/types";
import { Link } from "react-router-dom";
import format from "date-fns/format";
import { Card } from "components/common/Card";
import { ruleType } from "./Rules/constants";

interface RuleListItemProps {
    rule: MenuLayerRule;
}
export function RuleListItem({ rule }: RuleListItemProps): JSX.Element {
    const { onChange } = useContext(RuleSetContext);
    const selectedRuleType = rule.definition.transforms.some((t) => t.type === TransformType.AdjustMenuPeriods)
        ? ruleType.Periods
        : ruleType.Menu;

    return (
        <Card
            cardActions={
                <Box sx={{ display: "flex" }}>
                    <Tooltip sx={iconStyle} title={rule.enabled ? "Disable" : "Enable"} arrow>
                        <IconButton size="small" onClick={() => onChange({ type: RuleSetEvent.RuleVisibility, rule })}>
                            {rule.enabled ? (
                                <VisibilityIcon fontSize="inherit" sx={{ color: "primary" }} />
                            ) : (
                                <VisibilityOffIcon
                                    fontSize="inherit"
                                    sx={{ color: (theme) => theme.palette.text.disabled }}
                                />
                            )}
                        </IconButton>
                    </Tooltip>
                    <Tooltip sx={iconStyle} title="Schedule">
                        <IconButton size="small" onClick={() => onChange({ type: RuleSetEvent.RuleSchedule, rule })}>
                            <DateRangeIcon fontSize="inherit" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip sx={iconStyle} title="Remove">
                        <IconButton size="small" onClick={() => onChange({ type: RuleSetEvent.RuleDelete, rule })}>
                            <DeleteIcon fontSize="inherit" />
                        </IconButton>
                    </Tooltip>
                </Box>
            }
            disableImage
        >
            <Grid item sx={{ display: "flex", flexDirection: "column", width: "100%", height: "inherited" }}>
                <Link to={getRuleLink(rule)}>
                    <Tooltip enterDelay={500} enterNextDelay={500} sx={iconStyle} title={rule.name} arrow>
                        <Typography
                            noWrap
                            sx={{
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                width: "100%",
                            }}
                            variant="h6"
                        >
                            {rule.name}
                        </Typography>
                    </Tooltip>
                </Link>
                <Typography
                    variant="caption"
                    sx={{
                        marginTop: "auto",
                        marginBottom: "auto",
                        paddingTop: (theme) => theme.spacing(0.75),
                        width: "100%",
                        transform: "scaleY(0.9)",
                    }}
                >
                    {rule && !rule.local_start && !rule.local_end ? (
                        "Always Active"
                    ) : rule.local_start && rule.local_end ? (
                        <span>
                            Active From:
                            <br /> {`${formatDate(rule.local_start, selectedRuleType)}`} -{" "}
                            {`${formatDate(rule.local_end, selectedRuleType)}`}
                        </span>
                    ) : rule.local_start ? (
                        <span>
                            Starts On:
                            <br /> {`${formatDate(rule.local_start, selectedRuleType)}`}
                        </span>
                    ) : rule.local_end ? (
                        <span>
                            Ends On:
                            <br /> {`${formatDate(rule.local_end, selectedRuleType)}`}
                        </span>
                    ) : (
                        ""
                    )}
                </Typography>
            </Grid>
        </Card>
    );
}

const formatDate = (dateString: string, selectedRuleType: ruleType) =>
    format(new Date(dateString), selectedRuleType === ruleType.Periods ? "MM/dd/yyyy" : "MM/dd/yyyy hh:mm a");
const iconStyle: SxProps<Theme> = { margin: "auto" };
const getRuleLink = (r: MenuLayerRule): string =>
    `/menus/${getMenuId(r)}/layers/${getLayerId(r)}/rule-sets/${getRuleSetId(r)}/rules/${r.id}`;
const getMenuId = (r: MenuLayerRule): string => r._links.self.href.replace(/.+\bmenus\b\/([^\/]*).+/, "$1");
const getLayerId = (r: MenuLayerRule): string => r._links.self.href.replace(/.+\blayers\b\/([^\/]*).+/, "$1");
const getRuleSetId = (r: MenuLayerRule): string => r._links.self.href.replace(/.+\brule_sets\b\/([^\/]*).+/, "$1");
