import { Typography, Theme } from "@mui/material";
import { makeStyles } from "theme";
import React from "react";

import img from "assets/images/construction.png";

const useStyles = makeStyles()((theme: Theme) => ({
    root: {
        margin: theme.spacing(12, "auto"),
    },
    img: {
        marginBottom: theme.spacing(5),
    },
}));

export default function NotFound(): JSX.Element {
    const { classes } = useStyles();

    return (
        <main className={classes.root}>
            <figure>
                <img src={img} alt="Omnisaur feels bad for you" className={classes.img} />
                <figcaption>
                    <Typography>Whoops. That page doesn&apos;t exist.</Typography>
                </figcaption>
            </figure>
        </main>
    );
}
