import { Box, Card, CardActionArea, CardMedia, Grid, Tooltip, Typography } from "@mui/material";
import ConfirmDialog from "components/common/ConfirmDialog";
import DefaultLayout from "components/common/layouts/DefaultLayout";
import { LocationMenuInfo } from "components/common/LocationMenuInfo";
import { OmniDrawer } from "components/common/OmniDrawer";
import EditPOSConfig from "components/mms/EditPOSConfig";
import NutritionFactsDisplay from "components/mms/NutritionFacts/NutritionFactsDisplay";
import LocationOptionSets from "components/mms/OptionSets/LocationOptionSets";
import TagCloud from "components/mms/Tags/TagCloud";
import { Sidebar, SidebarContent, SidebarHeader, SidebarSection } from "components/Sidebar";
import { getBreadcrumbSkeleton, MainContentSkeleton } from "components/skeletons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useLocation, useParams } from "react-router-dom";
import { apiBaseUrl } from "services/env";
import { getSession } from "services/session";
import { locationFetch } from "store/locations/actions";
import { locationMenuPageFetch } from "store/mms/menus/location/actions";
import { locationItemFetch, locationItemSave } from "store/mms/menus/location/items/actions";
import { locationModifierSave } from "store/mms/menus/location/items/modifiers/actions";
import { LocationModifier, ModifierStrategy } from "store/mms/menus/location/items/modifiers/types";
import { buildTraversal, ItemStrategy } from "store/mms/menus/location/items/types";
import { entityLocationPageLink } from "store/mms/menus/types";
import { POSConfig } from "store/mms/types";
import { RootState } from "store/rootReducer";
import { DetailsSidebarSection, useSharedEntityPageStyle } from "./helpers";

export default function LocationMenuItem(): JSX.Element {
    const s = getSession();
    const { classes } = useSharedEntityPageStyle();
    const { locationId, menuId } = useParams() as { locationId: string; menuId: string };
    const location = useLocation();
    const locations = useSelector((state: RootState) => state.locations.byId);
    const fetchingLocation = useSelector((state: RootState) => state.locations.fetching);
    const menusById = useSelector((state: RootState) => state.mms.menus.location.menus.byId);
    const menusFetching = useSelector((state: RootState) => state.mms.menus.location.menus.fetching);
    const items = useSelector((state: RootState) => state.mms.menus.location.items);
    const fetchingItem = useSelector((state: RootState) => state.mms.menus.location.items.fetching);
    const modsByHref = useSelector((state: RootState) => state.mms.menus.location.modifiers.byHref);
    const dispatch = useDispatch();
    const [itemPOSConfig, setItemPOSConfig] = useState<POSConfig | undefined>(undefined);
    const [editModifier, setEditModifier] = useState<LocationModifier | undefined>(undefined);
    const [modifierVisibility, setModifierVisibility] = useState<{
        modifier?: LocationModifier;
        saving: boolean;
    }>({ saving: false });
    const itemUrl = `/1.1/accounts/${s?.accountId}${location.pathname
        .replace(/-/g, "_")
        .replace("menus", "mms/menus")}`;
    const locationUrl = itemUrl.replace(/(\/locations\/[^\/]*)(.+)/, "$1");
    const item = items.byHref[apiBaseUrl + itemUrl];
    const accountLocation = locations[locationId];
    const locationMenu = menusById[menuId];
    const isLoading =
        item === undefined ||
        accountLocation === undefined ||
        fetchingLocation ||
        locationMenu === undefined ||
        menusFetching ||
        fetchingItem;

    // get the item
    useEffect(() => {
        if (item !== undefined) {
            return;
        }

        dispatch(locationItemFetch(itemUrl));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // get the location
    useEffect(() => {
        if (accountLocation !== undefined) {
            return;
        }

        dispatch(locationFetch(locationUrl));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // get the location menus
    useEffect(() => {
        if (locationMenu !== undefined) {
            return;
        }

        dispatch(locationMenuPageFetch(`${locationUrl}/mms/menus`));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const breadcrumbs = isLoading
        ? getBreadcrumbSkeleton()
        : [
              <RouterLink key="location" to={`/locations/${accountLocation.id}/menus/${menuId}`}>
                  {accountLocation.display_name}
              </RouterLink>,
              <Typography key="item" color="textPrimary">
                  {item.display_name}
              </Typography>,
          ];

    return (
        <DefaultLayout
            aside={
                <Sidebar>
                    <DetailsSidebarSection
                        isItem
                        entityId={itemUrl.replace(/.*\//, "")}
                        entity={item}
                        onChangeVisibility={(visible: boolean) =>
                            dispatch(locationItemSave({ ...item, enabled: visible }, itemUrl))
                        }
                        onEditPOSConfig={() => setItemPOSConfig(item?.pos_config || {})}
                    />
                    <SidebarSection>
                        <SidebarHeader>Tags</SidebarHeader>
                        <SidebarContent>
                            <TagCloud tags={item?.tags} loading={isLoading} />
                        </SidebarContent>
                    </SidebarSection>
                </Sidebar>
            }
            breadcrumbs={[
                <RouterLink key="locations" to="/locations">
                    Locations
                </RouterLink>,
                ...breadcrumbs,
            ]}
            header={<LocationMenuInfo location={accountLocation} locationMenu={locationMenu} />}
        >
            {isLoading ? (
                <MainContentSkeleton />
            ) : (
                <>
                    <Grid container spacing={3} wrap="nowrap" justifyContent="space-between">
                        <Grid item>
                            <Typography variant="h4" className={classes.title}>
                                {item.display_name}
                            </Typography>
                            <Typography variant="subtitle1">{item.description}</Typography>
                            {item.nutrition && (
                                <Box sx={{ flexFlow: "column", marginTop: (theme) => theme.spacing(4) }}>
                                    <Typography sx={{ marginBottom: (theme) => theme.spacing(1) }} variant="h6">
                                        Nutrition Facts
                                    </Typography>
                                    <NutritionFactsDisplay nutrition={item.nutrition} />
                                </Box>
                            )}
                        </Grid>

                        {item._embedded.image && (
                            <Grid item>
                                <Card>
                                    <CardActionArea onClick={() => window.open(item._embedded.image?.url, "_blank")}>
                                        <Tooltip title="Open" arrow>
                                            <CardMedia className={classes.image} image={item._embedded.image.url} />
                                        </Tooltip>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        )}
                    </Grid>

                    <LocationOptionSets
                        modsByHref={modsByHref}
                        optionSets={item._embedded.option_sets}
                        parent={item}
                        onModifierEdit={(m: LocationModifier) => setEditModifier(m)}
                        onSetVisibility={(m: LocationModifier) => setModifierVisibility({ modifier: m, saving: false })}
                    />

                    <OmniDrawer
                        close={() => setItemPOSConfig(undefined)}
                        open={itemPOSConfig !== undefined}
                        title="Update Item Settings"
                    >
                        {item !== undefined && itemPOSConfig !== undefined && (
                            <EditPOSConfig
                                posConfig={item.pos_config}
                                strategy={item.strategy}
                                traversal={buildTraversal(item)}
                                locationUrl={locationUrl}
                                onSave={(pos_config: POSConfig, strategy: ItemStrategy, callback) =>
                                    dispatch(
                                        locationItemSave(
                                            { ...item, linked: true, pos_config, strategy },
                                            itemUrl,
                                            callback,
                                        ),
                                    )
                                }
                                onClose={() => setItemPOSConfig(undefined)}
                                disableLocationSearch
                            />
                        )}
                    </OmniDrawer>

                    <OmniDrawer
                        close={() => setEditModifier(undefined)}
                        open={editModifier !== undefined}
                        title="Update Modifier Settings"
                    >
                        {item !== undefined && editModifier !== undefined && (
                            <EditPOSConfig
                                posConfig={editModifier.pos_config}
                                strategy={editModifier.strategy}
                                isModifier
                                traversal={buildTraversal(item, entityLocationPageLink(editModifier))}
                                locationUrl={locationUrl}
                                onSave={(pos_config: POSConfig, strategy: ModifierStrategy, callback) =>
                                    dispatch(
                                        locationModifierSave(
                                            { ...editModifier, linked: true, pos_config, strategy },
                                            editModifier._links.self.href,
                                            callback,
                                        ),
                                    )
                                }
                                onClose={() => setEditModifier(undefined)}
                                disableLocationSearch
                            />
                        )}
                    </OmniDrawer>

                    <ConfirmDialog
                        title={`${modifierVisibility.modifier?.enabled ? "Hide" : "Display"} Modifier`}
                        open={modifierVisibility.modifier !== undefined}
                        confirming={modifierVisibility.saving}
                        onConfirm={() => {
                            if (modifierVisibility.modifier) {
                                setModifierVisibility((prevState) => ({ ...prevState, saving: true }));
                                dispatch(
                                    locationModifierSave(
                                        {
                                            ...modifierVisibility.modifier,
                                            enabled: !modifierVisibility.modifier.enabled,
                                        },
                                        modifierVisibility.modifier._links.self.href,
                                        () => setModifierVisibility({ modifier: undefined, saving: false }),
                                    ),
                                );
                            }
                        }}
                        onClose={() => setModifierVisibility({ modifier: undefined, saving: false })}
                    >
                        <Typography>
                            The Modifier <strong>{modifierVisibility.modifier?.display_name}</strong> will be{" "}
                            {modifierVisibility.modifier?.enabled ? "hidden" : "displayed"} across any Item or Modifier
                            Group where it is used.
                        </Typography>
                    </ConfirmDialog>
                </>
            )}
        </DefaultLayout>
    );
}
