import { LocalizationProvider, DateTimePicker } from "@mui/lab";
import { Alert, Divider, FormControl, FormLabel, TextField, Switch, Typography, Stack } from "@mui/material";
import { OmniDrawer } from "components/common/OmniDrawer";
import React, { useContext } from "react";
import { Field, Form } from "react-final-form";
import { LocationMenuApplication } from "store/mms/menus/location/applications/types";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { FormSubmit } from "components/common/FormSubmit";
import { handleErrors, mapToFormErrors } from "components/common/forms/helpers";
import { useDispatch } from "react-redux";
import { applicationActionOrderingEnable } from "store/mms/application-actions/actions";
import { LocationContext } from "pages/Locations/context";

interface ApplicationScheduledEventAddProps {
    application?: LocationMenuApplication;
    onClose(): void;
    open: boolean;
}

export function ApplicationScheduledEventAdd({
    application,
    onClose,
    open,
}: ApplicationScheduledEventAddProps): JSX.Element {
    const dispatch = useDispatch();
    const minDate = new Date();

    const { menuId, locationId } = useContext(LocationContext);

    return (
        <OmniDrawer close={onClose} open={open} title={"Schedule Ordering Status for " + application?.name}>
            {application && (
                <Form
                    initialValues={{ scheduledStart: null, scheduledEnabled: false }}
                    validate={(values) => ({
                        scheduledStart: !values.scheduledStart ? "Required" : undefined,
                    })}
                    onSubmit={(values, _, errorsCallback) => {
                        dispatch(
                            applicationActionOrderingEnable(
                                Math.round(values.scheduledStart.getTime() / 1000),
                                {
                                    menus: [menuId],
                                    locations: [locationId],
                                    applications: [application.id],
                                },
                                {
                                    enabled: values.scheduledEnabled,
                                },
                                (error?: Error) => {
                                    if (!error && onClose) onClose();
                                    return errorsCallback && errorsCallback(mapToFormErrors(error));
                                },
                            ),
                        );
                    }}
                >
                    {({ handleSubmit, submitError, values }) => (
                        <form onSubmit={handleSubmit} noValidate>
                            <FormControl
                                component="fieldset"
                                sx={{ minWidth: 120, marginBottom: (theme) => theme.spacing(6) }}
                                fullWidth
                            >
                                <FormLabel sx={{ marginBottom: (theme) => theme.spacing(4) }} component="legend">
                                    Select when the status change should take place
                                </FormLabel>
                                <Field name="scheduledStart">
                                    {({ input, meta }) => (
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DateTimePicker
                                                {...input}
                                                {...handleErrors(meta)}
                                                inputFormat="yyyy/MM/dd hh:mm a"
                                                minDate={minDate}
                                                renderInput={(props) => <TextField {...props} error={false} />}
                                            />
                                        </LocalizationProvider>
                                    )}
                                </Field>
                            </FormControl>
                            <FormControl component="fieldset" sx={{ minWidth: 120 }} fullWidth>
                                <FormLabel sx={{ marginBottom: (theme) => theme.spacing(4) }} component="legend">
                                    Select the status that ordering for {application.name} should be set to at the above
                                    time
                                </FormLabel>
                                <Field name="scheduledEnabled">
                                    {({ input }) => (
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <Typography>Disabled</Typography>
                                            <Switch {...input} />
                                            <Typography>Enabled</Typography>
                                        </Stack>
                                    )}
                                </Field>
                            </FormControl>
                            {submitError && (
                                <Alert variant="outlined" severity="error">
                                    {submitError}
                                </Alert>
                            )}
                            <Divider sx={(theme) => ({ margin: theme.spacing(3, -3) })} />
                            <FormSubmit
                                sx={(theme) => ({
                                    marginRight: theme.spacing(0),
                                    marginLeft: "auto",
                                    width: "max-content",
                                })}
                                disableSubmit={!values.scheduledStart}
                                label="Schedule Change"
                                onClose={onClose}
                            />
                        </form>
                    )}
                </Form>
            )}
        </OmniDrawer>
    );
}
