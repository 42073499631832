import { Typography, Alert } from "@mui/material";
import ConfirmDialog from "components/common/ConfirmDialog";
import React, { useState } from "react";
import { sectionDelete } from "store/mms/menus/master/sections/actions";
import { useDispatch } from "react-redux";
import { Section } from "store/mms/menus/master/sections/types";
import { axiosErrorMessage } from "store/types";

interface SectionDeleteProps {
    menuName: string;
    section: Section;
    onClose(): void;
    open: boolean;
}

export function SectionDelete({ menuName, section, onClose, open }: SectionDeleteProps): JSX.Element {
    const dispatch = useDispatch();
    const [state, setState] = useState<{ saving: boolean; error?: string }>({
        saving: false,
    });

    return (
        <ConfirmDialog
            title="Remove Category"
            open={open}
            onClose={onClose}
            onConfirm={() => {
                setState({ saving: true });
                dispatch(
                    sectionDelete(section, (error?: Error): void => {
                        if (error) {
                            return setState({ saving: false, error: axiosErrorMessage(error) });
                        }
                        setState({ saving: false });
                        onClose();
                    }),
                );
            }}
            confirm="Remove Category"
            confirming={state.saving}
            maxWidth="xl"
            transitionDuration={0}
        >
            <Typography>
                Are you sure you want to remove the Category <strong>{section.name}</strong> from the {menuName} Menu?
            </Typography>

            <Alert variant="outlined" severity="info">
                This change will affect <strong>all Locations</strong>.
            </Alert>

            {state.error && (
                <Alert variant="outlined" severity="error">
                    {state.error}
                </Alert>
            )}
        </ConfirmDialog>
    );
}
