import { AccountState, AccountActions, ACCOUNT_FETCH, ACCOUNT_FETCH_SUCCESS, ACCOUNT_FETCH_ERROR } from "./types";

const initialState: AccountState = {
    company: "...",
    loading: true,
};

export function accountReducer(state = initialState, action: AccountActions): AccountState {
    switch (action.type) {
        case ACCOUNT_FETCH:
            return {
                ...state,
                loading: true,
            };
        case ACCOUNT_FETCH_SUCCESS:
            return {
                ...state,
                ...action.payload,
                loading: false,
            };
        case ACCOUNT_FETCH_ERROR:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
}
