import { MasterEntity } from "store/mms/menus/types";
import { HALLink, SaveAction } from "store/types";
import { MenuLayer, MenuLayerLocation } from "../types";
import { MenuLayerRule } from "./rules/types";

export const RULE_SET_ADD = "@@ADMINMASTER/MENU/LAYER/RULE_SET/ADD";
export const RULE_SET_ADD_SUCCESS = "@@ADMINMASTER/MENU/LAYER/RULE_SET/ADD_SUCCESS";

export const RULE_SET_SAVE = "@@ADMINMASTER/MENU/LAYER/RULE_SET/SAVE";
export const RULE_SET_SAVE_SUCCESS = "@@ADMINMASTER/MENU/LAYER/RULE_SET/SAVE_SUCCESS";

export const RULE_SET_MIGRATE_LOCATIONS_ADD = "@@ADMINMASTER/MENU/LAYER/RULE_SET/MIGRATE_LOCATIONS_ADD";
export const RULE_SET_MIGRATE_LOCATIONS_ADD_SUCCESS = "@@ADMINMASTER/MENU/LAYER/RULE_SET/MIGRATE_LOCATIONS_ADD_SUCCESS";

export const RULE_SET_MIGRATE_LOCATIONS_DELETE = "@@ADMINMASTER/MENU/LAYER/RULE_SET/MIGRATE_LOCATIONS_DELETE";
export const RULE_SET_MIGRATE_LOCATIONS_DELETE_SUCCESS =
    "@@ADMINMASTER/MENU/LAYER/RULE_SET/MIGRATE_LOCATIONS_DELETE_SUCCESS";

export interface MenuLayerRuleSet {
    id: string;
    name: string;
    enabled: boolean;
    start: number | null;
    end: number | null;

    _embedded: {
        locations: MenuLayerLocation[];
        rules: MenuLayerRule[];
    };

    _links: {
        locations: HALLink;
        rules: HALLink;
        self: {
            href: string;
            type: "application/hal+json; name=mms_master_menu_layer_rule_set";
        };
    };
}

export const isMenuLayerRuleSet = (e: MasterEntity): e is MenuLayerRuleSet =>
    (e as MenuLayerRuleSet)?._links?.self?.type === "application/hal+json; name=mms_master_menu_layer_rule_set";

export interface NewMenuLayerRuleSet extends Pick<MenuLayerRuleSet, "name"> {
    enabled: false;
}

export interface RuleSetAdd extends SaveAction {
    type: typeof RULE_SET_ADD;
    layer: MenuLayer;
    ruleSet: NewMenuLayerRuleSet;
}

interface RuleSetAddSuccess {
    type: typeof RULE_SET_ADD_SUCCESS;
    layerId: string;
    ruleSet: MenuLayerRuleSet;
}

export interface RuleSetSave extends SaveAction {
    type: typeof RULE_SET_SAVE;
    layerId: string;
    ruleSet: MenuLayerRuleSet;
}

interface RuleSetSaveSuccess {
    type: typeof RULE_SET_SAVE_SUCCESS;
    layerId: string;
    ruleSet: MenuLayerRuleSet;
}

export interface RuleSetMigrateLocationsAdd extends SaveAction {
    type: typeof RULE_SET_MIGRATE_LOCATIONS_ADD;
    layer: MenuLayer;
    locations: RuleSetLocationCsvData[];
    destinationRuleSet: MenuLayerRuleSet;
}
interface RuleSetMigrateLocationsAddSuccess {
    type: typeof RULE_SET_MIGRATE_LOCATIONS_ADD_SUCCESS;
    menuId: string;
    layer: MenuLayer;
}
export interface RuleSetMigrateLocationsDelete extends SaveAction {
    type: typeof RULE_SET_MIGRATE_LOCATIONS_DELETE;
    layer: MenuLayer;
    ruleSet: MenuLayerRuleSet;
    destinationRuleSet: MenuLayerRuleSet;
}

interface RuleSetMigrateLocationsDeleteSuccess {
    type: typeof RULE_SET_MIGRATE_LOCATIONS_DELETE_SUCCESS;
    menuId: string;
    layer: MenuLayer;
}

export interface RuleSetLocationCsvData {
    id: string;
    name: string;
}
export type RuleSetActions =
    | RuleSetAdd
    | RuleSetAddSuccess
    | RuleSetSave
    | RuleSetSaveSuccess
    | RuleSetMigrateLocationsAdd
    | RuleSetMigrateLocationsAddSuccess
    | RuleSetMigrateLocationsDelete
    | RuleSetMigrateLocationsDeleteSuccess;
