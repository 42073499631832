import React from "react";
import {
    CardContentProps as MuiCardContentProps,
    Card as MuiCard,
    CardProps as MuiCardProps,
    Box,
    CardContent,
    CardActionsProps as MuiCardActionsProps,
    CardActions,
} from "@mui/material";
import { CardMedia } from "./CardMedia";

interface CardProps extends MuiCardProps {
    children: React.ReactNode;
    cardActions?: React.ReactNode;
    CardActionsProps?: Omit<MuiCardActionsProps, "children">;
    CardContentProps?: Omit<MuiCardContentProps, "children">;
    // Allow null because images on menu entities can technically be null.
    image?: string | null;
    disableImage?: boolean;
}

export function Card({
    children,
    cardActions,
    CardActionsProps,
    CardContentProps,
    image,
    disableImage = false,
    ...rest
}: CardProps): JSX.Element {
    const { sx = [], ...lessRest } = rest;
    const { sx: cardContentSx = [], ...restCardContentProps } = CardContentProps || { sx: [] };
    const { sx: cardActionsSx = [], ...restCardActionProps } = CardActionsProps || { sx: [] };
    const cardMedia = (
        <CardMedia
            sx={(theme) => ({
                height: cardHeight,
                width: cardHeight,
                minWidth: cardHeight,

                [theme.breakpoints.down("sm")]: {
                    height: smallCardHeight,
                    width: smallCardHeight,
                    minWidth: smallCardHeight,
                },
            })}
            image={image}
        />
    );

    return (
        <MuiCard
            variant="outlined"
            sx={[
                (theme) => ({
                    display: "flex",
                    height: cardHeight,
                    justifyContent: "space-between",

                    "&.disabled": {
                        ".MuiCardContent-root > .MuiTypography-root": {
                            color: "text.disabled",
                            textDecorationColor: (theme) => theme.palette.text.disabled,
                        },
                        ".MuiCardMedia-root": {
                            filter: "grayscale(100%) opacity(50%)",
                        },
                    },

                    [theme.breakpoints.down("sm")]: {
                        height: smallCardHeight,
                    },
                }),
                ...(Array.isArray(sx) ? sx : [sx]),
            ]}
            {...lessRest}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    width: "100%",
                    padding: (theme) => theme.spacing(2),
                }}
            >
                <CardContent
                    sx={[
                        {
                            display: "flex",
                            flexDirection: "column",
                            flexBasis: "60%",
                            flexGrow: 1,
                            userSelect: "none",
                            padding: 0,

                            "& > .MuiCardContent-root": {
                                display: "flex",
                                flexDirection: "column",
                                flexGrow: 1,
                            },
                        },
                        ...(Array.isArray(cardContentSx) ? cardContentSx : [cardContentSx]),
                    ]}
                    {...restCardContentProps}
                >
                    {children}
                </CardContent>
                {cardActions && (
                    <CardActions
                        sx={[
                            {
                                padding: 0,
                            },
                            ...(Array.isArray(cardActionsSx) ? cardActionsSx : [cardActionsSx]),
                        ]}
                        {...restCardActionProps}
                    >
                        {cardActions}
                    </CardActions>
                )}
            </Box>
            {!disableImage && cardMedia}
        </MuiCard>
    );
}

const cardHeight = 170;
const smallCardHeight = 140;
