import { call, fork, ForkEffect, put, takeEvery } from "redux-saga/effects";
import { core } from "services/api";
import { enqueueSnackbar } from "store/notifications/actions";
import { MenuLayer } from "../types";
import {
    ruleSetAddSuccess,
    ruleSetMigrateLocationsAddSuccess,
    ruleSetMigrateLocationsDeleteSuccess,
    ruleSetSaveSuccess,
} from "./actions";
import rulesSaga from "./rules/effects";
import {
    MenuLayerRuleSet,
    RuleSetAdd,
    RuleSetMigrateLocationsAdd,
    RuleSetMigrateLocationsDelete,
    RuleSetSave,
    RULE_SET_ADD,
    RULE_SET_MIGRATE_LOCATIONS_ADD,
    RULE_SET_MIGRATE_LOCATIONS_DELETE,
    RULE_SET_SAVE,
} from "./types";

const mapToReq = (rs: MenuLayerRuleSet): Omit<MenuLayerRuleSet, "_embedded" | "_links"> => ({
    id: rs.id,
    enabled: rs.enabled,
    end: rs.end,
    name: rs.name,
    start: rs.start,
});

function* ruleSetAdd({ layer, ruleSet, callback }: RuleSetAdd) {
    try {
        const resp: { data: MenuLayerRuleSet } = yield call(core.post, layer._links.rule_sets.href, ruleSet);

        yield put(ruleSetAddSuccess(layer.id, resp.data));
        if (callback) {
            yield call(callback);
        }
    } catch (e) {
        console.error("Failed to create Menu Layer Rule Set", e);

        if (e instanceof Error && callback) {
            yield call(callback, e);
        }

        yield put(
            enqueueSnackbar({
                message: `Failed to create Menu Layer Rule Set ${ruleSet.name}.`,
                options: {
                    variant: "error",
                },
            }),
        );
    }
}

function* ruleSetSave({ layerId, ruleSet, callback }: RuleSetSave) {
    try {
        const resp: { data: MenuLayerRuleSet } = yield call(core.put, ruleSet._links.self.href, mapToReq(ruleSet));

        yield put(ruleSetSaveSuccess(layerId, resp.data));
        if (callback) {
            yield call(callback);
        }
    } catch (e) {
        console.error("Failed to save Menu Layer Rule Set", e);

        if (e instanceof Error && callback) {
            yield call(callback, e);
        }
    }
}

function* ruleSetMigrateLocationsAdd({ layer, locations, destinationRuleSet, callback }: RuleSetMigrateLocationsAdd) {
    try {
        // migrate locations to the destination rule set
        yield call(core.post, destinationRuleSet._links.locations.href, {
            locations: locations.map((l) => ({
                location: l.id,
            })),
        });

        // get the layer with all the recent changes
        const resp: { data: MenuLayer } = yield call(core.get, layer._links.self.href);
        const savedLayer = resp.data;

        yield put(
            enqueueSnackbar({
                message: `Successfully migrated Locations to ${destinationRuleSet.name}`,
                options: {
                    variant: "success",
                },
            }),
        );
        if (callback) {
            yield call(callback);
        }
        yield put(
            ruleSetMigrateLocationsAddSuccess(
                savedLayer._links.self.href.replace(/.+menus\/([^\/]*).*/, "$1"),
                savedLayer,
            ),
        );
    } catch (e) {
        console.error("Failed to migrate Menu Layer Rule Set Locations", e);

        if (e instanceof Error && callback) {
            yield call(callback, e);
        }
    }
}

function* ruleSetMigrateLocationsDelete({
    layer,
    ruleSet,
    destinationRuleSet,
    callback,
}: RuleSetMigrateLocationsDelete) {
    try {
        // migrate locations to the destination rule set
        yield call(core.post, destinationRuleSet._links.locations.href, {
            locations: ruleSet._embedded.locations.map((l) => ({
                location: l.id,
            })),
        });

        // delete the rule set
        yield call(core.delete, ruleSet._links.self.href);

        // get the layer with all the recent changes
        const resp: { data: MenuLayer } = yield call(core.get, layer._links.self.href);
        const savedLayer = resp.data;
        yield put(
            ruleSetMigrateLocationsDeleteSuccess(
                savedLayer._links.self.href.replace(/.+menus\/([^\/]*).*/, "$1"),
                savedLayer,
            ),
        );
        if (callback) {
            yield call(callback);
        }
    } catch (e) {
        console.error("Failed to migrate Menu Layer Rule Set Locations", e);

        if (e instanceof Error && callback) {
            yield call(callback, e);
        }
    }
}

export default function* ruleSetsSaga(): Generator<ForkEffect<void>, void, unknown> {
    yield takeEvery(RULE_SET_ADD, ruleSetAdd);
    yield takeEvery(RULE_SET_SAVE, ruleSetSave);
    yield takeEvery(RULE_SET_MIGRATE_LOCATIONS_ADD, ruleSetMigrateLocationsAdd);
    yield takeEvery(RULE_SET_MIGRATE_LOCATIONS_DELETE, ruleSetMigrateLocationsDelete);
    yield fork(rulesSaga);
}
