import { OmniDrawer } from "components/common/OmniDrawer";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { masterItemFetch } from "store/mms/menus/master/items/actions";
import { masterModifierFetch } from "store/mms/menus/master/items/modifiers/actions";
import { MasterModifier } from "store/mms/menus/master/items/modifiers/types";
import { optionSetCreate } from "store/mms/menus/master/items/option-sets/actions";
import { isMasterItem, MasterItem } from "store/mms/menus/master/items/types";
import { entityMasterPageLink } from "store/mms/menus/types";
import { ModifierGroupAdd } from "./OptionSets/ModifierGroupAdd";

interface EntityAddModifierGroupProps {
    entity?: MasterModifier | MasterItem;
    itemId?: string;
    menuId: string;
    onClose(): void;
    open: boolean;
}

export function EntityAddModifierGroup({
    entity,
    itemId,
    menuId,
    onClose,
    open,
}: EntityAddModifierGroupProps): JSX.Element {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    return (
        <OmniDrawer close={onClose} open={open} title="Add Modifier Group">
            {entity && (
                <ModifierGroupAdd
                    menuId={menuId}
                    onClose={onClose}
                    onSubmit={(group, callback) => {
                        dispatch(
                            optionSetCreate(group, entity, (createdOptionSet, error) => {
                                if (callback) {
                                    callback(error);
                                }
                                const isMasterItemEntity = isMasterItem(entity);

                                if (isMasterItemEntity) {
                                    dispatch(masterItemFetch(entity._links.self.href));
                                } else {
                                    dispatch(masterModifierFetch(entity._links.self.href));
                                }
                                if (createdOptionSet) {
                                    navigate(
                                        entityMasterPageLink(createdOptionSet, isMasterItemEntity ? undefined : itemId),
                                    );
                                }
                            }),
                        );
                    }}
                />
            )}
        </OmniDrawer>
    );
}
