import { call, ForkEffect, put, takeLatest } from "redux-saga/effects";
import { core } from "services/api";
import { RestaurantConceptsFetchResp, RESTAURANT_CONCEPT_FETCH } from "./types";
import {
    restaurantConceptsFetchError as restaurantConceptsFetchErrorAction,
    restaurantConceptsFetchSuccess as restaurantConceptsFetchSuccessAction,
} from "./actions";

function* restaurantConceptsFetch({ url }: { url: string; type: typeof RESTAURANT_CONCEPT_FETCH }) {
    try {
        const resp: { data: RestaurantConceptsFetchResp } = yield call(core.get, url);

        yield put(restaurantConceptsFetchSuccessAction(resp.data._embedded.restaurant_concepts));
    } catch (e) {
        if (e instanceof Error) {
            console.error("Failed to fetch restaurant concepts", e);
            yield put(restaurantConceptsFetchErrorAction(e));
        }
    }
}

export default function* restaurantConceptsSaga(): Generator<ForkEffect<void>, void, unknown> {
    yield takeLatest(RESTAURANT_CONCEPT_FETCH, restaurantConceptsFetch);
}
