import { call, ForkEffect, put, takeEvery } from "redux-saga/effects";
import { core } from "services/api";
import { POSConfig } from "store/mms/types";
import { enqueueSnackbar } from "store/notifications/actions";
import {
    masterItemFetchSuccess,
    masterItemFetchError,
    masterItemSaveSuccess,
    masterItemSaveError,
    masterItemImageSaveError,
    masterItemImageSaveSuccess,
    masterItemImageDeleteSuccess,
    masterItemImageDeleteError,
    masterItemOptionSetsSaveError,
} from "./actions";
import { masterModifiersHydrate } from "./modifiers/actions";
import { mapModifierRespToDomain } from "./modifiers/effects";
import { MasterModifier, MasterModifierResp } from "./modifiers/types";
import { optionSetsHydrate } from "./option-sets/actions";
import { flattenOptionSets } from "./option-sets/effects";
import { modifierGroupsHydrate } from "./option-sets/modifier-group/actions";
import { mapModifierGroupRespToDomain } from "./option-sets/modifier-group/effects";
import { ModifierGroupModifier, ModifierGroupResp } from "./option-sets/modifier-group/types";
import { OptionSet } from "./option-sets/types";
import {
    MasterItem,
    MasterItemImageResp,
    MasterItemReq,
    MasterItemResp,
    MASTER_ITEM_FETCH,
    MASTER_ITEM_IMAGE_DELETE,
    MASTER_ITEM_IMAGE_SAVE,
    MASTER_ITEM_OPTION_SETS_SAVE,
    MASTER_ITEM_SAVE,
} from "./types";

export const mapItemResp = (r: MasterItemResp): MasterItem => {
    const pos_config: POSConfig = JSON.parse(r.pos_config !== "" ? r.pos_config : "{}");

    return {
        ...r,
        pos_config,
    };
};
const mapDomainToReq = (i: MasterItem, force_location_update?: boolean): MasterItemReq => {
    return {
        description: i.description,
        base_price_per_unit: i.base_price_per_unit,
        display_name: i.display_name,
        enabled: i.enabled,
        id: i.id,
        linked: i.linked,
        pos_config: JSON.stringify(i.pos_config),
        reference_name: i.reference_name,
        source_location: i.source_location,
        strategy: i.strategy,
        force_location_update,
    };
};

function* masterItemFetch({ url }: { url: string; type: typeof MASTER_ITEM_FETCH }) {
    try {
        const resp: { data: MasterItemResp } = yield call(core.get, url);
        const savedItem = mapItemResp(resp.data);
        const flattenedOSes = flattenOptionSets(savedItem._embedded.option_sets);
        const flattenedMods = flattenedOSes.reduce((list: MasterModifier[], os) => {
            const osMods = os._embedded.modifier_group._embedded.modifiers.map((m: ModifierGroupModifier) =>
                mapModifierRespToDomain(m._embedded.modifier as MasterModifierResp),
            );

            return [...list, ...osMods];
        }, []);

        yield put(optionSetsHydrate(flattenedOSes));
        yield put(
            modifierGroupsHydrate(
                flattenedOSes.map((os) =>
                    mapModifierGroupRespToDomain(os._embedded.modifier_group as ModifierGroupResp),
                ),
            ),
        );
        yield put(masterModifiersHydrate(flattenedMods));
        yield put(masterItemFetchSuccess(savedItem));
    } catch (e) {
        console.error("Failed to fetch Menu Master Item", e);
        if (e instanceof Error) {
            yield put(masterItemFetchError(e));
        }
    }
}

function* masterItemSave({
    item,
    url,
    callback,
    force_location_update,
}: {
    type: typeof MASTER_ITEM_SAVE;
    item: MasterItem;
    url: string;
    callback?: (error?: Error) => void;
    force_location_update?: boolean;
}) {
    try {
        const resp: { data: MasterItemResp } = yield call(core.put, url, mapDomainToReq(item, force_location_update));
        const savedItem = mapItemResp(resp.data);
        const flattenedOSes = flattenOptionSets(savedItem._embedded.option_sets);
        const flattenedMods = flattenedOSes.reduce((list: MasterModifier[], os) => {
            const osMods = os._embedded.modifier_group._embedded.modifiers.map((m: ModifierGroupModifier) =>
                mapModifierRespToDomain(m._embedded.modifier as MasterModifierResp),
            );

            return [...list, ...osMods];
        }, []);

        yield put(optionSetsHydrate(flattenedOSes));
        yield put(
            modifierGroupsHydrate(
                flattenedOSes.map((os) =>
                    mapModifierGroupRespToDomain(os._embedded.modifier_group as ModifierGroupResp),
                ),
            ),
        );
        yield put(masterModifiersHydrate(flattenedMods));

        if (callback !== undefined) {
            yield call(callback);
        }
        yield put(masterItemSaveSuccess(savedItem));
        yield put(
            enqueueSnackbar({
                message: `Successfully saved Item, ${savedItem.display_name}.`,
                options: {
                    variant: "success",
                },
            }),
        );
    } catch (e) {
        console.error("Failed to save Menu Master Item", e);
        if (e instanceof Error) {
            if (callback !== undefined) {
                yield call(callback, e);
            }
            yield put(masterItemSaveError(e));
        }

        yield put(
            enqueueSnackbar({
                message: `Failed to save Item${item.display_name && ", " + item.display_name}.`,
                options: {
                    variant: "error",
                },
            }),
        );
    }
}

function* masterItemImageSave({
    item,
    imageUrl,
    callback,
}: {
    type: typeof MASTER_ITEM_IMAGE_SAVE;
    item: MasterItem;
    imageUrl: string;
    callback?: (error?: Error) => void;
}) {
    try {
        const resp: { data: MasterItemImageResp } = yield call(
            item._embedded.image ? core.put : core.post,
            item._links.image.href,
            { url: imageUrl },
        );

        if (callback !== undefined) {
            yield call(callback);
        }
        yield put(masterItemImageSaveSuccess(item.id, resp.data.url));
    } catch (e) {
        console.error("Failed to save Menu Master Item Image", e);
        if (e instanceof Error) {
            if (callback !== undefined) {
                yield call(callback, e);
            }
            yield put(masterItemImageSaveError(e));
        }

        yield put(
            enqueueSnackbar({
                message: `Failed to save Item Image.`,
                options: {
                    variant: "error",
                },
            }),
        );
    }
}

function* masterItemImageDelete({
    item,
    callback,
}: {
    type: typeof MASTER_ITEM_IMAGE_DELETE;
    item: MasterItem;
    callback?: (error?: Error) => void;
}) {
    try {
        yield call(core.delete, item._links.image.href);
        if (callback !== undefined) {
            yield call(callback);
        }
        yield put(masterItemImageDeleteSuccess(item.id));
    } catch (e) {
        console.error("Failed to delete Menu Master Item Image", e);
        if (e instanceof Error) {
            if (callback !== undefined) {
                yield call(callback, e);
            }
            yield put(masterItemImageDeleteError(e));
        }

        yield put(
            enqueueSnackbar({
                message: `Failed to remove Item Image.`,
                options: {
                    variant: "error",
                },
            }),
        );
    }
}

function* masterItemOptionSetsSave({
    item,
    optionSets,
    callback,
}: {
    type: typeof MASTER_ITEM_OPTION_SETS_SAVE;
    item: MasterItem;
    optionSets: OptionSet[];
    callback?: (error?: Error) => void;
}) {
    const prevOptionSets = item._embedded.option_sets;
    try {
        yield call(
            core.post,
            item._links.option_sets.href,
            optionSets.map((os) => ({
                description: os.description,
                enabled: os.enabled,
                id: os.id,
                implicit: os.implicit,
                max: os.max,
                min: os.min,
                name: os.name,
                required: os.required,
            })),
        );

        if (callback !== undefined) {
            yield call(callback);
        }
    } catch (e) {
        console.error("Failed to save Menu Master Item Option Sets", e);
        if (e instanceof Error) {
            if (callback !== undefined) {
                yield call(callback, e);
            }
            yield put(masterItemOptionSetsSaveError(e, item, prevOptionSets));
        }

        yield put(
            enqueueSnackbar({
                message: `Failed to save Item Modifier Groups.`,
                options: {
                    variant: "error",
                },
            }),
        );
    }
}

export default function* masterItemsSaga(): Generator<ForkEffect<never>, void, unknown> {
    yield takeEvery(MASTER_ITEM_FETCH, masterItemFetch);
    yield takeEvery(MASTER_ITEM_SAVE, masterItemSave);
    yield takeEvery(MASTER_ITEM_IMAGE_SAVE, masterItemImageSave);
    yield takeEvery(MASTER_ITEM_IMAGE_DELETE, masterItemImageDelete);
    yield takeEvery(MASTER_ITEM_OPTION_SETS_SAVE, masterItemOptionSetsSave);
}
