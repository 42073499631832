import React from "react";
import { VictoryTheme, VictoryTooltip } from "victory";

export default function Tooltip(): JSX.Element {
    return (
        <VictoryTooltip
            cornerRadius={2}
            // TODO: This doesn't work  so we're using the out-of-the-box theme for now.
            // style={{
            //     fill: theme.palette.text.primary,
            //     background: theme.palette.background.paper,
            // }}
            theme={VictoryTheme.material}
        />
    );
}
