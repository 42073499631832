import React, { useEffect, useMemo } from "react";
import {
    Typography,
    Alert,
    Skeleton,
    Checkbox,
    ListItemText,
    ListItem,
    ListItemButton,
    ListItemIcon,
    Box,
    Chip,
    Divider,
} from "@mui/material";
import { MasterMenu } from "store/mms/menus/master/types";
import { Location } from "store/locations/types";
import ConfirmDialog from "components/common/ConfirmDialog";
import { locationMenuOnboard, locationMenuPageFetch } from "store/mms/menus/location/actions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/rootReducer";
import pluralize from "pluralize";
import PaperWrapper from "components/common/PaperWrapper";
import { Autocomplete } from "mui-rff";
import { Form } from "react-final-form";
import { FormSubmit } from "components/common/FormSubmit";
import { mapToFormErrors } from "components/common/forms/helpers";

interface LocationMenuLinkProps {
    location?: Location;
    menusById: {
        [key: string]: MasterMenu;
    };
    linkedMenuIds?: string[];
    open: boolean;
    onClose?(): void;
    onConfirm?: () => void;
}

export function LocationMenuLink({
    open,
    onClose,
    onConfirm,
    location,
    menusById,
    linkedMenuIds,
}: LocationMenuLinkProps): JSX.Element {
    const dispatch = useDispatch();

    const menusFetching = useSelector((state: RootState) => state.mms.menus.location.menus.fetching);
    const menusByLocationId = useSelector((state: RootState) => state.mms.menus.location.menus.byLocationId);
    const locationMenuIds = useMemo(() => menusByLocationId[location?.id || ""] || [], [menusByLocationId, location]);

    const unlinkedMenus = useMemo(
        () => Object.values(menusById).filter((menu) => !locationMenuIds.includes(menu.id)),
        [locationMenuIds, menusById],
    );

    useEffect(() => {
        if (location && open && !linkedMenuIds) {
            dispatch(locationMenuPageFetch(`${location._links.self.href}mms/menus`));
        }
    }, [dispatch, location, linkedMenuIds, open]);

    const isLoading = location === undefined || !menusByLocationId[location?.id || ""] || menusFetching;
    const allMenusLinked = unlinkedMenus.length === 0;

    return (
        <ConfirmDialog
            hideActions
            title="Link one or more Menus"
            open={open}
            onClose={onClose}
            maxWidth="xl"
            transitionDuration={0}
            sx={{
                "& .MuiDialog-paper": {
                    minWidth: "565px",
                },
            }}
        >
            <section>
                {isLoading ? (
                    <MenuLinkSkeleton />
                ) : (
                    <>
                        <Typography>Location</Typography>
                        <Typography
                            sx={(theme) => ({
                                marginBottom: theme.spacing(4),
                                fontWeight: theme.typography.fontWeightBold,
                            })}
                        >
                            {location?.display_name}
                        </Typography>
                        {allMenusLinked && (
                            <Alert sx={{ marginBottom: (theme) => theme.spacing(4) }} severity="info">
                                All Menus have been linked to this Location.
                            </Alert>
                        )}
                        <Form
                            initialValues={{
                                menus: [],
                            }}
                            onSubmit={(values, _, errorsCallback) => {
                                if (location) {
                                    const onboardRequests = values.menus.map((m: MasterMenu) => ({
                                        mms_menu: m.id,
                                    }));
                                    dispatch(
                                        locationMenuOnboard(
                                            `${location._links.self.href}mms/menus`,
                                            onboardRequests,
                                            (errors?: Error) => {
                                                if (errors) {
                                                    return errorsCallback && errorsCallback(mapToFormErrors(errors));
                                                }
                                                onConfirm && onConfirm();
                                                onClose && onClose();
                                            },
                                        ),
                                    );
                                }
                            }}
                        >
                            {({ values, handleSubmit, submitError }) => (
                                <Box onSubmit={handleSubmit} component="form">
                                    {!allMenusLinked && (
                                        <Autocomplete
                                            sx={{ maxWidth: "500px" }}
                                            name="menus"
                                            label="Menus"
                                            options={Object.values(menusById)}
                                            getOptionDisabled={(menu) => locationMenuIds.includes(menu.id)}
                                            getOptionLabel={(menu) => menu.name}
                                            getOptionValue={(menu) => menu}
                                            isOptionEqualToValue={(o, v) => o.id === v.id}
                                            renderOption={(props, menu, { selected }) => {
                                                const labelId = `checkbox-list-label-${menu.id}`;
                                                const isLinked = locationMenuIds.includes(menu.id);

                                                return (
                                                    <ListItem
                                                        disabled={isLinked}
                                                        sx={{ width: "100%" }}
                                                        key={menu.id}
                                                        disablePadding
                                                        {...props}
                                                    >
                                                        <ListItemButton>
                                                            <ListItemIcon>
                                                                <Checkbox
                                                                    checked={selected || isLinked}
                                                                    tabIndex={-1}
                                                                    disableRipple
                                                                    inputProps={{ "aria-labelledby": labelId }}
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText id={labelId} primary={menu.name} />
                                                        </ListItemButton>
                                                    </ListItem>
                                                );
                                            }}
                                            renderTags={(menus, getTagProps) =>
                                                menus.map((menu, index) => (
                                                    <Chip
                                                        color="primary"
                                                        label={menu.name}
                                                        {...getTagProps({ index })}
                                                        key={menu.id}
                                                    />
                                                ))
                                            }
                                            textFieldProps={{
                                                variant: "outlined",
                                            }}
                                            PaperComponent={PaperWrapper}
                                            multiple
                                            selectOnFocus
                                            clearOnBlur
                                            handleHomeEndKeys
                                            disableCloseOnSelect
                                            fullWidth
                                        />
                                    )}
                                    {submitError && (
                                        <Alert variant="outlined" severity="error">
                                            {submitError}
                                        </Alert>
                                    )}
                                    <Divider sx={(theme) => ({ margin: theme.spacing(3, -3) })} />
                                    <FormSubmit
                                        disableSubmit={values.menus.length === 0}
                                        sx={(theme) => ({
                                            marginRight: theme.spacing(0),
                                            marginLeft: "auto",
                                            width: "max-content",
                                        })}
                                        alignItems="right"
                                        label={pluralize("Link Menu", values.menus.length)}
                                        onClose={() => onClose && onClose()}
                                    />
                                </Box>
                            )}
                        </Form>
                    </>
                )}
            </section>
        </ConfirmDialog>
    );
}

const MenuLinkSkeleton = () => (
    <Box>
        <Skeleton width={60} />
        <Skeleton width={100} sx={{ marginBottom: (theme) => theme.spacing() }} />
        <Skeleton height={80} />
        <Divider sx={(theme) => ({ margin: theme.spacing(1, -3) })} />{" "}
        <Box
            display="flex"
            flexDirection="row"
            alignItems="right"
            sx={{ marginRight: 0, marginLeft: "auto", width: "max-content" }}
        >
            <Skeleton height={60} width={115} />
            <Skeleton sx={{ marginLeft: (theme) => theme.spacing(2.5) }} width={70} />
        </Box>
    </Box>
);
