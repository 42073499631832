import { combineReducers } from "redux";
import applications from "./applications/reducers";
import modifiers from "./items/modifiers/reducers";
import items from "./items/reducers";
import periods from "./periods/reducers";
import sections from "./sections/reducers";
import taxRates from "./taxes/reducers";
import {
    AdminlocationReset,
    ADMINLOCATION_RESET,
    LocationMenu,
    LocationMenuActions,
    LOCATION_BY_MENU_EXPORT_FETCH,
    LOCATION_MENU_APPLICATION_PAGE_FETCH_SUCCESS,
    LOCATION_MENU_PAGE_FETCH,
    LOCATION_MENU_PAGE_FETCH_ERROR,
    LOCATION_MENU_PAGE_FETCH_SUCCESS,
    LOCATION_MENU_SAVE,
    LOCATION_MENU_SAVE_ERROR,
    LOCATION_MENU_SAVE_SUCCESS,
} from "./types";

interface LocationMenusState {
    readonly byId: {
        [key: string]: LocationMenu;
    };
    readonly byLocationId: {
        [key: string]: string[];
    };
    readonly idsByMasterMenuId: {
        [menuId: string]: string[];
    };
    readonly allIds: string[];
    readonly fetching: boolean;
    readonly saving: boolean;
    readonly savingError?: Error;
    readonly fetchingError?: Error;
}

const initialState: LocationMenusState = {
    byId: {},
    byLocationId: {},
    idsByMasterMenuId: {},
    allIds: [],
    fetching: false,
    saving: false,
};

const menuId = (locationMenu: LocationMenu): string =>
    locationMenu._links.self.href.replace(/.+menus\/([^\/]*).*/, "$1");

function menus(state = initialState, action: LocationMenuActions | AdminlocationReset): LocationMenusState {
    switch (action.type) {
        case LOCATION_MENU_PAGE_FETCH:
            return {
                ...state,
                fetching: true,
            };

        case LOCATION_BY_MENU_EXPORT_FETCH: {
            return { ...state, fetching: true };
        }

        case LOCATION_MENU_PAGE_FETCH_SUCCESS:
            const newIds = action.menus.map((m) => m.id);

            return {
                ...state,
                fetching: false,
                byId: {
                    ...state.byId,
                    ...action.menus.reduce((allMenus, m) => ({ ...allMenus, [m.id]: m }), {}),
                },
                byLocationId: {
                    ...state.byLocationId,
                    [action.locationId]: [
                        ...(state.byLocationId[action.locationId] || []).filter((id) => !newIds.includes(id)),
                        ...newIds,
                    ],
                },
                idsByMasterMenuId: action.menus.reduce((map: { [menuId: string]: string[] }, m) => {
                    const masterMenuId = menuId(m);
                    const locationIds = new Set([
                        ...(map[masterMenuId] || state.idsByMasterMenuId[masterMenuId] || []),
                        m.id,
                    ]);
                    map[masterMenuId] = Array.from(locationIds);
                    return map;
                }, {}),
                allIds: [...state.allIds.filter((id) => !newIds.includes(id)), ...newIds],
            };

        case LOCATION_MENU_PAGE_FETCH_ERROR:
            return {
                ...state,
                fetching: false,
                fetchingError: action.error,
            };

        case LOCATION_MENU_SAVE:
            return {
                ...state,
                saving: true,
            };

        case LOCATION_MENU_SAVE_SUCCESS:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.menu.id]: action.menu,
                },
                byLocationId: {
                    ...state.byLocationId,
                    [action.locationId]: [
                        ...(state.byLocationId[action.locationId] || []).filter((id) => id !== action.menu.id),
                        action.menu.id,
                    ],
                },
                allIds: [...state.allIds.filter((id) => id !== action.menu.id), action.menu.id],
                saving: false,
            };

        case LOCATION_MENU_SAVE_ERROR:
            return {
                ...state,
                saving: false,
                savingError: action.error,
            };

        case LOCATION_MENU_APPLICATION_PAGE_FETCH_SUCCESS:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.menuId]: {
                        ...state.byId[action.menuId],
                        _embedded: {
                            ...state.byId[action.menuId]._embedded,
                            applications: action.applications,
                        },
                    },
                },
            };

        case ADMINLOCATION_RESET:
            return initialState;

        default:
            return state;
    }
}

const locationMenusReducer = combineReducers({
    applications,
    items,
    menus,
    modifiers,
    periods,
    sections,
    taxRates,
});

export default locationMenusReducer;
