import { Grid, GridSize, Alert, MenuItem, TextField, Typography, Theme } from "@mui/material";
import { makeStyles } from "theme";
import { handleErrors } from "components/common/forms/helpers";
import { FormSubmit } from "components/common/FormSubmit";
import { OmniDrawer } from "components/common/OmniDrawer";
import { FORM_ERROR } from "final-form";
import React from "react";
import { Field, Form } from "react-final-form";
import { useDispatch } from "react-redux";
import { MasterModifier } from "store/mms/menus/master/items/modifiers/types";
import { MasterItem } from "store/mms/menus/master/items/types";
import { entityNutritionUpdate } from "store/mms/menus/master/nutrition/actions";
import { CalorieMode, Nutrition } from "store/mms/types";
import { axiosErrorMessage } from "store/types";

interface NutritionFactsEditProps {
    entity?: MasterItem | MasterModifier;
    open: boolean;
    onClose(): void;
}

interface formValues {
    serving_unit: string;
    serving_base?: string;
    serving_total?: string;
    calories_mode: CalorieMode;
    calories_lower?: string;
    calories_upper?: string;
}

export default function NutritionFactsEdit({ entity, open, onClose }: NutritionFactsEditProps): JSX.Element {
    const dispatch = useDispatch();
    const { classes } = useStyles();

    const initial = entity?._embedded.nutrition;
    const initialValues: formValues = {
        serving_unit: initial?.serving.unit || "",
        serving_base: initial?.serving.base.toString() || "",
        serving_total: initial?.serving.total.toString() || "",
        calories_lower: initial?.calories.lower.toString() || "",
        calories_mode: initial?.calories.mode || CalorieMode.single,
        calories_upper: initial?.calories.upper?.toString(),
    };
    return (
        <OmniDrawer close={onClose} open={open} title="Nutrition Facts">
            {open && entity && (
                <Form
                    initialValues={initialValues}
                    onSubmit={(values, form, errorsCallback) => {
                        const nu: Nutrition = {
                            serving: {
                                unit: values.serving_unit,
                                base: parseInt(values.serving_base, 10),
                                total: parseInt(values.serving_total, 10),
                            },
                            calories: {
                                mode: values.calories_mode,
                                lower: parseInt(values.calories_lower, 10),
                                upper: values.calories_upper ? parseInt(values.calories_upper, 10) : undefined,
                            },
                        };

                        dispatch(
                            entityNutritionUpdate(entity, nu, (error?: Error) => {
                                if (error && errorsCallback !== undefined) {
                                    errorsCallback({ [FORM_ERROR]: axiosErrorMessage(error) });
                                } else {
                                    onClose();
                                }
                            }),
                        );
                    }}
                    validate={(values) => ({
                        serving_unit: !values.serving_unit ? "Required" : undefined,
                        serving_base: !values.serving_base ? "Required" : undefined,
                        serving_total: !values.serving_total ? "Required" : undefined,
                        calories_lower: !values.calories_lower ? "Required" : undefined,
                        calories_upper:
                            values.calories_mode !== CalorieMode.single && !values.calories_upper
                                ? "Required"
                                : undefined,
                    })}
                >
                    {({ handleSubmit, submitError, values }) => (
                        <form onSubmit={handleSubmit}>
                            <Typography variant="h6" className={classes.header}>
                                Serving Size
                            </Typography>
                            <Input
                                name="serving_unit"
                                label="Unit of Measure"
                                type="input"
                                helperText="Examples: Slice, Piece, Cup, Taco, etc."
                            />

                            <Grid container spacing={2}>
                                <Input name="serving_base" label="Serving Size" gridSize={6} />
                                <Input name="serving_total" label="Total Servings" gridSize={6} />
                            </Grid>

                            <Typography variant="h6" className={classes.header}>
                                Calories
                            </Typography>

                            <Field name="calories_mode">
                                {({ input, meta }) => (
                                    <TextField
                                        {...input}
                                        {...handleErrors(meta)}
                                        label="Type"
                                        variant="outlined"
                                        select
                                        fullWidth
                                        className={classes.field}
                                    >
                                        <MenuItem value="single">Single (total)</MenuItem>
                                        <MenuItem value="double">Double (per serving / all servings)</MenuItem>
                                        <MenuItem value="multiple">Range (low - high)</MenuItem>
                                    </TextField>
                                )}
                            </Field>

                            <Grid container spacing={2}>
                                <Input
                                    name="calories_lower"
                                    label={calLowerLabel(values.calories_mode)}
                                    gridSize={values.calories_mode == CalorieMode.single ? 12 : 6}
                                />
                                {values.calories_mode != CalorieMode.single && (
                                    <Input
                                        name="calories_upper"
                                        label={calUpperLabel(values.calories_mode)}
                                        gridSize={6}
                                    />
                                )}
                            </Grid>

                            {submitError && (
                                <Alert className={classes.submitErrors} severity="error">
                                    {submitError}
                                </Alert>
                            )}
                            <FormSubmit
                                className={classes.footer}
                                onClose={onClose}
                                label={entity._embedded.nutrition ? "Update" : "Add"}
                            />
                        </form>
                    )}
                </Form>
            )}
        </OmniDrawer>
    );
}

interface InputProps {
    name: string;
    label: string;
    type?: string;
    gridSize?: GridSize;
    helperText?: string;
}
const Input = ({ name, label, type, gridSize, helperText }: InputProps): JSX.Element => {
    const { classes } = useStyles();
    const field = (
        <Field name={name}>
            {({ input, meta }) => (
                <TextField
                    {...input}
                    {...handleErrors(meta, helperText)}
                    label={label}
                    variant="outlined"
                    type={type || "number"}
                    className={classes.field}
                    fullWidth
                    data-lpignore
                />
            )}
        </Field>
    );

    return gridSize ? (
        <Grid item xs={gridSize}>
            {field}
        </Grid>
    ) : (
        field
    );
};

const useStyles = makeStyles()((theme: Theme) => ({
    header: { marginBottom: theme.spacing(2) },
    field: { marginBottom: theme.spacing(3) },
    submitErrors: { margin: theme.spacing(4, 0) },
    footer: {
        marginTop: theme.spacing(8),
        textAlign: "end",
    },
}));

const calLowerLabel = (m: CalorieMode): string => {
    switch (m) {
        case CalorieMode.double:
            return "Per serving";
        case CalorieMode.multiple:
            return "Low";
        default:
            return "Total";
    }
};

const calUpperLabel = (m: CalorieMode): string => {
    switch (m) {
        case CalorieMode.double:
            return "All servings";
        case CalorieMode.multiple:
            return "High";
        default:
            return "";
    }
};
