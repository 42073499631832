import { Typography, Alert } from "@mui/material";
import ConfirmDialog from "components/common/ConfirmDialog";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router";
import { masterItemOptionSetDeleteSuccess } from "store/mms/menus/master/items/actions";
import { masterModifierOptionSetDeleteSuccess } from "store/mms/menus/master/items/modifiers/actions";
import { MasterModifier } from "store/mms/menus/master/items/modifiers/types";
import { optionSetDelete } from "store/mms/menus/master/items/option-sets/actions";
import { OptionSet } from "store/mms/menus/master/items/option-sets/types";
import { isMasterItem, MasterItem } from "store/mms/menus/master/items/types";
import { RootState } from "store/rootReducer";
import { axiosErrorMessage } from "store/types";

interface OptionSetDeleteProps {
    onClose(): void;
    open: boolean;
    optionSet: OptionSet;
    parent: MasterItem | MasterModifier;
    redirectUrl?: string;
}

export function OptionSetDelete({ onClose, open, optionSet, parent, redirectUrl }: OptionSetDeleteProps): JSX.Element {
    const dispatch = useDispatch();
    const groupsById = useSelector((state: RootState) => state.mms.menus.master.modifierGroups.byId);
    const [state, setState] = useState<{ deleting: boolean; error?: string; redirect: boolean }>({
        deleting: false,
        redirect: false,
    });
    const group = groupsById[optionSet._embedded.modifier_group.id];

    return state.redirect && redirectUrl !== undefined ? (
        <Navigate to={redirectUrl} />
    ) : (
        <ConfirmDialog
            title="Remove Modifier Group"
            open={open}
            onClose={onClose}
            onConfirm={() => {
                setState((prevState) => ({ ...prevState, error: undefined, deleting: true }));
                dispatch(
                    optionSetDelete(optionSet._links.self.href, (error?: Error): void => {
                        if (error) {
                            return setState((prevState) => ({
                                ...prevState,
                                deleting: false,
                                error: axiosErrorMessage(error),
                            }));
                        }
                        if (isMasterItem(parent)) {
                            dispatch(masterItemOptionSetDeleteSuccess(parent, optionSet));
                        } else {
                            dispatch(masterModifierOptionSetDeleteSuccess(parent, optionSet));
                        }
                        setState(() => ({
                            deleting: false,
                            error: undefined,
                            redirect: redirectUrl !== undefined && redirectUrl !== "" ? true : false,
                        }));
                        onClose();
                    }),
                );
            }}
            confirm="Remove Modifier Group"
            confirming={state.deleting}
            maxWidth="xl"
            transitionDuration={0}
        >
            <Typography>
                Are you sure you want to remove the <strong>{group.display_name}</strong> Modifier Group from{" "}
                <strong>{parent.display_name}</strong>?
            </Typography>

            <Alert variant="outlined" severity="info">
                This change will affect all linked <strong>Locations</strong> and anywhere{" "}
                <strong>{parent.display_name}</strong> is used.
            </Alert>

            {state.error && (
                <Alert variant="outlined" severity="error">
                    {state.error}
                </Alert>
            )}
        </ConfirmDialog>
    );
}
