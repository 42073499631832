import { Box, Card, CardActionArea, CardContent, FormControl, Grid, Skeleton, Typography, Theme } from "@mui/material";
import { makeStyles } from "theme";
import { Restaurant as RestaurantIcon, Schedule as ScheduleIcon } from "@mui/icons-material";
import { Wizard, WizardPage } from "components/common/forms/Wizard";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSession } from "services/session";
import { menuFetch } from "store/mms/menus/master/actions";
import { layersPageFetch } from "store/mms/menus/master/layers/actions";
import { sectionPageFetch } from "store/mms/menus/master/sections/actions";
import { RootState } from "store/rootReducer";
import { EntitySelect } from "components/mms/MenuLayers/RuleSets/Rules/EntitySelect";
import { FORM_ERROR } from "final-form";
import { ConfigureRule } from "components/mms/MenuLayers/RuleSets/Rules/ConfigureRule";
import { orderProfilesFetch } from "store/mms/order-profiles/actions";
import { tagsFetch } from "store/mms/tags/actions";
import { default as createCalculateDecorator } from "final-form-calculate";
import {
    constraintsOrderProfiles,
    enableConstraintsOrderProfiles,
    enableTransformTypeAddTags,
    enableTransformTypeAdjustPrice,
    enableTransformTypeEnabled,
    enableTransformTypePeriods,
    entitySelectErrors,
    formValues,
    getInitialValues,
    mapValuesToMenuRule,
    ruleType,
    selectedItems,
    selectedModifiers,
    transformErrors,
    transformTypeAdjustPriceConfig,
} from "./constants";
import { periodPageFetch } from "store/mms/menus/master/periods/actions";
import arrayMutators from "final-form-arrays";
import { useNavigate, useLocation } from "react-router-dom";
import { ruleSave } from "store/mms/menus/master/layers/rule-sets/rules/actions";
import { axiosErrorMessage } from "store/types";
import { TransformType } from "store/mms/menus/master/layers/rule-sets/rules/types";
import { blockFormCalculators } from "components/mms/MenuPeriods/helpers";

interface RuleEditProps {
    menuId: string;
    layerId: string;
    ruleSetId: string;
    ruleId?: string;
}

export function RuleEdit({ menuId, layerId, ruleSetId, ruleId }: RuleEditProps): JSX.Element {
    const { classes } = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const menusById = useSelector((state: RootState) => state.mms.menus.master.menus.byId);
    const masterMenusFetching = useSelector((state: RootState) => state.mms.menus.master.menus.fetching);
    const menu = menusById[menuId];

    const sectionsById = useSelector((state: RootState) => state.mms.menus.master.sections.byId);
    const sectionIdsByMenuId = useSelector((state: RootState) => state.mms.menus.master.sections.sectionIdsByMenuId);
    const sectionsFetching = useSelector((state: RootState) => state.mms.menus.master.sections.fetching);
    const sections =
        menu && sectionIdsByMenuId[menu.id] ? sectionIdsByMenuId[menu.id].map((id) => sectionsById[id]) : [];

    const itemsById = useSelector((state: RootState) => state.mms.menus.master.items.byId);
    const modifiersByHref = useSelector((state: RootState) => state.mms.menus.master.modifiers.byHref);
    const modifierHrefsById = useSelector((state: RootState) => state.mms.menus.master.modifiers.hrefsById);

    const layersById = useSelector((state: RootState) => state.mms.menus.master.layers.byId);
    const layersFetching = useSelector((state: RootState) => state.mms.menus.master.layers.fetching);
    const layer = layersById[layerId];
    const ruleSet = layer?._embedded.rule_sets.find((rs) => rs.id === ruleSetId);
    const rule = ruleSet?._embedded.rules.find((r) => r.id === ruleId);

    const periodsById = useSelector((state: RootState) => state.mms.menus.master.periods.byId);
    const periodsByMenuId = useSelector((state: RootState) => state.mms.menus.master.periods.byMenuId);
    const periodsFetching = useSelector((state: RootState) => state.mms.menus.master.periods.fetching);

    const orderProfilesFetching = useSelector((state: RootState) => state.mms.orderProfiles.fetching);
    const orderProfilesLoaded = useSelector((state: RootState) => state.mms.orderProfiles.loaded);
    const tagsFetching = useSelector((state: RootState) => state.mms.tags.fetching);
    const tags = useSelector((state: RootState) => state.mms.tags.tags);

    const [selectedRuleType, setSelectedRuleType] = useState<ruleType | undefined>(undefined);
    const [hasSelectedRuleType, setHasSelectedRuleType] = useState(false);
    const handleRuleTypeSelect = (selectedRuleType: ruleType) => {
        setSelectedRuleType(selectedRuleType);
        setHasSelectedRuleType(true);
    };
    const handleClose = () => navigate(location.pathname.replace(/rules.*/i, ""));

    const isLoading =
        masterMenusFetching ||
        menu === undefined ||
        layersFetching ||
        layer === undefined ||
        periodsFetching ||
        !orderProfilesLoaded ||
        orderProfilesFetching ||
        tags.length === 0 ||
        tagsFetching;

    useEffect(() => {
        if (menu === undefined && !masterMenusFetching) {
            dispatch(menuFetch(`/1.1/accounts/${getSession()?.accountId}/mms/menus/${menuId}`));
        }
        if (menu !== undefined && layer === undefined && !layersFetching) {
            dispatch(layersPageFetch(menu));
        }

        if (menu !== undefined && sectionIdsByMenuId[menu.id] === undefined && !sectionsFetching) {
            dispatch(sectionPageFetch(menu._links.sections.href));
        }

        if (menu !== undefined && periodsByMenuId[menuId] === undefined && !periodsFetching) {
            dispatch(periodPageFetch(menuId));
        }

        if (tags.length === 0 && !tagsFetching) {
            dispatch(tagsFetch());
        }

        if (!orderProfilesLoaded && !orderProfilesFetching) {
            dispatch(orderProfilesFetch());
        }
    }, [
        dispatch,
        layer,
        menu,
        menuId,
        sectionIdsByMenuId,
        periodsByMenuId,
        tags.length,
        orderProfilesLoaded,
        masterMenusFetching,
        layersFetching,
        sectionsFetching,
        periodsFetching,
        tagsFetching,
        orderProfilesFetching,
    ]);

    useEffect(() => {
        if (rule !== undefined && !hasSelectedRuleType) {
            setSelectedRuleType(
                rule.definition.transforms.some((t) => t.type === TransformType.AdjustMenuPeriods)
                    ? ruleType.Periods
                    : ruleType.Menu,
            );
        }
    }, [hasSelectedRuleType, rule]);

    return isLoading ? (
        <Skeleton />
    ) : (
        <Box className={classes.container}>
            {selectedRuleType === undefined ? (
                <FormControl className={classes.ruleTypeContainer} component="fieldset">
                    <Typography variant="h5" className={classes.ruleTypeTitle}>
                        What type of rule do you want to create?
                    </Typography>
                    <Grid container justifyContent="center" spacing={2}>
                        <Grid item>
                            <Card className={classes.ruleTypeCard} raised>
                                <CardActionArea onClick={() => handleRuleTypeSelect(ruleType.Menu)}>
                                    <CardContent>
                                        <RestaurantIcon />
                                        <Typography gutterBottom variant="h5" component="h2">
                                            Menu
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>

                        <Grid item>
                            <Card className={classes.ruleTypeCard} raised>
                                <CardActionArea onClick={() => handleRuleTypeSelect(ruleType.Periods)}>
                                    <CardContent>
                                        <ScheduleIcon />
                                        <Typography gutterBottom variant="h5" component="h2">
                                            Hours
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    </Grid>
                </FormControl>
            ) : (
                <Wizard
                    onSubmit={(values, form, callback) => {
                        if (ruleSet !== undefined) {
                            dispatch(
                                ruleSave(
                                    rule !== undefined ? rule._links.self.href : ruleSet._links.rules.href,
                                    mapValuesToMenuRule(values as formValues, rule),
                                    (errors?: Error) => {
                                        if (errors && callback) {
                                            return callback({ [FORM_ERROR]: axiosErrorMessage(errors) });
                                        }
                                        handleClose();
                                    },
                                ),
                            );
                        }
                    }}
                    initialValues={getInitialValues(
                        menuId,
                        periodsByMenuId[menuId].map((id) => periodsById[id]),
                        selectedRuleType,
                        itemsById,
                        modifiersByHref,
                        modifierHrefsById,
                        rule,
                    )}
                    formProps={{
                        decorators: [calculateDecorator, blockFormCalculators],
                        mutators: arrayMutators,
                    }}
                    onClose={handleClose}
                    submitLabel={`${rule === undefined ? "Create" : "Update"} Rule`}
                    noPrevOnClick={() => setSelectedRuleType(undefined)}
                >
                    {selectedRuleType === ruleType.Menu ? (
                        <WizardPage
                            validate={(v) => {
                                if (
                                    (!v[selectedItems] || (v[selectedItems] as string[]).length === 0) &&
                                    (!v[selectedModifiers] || (v[selectedModifiers] as string[]).length === 0)
                                ) {
                                    return {
                                        [entitySelectErrors]: "At least one Menu entity must be selected to proceed.",
                                    };
                                }
                                return {};
                            }}
                        >
                            <EntitySelect sections={sections} />
                        </WizardPage>
                    ) : null}

                    <WizardPage
                        validate={(values) => {
                            if (
                                (selectedRuleType === ruleType.Menu &&
                                    !values[enableTransformTypeEnabled] &&
                                    !values[enableTransformTypeAdjustPrice] &&
                                    !values[enableTransformTypeAddTags]) ||
                                (selectedRuleType === ruleType.Periods && !values[enableTransformTypePeriods])
                            ) {
                                return {
                                    [transformErrors]:
                                        "At least one Menu Rule must be selected and configured to proceed.",
                                };
                            }
                            return {};
                        }}
                    >
                        <ConfigureRule />
                    </WizardPage>
                </Wizard>
            )}
        </Box>
    );
}

const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        minHeight: "inherit",
        display: "flex",

        "& > form .TabPanel-root": {
            minHeight: 400,
        },
    },
    ruleTypeContainer: {
        margin: "auto",
    },
    ruleTypeTitle: {
        textAlign: "center",
        marginBottom: theme.spacing(2),
    },
    ruleTypeCard: {
        height: "200px",
        width: "200px",

        "& > .MuiCardActionArea-root": {
            height: "inherit",

            "& .MuiCardContent-root": {
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            },
        },
    },
    ruleType: {
        flexGrow: 1,
        alignItems: "center",
    },
    iconLabel: {
        display: "inline-flex",
        alignItems: "center",
        padding: theme.spacing(1),

        "& > svg": {
            marginRight: theme.spacing(1),
        },
    },
    grid: {
        minHeight: "inherit",
    },
}));

const calculateDecorator = createCalculateDecorator({
    field: enableConstraintsOrderProfiles,
    updates: (value) => {
        if (value) {
            return {
                [`${transformTypeAdjustPriceConfig}.order_profile`]: "",
            };
        }
        return {
            [constraintsOrderProfiles]: [],
        };
    },
});
