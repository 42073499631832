import React, { useRef, useState } from "react";
import { ActionButton } from "components/common/buttons/ActionButton";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";

import { LocationMenuApplication } from "store/mms/menus/location/applications/types";
import { Popover, SxProps, Theme } from "@mui/material";
import ApplicationSnoozeConfirmDialog from "./ApplicationSnoozeConfirmDialog";
import { addMilliseconds, startOfTomorrow } from "date-fns";
import { RootState } from "store/rootReducer";
import { useSelector } from "react-redux";
import { getTimezoneOffset } from "date-fns-tz";

const hour = 3600000;

interface snoozeOption {
    index: number;
    display: string;
    duration: number;
    eod: boolean;
}

const snooze1Hour: snoozeOption = { index: 0, display: "for 1 hour", duration: hour, eod: false };
const snooze4Hours: snoozeOption = { index: 1, display: "for 4 hours", duration: 4 * hour, eod: false };
const snooze24Hours: snoozeOption = { index: 2, display: "for 24 hours", duration: 24 * hour, eod: false };
const snoozeEOD: snoozeOption = { index: 3, display: "until end of day (midnight)", duration: 0, eod: true };
const customDuration = 0; // Instead of using '| null' which complicates calls, just use 0 to indicate custom.
const snoozeCustom: snoozeOption = { index: 4, display: "until custom time", duration: customDuration, eod: false };

const snoozeOptions = [snooze1Hour, snooze4Hours, snooze24Hours, snoozeEOD, snoozeCustom];

interface ApplicationSnoozeProps {
    application: LocationMenuApplication;
    incomingChange: boolean | undefined;
    sx: SxProps<Theme>;
    onChange(): void;
}

export default function ApplicationSnoozeButton({
    application,
    incomingChange,
    sx,
    onChange,
}: ApplicationSnoozeProps): JSX.Element {
    const actionAnchorRef = useRef(null);
    const [duration, setDuration] = useState<number>(snooze1Hour.duration);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [popoverOpen, setPopoverOpen] = useState(false);

    const locationsById = useSelector((state: RootState) => state.locations.byId);
    const locationId = application._links.self.href.match(/(?:locations\/)([a-zA-Z0-9]*)(?:\/)/)?.pop();

    const handleToggle = () => {
        setPopoverOpen((prevOpen) => !prevOpen);
    };

    const handleClose = () => {
        setPopoverOpen(false);
    };

    const handleConfirmOpen = (index: number) => {
        const snoozeOption = snoozeOptions[index];
        const isCustomDuration = snoozeOption.duration === customDuration;
        let snoozeUntil = isCustomDuration ? customDuration : Date.now() + snoozeOption.duration;

        if (snoozeOption.eod) {
            // Get the location timezone to convert offsets so we can use the locations local midnight
            const location = locationsById[locationId || ""];
            const locationOffset = getTimezoneOffset(location.timezone); // returns milliseconds
            const localMidnight = startOfTomorrow();
            const localOffset = localMidnight.getTimezoneOffset() * -60000; // Date.getTimezoneOffset returns minutes
            const offsetDiff = localOffset - locationOffset;

            snoozeUntil = addMilliseconds(localMidnight, offsetDiff).getTime();
        }

        setDuration(snoozeUntil);
        setConfirmOpen(true);
    };

    const handleConfirmClose = () => {
        setConfirmOpen(false);
    };

    const actualState = application.actual_enabled;
    const stateKnown = actualState !== null;
    const expectedState = incomingChange !== undefined ? incomingChange : application.expected_enabled;
    const pendingChange = stateKnown && application.actual_enabled !== expectedState;
    const buttonEnabled = application.expected_enabled && !pendingChange && stateKnown;

    const handleMenuItemClick = (index: number) => {
        handleConfirmOpen(index);

        setPopoverOpen(false);
    };

    let buttonText = "Snooze for 1 Hour";
    if (!stateKnown) {
        buttonText = "Unavailable";
    }

    return (
        <>
            <ButtonGroup size="small" variant="contained" aria-label="Actions" sx={sx} disabled={!buttonEnabled}>
                <ActionButton
                    label={buttonText}
                    onClick={() => {
                        handleConfirmOpen(snooze1Hour.index);
                    }}
                />
                <Button
                    size="small"
                    aria-controls={popoverOpen ? "split-button-menu" : undefined}
                    aria-expanded={popoverOpen ? "true" : undefined}
                    aria-label="additional actions"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                    ref={actionAnchorRef}
                >
                    <ArrowDropDownIcon />
                </Button>
            </ButtonGroup>
            <Popover
                open={popoverOpen}
                anchorEl={actionAnchorRef.current}
                role={undefined}
                disablePortal
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
                <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu">
                        {snoozeOptions.map((option, index) => (
                            <MenuItem key={option.index} onClick={() => handleMenuItemClick(index)}>
                                Snooze {" " + option.display}
                            </MenuItem>
                        ))}
                    </MenuList>
                </ClickAwayListener>
            </Popover>
            <ApplicationSnoozeConfirmDialog
                open={confirmOpen}
                handleClose={handleConfirmClose}
                application={application}
                snoozeDuration={duration}
                onChange={onChange}
            />
        </>
    );
}
