import {
    Section,
    SectionActions,
    SECTION_PAGE_FETCH,
    SECTION_PAGE_FETCH_ERROR,
    SECTION_PAGE_FETCH_SUCCESS,
} from "./types";

export function sectionPageFetch(url: string): SectionActions {
    return {
        type: SECTION_PAGE_FETCH,
        url,
    };
}

export function sectionPageFetchSuccess(menuUrl: string, sections: Section[]): SectionActions {
    return {
        type: SECTION_PAGE_FETCH_SUCCESS,
        menuUrl,
        sections,
    };
}

export function sectionPageFetchError(error: Error): SectionActions {
    return {
        type: SECTION_PAGE_FETCH_ERROR,
        error,
    };
}
