import React from "react";
import {
    Button,
    Dialog,
    DialogActions as MuiDialogActions,
    DialogActionsProps as MuiDialogActionsProps,
    DialogContent as MuiDialogContent,
    DialogContentProps as MuiDialogContentProps,
    DialogProps,
    DialogTitle as MuiDialogTitle,
    DialogTitleProps as MuiDialogTitleProps,
    IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Close as CloseIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
interface DialogTitleProps {
    id: string;
    children: React.ReactNode;
    onClose?: () => void;
    dialogTitleProps?: Partial<MuiDialogTitleProps>;
}

export const DialogTitle = (props: DialogTitleProps): JSX.Element => {
    const { children, onClose, ...other } = props;
    return (
        <MuiDialogTitle
            {...other}
            sx={(theme) => ({
                margin: 0,
                padding: theme.spacing(3),
            })}
        >
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    sx={(theme) => ({
                        position: "absolute",
                        right: theme.spacing(2),
                        top: theme.spacing(2),
                        color: theme.palette.grey[500],
                    })}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
};

export const DialogContent = styled(MuiDialogContent)<MuiDialogContentProps>(({ theme }) => ({
    padding: theme.spacing(4, 3),

    "& .MuiAlert-root": {
        marginTop: theme.spacing(4),
    },
}));

export const DialogActions = styled(MuiDialogActions)<MuiDialogActionsProps>(({ theme }) => ({
    margin: 0,
    padding: theme.spacing(3, 2),
}));

interface ConfirmDialogProps extends Omit<DialogProps, "onClose" | "classes"> {
    title: string;
    confirm?: string;
    confirming?: boolean;
    cancel?: string;
    dialogActionsProps?: Partial<MuiDialogActionsProps>;
    dialogContentProps?: Partial<MuiDialogContentProps>;
    dialogTitleProps?: Omit<Partial<MuiDialogTitleProps>, "onClose">;
    disableConfirm?: boolean;
    hideActions?: boolean;
    onConfirm?: () => void;
    onClose?: () => void;
}

const ConfirmDialog = (props: ConfirmDialogProps): JSX.Element => {
    const {
        onClose,
        onConfirm,
        children,
        title,
        confirming = false,
        confirm = "Confirm",
        cancel = "Cancel",
        dialogActionsProps,
        dialogContentProps,
        dialogTitleProps,
        disableConfirm = false,
        hideActions = false,
        ...rest
    } = props;

    return (
        <Dialog {...rest} onClose={onClose}>
            <DialogTitle id="confirm-dialog-title" onClose={onClose} {...dialogTitleProps}>
                {title}
            </DialogTitle>
            <DialogContent dividers {...dialogContentProps}>
                {children}
            </DialogContent>
            {!hideActions && (
                <DialogActions {...dialogActionsProps}>
                    <LoadingButton
                        autoFocus
                        variant="contained"
                        onClick={onConfirm}
                        disabled={disableConfirm || confirming}
                        color="primary"
                        loading={confirming}
                    >
                        {confirm}
                    </LoadingButton>
                    <Button onClick={onClose}>{cancel}</Button>
                </DialogActions>
            )}
        </Dialog>
    );
};

export default ConfirmDialog;
