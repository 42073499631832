import { capitalize } from "components/common/utils";
import { ListRoute } from "store/types";

export const LOCATION_FETCH = "@@LOCATION/FETCH";
export const LOCATION_FETCH_SUCCESS = "@@LOCATION/FETCH_SUCCESS";
export const LOCATION_FETCH_ERROR = "@@LOCATION/FETCH_ERROR";

export const LOCATION_SEARCH = "@@LOCATION/SEARCH";
export const LOCATION_SEARCH_SUCCESS = "@@LOCATION/SEARCH_SUCCESS";
export const LOCATION_SEARCH_ERROR = "@@LOCATION/SEARCH_ERROR";
export const LOCATION_SEARCH_RESET = "@@LOCATION/SEARCH_RESET";

export const LOCATION_PAGE_FETCH = "@@LOCATION/PAGE/FETCH";
export const LOCATION_PAGE_FETCH_SUCCESS = "@@LOCATION/PAGE/FETCH_SUCCESS";
export const LOCATION_PAGE_FETCH_ERROR = "@@LOCATION/PAGE/FETCH_ERROR";

// These are copied directly from Omnivore's Python API v1.0
// https://github.com/omnivore/titanoboa/blob/7f044108ddb7051221fd2bcde99e4f9e76953040/om/backend-core/backend_core/models/location.py#L119
// If the source of truth for POSTypes changes, ensure the link above reflects the change.
export enum POSType {
    ALOHA = "aloha",
    BRINK = "brink",
    CLOUDCONNECT = "cloudconnect",
    CLOVER = "clover",
    DINERWARE = "dinerware",
    DOSHII = "doshii",
    FOCUS = "focus",
    INFOGENESIS = "infogenesis",
    LAVU = "lavu",
    LEAPSET = "leapset",
    LIGHTSPEED = "lightspeed",
    MICROS3700 = "micros3700",
    MICROS9700 = "micros9700",
    MOCK = "mock",
    OTHER = "other",
    POSITOUCH = "positouch",
    SIMPHONY = "simphony",
    SIMPHONY1 = "simphony1",
    SIMPHONY2 = "simphony2",
    MAITRED = "maitred",
    AZBAR = "azbar",
    SALIDO = "salido",
    VELOCE = "veloce",
    SQUIRREL = "squirrel",
    XPIENT = "xpient",
    NORTHSTAR = "northstar",
    TEND = "tend",
    TOAST = "toast",
    UNKNOWN = "unknown",
}

export const POSTypeTitle = (posType: POSType): string => {
    switch (posType) {
        case POSType.ALOHA:
            return "NCR Aloha";

        case POSType.FOCUS:
            return "Focus POS";

        case POSType.INFOGENESIS:
            return "Agilysys InfoGenesis";

        case POSType.MAITRED:
            return "Maitre'D";

        case POSType.MICROS3700:
            return "Micros 3700";

        case POSType.MOCK:
            return "Mock POS";

        case POSType.POSITOUCH:
            return "POSitouch";

        case POSType.SIMPHONY1:
            return "Micros Simphony First Edition";

        case POSType.SIMPHONY:
            return "Micros Simphony";

        default:
            return capitalize(posType.valueOf());
    }
};

export enum Status {
    ACTIVATING = "activating",
    ACTIVATING_ERROR = "activating_error",
    ACTIVE = "active",
    DEGRADED = "degraded",
    INITIALIZING = "initializing",
    INITIALIZING_ERROR = "initializing_error",
    OFFLINE = "offline",
    ONLINE = "online",
    OFFBOARDED = "offboarded",
    PENDING = "pending",
    REPLACED = "replaced",
}

export const StatusTitle = (status: Status): string => {
    switch (status) {
        case Status.ONLINE:
        case Status.DEGRADED:
            return "Online";
        // For now, we are simplifying status interpretation to "if you are not ONLINE, you are OFFLINE". We can easily
        // distinguish other statuses if the need arises.
        case Status.ACTIVE:
        case Status.ACTIVATING:
        case Status.ACTIVATING_ERROR:
        case Status.INITIALIZING:
        case Status.INITIALIZING_ERROR:
        case Status.OFFBOARDED:
        case Status.OFFLINE:
        case Status.PENDING:
        case Status.REPLACED:
            return "Offline";
        default:
            return "Unknown";
    }
};

export interface Address {
    city: string;
    country: string;
    state: string;
    street1: string;
    street2: string;
    zip: string;
}

export interface Location {
    id: string;
    name: string;
    display_name: string;
    pos_type: POSType;
    address: Address;
    development: boolean;
    timezone: string;

    _links: {
        self: {
            href: string;
            type: "application/hal+json; name=account_location";
        };
    };
}

export interface LocationListResp extends ListRoute {
    _embedded: { locations: Location[] };
}

export interface LocationFetch {
    type: typeof LOCATION_FETCH;
    url: string;
}

interface LocationFetchSuccess {
    type: typeof LOCATION_FETCH_SUCCESS;
    location: Location;
}

interface LocationFetchError {
    type: typeof LOCATION_FETCH_ERROR;
    error: Error;
}

export interface LocationsSearch {
    type: typeof LOCATION_SEARCH;
    query: string;
}

interface LocationsSearchSuccess {
    type: typeof LOCATION_SEARCH_SUCCESS;
    locations: Location[];
}

interface LocationsSearchError {
    type: typeof LOCATION_SEARCH_ERROR;
    error: Error;
}

interface LocationSearchReset {
    type: typeof LOCATION_SEARCH_RESET;
}

export interface LocationPageFetch {
    type: typeof LOCATION_PAGE_FETCH;
    url: string;
    page: number;
    pageSize: number;
    query?: string;
}

interface LocationPageFetchSuccess {
    type: typeof LOCATION_PAGE_FETCH_SUCCESS;
    locations: Location[];
    page: number;
    pageSize: number;
    total: number;
}

interface LocationPageFetchError {
    type: typeof LOCATION_PAGE_FETCH_ERROR;
    page: number;
    error: Error;
}

export type LocationsActions =
    | LocationFetch
    | LocationFetchSuccess
    | LocationFetchError
    | LocationsSearch
    | LocationsSearchSuccess
    | LocationsSearchError
    | LocationSearchReset
    | LocationPageFetch
    | LocationPageFetchSuccess
    | LocationPageFetchError;
