import { call, ForkEffect, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { core } from "services/api";
import { POSConfig } from "store/mms/types";
import { enqueueSnackbar } from "store/notifications/actions";
import { RootState } from "store/rootReducer";
import { ModifierGroupModifier } from "../option-sets/modifier-group/types";
import { OptionSet } from "../option-sets/types";
import {
    masterModifierFetch as masterModifierFetchAction,
    masterModifierFetchError,
    masterModifierFetchSuccess,
    masterModifierOptionSetsSaveError,
    masterModifierSaveError,
    masterModifierSaveSuccess,
} from "./actions";
import {
    MasterModifier,
    MasterModifierReq,
    MasterModifierResp,
    MASTER_MODIFIER_FETCH,
    MASTER_MODIFIER_OPTION_SETS_SAVE,
    MASTER_MODIFIER_SAVE,
} from "./types";

export const flattenMods = (mods: MasterModifier[]): MasterModifier[] => {
    const flattenedMods: MasterModifier[] = [...mods];

    for (const mod of mods) {
        mod._embedded.option_sets.forEach((os) => {
            const osMods = os._embedded.modifier_group._embedded.modifiers.map((m: ModifierGroupModifier) =>
                mapModifierRespToDomain(m._embedded.modifier as MasterModifierResp),
            );
            flattenedMods.push(...osMods);
            flattenedMods.push(...flattenMods(osMods));
        });
    }
    return flattenedMods;
};
const mapDomainToReq = (m: MasterModifier, force_location_update?: boolean): MasterModifierReq => {
    return {
        base_price_per_unit: m.base_price_per_unit,
        display_name: m.display_name,
        enabled: m.enabled,
        id: m.id,
        linked: m.linked,
        pos_config: JSON.stringify(m.pos_config),
        reference_name: m.reference_name,
        source_location: m.source_location,
        strategy: m.strategy,
        force_location_update,
    };
};
export const mapModifierRespToDomain = (r: MasterModifierResp): MasterModifier => {
    return {
        ...r,
        pos_config:
            r.pos_config !== "null" && r.pos_config !== ""
                ? (JSON.parse(r.pos_config) as POSConfig)
                : ({} as POSConfig),
    };
};
const hrefsById = (state: RootState) => state.mms.menus.master.modifiers.hrefsById;

function* masterModifierFetch({ url }: { url: string; type: typeof MASTER_MODIFIER_FETCH }) {
    try {
        const resp: { data: MasterModifierResp } = yield call(core.get, url);

        yield put(masterModifierFetchSuccess(mapModifierRespToDomain(resp.data)));
    } catch (error) {
        console.error("Failed to fetch Menu Master Modifier", error);
        if (error instanceof Error) {
            yield put(masterModifierFetchError(error));
        }
    }
}

function* masterModifierSave({
    url,
    modifier,
    callback,
    force_location_update,
}: {
    url: string;
    type: typeof MASTER_MODIFIER_SAVE;
    modifier: MasterModifier;
    callback?: (error?: Error) => void;
    force_location_update?: boolean;
}) {
    try {
        const resp: { data: MasterModifierResp } = yield call(
            modifier.id === "" ? core.post : core.put,
            url,
            mapDomainToReq(modifier, force_location_update),
        );
        const mod = mapModifierRespToDomain(resp.data);
        const modsMap: {
            [key: string]: string[];
        } = yield select(hrefsById);

        if (callback !== undefined) {
            yield call(callback);
        }
        yield put(masterModifierSaveSuccess(mod));
        yield put(
            enqueueSnackbar({
                message: `Successfully saved Modifier, ${mod.display_name}.`,
                options: {
                    variant: "success",
                },
            }),
        );
        for (const url of modsMap[mod.id]) {
            if (url !== mod._links.self.href) {
                yield put(masterModifierFetchAction(url));
            }
        }
    } catch (e) {
        console.error("Failed to save Menu Master Modifier", e);
        if (e instanceof Error) {
            if (callback !== undefined) {
                yield call(callback, e);
            }
            yield put(masterModifierSaveError(e));
        }

        yield put(
            enqueueSnackbar({
                message: `Failed to save Modifier${modifier.display_name && ", " + modifier.display_name}.`,
                options: {
                    variant: "error",
                },
            }),
        );
    }
}

function* masterModifierOptionSetsSave({
    modifier,
    optionSets,
    callback,
}: {
    type: typeof MASTER_MODIFIER_OPTION_SETS_SAVE;
    modifier: MasterModifier;
    optionSets: OptionSet[];
    callback?: (error?: Error) => void;
}) {
    const prevOptionSets = modifier._embedded.option_sets;
    try {
        yield call(
            core.post,
            modifier._links.option_sets.href,
            optionSets.map((os) => ({
                description: os.description,
                enabled: os.enabled,
                id: os.id,
                implicit: os.implicit,
                max: os.max,
                min: os.min,
                name: os.name,
                required: os.required,
            })),
        );

        if (callback !== undefined) {
            yield call(callback);
        }
    } catch (e) {
        console.error("Failed to save Menu Master Modifier Option Sets", e);
        if (e instanceof Error) {
            if (callback !== undefined) {
                yield call(callback, e);
            }
            yield put(masterModifierOptionSetsSaveError(e, modifier, prevOptionSets));
        }

        yield put(
            enqueueSnackbar({
                message: `Failed to save Modifier Modifier Groups.`,
                options: {
                    variant: "error",
                },
            }),
        );
    }
}

export default function* masterModifiersSaga(): Generator<ForkEffect<never>, void, unknown> {
    yield takeLatest(MASTER_MODIFIER_SAVE, masterModifierSave);
    yield takeEvery(MASTER_MODIFIER_FETCH, masterModifierFetch);
    yield takeEvery(MASTER_MODIFIER_OPTION_SETS_SAVE, masterModifierOptionSetsSave);
}
