import PaperWrapper from "components/common/PaperWrapper";
import { Autocomplete } from "mui-rff";
import React, { useEffect, useMemo, useState } from "react";
import debounce from "lodash/debounce";
import { apiBaseUrl, searchDebounce } from "services/env";
import { useFormState } from "react-final-form";
import { AutocompleteInputChangeReason } from "@mui/material";
import { Location, LocationListResp } from "store/locations/types";
import { AxiosResponse } from "axios";
import { core } from "services/api";
import { getSession } from "services/session";

const s = getSession();
const locationsUrl = `${apiBaseUrl}/1.1/accounts/${s?.accountId}/locations?search=`;
interface LocationSearchProps {
    entityType: string;
}

export default function LocationSearch({ entityType }: LocationSearchProps): JSX.Element {
    const formState = useFormState();
    const { location } = formState.values;
    const [state, setState] = useState<{
        options: Location[];
        searching: boolean;
        query: string;
    }>({
        options: location !== undefined ? [location] : [],
        searching: false,
        query: "",
    });
    const search = useMemo(
        () =>
            debounce(async (isMounted: () => boolean, query: string) => {
                try {
                    const resp: AxiosResponse<LocationListResp> = await core.get(locationsUrl + query);

                    if (!isMounted()) {
                        return;
                    }

                    setState((prevState) => ({
                        ...prevState,
                        searching: false,
                        options: resp.data._embedded.locations,
                    }));
                } catch (e) {
                    console.error("Failed to search for Locations", e);
                    setState((prevState) => ({ ...prevState, searching: false }));
                }
            }, searchDebounce),
        [],
    );
    const handleSearch = (event: unknown, value: string, reason: AutocompleteInputChangeReason) => {
        if (reason === "reset") {
            return;
        }

        setState((prevState) => ({ ...prevState, query: value, searching: true }));
    };

    useEffect(() => {
        if (!state.searching) {
            return;
        }

        let mounted = true;
        search(() => mounted, state.query);

        return () => {
            mounted = false;
        };
    }, [search, state.query, state.searching]);

    return (
        <Autocomplete
            name="location"
            label="Source Location"
            helperText={`Select the Location from which to pull the POS data for this ${entityType}.`}
            options={state.options}
            onInputChange={handleSearch}
            getOptionLabel={(o) => (o && `${o.display_name} (${o.id})`) || ""}
            showError={({ meta: { submitError, dirtySinceLastSubmit, error, touched } }) => {
                return !!(((submitError && !dirtySinceLastSubmit) || error) && touched);
            }}
            loading={state.searching}
            PaperComponent={PaperWrapper}
            textFieldProps={{
                title: "Source Location",
                variant: "outlined",
                autoFocus: true,
            }}
            fullWidth
            freeSolo
        />
    );
}
