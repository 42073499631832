import { getSession } from "services/session";
import {
    Block,
    Period,
    PeriodActions,
    PERIOD_SAVE,
    PERIOD_SAVE_SUCCESS,
    PERIOD_SAVE_ERROR,
    PERIOD_BLOCKS_SAVE,
    PERIOD_BLOCKS_SAVE_ERROR,
    PERIOD_BLOCKS_SAVE_SUCCESS,
    PERIOD_PAGE_FETCH,
    PERIOD_PAGE_FETCH_ERROR,
    PERIOD_PAGE_FETCH_SUCCESS,
    NewPeriod,
} from "./types";

export function periodPageFetch(menuId: string): PeriodActions {
    return {
        type: PERIOD_PAGE_FETCH,
        url: `/1.1/accounts/${getSession()?.accountId}/mms/menus/${menuId}/periods`,
    };
}

export function periodPageFetchSuccess(menuId: string, periods: Period[]): PeriodActions {
    return {
        type: PERIOD_PAGE_FETCH_SUCCESS,
        menuId,
        periods,
    };
}

export function periodPageFetchError(error: Error): PeriodActions {
    return {
        type: PERIOD_PAGE_FETCH_ERROR,
        error,
    };
}

export function periodSave(
    period: Period | NewPeriod,
    blocks: Block[],
    disabledSections: string[],
    url: string,
    callback?: (error?: Error) => void,
): PeriodActions {
    return {
        type: PERIOD_SAVE,
        period,
        blocks,
        disabledSections,
        url,
        callback,
    };
}

export function periodSaveSuccess(period: Period, menuId: string): PeriodActions {
    return {
        type: PERIOD_SAVE_SUCCESS,
        menuId,
        period,
    };
}

export function periodSaveError(error: Error): PeriodActions {
    return {
        type: PERIOD_SAVE_ERROR,
        error,
    };
}

export function periodBlocksSave(
    period: Period,
    blocks: Block[],
    url: string,
    callback?: (error?: Error) => void,
): PeriodActions {
    return {
        type: PERIOD_BLOCKS_SAVE,
        period,
        blocks,
        url,
        callback,
    };
}

export function periodBlocksSaveSuccess(period: Period, blocks: Block[], menuId: string): PeriodActions {
    return {
        type: PERIOD_BLOCKS_SAVE_SUCCESS,
        menuId,
        period,
        blocks,
    };
}

export function periodBlocksSaveError(error: Error): PeriodActions {
    return {
        type: PERIOD_BLOCKS_SAVE_ERROR,
        error,
    };
}
